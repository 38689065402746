import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getLabelsAndValuesFromNumber } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        paymentMethod: undefined,
        amount: undefined,
        upfrontPaymentPeriodType: undefined,
        upfrontPaymentPeriod: undefined,
      }}
      validationSchema={Yup.object().shape({
        paymentMethod: Yup.string().required(t('required')),
        amount: Yup.string().required(t('required')),
        upfrontPaymentPeriodType: Yup.string().required(t('required')),
        upfrontPaymentPeriod: Yup.string().required(t('required')),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        dirty,
        values: { upfrontPaymentPeriodType },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                isSelect
                label={t('paymentMethod')}
                name='paymentMethod'
                options={[
                  { label: t('cash'), value: 'cash' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('debitCreditCard'), value: 'card' },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field label={t('paymentAmount')} name='amount' />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                doFinally={() => {
                  setFieldTouched('upfrontPaymentPeriod', false);
                  setFieldValue('upfrontPaymentPeriod', undefined);
                }}
                label={t('paymentPeriodType')}
                name='upfrontPaymentPeriodType'
                options={[
                  { label: t('weeks'), value: 'weeks' },
                  { label: t('months'), value: 'months' },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('paymentPeriod')}
                name='upfrontPaymentPeriod'
                options={getLabelsAndValuesFromNumber({
                  numberFrom: 1,
                  numberTo: upfrontPaymentPeriodType === 'weeks' ? 52 : 36,
                })}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
