import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingLocations,
  isFetchingRegions,
  locations,
  regions,
  paymentGatewayOptions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        date: Yup.string().required(t('required')),
        regionId: Yup.string(),
        locationId: Yup.string(),
        paymentGatewayId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('date')}
                name='date'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingRegions}
                label={t('region')}
                name='regionId'
                options={[
                  { label: t('all'), value: undefined },
                  ...regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingLocations}
                label={t('location')}
                name='locationId'
                options={[
                  { label: t('all'), value: undefined },
                  ...locations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentGateway')}
                name='paymentGatewayId'
                options={paymentGatewayOptions}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
