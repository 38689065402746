import _ from 'lodash';
import { Box, ProcessingSuspense, Text } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { measures } from 'octiv-utilities/Constants';
import { getExercisePart } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../button';

const Workout = ({
  data,
  hasTrainerNotesToggle = false,
  hasLargeSpacing = false,
  isLoading = false,
  isOverview = false,
  showTrainerNotes = true,
  showCoolDown = true,
  showExercises = true,
  showMemberNotes = true,
  showName = true,
  showProgramme = true,
  showTrainerView = false,
  showWarmUp = true,
  textProps = { variant: 'caption' },
  ...props
}) => {
  const { t } = useTranslation();

  const [toggle, setToggle] = useToggle();

  if (isLoading) return <ProcessingSuspense />;
  if (!data || _.isEmpty(data)) return null;

  const {
    id,
    name,
    programme,
    warmUp,
    coolDown,
    exercises,
    memberNotes,
    trainerNotes,
  } = data;

  const isVisibleName = showName && !!name;
  const isVisibleProgramme = showProgramme && !!programme?.name;
  const isVisibleWarmUp = showWarmUp && !isOverview && !!warmUp;
  const isVisibleExercises = showExercises && !!exercises;
  const isVisibleCoolDown = showCoolDown && !isOverview && !!coolDown;
  const isVisibleMemberNotes = showMemberNotes && !isOverview && !!memberNotes;
  const isVisibleTrainerNotes =
    (hasTrainerNotesToggle
      ? toggle.isVisible
      : showTrainerNotes && !isOverview) && !!trainerNotes;

  return (
    <Box hasRadius bg='grey6' key={id} whiteSpace='normal' {...props}>
      {showTrainerView && (
        <Button
          isFullWidth
          icon='fullscreen'
          isDisabled={isLoading}
          mb={4}
          text={t('trainerView')}
          onClick={() => window.open(`/workouts/trainer/${id}`)}
        />
      )}

      {isVisibleName && <Text {...textProps}>{name}</Text>}

      {isVisibleProgramme && (
        <Text color='grey2' {...textProps}>
          {programme.name}
        </Text>
      )}

      {[
        ...(isVisibleWarmUp
          ? [
              {
                id: 'warmUp',
                title: `${t('warmUp')}:`,
                body: warmUp,
              },
            ]
          : []),
        ...(isVisibleExercises
          ? [
              {
                id: 'exercises',
                exercises,
              },
            ]
          : []),
        ...(isVisibleCoolDown
          ? [
              {
                id: 'coolDown',
                title: `${t('coolDown')}:`,
                body: coolDown,
              },
            ]
          : []),
        ...(isVisibleMemberNotes
          ? [
              {
                id: 'memberNotes',
                title: `${t('memberNotes')}:`,
                body: memberNotes,
              },
            ]
          : []),
        ...(isVisibleTrainerNotes
          ? [
              {
                id: 'trainerNotes',
                title: `${t('trainerNotes')}:`,
                body: trainerNotes,
              },
            ]
          : []),
      ].map((item, index) => (
        <React.Fragment key={item.id}>
          {item.title && (
            <Text
              mt={
                index === 0 && !isVisibleName && !isVisibleProgramme
                  ? undefined
                  : hasLargeSpacing
                  ? 6
                  : 2
              }
              {...textProps}
            >
              {item.title}
            </Text>
          )}

          {item.body && (
            <Text color='grey1' whiteSpace='pre-line' {...textProps}>
              {item.body}
            </Text>
          )}

          {item.exercises &&
            item.exercises.map((exercise, exerciseIndex) => (
              <React.Fragment key={`${exerciseIndex.toString()}`}>
                <Text
                  isBold
                  color='primary'
                  mt={hasLargeSpacing ? 6 : 2}
                  {...textProps}
                >
                  {`${t('part')} ${
                    exercise.prefix
                      ? exercise.prefix
                      : getExercisePart({ index: exerciseIndex })
                  }${exercise.name ? ': ' : ''}`}

                  {exercise.name && (
                    <Text isBold as='span' {...textProps}>
                      {exercise.name}
                    </Text>
                  )}
                </Text>

                <Text color='grey2' mb={1} {...textProps}>
                  {`${t('measure')}: ${
                    measures.find(
                      (measure) =>
                        measure.measureId === exercise.measuringUnit?.id
                    )?.description
                  }`}
                </Text>

                <Text color='grey1' whiteSpace='pre-line' {...textProps}>
                  {exercise.description}
                </Text>
              </React.Fragment>
            ))}
        </React.Fragment>
      ))}

      {hasTrainerNotesToggle && !!trainerNotes && (
        <Button
          color='grey2'
          hasBorder={toggle.isVisible}
          mt={3}
          scale='small'
          text={
            toggle.isVisible ? t('hideTrainerNotes') : t('viewTrainerNotes')
          }
          onClick={setToggle}
        />
      )}
    </Box>
  );
};

export default Workout;
