import {
  Button,
  Chart,
  Col,
  Modal,
  ProcessingBar,
  Row,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useBodyWeightsCreate,
  useBodyWeightsDelete,
  useBodyWeightsFind,
  useBodyWeightsUpdate,
} from 'octiv-hooks/requests/BodyWeights';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default ({ userId }) => {
  const { t } = useTranslation();

  const { tenantId } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const bodyWeightsFind = useBodyWeightsFind({
    filter: { tenantId, userId },
    paging: {
      perPage: -1,
    },
  });

  const bodyWeightsCreate = useBodyWeightsCreate({
    onSuccess: resetToggle,
  });

  const bodyWeightsUpdate = useBodyWeightsUpdate({
    onSuccess: resetToggle,
  });

  const bodyWeightsDelete = useBodyWeightsDelete();

  const onSubmitForm = (values) => {
    if (toggle.type === 'create') {
      bodyWeightsCreate.mutate({ userId, ...values });
    } else {
      bodyWeightsUpdate.mutate({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: bodyWeightsFind.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'delete':
        bodyWeightsDelete.mutate({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(bodyWeightsFind.isFetching ||
        bodyWeightsCreate.isLoading ||
        bodyWeightsUpdate.isLoading ||
        bodyWeightsDelete.isLoading) && <ProcessingBar />}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${toggle.type === 'create' ? t('add') : t('update')} ${t(
            'weight'
          )}`}
          onClose={resetToggle}
        >
          <Form
            initialValues={toggle.data}
            isCreateFlow={toggle.type === 'create'}
            onSubmit={onSubmitForm}
          />
        </Modal>
      )}

      <Button
        mb={4}
        text={t('createNew')}
        onClick={() => setToggle({ type: 'create' })}
      />

      <Row>
        {bodyWeightsFind.data?.data?.[1] && (
          <Col md={6}>
            <Chart
              isLine
              isShort
              data={bodyWeightsFind.data?.data?.map((item) => ({
                name: getDateReadableDayMonthYear({ date: item.recordedAt }),
                Weight: item.weight,
              }))}
              isLoading={bodyWeightsFind.isFetching}
              isVisibleLegend={false}
            />
          </Col>
        )}

        <Col md={bodyWeightsFind.data?.data?.[1] ? 6 : undefined}>
          <Table
            data={bodyWeightsFind.data?.data || []}
            isLoading={bodyWeightsFind.isFetching}
            onClickAction={onClickAction}
          />
        </Col>
      </Row>
    </>
  );
};
