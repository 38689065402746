import { InlineUser, Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  // getFullName,
  getMandateStatusDetails,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setInvoices, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => <InlineUser user={original} />,
      },
      {
        Header: t('sentOn'),
        id: 'goCardlessLinkSentOn',
        // accessor: 'user',
        Cell: ({ row: { original } }) =>
          getDateReadableDayMonthYear({
            date: original?.userTenant?.goCardlessLinkSentOn,
          }),
      },
      {
        Header: t('status'),
        accessor: 'mandate',
        Cell: ({ row: { original } }) => {
          const { mandate } = original;
          const { isActive, isInactive, status } = getMandateStatusDetails({
            status: mandate?.status,
            sentAt: original?.goCardlessLinkSentOn,
            isGoCardless: true,
          });

          return (
            <Text
              as='span'
              color={isActive ? 'success' : isInactive ? 'danger' : 'grey1'}
            >
              {status}
            </Text>
          );
        },
      },
      {
        Header: t('actions'),
        Cell: ({ row: { original } }) => {
          const { mandate } = original;
          if (mandate?.status === 'active' || mandate?.status === 'cancelled') {
            return (
              <Text
                as='span'
                color='grey1'
                onClick={() =>
                  onClickAction({
                    id: mandate?.id,
                    action: 'delete',
                  })
                }
              >
                {t('delete')}
              </Text>
            );
          }

          if (!mandate?.status || mandate?.status === 'pending') {
            return (
              <>
                <Text
                  as='span'
                  color='primary'
                  mr={8}
                  onClick={() =>
                    onClickAction({
                      id: original?.id,
                      action: 'sendLink',
                    })
                  }
                >
                  {t('sendOnboardingLink')}
                </Text>

                <Text
                  as='span'
                  color='primary'
                  onClick={() =>
                    onClickAction({
                      id: original?.id,
                      action: 'openLink',
                    })
                  }
                >
                  {t('openOnboardingLink')}
                </Text>
              </>
            );
          }

          return null;
        },
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
