import { createTheme } from '@mui/material/styles';
import { isHexString } from 'simpler-color/lib/color/parsers/hexString';

const commonColors = {
  dark: '#000000',
  dark10: '#0000001a',
  dark20: '#00000033',
  dark50: '#00000080',
  darkOverlay: '#000000cc',
  light: '#ffffff',
  light20: '#ffffff33',
  light50: '#ffffff80',
  lightOverlay: '#ffffffcc',
  transparent: 'transparent',
};

const sizes = [0, '0.25rem', '0.5rem', '0.75rem'];
for (let index = 1; index < 20; index += 1)
  sizes.push(
    `${index}rem`,
    `${index}.25rem`,
    `${index}.5rem`,
    `${index}.75rem`
  );

export const getTheme = ({
  primaryDark,
  buttonDark,
  primaryLight,
  buttonLight,
} = {}) => {
  const darkPrimary =
    primaryDark && isHexString(primaryDark) ? primaryDark : '#79da45';
  const darkButton =
    buttonDark && isHexString(buttonDark) ? buttonDark : '#27272a';

  const lightPrimary =
    primaryLight && isHexString(primaryLight) ? primaryLight : '#79d03b';
  const lightButton =
    buttonLight && isHexString(buttonLight) ? buttonLight : '#fafafa';

  const common = {
    sizes,
    space: sizes,
    borders: [0, '1px solid', '2px solid'],
    fonts: {
      jumbo: {
        as: 'h1',
        fontSize: '2rem',
      },
      display: {
        as: 'h1',
        fontSize: '1.5rem',
      },
      heading: {
        as: 'h2',
        fontSize: '1.25rem',
      },
      subheading: {
        as: 'h3',
        fontSize: '1rem',
      },
      body: {
        as: 'p',
        fontSize: '0.875rem',
      },
      caption: {
        as: 'p',
        fontSize: '0.8rem',
      },
      tiny: {
        as: 'p',
        fontSize: '0.785rem',
      },
    },
  };

  return {
    dark: {
      app: {
        ...common,
        isDark: true,
        colors: {
          ...commonColors,
          primary: darkPrimary,
          primary20: `${darkPrimary}33`,
          button: darkButton,

          // Tailwind - Zinc
          grey1: '#a1a1aa',
          grey2: '#71717a',
          grey3: '#52525b',
          grey4: '#3f3f46',
          grey5: '#27272a',
          grey6: '#18181b',
          grey7: '#000000',
          font: '#fafafa',
          fontInverse: '#27272a',

          success: '#a3e635',
          success20: '#a3e63533',
          info: '#38bdf8',
          info20: '#38bdf833',
          warning: '#facc15',
          warning20: '#facc1533',
          danger: '#f43f5e',
          danger20: '#f43f5e33',
        },
      },
      mui: createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: darkPrimary,
          },
        },
      }),
    },
    light: {
      app: {
        ...common,
        isDark: false,
        colors: {
          ...commonColors,
          primary: lightPrimary,
          primary20: `${lightPrimary}33`,
          button: lightButton,

          // Tailwind - Zinc
          grey1: '#52525b',
          grey2: '#71717a',
          grey3: '#a1a1aa',
          grey4: '#d4d4d8',
          grey5: '#e4e4e7',
          grey6: '#f4f4f5',
          grey7: '#ffffff',
          font: '#27272a',
          fontInverse: '#fafafa',

          success: '#84cc16',
          success20: '#84cc1633',
          info: '#0ea5e9',
          info20: '#0ea5e933',
          warning: '#eab308',
          warning20: '#eab30833',
          danger: '#e11d48',
          danger20: '#e11d4833',
        },
      },
      mui: createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: lightPrimary,
          },
        },
      }),
    },
  };
};
