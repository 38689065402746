import { Box, Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('trainer'),
        accessor: 'user',
        Cell: ({ value }) => getFullName(value),
      },
      {
        Header: t('classes'),
        accessor: 'classes',
        Cell: ({
          row: {
            original: { classes, user },
          },
        }) => (
          <Box onClick={() => onClickAction({ action: 'classes', user })}>
            <Text as='span' color='primary'>
              {classes}
            </Text>
          </Box>
        ),
      },
      {
        Header: `${t('earned')} (${t('classes')})`,
        accessor: 'amountEarnedForClasses',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('sessions'),
        accessor: 'sessions',
        Cell: ({
          row: {
            original: { sessions, user },
          },
        }) => (
          <Box onClick={() => onClickAction({ action: 'sessions', user })}>
            <Text as='span' color='primary'>
              {sessions}
            </Text>
          </Box>
        ),
      },
      {
        Header: `${t('earned')} (${t('sessions')})`,
        accessor: 'amountEarnedForSessions',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [onClickAction, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
