import { Images } from 'octiv-assets';
import { Box, Button, Image, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMediaQuery, useToggle } from 'octiv-hooks';
import {
  useBodyMeasurementsCreate,
  useBodyMeasurementsDelete,
  useBodyMeasurementsFind,
  useBodyMeasurementsUpdate,
} from 'octiv-hooks/requests/BodyMeasurements';
import { getAge } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default ({
  isFetchingUserTenant,
  user: { id: userId, dateOfBirth, gender },
}) => {
  const { t } = useTranslation();

  const { tenantId } = useActiveUserTenant();

  const { lg, md } = useMediaQuery();
  const [toggle, setToggle, resetToggle] = useToggle();

  const age = dateOfBirth ? getAge({ dateOfBirth }) : undefined;
  const isAdult = age >= 18;
  const isMale = gender?.id > 0 ? gender.id === 1 : undefined;

  const bodyMeasurementsFind = useBodyMeasurementsFind({
    filter: { tenantId, userId },
  });

  const bodyMeasurementsCreate = useBodyMeasurementsCreate({
    onSuccess: resetToggle,
  });

  const bodyMeasurementsUpdate = useBodyMeasurementsUpdate({
    onSuccess: resetToggle,
  });

  const bodyMeasurementsDelete = useBodyMeasurementsDelete();

  const calculateBodyFatPercentage = ({
    tricep,
    subscapular,
    abdominal,
    suprailiac,
    thigh,
    calf,
  }) => {
    let bodyDensity;
    let bodyFatPercentage;

    if (isAdult) {
      const sumOfSkinFolds =
        Number(tricep) +
        Number(subscapular) +
        Number(abdominal) +
        Number(suprailiac) +
        Number(thigh) +
        Number(calf);

      if (isMale) {
        bodyDensity = 1.10326 - 0.00031 * age - 0.00036 * sumOfSkinFolds;
      } else {
        bodyDensity = 1.07878 - 0.00035 * age - 0.00032 * sumOfSkinFolds;
      }

      bodyFatPercentage = (495 / bodyDensity - 450).toFixed(2);
    } else {
      const sumOfSkinFolds = Number(tricep) + Number(calf);

      if (isMale) bodyFatPercentage = 0.735 * (sumOfSkinFolds + 1.0);
      else bodyFatPercentage = 0.61 * (sumOfSkinFolds + 5.1);
    }

    return parseFloat(bodyFatPercentage);
  };

  const onSubmitForm = ({ useOctiv, bodyFatPercentage, ...values }) => {
    const data = {
      ...values,
      bodyFatPercentage: useOctiv
        ? calculateBodyFatPercentage(values)
        : bodyFatPercentage,
    };

    if (toggle.type === 'create') {
      bodyMeasurementsCreate.mutate({ userId, ...data });
    } else {
      bodyMeasurementsUpdate.mutate({ id: toggle.data.id, ...data });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: bodyMeasurementsFind.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'delete':
        bodyMeasurementsDelete.mutate({ id });
        break;

      default:
        break;
    }
  };

  if (isFetchingUserTenant) return null;

  let reasonCannotCalculate;
  if (age === undefined || isMale === undefined) {
    reasonCannotCalculate = `${age === undefined ? t('dateOfBirth') : ''}${
      age === undefined && isMale === undefined ? ' & ' : ''
    }${isMale === undefined ? t('gender') : ''}`;
  }

  return (
    <>
      {(bodyMeasurementsFind.isFetching ||
        bodyMeasurementsCreate.isLoading ||
        bodyMeasurementsUpdate.isLoading ||
        bodyMeasurementsDelete.isLoading) && <ProcessingBar />}

      {toggle.isVisible ? (
        <Box isFlex={md}>
          <Image
            backgroundPosition='top'
            backgroundSize='contain'
            m={4}
            minHeight={lg ? '400px' : md ? '300px' : '250px'}
            minWidth={lg ? '308px' : md ? '231px' : '192px'}
            mt={0}
            src={Images.measurements}
          />

          <Form
            calculatedBodyFatPercentage={calculateBodyFatPercentage}
            initialValues={toggle.data}
            isAdult={isAdult}
            isLoading={
              bodyMeasurementsCreate.isLoading ||
              bodyMeasurementsUpdate.isLoading
            }
            reasonCannotCalculate={reasonCannotCalculate}
            onCancel={resetToggle}
            onSubmit={onSubmitForm}
          />
        </Box>
      ) : (
        <>
          <Button
            mb={4}
            text={t('addNewComposition')}
            onClick={() => setToggle({ type: 'create' })}
          />

          <Table
            data={bodyMeasurementsFind.data?.data || []}
            isLoading={bodyMeasurementsFind.isFetching}
            onClickAction={onClickAction}
          />
        </>
      )}
    </>
  );
};
