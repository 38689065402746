import {
  AddBookingsModal,
  EventModal,
  Navigation,
  UserMember,
  UserPackageModal,
} from 'octiv-containers';
import { AccessContext, ActiveScreenContext } from 'octiv-context';
import { ActiveScreenInitialState } from 'octiv-context/ActiveScreen';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useAddBookingsModal } from 'octiv-context/AddBookingsModal';
import { useEventModal } from 'octiv-context/EventModal';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useUserPackageModal } from 'octiv-context/UserPackageModal';
import { useLogEvent } from 'octiv-hooks';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { getStacksMergeAccounts } from './stacks/AccountMerge';
import { getStacksDiscovery } from './stacks/Discovery';
import { getStacksMember } from './stacks/Member';
import { getStacksPublic } from './stacks/Public';
import { getStacksStaff } from './stacks/Staff';
import { getStacksSuperAdmin } from './stacks/SuperAdmin';
import { getStacksUser } from './stacks/User';

const Navigator = () => {
  const { t } = useTranslation();

  const { isSignedIn, isSuperAdmin, isDiscoveryAdmin, mergeAccount } =
    useSignedInUser();
  const {
    activeUserTenantId,
    isMember,
    isStaff,
    isTrainer,
    landingScreen,
    typeId,
    tenant: { isGoCardless, isPaystack, isSepa, regionId },
  } = useActiveUserTenant();

  const hasAccess = useContext(AccessContext);
  const { pathname, search } = useLocation();

  const onLoginPath = useMemo(
    () => `${pathname}${search.length ? search : ''}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSignedIn]
  );

  let stacks;

  if (isSignedIn) {
    stacks = isSuperAdmin
      ? getStacksSuperAdmin({ t, onLoginPath })
      : isDiscoveryAdmin
      ? getStacksDiscovery({
          t,
          onRedirectPath: '/login',
          onLoginPath,
        })
      : mergeAccount
      ? getStacksMergeAccounts({
          t,
          onRedirectPath: '/login',
          onLoginPath,
        })
      : isStaff
      ? getStacksStaff({
          t,
          hasAccess,
          isGoCardless,
          isPaystack,
          isSepa,
          isTrainer,
          onLoginPath,
          regionId,
          typeId,
        })
      : isMember
      ? getStacksMember({ t, onLoginPath, hasAccess, landingScreen })
      : !activeUserTenantId
      ? getStacksUser({ onLoginPath })
      : undefined;
  }

  if (!stacks) {
    stacks = getStacksPublic({ t, onRedirectPath: '/login', onLoginPath });
  }

  // Get extra data for the active screen
  let activeScreen = useMemo(() => ({ options: ActiveScreenInitialState }), []);

  for (let index = 0; index < stacks.length; index += 1) {
    const { screens } = stacks[index];
    const screen = screens.find((el) => {
      if (
        el.path &&
        matchPath(
          {
            path: el.path,
            caseSensitive: false,
            end: true,
          },
          pathname
        )
      ) {
        return true;
      }

      return false;
    });

    if (screen) {
      activeScreen = {
        ...screen,
        options: {
          ...activeScreen.options,
          ...stacks[index].options,
          ...screen.options,
        },
      };
      break;
    }
  }

  // Log screen navigation
  useLogEvent({ isScreen: true, name: activeScreen.name });

  return (
    <ActiveScreenContext.Provider value={activeScreen.options}>
      <Navigation stacks={stacks} />

      <Routes>
        {stacks.map(({ screens }) =>
          screens.map(({ isDivider, element, path }) => {
            if (isDivider) return null;

            return <Route element={element} key={path} path={path} />;
          })
        )}
      </Routes>
    </ActiveScreenContext.Provider>
  );
};

export default () => {
  const addBookingsModal = useAddBookingsModal();
  const eventModal = useEventModal();
  const userPackageModal = useUserPackageModal();
  const memberModal = useMemberModal();

  return (
    <BrowserRouter>
      <Navigator />

      {!!addBookingsModal.eventId && <AddBookingsModal />}
      {!!eventModal.type && <EventModal />}
      {!!userPackageModal.userId && <UserPackageModal />}
      {(!!memberModal.userId || !!memberModal.userTenantId) && <UserMember />}
    </BrowserRouter>
  );
};
