import moment from 'moment';
import { Toast } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import {
  getDateTime,
  getDateYearMonthDay,
  transformApiError,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  useAttendanceRecordsCreateCheckIn,
  useAttendanceRecordsCreateCheckOut,
} from './requests/AttendanceRecords';
import {
  useClassBookingsCreate,
  useClassBookingsUpdateCancel,
} from './requests/ClassBookings';
import {
  useClassBookingWaitingCreate,
  useClassBookingWaitingUpdateLeaveWaitingList,
} from './requests/ClassBookingWaiting';
import {
  useLeadsCreateClassBooking,
  useLeadsUpdateCancelClassBooking,
} from './requests/Leads';
import useQuery from './useQuery';

const useBooking = ({
  isWidget,
  leadUserId,
  leadToken,
  leadSessions,
  event: {
    class: { location, isVirtual },
    id,
    name,
    bookings,
    bookingsCount,
    waitingList,
    bookingThresholdDate,
    bookingThresholdTime,
    cancellationThresholdDate,
    cancellationThresholdTime,
    date,
    endTime,
    limit,
  },
  onSuccessBook,
  onSuccessCancel,
}) => {
  const { t } = useTranslation();

  const code = useQuery('code');

  const { signedInUserId, healthProviderId } = useSignedInUser();
  const {
    tenant: { locations },
    isStaff,
  } = useActiveUserTenant();

  const healthProviders =
    locations.find((item) => item.id === location.id)?.healthProviders || [];

  const hasValidHealthProvider = !!healthProviders.find(
    (item) => item.id === healthProviderId
  );

  const dateNow = getDateYearMonthDay({ canFallback: true });
  const timeNow = getDateTime({ canFallback: true });

  const isToday = dateNow === date;
  const isPastEventDate = moment(dateNow).isAfter(date);
  const isPastEvent = isPastEventDate || (isToday && timeNow > endTime);
  const isPastBookingThreshold =
    isPastEventDate ||
    moment(dateNow).isAfter(bookingThresholdDate) ||
    (dateNow === bookingThresholdDate && timeNow > bookingThresholdTime);
  const isPastCancellationThreshold =
    isPastEventDate ||
    moment(dateNow).isAfter(cancellationThresholdDate) ||
    (dateNow === cancellationThresholdDate &&
      timeNow > cancellationThresholdTime);

  const booking = bookings?.find(
    (item) =>
      (item.userId === signedInUserId || item.userId === leadUserId) &&
      (item.statusId === 1 || item.statusId === 5)
  );
  const isCheckedIn = !!booking?.checkedInAt;
  const isCheckedOut = !!booking?.checkedOutAt;
  const isBooked = !!booking;
  const waitlistBooking = waitingList?.find(
    (item) =>
      (item.userId === signedInUserId || item.userId === leadUserId) &&
      item.status?.id === 'waiting'
  );
  const isWaitlist = !!waitlistBooking;

  const canBook =
    (isWidget ? !!leadToken && leadSessions && bookingsCount < limit : true) &&
    !isPastEventDate &&
    !isPastBookingThreshold &&
    !isBooked &&
    !isWaitlist;

  const canCheckIn =
    code &&
    !isVirtual &&
    isToday &&
    timeNow <= endTime &&
    !isCheckedIn &&
    isBooked;
  const canCheckOut =
    code &&
    !isVirtual &&
    isToday &&
    hasValidHealthProvider &&
    isCheckedIn &&
    isBooked &&
    !isCheckedOut;

  const canCancel = !isPastEvent && !isCheckedIn && (isBooked || isWaitlist);

  const canDropIn =
    (isWidget ? !!leadToken && leadSessions && bookingsCount < limit : true) &&
    !isBooked &&
    !isCheckedIn &&
    !isVirtual &&
    isToday &&
    timeNow > bookingThresholdTime &&
    timeNow < endTime;

  const hasAttended =
    isCheckedOut ||
    (isCheckedIn && !canCheckOut) ||
    (isBooked && isPastEvent && !canCheckOut);

  const bookingsPercentage =
    (bookingsCount / limit) * 100 > 100
      ? '100%'
      : `${(bookingsCount / limit) * 100}%`;
  const bookingsWithLimit = `${bookingsCount}/${limit}`;

  const attendanceString =
    hasAttended && isPastEvent
      ? t('youAttended')
      : isCheckedOut
      ? t('youHaveCheckedOut')
      : isCheckedIn
      ? t('youHaveCheckedIn')
      : isBooked
      ? t('youAreBookedIn')
      : isWaitlist
      ? t('youAreOnTheWaitingList')
      : canDropIn
      ? t('bookingThresholdPassed')
      : undefined;

  const useFetchBookingProps = {
    meta: {
      useOnErrorToast: false,
    },
    onSuccess: onSuccessBook,
    onError: (error) => {
      const { title, body } = transformApiError(error);

      if (body.includes('You do not have an active mandate')) {
        const url = body.split('Please sign one: ')[1];

        if (window.confirm(t('mandateInactiveCannotBook'))) {
          window.location.assign(url);
        }
      } else {
        toast.error(<Toast body={body} title={title} variant='danger' />);
      }
    },
  };

  const leadsCreateClassBooking = useLeadsCreateClassBooking({
    onSuccess: onSuccessBook,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const leadsUpdateCancelClassBooking = useLeadsUpdateCancelClassBooking({
    onSuccess: onSuccessCancel,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const classBookingsCreate = useClassBookingsCreate(useFetchBookingProps);

  const classBookingWaitingCreate =
    useClassBookingWaitingCreate(useFetchBookingProps);

  const classBookingsUpdateCancel = useClassBookingsUpdateCancel({
    onSuccess: onSuccessCancel,
  });

  const classBookingWaitingUpdateLeaveWaitingList =
    useClassBookingWaitingUpdateLeaveWaitingList({
      onSuccess: onSuccessCancel,
    });

  const attendanceRecordsCreateCheckIn = useAttendanceRecordsCreateCheckIn({
    onSuccess: onSuccessBook,
    meta: {
      onSuccessToast: { title: t('youHaveCheckedIn') },
    },
  });

  const attendanceRecordsCreateCheckOut = useAttendanceRecordsCreateCheckOut({
    onSuccess: onSuccessBook,
    meta: {
      onSuccessToast: {
        title: t('youHaveCheckedOut'),
        body: t('vitalityPointsWillReflect'),
      },
    },
  });

  const onCheckIn = () =>
    attendanceRecordsCreateCheckIn.mutate({
      code,
      classBookingId: booking.id,
    });

  const onCheckOut = () =>
    attendanceRecordsCreateCheckOut.mutate({
      code,
      classBookingId: booking.id,
    });

  const onBook = () => {
    if (canDropIn) {
      if (window.confirm(t('bookingThresholdPassedConfirm'))) {
        if (leadToken) {
          leadsCreateClassBooking.mutate({
            leadToken,
            classDateId: id,
          });
        } else {
          classBookingsCreate.mutate({
            classDateId: id,
            userId: signedInUserId,
            bookingThresholdBypass: isStaff ? 1 : 0,
          });
        }
      }
    } else if (bookingsCount >= limit) {
      if (window.confirm(`${name} ${t('classIsFullyBooked')}`)) {
        classBookingWaitingCreate.mutate({
          userId: signedInUserId,
          classDateId: id,
        });
      }
    } else if (leadToken) {
      leadsCreateClassBooking.mutate({
        leadToken,
        classDateId: id,
      });
    } else {
      classBookingsCreate.mutate({ userId: signedInUserId, classDateId: id });
    }
  };

  const onCancel = () => {
    if (
      window.confirm(
        `${
          !isWaitlist && isPastCancellationThreshold
            ? `${t('cancellingAfterThreshold')} `
            : ''
        }${t('areYouSureCancelBooking')}`
      )
    ) {
      if (leadToken) {
        leadsUpdateCancelClassBooking.mutate({
          leadToken,
          classBookingId: booking.id,
        });
      } else if (isWaitlist) {
        classBookingWaitingUpdateLeaveWaitingList.mutate({
          id: waitlistBooking.id,
        });
      } else {
        classBookingsUpdateCancel.mutate({
          id: booking.id,
        });
      }
    }
  };

  const onBookCheckInOut = () =>
    canCheckIn ? onCheckIn() : canCheckOut ? onCheckOut() : onBook();

  return {
    attendanceString,
    bookingsPercentage,
    bookingsWithLimit,
    canBook,
    canCancel,
    canCheckIn,
    canCheckOut,
    canDropIn,
    hasAttended,
    isBooked,
    isLoadingBooking:
      attendanceRecordsCreateCheckIn.isLoading ||
      attendanceRecordsCreateCheckOut.isLoading ||
      classBookingsCreate.isLoading ||
      classBookingWaitingCreate.isLoading ||
      leadsCreateClassBooking.isLoading,
    isLoadingCancelling:
      classBookingsUpdateCancel.isLoading ||
      classBookingWaitingUpdateLeaveWaitingList.isLoading ||
      leadsUpdateCancelClassBooking.isLoading,
    isPastBookingThreshold,
    isPastEvent,
    isWaitlist,
    onBookCheckInOut,
    onCancel,
  };
};

export default useBooking;
