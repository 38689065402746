import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues = {},
  isFetchingMembers,
  members,
  isCreate,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userId: isCreate
          ? undefined
          : getFullName(initialValues?.user || initialValues?.leadMember),
        dueOn: getDateYearMonthDay({
          date: initialValues.dueOn,
          canFallback: true,
        }),
        description: initialValues.description || undefined,
        amount: initialValues.amount || undefined,
      }}
      validationSchema={Yup.object().shape({
        userId: Yup.string().required(t('required')),
        dueOn: Yup.string().required(t('required')),
        description: Yup.string(),
        amount: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Field
                isDisabled={!isCreate}
                isLoading={isFetchingMembers}
                isSelect={isCreate}
                label={t('member')}
                name='userId'
                options={members.map((member) => ({
                  label: getFullName(member),
                  value: member.id,
                }))}
              />
            </Col>

            <Col md={4}>
              <Field isDate label={t('dueDate')} name='dueOn' />
            </Col>

            <Col md={4}>
              <Field label={t('amount')} name='amount' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
