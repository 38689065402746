import { Formik } from 'formik';
import { Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        content: undefined,
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            hasSubmitButton
            buttonSubmitProps={{ isLoading }}
            label={t('description')}
            name='content'
          />
        </form>
      )}
    </Formik>
  );
};
