import { Formik } from 'formik';
import moment from 'moment';
import { Alert, Button, Col, Field, Row, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  getDateYearMonthDay,
  getProratedAmount,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  tenantPackages,
  initialValues = {},
  isCashMember,
  isCreate,
  isDebitOrderMember,
  isFetchingTenantPackages,
  isFetchingUserPaymentDetails,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const useSessionsAvailable = !isCreate && initialValues.package?.typeId === 3;

  return (
    <Formik
      {...props}
      initialValues={{
        packageId: initialValues.package?.id || undefined,
        startingOn:
          getDateYearMonthDay({ date: initialValues.startDate }) || undefined,
        endingOn:
          getDateYearMonthDay({ date: initialValues.endDate }) || undefined,
        ...(useSessionsAvailable
          ? {
              sessionsAvailable:
                typeof initialValues.sessionsAvailable === 'number'
                  ? initialValues.sessionsAvailable.toString()
                  : initialValues.sessionsAvailable || undefined,
            }
          : {}),
        invoice: {
          shouldGenerate: 0,
          amount: undefined,
          paymentType: undefined,
          isSend: 0,
        },
      }}
      validationSchema={Yup.object().shape({
        packageId: Yup.number().required(t('required')),
        startingOn: Yup.string().required(t('required')),
        endingOn: Yup.string(),
        ...(useSessionsAvailable ? { sessionsAvailable: Yup.string() } : {}),
        invoice: Yup.object().shape({
          shouldGenerate: Yup.bool().required(t('required')),
          amount: Yup.string().when('shouldGenerate', {
            is: (value) => !!value,
            then: Yup.string().required(t('required')),
          }),
          paymentType: Yup.string().when('shouldGenerate', {
            is: (value) => !!value,
            then: Yup.string().required(t('required')),
          }),
          isSend: Yup.bool().when('shouldGenerate', {
            is: (value) => !!value,
            then: Yup.bool().required(t('required')),
          }),
        }),
      })}
    >
      {({ handleSubmit, setFieldValue, dirty, values }) => {
        const tenantPackage = React.useRef(undefined);
        const [defaultPeriodText, setDefaultPeriodText] =
          React.useState(undefined);

        const isLimitedPackage = tenantPackage.current?.type?.id === 3;
        const canRenderInvoicing =
          isCreate && tenantPackage?.current && values.startingOn;

        React.useEffect(() => {
          if (tenantPackage.current?.id !== values.packageId) {
            tenantPackage.current = tenantPackages?.find(
              (item) => item.id === values.packageId
            );
          }

          let defaultEndingOn;

          if (tenantPackage.current?.defaultPeriodType) {
            if (values.startingOn) {
              defaultEndingOn = getDateYearMonthDay({
                date: moment(values.startingOn).add(
                  tenantPackage.current?.defaultPeriod,
                  tenantPackage.current?.defaultPeriodType
                ),
              });
            }

            setDefaultPeriodText(
              `${t('packageDefaultPeriodIs')} ${
                tenantPackage.current?.defaultPeriod
              } ${tenantPackage.current?.defaultPeriodType}${
                defaultEndingOn
                  ? ` (${getDateReadableDayMonthYear({
                      date: defaultEndingOn,
                    })})`
                  : ''
              }`
            );
          } else {
            setDefaultPeriodText(undefined);
          }

          if (isCreate) {
            if (tenantPackage.current?.type?.id === 3) {
              setFieldValue('invoice.amount', tenantPackage.current?.price);
            } else if (values.startingOn) {
              setFieldValue(
                'invoice.amount',
                getProratedAmount({
                  date: values.startingOn,
                  amount: tenantPackage.current?.price,
                })
              );
            }

            setFieldValue('endingOn', defaultEndingOn);
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values.packageId, values.startingOn, tenantPackages]);

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Field
                  isSelect
                  doFinally={() => {
                    setFieldValue('invoice.shouldGenerate', false);
                    setFieldValue('invoice.amount', undefined);
                    setFieldValue('invoice.paymentType', undefined);
                    setFieldValue('invoice.isSend', false);
                  }}
                  helper={defaultPeriodText}
                  isDisabled={!isCreate}
                  isLoading={isFetchingTenantPackages}
                  label={t('package')}
                  name='packageId'
                  options={tenantPackages?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Col>

              <Col md={6}>
                <Field
                  isDate
                  isDisabled={!values.packageId}
                  label={t('startDate')}
                  name='startingOn'
                />
              </Col>

              <Col md={6}>
                <Field
                  isDate
                  isDisabled={!values.packageId || !values.startingOn}
                  label={`${t('endDate')} (${t('notRequired')})`}
                  name='endingOn'
                />
              </Col>

              {useSessionsAvailable && (
                <Col>
                  <Field
                    label={t('sessionsAvailable')}
                    name='sessionsAvailable'
                  />
                </Col>
              )}

              {canRenderInvoicing &&
                !isLimitedPackage &&
                (isCashMember || isDebitOrderMember) && (
                  <Col>
                    <Alert
                      subtitle={
                        isCashMember
                          ? t('packageIncludedInNextCashBatch')
                          : t('packageIncludedInNextDebitOrder')
                      }
                      variant='info'
                    />
                  </Col>
                )}

              {canRenderInvoicing && (
                <>
                  <Col>
                    <Field
                      isCheckbox
                      name='invoice.shouldGenerate'
                      options={[
                        {
                          label: isLimitedPackage
                            ? t('generateInvoiceForPackage')
                            : t('generateProratedInvoiceForPackage'),
                          value: 1,
                        },
                      ]}
                    />
                  </Col>

                  {values.invoice.shouldGenerate && (
                    <>
                      <Col>
                        <Field
                          helper={`${t('packagePriceIs')} ${toCurrency({
                            value: tenantPackage.current?.price,
                          })}${
                            !isLimitedPackage
                              ? `${t(
                                  'packagePriceSuggestedProrate'
                                )}: ${toCurrency({
                                  value: getProratedAmount({
                                    date: values.startingOn,
                                    amount: tenantPackage.current?.price,
                                  }),
                                })}`
                              : ''
                          }`}
                          label={t('invoiceAmount')}
                          name='invoice.amount'
                        />
                      </Col>

                      <Col>
                        <Text mb={1}>{t('paymentType')}</Text>

                        <Field
                          isRadio
                          canShowErrorUntouched={false}
                          isLoading={isFetchingUserPaymentDetails}
                          label={`${t('memberAlreadyPaid')}${
                            isLimitedPackage
                              ? ` (${t(
                                  'sessionsReleasedImmediately'
                                ).toLowerCase()})`
                              : ''
                          }`}
                          name='invoice.paymentType'
                          options={[
                            { label: t('cash'), value: 'cash' },
                            { label: t('card'), value: 'card' },
                            { label: t('eft'), value: 'eft' },
                          ]}
                        />
                      </Col>

                      <Col>
                        <Field
                          isRadio
                          canShowErrorUntouched={false}
                          label={`${t('memberNotYetPaid')}${
                            isLimitedPackage
                              ? ` (${t(
                                  'sessionsReleasedAfterPayment'
                                ).toLowerCase()})`
                              : ''
                          }`}
                          name='invoice.paymentType'
                          options={[
                            {
                              label: t('requestAdhocPayment'),
                              value: 'adhocPayment',
                            },
                            {
                              label: t('justGenerateInvoice'),
                              value: 'invoiceOnly',
                            },
                          ]}
                        />
                      </Col>

                      <Col>
                        <Field
                          isHorizontal
                          isRadio
                          label={t('sendInvoiceOnSubmission')}
                          name='invoice.isSend'
                          options={[
                            { label: t('yes'), value: 1 },
                            { label: t('no'), value: 0 },
                          ]}
                        />
                      </Col>
                    </>
                  )}
                </>
              )}

              <Col>
                <Button
                  isDisabled={!dirty}
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};
