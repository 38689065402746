import styled from 'styled-components';
import {
  color,
  fontFamily,
  fontSize as styledFontSize,
  layout,
  lineHeight as styledLineHeight,
  space,
  textAlign,
} from 'styled-system';

const StyledText = styled('p').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![
      'color',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'letterSpacing',
      'pointerEvents',
      'textDecoration',
    ].includes(prop) && defaultValidatorFn(prop),
})`
  transition: color 150ms ease;
  margin: 0;

  line-height: ${({ lineHeight, fontSize }) =>
    lineHeight ||
    (fontSize ? Number(fontSize?.replace('rem', '')) * 1.3 : 1)}rem;
  font-weight: ${({ isBold }) => (isBold ? 500 : 400)};
  font-style: ${({ isItalic }) => (isItalic ? 'italic' : 'normal')}
    ${({ isUpperCase }) => isUpperCase && 'text-transform: uppercase'};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration}`};
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents}`};

  ${({ theme, onClick, to, colorHover = 'primary' }) =>
    (onClick || to) &&
    `&:hover {
      cursor: pointer;
      color: ${theme.colors[colorHover]};
  }`};

  ${color};
  ${fontFamily};
  ${styledFontSize};
  ${layout}
  ${styledLineHeight}
  ${space};
  ${textAlign};
`;

export default StyledText;
