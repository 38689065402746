import { useAccess } from 'octiv-context/Access';
import {
  useTenantSettingsFindCoronavirus,
  useTenantSettingsUpdateCoronavirus,
} from 'octiv-hooks/requests/TenantSettings';
import React from 'react';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const { setAccess } = useAccess();

  const tenantSettingsFindCoronavirus = useTenantSettingsFindCoronavirus();
  const tenantSettingsUpdateCoronavirus = useTenantSettingsUpdateCoronavirus({
    onSuccess: (response) => setAccess({ settingsCoronavirus: response }),
  });

  const handleSubmit = (values) =>
    tenantSettingsUpdateCoronavirus.mutate(values);

  return (
    <Setting>
      {!tenantSettingsFindCoronavirus.isFetching && (
        <Form
          initialValues={tenantSettingsFindCoronavirus.data}
          isLoading={tenantSettingsUpdateCoronavirus.isLoading}
          onSubmit={handleSubmit}
        />
      )}
    </Setting>
  );
};
