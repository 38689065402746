import { Box, Icon, Table, VaccinationIcon } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import {
  getDateReadableDayMonthYear,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MemberTableActions from '../shared/MemberTableActions';

export default ({ data, setSelected, onClickAction, query, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('firstName'),
        accessor: 'name',
        sortBy: 'name',
        Cell: ({
          row: {
            original: { name, userTenant },
          },
        }) => (
          <Box isFlex alignItems='center'>
            <VaccinationIcon
              {...userTenant?.coronavirusVaccinationDetails}
              mr={2}
            />

            {userTenant?.isHighRisk ? (
              <Icon color='warning' mr={2} name='warning' />
            ) : null}
            {name}
          </Box>
        ),
      },
      {
        Header: t('surname'),
        accessor: 'surname',
        sortBy: 'surname',
      },
      {
        Header: t('email'),
        accessor: 'email',
        sortBy: 'email',
      },
      {
        Header: t('packages'),
        accessor: 'userTenant.userPackages',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value
            ? value
                .map(
                  (item) =>
                    `${item.package.name} (${item.sessionsAvailableText})`
                )
                .join(', ')
            : '',
      },
      {
        Header: t('contractEnd'),
        id: 'contractEndDate',
        accessor: 'userTenant.userContract',
        sortBy: 'contract_ending_at',
        Cell: ({ value }) =>
          getDateReadableDayMonthYear({ date: value?.endingAt }),
      },
      {
        Header: t('lastModified'),
        accessor: 'updatedAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({ row }) => (
          <MemberTableActions row={row} onClickAction={onClickAction} />
        ),
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(!hasAccess.featureMembersActions ? ['actions'] : []),
      ...(query.userTenantStatusId !== 4 ? ['updatedAt'] : []),
    ],
    [hasAccess.featureMembersActions, query.userTenantStatusId]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'name',
        desc: false,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      isPaginationAllHidden
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      sortBy={sortBy}
      onSelect={hasAccess.featureMembersActions ? setRowSelected : undefined}
      onSelectAll={
        hasAccess.featureMembersActions ? setAllRowsSelected : undefined
      }
    />
  );
};
