import { Button, Modal, ProcessingBar } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useAddressesCreate,
  useAddressesFind,
  useAddressesUpdate,
} from 'octiv-hooks/requests/Addresses';
import { useFinancesDeletePaymentGatewaysPayfastDetails } from 'octiv-hooks/requests/Finances';
import { useHealthProvidersFind } from 'octiv-hooks/requests/HealthProviders';
import { useLocationCategoriesFind } from 'octiv-hooks/requests/LocationCategories';
import {
  useLocationsCreate,
  useLocationsDelete,
  useLocationsFind,
  useLocationsUpdate,
} from 'octiv-hooks/requests/Locations';
import { useTenantsFindById } from 'octiv-hooks/requests/Tenants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormAddress from './FormAddress';
import FormLocation from './FormLocation';
import TableLocations from './TableLocations';

export default ({ tenantId, timezones }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const { data: healthProviders, isFetching: isFetchingHealthProviders } =
    useHealthProvidersFind({ filter: { isActive: true } });

  const { data: locationCategories, isFetching: isFetchingLocationCategories } =
    useLocationCategoriesFind();

  const [locationId, setLocationId] = React.useState(undefined);

  // #region Locations
  const {
    data: locations,
    isFetching: isFetchingLocations,
    refetch: getLocationsRequest,
  } = useLocationsFind({
    filter: { tenantId },
    include:
      'healthProviders,locationAddress,locationPaymentGateways.settings,payFastPaymentGateway,timezone,category',
    paging: { perPage: -1 },
  });

  const locationsCreate = useLocationsCreate({
    onSuccess: () => {
      getLocationsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const tenantsFind = useTenantsFindById(
    { id: tenantId },
    {
      enabled: true,
    }
  );

  const locationsUpdate = useLocationsUpdate({
    onSuccess: () => {
      getLocationsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isLoading: isDeletingLocation, mutate: deleteLocationRequest } =
    useLocationsDelete({
      onSuccess: getLocationsRequest,
      meta: {
        useOnSuccessToast: true,
      },
    });
  // #endregion

  // #region Addresses
  const addressesFind = useAddressesFind(
    {
      filter: {
        locationId,
      },
    },
    {
      enabled: !!locationId,
    }
  );

  const addressesCreate = useAddressesCreate({
    enaled: !!locationId,
    onSuccess: () => resetToggle(),
  });
  const addressesUpdate = useAddressesUpdate({
    enaled: !!locationId,
    onSuccess: () => resetToggle(),
  });
  // #endregion

  const {
    isLoading: isDeletingLocationPayfastDetails,
    mutate: deleteLocationPayfastDetailsRequest,
  } = useFinancesDeletePaymentGatewaysPayfastDetails({
    onSuccess: getLocationsRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      locationsCreate.mutate({
        tenantId,
        ...values,
        timezoneId:
          values?.timezoneId === undefined
            ? tenantsFind?.data?.timezoneId
            : values?.timezoneId,
      });
    } else {
      locationsUpdate.mutate({
        id: toggle.data.id,
        ...values,
        timezoneId:
          values?.timezoneId === undefined ? null : values?.timezoneId,
      });
    }
  };

  const onPutPostAddressRequest = (values) => {
    const isCreate = addressesFind?.data?.data?.length === 0;
    const address = addressesFind?.data?.data?.[0];

    if (isCreate) {
      addressesCreate.mutate({ tenantId, ...values, locationId });
    } else {
      addressesUpdate.mutate({
        id: address?.id,
        tenantId,
        ...values,
        locationId,
      });
    }
  };

  const onClickAction = ({ action, id }) => {
    const location = locations?.data.find((item) => item.id === id);
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: location,
        });
        setLocationId(location.id);
        break;

      case 'address':
        setToggle({
          type: 'address',
          data: location,
        });
        setLocationId(location.id);
        break;

      case 'deletePayfastDetails':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteLocationPayfastDetailsRequest({ locationId: id });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteLocationRequest({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(isDeletingLocationPayfastDetails || isDeletingLocation) && (
        <ProcessingBar />
      )}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={
            toggle.type === 'address'
              ? `${t('manageAddress')}`
              : `${
                  toggle.type === 'create' ? t('create') : t('update')
                } Location`
          }
          onClose={() => {
            setLocationId(undefined);
            resetToggle();
          }}
        >
          {['create', 'update']?.includes(toggle?.type) && (
            <FormLocation
              healthProviders={healthProviders || []}
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isFetchingHealthProviders={isFetchingHealthProviders}
              isFetchingLocationCategories={isFetchingLocationCategories}
              isLoading={locationsCreate.isLoading || locationsUpdate.isLoading}
              locationCategories={locationCategories?.data || []}
              locationId={toggle?.data?.id}
              timezones={timezones}
              onSubmit={onPutPostRequest}
            />
          )}
          <br />
          {toggle?.type === 'address' && (
            <FormAddress
              initialValues={
                locations?.data?.find((l) => l.id === locationId)?.address
              }
              isCreate={toggle.type === 'create'}
              isLoading={
                addressesFind.isFetching ||
                addressesUpdate.isLoading ||
                addressesCreate.isLoading
              }
              onSubmit={onPutPostAddressRequest}
            />
          )}
        </Modal>
      )}

      <Button
        mb={4}
        text='Add New Location'
        onClick={() => setToggle({ type: 'create' })}
      />

      <TableLocations
        data={locations?.data || []}
        isLoading={isFetchingLocations}
        onClickAction={onClickAction}
      />
    </>
  );
};
