import { Container } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useReportsFindExport } from 'octiv-hooks/requests/Reports';
import { downloadFile } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    canAccessAllLocations,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({});

  const { isFetching: isFetchingReport } = useReportsFindExport(
    {
      filter: query,
    },
    {
      enabled: query.reportType !== undefined,
      timeout: 300000,
      onSuccess: downloadFile,
    }
  );

  const onFormSubmit = (values) => {
    setQuery(values);
  };

  return (
    <Container
      appBarProps={{
        title: t('exports'),
        breadcrumbs: [t('reports')],
      }}
      isLoading={isFetchingReport}
    >
      <Form
        canAccessAllLocations={canAccessAllLocations}
        isLoading={isFetchingReport}
        locationOptions={locationOptionsAll}
        selectedLocation={selectedLocation}
        onSubmit={onFormSubmit}
      />
    </Container>
  );
};
