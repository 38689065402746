import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useAffiliatesFind = (input, options) => {
  const { paging = {} } = input || {};

  return useQuery(
    ['tenants', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/affiliates`,
        params: {
          ...paging,
        },
      }),
    options
  );
};

export const useAffiliatesCreateLink = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/affiliates/link`,
        data: {
          affiliateId: data.affiliateId,
          tenantIds: data.tenantIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAffiliatesCreateUnlink = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/affiliates/unlink`,
        data: {
          affiliateId: data.affiliateId,
          tenantIds: data.tenantIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};
