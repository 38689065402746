export default [
  {
    name: 'Accept Contract Terms And Conditions',
    description: 'Sent to member.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Contract Link', value: '[link]' },
    ],
  },
  {
    name: 'Class Booking: Booked From Waiting List',
    description:
      'Sent to staff. Occurs when a member is booked into a class from the waiting list.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Waiting Member Name', value: '[waiting_member_name]' },
      { label: 'Waiting Member Surname', value: '[waiting_member_surname]' },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: Booked From Waiting List (Created By Member)',
    description:
      'Sent to member. Occurs when a member is booked into a class from the waiting list.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: Booked From Waiting List (Created By Trainer)',
    description:
      'Sent to member. Occurs when a member is booked into a class from the waiting list.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: Cancelled',
    description: 'Sent to staff. Occurs when a member cancels a booking.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      {
        label: 'Cancel Threshold Message',
        value: '[cancel_threshold_message]',
      },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: Cancelled',
    description: 'Sent to member. Occurs when a member cancels a booking.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      {
        label: 'Cancel Threshold Message',
        value: '[cancel_threshold_message]',
      },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: Confirmation',
    description: 'Sent to member. Occurs when a class is booked.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Booking: No Show',
    description: 'Sent to member. Occurs when a booking is marked as no show.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Time', value: '[class_time]' },
      { label: 'Class Booking Date', value: '[class_booking_date]' },
    ],
  },
  {
    name: 'Class Update: Discontinued',
    description: 'Sent to member. Occurs when a class is deleted.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Date', value: '[class_date]' },
      { label: 'Class Time', value: '[class_time]' },
    ],
  },
  {
    name: 'Class Update: Discontinued',
    description: 'Sent to staff. Occurs when a class is deleted.',
    placeholders: [
      { label: 'Coach Name', value: '[coach_name]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Date', value: '[class_date]' },
      { label: 'Class Time', value: '[class_time]' },
    ],
  },
  {
    name: 'Class Update: Discontinued Recurring Class Day',
    description:
      'Sent to member. Occurs when a day is removed from a recurring class and their booking is cancelled.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Old Class Name', value: '[old_class_name]' },
      { label: 'Old Class Date', value: '[old_class_date]' },
      { label: 'Old Class Time', value: '[old_class_time]' },
    ],
  },
  {
    name: 'Class Update: Schedule Changed',
    description: 'Sent to member. Occurs when a class schedule is changed.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Name', value: '[member_surname]' },
      { label: 'Class Name', value: '[class_name]' },
      { label: 'Class Date', value: '[class_date]' },
      { label: 'Class Old Time', value: '[class_old_time]' },
      { label: 'Class New Time', value: '[class_new_time]' },
      { label: 'Class Coach', value: '[class_coach]' },
    ],
  },
  {
    name: 'Contract Expiry',
    description:
      "Sent to member. Occurs when a member's contract is reaching it's expiry date.",
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Member Email', value: '[member_email]' },
      { label: 'Member Mobile', value: '[member_mobile]' },
      {
        label: 'Expiry Notification Days',
        value: '[expiry_notification_days]',
      },
      { label: 'Expiry Date', value: '[expiry_date]' },
    ],
  },
  {
    name: 'Contract Expiry',
    description:
      "Sent to staff. Occurs when a member's contract is reaching it's expiry date.",
    placeholders: [
      { label: 'Coach Name', value: '[coach_name]' },
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Member Email', value: '[member_email]' },
      { label: 'Member Mobile', value: '[member_mobile]' },
      {
        label: 'Expiry Notification Days',
        value: '[expiry_notification_days]',
      },
      { label: 'Expiry Date', value: '[expiry_date]' },
    ],
  },
  {
    name: 'Lead Member Registration Confirmation',
    description:
      'Sent to lead. Occurs when a new lead registers via the lead widget.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Lead Member Registration Confirmation (Demo)',
    description:
      'Sent to lead. Occurs when a new lead registers via the lead demo widget.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Member Birthday',
    description: "Sent to staff. Occurs on a member's birthday.",
    placeholders: [
      { label: 'Coach Name', value: '[coach_name]' },
      { label: 'Coach Surname', value: '[coach_surname]' },
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Member Birthday',
    description: "Sent to member. Occurs on a member's birthday.",
    placeholders: [{ label: 'Member Name', value: '[member_name]' }],
  },
  {
    name: 'New Drop-In',
    description:
      'Sent to staff. Occurs when a drop-in is created via the drop-in widget.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Member Email', value: '[member_email]' },
      { label: 'Member Mobile', value: '[member_mobile]' },
      { label: 'Member DOB', value: '[member_dob]' },
      { label: 'Member Notes', value: '[member_notes]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Sessions Purchased', value: '[sessions_purchased]' },
    ],
  },
  {
    name: 'New Drop-In',
    description:
      'Sent to drop-in. Occurs when a drop-in is created via the drop-in widget.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Sessions Purchased', value: '[sessions_purchased]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'Booking Link', value: '[booking_link]' },
    ],
  },
  {
    name: 'Send: Contract',
    description: 'Sent to member. Occurs when a contract is sent.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Send: Invoice',
    description: 'Sent to member. Occurs when an invoice is sent.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Send: Statement',
    description: 'Sent to member. Occurs when a statement is sent.',
    placeholders: [{ label: 'Message', value: '[message]' }],
  },
  {
    name: 'Send: Waiver',
    description: 'Sent to member. Occurs when a waiver is sent.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'Link', value: '[link]' },
    ],
  },
  {
    name: 'Send: Waiver With Attachment',
    description:
      'Sent to member. Occurs when a waiver is sent with an attachment.',
    placeholders: [
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
    ],
  },
  {
    name: 'Sign Up: 1 Month',
    description: 'Sent to member. Occurs 1 month after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Sign Up: 1 Year',
    description: 'Sent to member. Occurs 1 year after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Sign Up: 2 Weeks',
    description: 'Sent to member. Occurs 2 weeks after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Sign Up: 3 Months',
    description: 'Sent to member. Occurs 3 months after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Sign Up: 6 Months',
    description: 'Sent to member. Occurs 6 months after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Sign Up: Immediately',
    description: 'Sent to member. Occurs immediately after a member signs up.',
    placeholders: [
      { label: 'Facility Name', value: '[facility_name]' },
      { label: 'Location Name', value: '[location_name]' },
      { label: 'User Name', value: '[user_name]' },
      { label: 'User Surname', value: '[user_surname]' },
    ],
  },
  {
    name: 'Task: Assigned',
    description: 'Sent to task assignee. Occurs when a new task is created.',
    placeholders: [
      { label: 'Coach Name', value: '[coach_name]' },
      { label: 'Coach Surname', value: '[coach_surname]' },
      { label: 'Task Name', value: '[task_name]' },
      { label: 'Task Time', value: '[task_time]' },
      { label: 'Task Date', value: '[task_date]' },
      { label: 'Task Description', value: '[task_description]' },
    ],
  },
  {
    name: 'Task: Removed as Assigned',
    description:
      'Sent to task assignee. Occurs when an assignee gets removed from a task.',
    placeholders: [
      { label: 'Coach Name', value: '[coach_name]' },
      { label: 'Coach Surname', value: '[coach_surname]' },
      { label: 'Task Name', value: '[task_name]' },
      { label: 'Task Time', value: '[task_time]' },
      { label: 'Task Date', value: '[task_date]' },
      { label: 'Task Description', value: '[task_description]' },
    ],
  },
  {
    name: 'Two Weeks Without Booking',
    description:
      'Sent to member. Occurs when a member does not make a booking for two weeks.',
    placeholders: [{ label: 'User Name', value: '[user_name]' }],
  },
  {
    name: 'Workout Result: New Comment',
    description:
      'Sent to member. Occurs when a new comment is made on a workout result.',
    placeholders: [
      { label: 'Capture User Name', value: '[wodcapture_user_name]' },
      { label: 'Capture User Surname', value: '[wodcapture_user_surname]' },
      { label: 'Member Name', value: '[member_name]' },
      { label: 'Member Surname', value: '[member_surname]' },
      { label: 'WOD Name', value: '[wod_name]' },
      { label: 'Comment', value: '[comment]' },
    ],
  },
];
