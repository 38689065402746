import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { measures } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  measureId,
  initialValues: { score, ...initialValues },
  ...props
}) => {
  const { t } = useTranslation();

  const splitScore = score ? score?.split?.('.') : [];

  const updatedInitialValues = { ...initialValues };
  const validationSchema = {};

  const measure = measures.find((item) => item.measureId === measureId);

  measure.fields.map(({ short, decimal, max }, index) => {
    updatedInitialValues[short] = decimal
      ? score || undefined
      : score
      ? splitScore[index]
      : undefined;

    if (decimal) {
      validationSchema[short] = max
        ? Yup.number()
            .typeError(t('mustBeANumber'))
            .max(max)
            .required(t('required'))
        : Yup.number().typeError(t('mustBeANumber')).required(t('required'));
    } else {
      validationSchema[short] = max
        ? Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            .max(max, t('mustBeLessThan', { number: max }))
            .required(t('required'))
        : Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            .required(t('required'));
    }

    return null;
  });

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={updatedInitialValues}
      validationSchema={Yup.object().shape({
        ...validationSchema,
        isRx: Yup.string().required(t('required')),
        note: Yup.string(),
      })}
    >
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Row>
          {measure.fields.map(({ short, long }) => (
            <Col key={short} sm={6}>
              <Field label={long} name={short} />
            </Col>
          ))}

          <Col>
            <Field
              isHorizontal
              isRadio
              name='isRx'
              options={[
                { label: t('rx'), value: 1 },
                { label: t('scaled'), value: 0 },
              ]}
            />
          </Col>

          <Col>
            <Field label={t('note')} name='note' />
          </Col>

          <Col>
            <Button
              isDisabled={!dirty}
              isLoading={isSubmitting}
              text={t('submit')}
              onClick={handleSubmit}
            />
          </Col>
        </Row>
      )}
    </Formik>
  );
};
