import { Box, Button, Container } from 'octiv-components';
import { usePaging } from 'octiv-hooks';
import {
  useWorkoutResultExercisesFindUnvalidatedBenchmarks,
  useWorkoutResultExercisesUpdateApproveAllBenchmarks,
  useWorkoutResultExercisesUpdateApproveBenchmark,
  useWorkoutResultExercisesUpdateRejectBenchmark,
} from 'octiv-hooks/requests/WorkoutResultExercises';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [paging, setPaging] = usePaging();

  const {
    data: memberBenchmarks,
    isFetching: isFetchingMemberBenchmarks,
    refetch: getMemberBenchmarksRequest,
  } = useWorkoutResultExercisesFindUnvalidatedBenchmarks({
    paging,
  });

  const {
    isLoading: isUpdatingMemberBenchmarksApproveAll,
    mutate: putMemberBenchmarksApproveAllRequest,
  } = useWorkoutResultExercisesUpdateApproveAllBenchmarks({
    onSuccess: getMemberBenchmarksRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingMemberBenchmarkApprove,
    mutate: putMemberBenchmarkApproveRequest,
  } = useWorkoutResultExercisesUpdateApproveBenchmark({
    onSuccess: getMemberBenchmarksRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingMemberBenchmarkReject,
    mutate: putMemberBenchmarkRejectRequest,
  } = useWorkoutResultExercisesUpdateRejectBenchmark({
    onSuccess: getMemberBenchmarksRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'approve':
        putMemberBenchmarkApproveRequest({ id });
        break;

      case 'reject':
        putMemberBenchmarkRejectRequest({ id });
        break;

      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('validateBenchmarks'),
        breadcrumbs: [t('workouts')],
        children: memberBenchmarks?.data?.[0] && (
          <Box ml='auto'>
            <Button
              text={t('approveAll')}
              onClick={() => putMemberBenchmarksApproveAllRequest({})}
            />
          </Box>
        ),
      }}
      isLoading={
        isFetchingMemberBenchmarks ||
        isUpdatingMemberBenchmarksApproveAll ||
        isUpdatingMemberBenchmarkApprove ||
        isUpdatingMemberBenchmarkReject
      }
    >
      <Table
        isPaginationAllHidden
        data={memberBenchmarks?.data || []}
        isLoading={isFetchingMemberBenchmarks}
        paging={memberBenchmarks?.paging}
        setPaging={setPaging}
        onClickAction={onClickAction}
      />
    </Container>
  );
};
