import { Table, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: 'Currencies',
        accessor: 'currencies',
        Cell: ({ value }) =>
          value ? value.map((item) => item.name).join(', ') : '',
      },
      {
        Header: 'Timezones',
        accessor: 'timezones',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value ? value.map((item) => item.name).join(', ') : '',
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              ...(query.isActive
                ? [{ label: t('deactivate'), value: 'deactivate' }]
                : [
                    { label: t('activate'), value: 'activate' },
                    { label: t('delete'), value: 'delete' },
                  ]),
            ]}
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, query.isActive, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
