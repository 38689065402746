import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        id: initialValues.id,
        isLateCancellation: false,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        isLateCancellation: Yup.bool().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            hasAlternateBackground
            isRadio
            name='isLateCancellation'
            options={[
              {
                label: t('cancelDisregardThreshold'),
                labelSubText: t('sessionWillBeReimbursed'),
                value: false,
              },
              {
                label: t('cancelAdhereToThreshold'),
                labelSubText: t('sessionWillPossiblyBeReimbursed'),
                value: true,
              },
            ]}
          />

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
