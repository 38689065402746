import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useLocationPaymentGatewaysFindGoCardlessDetails = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['locationPaymentGateways', 'findGoCardlessDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-payment-gateways/go-cardless-details`,
        params: { tenantId },
      }),
    options
  );
};

export const useLocationPaymentGatewaysFindDebitOrderNetcashDetails = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['locationPaymentGateways', 'findDebitOrderNetcashDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-payment-gateways/debit-order/netcash-details`,
        params: { tenantId },
      }),
    options
  );
};

export const useLocationPaymentGatewaysUpdateDebitOrderNetcashDetails = (
  options
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { settingsId, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/location-payment-gateways/debit-order/${data.locationPaymentGatewayId}/netcash-details`,
        data: {
          merchantAccountNumber: data.merchantAccountNumber,
          accountServiceKey: data.accountServiceKey,
          debitOrderServiceKey: data.debitOrderServiceKey,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationPaymentGateways'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationPaymentGatewaysFindDebitOrderThreePeaksDetails = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['locationPaymentGateways', 'findDebitOrderThreePeaksDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-payment-gateways/debit-order/three-peaks-details`,
        params: {
          tenantId,
        },
      }),
    options
  );
};

export const useLocationPaymentGatewaysUpdateDebitOrderThreePeaksDetails = (
  options
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/location-payment-gateways/debit-order/${id}/three-peaks-details`,
        data: { devId: data.devId, devToken: data.devToken, cref: data.cref },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationPaymentGateways'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationPaymentGatewaysFindAdhoc = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['locationPaymentGateways', 'findAdhoc', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-payment-gateways/adhoc-details`,
        params: {
          tenantId,
          locationId: input.locationId,
          isActive: input.isActive,
        },
      }),
    options
  );
};

export const useLocationPaymentGatewaysCreateAdhoc = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/location-payment-gateways/adhoc-details`,
        data: {
          tenantId: data.tenantId || tenantId,
          locationId: data.locationId,
          paymentGatewayId: data.paymentGatewayId,
          merchantAccountNumber: data.merchantAccountNumber,
          payNowServiceKey: data.payNowServiceKey,
          secretKey: data.secretKey,
          publicKey: data.publicKey,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          accountType: data.accountType,
          bankCode: data.bankCode,
          documentType: data.documentType,
          documentNumber: data.documentNumber,
          notifyEmailAddress: data.notifyEmailAddress,
          isForSignUp: Number(data.isForSignUp),
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationPaymentGateways'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationPaymentGatewaysUpdateAdhoc = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { settingsId, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/location-payment-gateways/adhoc-details/${settingsId}`,
        data: {
          tenantId: data.tenantId || tenantId,
          merchantAccountNumber: data.merchantAccountNumber,
          payNowServiceKey: data.payNowServiceKey,
          secretKey: data.secretKey,
          publicKey: data.publicKey,
          accountName: data.accountName,
          accountNumber: data.accountNumber,
          accountType: data.accountType,
          bankCode: data.bankCode,
          documentType: data.documentType,
          documentNumber: data.documentNumber,
          notifyEmailAddress: data.notifyEmailAddress,
          isForSignUp: Number(data.isForSignUp),
          isActive: data.isActive,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationPaymentGateways'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationPaymentGatewaysFindDebitOrderSepaDetails = (
  input,
  options
) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['locationPaymentGateways', 'findDebitOrderSepaDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-payment-gateways/debit-order/sepa-details`,
        params: {
          filter: {},
          tenantId,
        },
        include: input.include,
      }),
    options
  );
};

export const useLocationPaymentGatewaysUpdateDebitOrderSepaDetails = (
  options
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { settingsId, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/location-payment-gateways/debit-order/${settingsId}/sepa-details`,
        data: {
          creditorId: data.creditorId,
          creditorIban: data.creditorIban,
          creditorBic: data.creditorBic,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationPaymentGateways'] });

      options?.onSuccess?.(...res);
    },
  });
};
