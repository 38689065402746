import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useTagsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['tags', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/tags`,
        params: {
          filter: {
            type: filter.type,
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useTagsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/tags`,
        data: {
          name: data.name,
          type: data.type,
          tenantId: data.tenantId || tenantId,
          locationId: data.locationId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTagsFindTypes = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['tags', 'findTypes', input],
    () =>
      request({
        method: 'get',
        url: `/api/tags/types`,
        params: { filter: { tenantId: filter.tenantId || tenantId } },
      }),
    options
  );
};

export const useTagsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/tags/${id}`,
        data: { name: data.name },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTagsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/tags/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tags'] });

      options?.onSuccess?.(...res);
    },
  });
};
