import moment from 'moment';
import { Box, Icon, Text } from 'octiv-components';
import {
  getDateDay,
  getDateRange,
  getDateReadableDay,
} from 'octiv-utilities/Functions';
import React from 'react';

export default ({
  activeDate,
  onChange,
  textDayProps,
  textMonthProps,
  ...props
}) => {
  const maximumDate = moment()
    .endOf('isoWeek')
    .subtract(1, 'week')
    .add(105, 'days')
    .toDate();
  const startDate = moment(activeDate).startOf('isoWeek');
  const endDate = moment(activeDate).endOf('isoWeek');
  const dates = getDateRange({ start: startDate, end: endDate });

  const canNextWeek = moment(endDate)
    .add(1, 'day')
    .isBefore(maximumDate, 'day');

  const onChangeWeek = ({ next = true }) => {
    const newActiveDate = next
      ? moment(activeDate).add(1, 'week')
      : moment(activeDate).subtract(1, 'week');

    onChange({ date: newActiveDate.startOf('isoWeek') });
  };

  return (
    <Box isFlex {...props}>
      <Box
        isFlex
        alignItems='center'
        justifyContent='center'
        width={8}
        onClick={() => onChangeWeek({ next: false })}
      >
        <Icon color='primary' name='arrow_back' />
      </Box>

      {dates.map((date) => {
        const isActive = moment(date).isSame(activeDate, 'day');
        const isDisabled = moment(date).isAfter(maximumDate, 'day');

        return (
          <Box
            isFlex
            alignItems='center'
            flex={1}
            flexDirection='column'
            key={date}
            opacity={isDisabled ? 0.5 : 1}
            onClick={isDisabled ? undefined : () => onChange({ date })}
          >
            <Text color='grey1' mb={1} variant='tiny'>
              {getDateReadableDay({ date })}
            </Text>

            <Box
              hasRadius
              isFlex
              alignItems='center'
              bg={isActive ? 'primary' : 'transparent'}
              justifyContent='center'
              size={8}
              sizeRadius={4}
            >
              <Text isBold color={isActive ? 'button' : undefined}>
                {getDateDay({ date })}
              </Text>
            </Box>
          </Box>
        );
      })}

      <Box
        isFlex
        alignItems='center'
        justifyContent='center'
        opacity={canNextWeek ? 1 : 0.5}
        width={8}
        onClick={canNextWeek ? onChangeWeek : undefined}
      >
        <Icon color={canNextWeek ? 'primary' : 'grey1'} name='arrow_forward' />
      </Box>
    </Box>
  );
};
