import { Table } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getEventString,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'class',
        Cell: ({ value }) => value?.location.name,
      },
      {
        Header: t('class'),
        Cell: ({
          row: {
            original: {
              class: { name, startTime, endTime },
            },
          },
        }) =>
          getEventString({
            name,
            startTime,
            endTime,
          }),
      },
      {
        Header: t('trainer'),
        accessor: 'classDate.instructor',
        Cell: ({ row: { original } }) => {
          const instructor =
            original.classDate?.instructor || original?.class.instructor;

          return getFullName(instructor);
        },
      },
      {
        Header: t('date'),
        accessor: 'classDate.date',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
