import { Container } from 'octiv-components';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import { getDateYear } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import LocationCategories from './locationCategories';
import Overview from './overview';
import Regions from './regions';

export default () => {
  const { t } = useTranslation();

  const [query, setQuery] = useState({
    regionId: undefined,
    tenantId: undefined,
    year: getDateYear({ canFallback: true }),
  });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
  });

  const { data: tenants, isFetching: isFetchingTenants } = useTenantsFind({
    paging: { perPage: -1 },
  });

  return (
    <Container
      appBarProps={{
        title: t('dashboard'),
      }}
    >
      <FormQuery
        initialValues={query}
        isFetchingRegions={isFetchingRegions}
        isFetchingTenants={isFetchingTenants}
        regions={regions?.data || []}
        tenants={tenants?.data || []}
        onSubmit={setQuery}
      />

      <Overview query={query} />

      <Regions query={query} />

      <LocationCategories query={query} />
    </Container>
  );
};
