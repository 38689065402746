import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const FormQuestionnaire = ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  const radioProps = {
    isRadio: true,
    isHorizontal: true,
    boxContainerProps: { mb: 4 },
    options: [
      { label: t('yes'), value: true },
      { label: t('no'), value: false },
    ],
  };

  return (
    <Formik
      {...props}
      initialValues={{
        temperature: initialValues.temperature || undefined,
        hasCough: initialValues.hasCough === 1,
        hasDifficultyBreathing: initialValues.hasDifficultyBreathing === 1,
        hasFever: initialValues.hasFever === 1,
        hasBeenInContactExperiencing:
          initialValues.hasBeenInContactExperiencing === 1,
        hasBeenInContactPositive: initialValues.hasBeenInContactPositive === 1,
        hasTravelled: initialValues.hasTravelled === 1,
        travelledWhere: initialValues.travelledWhere || undefined,
      }}
      validationSchema={Yup.object().shape({
        temperature: Yup.string().required(t('required')),
        hasCough: Yup.bool().required(t('required')),
        hasDifficultyBreathing: Yup.bool().required(t('required')),
        hasFever: Yup.bool().required(t('required')),
        hasBeenInContactExperiencing: Yup.bool().required(t('required')),
        hasBeenInContactPositive: Yup.bool().required(t('required')),
        hasTravelled: Yup.bool().required(t('required')),
        travelledWhere: Yup.string().when('hasTravelled', {
          is: (value) => value,
          then: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, values: { hasTravelled } }) => (
        <form onSubmit={handleSubmit}>
          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('covidTemperature')}
            name='temperature'
          />

          <Field {...radioProps} label={t('covidHasCough')} name='hasCough' />

          <Field
            {...radioProps}
            label={t('covidHasDifficultyBreathing')}
            name='hasDifficultyBreathing'
          />

          <Field {...radioProps} label={t('covidHasFever')} name='hasFever' />

          <Field
            {...radioProps}
            label={t('covidHasBeenInContactExperiencing')}
            name='hasBeenInContactExperiencing'
          />

          <Field
            {...radioProps}
            label={t('covidHasBeenInContactPositive')}
            name='hasBeenInContactPositive'
          />

          <Field
            {...radioProps}
            label={t('covidHasTravelled')}
            name='hasTravelled'
          />

          {hasTravelled && (
            <Field
              boxContainerProps={{ mb: 4 }}
              label={t('covidTravelledWhere')}
              name='travelledWhere'
            />
          )}

          <Button isLoading={isLoading} text={t('submit')} type='submit' />
        </form>
      )}
    </Formik>
  );
};

export default FormQuestionnaire;
