/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

const useWindowFocus = ({ onFocus, onBlur, onMountUnfocused }) => {
  useEffect(() => {
    if (onFocus) {
      if (typeof document !== 'undefined' && document.hasFocus()) {
        onFocus();
      } else if (onMountUnfocused) {
        onMountUnfocused();
      }

      window.addEventListener('focus', onFocus, false);

      return () => window.removeEventListener('focus', onFocus);
    }

    return window.removeEventListener('focus', onFocus);
  }, [onFocus]);

  useEffect(() => {
    if (onBlur) {
      window.addEventListener('blur', onBlur, false);

      return () => window.removeEventListener('blur', onBlur);
    }

    return window.removeEventListener('blur', onBlur);
  }, [onBlur]);
};

export default useWindowFocus;
