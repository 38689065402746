import moment from 'moment';
import {
  Box,
  Col,
  Container,
  DateStrip,
  Row,
  Text,
  Workout,
} from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useWorkoutsFind } from 'octiv-hooks/requests/Workouts';
import useWidgetTenant from 'octiv-hooks/useWidgetTenant';
import {
  getDateYearMonthDay,
  sanitizeWorkout,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetHeader from '../components/Header';
import FormQuery from './FormQuery';

export default () => {
  const { t, i18n } = useTranslation();

  const { xsDown } = useMediaQuery();

  const [workout, setWorkout] = useState(undefined);
  const [date, setDate] = useState(getDateYearMonthDay({ canFallback: true }));
  const [programmeId, setProgrammeId] = useState(undefined);

  const { tenantId, locationOptions, ...tenant } = useWidgetTenant();

  const workoutsFind = useWorkoutsFind(
    {
      filter: {
        tenantId,
        programmeId,
        startsAfter: date,
        endsBefore: getDateYearMonthDay({
          date: moment(date).add(1, 'day'),
        }),
        useWorkoutThreshold: 1,
      },
      paging: {
        perPage: -1,
      },
    },
    {
      enabled: !!programmeId,
      onSuccess: ({ data }) => {
        setWorkout(sanitizeWorkout({ workout: data?.[0] }));
      },
    }
  );

  const programmesFind = useProgrammesFind(
    {
      filter: {
        tenantId,
        isActive: 1,
      },
      paging: {
        perPage: -1,
      },
    },
    {
      enabled: !!tenantId,
      onSuccess: ({ data }) => {
        if (data?.[0]?.id) setProgrammeId(data?.[0].id);
      },
    }
  );

  return (
    <Container isLoading={workoutsFind.isFetching}>
      <WidgetHeader tenant={tenant} />

      <DateStrip
        activeDate={date}
        mb={6}
        onChange={(value) => setDate(getDateYearMonthDay({ date: value.date }))}
      />

      <Box mb={4}>
        <Row>
          <Col lg={3} md={4}>
            <FormQuery
              initialValues={{ programmeId }}
              isFetchingProgrammes={programmesFind.isFetching}
              programmes={programmesFind.data?.data || []}
              onSubmit={(values) => setProgrammeId(values.programmeId)}
            />
          </Col>

          <Col lg={9} md={8}>
            <Box isFlex alignItems='center' minHeight={xsDown ? undefined : 14}>
              <Text
                isBold
                mr='auto'
                variant={xsDown ? 'subheading' : 'heading'}
              >
                {moment(date)
                  .locale(i18n.resolvedLanguage)
                  .format('dddd DD MMMM YYYY')}
              </Text>
            </Box>
          </Col>
        </Row>
      </Box>

      {!workout && !workoutsFind.isFetching && !programmesFind.isFetching && (
        <Text color='grey1'>{t('workoutUnavailable')}</Text>
      )}

      <Workout
        bg={undefined}
        data={workout}
        isLoading={workoutsFind.isFetching}
        showProgramme={false}
        textProps={{ variant: 'subheading' }}
      />
    </Container>
  );
};
