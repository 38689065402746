import { InlineUser, Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({
          row: {
            original: {
              user: { name, surname, dateOfBirth },
            },
          },
        }) => <InlineUser user={{ name, surname, dateOfBirth }} />,
      },
      {
        Header: t('exercise'),
        accessor: 'exercise',
      },
      {
        Header: t('score'),
        accessor: 'score',
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
