import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const FormNoShowAndCancellationFee = ({ type, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        fee: 0,
      }}
      validationSchema={Yup.object().shape({
        fee: Yup.number().required(t('required')),
      })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            isText
            label={t(`${type?.trim()}Description`)}
            min={1}
            name='fee'
          />
          <Button
            isLoading={isLoading}
            marginTop='1rem'
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};

export default FormNoShowAndCancellationFee;
