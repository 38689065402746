import { Images } from 'octiv-assets';
import { Box, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { isLocalStorageAvailable } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AppLoading = ({ children }) => {
  const { t } = useTranslation();
  const { isSigningIn, isSignedIn } = useSignedInUser();
  const { isSettingUserTenant, isSettingLocation } = useActiveUserTenant();

  const [isVisibleLocalStorageMessage, setIsVisibleLocalStorageMessage] =
    React.useState(false);

  React.useEffect(() => {
    if (!isLocalStorageAvailable()) {
      setIsVisibleLocalStorageMessage(true);
    }
  }, []);

  const isLoading =
    isSigningIn || (isSignedIn && (isSettingUserTenant || isSettingLocation));

  return (
    <>
      <Box
        isFlex
        alignItems='center'
        bg='grey7'
        bottom={0}
        flexDirection='column'
        justifyContent='center'
        left={0}
        opacity={isLoading ? 1 : 0}
        pointerEvents={isLoading ? undefined : 'none'}
        position='absolute'
        right={0}
        top={0}
        transition='opacity 200ms linear'
        zIndex={50000}
      >
        <img alt={t('octivLogo')} src={Images.logoLight} width={250} />

        {isVisibleLocalStorageMessage && (
          <Box maxWidth='580px' mx='auto' px='16px'>
            <Text
              as='h1'
              lineHeight='normal'
              mb={2}
              mt={5}
              textAlign='center'
              variant={'heading'}
            >
              {t('localStorageAccessDisabled')}
            </Text>

            <Text color='grey1' textAlign='center'>
              {t('localStorageAccessDisabledDescription')}
            </Text>
          </Box>
        )}
      </Box>

      {!isLoading && children}
    </>
  );
};

export default AppLoading;
