import _ from 'lodash';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, ProcessingSpinner, Text } from '..';

const RadioCheckbox = ({
  boxContainerProps,
  hasColumns,
  hasSelectAll,
  isArray,
  isCheckbox,
  isDisabled,
  isHorizontal = false,
  isLoading,
  isRadio,
  onChange,
  options = [],
  renderLabel,
  renderRight,
  value,
}) => {
  const { t } = useTranslation();

  const { lg, md } = useMediaQuery();

  const canUseColumns = hasColumns && md && options.length > 8;
  const isCheckboxArray =
    isCheckbox && (isArray || isLoading || options.length > 1);
  const icons = isRadio
    ? ['radio_button_checked', 'radio_button_unchecked']
    : ['check_box', 'check_box_outline_blank'];

  const onClick = ({ isActive, newValue }) => {
    if (isCheckboxArray) {
      onChange(
        value?.includes(newValue)
          ? value?.filter((val) => val !== newValue)
          : value?.concat(newValue)
      );
    } else {
      onChange(
        isActive ? (typeof newValue === typeof true ? !newValue : '') : newValue
      );
    }
  };

  const renderOptions = () => {
    const render = (optionsToRender) =>
      optionsToRender.map((option, index) => {
        const isActive = isCheckboxArray
          ? value?.includes(option.value)
          : value === option.value;
        const hasLabelSubText = !!option.labelSubText;

        return (
          <Box
            isFlex
            alignItems='center'
            key={`${index.toString()}`}
            mr={isHorizontal ? 3 : undefined}
            mt={
              isRadio || isCheckboxArray ? (hasLabelSubText ? 2 : 1) : undefined
            }
            {...boxContainerProps}
            onClick={
              isDisabled || (isRadio && isActive)
                ? undefined
                : () => onClick({ isActive, newValue: option.value })
            }
          >
            <Icon
              isActive={isActive}
              mr={1}
              name={isActive ? icons[0] : icons[1]}
            />

            <Box mt={hasLabelSubText ? 1 : undefined}>
              <Text>{option.label}</Text>

              {hasLabelSubText && (
                <Text color='grey1' variant='caption'>
                  {option.labelSubText}
                </Text>
              )}
            </Box>

            {renderRight && renderRight()}
          </Box>
        );
      });

    if (canUseColumns) {
      const numberOfOptions = options.length;
      const numberOfColumns =
        numberOfOptions > 24 && lg ? 4 : numberOfOptions > 16 ? 3 : 2;
      const numberOfItemsPerColumn = Math.ceil(
        numberOfOptions / numberOfColumns
      );

      const columns = [];
      const chunks = _.chunk(options, numberOfItemsPerColumn);
      for (let index = 0; index < numberOfColumns; index += 1) {
        columns.push(
          <Box flex={1} key={index.toString()} ml={index > 0 ? 4 : undefined}>
            {render(chunks[index])}
          </Box>
        );
      }

      return columns;
    }

    return render(options);
  };

  return (
    <>
      {(isRadio || isCheckboxArray) && renderLabel()}

      {isLoading ? (
        <ProcessingSpinner mt={1} />
      ) : (
        <>
          {hasSelectAll && (
            <Box isFlex mb={2} mt={1}>
              <Button
                hasBorder
                hasRadiusLeft
                color='grey1'
                scale='small'
                text={t('selectAll')}
                onClick={() => onChange(options.map((item) => item.value))}
              />

              <Button
                hasBorder
                hasRadiusRight
                color='grey1'
                scale='small'
                text={t('deselectAll')}
                onClick={() => onChange([])}
              />
            </Box>
          )}

          <Box isFlex={isHorizontal || canUseColumns}>{renderOptions()}</Box>
        </>
      )}

      {!isRadio && !isCheckboxArray && renderLabel()}
    </>
  );
};

export default RadioCheckbox;
