import { Box, Text } from 'octiv-components';
import { ActiveScreenContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMediaQuery } from 'octiv-hooks';
import React, { useContext } from 'react';

const AppBar = ({
  boxContainerProps,
  breadcrumbs,
  children,
  subtitle,
  textBreadcrumbsProps,
  textSubtitleProps,
  textTitleProps,
  title,
}) => {
  const { xsDown } = useMediaQuery();
  const { isVisibleNavigation } = useContext(ActiveScreenContext);
  const { activeUserTenantId } = useActiveUserTenant();

  return (
    <Box
      isFlex
      minHeight={19}
      pl={!!activeUserTenantId && isVisibleNavigation && xsDown ? 8 : undefined}
      py={4}
      {...boxContainerProps}
    >
      {title && (
        <Box>
          <Box isFlex>
            {breadcrumbs &&
              breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={breadcrumb}>
                  <Text
                    color='grey1'
                    lineHeight='normal'
                    {...textBreadcrumbsProps}
                  >
                    {breadcrumb}
                  </Text>

                  {index !== breadcrumbs.length - 1 && (
                    <Text
                      color='grey2'
                      lineHeight='normal'
                      mx={1}
                      {...textBreadcrumbsProps}
                    >
                      /
                    </Text>
                  )}
                </React.Fragment>
              ))}
          </Box>

          <Text
            as='h1'
            lineHeight='normal'
            mt={breadcrumbs ? undefined : 1}
            variant={xsDown ? 'heading' : 'display'}
            {...textTitleProps}
          >
            {title}
          </Text>

          {subtitle && (
            <Text
              color='grey1'
              lineHeight='normal'
              mt={1}
              {...textSubtitleProps}
            >
              {subtitle}
            </Text>
          )}
        </Box>
      )}

      {children}
    </Box>
  );
};

export default AppBar;
