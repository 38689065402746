import { FieldArray, Formik } from 'formik';
import { Box, Button, Col, Field, Icon, Row, Text } from 'octiv-components';
import { getDateYearMonthDay, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const newLineItem = {
  description: undefined,
  discriminator: undefined,
  code: undefined,
  unitPrice: undefined,
  quantity: 1,
};

export default ({
  initialValues = {},
  invoiceItemTypes,
  isCreate,
  isFetchingInvoiceItemTypes,
  isFetchingMembers,
  isLoading,
  locationOptions,
  members,
  onSelectLocation,
  toCurrency,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        locationId: undefined,
        userTenantId: isCreate
          ? undefined
          : getFullName(
              initialValues.user ||
                initialValues?.nonMember ||
                initialValues?.leadMember
            ),
        dueOn: getDateYearMonthDay({
          date: initialValues.dueOn,
          canFallback: true,
        }),
        description: initialValues.description || undefined,
        status: initialValues.status || 'unpaid',
        lineItems: initialValues.items
          ? initialValues.items.map((invoiceItem) => ({
              description: invoiceItem.description || undefined,
              discriminator: invoiceItem.discriminator || undefined,
              code: invoiceItem.code || undefined,
              unitPrice: invoiceItem.unitPrice || undefined,
              quantity:
                invoiceItem.quantity >= 0 ? invoiceItem.quantity.toString() : 1,
            }))
          : [{ ...newLineItem }],
        isSend: initialValues.isSend || false,
      }}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        userTenantId: Yup.string().required(t('required')),
        dueOn: Yup.string().required(t('required')),
        description: Yup.string(),
        status: Yup.string().required(t('required')),
        lineItems: Yup.array().of(
          Yup.object().shape({
            description: Yup.string().required(t('required')),
            discriminator: Yup.string().required(t('required')),
            code: Yup.string().nullable(),
            unitPrice: Yup.string().required(t('required')),
            quantity: Yup.string().required(t('required')),
          })
        ),
        isSend: isCreate ? Yup.bool().required(t('required')) : Yup.bool(),
      })}
    >
      {({ handleSubmit, setFieldValue, values: { lineItems } }) => {
        const invoiceTotal = lineItems.reduce(
          (a, b) => a + (b.unitPrice || 0) * b.quantity,
          0
        );

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              {isCreate && (
                <Col md={3}>
                  <Field
                    isSelect
                    doFinally={(newLocationId) => {
                      setFieldValue('userTenantId', undefined);
                      onSelectLocation({ locationId: newLocationId });
                    }}
                    label={t('memberLocation')}
                    name='locationId'
                    options={locationOptions}
                  />
                </Col>
              )}

              <Col md={3}>
                <Field
                  isDisabled={!isCreate}
                  isLoading={isFetchingMembers}
                  isSelect={isCreate}
                  label={t('member')}
                  name='userTenantId'
                  options={members.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  }))}
                />
              </Col>

              <Col md={3}>
                <Field isDate label={t('dueDate')} name='dueOn' />
              </Col>

              {isCreate && (
                <Col md={3}>
                  <Field
                    isSelect
                    label={t('initialStatus')}
                    name='status'
                    options={[
                      {
                        label: t('unpaid'),
                        value: 'unpaid',
                      },
                      {
                        label: t('paid'),
                        value: 'paid',
                      },
                    ]}
                  />
                </Col>
              )}

              <Col>
                <Field
                  as='textarea'
                  label={t('description')}
                  name='description'
                />
              </Col>

              {isCreate && (
                <Col>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('sendInvoiceOnSubmission')}
                    name='isSend'
                    options={[
                      {
                        label: t('yes'),
                        value: true,
                      },
                      {
                        label: t('no'),
                        value: false,
                      },
                    ]}
                  />
                </Col>
              )}
            </Row>

            <Text mb={2} mt={4} variant='subheading'>
              {t('invoiceLineItems')}
            </Text>

            <FieldArray
              name='lineItems'
              render={(arrayHelpers) =>
                lineItems.map((lineItem, index) => (
                  <Box
                    isFlex
                    alignItems='center'
                    key={`${index.toString()}`}
                    mb={4}
                  >
                    <Box flex={1}>
                      <Row>
                        <Col md={3}>
                          <Field
                            label={t('description')}
                            name={`lineItems[${index}].description`}
                          />
                        </Col>

                        <Col md={3}>
                          <Field
                            isSelect
                            isLoading={isFetchingInvoiceItemTypes}
                            label={t('itemType')}
                            name={`lineItems[${index}].discriminator`}
                            options={[
                              {
                                label: t('membershipFee'),
                                value: 'membership',
                              },
                              {
                                label: t('discount'),
                                value: 'discount',
                              },
                              {
                                label: t('storeItem'),
                                value: 'pos',
                              },
                              {
                                label: t('merchandise'),
                                value: 'merchandise',
                              },
                              {
                                label: t('supplement'),
                                value: 'supplement',
                              },
                              {
                                label: t('beverages'),
                                value: 'beverages',
                              },
                              {
                                label: t('other'),
                                value: 'other',
                              },
                              ...(invoiceItemTypes
                                ? invoiceItemTypes.map((item) => ({
                                    label: item.name,
                                    value: item.name,
                                  }))
                                : []),
                            ]}
                          />
                        </Col>

                        <Col md={2}>
                          <Field
                            label={t('code')}
                            name={`lineItems[${index}].code`}
                          />
                        </Col>

                        <Col md={2}>
                          <Field
                            label={t('unitPrice')}
                            name={`lineItems[${index}].unitPrice`}
                          />
                        </Col>

                        <Col md={2}>
                          <Field
                            label={t('quantity')}
                            name={`lineItems[${index}].quantity`}
                          />
                        </Col>
                      </Row>
                    </Box>

                    {lineItems.length > 1 && (
                      <Icon
                        color='danger'
                        ml={4}
                        name='delete'
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    )}
                  </Box>
                ))
              }
            />

            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={t('addLineItem')}
                onClick={() =>
                  setFieldValue('lineItems', [...lineItems, { ...newLineItem }])
                }
              />

              <Button isLoading={isLoading} text={t('submit')} type='submit' />

              <Text ml='auto' variant='heading'>
                {`${t('total')}: ${toCurrency({
                  value: invoiceTotal,
                })}`}
              </Text>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
