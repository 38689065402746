import { Formik } from 'formik';
import { Col, Field, Row, Text } from 'octiv-components';
import { getMonths } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ amount, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        month: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4} sm={6} xs={8}>
              <Field
                canSubmitOnChange
                isSelect
                label={t('month')}
                name='month'
                options={getMonths({ withValue: true }).map((month) => ({
                  label: month.name,
                  value: month.value,
                }))}
              />
            </Col>

            <Col lg={9} md={8} sm={6} xs={4}>
              <Text mt={4} variant='subheading'>
                {`${t('total')}: ${amount === undefined ? '-' : amount}`}
              </Text>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
