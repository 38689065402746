import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        name: undefined,
        surname: undefined,
        genderId: undefined,
        dateOfBirth: undefined,
        mobile: undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        genderId: Yup.string(),
        dateOfBirth: Yup.date()
          .min(moment('01/01/1900').toDate(), t('invalidDate'))
          .max(moment().toDate(), t('invalidDate')),
        mobile: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} xl={4}>
              <Field label={t('firstName')} name='name' />
            </Col>

            <Col md={6} xl={4}>
              <Field label={t('surname')} name='surname' />
            </Col>

            <Col md={6} xl={4}>
              <Field label={t('mobileNumber')} name='mobile' />
            </Col>

            <Col md={6} xl={4}>
              <Field
                isSelect
                label={t('gender')}
                name='genderId'
                options={genderOptions}
              />
            </Col>

            <Col md={6} xl={4}>
              <Field isDate label={t('dateOfBirth')} name='dateOfBirth' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
