import { Table } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getEventString,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('class'),
        id: 'class',
        Cell: ({
          row: {
            original: {
              class: { name, startTime, endTime },
              classDate,
            },
          },
        }) => {
          const classStartTime = classDate?.startTime || startTime;
          const classEndTime = classDate?.endTime || endTime;

          return getEventString({
            name,
            startTime: classStartTime,
            endTime: classEndTime,
          });
        },
      },
      {
        Header: t('date'),
        accessor: 'classDate',
        Cell: ({ row: { original } }) => {
          const currentClass = original?.classDate || original?.class;
          return getDateReadableDayMonthYear({ date: currentClass.date });
        },
      },
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ row: { original } }) => original?.class?.location?.name,
      },
      {
        Header: t('trainer'),
        accessor: 'classTrainer',
        Cell: ({ row: { original } }) => {
          const currentInstructor =
            original?.classDate?.instructor || original?.class?.instructor;
          return getFullName(currentInstructor);
        },
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
