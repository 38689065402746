import { Card } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LeadsManage } from '../..';

export default () => {
  const { t } = useTranslation();

  return (
    <Card textTitleProps={{ to: '/leads/manage' }} title={t('pendingLeads')}>
      <LeadsManage isCompactView />
    </Card>
  );
};
