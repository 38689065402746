import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  isPublicContext,
  onClickForgotPassword,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        email: undefined,
        ...(isPublicContext ? { password: undefined } : {}),
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        ...(isPublicContext
          ? { password: Yup.string().required(t('required')) }
          : {}),
      })}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                hasSubmitButton={!isPublicContext}
                isLoading={isLoading}
                label={t('emailAddress')}
                name='email'
                type='email'
              />
            </Col>

            {isPublicContext && (
              <Col md={6}>
                <Field
                  autoComplete
                  hasSubmitButton
                  helper={t('forgotPassword')}
                  label={t('password')}
                  name='password'
                  textHelperProps={{
                    onClick: () => onClickForgotPassword(values),
                  }}
                  type='password'
                />
              </Col>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
