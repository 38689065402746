import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const usePasswordCreateForget = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/password/forget`,
        data: { email: data.email },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['password'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePasswordCreateReset = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/password/reset`,
        data: {
          email: data.email,
          token: data.token,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['password'] });

      options?.onSuccess?.(...res);
    },
  });
};
