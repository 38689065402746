import { BulkActions } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { userStatus } from 'octiv-utilities/Constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const MemberBulkActions = ({
  selectedData,
  onClickAction,
  queryStatusId,
  ...props
}) => {
  const { t } = useTranslation();
  const hasAccess = useContext(AccessContext);

  return (
    <BulkActions
      {...props}
      data={selectedData}
      options={[
        {
          icon: 'article',
          title: t('generateInvoice'),
          value: 'invoiceGenerate',
        },
        {
          icon: 'description',
          title: t('generateContract'),
          value: 'contractGenerate',
        },
        ...(hasAccess.containerComms
          ? [
              {
                icon: 'forum',
                title: t('sendComm'),
                value: 'sendComm',
              },
            ]
          : []),
        {
          icon: 'description',
          title: t('sendContract'),
          value: 'sendContract',
        },
        {
          icon: 'assignment',
          title: t('sendWaiver'),
          value: 'sendWaiverLatest',
        },
        {
          icon: 'receipt_long',
          title: t('sendStatement'),
          value: 'sendStatement',
        },
        {
          icon: 'money',
          title: t('changePaymentType'),
          value: 'paymentType',
        },
        {
          icon: 'fitness_center',
          title: t('changeProgramme'),
          value: 'changeProgramme',
        },
        {
          icon: 'person_add_alt',
          title: t('activate'),
          value: 'activate',
          color: 'success',
        },
        {
          icon: 'person_off',
          title: t('placeOnHold'),
          value: 'placeOnHold',
          color: 'warning',
        },
        {
          icon: 'person_add_disabled',
          title: t('suspend'),
          value: 'suspend',
          color: 'warning',
        },
        ...(queryStatusId === 4
          ? [
              {
                icon: 'delete',
                title: t('delete'),
                value: 'delete',
                color: 'danger',
              },
            ]
          : [
              {
                icon: 'person_remove',
                title: t('deactivate'),
                value: 'deactivate',
                color: 'danger',
              },
            ]),
        {
          icon: 'warning',
          title: t('removeHighRisk'),
          value: 'removeHighRisk',
          color: 'success',
        },
        {
          icon: 'warning',
          title: t('markHighRisk'),
          value: 'markHighRisk',
          color: 'warning',
        },
        ...([userStatus.ACTIVE, userStatus.PENDING].includes(queryStatusId)
          ? [
              {
                icon: 'forum',
                title: t('sendWelcomeEmail'),
                value: 'sendWelcomeEmail',
                color: 'success',
              },
            ]
          : []),
      ]}
      onClick={({ value }) => onClickAction({ isBulk: true, action: value })}
    />
  );
};

export default MemberBulkActions;
