import { Box, Image, Table, Text, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'createdOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('customer'),
        accessor: 'purchaser',
        Cell: ({ value }) => (
          <Box isFlex alignItems='center'>
            {value.image && (
              <Image hasRadius mr={2} size={8} src={value.profileImage} />
            )}
            {value?.name && <Text as='span'>{value?.name}</Text>}
          </Box>
        ),
      },
      {
        Header: t('items'),
        accessor: 'cartSize',
      },
      {
        Header: t('total'),
        accessor: 'cartAmount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id, status, invoiceId },
          },
        }) =>
          status !== 'cancelled' ? (
            <Tooltip
              options={[
                {
                  label: t('view'),
                  value: 'view',
                },
                {
                  label: t('edit'),
                  value: 'update',
                },
                {
                  label: t('cancel'),
                  value: 'cancel',
                },
              ]}
              onClick={({ value }) =>
                onClickAction({ action: value, id, invoiceId })
              }
            />
          ) : null,
      },
    ],
    [onClickAction, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
