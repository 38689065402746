import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  benchmarks,
  isFetchingBenchmarks,
  isFetchingRegions,
  isLoading,
  locationOptions,
  regions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        exerciseId: undefined,
        regionId: undefined,
        locationId: locationOptions[0].value,
        genderId: undefined,
        canCompare: false,
        regionIdCompare: undefined,
        locationIdCompare: locationOptions[0].value,
        genderIdCompare: undefined,
      }}
      validationSchema={Yup.object().shape({
        exerciseId: Yup.string().required(t('required')),
        regionId: Yup.string(),
        locationId: Yup.string(),
        genderId: Yup.string(),
        canCompare: Yup.bool().required(t('required')),
        regionIdCompare: Yup.string(),
        locationIdCompare: Yup.string(),
        genderIdCompare: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        dirty,
        values: {
          canCompare,
          regionId,
          regionIdCompare,
          locationId,
          locationIdCompare,
        },
      }) => {
        const isRegionVisible =
          locationId === locationOptions[0].value &&
          locationIdCompare === locationOptions[0].value;
        const isLocationVisible =
          regionId === undefined && regionIdCompare === undefined;

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <Field
                  hasAlternateBackground
                  isSelect
                  isLoading={isFetchingBenchmarks}
                  label={t('exercise')}
                  name='exerciseId'
                  options={benchmarks.map((benchmark) => ({
                    label: benchmark.name,
                    value: benchmark.id,
                  }))}
                />
              </Col>

              {isRegionVisible && (
                <Col md={3}>
                  <Field
                    hasAlternateBackground
                    isSelect
                    isLoading={isFetchingRegions}
                    label={t('region')}
                    name='regionId'
                    options={[
                      { label: t('all'), value: undefined },
                      ...regions.map((region) => ({
                        label: region.name,
                        value: region.id,
                      })),
                    ]}
                  />
                </Col>
              )}

              {isLocationVisible && (
                <Col md={3}>
                  <Field
                    hasAlternateBackground
                    isSelect
                    label={t('location')}
                    name='locationId'
                    options={locationOptions}
                  />
                </Col>
              )}

              <Col md={3}>
                <Field
                  hasAlternateBackground
                  isSelect
                  label={t('gender')}
                  name='genderId'
                  options={[
                    { label: t('all'), value: undefined },
                    { label: t('male'), value: 1 },
                    { label: t('female'), value: 2 },
                  ]}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Field
                  isCheckbox
                  boxContainerProps={{ my: 2 }}
                  name='canCompare'
                  options={[{ label: t('compareResults'), value: true }]}
                />
              </Col>

              {canCompare && (
                <>
                  {isRegionVisible && (
                    <Col md={3}>
                      <Field
                        hasAlternateBackground
                        isSelect
                        isLoading={isFetchingRegions}
                        label={t('region')}
                        name='regionIdCompare'
                        options={[
                          { label: t('all'), value: undefined },
                          ...regions.map((region) => ({
                            label: region.name,
                            value: region.id,
                          })),
                        ]}
                      />
                    </Col>
                  )}

                  {isLocationVisible && (
                    <Col md={3}>
                      <Field
                        hasAlternateBackground
                        isSelect
                        label={t('location')}
                        name='locationIdCompare'
                        options={locationOptions}
                      />
                    </Col>
                  )}

                  <Col md={3}>
                    <Field
                      hasAlternateBackground
                      isSelect
                      label={t('gender')}
                      name='genderIdCompare'
                      options={[
                        { label: t('all'), value: undefined },
                        { label: t('male'), value: 1 },
                        { label: t('female'), value: 2 },
                      ]}
                    />
                  </Col>
                </>
              )}
            </Row>

            <Row>
              <Col>
                <Button
                  isDisabled={!dirty}
                  isLoading={isLoading}
                  text={t('search')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};
