import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UserTenantsFormLinkUser = ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        email: undefined,
        password: undefined,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .email(t('invalidEmail'))
          .required(t('required')),
        password: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            label={t('emailAddress')}
            name='email'
            type='email'
          />

          <Field
            autoComplete
            boxContainerProps={{ mb: 4 }}
            label={t('password')}
            name='password'
            type='password'
          />

          <Button isLoading={isLoading} text={t('submit')} type='submit' />
        </form>
      )}
    </Formik>
  );
};

export default UserTenantsFormLinkUser;
