import 'react-app-polyfill/stable';
import 'octiv-utilities/Shims';
import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import 'firebase/analytics';
import 'octiv-config/i18n';

import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { QueryClientProvider } from 'octiv-components';
import FirebaseConfig from 'octiv-config/Firebase';
import SentryConfig from 'octiv-config/Sentry';
import { AppLoading, ErrorBoundary, RemoteConfig } from 'octiv-containers';
import { AccessContextProvider } from 'octiv-context/Access';
import { ActiveUserTenantContextProvider } from 'octiv-context/ActiveUserTenant';
import { AddBookingsModalContextProvider } from 'octiv-context/AddBookingsModal';
import { EventModalContextProvider } from 'octiv-context/EventModal';
import { MemberModalContextProvider } from 'octiv-context/MemberModal';
import { SignedInUserContextProvider } from 'octiv-context/SignedInUser';
import { ThemeContextProvider } from 'octiv-context/Theme';
import { UserPackageModalContextProvider } from 'octiv-context/UserPackageModal';
import { AppNavigator } from 'octiv-navigation';
import { isProduction } from 'octiv-utilities/Constants';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { toast, ToastContainer } from 'react-toastify';

firebase.initializeApp(FirebaseConfig);
const analytics = firebase.analytics();
analytics.setAnalyticsCollectionEnabled(isProduction);

Sentry.init(SentryConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider>
    <ThemeContextProvider>
      <RemoteConfig>
        <SignedInUserContextProvider>
          <ActiveUserTenantContextProvider>
            <AppLoading>
              <AccessContextProvider>
                <MemberModalContextProvider>
                  <EventModalContextProvider>
                    <AddBookingsModalContextProvider>
                      <UserPackageModalContextProvider>
                        <ErrorBoundary>
                          <ToastContainer
                            closeButton={false}
                            icon={false}
                            position={toast.POSITION.BOTTOM_LEFT}
                          />

                          <AppNavigator />
                        </ErrorBoundary>
                      </UserPackageModalContextProvider>
                    </AddBookingsModalContextProvider>
                  </EventModalContextProvider>
                </MemberModalContextProvider>
              </AccessContextProvider>
            </AppLoading>
          </ActiveUserTenantContextProvider>
        </SignedInUserContextProvider>
      </RemoteConfig>
    </ThemeContextProvider>
  </QueryClientProvider>
);
