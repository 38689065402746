import { BulkActions } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PackageBulkActions = ({ selectedData, onClickAction, ...props }) => {
  const { t } = useTranslation();

  return (
    <BulkActions
      {...props}
      data={selectedData}
      options={[
        {
          icon: 'auto_delete',
          title: t('lateCancellationFee'),
          value: 'lateCancellationFee ',
          color: 'success',
        },
        {
          icon: 'visibility_off',
          title: t('noShowFee'),
          value: 'noShowFee',
          color: 'success',
        },
      ]}
      onClick={({ value }) => onClickAction({ isBulk: true, action: value })}
    />
  );
};

export default PackageBulkActions;
