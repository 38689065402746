import { Container, ToggleTabs } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateYear } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Accounts from './accounts';
import FormQuery from './FormQuery';
import Leads from './leads';
import Members from './members';
import Scheduling from './scheduling';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    year: getDateYear({ canFallback: true }),
  });

  return (
    <Container
      appBarProps={{
        title: t('dashboard'),
        breadcrumbs: [t('reports')],
      }}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <ToggleTabs
        hasRadiusTop
        bg='grey6'
        mt={4}
        options={[
          {
            label: t('accounts'),
            children: <Accounts query={query} />,
          },
          {
            label: t('scheduling'),
            children: <Scheduling query={query} />,
          },
          {
            label: t('members'),
            children: <Members query={query} />,
          },
          {
            label: t('leads'),
            children: <Leads query={query} />,
          },
        ]}
      />
    </Container>
  );
};
