import { Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
  toCapital,
  toCase,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setSelected, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { setMemberModal } = useMemberModal();
  const { toCurrency } = useActiveUserTenant();
  const hasAccess = useContext(AccessContext);

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('code'),
        accessor: 'code',
      },
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                ? () => setMemberModal({ userTenantId: value?.userTenant?.id })
                : null
            }
          >
            {getFullName(value)}
          </Text>
        ),
      },
      {
        Header: t('description'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => (
          <Text
            as='span'
            color={
              value === 'pending' || value === 'submitted'
                ? 'warning'
                : value === 'credited'
                ? 'info'
                : value === 'paid'
                ? 'success'
                : value === 'unpaid'
                ? 'danger'
                : undefined
            }
          >
            {toCapital({ value: t(value) })}
          </Text>
        ),
      },
      {
        Header: t('dueDate'),
        accessor: 'dueOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('paymentType'),
        accessor: 'payments',
        Cell: ({ value }) =>
          value ? toCase({ value: value[value.length - 1].type }) : '',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, setMemberModal, hasAccess, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
