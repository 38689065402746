import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  onDownloadFile,
  initialValues = {},
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        id: initialValues?.id || undefined,
        status: initialValues?.status || undefined,
        file: initialValues?.file || undefined,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        status: Yup.string().required(t('required')),
        file: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3}>
              <Field
                isSelect
                label={t('status')}
                name='status'
                options={[
                  {
                    label: t('unvaccinated'),
                    value: 'unvaccinated',
                  },
                  {
                    label: t('vaccinated'),
                    value: 'vaccinated',
                  },
                  {
                    label: t('boosted'),
                    value: 'boosted',
                  },
                ]}
              />
            </Col>

            <Col>
              <Field
                isDrop
                label={t('fileCertificate')}
                name='file'
                onDownload={onDownloadFile}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
