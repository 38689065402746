import { Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { toCase } from 'octiv-utilities/Functions';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickClassesAttended, query, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const paymentTypes = useMemo(
    () => (data[0]?.paymentTypes ? Object.keys(data[0].paymentTypes) : []),
    [data]
  );

  const paymentTags = useMemo(
    () => (data[0]?.paymentTags ? Object.keys(data[0].paymentTags) : []),
    [data]
  );

  const purchaseMethods = useMemo(
    () =>
      data[0]?.purchaseMethods ? Object.keys(data[0].purchaseMethods) : [],
    [data]
  );

  const getCountAndTotal = useCallback(
    ({ mappableData, parentKey, canSetCase = true }) =>
      mappableData.map((item) => ({
        Header:
          parentKey !== 'paymentTags'
            ? t(item)
            : canSetCase
            ? toCase({
                value: item,
                isCamelCase: true,
              })
            : item,
        accessor: `${parentKey}.${item}`,
        Cell: ({ value }) =>
          value?.count
            ? `${value.count} - ${toCurrency({ value: value.total })}`
            : '',
      })),
    [toCurrency, t]
  );

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('package'),
        accessor: 'package.name',
      },
      {
        Header: t('price'),
        accessor: 'package.price',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('purchaseCount'),
        accessor: 'count',
      },
      {
        Header: t('potentialRevenue'),
        id: 'potentialRevenue',
        Cell: ({ row: { original } }) => {
          const {
            package: { price },
            count,
          } = original;

          return toCurrency({ value: price * count });
        },
      },
      {
        Header: t('revenue'),
        accessor: 'totalPackageRevenue',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('classesAttended'),
        accessor: 'classAttended',
        Cell: ({ value, row: { original } }) =>
          value ? (
            <Text
              as='span'
              color='primary'
              onClick={(e) => {
                e.stopPropagation();
                onClickClassesAttended({ package: original.package });
              }}
            >
              {value}
            </Text>
          ) : (
            0
          ),
      },
      ...getCountAndTotal({
        mappableData: paymentTypes,
        parentKey: 'paymentTypes',
      }),
      ...getCountAndTotal({
        mappableData: paymentTags,
        parentKey: 'paymentTags',
        canSetCase: false,
      }),
      ...getCountAndTotal({
        mappableData: purchaseMethods,
        parentKey: 'purchaseMethods',
      }),
    ],
    [
      getCountAndTotal,
      toCurrency,
      onClickClassesAttended,
      paymentTypes,
      paymentTags,
      purchaseMethods,
      t,
    ]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(query.paymentType
        ? paymentTypes
            .filter((value) => value !== query.paymentType)
            .map((paymentType) => `paymentTypes.${paymentType}`)
        : []),
    ],
    [query.paymentType, paymentTypes]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
