import { Formik } from 'formik';
import { Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default (props) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        isActive: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            canSubmitOnChange
            hasAlternateBackground
            isHorizontal
            isRadio
            label={t('status')}
            name='isActive'
            options={[
              { label: t('active'), value: true },
              { label: t('deactivated'), value: false },
            ]}
          />
        </form>
      )}
    </Formik>
  );
};
