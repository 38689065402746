import moment from 'moment';
import { Container } from 'octiv-components';
import { useExportFindLocations } from 'octiv-hooks/requests/Export';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import { downloadFile, getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const [query, setQuery] = useState({
    startDate: getDateYearMonthDay({
      date: moment().startOf('month'),
    }),
    endDate: getDateYearMonthDay({ date: moment().endOf('month') }),
    regionId: undefined,
    tenantId: undefined,
  });

  const regionsFind = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
  });
  const tenantsFind = useTenantsFind({
    filter: { regionId: query?.regionId },
    paging: { perPage: -1 },
  });

  const exportFindLocations = useExportFindLocations(
    {
      filter: query,
    },
    {
      enabled: false,
      onSuccess: (response) => downloadFile(response.file),
    }
  );

  const onSubmitForm = (values) => {
    setQuery(values);
    // TODO: improve
    setTimeout(() => {
      exportFindLocations.refetch();
    }, 200);
  };

  return (
    <Container
      appBarProps={{
        title: t('locations'),
        breadcrumbs: ['Exports'],
      }}
    >
      <Form
        initialValues={query}
        isFetchingRegions={regionsFind?.isFetching}
        isFetchingTenants={tenantsFind.isFetching}
        isLoading={exportFindLocations.isFetching}
        regions={regionsFind?.data?.data || []}
        tenants={tenantsFind?.data?.data || []}
        onGetTenantsRequest={({ regionId }) =>
          setQuery((prev) => ({ ...prev, regionId }))
        }
        onSubmit={onSubmitForm}
      />
    </Container>
  );
};
