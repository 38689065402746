import _ from 'lodash';
import { Box, Icon, Text } from 'octiv-components';
import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';

const ToggleTabs = ({
  activeKey = undefined,
  borderColor = 'grey5',
  height = 11,
  iconProps,
  initialActiveIndex = 0,
  options,
  setActiveKey,
  textProps,
  onChangeTab,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);

  const isControlled = activeKey !== undefined;

  return (
    <>
      <Box
        boxShadow={`inset 0 -1px 0 0 ${theme.colors[borderColor]}`}
        className='hide-scrollbar'
        height={height}
        overflowX='scroll'
        position='relative'
        {...props}
      >
        <Box isFlex position='absolute'>
          {options.map(({ label, icon, key }, index) => {
            const isActive = isControlled
              ? activeKey === key
              : activeIndex === index;

            return (
              <Box
                key={`${index.toString()}`}
                onClick={
                  isActive
                    ? undefined
                    : () => {
                        if (isControlled) {
                          setActiveKey(key);
                        } else {
                          setActiveIndex(index);
                        }

                        if (onChangeTab) onChangeTab({ key, index });
                      }
                }
              >
                <Box
                  isFlex
                  alignItems='center'
                  boxShadow={
                    isActive
                      ? `inset 0 -2px 0 0 ${theme.colors.primary}`
                      : undefined
                  }
                  height={height}
                  px={4}
                >
                  {label && (
                    <Text
                      color={isActive ? 'font' : 'grey1'}
                      whiteSpace='nowrap'
                      {...textProps}
                      lineHeight={1}
                    >
                      {label}
                    </Text>
                  )}

                  {icon && (
                    <Icon
                      color='font'
                      ml={label ? 1 : undefined}
                      scale='small'
                      {...iconProps}
                      name={icon}
                    />
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      {isControlled || !options[activeIndex].children
        ? null
        : _.isFunction(options[activeIndex].children)
        ? options[activeIndex].children(activeIndex)
        : options[activeIndex].children}
    </>
  );
};

export default ToggleTabs;
