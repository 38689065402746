import { Formik } from 'formik';
import { Alert, Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  banks,
  isFetchingBanks,
  initialValues,
  isCreate,
  ...props
}) => {
  const { t } = useTranslation();

  const capitecBankCode = '470010';
  const capitecBusinessBankCode = '450105';

  const initialBankCode = initialValues.bankName
    ? banks.find((bank) => bank.name === initialValues.bankName)?.code
    : undefined;

  const shouldValidateBankAccount = ({
    currentBankCode,
    currentAccountNumber,
  }) =>
    !initialValues.bankName ||
    !initialValues.accountNumber ||
    initialBankCode !== currentBankCode ||
    initialValues.accountNumber !== currentAccountNumber;

  const accountValidation = Yup.string().when(['bankCode', 'accountNumber'], {
    is: (bankCode, accountNumber) =>
      bankCode !== capitecBusinessBankCode &&
      shouldValidateBankAccount({
        currentBankCode: bankCode,
        currentAccountNumber: accountNumber,
      }),
    then: Yup.string().required(t('required')),
  });

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        bankCode: initialBankCode,
        accountNumber: initialValues.accountNumber || undefined,
        accountName: undefined,
        accountType: undefined,
        documentType: undefined,
        documentNumber: undefined,
        notifyEmailAddress: initialValues.notifyEmailAddress || undefined,
        isForSignUp: initialValues.isForSignUp ? initialValues.isForSignUp : 1,
        ...(isCreate
          ? {}
          : {
              isActive: initialValues.isActive,
            }),
      }}
      validationSchema={Yup.object().shape({
        bankCode: Yup.string().required(t('required')),
        accountNumber: Yup.string().required(t('required')),
        accountName: accountValidation,
        accountType: accountValidation,
        documentType: accountValidation,
        documentNumber: accountValidation,
        notifyEmailAddress: Yup.string().email(t('invalidEmail')).nullable(),
        isForSignUp: Yup.number(),
        ...(isCreate
          ? {}
          : {
              isActive: Yup.number(), // .required(t('required')),
            }),
      })}
    >
      {({ handleSubmit, dirty, values, setFieldTouched, setFieldValue }) => {
        const isCapitecAccount = values.bankCode === capitecBankCode;
        const isCapitecBusinessAccount =
          values.bankCode === capitecBusinessBankCode;

        return (
          <form onSubmit={handleSubmit}>
            <Alert mb={4} subtitle={t('paystackSettlements')} variant='info' />

            <Field
              isSelect
              boxContainerProps={{ mb: 4 }}
              isLoading={isFetchingBanks}
              label={t('bank')}
              name='bankCode'
              options={banks.map((item) => ({
                label: item.name,
                value: item.code,
              }))}
            />

            <Field
              boxContainerProps={{ mb: 4 }}
              label={t('accountNumber')}
              name='accountNumber'
            />

            {isCapitecBusinessAccount && (
              <Alert
                mb={4}
                subtitle={t('paystackCapitecBusinessAccount')}
                variant='danger'
              />
            )}

            {!isCapitecBusinessAccount &&
              shouldValidateBankAccount({
                currentBankCode: values.bankCode,
                currentAccountNumber: values.accountNumber,
              }) && (
                <>
                  <Field
                    boxContainerProps={{ mb: 4 }}
                    label={t('accountName')}
                    name='accountName'
                  />

                  <Field
                    isSelect
                    boxContainerProps={{ mb: 4 }}
                    doFinally={(accountTypeValue) => {
                      setFieldTouched('documentType', false);
                      setFieldValue(
                        'documentType',
                        accountTypeValue === 'business'
                          ? 'businessRegistrationNumber'
                          : 'identityNumber'
                      );

                      setFieldTouched('documentNumber', false);
                      setFieldValue('documentNumber', undefined);
                    }}
                    label={t('accountType')}
                    name='accountType'
                    options={[
                      ...(!isCapitecAccount
                        ? [{ label: t('business'), value: 'business' }]
                        : []),
                      { label: t('personal'), value: 'personal' },
                    ]}
                  />

                  {values.accountType && (
                    <>
                      {values.accountType !== 'business' && (
                        <Field
                          isSelect
                          boxContainerProps={{ mb: 4 }}
                          label={t('verificationDocumentType')}
                          name='documentType'
                          options={[
                            {
                              label: t('identityNumber'),
                              value: 'identityNumber',
                            },
                            {
                              label: t('passportNumber'),
                              value: 'passportNumber',
                            },
                          ]}
                        />
                      )}

                      <Field
                        boxContainerProps={{ mb: 4 }}
                        label={
                          values.documentType === 'businessRegistrationNumber'
                            ? t('businessRegistrationNumber')
                            : values.documentType === 'identityNumber'
                            ? t('identityNumber')
                            : t('passportNumber')
                        }
                        name='documentNumber'
                      />
                    </>
                  )}
                </>
              )}

            <Field
              canSetEmptyStringNull
              boxContainerProps={{ mb: 4 }}
              label={t('paymentNotificationEmailAddress')}
              name='notifyEmailAddress'
            />

            <Field
              isCheckbox
              boxContainerProps={{ mb: 4 }}
              name='isForSignUp'
              options={[
                {
                  label: t('useGatewayForLimitedPackagePurchases', {
                    paymentGateway: 'Paystack',
                  }),
                  value: 1,
                },
              ]}
            />

            {!isCreate && (
              <Field
                isHorizontal
                isRadio
                boxContainerProps={{ mb: 4 }}
                label={t('gatewayStatus')}
                name='isActive'
                options={[
                  { label: t('active'), value: 1 },
                  { label: t('deactivated'), value: 0 },
                ]}
              />
            )}

            <Button
              isDisabled={!dirty}
              isLoading={isLoading}
              text={t('submit')}
              type='submit'
            />
          </form>
        );
      }}
    </Formik>
  );
};
