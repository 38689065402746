import {
  useSettingsFindWaiver,
  useSettingsUpdateWaiver,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useSettingsFindWaiver({});

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateWaiver({ onSuccess: getSettingsRequest });

  const handleSubmit = (values) => {
    putSettingsRequest({ id: settings.waiver.id, ...values });
  };

  return (
    <Setting title={t('waivers')}>
      {!isFetchingSettings && (
        <Form
          initialValues={settings?.waiver || {}}
          isLoading={isUpdatingSettings}
          onSubmit={handleSubmit}
        />
      )}
    </Setting>
  );
};
