import { Col, Container, Divider, Row } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging } from 'octiv-hooks';
import { useExercisesFind } from 'octiv-hooks/requests/Exercises';
import { useLeaderboardFind } from 'octiv-hooks/requests/Leaderboard';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locationOptionsAll },
    tenantId,
  } = useActiveUserTenant();
  const [isVisibleCompare, setIsVisibleCompare] = useState(false);
  const [paging, setPaging] = usePaging();
  const [pagingCompared, setPagingCompared] = usePaging();
  const [query, setQuery] = useState({
    tenantId,
    exerciseId: undefined,
    locationId: undefined,
    regionId: undefined,
    genderId: undefined,
  });
  const [queryCompare, setQueryCompare] = useState({
    tenantId,
    exerciseId: undefined,
    locationId: undefined,
    regionId: undefined,
    genderId: undefined,
  });

  const { data: benchmarks, isFetching: isFetchingBenchmarks } =
    useExercisesFind({
      filter: {
        isActive: true,
        isBenchmark: 1,
        isMinimalData: true,
      },
      paging: { perPage: -1 },
    });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: {
      isActive: true,
    },
    paging: {
      perPage: -1,
    },
  });

  const {
    data: leaderboard,
    isFetching: isFetchingLeaderboard,
    // refetch: getLeaderboardRequest,
  } = useLeaderboardFind(
    {
      filter: query,
      paging,
    },
    {
      enabled: !!query?.exerciseId,
    }
  );

  const {
    data: leaderboardCompared,
    isFetching: isFetchingLeaderboardCompared,
    // refetch: getLeaderboardComparedRequest,
  } = useLeaderboardFind(
    {
      filter: queryCompare,
      paging: pagingCompared,
    },
    {
      enabled: !!queryCompare?.exerciseId && query?.canCompare,
    }
  );

  const onFormSubmit = (values) => {
    const {
      exerciseId,
      regionId,
      locationId,
      genderId,
      canCompare,
      regionIdCompare,
      locationIdCompare,
      genderIdCompare,
    } = values;

    setQuery((prev) => ({
      ...prev,
      exerciseId,
      locationId,
      regionId,
      genderId,
      canCompare,
    }));

    if (canCompare) {
      setQueryCompare((prev) => ({
        ...prev,
        exerciseId,
        locationId: locationIdCompare,
        regionId: regionIdCompare,
        genderId: genderIdCompare,
      }));
    }

    setIsVisibleCompare(canCompare);
  };

  return (
    <Container
      appBarProps={{
        title: t('leaderboard'),
        breadcrumbs: [t('workouts')],
      }}
      isLoading={
        isFetchingBenchmarks ||
        isFetchingRegions ||
        isFetchingLeaderboard ||
        isFetchingLeaderboardCompared
      }
    >
      <FormQuery
        benchmarks={benchmarks?.data || []}
        isFetchingBenchmarks={isFetchingBenchmarks}
        isFetchingRegions={isFetchingRegions}
        isLoading={isFetchingLeaderboard}
        locationOptions={locationOptionsAll}
        regions={regions?.data || []}
        onSubmit={onFormSubmit}
      />

      <Divider pb={4} />

      {isVisibleCompare ? (
        <Row>
          <Col md={6}>
            <Table
              data={leaderboard?.data || []}
              paging={leaderboard?.paging}
              setPaging={setPaging}
            />
          </Col>
          <Col md={6}>
            <Table
              data={leaderboardCompared?.data || []}
              paging={leaderboardCompared?.paging}
              setPaging={setPagingCompared}
            />
          </Col>
        </Row>
      ) : (
        <Table
          data={leaderboard?.data || []}
          paging={leaderboard?.paging}
          setPaging={setPaging}
        />
      )}
    </Container>
  );
};
