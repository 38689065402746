import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  locationOptions,
  packages,
  isFetchingProgrammes,
  isFetchingPackages,
  programmes,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        userTenantLocationId: Yup.string(),
        userTenantStatusId: Yup.string(),
        userTenantPackageId: Yup.string(),
        userTenantProgrammeId: Yup.string(),
        debitStatusId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                label={t('location')}
                name='userTenantLocationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                label={t('status')}
                name='userTenantStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                isLoading={isFetchingPackages}
                label={t('package')}
                name='userTenantPackageId'
                options={[
                  { label: t('all'), value: undefined },
                  ...packages.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                isLoading={isFetchingProgrammes}
                label={t('programme')}
                name='userTenantProgrammeId'
                options={[
                  { label: t('all'), value: undefined },
                  ...programmes.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                label={t('paymentType')}
                name='debitStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('cashEftCard'), value: 1 },
                  { label: t('debitOrder'), value: 2 },
                  { label: t('noPayment'), value: 4 },
                  { label: t('upfrontPayment'), value: 5 },
                  { label: t('onlinePayment'), value: 6 },
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field hasSubmitButtonSearch label={t('search')} name='search' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
