import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName, getUserStatusName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, isFetchingMembers, members, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        userTenantStatusId: Yup.string(),
        userId: Yup.string(),
        invoiceMemberType: Yup.string().required(t('required')),
        type: Yup.string(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, values: { userTenantStatusId } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('memberFilterStatus')}
                name='userTenantStatusId'
                options={[
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingMembers}
                label={`${t('member')} (${getUserStatusName({
                  id: userTenantStatusId,
                })})`}
                name='userId'
                options={[
                  { label: t('all'), value: undefined },
                  ...members.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('type')}
                name='invoiceMemberType'
                options={[
                  { label: 'Member Invoices', value: 'athleteInvoicePayments' },
                  {
                    label: 'Lead Invoices',
                    value: 'leadMemberInvoicePayments',
                  },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentType')}
                name='type'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('cash'), value: 'cash' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('card'), value: 'card' },
                  { label: t('debitOrder'), value: 'debit_order' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
