import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'region.name',
      },
      {
        Header: t('tenants'),
        accessor: 'tenantsCount',
      },
      {
        Header: t('locations'),
        accessor: 'locationsCount',
      },
      {
        Header: t('users'),
        accessor: 'usersCount',
      },
      {
        Header: t('staff'),
        accessor: 'staffCount',
      },
      {
        Header: t('members'),
        accessor: 'membersCount',
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
