import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingProgrammes,
  locationOptions,
  programmes,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        statusId: Yup.string().required(t('required')),
        dateToQuery: Yup.string().required(t('required')),
        startDate: Yup.string(),
        endDate: Yup.string().when('startDate', {
          is: (value) => !!value,
          then: Yup.string().required(t('required')),
        }),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='statusId'
                options={[
                  { label: t('all'), value: 'all' },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('dateQuery')}
                name='dateToQuery'
                options={[
                  { label: t('starting'), value: 'starting_on' },
                  { label: t('ending'), value: 'ending_on' },
                  { label: t('joined'), value: 'created_on' },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>

            <Col md={3}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
