import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: '*Location Name',
        accessor: 'locationName',
      },
      {
        Header: '*Package',
        accessor: 'package',
      },
      {
        Header: '*Package End Date',
        accessor: 'packageStartDate',
      },
      {
        Header: '*Package Start Date',
        accessor: 'packageEndDate',
      },
      {
        Header: '*Programme',
        accessor: 'programme',
      },
      {
        Header: 'Member ID',
        accessor: 'memberId',
      },
      {
        Header: '*First Name',
        accessor: 'name',
      },
      {
        Header: '*Surname',
        accessor: 'surname',
      },
      {
        Header: '*Gender',
        accessor: 'gender',
      },
      {
        Header: '*Email Address',
        accessor: 'emailAddress',
      },
      {
        Header: '*Mobile Number',
        accessor: 'mobileNumber',
      },
      {
        Header: '*Date of Birth',
        accessor: 'dateOfBirth',
      },
      {
        Header: t('contractStart'),
        accessor: 'contractStart',
      },
      {
        Header: t('contractEnd'),
        accessor: 'contractEnd',
      },
      {
        Header: t('sessions'),
        accessor: 'sessions',
      },
      {
        Header: t('notes'),
        accessor: 'notes',
      },
      {
        Header: 'GoCardless',
        accessor: 'goCardless',
      },
      {
        Header: 'GoCardless Debit Day',
        accessor: 'goCardlessDebitDay',
      },
      {
        Header: 'Date Joined',
        accessor: 'dateJoined',
      },
      {
        Header: 'Member Social Security Number',
        accessor: 'memberSocialSecurityNumber',
      },
      {
        Header: 'Member Address',
        accessor: 'memberAddress',
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
