import { Box, Icon, Table } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isLoading, onChangeRecipients, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: () => (
          <Box ml='auto'>
            <Icon
              color='danger'
              name='delete'
              onClick={() => onChangeRecipients({ removeAll: true })}
            />
          </Box>
        ),
        accessor: 'id',
        Cell: ({ value }) => (
          <Icon
            boxContainerProps={{
              ml: 'auto',
            }}
            name='delete'
            onClick={() => onChangeRecipients({ removeId: value })}
          />
        ),
      },
    ],
    [onChangeRecipients, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
      isLoading={isLoading}
    />
  );
};
