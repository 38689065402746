import { Box, Icon, InlineUser, Table } from 'octiv-components';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'user',
        Cell: ({ value }) => <InlineUser user={value} />,
      },
      {
        Header: t('latestUpdate'),
        accessor: 'updates',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value?.[0] ? value[value.length - 1].content : null,
      },
      {
        Header: t('updatedOn'),
        accessor: 'updatedAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('status'),
        accessor: 'updates',
        id: 'status',
        Cell: ({ value }) => {
          const isHealed =
            value[value.length - 1]?.content === 'Marked as "healed"';

          return (
            <Box isFlex alignItems='center'>
              <Icon
                color={isHealed ? 'success' : 'danger'}
                mb='1px'
                mr={1}
                name='healing'
                scale='small'
              />
              {isHealed ? t('healed') : t('injured')}
            </Box>
          );
        },
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
