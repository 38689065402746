import { Table } from 'octiv-components';
import { toCase } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, hasError, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('user'),
        accessor: 'user',
      },
      {
        Header: t('bank'),
        accessor: 'bank',
      },
      {
        Header: t('accountType'),
        accessor: 'accountType',
      },
      {
        Header: t('accountNumber'),
        accessor: 'accountNumber',
      },
      {
        Header: t('accountName'),
        accessor: 'accountName',
      },
      {
        Header: t('internationalBankAccountNumber'),
        accessor: 'iban',
      },
      {
        Header: t('bankIdentifierCode'),
        accessor: 'bic',
      },
      {
        Header: t('address'),
        accessor: 'address',
      },
      {
        Header: t('debitDate'),
        accessor: 'debitDay',
      },
      {
        Header: t('specialRate'),
        accessor: 'specialRate',
      },
      {
        Header: 'Invalid Field',
        accessor: 'invalidField',
        Cell: ({ value }) => toCase({ value, isCamelCase: true }),
      },
      {
        Header: 'Invalid Message',
        accessor: 'invalidMessage',
      },
    ],
    [t]
  );

  const hiddenColumns = useMemo(
    () => (hasError ? [] : ['invalidField', 'invalidMessage']),
    [hasError]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
