import { useMediaQuery } from 'react-responsive';

export default () => ({
  xs: useMediaQuery({ minWidth: 0 }),
  sm: useMediaQuery({ minWidth: '36rem' }),
  md: useMediaQuery({ minWidth: '48rem' }),
  lg: useMediaQuery({ minWidth: '62rem' }),
  xl: useMediaQuery({ minWidth: '75rem' }),
  xxl: useMediaQuery({ minWidth: '100rem' }),

  xsDown: useMediaQuery({ maxWidth: '36rem' }),
  smDown: useMediaQuery({ maxWidth: '48rem' }),
  mdDown: useMediaQuery({ maxWidth: '62rem' }),
  lgDown: useMediaQuery({ maxWidth: '75rem' }),
  xlDown: useMediaQuery({ maxWidth: '100rem' }),
  xxlDown: true,
});
