import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isFetchingMembers, locationOptions, members, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        userTenantLocationId: Yup.string(),
        userId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='userTenantLocationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingMembers}
                label={t('member')}
                name='userId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(members?.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
