import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { useDebitBatchesFindLocation } from 'octiv-hooks/requests/DebitBatches';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ invoiceId, locationId, isLoading, ...props }) => {
  const { t } = useTranslation();

  const { data: debitBatchesAll, isFetching: isFetchingDebitBatchesAll } =
    useDebitBatchesFindLocation({
      filter: { locationId, isReturnAll: true },
      paging: { perPage: -1 },
    });

  return (
    <Formik
      {...props}
      initialValues={{
        id: invoiceId,
        debitBatchId: undefined,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        debitBatchId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                isLoading={isFetchingDebitBatchesAll}
                label={t('batch')}
                name='debitBatchId'
                options={
                  debitBatchesAll
                    ? debitBatchesAll?.data?.map((item) => ({
                        label: getDateReadableDayMonthYear({
                          date: item.debitDayDate.date,
                        }),
                        value: item.id,
                      }))
                    : []
                }
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
