import { Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getEventString,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// TODO: move to util, once this needs to be re-used
const keys = [
  'hasBeenInContactExperiencing',
  'hasBeenInContactPositive',
  'hasCough',
  'hasDifficultyBreathing',
  'hasFever',
  'hasTravelled',
];

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);

  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        id: 'date',
        accessor: 'classBooking',
        Cell: ({ value }) => getDateReadableDayMonthYear(value?.classDate),
      },
      {
        Header: t('class'),
        id: 'class',
        accessor: 'classBooking',
        Cell: ({ value }) => getEventString(value?.class),
      },
      {
        Header: t('temperature'),
        accessor: 'temperature',
      },
      {
        Header: t('yesCount'),
        id: 'yes',
        accessor: 'yesNoCount',
        Cell: ({ row: { original } }) =>
          keys.filter((key) => original[key] === 1).length,
      },
      {
        Header: t('noCount'),
        id: 'no',
        accessor: 'yesNoCount',
        Cell: ({ row: { original } }) =>
          keys.filter((key) => original[key] === 0).length,
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: {
              id,
              hasBeenInContactExperiencing,
              hasBeenInContactPositive,
              hasCough,
              hasDifficultyBreathing,
              hasFever,
              hasTravelled,
              temperature,
              travelledWhere,
            },
          },
        }) => (
          <Text
            as='span'
            color='primary'
            onClick={() =>
              onClickAction({
                data: {
                  id,
                  hasBeenInContactExperiencing,
                  hasBeenInContactPositive,
                  hasCough,
                  hasDifficultyBreathing,
                  hasFever,
                  hasTravelled,
                  temperature,
                  travelledWhere,
                },
              })
            }
          >
            {t('viewEdit')}
          </Text>
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
