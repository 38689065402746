import {
  useSettingsFindBookings,
  useSettingsUpdateBookings,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const { data: settings, isFetching: isFetchingSettings } =
    useSettingsFindBookings();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateBookings();

  const handleSubmit = (values) => putSettingsRequest(values);

  return (
    <Setting isLoading={isFetchingSettings} title={t('memberBookings')}>
      <Form
        initialValues={isFetchingSettings ? {} : settings}
        isLoading={isUpdatingSettings}
        onSubmit={handleSubmit}
      />
    </Setting>
  );
};
