import {
  useDropInPackagesFindSettings,
  useDropInPackagesUpdateSettings,
} from 'octiv-hooks/requests/DropInPackages';
import React from 'react';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useDropInPackagesFindSettings();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useDropInPackagesUpdateSettings({ onSuccess: getSettingsRequest });

  // TODO: API error: payment type 'adhoc' is invalid... but 'cash' is allowed.
  // Need to check once this is fixed on the API... as PROD used to allow 'adhoc'
  const handleSubmit = (values) => putSettingsRequest(values);

  return (
    <Setting>
      {!isFetchingSettings && (
        <Form
          initialValues={settings?.data || settings || {}}
          isLoading={isUpdatingSettings}
          onSubmit={handleSubmit}
        />
      )}
    </Setting>
  );
};
