import { Card, Container } from 'octiv-components';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useSettingsFindSignUp } from 'octiv-hooks/requests/Settings';
import {
  useUsersUpdate,
  useUsersUpdateImage,
} from 'octiv-hooks/requests/Users';
import React from 'react';

import DiscoveryVitality from '../../discoveryVitality';
import FormImage from './FormImage';
import FormUser from './FormUser';

export default () => {
  const { isRefetchingSignedInUser, refetchSignedInUser, ...signedInUser } =
    useSignedInUser();

  const settingsFindSignUp = useSettingsFindSignUp({});

  const usersUpdateImage = useUsersUpdateImage({
    onSuccess: refetchSignedInUser,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const usersUpdate = useUsersUpdate({
    onSuccess: refetchSignedInUser,
    meta: {
      useOnSuccessToast: true,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(refetchSignedInUser, []);

  return (
    <Container
      appBarProps={{
        title: 'Profile',
        breadcrumbs: ['User'],
      }}
      isLoading={isRefetchingSignedInUser || usersUpdateImage.isLoading}
    >
      <Card>
        <FormImage
          initialValues={{ id: signedInUser.id, image: signedInUser.image }}
          onSubmit={usersUpdateImage.mutate}
        />

        <FormUser
          initialValues={signedInUser}
          isLoading={usersUpdate.isLoading}
          signUpRequiredFields={settingsFindSignUp?.data?.signUpRequiredFields}
          onSubmit={usersUpdate.mutate}
        />
      </Card>

      <DiscoveryVitality />
    </Container>
  );
};
