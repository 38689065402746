import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  affiliates,
  isFetchingAffiliates,
  isFetchingLocationCategories,
  isFetchingRegions,
  locationCategories,
  regions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        regionId: Yup.string(),
        affiliateId: Yup.string(),
        statusId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingRegions}
                label={t('region')}
                name='regionId'
                options={[
                  { label: t('all'), value: undefined },
                  ...regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingLocationCategories}
                label={t('locationCategory')}
                name='locationCategoryId'
                options={[
                  { label: t('all'), value: undefined },
                  ...locationCategories.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingAffiliates}
                label={t('affiliate')}
                name='affiliateId'
                options={[
                  { label: t('all'), value: undefined },
                  ...affiliates.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='statusId'
                options={[
                  { label: t('active'), value: 1 },
                  { label: t('deactivated'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: 'Deleted', value: 4 },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
