import { InlineUser, Table } from 'octiv-components';
import { getDateReadableDayMonth } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => <InlineUser user={original} />,
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: t('birthday'),
        accessor: 'dateOfBirth',
        Cell: ({ value }) => getDateReadableDayMonth({ date: value }),
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
