import React from 'react';

const UserPackageModalBaseState = {
  userId: undefined,
  userTenantId: undefined,
  userLocationId: undefined,
  validPackageIds: [],
  initialUserPackage: {},
  onClose: undefined,
};

const UserPackageModalInitialState = {
  ...UserPackageModalBaseState,
  setUserPackageModal: () => {},
  resetUserPackageModal: () => {},
};

export const UserPackageModalContext = React.createContext(
  UserPackageModalInitialState
);

export const useUserPackageModal = () => {
  const userPackageModal = React.useContext(UserPackageModalContext);

  return userPackageModal;
};

export const UserPackageModalContextProvider = ({ children }) => {
  const [userPackageModalContext, setUserPackageModalContext] = React.useState(
    UserPackageModalInitialState
  );

  const onSetUserPackageModal = React.useCallback(
    (values) =>
      setUserPackageModalContext((prev) => ({
        ...prev,
        userId: values.userId,
        userTenantId: values.userTenantId,
        userLocationId: values.userLocationId,
        validPackageIds: values.validPackageIds,
        initialUserPackage: values.initialUserPackage,
        onClose: values.onClose || prev.onClose,
      })),
    []
  );

  const onResetUserPackageModal = React.useCallback(
    () =>
      setUserPackageModalContext((prev) => ({
        ...prev,
        ...UserPackageModalBaseState,
      })),
    []
  );

  React.useEffect(() => {
    setUserPackageModalContext({
      ...UserPackageModalBaseState,
      setUserPackageModal: onSetUserPackageModal,
      resetUserPackageModal: onResetUserPackageModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserPackageModalContext.Provider value={userPackageModalContext}>
      {children}
    </UserPackageModalContext.Provider>
  );
};
