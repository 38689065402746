import { Modal, ProcessingBar } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { useBanksFind } from 'octiv-hooks/requests/Banks';
import { useFinancesFindDiscounts } from 'octiv-hooks/requests/Finances';
import {
  useUserTenantsFindByIdPaymentDetails,
  useUserTenantsUpdatePaymentDetails,
  useUserTenantsUpdateRenewUpfrontPayment,
} from 'octiv-hooks/requests/UserTenants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormPaymentDetails, { sanitizeValues } from './FormPaymentDetails';
import FormRenewUpfrontPayment from './FormRenewUpfrontPayment';

export const paymentTypes = {
  CASH: 1,
  DEBIT_ORDER: 2,
  NONE: 4,
  UPFRONT: 5,
  ONLINE: 6,
};

export default ({ userTenantId }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const banksFind = useBanksFind();

  const financesFindDiscounts = useFinancesFindDiscounts();

  const userTenantsFindByIdPaymentDetails =
    useUserTenantsFindByIdPaymentDetails({
      id: userTenantId,
    });

  const userTenantsUpdatePaymentDetails = useUserTenantsUpdatePaymentDetails({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdateRenewUpfrontPayment =
    useUserTenantsUpdateRenewUpfrontPayment({
      onSuccess: resetToggle,
    });

  const onSubmitPaymentDetails = (values) => {
    userTenantsUpdatePaymentDetails.mutate({
      id: userTenantId,
      ...sanitizeValues(values),
    });
  };

  const onSubmitRenewUpfrontPayment = (values) => {
    userTenantsUpdateRenewUpfrontPayment.mutate({
      id: userTenantId,
      ...values,
    });
  };

  return (
    <>
      {(userTenantsFindByIdPaymentDetails.isFetching ||
        userTenantsUpdatePaymentDetails.isLoading) && <ProcessingBar />}

      {toggle.isVisible && (
        <Modal isSidebar title={t('renewUpfrontPayment')} onClose={resetToggle}>
          <FormRenewUpfrontPayment
            isLoading={userTenantsUpdateRenewUpfrontPayment.isLoading}
            onSubmit={onSubmitRenewUpfrontPayment}
          />
        </Modal>
      )}

      <FormPaymentDetails
        banks={banksFind.data || []}
        discounts={financesFindDiscounts.data?.data || []}
        initialValues={userTenantsFindByIdPaymentDetails.data || {}}
        isFetchingBanks={banksFind.isFetching}
        isFetchingDiscounts={financesFindDiscounts.isFetching}
        isLoading={userTenantsUpdatePaymentDetails.isLoading}
        onRenewUpfrontPayment={() => setToggle({ isVisible: true })}
        onSubmit={onSubmitPaymentDetails}
      />
    </>
  );
};
