import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Col,
  Field,
  Icon,
  Row,
  Step,
} from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import TableXlsxExampleWorkouts from './TableXlsxExampleWorkouts';

export default ({ affiliates, isLoading, ...props }) => {
  const { t } = useTranslation();
  const workoutImportSpecifics = t('workoutImportSpecifics', {
    returnObjects: true,
  });

  const downloadFileTemplate = () => {
    const link = document.createElement('a');
    link.href = `${window.location.origin}/files/OctivTemplate-GlobalProgrammeWorkouts.xlsx`; // Path to your CSV file
    link.download = 'OctivTemplate-GlobalProgrammeWorkouts.xlsx'; // Name the file as it will be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Call this function on a button click or any other event
  };

  return (
    <Formik
      {...props}
      initialValues={{
        file: undefined,
      }}
      validationSchema={Yup.object().shape({
        file: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Alert
                mb={4}
                subtitle='The format of the file you upload must be .xlsx and adhere to the guidelines provided below.'
                title={t('pleaseNote')}
                variant='warning'
              />

              <Step
                boxContainerProps={{ mb: 4 }}
                mb={2}
                title='Download this template to create your own workout'
                value='1'
              >
                <Button
                  hasBorder
                  marginTop='2rem'
                  text={
                    <Box isFlex alignItems='center' flexDirection='row'>
                      <Icon name='download' />
                      {t('downloadTemplate')}
                    </Box>
                  }
                  onClick={() => downloadFileTemplate()}
                />

                <br />

                <TableXlsxExampleWorkouts
                  hasAlternateBackground
                  data={[
                    {
                      programme: 'CrossFit Compete',
                      name: 'John',
                      date: '17-09-2024',
                      warmUp: 'Jumping jacks',
                      coolDown: 'Water break',
                      memberNotes: 'This is an example note.',
                      coachNotes: 'This is an example note.',
                      partAType: 'Exercise',
                      partAPart: 'Exercise',
                      partACategory: 'CrossFit Open WODs',
                      partABenchmark: 'The Open 11.2',
                      partAName: 'Burpees',
                      partADescription: 'Burpees for 5 minutes',
                      partAMeasurement: 'For Repetitions',
                      partAResourceURL: 'https://example.resource-url.com',
                    },
                  ]}
                />
              </Step>

              <Step
                boxContainerProps={{ mb: 4 }}
                mb={2}
                title='Specifics:'
                value='2'
              >
                <Box color='font'>
                  <ul>
                    {workoutImportSpecifics?.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </Box>
              </Step>
            </Col>
            <Col>
              <Field
                isDrop
                useFileIcon
                accept={{
                  'text/xlsx': ['.xlsx'],
                  'application/vnd.ms-excel': ['.xls'],
                }}
                label={t('xlsxFile')}
                name='file'
              />
            </Col>
            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
