import { Formik } from 'formik';
import { Alert, Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, tenants, isFetchingTenants, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        bankStatement: undefined,
      }}
      validationSchema={Yup.object().shape({
        bankStatement: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Alert
            mb={4}
            subtitle={t('debitBatchReconAlertSubtitle')}
            title={t('pleaseNote')}
            variant='info'
          />

          <Field
            isDrop
            useFileIcon
            accept={{
              'application/xml': ['.xml'],
            }}
            label={t('file')}
            name='bankStatement'
          />

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
