import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingLocationTags,
  isFetchingUsersTrainers,
  isLoading,
  locationOptions,
  locationTags,
  usersTrainers,
  ...props
}) => {
  const { t } = useTranslation();

  const { isTrainer } = useActiveUserTenant();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        isSession: Yup.string(),
        locationId: Yup.string(),
        tagIds: Yup.array(),
        trainerUserTenantId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Field
                canSubmitOnChange
                isHorizontal
                isRadio
                isDisabled={isTrainer}
                label={t('eventTypes')}
                name='isSession'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('classes'), value: false },
                  { label: t('sessions'), value: true },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isMulti
                isSelect
                isLoading={isFetchingLocationTags}
                label={t('locationTags')}
                name='tagIds'
                options={locationTags.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isDisabled={isTrainer}
                isLoading={isFetchingUsersTrainers}
                label={t('trainer')}
                name='trainerUserTenantId'
                options={[
                  { label: t('all'), value: undefined },
                  ...usersTrainers.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
