import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useDropInPackagesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['dropInPackages', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/drop-in-packages`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            status: filter.status || 1,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useDropInPackagesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/drop-in-packages`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          description: data.description,
          price: data.price,
          priority: data.priority,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['dropInPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDropInPackagesFindSettings = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dropInPackages', 'findSettings', input],
    () =>
      request({
        method: 'get',
        url: `/api/drop-in-packages/settings`,
        params: {
          filter: {
            tenantId,
          },
        },
      }),
    options
  );
};

export const useDropInPackagesUpdateSettings = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/drop-in-packages/settings`,
        data: {
          showAllClasses: data.showAllClasses,
          paymentType: data.paymentType,
          tenantId: data.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['dropInPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDropInPackagesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['dropInPackages', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/drop-in-packages/${id}`,
      }),
    options
  );
};

export const useDropInPackagesUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/drop-in-packages/${id}`,
        data: {
          name: data.name,
          description: data.description,
          price: data.price,
          priority: data.priority,
          tenantId: data?.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['dropInPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDropInPackagesUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/drop-in-packages/activate-deactivate/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['dropInPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDropInPackagesFindByIdClasses = (input, options) => {
  const { id, filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dropInPackages', 'findByIdClasses', input],
    () =>
      request({
        method: 'get',
        url: `/api/drop-in-packages/classes/${id}`,
        params: {
          filter: {
            locationId: filter.locationId,
            tenantId: filter.tenantId || tenantId,
          },
        },
      }),
    options
  );
};

export const useDropInPackagesUpdateClasses = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/drop-in-packages/classes/${id}`,
        data: {
          locationId: data.locationId,
          classIds: data.classIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['dropInPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};
