import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import {
  Box,
  Button,
  Col,
  Divider,
  Field,
  Icon,
  Row,
  Text,
} from 'octiv-components';
import { measures } from 'octiv-utilities/Constants';
import {
  getDateYearMonthDay,
  getExercisePart,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingExerciseCategories,
  isFetchingBenchmarks,
  isFetchingProgrammes,
  isLoading,
  isCreateWorkout,
  exerciseCategories,
  initialValues = {},
  programmes,
  benchmarks,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        name: initialValues.name || undefined,
        date: getDateYearMonthDay({ date: initialValues.date }) || undefined,
        programmeId: initialValues.programmeId || undefined,
        warmUp: initialValues.warmUp || undefined,
        coolDown: initialValues.coolDown || undefined,
        trainerNotes: initialValues.trainerNotes || undefined,
        memberNotes: initialValues.memberNotes || undefined,
        exercises: initialValues.exercises || [],
      }}
      validationSchema={Yup.object().shape({
        date: Yup.string().required(t('required')),
        programmeId: Yup.number().required(t('required')),
        name: Yup.string(),
        warmUp: Yup.string(),
        coolDown: Yup.string(),
        memberNotes: Yup.string(),
        trainerNotes: Yup.string(),
        exercises: Yup.array().of(
          Yup.object().shape({
            order: Yup.number().required(t('required')),
            name: Yup.string().when('benchmarkId', {
              is: (value) => !value,
              then: Yup.string().required(t('required')),
            }),
            prefix: Yup.string(),
            description: Yup.string(),
            measureId: Yup.number().required(t('required')),
            resourceUrl: Yup.string(),
            isBenchmark: Yup.bool(),
            benchmarkId: Yup.number(),
          })
        ),
      })}
    >
      {({ handleSubmit, setFieldValue, values: { exercises } }) => {
        const onAddExercise = ({ showAsBenchmark = false }) =>
          setFieldValue('exercises', [
            ...exercises,
            {
              order:
                exercises.length > 0
                  ? exercises[exercises.length - 1].order + 1
                  : 1,
              name: undefined,
              prefix: undefined,
              description: undefined,
              measureId: undefined,
              resourceUrl: undefined,
              isBenchmark: showAsBenchmark ? undefined : false,
              benchmarkId: undefined,
            },
          ]);

        const filterBenchmarks = ({ index }) => {
          let filteredBenchmarks = benchmarks;

          if (exercises[index].benchmarkCategoryId !== undefined) {
            filteredBenchmarks = benchmarks.filter(
              (item) =>
                item?.exerciseCategoryId ===
                exercises[index].benchmarkCategoryId
            );
          }

          return filteredBenchmarks.map((item) => ({
            label: item.name,
            value: item.id,
          }));
        };

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Field label={t('name')} name='name' />
              </Col>

              <Col md={4}>
                <Field isDate label={t('date')} name='date' />
              </Col>

              <Col md={4}>
                <Field
                  isSelect
                  isLoading={isFetchingProgrammes}
                  label={t('programme')}
                  name='programmeId'
                  options={programmes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Col>

              <Col md={3}>
                <Field as='textarea' label={t('warmUp')} name='warmUp' />
              </Col>

              <Col md={3}>
                <Field as='textarea' label={t('coolDown')} name='coolDown' />
              </Col>

              <Col md={3}>
                <Field
                  as='textarea'
                  label={t('memberNotes')}
                  name='memberNotes'
                />
              </Col>

              <Col md={3}>
                <Field
                  as='textarea'
                  label={t('trainerNotes')}
                  name='trainerNotes'
                />
              </Col>
            </Row>

            {!_.isEmpty(exercises) && (
              <Text mb={4} mt={5} variant='heading'>
                {t('exercises')}
              </Text>
            )}

            <FieldArray
              name='exercises'
              render={(arrayHelpers) =>
                exercises.map((exercise, index) => {
                  const swap = (indexA, indexB) => {
                    setFieldValue(
                      `exercises[${indexA}].order`,
                      exercises[indexB].order,
                      false
                    );

                    setFieldValue(
                      `exercises[${indexB}].order`,
                      exercises[indexA].order,
                      false
                    );

                    arrayHelpers.swap(indexA, indexB);
                  };

                  const isBenchmarkExercise =
                    exercises[index].isBenchmark === undefined;

                  return (
                    <Box key={`${index.toString()}`}>
                      {index > 0 && <Divider hasLine my={5} />}

                      <Box
                        isFlex
                        alignItems='center'
                        justifyContent='space-between'
                        mb={2}
                      >
                        <Text variant='subheading'>
                          {`${t('part')} ${
                            exercises[index].prefix !== undefined
                              ? exercises[index].prefix
                              : getExercisePart({ index })
                          }`}
                        </Text>

                        <Box isFlex alignItems='center'>
                          <Text color='grey1' mr={1}>
                            {`${t('changeOrder')}:`}
                          </Text>

                          <Box
                            isDisabled={index === 0}
                            onClick={
                              index === 0
                                ? undefined
                                : () => swap(index, index - 1)
                            }
                          >
                            <Icon name='arrow_upward' />
                          </Box>

                          <Box
                            isDisabled={index >= exercises.length - 1}
                            onClick={
                              index >= exercises.length - 1
                                ? undefined
                                : () => swap(index, index + 1)
                            }
                          >
                            <Icon name='arrow_downward' />
                          </Box>

                          <Box
                            ml={4}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <Icon color='danger' name='delete' />
                          </Box>
                        </Box>
                      </Box>

                      <Row>
                        <Col md={6}>
                          <Row>
                            {isBenchmarkExercise && (
                              <>
                                <Col md={6}>
                                  <Field
                                    isSelect
                                    isLoading={isFetchingExerciseCategories}
                                    label={t('exerciseCategory')}
                                    name={`exercises[${index}].benchmarkCategoryId`}
                                    options={[
                                      {
                                        label: t('all'),
                                        value: undefined,
                                      },
                                      ...(exerciseCategories || []).map(
                                        (item) => ({
                                          label: item.name,
                                          value: item.id,
                                        })
                                      ),
                                    ]}
                                  />
                                </Col>

                                <Col md={6}>
                                  <Field
                                    isSelect
                                    doFinally={(value) => {
                                      const benchmark = benchmarks.find(
                                        (item) => item.id === value
                                      );

                                      setFieldValue(
                                        `exercises[${index}].measureId`,
                                        benchmark.measuringUnit.id,
                                        false
                                      );

                                      setFieldValue(
                                        `exercises[${index}].description`,
                                        benchmark.description,
                                        false
                                      );
                                    }}
                                    label={t('benchmark')}
                                    name={`exercises[${index}].benchmarkId`}
                                    options={filterBenchmarks({ index })}
                                  />
                                </Col>
                              </>
                            )}

                            <Col md={6}>
                              <Field
                                label={t('part')}
                                name={`exercises[${index}].prefix`}
                                placeholder={getExercisePart({ index })}
                              />
                            </Col>

                            {!isBenchmarkExercise && (
                              <Col md={6}>
                                <Field
                                  label={t('name')}
                                  name={`exercises[${index}].name`}
                                />
                              </Col>
                            )}

                            <Col md={6}>
                              <Field
                                isSelect
                                isDisabled={isBenchmarkExercise}
                                label={t('measure')}
                                name={`exercises[${index}].measureId`}
                                options={measures.map((item) => ({
                                  label: item.description,
                                  value: item.measureId,
                                }))}
                              />
                            </Col>

                            {!isBenchmarkExercise && (
                              <Col md={6}>
                                <Field
                                  label={t('resourceUrl')}
                                  name={`exercises[${index}].resourceUrl`}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>

                        <Col md={6}>
                          <Field
                            as='textarea'
                            isDisabled={isBenchmarkExercise}
                            label={t('description')}
                            name={`exercises[${index}].description`}
                          />
                        </Col>
                      </Row>
                    </Box>
                  );
                })
              }
            />

            <Box isFlex mt={4}>
              <Button
                hasBorder
                text={t('addExercise')}
                type='button'
                onClick={onAddExercise}
              />

              <Button
                hasBorder
                mx={2}
                text={t('addBenchmark')}
                type='button'
                onClick={() => onAddExercise({ showAsBenchmark: true })}
              />

              <Button
                isDisabled={!exercises || exercises.length === 0}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
