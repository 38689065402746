import React from 'react';

export const ActiveScreenInitialState = {
  title: undefined,
  isVisibleInNavigation: true,
  isVisibleNavigation: true,
  isSecure: true,
};

export default React.createContext(ActiveScreenInitialState);
