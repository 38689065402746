import { FieldArray, Formik } from 'formik';
import { Button, Card, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const bookingThresholdOptions = [];
for (let index = 1; index < 91; index += 1) {
  bookingThresholdOptions.push({
    label: `${
      index > 1 ? `${index - 1} day${index > 2 ? 's' : ''} and ` : ''
    }23 hours`,
    value: index,
  });
}

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        bookingThreshold: initialValues.bookingThreshold || undefined,
        isLimitInterLocationBookings:
          initialValues.isLimitInterLocationBookings,
        locationSettings: initialValues.locationSettings || [],
      }}
      validationSchema={Yup.object().shape({
        bookingThreshold: Yup.string().required(t('required')),
        isLimitInterLocationBookings: Yup.number().required(t('required')),
        locationSettings: Yup.array().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                isSelect
                label={t('howLongInAdvanceCanMemberBook')}
                name='bookingThreshold'
                options={bookingThresholdOptions}
              />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('interLocationBookings')}
                name='isLimitInterLocationBookings'
                options={[
                  {
                    label: t('interLocationBookingsAssignedLocation'),
                    value: 1,
                  },
                  {
                    label: t('interLocationBookingsAnyLocation'),
                    value: 0,
                  },
                ]}
              />
            </Col>

            <Col>
              <FieldArray
                name='locationsSettings'
                render={() =>
                  values.locationSettings.map((setting, index) => (
                    <Card
                      hasBorder
                      key={setting.id}
                      mb={4}
                      title={setting.name}
                    >
                      <Row>
                        <Col md={4}>
                          <Field
                            isSelect
                            label={t('maxBookingsPerMemberPerDay')}
                            name={`locationSettings[${index}].maxBookingsPerAthletePerDay`}
                            options={[
                              { label: '1', value: 1 },
                              { label: '2', value: 2 },
                              { label: '3', value: 3 },
                              { label: '4', value: 4 },
                              { label: '5', value: 5 },
                            ]}
                          />
                        </Col>

                        <Col md={4}>
                          <Field
                            isSelect
                            label={t('displayBookingDetailsInClass')}
                            name={`locationSettings[${index}].isDisplayBookingDetails`}
                            options={[
                              { label: t('yes'), value: 1 },
                              { label: t('no'), value: 0 },
                            ]}
                          />
                        </Col>

                        <Col md={4}>
                          <Field
                            isSelect
                            label={t('displayBookedMembersInClass')}
                            name={`locationSettings[${index}].isViewClassBookings`}
                            options={[
                              { label: t('yes'), value: 1 },
                              { label: t('no'), value: 0 },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Card>
                  ))
                }
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
