import { Container, Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useBulkUploadCreateBankingDetails,
  useBulkUploadCreateUsers,
} from 'octiv-hooks/requests/BulkUpload';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import React, { useState } from 'react';

import Form from './Form';
import TableResponseBankDetails from './TableResponseBankDetails';
import TableResponseUsers from './TableResponseUsers';

export default () => {
  const [toggle, setToggle, resetToggle] = useToggle();
  // TODO: either integrate this type with the above toggle, or find a less semantically confusing term for this
  const [toggleType, setToggleType] = useState();

  const { isLoading: isCreatingUploadUsers, mutate: postUploadUsersRequest } =
    useBulkUploadCreateUsers({
      onSuccess: (response) => {
        setToggle({ type: toggleType, data: response });
      },
    });

  const {
    isLoading: isCreatingUploadBankDetails,
    mutate: postUploadBankDetailsRequest,
  } = useBulkUploadCreateBankingDetails({
    onSuccess: (response) => {
      setToggle({ type: toggleType, data: response });
    },
  });

  const { data: tenants, isFetching: isFetchingTenants } = useTenantsFind({
    filter: { statusId: 1 },
    paging: { perPage: -1 },
  });

  const onSubmitForm = ({ type, ...values }) => {
    if (type === 'users') {
      setToggleType(type);
      postUploadUsersRequest({
        ...values,
      });
    } else {
      setToggleType(type);
      postUploadBankDetailsRequest({
        ...values,
      });
    }
  };

  const toggleDataHasError = !!toggle.data?.[0]?.invalidField;

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={toggleDataHasError ? 'Errors' : 'Response Data'}
          onClose={resetToggle}
        >
          {toggle.type === 'users' ? (
            <TableResponseUsers data={toggle.data} />
          ) : (
            <TableResponseBankDetails
              data={toggle.data}
              hasError={toggleDataHasError}
            />
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: 'Import Data',
          breadcrumbs: ['Facilities'],
        }}
        isLoading={isCreatingUploadUsers || isCreatingUploadBankDetails}
      >
        <Form
          isFetchingTenants={isFetchingTenants}
          isLoading={isCreatingUploadUsers || isCreatingUploadBankDetails}
          tenants={tenants?.data || []}
          onSubmit={onSubmitForm}
        />
      </Container>
    </>
  );
};
