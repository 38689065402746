import { Images } from 'octiv-assets';
import { Box, Image, Text } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DownloadApp = ({ isFullscreen = false, ...props }) => {
  const { t } = useTranslation();

  const { mdDown } = useMediaQuery();

  return (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      backgroundImage={`url(${Images.mockup})`}
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      borderColor='primary'
      borderStyle='solid'
      borderWidth='1px'
      flexDirection={mdDown ? 'column' : 'row'}
      justifyContent={mdDown ? 'center' : 'space-between'}
      py={4}
      {...(isFullscreen
        ? {
            hasRadius: false,
            border: 'none',
            flexDirection: 'column',
            justifyContent: 'center',
            p: 6,
            height: '100vh',
          }
        : {})}
      {...props}
    >
      {isFullscreen && (
        <>
          <Image
            alt={t('octivLogo')}
            bg='transparent'
            mb={4}
            size={24}
            src={Images.logomark}
          />

          <Text mb={3} textAlign='center' variant='display'>
            {t('downloadOctiv')}
          </Text>
        </>
      )}

      <Text
        maxWidth={isFullscreen ? '31rem' : undefined}
        mb={mdDown || isFullscreen ? 4 : undefined}
        mx={4}
        textAlign='center'
        variant='subheading'
      >
        {t('downloadOctivDescription')}
      </Text>

      <Box isFlex mx={4}>
        <a href='https://apps.apple.com/za/app/octiv/id1533669918'>
          <Image
            alt={t('downloadAppStore')}
            backgroundSize='contain'
            bg='transparent'
            height={12}
            src={Images.appStoreApple}
            width={36}
          />
        </a>

        <Box width={4} />

        <a href='https://play.google.com/store/apps/details?id=com.octiv.mobile'>
          <Image
            alt={t('downloadGooglePlay')}
            backgroundSize='contain'
            bg='transparent'
            height={12}
            src={Images.appStoreGoogle}
            width={40}
          />
        </a>
      </Box>
    </Box>
  );
};

export default DownloadApp;
