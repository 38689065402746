import {
  Alert,
  Box,
  Container,
  ProcessingSpinner,
  Text,
  ToggleTabs,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  useSettingsFindWidgets,
  useSettingsUpdateWidgets,
} from 'octiv-hooks/requests/Settings';
import { domain } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../components/Setting';
import Form from './Form';
import FormLocation from './FormLocation';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [query, setQuery] = useState({
    locationId: undefined,
  });

  const findWidgets = useSettingsFindWidgets({
    filter: query,
  });

  const updateWidgets = useSettingsUpdateWidgets();

  const handleSubmit = (values) => {
    updateWidgets.mutate({ locationId: query?.locationId, ...values });
  };

  const renderIframe = ({ url }) => (
    <Text as='span' color='grey1'>
      {`<iframe
        src='${domain}${url}'
        frameBorder='0'
        height='1000px'
        width='100%'
      >
        ${t('browserDoesNotSupportIframes')}
      </iframe>`}
    </Text>
  );

  const renderUrl = ({ url }) => (
    <Text as='a' color='primary' href={`${domain}${url}`} target='_blank'>
      {`${domain}${url}`}
    </Text>
  );

  const renderWidgets = () =>
    [
      {
        title: t('signUp'),
        url: `/widget/sign-up?publicToken=${findWidgets.data.publicToken}`,
        alert: {
          variant: 'warning',
          subtitle: t('usageOfIframeNotRecommended'),
        },
      },
      {
        title: t('packages'),
        url: `/widget/packages?publicToken=${findWidgets.data.publicToken}`,
        alert: {
          variant: 'info',
          subtitle: t('useThisWidgetAlongWithSignUpWidget'),
        },
      },
      {
        title: t('scheduleWithEmbeddedDropIn'),
        url: `/widget/schedule?publicToken=${findWidgets.data.publicToken}`,
        alert: {
          variant: 'warning',
          subtitle: t('usageOfIframeNotRecommended'),
        },
      },
      {
        title: t('scheduleWithoutEmbeddedDropIn'),
        url: `/widget/schedule?hasEmbeddedDropIn=false&publicToken=${findWidgets.data.publicToken}`,
        alert: {
          variant: 'info',
          subtitle: t('useThisWidgetAlongWithDropInWidget'),
        },
      },
      {
        title: t('dropIn'),
        url: `/widget/schedule?isDropIn=true&publicToken=${findWidgets.data.publicToken}`,
        alert: {
          variant: 'warning',
          subtitle: t('usageOfIframeNotRecommended'),
        },
      },
      {
        title: t('workouts'),
        url: `/widget/workouts?publicToken=${findWidgets.data.publicToken}`,
      },
      {
        title: t('leads'),
        url: `/widget/leads?publicToken=${findWidgets.data.publicToken}`,
      },
      {
        title: t('leadsDemo'),
        url: `/widget/leads?isDemo=true&publicToken=${findWidgets.data.publicToken}`,
      },
    ].map(({ title, url, alert }, index) => (
      <React.Fragment key={title}>
        <Text mb={3} mt={index > 0 ? 8 : 6} variant='subheading'>
          {title}
        </Text>

        {!!alert && <Alert mb={4} {...alert} />}

        <Box hasRadius bg='grey5'>
          <ToggleTabs
            options={[
              {
                label: t('url'),
                children: (
                  <Box borderTop={[1]} borderTopColor='grey4' p={4}>
                    {renderUrl({ url })}
                  </Box>
                ),
              },
              {
                label: t('iframe'),
                children: (
                  <Box borderTop={[1]} borderTopColor='grey4' p={4}>
                    {renderIframe({ url })}
                  </Box>
                ),
              },
            ]}
          />
        </Box>
      </React.Fragment>
    ));

  return (
    <Container
      appBarProps={{
        title: t('widgets'),
        breadcrumbs: [t('settings')],
      }}
    >
      <Alert mb={4} subtitle={t('useBelowLocationDropdown')} variant='info' />

      {!findWidgets.isFetching ? (
        <>
          <FormLocation
            initialValues={query}
            locationOptions={locationOptionsAll}
            onSubmit={({ locationId }) => {
              setQuery({ locationId });
              // NOTE: request wouldn't trigger with just the setQuery update
              setTimeout(() => {
                findWidgets?.refetch();
              }, 100);
            }}
          />

          <Box mb={4} />

          <Setting title={t('settings')}>
            <Form
              initialValues={findWidgets.data}
              isLoading={updateWidgets.isLoading}
              onSubmit={handleSubmit}
            />
          </Setting>

          <Setting title={t('urlsIframes')}>
            <Alert
              mb={4}
              subtitle={t('usageOfIframesDescription')}
              title={t('usageOfIframes')}
              variant='danger'
            />

            {renderWidgets()}
          </Setting>
        </>
      ) : (
        <ProcessingSpinner m='auto' />
      )}
    </Container>
  );
};
