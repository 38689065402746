import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useAddressesFind = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessPrivileges', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/addresses`,
        params: {
          filter: {
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const useAddressesFindById = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessPrivileges', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/addresses/${input.id}`,
        params: {
          filter: {
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const useAddressesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/addresses`,
        data: {
          locationId: data.locationId,
          address: data.address,
          streetAddress: data.streetAddress,
          city: data.city,
          stateProvinceRegion: data.stateProvinceRegion,
          postalCode: data.postalCode,
          countryCode: data.countryCode,
          latitude: data.latitude,
          longitude: data.longitude,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAddressesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/addresses/${data.id}`,
        data: {
          // locationId: data.locationId,
          address: data.address,
          streetAddress: data.streetAddress,
          city: data.city,
          stateProvinceRegion: data.stateProvinceRegion,
          postalCode: data.postalCode,
          countryCode: data.countryCode,
          latitude: data.latitude,
          longitude: data.longitude,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['addresses'] });

      options?.onSuccess?.(...res);
    },
  });
};
