import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, isFetching, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        userType: Yup.string().required(t('required')),
        search: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        values: { userType },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                canSubmitOnChange
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={6}>
              <Field
                canSubmitOnChange
                isSelect
                doFinally={() => {
                  setFieldTouched('search', false);
                  setFieldValue('search', undefined);
                }}
                isDisabled={isFetching}
                label={t('userType')}
                name='userType'
                options={[
                  { label: t('member'), value: 'member' },
                  { label: t('lead'), value: 'lead' },
                  { label: t('staff'), value: 'staff' },
                  { label: t('nonMember'), value: 'nonMember' },
                ]}
              />
            </Col>

            {userType !== 'nonMember' && (
              <Col md={6}>
                <Field
                  autoFocus
                  hasSubmitButtonSearch
                  label={t('search')}
                  name='search'
                />
              </Col>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
