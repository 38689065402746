import { Alert, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useLocationPaymentGatewaysFindDebitOrderThreePeaksDetails,
  useLocationPaymentGatewaysUpdateDebitOrderThreePeaksDetails,
} from 'octiv-hooks/requests/LocationPaymentGateways';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const {
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useLocationPaymentGatewaysFindDebitOrderThreePeaksDetails();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useLocationPaymentGatewaysUpdateDebitOrderThreePeaksDetails({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onFormSubmit = (values) =>
    putSettingsRequest({
      id: toggle.data.id,
      ...values,
    });

  const hasNotOnboarded = !!settings?.find(
    (item) => item?.settings?.cref === null || item?.settings?.cref === ''
  );

  return (
    <>
      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`Three Peaks ${t('details')}`}
          onClose={resetToggle}
        >
          <Form
            initialValues={toggle.data}
            isLoading={isUpdatingSettings}
            onSubmit={onFormSubmit}
          />
        </Modal>
      )}

      <Setting title='Three Peaks'>
        {hasNotOnboarded && (
          <Alert
            mb={4}
            subtitle={t('locationNotOnboardedTo', {
              paymentGateway: 'Three Peaks',
            })}
            variant='danger'
          />
        )}

        <Table
          hasAlternateBackground
          data={settings || []}
          isLoading={isFetchingSettings}
          locationOptions={locationOptions}
          onClickAction={({ id }) =>
            setToggle({
              type: 'update',
              data: settings?.find((item) => item?.id === id),
            })
          }
        />
      </Setting>
    </>
  );
};
