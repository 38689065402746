import { InlineUser, Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  translateSessionsAvailableText,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => <InlineUser user={value} />,
        // sortBy: 'user',
      },
      {
        Header: t('name'),
        accessor: 'package',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: t('start'),
        accessor: 'startDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
        sortBy: 'start_date',
      },
      {
        Header: t('end'),
        accessor: 'endDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
        sortBy: 'end_date',
      },
      {
        Header: t('sessionsAvailable'),
        accessor: 'sessionsAvailableText',
        Cell: ({ value }) => translateSessionsAvailableText({ text: value }),
      },
      {
        Header: t('futureBookings'),
        accessor: 'upcomingClassBookingsCount',
      },
      {
        Header: t('status'),
        id: 'status',
        Cell: ({
          row: {
            original: { isActive, package: tenantPackage },
          },
        }) => (
          <>
            <Text as='span'>{isActive ? t('active') : t('inactive')}</Text>
            {tenantPackage.isActive ? (
              ''
            ) : (
              <Text as='span' color='danger'>
                {` (${t('mainPackageDeactivated')})`}
              </Text>
            )}
          </>
        ),
      },
    ],
    [t]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'endDate',
        desc: true,
      },
    ],
    []
  );

  return (
    <Table {...props} columns={tableColumns} data={tableData} sortBy={sortBy} />
  );
};
