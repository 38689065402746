import { useQuery } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useTimezonesFind = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['timezones', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/timezones`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useTimezonesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['timezones', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/timezones/${id}`,
      }),
    options
  );
};
