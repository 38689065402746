import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingPackageEvents,
  isLoading,
  packageEvents,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        classIds: isFetchingPackageEvents
          ? []
          : packageEvents
              .filter((item) => item.isChecked)
              .map((item) => item.class.id),
      }}
      validationSchema={Yup.object().shape({
        classIds: Yup.array(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                hasSelectAll
                isArray
                isCheckbox
                isLoading={isFetchingPackageEvents}
                label={t('classes')}
                name='classIds'
                options={packageEvents.map(
                  ({
                    class: { id, name, startTime, endTime, activeDays },
                  }) => ({
                    label: getEventString({
                      name,
                      startTime,
                      endTime,
                      recurringDays: activeDays,
                    }),
                    value: id,
                  })
                )}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
