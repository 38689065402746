import { Box } from 'octiv-components';
import React from 'react';

const ProcessingSuspense = ({
  items = [
    { width: '25%' },
    { width: '45%' },
    { width: '40%' },
    { width: '50%' },
  ],
  ...props
}) =>
  items.map((item, index) => (
    <Box
      hasRadius
      bg='grey5'
      height={4}
      key={`${index.toString()}`}
      mb={index < items.length - 1 ? 2 : undefined}
      sizeRadius={1}
      width='25%'
      {...props}
      {...item}
    />
  ));

export default ProcessingSuspense;
