import { Table } from 'octiv-components';
import React, { useMemo } from 'react';

export default ({ data, isLoading, onChangeRecipients, columns, ...props }) => {
  const onSelect = (row) => onChangeRecipients({ add: [row] });

  const onSelectAll = () => onChangeRecipients({ add: data });

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(() => columns, [columns]);

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
      isLoading={isLoading}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
    />
  );
};
