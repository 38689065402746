import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('submissionId'),
        accessor: 'submissionId',
      },
      {
        Header: t('actionDate'),
        accessor: 'actionDate',
      },
      {
        Header: t('records'),
        accessor: 'records',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
      },
      {
        Header: t('submissionStatus'),
        accessor: 'submissionStatus',
      },
      {
        Header: t('rejectedRecords'),
        accessor: 'rejectedRecords',
      },
      {
        Header: t('rejectedAmount'),
        accessor: 'rejectedAmount',
      },
      {
        Header: t('unpaidRecords'),
        accessor: 'unpaidRecords',
      },
      {
        Header: t('unpaidAmount'),
        accessor: 'unpaidAmount',
      },
      {
        Header: t('lateUnpaidRecords'),
        accessor: 'lateUnpaidRecords',
      },
      {
        Header: t('lateUnpaidAmount'),
        accessor: 'lateUnpaidAmount',
      },
      {
        Header: t('validationStatus'),
        accessor: 'validationStatus',
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
