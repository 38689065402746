import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useBodyMeasurementsFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['bodyMeasurements', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/body-measurements`,
        params: {
          filter: {
            tenantId: filter.tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            recordedAt: filter.recordedAt,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useBodyMeasurementsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/body-measurements`,
        data: {
          userId: data.userId,
          tricep: data.tricep,
          subscapular: data.subscapular,
          abdominal: data.abdominal,
          suprailiac: data.suprailiac,
          thigh: data.thigh,
          calf: data.calf,
          bodyFatPercentage: data.bodyFatPercentage,
          recordedAt: data.recordedAt,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyMeasurements'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useBodyMeasurementsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/body-measurements/${id}`,
        data: {
          tricep: data.tricep,
          subscapular: data.subscapular,
          abdominal: data.abdominal,
          suprailiac: data.suprailiac,
          thigh: data.thigh,
          calf: data.calf,
          bodyFatPercentage: data.bodyFatPercentage,
          recordedAt: data.recordedAt,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyMeasurements'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useBodyMeasurementsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/body-measurements/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyMeasurements'] });

      options?.onSuccess?.(...res);
    },
  });
};
