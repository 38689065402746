import { Box, ProcessingSpinner } from 'octiv-components';
import React from 'react';

const FullscreenProcessingSpinner = () => {
  return (
    <Box
      isFlex
      alignItems='center'
      bg='grey7'
      bottom={0}
      justifyContent='center'
      left={0}
      position='absolute'
      right={0}
      top={0}
    >
      <ProcessingSpinner />
    </Box>
  );
};

export default FullscreenProcessingSpinner;
