import { Table, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { id, isActive },
          },
        }) => (
          <Tooltip
            stopPropagation
            options={[
              { label: t('viewEdit'), value: 'update' },
              {
                label: isActive ? t('deactivate') : t('activate'),
                value: 'updateStatus',
              },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
