/* eslint-disable */
import './Styles.css';

// FullCalendar must always be imported first
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
/* eslint-enable */

import moment from 'moment';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Calendar = (props) => {
  const { t, i18n } = useTranslation();

  const { xsDown, lgDown } = useMediaQuery();

  return (
    <FullCalendar
      navLinks
      nowIndicator
      aspectRatio={lgDown ? 0.95 : 1.95}
      buttonIcons={false}
      buttonText={{
        today: t('today'),
        month: t('month'),
        week: t('week'),
        day: t('day'),
        list: t('list'),
        prev: xsDown ? '<' : t('prev'),
        next: xsDown ? '>' : t('next'),
      }}
      dayHeaderFormat={({ date }) =>
        moment(date.array).locale(i18n.resolvedLanguage).format('ddd DD')
      }
      eventColor='transparent'
      eventDisplay='block'
      headerToolbar={{
        start: xsDown ? 'prev,next' : 'today prev,next',
        center: 'title',
        end: xsDown
          ? 'timeGridDay,listWeek'
          : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
      }}
      initialView={xsDown ? 'timeGridDay' : 'dayGridMonth'}
      locale={i18n.resolvedLanguage}
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
      slotDuration='00:15:00'
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }}
      views={{
        dayGridMonth: {
          dayHeaderFormat: ({ date }) =>
            moment(date.marker).locale(i18n.resolvedLanguage).format('ddd'),
        },
      }}
      weekNumberCalculation='ISO'
      {...props}
    />
  );
};

export default Calendar;
