import { Formik } from 'formik';
import { Alert, Button, Field, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, isCreate, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        secretKey: initialValues.secretKey || undefined,
        publicKey: initialValues.publicKey || undefined,
        isForSignUp: initialValues.isForSignUp ? initialValues.isForSignUp : 1,
        ...(isCreate
          ? {}
          : {
              isActive: initialValues.isActive,
            }),
      }}
      validationSchema={Yup.object().shape({
        secretKey: Yup.string().required(t('required')),
        publicKey: Yup.string().required(t('required')),
        isForSignUp: Yup.number(),
        ...(isCreate
          ? {}
          : {
              isActive: Yup.number().required(t('required')),
            }),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Alert
            mb={4}
            subtitle={`${t('stripeSetupWebhook')} ${
              initialValues.publicToken
                ? t('stripeSetupUrl')
                : t('stripeSetupDetails')
            }`}
            variant='info'
          />

          {initialValues.publicToken && (
            <>
              <Text color='grey1' variant='caption'>
                {t('webhookUrl')}
              </Text>
              <Text mb={4} variant='caption'>
                {`https://api.octivfitness.com/api/public/stripe/events/${initialValues.publicToken}`}
              </Text>
            </>
          )}

          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('secretKey')}
            name='secretKey'
          />

          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('publicKey')}
            name='publicKey'
          />

          <Field
            isCheckbox
            boxContainerProps={{ mb: 4 }}
            name='isForSignUp'
            options={[
              {
                label: t('useGatewayForLimitedPackagePurchases', {
                  paymentGateway: 'Stripe',
                }),
                value: 1,
              },
            ]}
          />

          {!isCreate && (
            <Field
              isHorizontal
              isRadio
              boxContainerProps={{ mb: 4 }}
              label={t('gatewayStatus')}
              name='isActive'
              options={[
                { label: t('active'), value: 1 },
                { label: t('deactivated'), value: 0 },
              ]}
            />
          )}

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
