import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
        locationId: Yup.string(),
        sentStatus: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} xl={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('sentUnsent')}
                name='sentStatus'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('sent'), value: 'sent' },
                  { label: t('unsent'), value: 'unsent' },
                ]}
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>

            <Col md={6} xl={3}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('memberCode')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
