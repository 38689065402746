import { Modal, ProcessingSuspense } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useCrmFindSettings,
  useCrmUpdateSettings,
} from 'octiv-hooks/requests/Crm';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const { data: settings, isFetching: isFetchingSettings } = useCrmFindSettings(
    {
      filter: {
        locationId: toggle.data?.id,
      },
    },
    {
      enabled: toggle.data?.id !== undefined,
    }
  );

  const crmUpdateSettings = useCrmUpdateSettings({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const handleSubmit = (values) => {
    crmUpdateSettings.mutate({
      id: settings?.id,
      locationId: toggle.data.id,
      ...values,
    });
  };

  return (
    <Setting title={t('locationDetails')}>
      {toggle.isVisible && (
        <Modal isSidebar title={t('locationDetails')} onClose={resetToggle}>
          {isFetchingSettings ? (
            <ProcessingSuspense />
          ) : (
            <Form
              initialValues={settings || {}}
              isLoading={crmUpdateSettings.isLoading}
              onSubmit={handleSubmit}
            />
          )}
        </Modal>
      )}

      <Table
        hasAlternateBackground
        data={locations}
        onClickAction={setToggle}
      />
    </Setting>
  );
};
