import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        status: Yup.string(),
        type: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='status'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('pending'), value: 'pending' },
                  { label: t('dropIn'), value: 'drop_in' },
                  { label: t('contacted'), value: 'contacted' },
                  { label: t('needsFollowUp'), value: 'follow_up' },
                  { label: t('requestedDemo'), value: 'request_demo' },
                  { label: t('converted'), value: 'converted' },
                  { label: t('other'), value: 'other' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('from')}
                name='type'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('leadWidget'), value: 'website' },
                  { label: t('referred'), value: 'referral' },
                  { label: t('dropIn'), value: 'drop_in' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
