import moment from 'moment';
import { Box, Button, Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useFinancesDeletePayments,
  useFinancesFindPayments,
  useFinancesFindPaymentsExport,
  useFinancesUpdatePayments,
} from 'octiv-hooks/requests/Finances';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { tagsTypes } from 'octiv-utilities/Constants';
import { downloadFile, getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormRecordPayment from '../invoice/FormRecordPayment';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenantId,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    userTenantStatusId: 2,
    userTenantId: undefined,
    invoiceMemberType: 'athleteInvoicePayments',
    type: undefined,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const payments = useFinancesFindPayments({ filter: query, paging });

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers({
    filter: {
      locationId: query.locationId,
      userTenantStatusId: query.userTenantStatusId,
      userGroup: 'gymMembers',
      view: 'members',
      isMinimalData: true,
    },
    paging: { perPage: -1 },
  });

  const { data: paymentTags, isFetching: isFetchingPaymentTags } = useTagsFind(
    { filter: { type: tagsTypes.PAYMENT_PROCESSOR, tenantId } },
    {
      enabled: toggle.isVisible,
    }
  );

  const paymentsUpdate = useFinancesUpdatePayments({
    onSuccess: () => {
      payments.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const deletePayment = useFinancesDeletePayments({
    onSuccess: payments.refetch,
    meta: {
      useSuccessToast: true,
    },
  });

  const {
    isFetching: isFetchingPaymentsExport,
    refetch: getPaymentsExportRequest,
  } = useFinancesFindPaymentsExport(
    { filter: query },
    {
      enabled: false,
      onSuccess: downloadFile,
    }
  );

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: payments.data?.data?.find?.((item) => item.id === id),
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deletePayment.mutate({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('payment')} onClose={resetToggle}>
          <FormRecordPayment
            initialValues={toggle.data}
            isFetchingPaymentTags={isFetchingPaymentTags}
            isLoading={paymentsUpdate.isLoading}
            paymentTags={paymentTags?.data || []}
            onSubmit={(values) => {
              paymentsUpdate.mutate({
                // TODO: check if multi-select is desired here
                id: toggle.data.id || toggle.data?.[0].id,
                ...values,
              });
            }}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('payments'),
          breadcrumbs: [t('accounts')],
          children: (
            <Box ml='auto'>
              <Button
                hasBorder
                text={t('csvExport')}
                onClick={getPaymentsExportRequest}
              />
            </Box>
          ),
        }}
        isLoading={
          payments.isFetching ||
          isFetchingPaymentsExport ||
          deletePayment.isLoading
        }
      >
        <FormQuery
          initialValues={query}
          isFetchingMembers={isFetchingMembers}
          locationOptions={locationOptionsAll}
          members={members?.data || []}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <Table
          data={payments.data?.data || []}
          isLoading={payments.isFetching}
          paging={payments.data?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
