import { Card, Image, Modal, Toast, ToggleTabs } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useSettingsFindSignUp } from 'octiv-hooks/requests/Settings';
import {
  useUsersFind,
  useUsersFindStaff,
  useUsersUpdate,
} from 'octiv-hooks/requests/Users';
import {
  useUserTenantsFindById,
  useUserTenantsUpdate,
} from 'octiv-hooks/requests/UserTenants';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FormUser from '../user/profile/FormUser';
import Billing from './billing';
import Composition from './composition';
import Contracts from './contracts';
import Coronavirus from './coronavirus';
import FormUserTenant from './FormUserTenant';
import Injuries from './injuries';
import LogBook from './logBook';
import Overview from './overview';
import Packages from './packages';
import Weight from './weight';

export const views = {
  OVERVIEW: 'overview',
  PROFILE: 'profile',
  CONTRACTS: 'contracts',
  PACKAGES: 'packages',
  BILLING: 'billing',
  INJURIES: 'injuries',
  COMPOSITION: 'composition',
  WEIGHT: 'weight',
  LOG_BOOK: 'logBook',
  CORONAVIRUS: 'coronavirus',
};

export default () => {
  const { t } = useTranslation();

  const { initialView, onClose, resetMemberModal, ...memberModal } =
    useMemberModal();
  const hasAccess = useContext(AccessContext);
  const {
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [selectedView, setSelectedView] = useState(initialView);

  // If the userTenantId is not provided, we need to get the userTenant by fetching the user first
  // For example, when fetching injuries a userTenant is not included in the response
  const usersFind = useUsersFind(
    { filter: { userId: memberModal.userId } },
    {
      enabled: !!memberModal.userId && !memberModal.userTenantId,
    }
  );

  const userTenantsFindById = useUserTenantsFindById(
    {
      id: memberModal.userTenantId || usersFind.data?.data?.[0]?.userTenant?.id,
      include: 'user,locations',
      append: 'finance_details',
    },
    {
      enabled: !!(
        memberModal.userTenantId || usersFind.data?.data?.[0]?.userTenant?.id
      ),
    }
  );

  React.useEffect(() => {
    if (userTenantsFindById.data && userTenantsFindById.data.typeId !== 4) {
      resetMemberModal();

      toast.error(
        <Toast
          body={'Invalid user type for the member modal.'}
          title={t('error')}
          variant='danger'
        />
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, userTenantsFindById.data]);

  const userTenant = userTenantsFindById.data;
  const userTenantId = userTenant?.id;
  const user = userTenant?.user;
  const userId = user?.id;

  const settingsFindSignUp = useSettingsFindSignUp({});

  const usersFindStaff = useUsersFindStaff({
    filter: { isMinimalData: true },
    paging: { perPage: -1 },
  });

  const programmesFind = useProgrammesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const userTenantsUpdate = useUserTenantsUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const usersUpdate = useUsersUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const returnView = () => {
    switch (selectedView) {
      case views.OVERVIEW:
        return (
          <Overview
            getUserTenantRequest={userTenantsFindById.refetch}
            isFetchingUserTenant={
              usersFind.isFetching || userTenantsFindById.isFetching
            }
            userTenant={userTenant}
          />
        );

      case views.PROFILE:
        return (
          <>
            <Card hasBorder mb={4} title={t('userDetails')}>
              <FormUser
                includePassword={false}
                initialValues={user}
                isLoading={
                  usersUpdate.isLoading || settingsFindSignUp.isFetching
                }
                signUpRequiredFields={
                  settingsFindSignUp?.data?.signUpRequiredFields
                }
                onSubmit={usersUpdate.mutate}
              />
            </Card>

            <Card hasBorder title={t('accountDetails')}>
              <FormUserTenant
                initialValues={userTenant}
                isFetchingProgrammes={programmesFind.isFetching}
                isFetchingStaff={usersFindStaff.isFetching}
                isLoading={userTenantsUpdate.isLoading}
                locationOptions={locationOptions}
                programmes={programmesFind.data?.data || []}
                staff={usersFindStaff.data?.data || []}
                onSubmit={({ memberDetails }) =>
                  userTenantsUpdate.mutate({
                    id: userTenantId,
                    ...memberDetails,
                  })
                }
              />
            </Card>
          </>
        );

      case views.CONTRACTS:
        return <Contracts userId={userId} />;

      case views.PACKAGES:
        return (
          <Packages
            userId={userId}
            userTenantId={userTenantId}
            userTenantLocationId={userTenant?.location?.id}
          />
        );

      case views.BILLING:
        return (
          <Billing
            getUserTenantRequest={userTenantsFindById.refetch}
            userTenantId={userTenantId}
          />
        );

      case views.INJURIES:
        return <Injuries userId={userId} />;

      case views.COMPOSITION:
        return (
          <Composition
            isFetchingUserTenant={userTenantsFindById.isFetching}
            user={user}
          />
        );

      case views.WEIGHT:
        return <Weight userId={userId} />;

      case views.LOG_BOOK:
        return <LogBook userId={userId} />;

      case views.CORONAVIRUS:
        return <Coronavirus userId={userId} />;

      default:
        return null;
    }
  };

  return userId ? (
    <Modal
      isLoading={userTenantsFindById.isFetching || userTenantsUpdate.isLoading}
      renderAboveContent={() => (
        <ToggleTabs
          activeKey={selectedView}
          mt={1}
          options={[
            { label: t('overview'), key: views.OVERVIEW },
            { label: t('profile'), key: views.PROFILE },
            ...(hasAccess.featureMembersManageContracts
              ? [{ label: t('contracts'), key: views.CONTRACTS }]
              : []),
            ...(hasAccess.featureMembersManagePackages
              ? [{ label: t('packages'), key: views.PACKAGES }]
              : []),
            ...(hasAccess.featureMembersManageBilling
              ? [{ label: t('billing'), key: views.BILLING }]
              : []),
            ...(hasAccess.featureMembersManageInjuries
              ? [{ label: t('injuries'), key: views.INJURIES }]
              : []),
            { label: t('composition'), key: views.COMPOSITION },
            { label: t('weight'), key: views.WEIGHT },
            { label: t('logbook'), key: views.LOG_BOOK },
            ...(hasAccess.containerCoronavirus
              ? [{ label: t('coronavirus'), key: views.CORONAVIRUS }]
              : []),
          ]}
          px={4}
          setActiveKey={(newKey) => {
            if (newKey === views.OVERVIEW) userTenantsFindById.refetch();
            setSelectedView(newKey);
          }}
        />
      )}
      renderLeftTitle={
        !(userTenantsFindById.isFetching && !userTenant?.id) &&
        selectedView !== views.OVERVIEW
          ? () => {
              return (
                <Image
                  hasRadius
                  usePlaceholderUser
                  mr={2}
                  size={6}
                  sizeRadius={1}
                  src={user?.image}
                />
              );
            }
          : undefined
      }
      title={
        userTenantsFindById.isFetching && !userTenant?.id
          ? ''
          : getFullName(user)
      }
      onClose={() => {
        resetMemberModal();
        if (onClose) onClose();
      }}
    >
      {userTenant?.id && returnView()}
    </Modal>
  ) : null;
};
