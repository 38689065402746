import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, strategy, ...props }) => {
  const { t } = useTranslation();

  const numberValidation =
    strategy === 'member_based_rate'
      ? Yup.number().typeError(t('mustBeANumber')).required(t('required'))
      : Yup.number();

  return (
    <Formik
      {...props}
      initialValues={{
        amount: undefined,
        minMembers: undefined,
        maxMembers: undefined,
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.string().required(t('required')),
        minMembers: numberValidation,
        maxMembers: numberValidation,
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label={t('amount')} name='amount' />
            </Col>

            {strategy === 'member_based_rate' && (
              <>
                <Col>
                  <Field label={t('minimumMembers')} name='minMembers' />
                </Col>

                <Col>
                  <Field label={t('maximumMembers')} name='maxMembers' />
                </Col>
              </>
            )}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
