import { Box, Icon, ProcessingSpinner, Text } from 'octiv-components';
import React from 'react';
import { Link } from 'react-router-dom';

const Chip = ({
  color = 'primary',
  hasAlternateBackground,
  icon,
  iconProps,
  isLoading,
  textTitleProps,
  title,
  to,
  ...props
}) => (
  <Box display='inline-block'>
    <Box
      hasRadius
      isFlex
      alignItems='center'
      as={to ? Link : undefined}
      bg={hasAlternateBackground ? 'grey5' : 'grey6'}
      height={7}
      to={to}
      {...props}
    >
      {isLoading ? (
        <ProcessingSpinner mx={2} />
      ) : icon ? (
        <Icon color={color} mx={2} name={icon} scale='small' {...iconProps} />
      ) : null}

      {icon && title && (
        <Box
          bg={hasAlternateBackground ? 'grey4' : 'grey5'}
          height='60%'
          width='1px'
        />
      )}

      {title && (
        <Text
          lineHeight='normal'
          ml={icon ? 2 : 4}
          mr={4}
          variant='caption'
          {...textTitleProps}
        >
          {title}
        </Text>
      )}
    </Box>
  </Box>
);

export default Chip;
