import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Col,
  Icon,
  Image,
  Modal,
  ProcessingBar,
  ProcessingSuspense,
  Row,
  Text,
  Tooltip,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useEventModal } from 'octiv-context/EventModal';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useToggle } from 'octiv-hooks';
import { useClassBookingsFindStats } from 'octiv-hooks/requests/ClassBookings';
import { useInjuriesFind } from 'octiv-hooks/requests/Injuries';
import {
  useOnHoldUsersCreate,
  useOnHoldUsersFind,
  useOnHoldUsersUpdate,
  useOnHoldUsersUpdateCancel,
  useOnHoldUsersUpdateRelease,
} from 'octiv-hooks/requests/OnHoldUsers';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import {
  useScheduledUserActionsCreate,
  useScheduledUserActionsDelete,
  useScheduledUserActionsFind,
} from 'octiv-hooks/requests/ScheduledUsersActions';
import {
  useUserContractsCreateDownload,
  useUserContractsCreateDownloadAttachment,
  useUserContractsCreateSend,
  useUserContractsCreateSendAttachment,
} from 'octiv-hooks/requests/UserContracts';
import { useUserPackagesFind } from 'octiv-hooks/requests/UserPackages';
import {
  useUserTenantsFindContractsWaivers,
  useUserTenantsUpdateBulkHighRisk,
  useUserTenantsUpdateBulkStatus,
} from 'octiv-hooks/requests/UserTenants';
import {
  useWaiversCreateDownload,
  useWaiversFindById,
  useWaiversUpdateBulkSend,
} from 'octiv-hooks/requests/Waivers';
import {
  downloadFile,
  getAge,
  getDateReadableDayMonthYear,
  getMandateStatusDetails,
  getWaiverOptions,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormDeactivate from './FormDeactivate';
import FormOnHold from './FormOnHold';
import FormReleaseOnHold from './FormReleaseOnHold';
import TableOnHoldUserTenant from './TableOnHoldUserTenant';
import TableScheduledActions from './TableScheduledActions';

export default ({ isFetchingUserTenant, userTenant = {} }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { resetMemberModal } = useMemberModal();
  const { resetEventModal } = useEventModal();
  const {
    tenantId,
    tenant: { isGoCardless, isSepa },
    toCurrency,
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [toggleWaiver, setToggleWaiver, resetToggleWaiver] = useToggle();

  const {
    assignedTrainer,
    createdAt,
    isHighRisk,
    statusId,
    status,
    programmeId,
    financeDetails,
    user: { id: userId, dateOfBirth, email, image, mobile } = {},
  } = userTenant;

  const injuriesFind = useInjuriesFind({
    filter: { tenantId, userId, status: 'injured' },
    paging: { perPage: 1 },
  });

  const classBookingsFindStats = useClassBookingsFindStats({
    filter: { userId },
  });

  const userPackagesFind = useUserPackagesFind({
    filter: { userId, isActive: 1 },
  });

  const userTenantsFindContractsWaivers = useUserTenantsFindContractsWaivers({
    filter: { userId },
  });
  const userContract =
    userTenantsFindContractsWaivers.data?.data?.[0]?.userContract;
  const waiver = userTenantsFindContractsWaivers.data?.data?.[0]?.waiver;

  const programmesFind = useProgrammesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const scheduledUserActionsFind = useScheduledUserActionsFind(
    { filter: { userId, status: 'pending' } },
    {
      enabled: hasAccess.featureMembersActions && !!userId,
    }
  );

  const onHoldUsersFind = useOnHoldUsersFind(
    {
      filter: { userId },
    },
    {
      enabled: !!userId && statusId === 6,
    }
  );
  const onHoldUser = onHoldUsersFind.data?.data?.[0];
  const onHoldUserId = onHoldUser?.id;

  const userContractsCreateSend = useUserContractsCreateSend({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateSendAttachment =
    useUserContractsCreateSendAttachment({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const userContractsCreateDownload = useUserContractsCreateDownload({
    onSuccess: (response) => downloadFile(response.file),
  });

  const userContractsCreateDownloadAttachment =
    useUserContractsCreateDownloadAttachment({
      onSuccess: (response) => downloadFile(response.file),
    });

  const waiversFindById = useWaiversFindById(
    { id: toggleWaiver.data?.id },
    {
      enabled: !!toggleWaiver.data?.id,
      onError: resetToggleWaiver,
    }
  );

  const waiversUpdateBulkSend = useWaiversUpdateBulkSend({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const waiversCreateDownload = useWaiversCreateDownload({
    onSuccess: downloadFile,
  });

  const userTenantsUpdateBulkHighRisk = useUserTenantsUpdateBulkHighRisk({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdateBulkStatus = useUserTenantsUpdateBulkStatus({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const scheduledUserActionsCreate = useScheduledUserActionsCreate({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const scheduledUserActionsDelete = useScheduledUserActionsDelete({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onHoldUsersCreate = useOnHoldUsersCreate({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onHoldUsersUpdateCancel = useOnHoldUsersUpdateCancel({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onHoldUsersUpdate = useOnHoldUsersUpdate({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onHoldUsersUpdateRelease = useOnHoldUsersUpdateRelease({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onSubmitUserScheduleAction = (values) => {
    if (toggle.type === 'updateOnHold') {
      onHoldUsersUpdate.mutate({ id: onHoldUserId, ...values });
    } else if (toggle.type === 'placeOnHold') {
      onHoldUsersCreate.mutate({ userIds: [userId], ...values });
    } else if (toggle.type === 'scheduleDeactivate') {
      scheduledUserActionsCreate.mutate({
        action: 'deactivate',
        userIds: [userId],
        ...values,
      });
    }
  };

  const onClickAction = ({ action, waiverId }) => {
    switch (action) {
      case 'email':
        window.location.href = `mailto: ${email}`;
        break;

      case 'call':
        window.location.href = `tel: ${mobile}`;
        break;

      case 'sendContract':
        userContractsCreateSend.mutate({ id: userContract?.id });
        break;

      case 'sendContractAttachment':
        userContractsCreateSendAttachment.mutate({ id: userContract?.id });
        break;

      case 'downloadContract':
        userContractsCreateDownload.mutate({ id: userContract?.id });
        break;

      case 'downloadContractAttachment':
        userContractsCreateDownloadAttachment.mutate({ id: userContract?.id });
        break;

      case 'viewWaiver':
        setToggleWaiver({ data: { id: waiverId } });
        break;

      case 'sendWaiverOriginal':
        waiversUpdateBulkSend.mutate({
          userIds: [userId],
          isLatest: false,
        });
        break;

      case 'sendWaiverLatest':
        waiversUpdateBulkSend.mutate({
          userIds: [userId],
          isLatest: true,
        });
        break;

      case 'downloadWaiver':
        waiversCreateDownload.mutate({ userId });
        break;

      case 'markHighRisk':
        userTenantsUpdateBulkHighRisk.mutate({
          userIds: [userId],
          isHighRisk: 1,
        });
        break;

      case 'removeHighRisk':
        userTenantsUpdateBulkHighRisk.mutate({
          userIds: [userId],
          isHighRisk: 0,
        });
        break;

      case 'activate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: [userId],
          statusId: 2,
        });
        break;

      case 'suspend':
        userTenantsUpdateBulkStatus.mutate({
          userIds: [userId],
          statusId: 3,
        });
        break;

      case 'deactivate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: [userId],
          statusId: 4,
        });
        break;

      case 'scheduleDeactivate':
        setToggle({ type: 'scheduleDeactivate' });
        break;

      case 'placeOnHold':
        setToggle({ type: 'placeOnHold' });
        break;

      case 'updateOnHold':
        setToggle({ type: 'updateOnHold' });
        break;

      case 'releaseOnHold':
        setToggle({ type: 'releaseOnHold' });
        break;

      case 'cancelOnHold':
        if (window.confirm(t('areYouSureProceed'))) {
          onHoldUsersUpdateCancel.mutate({ id: onHoldUserId });
        }
        break;

      default:
        break;
    }
  };

  if (isFetchingUserTenant && !userId) {
    return (
      <Box isFlex mb={1}>
        <ProcessingSuspense items={[{ width: 24, height: 24 }]} mr={4} />

        <Box width='50%'>
          <ProcessingSuspense
            height={6}
            items={[{ width: '25%' }, { width: '45%' }, { width: '40%' }]}
            mb={3}
          />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {waiversFindById.data && toggleWaiver.isVisible && (
        <Modal isSidebar title={t('waiver')} onClose={resetToggleWaiver}>
          <Alert
            mb={4}
            subtitle={
              waiversFindById.data?.status === 'sent'
                ? t('waiverHasNotBeenSigned')
                : `${t('waiverHasBeenSigned')}: ${getDateReadableDayMonthYear({
                    date: waiversFindById.data?.signedAt,
                  })}`
            }
            variant={
              waiversFindById.data?.status === 'sent' ? 'warning' : 'success'
            }
          />

          <Text
            as='div'
            dangerouslySetInnerHTML={{
              __html: waiversFindById.data?.digitalTermsAndConditions,
            }}
          />
        </Modal>
      )}

      {(onHoldUsersCreate.isLoading ||
        onHoldUsersUpdate.isLoading ||
        onHoldUsersUpdateCancel.isLoading ||
        scheduledUserActionsCreate.isLoading ||
        userContractsCreateDownload.isLoading ||
        userContractsCreateDownloadAttachment.isLoading ||
        userContractsCreateSend.isLoading ||
        userContractsCreateSendAttachment.isLoading ||
        waiversFindById.isFetching ||
        waiversUpdateBulkSend.isLoading ||
        waiversCreateDownload.isLoading ||
        userTenantsUpdateBulkHighRisk.isLoading ||
        userTenantsUpdateBulkStatus.isLoading ||
        onHoldUsersUpdateRelease.isLoading ||
        scheduledUserActionsDelete.isLoading) && <ProcessingBar />}

      <Box isFlex>
        <Box mr={4} position='relative' size={24}>
          <Image hasRadius usePlaceholderUser size={24} src={image} />

          <Box left='-0.5rem' position='absolute' top='-0.5rem'>
            {injuriesFind?.data?.data?.[0] ? (
              <Icon color='danger' mr={1} name='healing' />
            ) : null}

            {isHighRisk ? <Icon color='warning' name='warning' /> : null}
          </Box>
        </Box>

        <Box
          isFlex
          flex={1}
          flexDirection='column'
          justifyContent='space-between'
        >
          {[
            {
              canRender: !!status?.name,
              title: `${t('status')}: ${toCapital({ value: status?.name })}`,
              icon: 'assignment_ind',
            },
            {
              canRender: !!dateOfBirth,
              title: dateOfBirth
                ? `${getDateReadableDayMonthYear({
                    date: dateOfBirth,
                  })} (${getAge({ dateOfBirth })})`
                : null,
              icon: 'cake',
            },
          ].map(
            ({ canRender, icon, title }) =>
              canRender && (
                <Box isFlex alignItems='center' key={title}>
                  <Icon mr={2} name={icon} />
                  <Text>{title}</Text>
                </Box>
              )
          )}

          <Box isFlex flexWrap='wrap'>
            {[
              {
                canRender: !!email,
                title: t('email'),
                icon: 'email',
                onClick: () => onClickAction({ action: 'email' }),
              },
              {
                canRender: !!mobile,
                title: t('call'),
                icon: 'phone',
                onClick: () => onClickAction({ action: 'call' }),
              },
            ].map(
              ({ canRender, icon, title, ...restAction }) =>
                canRender && (
                  <Chip
                    hasAlternateBackground
                    icon={icon}
                    key={title}
                    mr={2}
                    title={title}
                    {...restAction}
                  />
                )
            )}
          </Box>
        </Box>
      </Box>

      <Box isFlex my={4}>
        {hasAccess.containerAccounts && (
          <Button
            mr={2}
            text={t('viewStatement')}
            to={`/accounts/statements?statusId=${statusId}&userId=${userId}`}
            onClick={() => {
              resetMemberModal();
              resetEventModal();
            }}
          />
        )}

        {hasAccess.featureMembersActions && (
          <Tooltip
            options={[
              ...(statusId !== 6
                ? [{ label: t('placeOnHold'), value: 'placeOnHold' }]
                : [
                    { label: t('releaseOnHold'), value: 'releaseOnHold' },
                    { label: t('deactivate'), value: 'deactivate' },
                  ]),
              ...(isHighRisk
                ? [{ label: t('removeHighRisk'), value: 'removeHighRisk' }]
                : [{ label: t('markHighRisk'), value: 'markHighRisk' }]),
              ...(statusId === 1
                ? [
                    { label: t('activate'), value: 'activate' },
                    { label: t('deactivate'), value: 'deactivate' },
                    {
                      label: t('scheduleDeactivate'),
                      value: 'scheduleDeactivate',
                    },
                    { label: t('suspend'), value: 'suspend' },
                  ]
                : []),
              ...(statusId === 2
                ? [
                    { label: t('deactivate'), value: 'deactivate' },
                    {
                      label: t('scheduleDeactivate'),
                      value: 'scheduleDeactivate',
                    },
                    { label: t('suspend'), value: 'suspend' },
                  ]
                : []),
              ...(statusId === 3 || statusId === 4
                ? [{ label: t('activate'), value: 'activate' }]
                : []),
              ...(userContract?.id
                ? [
                    { title: t('contract') },
                    {
                      label: t('sendContract'),
                      value: 'sendContract',
                    },
                    {
                      label: t('downloadContract'),
                      value: 'downloadContract',
                    },
                    ...(userContract?.file
                      ? [
                          {
                            label: t('sendContractAttachment'),
                            value: 'sendContractAttachment',
                          },
                          {
                            label: t('downloadContractAttachment'),
                            value: 'downloadContractAttachment',
                          },
                        ]
                      : []),
                  ]
                : []),
              { title: t('waiver') },
              ...getWaiverOptions({ waiver }),
            ]}
            placement='right'
            onClick={({ value }) =>
              onClickAction({ action: value, waiverId: waiver?.id })
            }
          >
            <Button icon='arrow_drop_down' text={t('actions')} />
          </Tooltip>
        )}
      </Box>

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={
            toggle.type === 'scheduleDeactivate'
              ? t('deactivate')
              : toggle.type === 'releaseOnHold'
              ? t('releaseOnHold')
              : t('placeOnHold')
          }
          onClose={resetToggle}
        >
          {toggle.type === 'scheduleDeactivate' ? (
            <FormDeactivate
              isLoading={scheduledUserActionsCreate.isLoading}
              onSubmit={onSubmitUserScheduleAction}
            />
          ) : toggle.type === 'releaseOnHold' ? (
            <FormReleaseOnHold
              initialValues={
                onHoldUser
                  ? {
                      proRataFee: onHoldUser.proRataFee,
                      isExtendPackageEndDate: onHoldUser.isExtendPackage,
                    }
                  : undefined
              }
              isLoading={onHoldUsersUpdateRelease.isLoading}
              onSubmit={(values) =>
                onHoldUsersUpdateRelease.mutate({
                  id: onHoldUserId,
                  ...values,
                })
              }
            />
          ) : (
            <FormOnHold
              initialValues={
                toggle.type === 'updateOnHold' ? onHoldUser : undefined
              }
              isLoading={
                onHoldUsersCreate.isLoading || onHoldUsersUpdate.isLoading
              }
              onSubmit={onSubmitUserScheduleAction}
            />
          )}
        </Modal>
      )}

      {scheduledUserActionsFind?.data?.data?.[0] && (
        <Card
          hasAlternateBackground
          hasPaddingChildren={false}
          mb={4}
          title={t('scheduledActions')}
        >
          <TableScheduledActions
            data={scheduledUserActionsFind.data?.data}
            onCancelAction={({ id }) =>
              scheduledUserActionsDelete.mutate({ id })
            }
          />
        </Card>
      )}

      {onHoldUser && (
        <Card
          hasAlternateBackground
          hasPaddingChildren={false}
          mb={4}
          title={t('onHoldDetails')}
        >
          <TableOnHoldUserTenant
            data={[onHoldUser]}
            onClickAction={onClickAction}
          />
        </Card>
      )}

      <Row>
        <Col md={6}>
          <Card hasAlternateBackground title={t('memberInfo')}>
            <Row>
              {[
                {
                  title: t('packages'),
                  body: userPackagesFind.data?.data
                    ? userPackagesFind.data?.data
                        .filter((item) => item.package)
                        .map(
                          (item) =>
                            `${item.package.name} (${item.sessionsAvailableText})`
                        )
                        .join(', ')
                    : null,
                  md: 12,
                },
                {
                  title: t('programme'),
                  body: programmeId
                    ? programmesFind.data?.data?.find(
                        ({ id }) => id === programmeId
                      )?.name
                    : null,
                },
                { title: t('emailAddress'), body: email },
                { title: t('mobileNumber'), body: mobile },
                {
                  title: t('dateJoined'),
                  body: getDateReadableDayMonthYear({
                    date: createdAt,
                  }),
                },
                {
                  title: t('contractStart'),
                  body: getDateReadableDayMonthYear({
                    date: userContract?.startingAt,
                  }),
                },
                {
                  title: t('contractEnd'),
                  body: getDateReadableDayMonthYear({
                    date: userContract?.endingAt,
                  }),
                },
                {
                  title: t('assignedStaffMember'),
                  body: assignedTrainer?.name,
                },
              ].map(
                ({ title, body, ...props }) =>
                  body && (
                    <Col key={title} md={6} {...props}>
                      <Text color='grey1' variant='caption'>
                        {title}
                      </Text>
                      <Text>{body}</Text>
                    </Col>
                  )
              )}
            </Row>
          </Card>
        </Col>

        <Col md={6}>
          <Card hasAlternateBackground title={t('memberAttendance')}>
            <Row>
              {[
                {
                  title: t('bookingsThisWeek'),
                  body: classBookingsFindStats.data?.weeksBookings,
                },
                {
                  title: t('bookingsThisMonth'),
                  body: classBookingsFindStats.data?.monthsBookings,
                },
                {
                  title: t('lastBooking'),
                  body: getDateReadableDayMonthYear({
                    date: classBookingsFindStats.data?.lastBooking?.date,
                  }),
                },
              ].map(
                ({ title, body, ...props }) =>
                  (body || body === 0) && (
                    <Col key={title} md={6} {...props}>
                      <Text color='grey1' variant='caption'>
                        {title}
                      </Text>
                      <Text>{body}</Text>
                    </Col>
                  )
              )}
            </Row>

            <Box isFlex>
              <Chip
                icon='pie_chart'
                mt={4}
                title={t('viewAttendanceReport')}
                to={`/reports/attendance/member-attendance?userId=${userId}`}
                onClick={() => {
                  resetMemberModal();
                  resetEventModal();
                }}
              />
            </Box>
          </Card>
        </Col>

        <Col md={6}>
          <Card hasAlternateBackground title={t('memberFinance')}>
            <Row>
              {[
                {
                  canRender: true,
                  title: t('outstandingAmount'),
                  body: toCurrency({
                    value: financeDetails?.outstandingAmount || 0,
                  }),
                },
                {
                  canRender: !!financeDetails?.lastPaidInvoice,
                  title: t('latestPayment'),
                  body: `${getDateReadableDayMonthYear({
                    date: financeDetails?.lastPaidInvoice?.dateTime,
                  })} (${financeDetails?.lastPaidInvoice?.type})`,
                },
                {
                  canRender: isGoCardless || isSepa,
                  title: t('mandateStatus'),
                  body: getMandateStatusDetails({
                    status: financeDetails?.mandate?.status,
                    sentAt: financeDetails?.mandate?.sentAt,
                    isGoCardless,
                  }).status,
                },
              ].map(
                ({ canRender, title, body, ...props }) =>
                  canRender && (
                    <Col key={title} md={6} {...props}>
                      <Text color='grey1' variant='caption'>
                        {title}
                      </Text>
                      <Text>{body}</Text>
                    </Col>
                  )
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
