import styled from 'styled-components';

export const StyledNavButton = styled.div`
  transition: background-color 250ms ease;
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => `${theme.sizes[2]} ${theme.sizes[3]}`};
  margin-left: ${({ theme }) => theme.sizes[2]};

  ${({ theme }) => `border-radius: ${theme.sizes[2]}`};

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;
