import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('locationName'),
        accessor: 'locationName',
      },
      {
        Header: t('firstName'),
        accessor: 'name',
      },
      {
        Header: t('surname'),
        accessor: 'surname',
      },
      {
        Header: t('gender'),
        accessor: 'gender',
      },
      {
        Header: t('emailAddress'),
        accessor: 'emailAddress',
      },
      {
        Header: t('mobileNumber'),
        accessor: 'mobileNumber',
      },
      {
        Header: t('dateOfBirth'),
        accessor: 'dateOfBirth',
      },
      {
        Header: t('status'),
        accessor: 'status',
      },
      {
        Header: t('source'),
        accessor: 'source',
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
