import { useState } from 'react';

import useWindowFocus from './useWindowFocus';

const hasFocus = () => typeof document !== 'undefined' && document.hasFocus();

const useIsWindowFocused = () => {
  const [isWindowFocused, setIsWindowFocused] = useState(hasFocus);

  useWindowFocus({
    onFocus: () => setIsWindowFocused(true),
    onBlur: () => setIsWindowFocused(false),
  });

  return isWindowFocused;
};

export default useIsWindowFocused;
