import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isDigital:
          typeof initialValues?.isDigital === typeof true
            ? initialValues?.isDigital
            : true,
        digitalTermsAndConditions:
          initialValues?.digitalTermsAndConditions || undefined,
        file: initialValues?.filepath || undefined,
      }}
      validationSchema={Yup.object().shape({
        isDigital: Yup.bool().required(t('required')),
        file: Yup.string().when('isDigital', {
          is: (value) => value === false,
          then: Yup.string().required(t('required')),
        }),
        digitalTermsAndConditions: Yup.string().when('isDigital', {
          is: (value) => value === true,
          then: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, dirty, values: { isDigital } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isCheckbox
                name='isDigital'
                options={[{ label: t('useDigitalWaiver'), value: true }]}
              />
            </Col>

            <Col>
              {isDigital ? (
                <Field
                  isEditor
                  label={t('waiverContent')}
                  name='digitalTermsAndConditions'
                />
              ) : (
                <Field isDrop label={t('waiverDocument')} name='file' />
              )}
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
