import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Modal,
  Text,
} from 'octiv-components';
import { AccountsInvoice } from 'octiv-containers';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useDebitBatchesCreateEnsureUsersOnBatch,
  useDebitBatchesCreateMarkAsUnprocessed,
  useDebitBatchesCreateRegenerateInvoices,
  useDebitBatchesCreateResubmit,
  useDebitBatchesFindByIdExport,
  useDebitBatchesFindLocation,
} from 'octiv-hooks/requests/DebitBatches';
import {
  useUserBatchesCreate,
  useUserBatchesFind,
} from 'octiv-hooks/requests/UserBatches';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { paymentGateways, userStatus } from 'octiv-utilities/Constants';
import {
  downloadFile,
  getDateReadableDayMonthYear,
} from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormResubmit from '../debitBatchControl/FormResubmit';
import FormAddToBatch from './FormAddToBatch';
import FormExportSepa from './FormExportSepa';
import FormQuery from './FormQuery';
import TableDebitBatches from './TableDebitBatches';

export default () => {
  const { t } = useTranslation();

  const [toggleResubmit, setToggleResubmit, resetToggleResubmit] = useToggle();
  const [toggleAddToBatch, setToggleAddToBatch, resetToggleAddToBatch] =
    useToggle();

  const {
    selectedLocation,
    tenant: { locationOptions, locations, regionId },
    toCurrency,
  } = useActiveUserTenant();
  const hasAccess = useContext(AccessContext);
  const [toggle, setToggle, resetToggle] = useToggle();
  const [batchesTotal, setBatchesTotal] = useState(undefined);
  const [painFormat, setPainFormat] = useState(undefined);
  const [isProcessAsBatch, setIsProcessAsBatch] = useState(true);
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    debitBatchId: undefined,
    addToBatchLocationId: selectedLocation?.id,
    addToBatchDebitBatchId: undefined,
    search: undefined,
  });

  const queryLocationPaymentGatewayId = locations.find(
    (item) => item.id === query.locationId
  )?.paymentGatewayId;

  // TODO: separate the two dropdowns into their own state (one in side modal, one in landing FormQuery)
  const {
    // data: debitBatchesAll,
    isFetching: isFetchingDebitBatchesAll,
    // refetch: getDebitBatchesAllRequest,
  } = useDebitBatchesFindLocation(
    {
      filter: {
        locationId: query.locationId,
        isReturnAll: true,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: false,
    }
  );

  // TODO: separate the two dropdowns into their own state (one in side modal, one in landing FormQuery)
  const {
    data: debitBatchesAllAddToBatch,
    // isFetching: isFetchingDebitBatchesAll,
    // refetch: getDebitBatchesAllAddNewRequest,
  } = useDebitBatchesFindLocation(
    {
      filter: {
        locationId: query.addToBatchLocationId,
        isReturnAll: true,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.addToBatchLocationId,
    }
  );

  const findDebitBatches = useDebitBatchesFindLocation(
    {
      filter: { locationId: query.locationId },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        if (!query.debitBatchId) {
          setQuery((prev) => ({
            ...prev,
            debitBatchId: response?.data[0]?.id,
          }));
        }
      },
    }
  );

  const {
    data: members,
    isFetching: isFetchingMembers,
    // refetch: getMembersRequest,
  } = useUsersFindMembers(
    {
      filter: {
        userTenantStatusId: userStatus.ACTIVE,
        isMinimalData: true,
        userTenantLocationId: query.addToBatchLocationId,
        userTenantDebitStatusId: 2,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: hasAccess.containerMembers && !!query?.addToBatchLocationId, // && toggle.isVisible,
    }
  );

  const findUserBatches = useUserBatchesFind(
    {
      filter: { ...query, isActive: 1 },
      paging: { perPage: -1 },
      sort: 'name',
    },
    {
      enabled: !!query.debitBatchId,
      onSuccess: (response) => {
        if (response && response?.data && response?.data?.[0]) {
          setBatchesTotal(
            response.data.reduce((a, b) => a + Number(b.invoice.amount), 0)
          );
        } else {
          setBatchesTotal(undefined);
        }
      },
    }
  );

  const {
    isLoading: isCreatingDebitBatchMarkAsUnprocessed,
    mutate: postDebitBatchMarkAsUnprocessed,
  } = useDebitBatchesCreateMarkAsUnprocessed({
    onSuccess: () => {
      findDebitBatches.refetch();
      findUserBatches.refetch();
    },
  });

  const {
    isLoading: isCreatingDebitBatchEnsureUsers,
    mutate: postDebitBatchEnsureUsers,
  } = useDebitBatchesCreateEnsureUsersOnBatch({
    onSuccess: findUserBatches.refetch,
  });

  const {
    isLoading: isCreatingDebitBatchRegenerateInvoices,
    mutate: postDebitBatchRegenerateInvoices,
  } = useDebitBatchesCreateRegenerateInvoices({
    onSuccess: findUserBatches.refetch,
  });

  const {
    isLoading: isCreatingDebitBatchResubmit,
    mutate: postDebitBatchResubmitRequest,
  } = useDebitBatchesCreateResubmit({
    onSuccess: () => {
      findUserBatches.refetch();
      resetToggleResubmit();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isLoading: isCreatingAddToBatch, mutate: postAddToBatchRequest } =
    useUserBatchesCreate({
      onSuccess: () => {
        findDebitBatches.refetch();
        findUserBatches.refetch();
        resetToggleAddToBatch();
      },
      meta: {
        useOnToastSuccess: true,
      },
    });

  const {
    isFetching: isFetchingDebitBatchExport,
    refetch: getDebitBatchExportRequest,
  } = useDebitBatchesFindByIdExport(
    { id: query.debitBatchId, painFormat, isProcessAsBatch },
    {
      enabled: !!painFormat,
      onSuccess: (res) => {
        setPainFormat(undefined);
        downloadFile(res);
        findDebitBatches.refetch();
        findUserBatches.refetch();
      },
    }
  );

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          data: { invoiceId: id },
        });
        break;

      default:
        break;
    }
  };

  const debitBatchProcessedOn = findDebitBatches.data?.data
    ? findDebitBatches?.data?.data?.find(
        (item) => item.id === query.debitBatchId
      )?.processedAt
    : null;

  return (
    <>
      {toggleResubmit.isVisible && (
        <Modal
          isSidebar
          title={debitBatchProcessedOn ? t('resubmitBatch') : t('submitBatch')}
          onClose={resetToggleResubmit}
        >
          <FormResubmit
            initialValues={toggleResubmit.data}
            isLoading={isCreatingDebitBatchResubmit}
            onSubmit={(values) => postDebitBatchResubmitRequest(values)}
          />
        </Modal>
      )}

      {toggleAddToBatch.isVisible && (
        <Modal
          isSidebar
          title={t('manuallyAddToBatch')}
          onClose={resetToggleAddToBatch}
        >
          <FormAddToBatch
            debitBatchesAll={debitBatchesAllAddToBatch}
            isFetchingDebitBatchesAll={isFetchingDebitBatchesAll}
            isFetchingMembers={isFetchingMembers}
            isLoading={isCreatingAddToBatch}
            locationOptions={locationOptions}
            members={members?.data || []}
            onLocationSelect={(locationId) => {
              setQuery((prev) => ({
                ...prev,
                addToBatchLocationId: locationId,
              }));
            }}
            onSubmit={(values) => {
              postAddToBatchRequest(values);
            }}
          />
        </Modal>
      )}

      {toggle.isVisible && (
        <AccountsInvoice
          initialView='form'
          invoiceId={toggle.data.invoiceId}
          onClose={() => {
            resetToggle();
            findUserBatches.refetch();
          }}
        />
      )}

      <Container
        appBarProps={{
          title: t('debitBatches'),
          breadcrumbs: [t('accounts')],
          children: (
            <Box ml='auto'>
              <Box isFlex>
                {query.debitBatchId && (
                  <>
                    {regionId === 2 ? (
                      <Button
                        hasBorder
                        isDisabled={isFetchingDebitBatchExport}
                        mr={2}
                        text={t('export')}
                        onClick={getDebitBatchExportRequest}
                      />
                    ) : (
                      queryLocationPaymentGatewayId !==
                        paymentGateways.SEPA && (
                        <Button
                          hasBorder
                          mr={2}
                          text={
                            debitBatchProcessedOn ? t('resubmit') : t('submit')
                          }
                          onClick={() =>
                            setToggleResubmit({
                              data: {
                                id: query.debitBatchId,
                                processedOn: debitBatchProcessedOn,
                                paymentGateway: {
                                  id: locations.find(
                                    (item) => item.id === query.locationId
                                  ).paymentGatewayId,
                                },
                              },
                            })
                          }
                        />
                      )
                    )}

                    {debitBatchProcessedOn ? (
                      <Button
                        hasBorder
                        isDisabled={isCreatingDebitBatchMarkAsUnprocessed}
                        mr={2}
                        text={t('markUnprocessed')}
                        onClick={() => {
                          if (
                            window.confirm(t('markUnprocessedConfirmation'))
                          ) {
                            postDebitBatchMarkAsUnprocessed({
                              id: query.debitBatchId,
                            });
                          }
                        }}
                      />
                    ) : (
                      <>
                        <Button
                          hasBorder
                          isDisabled={isCreatingDebitBatchRegenerateInvoices}
                          mr={2}
                          text={t('regenerateInvoices')}
                          onClick={() =>
                            postDebitBatchRegenerateInvoices({
                              id: query.debitBatchId,
                            })
                          }
                        />

                        <Button
                          hasBorder
                          isDisabled={isCreatingDebitBatchEnsureUsers}
                          mr={2}
                          text={t('ensureBatchUsersLoaded')}
                          onClick={() =>
                            postDebitBatchEnsureUsers({
                              id: query.debitBatchId,
                            })
                          }
                        />
                      </>
                    )}
                  </>
                )}

                <Button
                  text={t('manuallyAddToBatch')}
                  onClick={setToggleAddToBatch}
                />
              </Box>
            </Box>
          ),
        }}
        isLoading={
          isCreatingDebitBatchMarkAsUnprocessed ||
          isCreatingDebitBatchEnsureUsers ||
          isCreatingDebitBatchRegenerateInvoices ||
          isFetchingDebitBatchExport ||
          findUserBatches.isFetching
        }
      >
        {query.debitBatchId &&
          queryLocationPaymentGatewayId === paymentGateways.SEPA && (
            <FormExportSepa
              isLoading={isFetchingDebitBatchExport}
              onSubmit={(values) => {
                setPainFormat(values?.painFormat);
                setIsProcessAsBatch(values?.isProcessAsBatch);
              }}
            />
          )}

        <FormQuery
          debitBatches={findDebitBatches.data?.data || []}
          initialValues={query}
          isFetchingDebitBatches={findDebitBatches.isFetching}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        {!!debitBatchProcessedOn && (
          <Alert
            mb={4}
            subtitle={t('batchWasProcessedOn', {
              date: getDateReadableDayMonthYear({
                date: debitBatchProcessedOn,
              }),
            })}
          />
        )}

        <TableDebitBatches
          data={
            findUserBatches?.isFetching ? [] : findUserBatches.data?.data || []
          }
          isLoading={findUserBatches.isFetching}
          isProcessed={!!debitBatchProcessedOn}
          onClickAction={onClickAction}
        />

        {batchesTotal && !findUserBatches?.isFetching && (
          <Text mt={4} textAlign='right' variant='heading'>
            {`${t('total')}: ${toCurrency({
              value: batchesTotal,
            })}`}
          </Text>
        )}
      </Container>
    </>
  );
};
