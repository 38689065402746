import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();
  const isCreate = JSON.stringify(initialValues) === JSON.stringify({});

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        description: initialValues.description || undefined,
        price: initialValues.price || undefined,
        healthProviderPrice: initialValues?.healthProviderPrice || undefined,
        priority: initialValues.priority || undefined,
        limit: initialValues.limit || undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        description: Yup.string().required(t('required')),
        price: Yup.string().required(t('required')),
        healthProviderPrice: Yup.string(),
        priority: Yup.string(),
        limit: Yup.number().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label={t('name')} name='name' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col>
              <Field label={t('price')} name='price' />
            </Col>

            {props?.isHealthProvider ? (
              <Col>
                <Field
                  label={t('healthProviderPrice')}
                  name='healthProviderPrice'
                />
              </Col>
            ) : null}

            <Col>
              <Field
                isDisabled={!isCreate}
                label={t('sessions')}
                name='limit'
                type='number'
              />
            </Col>

            <Col>
              <Field
                helper={t('usePriorityToSortPackagesOnDropInWidget')}
                label={t('priority')}
                name='priority'
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
