import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default (props) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        type: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit, values: { search } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                isSelect
                isDisabled={!!search}
                label={t('exerciseCategory')}
                name='type'
                options={[
                  {
                    label: t('allExercises'),
                    value: undefined,
                  },
                  {
                    label: t('benchmarkExercises'),
                    value: 'benchmarkExercises',
                  },
                  {
                    label: t('programmedExercises'),
                    value: 'wodExercises',
                  },
                  {
                    label: t('personalExercises'),
                    value: 'personalExercises',
                  },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field hasSubmitButtonSearch label={t('search')} name='search' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
