import { Images } from 'octiv-assets';
import { Box, Image, Text } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

const WidgetHeader = ({ tenant }) => {
  const { t } = useTranslation();

  const { xsDown } = useMediaQuery();

  const theme = useContext(ThemeContext);

  return (
    <Box isFlex alignItems={xsDown ? undefined : 'center'} my={4}>
      <Box isFlex alignItems='center' mr={2}>
        {tenant?.settings?.logo && (
          <Image
            hasRadius
            alt={t('logo')}
            backgroundColor='grey6'
            mr={2}
            size={15}
            src={tenant?.settings?.logo}
          />
        )}

        <Text variant='heading'>{tenant?.name}</Text>
      </Box>

      <Image
        alt={t('octivLogo')}
        backgroundSize='contain'
        height={xsDown ? 6 : 10}
        ml='auto'
        src={
          theme.isDark ? Images.logoPoweredByLight : Images.logoPoweredByDark
        }
        width={xsDown ? 20 : 34}
        onClick={() =>
          window.open('https://octivfitness.com', 'octivMarketingWebsite')
        }
      />
    </Box>
  );
};

export default WidgetHeader;
