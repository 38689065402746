import { Box, Button, Container, Modal } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useExercisesCreate,
  useExercisesFind,
  useExercisesUpdate,
} from 'octiv-hooks/requests/Exercises';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);

  const { tenantId } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();
  // TODO: resetPaging properly when we toggle from "Active" to "Inactive" status
  const [paging, setPaging, resetPaging] = usePaging();
  const [query, setQuery] = useState({ isActive: 1 });

  const exercisesFind = useExercisesFind({
    filter: { ...query, isBenchmark: 1, type: 'owned' },
    paging,
  });

  const exercisesCreate = useExercisesCreate({ onSuccess: resetToggle });

  const exercisesUpdate = useExercisesUpdate({ onSuccess: resetToggle });

  const handleSubmit = (values) => {
    if (toggle.type === 'update') {
      exercisesUpdate.mutate({ id: toggle.data.id, ...values });
    } else {
      exercisesCreate.mutate({
        ...values,
        tenantId,
        isBenchmark: 1,
      });
    }
  };

  const handleSetQuery = (values) => {
    if (values?.isActive !== query?.isActive) {
      resetPaging();
    }
    setQuery(values);
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'update':
        setToggle({
          type: 'update',
          data: exercisesFind.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'activate':
        exercisesUpdate.mutate({ id, isActive: 1 });
        break;

      case 'deactivate':
        exercisesUpdate.mutate({ id, isActive: 0 });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('benchmark')} onClose={resetToggle}>
          <Form
            initialValues={toggle.data}
            isLoading={exercisesUpdate.isLoading || exercisesCreate.isLoading}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('manageBenchmarks'),
          breadcrumbs: [t('workouts')],
          children: hasAccess.featureWorkoutsActions ? (
            <Box ml='auto'>
              <Button
                text={t('createNew')}
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ) : undefined,
        }}
        isLoading={exercisesUpdate.isLoading || exercisesCreate.isLoading}
      >
        <FormQuery initialValues={query} onSubmit={handleSetQuery} />

        <Box height={4} />

        <Table
          data={exercisesFind.data?.data}
          isLoading={exercisesFind.isFetching}
          paging={exercisesFind?.data?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
