import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useStoreFindReportsSales = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['store', 'findReportsSales', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/reports`,
        params: {
          filter: {
            location: filter.location,
            locationId: filter.locationId,
            tenantId: filter.tenantId || tenantId,
            search: filter.search,
            status: filter.status,
            startDate: filter.startDate,
            endDate: filter.endDate,
            page: filter.page,
            perPage: filter.perPage,
            sort: filter.sort,
            order: filter.order,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useStoreFindByIdReportsSaleDetails = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['store', 'findByIdReportsSaleDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/reports/sales-details/${id}`,
        params: {
          filter: {
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useStoreFindReportsExportSales = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['store', 'findReportsExportSales', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/reports/export-sales`,
        params: {
          filter: {
            tenantId,
            locationId: filter.location,
            search: filter.search,
            status: filter.status,
            startDate: filter.startDate,
            endDate: filter.endDate,
            sort: filter.sort,
            order: filter.order,
          },
        },
      }),
    options
  );
};

export const useStoreFindReportsStockItems = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['store', 'findReportsStockItems', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/reports/stock-items`,
        params: {
          filter: {
            location: filter.location,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useStoreFindByIdReportsStockItemDetails = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['store', 'findByIdReportsStockItemDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/reports/stock-item-details/${id}`,
        params: {
          filter: {
            location: filter.location,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useStoreFindByIdSales = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['store', 'findByIdSales', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/sales/${id}`,
      }),
    options
  );
};

export const useStoreCreateSales = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/point-of-sale/sales`,
        data: {
          locationId: data.locationId,
          products: data.products,
          salePaymentType: data.salePaymentType,
          discount: data.discount,
          userId: data.userId,
          nonMember: data.nonMember,
          note: data.note,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useStoreUpdateSalesCancel = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/point-of-sale/sales/${id}/cancel`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useStoreFindStockItems = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['store', 'findStockItems', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/stock-items`,
        params: {
          filter: {
            locationId: filter.locationId,
            search: filter.search,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useStoreCreateStockItems = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();

      if (data.image && typeof data.image !== 'string') {
        formData.append('image', data.image);
      }

      data.locationIds.forEach((locationId, index) =>
        formData.append(`locationIds[${index}]`, locationId)
      );

      // formData.append('locationIds', data.locationIds);
      formData.append('name', data.name);
      formData.append('costPrice', data.costPrice);
      formData.append('sellingPrice', data.sellingPrice);
      formData.append('vat', data.vat || '');
      formData.append('sku', data.sku || '');
      formData.append('hasLimitedStock', data.hasLimitedStock);
      // if (data.hasLimitedStock)
      formData.append(
        'stockLevel',
        data.hasLimitedStock ? data.stockLevel : -1
      );
      formData.append('description', data.description);
      formData.append('category', data.category);

      return request({
        method: 'post', // TODO: confirm with and without image upload
        url: `/api/point-of-sale/stock-items`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useStoreFindStockItemsCategories = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['store', 'findStockItemsCategories', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/stock-items/categories`,
        params: {
          filter: {
            location: filter.location,
            locationId: filter.locationId,
            tenantId,
          },
        },
      }),
    options
  );
};

export const useStoreFindByIdStockItems = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['store', 'findByIdStockItems', input],
    () =>
      request({
        method: 'get',
        url: `/api/point-of-sale/stock-items/${id}`,
      }),
    options
  );
};

export const useStoreUpdateStockItems = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const formData = new FormData();

      if (data.image && typeof data.image !== 'string') {
        formData.append('image', data.image);
      }

      if (data.locationIds) {
        formData.append('locationIds', data.locationIds);
      } else {
        formData.append('locationId', data.locationId);
      }
      formData.append('name', data.name);
      formData.append('costPrice', data.costPrice);
      formData.append('sellingPrice', data.sellingPrice);
      formData.append('vat', data.vat || '');
      formData.append('sku', data.sku || '');
      formData.append('hasLimitedStock', data.hasLimitedStock);
      formData.append('stockLevel', data.stockLevel);
      formData.append('description', data.description);
      formData.append('category', data.category);

      return request({
        // method: data?.image ? 'post' : 'put',
        // NB: using post for the case of updating images
        method: 'post',
        url: `/api/point-of-sale/stock-items/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useStoreDeleteStockItems = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/point-of-sale/stock-items/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useStoreCreateStockItemsImport = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/point-of-sale/stock-items/import`,
        data: { locationId: data.locationId, csvFile: data.csvFile },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['store'] });

      options?.onSuccess?.(...res);
    },
  });
};
