import { Icon } from 'octiv-components';
import {
  getDateReadableDayMonthYearTime,
  getFullName,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BookingInfo = ({
  iconProps,
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  ...props
} = {}) => {
  const { t } = useTranslation();

  if (!createdAt && !createdBy && !updatedAt && !updatedBy) {
    return null;
  }

  const renderItem = ({ title, user, date }) =>
    user || date ? (
      <span style={{ display: 'block' }}>
        {`${title}: ${user ? getFullName({ ...user, isShortened: true }) : ''}${
          user && date ? ', ' : ''
        }${
          date
            ? getDateReadableDayMonthYearTime({
                date,
              })
            : ''
        }`}
      </span>
    ) : null;

  return (
    <Icon
      color='grey3'
      name='info'
      pr={2}
      tooltip={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          {renderItem({
            title: t('created'),
            user: createdBy,
            date: createdAt,
          })}

          {renderItem({
            title: t('updated'),
            user: updatedBy,
            date: updatedAt,
          })}
        </>
      }
      {...props}
    />
  );
};

export default BookingInfo;
