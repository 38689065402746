import { FieldArray, Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        debitOrderSettings: initialValues,
      }}
      validationSchema={Yup.object().shape({
        debitOrderSettings: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
      })}
    >
      {({ handleSubmit, dirty, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <FieldArray
              name='debitOrderSettings'
              render={() =>
                values.debitOrderSettings.map((debitOrderSetting, index) => (
                  <Col key={`${index.toString()}`} md={6} xl={4}>
                    <Field
                      isSelect
                      label={debitOrderSetting.debitDay.name}
                      name={`debitOrderSettings[${index}].invoiceDueOnDate.id`}
                      options={[
                        {
                          label: t('onDateOfDebitOrder'),
                          value: 'on the date of debit-order',
                        },
                        {
                          label: t('firstDayOfNextMonth'),
                          value: 'first day of the next month',
                        },
                      ]}
                    />
                  </Col>
                ))
              }
            />

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
