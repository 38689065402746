import styled, { keyframes } from 'styled-components';
import { color } from 'styled-system';

const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  85% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(200%);
  }
`;

const StyledProcessingBar = styled.div`
  animation: ${animation} 2.5s infinite ease;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 2px;
  width: 50%;
  z-index: 501;

  ${color};
`;

export default StyledProcessingBar;
