import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  data,
  locations,
  hasLocationGoCardless,
  hasLocationNetcash,
  onClickAction,
  isTenantSouthAfrican,
  ...props
}) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(() => {
    const returnText = ({
      hasDetails,
      location,
      paymentGatewayId,
      setting,
    }) => {
      const isActive = !!hasDetails && setting?.isActive === 1;
      const isDeactivated = !!hasDetails && setting?.isActive !== 1;

      return (
        <Text
          as='span'
          color={isActive ? 'success' : isDeactivated ? 'danger' : 'info'}
          onClick={() => {
            if (hasDetails) {
              onClickAction({
                type: 'update',
                data: setting,
              });
            } else {
              onClickAction({
                type: 'create',
                data: {
                  locationId: location?.id,
                  paymentGatewayId,
                },
              });
            }
          }}
        >
          {isActive || isDeactivated
            ? isActive
              ? t('viewActiveDetails')
              : t('viewDeactivatedDetails')
            : t('enable')}
        </Text>
      );
    };

    return [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: 'GoCardless',
        id: 'goCardless',
        Cell: ({
          row: {
            original: { location, settings },
          },
        }) => {
          if (
            locations?.find((item) => item?.id === location?.id)
              ?.paymentGatewayId === 5
          ) {
            // const setting = settings;
            // TODO: check with Ischci
            const setting = settings?.find(
              (item) => item.paymentGatewayId === 5
            );
            const hasDetails = setting && setting.isForSignUp;

            return returnText({
              hasDetails,
              setting,
              location,
              paymentGatewayId: 5,
            });
          }

          return null;
        },
      },
      {
        Header: 'Paystack',
        id: 'paystack',
        Cell: ({
          row: {
            original: { location, settings },
          },
        }) => {
          // TODO: check with Ischci
          const setting = settings?.find((item) => item.paymentGatewayId === 7);
          const hasDetails = setting && setting.accountNumber;

          return returnText({
            hasDetails,
            setting,
            location,
            paymentGatewayId: 7,
          });
        },
      },
      {
        Header: 'Netcash Pay Now',
        id: 'netcash',
        Cell: ({
          row: {
            original: { location, settings },
          },
        }) => {
          if (
            locations.find((item) => item?.id === location?.id)
              ?.paymentGatewayId === 4
          ) {
            const setting = settings?.find(
              (item) => item.paymentGatewayId === 4
            );
            const hasDetails = setting && setting?.merchantAccountNumber;

            return returnText({
              hasDetails,
              setting,
              location,
              paymentGatewayId: 4,
            });
          }

          return null;
        },
      },
      {
        Header: 'Stripe',
        id: 'stripe',
        Cell: ({
          row: {
            original: { location, settings },
          },
        }) => {
          const setting = settings?.find((item) => item.paymentGatewayId === 6);
          const hasDetails = setting && setting?.publicKey;

          return returnText({
            hasDetails,
            setting,
            location,
            paymentGatewayId: 6,
          });
        },
      },
    ];
  }, [locations, onClickAction, t]);

  const hiddenColumns = useMemo(
    () => [
      ...(hasLocationGoCardless ? [] : ['goCardless']),
      ...(hasLocationNetcash ? [] : ['netcash']),
      ...(isTenantSouthAfrican ? [] : ['paystack']),
    ],
    [hasLocationGoCardless, hasLocationNetcash, isTenantSouthAfrican]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
