import styled from 'styled-components';
import { color, maxWidth, space } from 'styled-system';

export const StyledDivider = styled.div`
  ${({ canFillSpace }) => (canFillSpace ? 'flex: 1' : undefined)};
  ${({ hasLine, theme }) =>
    hasLine
      ? `height: 1px; background-color: ${theme.colors.grey4}`
      : undefined}

  ${color};
  ${maxWidth};
  ${space};
`;
