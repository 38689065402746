import { Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useQuery, useToggle } from 'octiv-hooks';
import {
  useAttendanceRecordsCreateCheckIn,
  useAttendanceRecordsDeleteCancelCheckIn,
} from 'octiv-hooks/requests/AttendanceRecords';
import {
  useClassBookingsFind,
  useClassBookingsUpdateCancel,
  useClassBookingsUpdateNoShow,
} from 'octiv-hooks/requests/ClassBookings';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormCancel from '../../modals/event/event/FormCancel';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const statusId = useQuery('statusId');

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [paging, setPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();

  const [query, setQuery] = useState({
    date: getDateYearMonthDay({ canFallback: true }),
    locationId: selectedLocation?.id,
    classDateId: undefined,
    status: statusId || '1',
  });

  const classDatesFind = useClassDatesFind({
    filter: {
      locationId: query.locationId,
      between: `${query.date},${query.date}`,
    },
    paging: { perPage: -1 },
  });

  const classBookingsFind = useClassBookingsFind({
    paging,
    filter: {
      date: query.date,
      between: `${query.date},${query.date}`,
      locationId: query.locationId,
      classDateId: query.classDateId,
      status: query.status,
    },
    sort: 'class_date_time',
  });

  const attendanceRecordsCreateCheckIn = useAttendanceRecordsCreateCheckIn();

  const attendanceRecordsDeleteCancelCheckIn =
    useAttendanceRecordsDeleteCancelCheckIn();

  const classBookingsUpdateNoShow = useClassBookingsUpdateNoShow();

  const classBookingsUpdateCancel = useClassBookingsUpdateCancel({
    onSuccess: resetToggle,
  });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'checkIn':
        attendanceRecordsCreateCheckIn.mutate({ classBookingId: id });
        break;

      case 'checkInCancel':
        attendanceRecordsDeleteCancelCheckIn.mutate({ classBookingId: id });
        break;

      case 'noShow':
        classBookingsUpdateNoShow.mutate({ id });
        break;

      case 'cancel':
        setToggle({ data: { id } });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal isCondensed title={t('cancelBooking')} onClose={resetToggle}>
          <FormCancel
            initialValues={toggle.data}
            isLoading={classBookingsUpdateCancel.isLoading}
            onSubmit={(values) => classBookingsUpdateCancel.mutate(values)}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('roster'),
          breadcrumbs: [t('scheduling')],
        }}
        isLoading={
          classDatesFind.isFetching ||
          classBookingsFind.isFetching ||
          attendanceRecordsCreateCheckIn.isFetching ||
          attendanceRecordsDeleteCancelCheckIn.isFetching ||
          classBookingsUpdateNoShow.isFetching
        }
      >
        <FormQuery
          classes={classDatesFind.data?.data}
          initialValues={query}
          isFetchingClasses={classDatesFind.isFetching}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <Table
          data={classBookingsFind.data?.data}
          isLoading={classBookingsFind.isFetching}
          paging={classBookingsFind.data?.paging}
          query={query}
          setPaging={setPaging}
          setQuery={setQuery}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
