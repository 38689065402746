import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { Toast } from 'octiv-components';
import { transformApiError } from 'octiv-utilities/Functions';
// import { isDevelopment } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const QueryClientProvider = ({ children }) => {
  const { t } = useTranslation();

  const onSuccess = ({ useOnSuccessToast, onSuccessToast }) => {
    if (useOnSuccessToast || !!onSuccessToast) {
      toast.success(
        <Toast
          variant='success'
          {...(onSuccessToast
            ? { ...onSuccessToast }
            : useOnSuccessToast
            ? { title: t('actionSuccessful') }
            : {})}
        />
      );
    }
  };

  const onError = ({ useOnErrorToast, error }) => {
    if (useOnErrorToast && !!error.message) {
      const { title, body } = transformApiError(error);

      toast.error(<Toast body={body} title={title} variant='danger' />);
    }
  };

  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onSuccess: (data, query) =>
            onSuccess({
              useOnSuccessToast: query?.meta?.useOnSuccessToast === true,
              onSuccessToast: query?.meta?.onSuccessToast,
            }),
          onError: (error, query) =>
            onError({
              error,
              useOnErrorToast: query?.meta?.useOnErrorToast !== false,
            }),
        }),
        mutationCache: new MutationCache({
          onSuccess: (data, variables, context, mutation) =>
            onSuccess({
              useOnSuccessToast: mutation?.meta?.useOnSuccessToast === true,
              onSuccessToast: mutation?.meta?.onSuccessToast,
            }),
          onError: (error, variables, context, mutation) =>
            onError({
              error,
              useOnErrorToast: mutation?.meta?.useOnErrorToast !== false,
            }),
        }),
        defaultOptions: {
          queries: {
            // TODO: revert
            refetchOnWindowFocus: false, //! isDevelopment,
          },
        },
      })
  );

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};

export default QueryClientProvider;
