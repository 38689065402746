import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        csvFile: undefined,
      }}
      validationSchema={Yup.object().shape({
        csvFile: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            isDrop
            useFileIcon
            accept={{
              'text/csv': ['.csv'],
              'application/vnd.ms-excel': ['.xls'],
            }}
            label={t('csvFile')}
            name='csvFile'
          />

          <Button
            isLoading={isLoading}
            my={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
