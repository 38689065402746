import moment from 'moment';
import { Box, Container, Modal } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import { useClassesFind } from 'octiv-hooks/requests/Classes';
import {
  useReportsFindAttendanceClassAttendance,
  useReportsFindAttendanceClassAttendanceDetails,
} from 'octiv-hooks/requests/Reports';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { userStatus } from 'octiv-utilities/Constants';
import { getDateYearMonthDay, getEventString } from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();
  const hasAccess = useContext(AccessContext);

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    isSession: undefined,
    isClassActive: true,
    classId: undefined,
    userId: undefined,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindAttendanceClassAttendance({ filter: query });

  const getIterable = (arg) =>
    Array.isArray(arg || {}) ? arg || [] : Object.values(arg || {});

  const { data: reportDetail, isFetching: isFetchingReportDetail } =
    useReportsFindAttendanceClassAttendanceDetails(
      {
        filter: {
          ...query,
          classId: toggle.data?.id,
          classBookingStatusId: toggle.data?.classBookingStatusId,
        },
        include: 'classDate',
      },
      {
        enabled: toggle.data !== undefined,
      }
    );

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers(
    {
      filter: {
        userGroup: 'gymMembers',
        view: 'members',
        userTenantStatusId: userStatus.ACTIVE,
        isMinimalData: true,
        locationId: query.locationId,
      },
      paging: {
        perPage: -1,
      },
      sort: 'name',
    },
    {
      enabled: hasAccess.containerMembers,
    }
  );

  const { data: classes, isFetching: isFetchingClasses } = useClassesFind({
    filter: {
      locationId: query.locationId,
      isSession: query.isSession,
      status: query.isClassActive ? 'active' : 'deactivated',
    },
    paging: {
      perPage: -1,
    },
  });

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${t('attendance')}: ${getEventString(toggle.data)}`}
          onClose={resetToggle}
        >
          <TableDetail
            data={isFetchingReportDetail ? [] : reportDetail}
            isLoading={isFetchingReportDetail}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('classAttendance'),
          breadcrumbs: [t('reports'), t('attendance')],
        }}
        isLoading={isFetchingReport || isFetchingReportDetail}
      >
        <FormQuery
          classes={classes?.data || []}
          initialValues={query}
          isFetchingClasses={isFetchingClasses}
          isFetchingMembers={isFetchingMembers}
          locationOptions={locationOptions}
          members={members?.data || []}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TableReport
          data={getIterable(report)}
          isLoading={isFetchingReport}
          onClickBookingStatus={(data) => setToggle({ data })}
        />
      </Container>
    </>
  );
};
