import moment from 'moment';
import { Box, Icon, Table, Text, Tooltip } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, isCompactView, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('summary'),
        accessor: 'summary',
      },
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value?.name || '',
      },
      {
        Header: t('assignees'),
        accessor: 'assignedUsers',
        Cell: ({ value }) =>
          value ? value.map((item) => getFullName(item)).join(', ') : '',
      },
      {
        Header: t('dueDate'),
        accessor: 'dueDate',
        Cell: ({ value }) =>
          value
            ? moment(value).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd, DD/MM/YYYY',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd, DD/MM/YYYY',
                sameElse: 'DD/MM/YYYY',
              })
            : '',
      },
      {
        Header: t('status'),
        accessor: 'isCompleted',
        Cell: ({ value }) => (
          <Text as='span' color={value ? 'success' : undefined}>
            {value ? t('complete') : t('incomplete')}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id, isCompleted },
          },
        }) => (
          <Box isFlex>
            <Icon
              mr={4}
              name={isCompleted ? 'undo' : 'check_circle'}
              onClick={() =>
                onClickAction({ action: 'completeUncomplete', id })
              }
            />

            {!isCompactView && (
              <Tooltip
                options={[
                  { label: t('viewEdit'), value: 'edit' },
                  { label: t('delete'), value: 'delete' },
                ]}
                onClick={({ value }) => onClickAction({ action: value, id })}
              />
            )}
          </Box>
        ),
      },
    ],
    [onClickAction, isCompactView, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(query.assignedUserId || isCompactView ? ['assignees'] : []),
      ...(query.locationId || isCompactView ? ['location'] : []),
      ...(query.showCompleted === undefined ? [] : ['isCompleted']),
      ...(query.showScheduled === false ? ['dueDate'] : []),
    ],
    [
      isCompactView,
      query.assignedUserId,
      query.locationId,
      query.showCompleted,
      query.showScheduled,
    ]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hasAlternateBackground={isCompactView}
      hiddenColumns={hiddenColumns}
    />
  );
};
