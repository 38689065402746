import { Formik } from 'formik';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isDebitOrder,
  isFetchingUserTenantPaymentDetails,
  isLoading,
  initialValues = {},
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        id: initialValues.id,
        sessions: undefined,
        paymentType: undefined,
        isSend: 0,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        sessions: Yup.string().required(t('required')),
        paymentType: Yup.string().required(t('required')),
        isSend: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, setFieldValue, values: { paymentType } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label={t('sessions')} name='sessions' />
            </Col>

            <Col>
              <Text mb={1}>{t('paymentType')}</Text>

              <Field
                isRadio
                canShowErrorUntouched={false}
                doFinally={(newPaymentType) => {
                  if (newPaymentType === 'noPayment') {
                    setFieldValue('isSend', 0);
                  }
                }}
                isLoading={isFetchingUserTenantPaymentDetails}
                label={t('sessionsReleasedImmediately')}
                name='paymentType'
                options={[
                  { label: t('cash'), value: 'cash' },
                  { label: t('card'), value: 'card' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('noPaymentFree'), value: 'noPayment' },
                  ...(isDebitOrder
                    ? [
                        {
                          label: t('addToNextDebitOrder'),
                          value: 'debitOrder',
                        },
                      ]
                    : []),
                ]}
              />
            </Col>

            <Col>
              <Field
                isRadio
                canShowErrorUntouched={false}
                label={t('sessionsReleasedAfterPayment')}
                name='paymentType'
                options={[
                  {
                    label: t('requestAdhocPayment'),
                    value: 'adhocPayment',
                  },
                  {
                    label: t('generateAnInvoice'),
                    value: 'invoiceOnly',
                  },
                ]}
              />
            </Col>

            {paymentType !== 'noPayment' && (
              <Col>
                <Field
                  isHorizontal
                  isRadio
                  label={t('sendInvoiceOnSubmission')}
                  name='isSend'
                  options={[
                    { label: t('yes'), value: 1 },
                    { label: t('no'), value: 0 },
                  ]}
                />
              </Col>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
