import { Container } from 'octiv-components';
import { useExportFindUsers } from 'octiv-hooks/requests/Export';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import { downloadFile } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const [query, setQuery] = useState({
    userType: 'all',
    statusId: 2,
    regionId: undefined,
    tenantStatusId: 1,
    tenantId: undefined,
    isLocationActive: true,
    locationId: undefined,
  });

  const exportFindUsers = useExportFindUsers(
    {
      filter: query,
    },
    {
      // TODO: figure out a better condition.
      // Tricky on this form as everything can be set to "ALL"
      // also need to remove the setTimeouts in event handler props
      enabled: false,
      onSuccess: (response) => downloadFile(response.file),
    }
  );

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
  });

  const { data: tenants, isFetching: isFetchingTenants } = useTenantsFind({
    filter: {
      statusId: 1,
      regionId: query?.regionId,
    },
    paging: { perPage: -1 },
  });

  const { data: locations, isFetching: isFetchingLocations } = useLocationsFind(
    {
      filter: {
        regionId: query?.regionId,
        tenantId: query?.tenantId,
      },
      paging: { perPage: -1 },
    }
  );

  const onSubmitForm = (values) => {
    setQuery(values);
    // TODO: improve implementation
    setTimeout(() => {
      exportFindUsers?.refetch();
    }, 200);
  };

  return (
    <Container
      appBarProps={{
        title: t('users'),
        breadcrumbs: ['Exports'],
      }}
    >
      <Form
        initialValues={query}
        isFetchingLocations={isFetchingLocations}
        isFetchingRegions={isFetchingRegions}
        isFetchingTenants={isFetchingTenants}
        isLoading={exportFindUsers.isFetching}
        locations={locations?.data || []}
        regions={regions?.data || []}
        tenants={tenants?.data || []}
        onGetLocationsRequest={({ tenantId, isLocationActive }) => {
          setQuery((prev) => ({
            ...prev,
            isActive: isLocationActive,
            tenantId,
          }));
        }}
        onGetTenantsRequest={({ regionId, tenantStatusId }) => {
          setQuery((prev) => ({
            ...prev,
            regionId,
            statusId: tenantStatusId,
          }));
        }}
        onSubmit={onSubmitForm}
      />
    </Container>
  );
};
