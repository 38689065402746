import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import { Button, Col, Divider, Field, Row, Text } from 'octiv-components';
import Box from 'octiv-components/box/index';
import Step from 'octiv-components/step/index';
import { getFullName, toCase } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  primaryAccount,
  initialValues,
  userTenants,
  isLoading,
  onBack,
  onNext,
  ...props
}) => {
  const { t } = useTranslation();

  const [associatedUserTenants, unassociatedUserTenants] = _.partition(
    userTenants,
    (ut) => ut.userId === primaryAccount?.id
  );

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: [], // initialValues.userIds,
      }}
      validationSchema={Yup.object().shape({
        // TODO: add schema for each array element
        // users: Yup.array().of(Yup.object().shape({}).required(t('required'))),
        userIds: Yup.array(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {isLoading ? null : (
            <>
              <Step
                hasBorder
                boxContainerProps={{ mb: 4 }}
                mb='1rem'
                title={t('mergeWithPrimaryAccount')}
                value='2'
              />

              <br />
              <Text>{t('mergeAccountsAlreadyAssociated')}</Text>

              <Row>
                <br />
                <br />
                <ul>
                  {associatedUserTenants?.map((userTenant, index) => (
                    <>
                      {/* <Box width='100%'> */}
                      <Text
                        color={
                          userTenant?.status?.name === 'ACTIVE'
                            ? 'success'
                            : 'danger'
                        }
                        mb={'0'}
                        ml='1rem'
                      >
                        {index + 1}.&nbsp;
                        {`${userTenant?.tenant?.name} (${getFullName(
                          userTenant
                        )} - ${toCase({
                          value: userTenant?.status?.name,
                        })})`}
                        {`(${toCase({ value: userTenant?.type?.name })})`}
                      </Text>
                      {/* </Box> */}
                    </>
                  ))}
                </ul>
              </Row>

              <br />
              <Text>{t('mergeAccountsAccountsToMerge')}</Text>
              <br />
              <Row>
                <FieldArray
                  name='userIds'
                  render={() =>
                    unassociatedUserTenants?.map((userTenant, index) => (
                      <React.Fragment key={`${index.toString()}`}>
                        <Divider hasLine mb={5} mt={4} />

                        <Col>
                          <Field
                            canSubmitOnChange
                            isCheckbox
                            name={`userIds[${index}]`}
                            options={[
                              {
                                label: (
                                  <Text
                                    color={
                                      userTenant?.status?.name === 'ACTIVE'
                                        ? 'success'
                                        : 'danger'
                                    }
                                  >{`${
                                    unassociatedUserTenants?.[index]?.tenant
                                      ?.name
                                  } (${getFullName(
                                    unassociatedUserTenants?.[index]
                                  )} - ${toCase({
                                    value:
                                      unassociatedUserTenants?.[index]?.status
                                        ?.name,
                                  })}) ${`(${toCase({
                                    value: userTenant?.type?.name,
                                  })})`}`}</Text>
                                ),
                                value: userTenant?.userId,
                              },
                            ]}
                          />
                        </Col>
                      </React.Fragment>
                    ))
                  }
                />
              </Row>

              <br />
              <Box isFlex flexDirection='row'>
                <Button
                  hasBorder
                  isLoading={isLoading}
                  mr={'1rem'}
                  text={t('back')}
                  onClick={onBack}
                />
                <Button
                  isLoading={isLoading}
                  text={t('next')}
                  onClick={onNext}
                />
              </Box>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};
