import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('submissionId'),
        accessor: 'submissionId',
      },
      {
        Header: t('reference'),
        accessor: 'reference',
      },
      {
        Header: t('actionDate'),
        accessor: 'actionDate',
      },
      {
        Header: t('debtorReference'),
        accessor: 'debtorReference',
      },
      {
        Header: t('accountHolder'),
        accessor: 'accountHolder',
      },
      {
        Header: t('description'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('bankName'),
        accessor: 'bankName',
      },
      {
        Header: t('bankAccountNumber'),
        accessor: 'bankAccountNumber',
      },
      {
        Header: t('bankCode'),
        accessor: 'bankCode',
      },
      {
        Header: t('bankAccountType'),
        accessor: 'bankAccountType',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
      },
      {
        Header: t('cell'),
        accessor: 'cell',
      },
      {
        Header: t('debtorNote'),
        accessor: 'debtorNote',
      },
      {
        Header: t('retentionAmount'),
        accessor: 'retentionAmount',
      },
      {
        Header: t('debitFee'),
        accessor: 'debitFee',
      },
      {
        Header: t('unpaidFee'),
        accessor: 'unpaidFee',
      },
      {
        Header: t('cdv'),
        accessor: 'cdv',
      },
      {
        Header: t('bankProcessingStatus'),
        accessor: 'bankProcessingStatus',
      },
      {
        Header: t('paidUnpaidStatus'),
        accessor: 'paidUnpaidStatus',
      },
      {
        Header: t('statusDescriptionOfDebit'),
        accessor: 'statusDescriptionOfDebit',
      },
      {
        Header: t('bankReturn'),
        accessor: 'breturn',
      },
      {
        Header: t('debitStatus'),
        accessor: 'debitStatus',
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
