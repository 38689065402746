import { Card } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tasks } from '../..';

export default () => {
  const { t } = useTranslation();

  const { userId } = useActiveUserTenant();

  return (
    <Card textTitleProps={{ to: '/tasks' }} title={t('tasks')}>
      <Tasks isCompactView assignedUserId={userId} />
    </Card>
  );
};
