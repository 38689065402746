import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const usePublicFindGoCardlessOnBoardingLink = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['public', 'findGoCardlessOnBoardingLink', input],
    () =>
      request({
        method: 'get',
        url: `/api/finances/go-cardless/on-boarding-link`,
        params: { filter: { userId: filter.userId } },
      }),
    options
  );
};

export const usePublicCreateGoCardlessEvents = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/finances/go-cardless/events`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreateGoCardlessProcessPayment = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/finances/go-cardless/process-payment`,
        data: { invoiceId: data.invoiceId, settingsId: data.settingsId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreateNetcashAccept = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/public/netcash/accept`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreateNetcashDecline = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/public/netcash/decline`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreateNetcashNotify = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/public/netcash/notify`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreatePayfastSubscriptionsPing = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/public/payfast/subscriptions/ping`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePaymentsFindByIdInvoiceForPayment = (input, options) => {
  const { id, ...params } = input || {};

  return useQuery(
    ['public', 'findByIdPaymentsInvoiceForPayment', input],
    () =>
      request({
        method: 'get',
        url: `/api/payments/invoice-for-payment/${id}`,
        params: {
          ...params,
        },
      }),
    options
  );
};

export const usePublicFindByIdPaymentsPaymentGateway = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['public', 'findByIdPaymentsPaymentGateway', input],
    () =>
      request({
        method: 'get',
        url: `/api/payments/payment-gateway/${id}`,
      }),
    options
  );
};

export const usePublicFindPaystackVerifyTransaction = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['public', 'findPaystackVerifyTransaction', input],
    () =>
      request({
        method: 'get',
        url: `/api/public/paystack/verify-transaction`,
        params: { filter: { reference: filter.reference } },
      }),
    options
  );
};

export const usePublicCreatePaystackEvents = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'post',
        url: `/api/public/paystack/events`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const usePublicCreateStripeEvents = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { token } = input || {};

      return request({
        method: 'post',
        url: `/api/public/stripe/events/${token}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['public'] });

      options?.onSuccess?.(...res);
    },
  });
};
