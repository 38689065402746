import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GlobalProgrammes from './globalProgrammes';
import Programmes from './programmes';
import WorkoutVisibility from './workoutVisibility';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('workouts'),
        breadcrumbs: [t('settings')],
      }}
    >
      <WorkoutVisibility />

      <Programmes />

      <GlobalProgrammes />
    </Container>
  );
};
