import { Icon, Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getFullName,
  getMandateStatusDetails,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  data,
  setInvoices,
  onClickAction,
  isProcessed,
  ...props
}) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();
  const {
    tenant: { isGoCardless, isSepa },
    toCurrency,
  } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ row: { original }, value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({
                userTenant: original?.invoice?.user?.userTenant,
                hasAccess,
              })
                ? () =>
                    setMemberModal({
                      userTenantId: original?.invoice?.user?.userTenant?.id,
                    })
                : null
            }
          >
            {value ? getFullName(value) : null}
          </Text>
        ),
      },
      {
        Header: t('invoiceCode'),
        accessor: 'invoice',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={() =>
              window.open(`/accounts/invoices?invoiceId=${value?.id}`)
            }
          >
            {value?.code}
          </Text>
        ),
      },
      {
        Header: t('manuallyAdded'),
        accessor: 'isManuallyAdded',
        Cell: ({ value }) => (value ? <Icon isActive name='check' /> : null),
      },
      {
        Header: t('amountDue'),
        id: 'amount',
        accessor: 'invoice',
        Cell: ({ value }) => toCurrency({ value: value?.amount }),
      },
      {
        Header: t('mandateStatus'),
        id: 'mandateStatus',
        Cell: ({
          row: {
            original: {
              user: { mandate },
              goCardlessLinkSentOn,
            },
          },
        }) => {
          const { isActive, isInactive, status } = getMandateStatusDetails({
            status: mandate?.status,
            sentAt: isGoCardless ? goCardlessLinkSentOn : mandate?.linkSentOn,
            isGoCardless,
          });

          return (
            <Text
              as='span'
              color={isActive ? 'success' : isInactive ? 'danger' : 'grey1'}
            >
              {status}
            </Text>
          );
        },
      },
      {
        Header: t('actions'),
        id: 'actions',
        accessor: 'invoice',
        Cell: ({ value }) => (
          <Text
            as='span'
            color='primary'
            onClick={() => onClickAction({ action: 'edit', id: value?.id })}
          >
            {t('edit')}
          </Text>
        ),
      },
    ],
    [onClickAction, toCurrency, setMemberModal, hasAccess, t, isGoCardless]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(isProcessed ? ['actions'] : []),
      ...(!isGoCardless && !isSepa ? ['mandateStatus'] : []),
    ],
    [isProcessed, isGoCardless, isSepa]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
