import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useCoronavirusQuestionnaireResultsFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['coronavirusQuestionnaireResults', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/coronavirus-questionnaire-results`,
        params: {
          include: 'classBooking,classBooking.classDate',
          filter: {
            tenantId,
            userId: filter.userId,
            locationId: filter.locationId,
            date: filter.date,
            classDateId: filter.classDateId,
            legacyEndpointRequest: filter.legacyEndpointRequest,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useCoronavirusQuestionnaireResultsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/coronavirus-questionnaire-results`,
        data: {
          classBookingId: data.classBookingId,
          temperature: data.temperature,
          hasCough: data.hasCough,
          hasDifficultyBreathing: data.hasDifficultyBreathing,
          hasFever: data.hasFever,
          hasBeenInContactExperiencing: data.hasBeenInContactExperiencing,
          hasBeenInContactPositive: data.hasBeenInContactPositive,
          hasTravelled: data.hasTravelled,
          travelledWhere: data.travelledWhere,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusQuestionnaireResults'],
      });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCoronavirusQuestionnaireResultsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/coronavirus-questionnaire-results/${id}`,
        data: {
          temperature: data.temperature.toString(),
          hasCough: data.hasCough,
          hasDifficultyBreathing: data.hasDifficultyBreathing,
          hasFever: data.hasFever,
          hasBeenInContactExperiencing: data.hasBeenInContactExperiencing,
          hasBeenInContactPositive: data.hasBeenInContactPositive,
          hasTravelled: data.hasTravelled,
          travelledWhere: data.travelledWhere,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusQuestionnaireResults'],
      });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCoronavirusQuestionnaireResultsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/coronavirus-questionnaire-results/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusQuestionnaireResults'],
      });

      options?.onSuccess?.(...res);
    },
  });
};
