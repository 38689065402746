import { Box, Icon, Text } from 'octiv-components';
import React from 'react';

const Toast = ({
  iconProps,
  body,
  textBodyProps,
  textTitleProps,
  title,
  variant,
  ...props
}) => {
  let icon;

  switch (variant) {
    case 'success':
      icon = 'done';
      break;

    case 'info':
      icon = 'info';
      break;

    case 'warning':
      icon = 'warning';
      break;

    case 'danger':
      icon = 'error';
      break;

    default:
      break;
  }

  return (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      pl={icon ? 4 : 5}
      pr={5}
      py={3}
      {...props}
    >
      {icon && <Icon color='button' mr={2} name={icon} {...iconProps} />}

      {(title || body) && (
        <Box>
          {title && (
            <Text isBold color='button' {...textTitleProps}>
              {title}
            </Text>
          )}

          {(body || textBodyProps) && (
            <Text color='button' {...textBodyProps}>
              {body}
            </Text>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Toast;
