import { StyledBox } from 'octiv-components/box/Styled';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: '100%';
  }
`;

const StyledProcessingBar = styled(StyledBox)`
  transition: width 250ms ease;
  animation: ${animation} 1s ease;
`;

export default StyledProcessingBar;
