import 'moment/locale/de';
import 'moment/locale/fr';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
import en from './locales/en.json';
import fr from './locales/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    debug: process.env.NODE_ENV !== 'production',
    fallbackLng: 'en',
    resources: {
      en,
      de,
      fr,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
