import { BulkActions, Card, Container, Divider, Modal } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import {
  useUsersFind,
  useUsersUpdate,
  useUsersUpdateChangePassword,
  useUsersUpdateSwitch,
} from 'octiv-hooks/requests/Users';
import {
  useUserTenantsDeleteBulk,
  useUserTenantsUpdate,
  useUserTenantsUpdateBulkStatus,
} from 'octiv-hooks/requests/UserTenants';
import { getFullName, getSelectedRows } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormUser from '../../user/profile/FormUser';
import FormUserTenant from '../../userStaff/FormUserTenant';
import FormChangePassword from './FormChangePassword';
import FormQuery from './FormQuery';
import Table from './Table';

const SuperAdminUsers = () => {
  const { t } = useTranslation();

  const [toggleFormUser, setToggleFormUser, resetToggleFormUser] = useToggle();
  const [
    toggleFormChangePassword,
    setToggleFormChangePassword,
    resetToggleFormChangePassword,
  ] = useToggle();

  const [users, setUsers] = useState([]);
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    userGroup: 'staff',
    tenantId: undefined,
    userTenantLocationId: undefined,
    userTenantStatusId: 2,
    userTenantDebitStatusId: undefined,
    search: undefined,

    sort: 'name',
  });

  const selectedData = getSelectedRows({ data: users });

  const tenantsFind = useTenantsFind({
    filter: { statusId: 1 },
    paging: { perPage: -1 },
  });

  const locationsFind = useLocationsFind(
    {
      filter: { tenantId: query.tenantId },
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.tenantId,
    }
  );

  const usersFind = useUsersFind(
    {
      filter: {
        ...query,
        userTenantTypeId: query.userGroup === 'staff' ? '2,3,5,6,8' : '4',
      },
      include:
        query.userGroup === 'staff' || query?.userGroup === 'members'
          ? 'userTenant,userTenant.locations,tenantUser.locations,tenantUser.tenant'
          : undefined,
      sort: query.sort,
      paging,
    },
    {
      enabled: !!query.tenantId || query?.search?.length > 0,
      onSuccess: (response) => setUsers(response?.data),
    }
  );

  const usersUpdate = useUsersUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const usersUpdateChangePassword = useUsersUpdateChangePassword({
    onSuccess: resetToggleFormChangePassword,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdate = useUserTenantsUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsUpdateBulkStatus = useUserTenantsUpdateBulkStatus({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsDeleteBulk = useUserTenantsDeleteBulk({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const usersUpdateSwitch = useUsersUpdateSwitch();

  const onClickAction = ({ action, isBulk = false, userId, userTenantId }) => {
    switch (action) {
      case 'view':
        setToggleFormUser({ data: users.find((user) => user.id === userId) });
        break;

      case 'activate':
        userTenantsUpdateBulkStatus.mutate({
          tenantId: query?.tenantId,
          userIds: isBulk ? selectedData.map((item) => item.id) : [userId],
          statusId: 2,
        });
        break;

      case 'deactivate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: isBulk ? selectedData.map((item) => item.id) : [userId],
          tenantId: query?.tenantId,
          statusId: 4,
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          userTenantsDeleteBulk.mutate({
            userTenantIds: isBulk
              ? selectedData.map((item) => item.userTenantId)
              : [userTenantId],
          });
        }
        break;

      case 'changePassword':
        setToggleFormChangePassword({
          data: {
            userIds: isBulk ? selectedData.map((item) => item.id) : [userId],
          },
        });
        break;

      case 'login':
        usersUpdateSwitch.mutate(
          {
            id: userId,
          },
          {
            onSuccess: ({ token }) => {
              window.open(
                `/dashboard?switchUserToken=${token}&switchUserTenantId=${userTenantId}`
              );
            },
          }
        );

        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleFormUser.isVisible && (
        <Modal
          title={getFullName(
            users.find((user) => user.id === toggleFormUser.data.id)
          )}
          onClose={resetToggleFormUser}
        >
          <Card hasBorder mb={4} title='User Details'>
            <FormUser
              includePassword={false}
              initialValues={toggleFormUser.data}
              isLoading={usersUpdate.isLoading}
              onSubmit={usersUpdate.mutate}
            />
          </Card>

          {query.userGroup === 'staff' && (
            <Card hasBorder title='Account Details'>
              <FormUserTenant
                initialValues={toggleFormUser.data.userTenant}
                isLoading={userTenantsUpdate.isLoading}
                locationOptions={locationsFind.data?.data?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                loggedInUserTypeId={1}
                onSubmit={(values) =>
                  userTenantsUpdate.mutate({
                    id: toggleFormUser.data.userTenant.id,
                    tenantId: query?.tenantId,
                    ...values,
                  })
                }
              />
            </Card>
          )}
        </Modal>
      )}

      {toggleFormChangePassword.isVisible && (
        <Modal
          isSidebar
          title={t('changePassword')}
          onClose={resetToggleFormChangePassword}
        >
          <FormChangePassword
            initialValues={toggleFormChangePassword.data}
            isLoading={usersUpdateChangePassword.isLoading}
            onSubmit={usersUpdateChangePassword.mutate}
          />
        </Modal>
      )}

      <Container
        appBarProps={{ title: t('users') }}
        isLoading={usersFind.isFetching}
      >
        <FormQuery
          initialValues={query}
          isFetchingLocations={locationsFind.isFetching}
          isFetchingTenants={tenantsFind.isFetching}
          locations={locationsFind.data?.data}
          tenants={tenantsFind.data?.data}
          onSubmit={(values) =>
            setQuery((prev) => ({
              ...values,
              sort: prev.sort,
            }))
          }
        />

        <Divider pb={4} />

        <BulkActions
          data={selectedData}
          mb={4}
          options={[
            ...(query.userTenantStatusId !== 2 && query.userTenantStatusId !== 6
              ? [
                  {
                    icon: 'person_add_alt',
                    title: t('activate'),
                    value: 'activate',
                    color: 'success',
                  },
                ]
              : []),
            ...(query.userTenantStatusId !== 4
              ? [
                  {
                    icon: 'person_remove',
                    title: t('deactivate'),
                    value: 'deactivate',
                    color: 'danger',
                  },
                ]
              : []),
            ...(query.userTenantStatusId === 4
              ? [
                  {
                    icon: 'delete',
                    title: t('delete'),
                    value: 'delete',
                    color: 'danger',
                  },
                ]
              : []),
            {
              icon: 'vpn_key',
              title: t('changePassword'),
              value: 'changePassword',
            },
          ]}
          onClick={({ value }) =>
            onClickAction({ isBulk: true, action: value })
          }
        />

        <Table
          data={users || []}
          isLoading={usersFind.isFetching}
          paging={usersFind.data?.paging}
          query={query}
          setPaging={setPaging}
          setQuery={setQuery}
          setSelected={setUsers}
          tenants={tenantsFind.data}
          onClickAction={onClickAction}
          onClickRow={({ original }) =>
            onClickAction({ action: 'view', userId: original?.id })
          }
          onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
        />
      </Container>
    </>
  );
};

export default SuperAdminUsers;
