import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  debitBatchesAll,
  isFetchingDebitBatchesAll,
  isFetchingMembers,
  isLoading,
  locationOptions,
  members,
  onLocationSelect,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        locationId: undefined,
        debitBatchId: undefined,
        userId: undefined,
      }}
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        debitBatchId: Yup.string().required(t('required')),
        userId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, values: { locationId } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                doFinally={onLocationSelect}
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            {!!locationId && (
              <>
                <Col>
                  <Field
                    isSelect
                    isLoading={isFetchingDebitBatchesAll}
                    label={t('batch')}
                    name='debitBatchId'
                    options={
                      debitBatchesAll
                        ? debitBatchesAll?.data?.map((item) => ({
                            label: getDateReadableDayMonthYear({
                              date: item.debitDayDate.date,
                            }),
                            value: item.id,
                          }))
                        : []
                    }
                  />
                </Col>

                <Col>
                  <Field
                    isSelect
                    isLoading={isFetchingMembers}
                    label={t('member')}
                    name='userId'
                    options={members?.map((item) => ({
                      label: getFullName(item),
                      value: item.id,
                    }))}
                  />
                </Col>

                <Col>
                  <Button
                    isLoading={isLoading}
                    text={t('submit')}
                    type='submit'
                  />
                </Col>
              </>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
