import moment from 'moment';
import { Box, Container, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useQuery, useToggle } from 'octiv-hooks';
import { useClassesFind } from 'octiv-hooks/requests/Classes';
import {
  useReportsFindAttendanceMemberAttendance,
  useReportsFindAttendanceMemberAttendanceDetails,
} from 'octiv-hooks/requests/Reports';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const initialUserTenantId = useQuery('userId');

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    statusId: 2,
    userId:
      initialUserTenantId !== null ? Number(initialUserTenantId) : undefined,
    isSession: undefined,
    classId: undefined,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindAttendanceMemberAttendance({
      filter: query,
      sort: 'user.name',
      paging,
    });

  const { data: reportDetail, isFetching: isFetchingReportDetail } =
    useReportsFindAttendanceMemberAttendanceDetails(
      {
        filter: {
          ...query,
          bookingStatusId: toggle.data?.bookingStatusId,
          userId: toggle.data?.userId,
        },
        include: 'classDate,classDate.headCoach',
      },
      {
        enabled: toggle.data !== undefined,
      }
    );

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.locationId,
      userTenantStatusId: query.statusId,
      isMinimalData: true,
    },
    paging: { perPage: -1 },
  });

  const { data: classes, isFetching: isFetchingClasses } = useClassesFind({
    filter: {
      locationId: query.locationId,
      isSession: query.isSession,
    },
  });

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('attendanceDetail')} onClose={resetToggle}>
          <TableDetail
            data={isFetchingReportDetail ? [] : reportDetail}
            isLoading={isFetchingReportDetail}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('memberAttendance'),
          breadcrumbs: [t('reports'), t('attendance')],
        }}
        isLoading={isFetchingReport || isFetchingReportDetail}
      >
        <FormQuery
          classes={classes?.data || []}
          initialValues={query}
          isFetchingClasses={isFetchingClasses}
          isFetchingMembers={isFetchingMembers}
          locationOptions={locationOptionsAll}
          members={members?.data || []}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TableReport
          data={report?.data || []}
          isLoading={isFetchingReport}
          paging={report?.paging}
          query={query}
          setPaging={setPaging}
          onClickBookingStatus={(data) => setToggle({ data })}
        />
      </Container>
    </>
  );
};
