import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Col,
  Field,
  Modal,
  Row,
  Step,
  Text,
} from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  generateTimeOptions,
  getDateReadableDayMonthYear,
  getDateTime,
  getDateYearMonthDay,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import AddRecipient from './addRecipient';
import TableRecipient from './TableRecipient';

const lengthSms = 160;
const lengthPush = 178;

export default ({ canSetType, isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  return (
    <Formik
      {...props}
      initialValues={{
        isConfirmed: false,
        name: initialValues.name || undefined,
        type: initialValues.type || undefined,
        frequency:
          initialValues.frequency && initialValues.frequency !== 'now'
            ? 'onceoff'
            : 'now',
        sendDate:
          getDateYearMonthDay({ date: initialValues.nextScheduledFor }) ||
          undefined,
        sendTime:
          getDateTime({ date: initialValues.nextScheduledFor }) || undefined,
        content: initialValues.content || undefined,
        recipientsMembers: initialValues.recipients
          ? initialValues.recipients.members || []
          : [],
        recipientsStaff: initialValues.recipients
          ? initialValues.recipients.staff || []
          : [],
        recipientsLeads: initialValues.recipients
          ? initialValues.recipients.leads || []
          : [],
        recipientsNonMembers: initialValues.recipients
          ? initialValues.recipients.nonMembers || []
          : [],

        // Push
        title: initialValues.title || undefined,

        // Email
        subject: initialValues.subject || undefined,
        replyTo: initialValues.replyTo || undefined,
        senderName: initialValues.senderName || undefined,
        imageHeader: initialValues.imageHeader || undefined,
        imageFooter: initialValues.imageFooter || undefined,
        attachment:
          initialValues.attachments && initialValues.attachments[0]
            ? initialValues.attachments[0].attachmentPath
            : undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        type: Yup.string().required(t('required')),
        frequency: Yup.string().required(t('required')),
        sendDate: Yup.string().when('frequency', {
          is: (value) => value === 'onceoff',
          then: Yup.string().required(t('required')),
        }),
        sendTime: Yup.string().when('frequency', {
          is: (value) => value === 'onceoff',
          then: Yup.string().required(t('required')),
        }),
        content: Yup.string()
          .when('type', {
            is: (value) => value === 'sms',
            then: Yup.string().max(lengthSms).required(t('required')),
          })
          .when('type', {
            is: (value) => value === 'push',
            then: Yup.string().max(lengthPush).required(t('required')),
          }),
        recipientsMembers: Yup.array(),
        recipientsStaff: Yup.array(),
        recipientsLeads: Yup.array(),
        recipientsNonMembers: Yup.array(),

        // Push
        title: Yup.string().when('type', {
          is: (value) => value === 'push',
          then: Yup.string().required(t('required')),
        }),

        // Email
        subject: Yup.string().when('type', {
          is: (value) => value === 'email',
          then: Yup.string().required(t('required')),
        }),
        replyTo: Yup.string().when('type', {
          is: (value) => value === 'email',
          then: Yup.string().required(t('required')),
        }),
        senderName: Yup.string().when('type', {
          is: (value) => value === 'email',
          then: Yup.string().required(t('required')),
        }),
        imageHeader: Yup.string(),
        imageFooter: Yup.string(),
        attachment: Yup.string(),
      })}
    >
      {({
        values: {
          isConfirmed,
          type,
          frequency,
          imageHeader,
          content,
          imageFooter,
          recipientsMembers,
          recipientsStaff,
          recipientsLeads,
          recipientsNonMembers,
          sendDate,
          sendTime,
        },
        handleSubmit,
        setFieldTouched,
        setFieldValue,
      }) => {
        const hasRecipients =
          !_.isEmpty(recipientsMembers) ||
          !_.isEmpty(recipientsStaff) ||
          !_.isEmpty(recipientsLeads) ||
          !_.isEmpty(recipientsNonMembers);
        const hasEmailContent =
          type === 'email' && !!(imageHeader || content || imageFooter);

        return (
          <>
            {toggle.isVisible && (
              <Modal
                title={`${t('recipients')}: ${toggle.data.title}`}
                onClose={resetToggle}
              >
                <AddRecipient
                  currentRecipients={toggle.data.data}
                  isTypeEmail={type === 'email'}
                  recipientType={toggle.data.value}
                  setFieldValue={(newRecipients) =>
                    setFieldValue(toggle.data.value, newRecipients)
                  }
                  onCloseModal={resetToggle}
                />
              </Modal>
            )}

            <form onSubmit={handleSubmit}>
              <Step
                boxContainerProps={{ mb: 4 }}
                title={t('generalDetails')}
                value='1'
              >
                <Row>
                  <Col md={3}>
                    <Field label={t('communicationName')} name='name' />
                  </Col>

                  {canSetType && (
                    <Col md={3}>
                      <Field
                        isSelect
                        doFinally={(newType) => {
                          if (newType !== type) {
                            setFieldTouched('content', false);
                            setFieldValue('content', undefined);
                          }
                        }}
                        // isOptionDisabled={(option) => option.value === 'sms'}
                        label={t('type')}
                        name='type'
                        options={[
                          { label: t('email'), value: 'email' },
                          {
                            label: `${t('sms')} (${t('temporarilyDisabled')})`,
                            value: 'sms',
                            isDisabled: true,
                          },
                          {
                            label: t('pushNotification'),
                            value: 'push',
                          },
                        ]}
                      />
                    </Col>
                  )}

                  <Col md={3}>
                    <Field
                      isSelect
                      doFinally={() => {
                        setFieldTouched('sendDate', false);
                        setFieldValue('sendTime', undefined);
                        setFieldTouched('sendDate', false);
                        setFieldValue('sendTime', undefined);
                      }}
                      label={t('whenSendIt')}
                      name='frequency'
                      options={[
                        { label: t('sendNow'), value: 'now' },
                        { label: t('sendLater'), value: 'onceoff' },
                      ]}
                    />
                  </Col>

                  {frequency === 'onceoff' && (
                    <Col>
                      <Row>
                        <Col md={3}>
                          <Field
                            isDate
                            doFinally={() => {
                              setFieldTouched('sendTime', false);
                              setFieldValue('sendTime', undefined);
                            }}
                            label={t('sendDate')}
                            minDate={moment()}
                            name='sendDate'
                          />
                        </Col>

                        <Col md={3}>
                          <Field
                            isSelect
                            isDisabled={!sendDate}
                            label={t('sendTime')}
                            name='sendTime'
                            options={generateTimeOptions(
                              moment(sendDate).isSame(moment(), 'day')
                                ? {
                                    minTime: getDateTime({ canFallback: true }),
                                  }
                                : undefined
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Step>

              {type === 'email' ? (
                <Step
                  boxContainerProps={{ mb: 4 }}
                  title={t('email')}
                  value='2'
                >
                  <Row>
                    <Col md={3}>
                      <Field label={t('subject')} name='subject' />
                    </Col>

                    <Col md={3}>
                      <Field label={t('replyToEmailAddress')} name='replyTo' />
                    </Col>

                    <Col md={3}>
                      <Field label={t('senderName')} name='senderName' />
                    </Col>

                    <Col>
                      <Row>
                        <Col md={6}>
                          <Field
                            isDrop
                            boxContainerProps={{ mt: 2, mb: 4 }}
                            label={t('headerImage')}
                            name='imageHeader'
                          />

                          <Field
                            isEditor
                            boxContainerProps={{ mb: 4 }}
                            label={t('content')}
                            name='content'
                            placeholders={[
                              { label: t('firstName'), value: '[name]' },
                              {
                                label: t('surname'),
                                value: '[surname]',
                              },
                            ]}
                          />

                          <Field
                            isDrop
                            boxContainerProps={{ mb: 4 }}
                            label={t('footerImage')}
                            name='imageFooter'
                          />

                          <Field
                            isDrop
                            useFileIcon
                            label={t('attachment')}
                            name='attachment'
                          />
                        </Col>

                        <Col md={6}>
                          <Text mb={2} variant='subheading'>
                            {t('emailPreview')}
                          </Text>

                          <Box hasRadius bg='#fff' p={4}>
                            <Box
                              hasRadius
                              border='1px solid #e5e5ea'
                              m='auto'
                              maxWidth='600px'
                              p={6}
                            >
                              {hasEmailContent ? (
                                <>
                                  {imageHeader && (
                                    <img
                                      alt={t('header')}
                                      src={
                                        imageHeader?.path
                                          ? URL.createObjectURL(imageHeader)
                                          : imageHeader
                                      }
                                      style={{
                                        maxWidth: '100%',
                                        marginBottom: '16px',
                                      }}
                                    />
                                  )}

                                  {content && (
                                    <Box
                                      className='email-content'
                                      dangerouslySetInnerHTML={{
                                        __html: content,
                                      }}
                                    />
                                  )}

                                  {imageFooter && (
                                    <img
                                      alt={t('footer')}
                                      src={
                                        imageFooter?.path
                                          ? URL.createObjectURL(imageFooter)
                                          : imageFooter
                                      }
                                      style={{
                                        maxWidth: '100%',
                                        marginTop: '16px',
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <Text color='#888888' textAlign='center'>
                                  {t('noContent')}
                                </Text>
                              )}
                            </Box>
                          </Box>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Step>
              ) : type === 'sms' ? (
                <Step boxContainerProps={{ mb: 4 }} title={t('sms')} value='2'>
                  <Field
                    as='textarea'
                    label={t('content')}
                    maxLength={lengthSms}
                    name='content'
                  />

                  <Text mt={2} textAlign='right'>
                    {`${lengthSms - (content?.length || 0)} ${t(
                      'remainingCharacters'
                    )}`}
                  </Text>
                </Step>
              ) : type === 'push' ? (
                <Step
                  boxContainerProps={{ mb: 4 }}
                  title={t('pushNotification')}
                  value='2'
                >
                  <Row>
                    <Col md={3}>
                      <Field label={t('title')} name='title' />
                    </Col>

                    <Col>
                      <Field
                        as='textarea'
                        label={t('body')}
                        maxLength={lengthPush}
                        name='content'
                      />

                      <Text mt={2} textAlign='right'>
                        {`${lengthPush - (content?.length || 0)} ${t(
                          'remainingCharacters'
                        )}`}
                      </Text>
                    </Col>
                  </Row>
                </Step>
              ) : null}

              {type && (
                <>
                  <Step
                    boxContainerProps={{ mb: 4 }}
                    title={t('recipients')}
                    value='3'
                  >
                    <Row>
                      {[
                        {
                          title: t('members'),
                          value: 'recipientsMembers',
                          data: recipientsMembers,
                        },
                        ...(type !== 'push'
                          ? [
                              {
                                title: t('staff'),
                                value: 'recipientsStaff',
                                data: recipientsStaff,
                              },
                              {
                                title: t('leads'),
                                value: 'recipientsLeads',
                                data: recipientsLeads,
                              },
                              {
                                title: t('others'),
                                value: 'recipientsNonMembers',
                                data: recipientsNonMembers,
                              },
                            ]
                          : []),
                      ].map((recipientSet) => {
                        const hasData = !_.isEmpty(recipientSet.data);

                        return (
                          <Col key={recipientSet.value} lg={3} md={6}>
                            <Card
                              hasBorder
                              boxChildrenContainerProps={{
                                p: 0,
                                pt: 4,
                                pb: hasData ? 0 : 4,
                                px: 4,
                              }}
                              title={recipientSet.title}
                            >
                              <Button
                                hasBorder
                                isFullWidth
                                text={`${hasData ? t('addRemove') : t('add')}`}
                                onClick={() =>
                                  setToggle({ data: recipientSet })
                                }
                              />

                              {hasData && (
                                <Box
                                  className='hide-scrollbar'
                                  maxHeight='31rem'
                                  overflowX='scroll'
                                >
                                  <TableRecipient
                                    data={recipientSet?.data || []}
                                  />
                                </Box>
                              )}
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </Step>

                  <Step
                    boxContainerProps={{ mb: 4 }}
                    title={t('confirmation')}
                    value='4'
                  >
                    {hasRecipients ? (
                      <Field
                        isCheckbox
                        name='isConfirmed'
                        options={[
                          {
                            label: `${t('confirmationContent')} ${
                              frequency === 'now'
                                ? 'now'
                                : sendDate && sendTime
                                ? `${getDateReadableDayMonthYear({
                                    date: sendDate,
                                  })} - ${sendTime.slice(0, -3)}`
                                : t('selectDateTime')
                            }`,
                            value: true,
                          },
                        ]}
                      />
                    ) : (
                      <Text color='warning'>{t('recipientRequired')}</Text>
                    )}

                    {isLoading && (
                      <Text color='info' mt={4}>
                        {t('timeToResolve')}
                      </Text>
                    )}

                    <Button
                      isDisabled={!isConfirmed || !hasRecipients}
                      isLoading={isLoading}
                      mt={4}
                      text={t('submit')}
                      type='submit'
                    />
                  </Step>
                </>
              )}
            </form>
          </>
        );
      }}
    </Formik>
  );
};
