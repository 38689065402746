import { Container } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Adhoc from './adhoc';
import GoCardless from './goCardless';
import Netcash from './netcash';
import PayFast from './payFast';
import Sepa from './sepa';
import ThreePeaks from './threePeaks';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations, regionId, isSepa },
  } = useActiveUserTenant();

  const isTenantPayFast = regionId !== 1;

  const hasLocationGoCardless = !!locations.find(
    (item) => item.paymentGatewayId === 5
  );

  const hasLocationNetcash = !!locations.find(
    (item) => item.paymentGatewayId === 4
  );

  const hasLocationThreePeaks = !!locations.find(
    (item) => item.paymentGatewayId === 3
  );

  return (
    <Container
      appBarProps={{
        title: t('paymentGateways'),
        breadcrumbs: [t('settings')],
      }}
    >
      {isTenantPayFast && <PayFast />}

      {hasLocationGoCardless && <GoCardless />}

      {isSepa && <Sepa />}

      {hasLocationNetcash && <Netcash />}

      {hasLocationThreePeaks && <ThreePeaks />}

      <Adhoc
        hasLocationGoCardless={hasLocationGoCardless}
        hasLocationNetcash={hasLocationNetcash}
      />
    </Container>
  );
};
