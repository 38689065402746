import {
  useSettingsFindWorkout,
  useSettingsUpdateWorkout,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useSettingsFindWorkout();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateWorkout({ onSuccess: getSettingsRequest });

  const handleSubmit = (values) => putSettingsRequest(values);

  return (
    <Setting isLoading={isFetchingSettings} title={t('workoutVisibility')}>
      <Form
        initialValues={settings || {}}
        isLoading={isUpdatingSettings}
        onSubmit={handleSubmit}
      />
    </Setting>
  );
};
