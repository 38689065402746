import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  currencies,
  initialValues = {},
  isFetchingCurrencies,
  isFetchingTimezones,
  isLoading,
  timezones,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        id: initialValues.id || undefined,
        name: initialValues.name || undefined,
        currencies:
          initialValues.currencies && initialValues.currencies[0]
            ? initialValues.currencies.map((item) => item.id)
            : undefined,
        timezones:
          initialValues.timezones && initialValues.timezones[0]
            ? initialValues.timezones.map((item) => item.id)
            : undefined,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('required')),
        currencies: Yup.array().required(t('required')).min(1, t('required')),
        timezones: Yup.array().required(t('required')).min(1, t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field label={t('name')} name='name' />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingCurrencies}
                label='Currencies'
                name='currencies'
                options={currencies.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingTimezones}
                label='Timezones'
                name='timezones'
                options={timezones.map((item) => ({
                  label: `${item.name} ${item.description} - ${item.zone}`,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
