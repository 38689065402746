import { useTenantSettingsFindCoronavirus } from 'octiv-hooks/requests/TenantSettings';
import { accessInitialState, getAccessContext } from 'octiv-utilities/Access';
import React from 'react';

import { useActiveUserTenant } from './ActiveUserTenant';

export const AccessInitialState = {
  ...accessInitialState,
  setAccess: () => {},
};

export const AccessContext = React.createContext(AccessInitialState);

export const useAccess = () => {
  const access = React.useContext(AccessContext);

  return access;
};

export const AccessContextProvider = ({ children }) => {
  const activeUserTenant = useActiveUserTenant();

  const [accessContext, setAccessContext] = React.useState(AccessInitialState);

  const tenantSettingsFindCoronavirus = useTenantSettingsFindCoronavirus(
    { tenantId: activeUserTenant.tenantId },
    { enabled: !!activeUserTenant.tenantId }
  );

  React.useEffect(() => {
    setAccessContext({
      ...AccessInitialState,
      ...getAccessContext({
        typeId: activeUserTenant.typeId,
        accessPrivileges: activeUserTenant.accessPrivileges,
        hiddenContainers: activeUserTenant.tenant.hiddenFeatures,
        settingsCoronavirus: tenantSettingsFindCoronavirus.data,
      }),
      setAccess: (newAccessContext = {}) => {
        setAccessContext((prev) => ({
          ...prev,
          ...getAccessContext({
            typeId: activeUserTenant.typeId,
            accessPrivileges: activeUserTenant.accessPrivileges,
            hiddenContainers: activeUserTenant.tenant.hiddenFeatures,
            settingsCoronavirus: tenantSettingsFindCoronavirus.data,
            ...newAccessContext,
          }),
        }));
      },
    });
  }, [activeUserTenant, tenantSettingsFindCoronavirus.data]);

  return (
    <AccessContext.Provider value={accessContext}>
      {children}
    </AccessContext.Provider>
  );
};
