import { Integrations } from '@sentry/tracing';
import { isProduction, isStaging, isUat } from 'octiv-utilities/Constants';

export default {
  dsn:
    isProduction || isStaging || isUat
      ? process.env.REACT_APP_SENTRY_DSN
      : null,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: isProduction ? 'production' : 'staging',
  enableAutoSessionTracking: true,
  beforeSend(event, hint) {
    const error = hint?.originalException;

    const ignoreStrings = [
      'QuotaExceededError', // Firebase Analytics
      'SecurityError: The operation is insecure.', // Firebase Analytics
      'Object Not Found Matching Id',
      'Non-Error promise rejection captured with',
      "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
      "SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
      'Installations: Could not process request. Application offline.',
      'ResizeObserver loop limit exceeded',
    ];

    if (
      error &&
      error.message &&
      ignoreStrings.findIndex((string) => error.message.includes(string)) !== -1
    ) {
      return null;
    }

    return event;
  },
};
