import { Box, Icon, Image, Text } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

const Sidebar = ({ stacks }) => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const { xsDown } = useMediaQuery();

  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [visibleSubItemsIndex, setVisibleSubItemsIndex] = useState(undefined);

  const showDrawer = () => setIsVisibleDrawer(true);
  const hideDrawer = () => setIsVisibleDrawer(false);
  const toggleDrawer = () => setIsVisibleDrawer((prev) => !prev);

  const renderPrimaryNavigationItem = ({
    hasSubItems = false,
    icon,
    image,
    isActive = false,
    isVisibleSubItems = false,
    title,
    path,
    onClick,
  }) => (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      as={path ? Link : undefined}
      bg={isActive ? 'grey6' : 'transparent'}
      bgHover={isActive ? 'grey6' : 'grey5'}
      key={title}
      mb={1}
      to={path}
      width={isVisibleDrawer ? 50 : 10}
      onClick={onClick}
    >
      {icon ? (
        <Icon color='grey1' isActive={isActive} name={icon} p={2} />
      ) : image ? (
        <Box
          isFlex
          alignItems='center'
          justifyContent='center'
          minHeight={10}
          minWidth={10}
        >
          <Image alt='Discovery Vitality' size={5} src={image} />
        </Box>
      ) : null}

      <Box
        isFlex
        alignItems='center'
        justifyContent='space-between'
        minWidth={40}
        opacity={isVisibleDrawer ? 1 : 0}
        pl={1}
      >
        <Text color={isActive ? 'font' : 'grey1'}>{title}</Text>

        {hasSubItems && !isActive && (
          <Icon
            color='grey1'
            name={isVisibleSubItems ? 'arrow_drop_up' : 'arrow_drop_down'}
            p={2}
          />
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        bg='darkOverlay'
        bottom={0}
        left={0}
        opacity={isVisibleDrawer ? 1 : 0}
        pointerEvents={xsDown && isVisibleDrawer ? undefined : 'none'}
        position='fixed'
        right={0}
        top={0}
        zIndex={497}
        onClick={hideDrawer}
      />

      {xsDown && (
        <Box position='fixed' width={isVisibleDrawer ? 54 : 4} zIndex={499}>
          <Box
            isFlex
            alignItems='center'
            bg='grey5'
            hasRadius={isVisibleDrawer}
            hasRadiusRight={!isVisibleDrawer}
            justifyContent='center'
            position='absolute'
            right={`-${theme.sizes[4]}`}
            size={8}
            top={17}
            onClick={toggleDrawer}
          >
            <Icon color='primary' name={isVisibleDrawer ? 'close' : 'menu'} />
          </Box>
        </Box>
      )}

      <Box
        isFlex
        bg='grey7'
        boxShadow={`1px 0 ${theme.colors.grey5}`}
        className='hide-scrollbar'
        flexDirection='column'
        height='100vh'
        left={xsDown && !isVisibleDrawer ? `-${theme.sizes[14]}` : 0}
        overflowY='scroll'
        pb={xsDown ? 30 : 4}
        position='fixed'
        pt={17}
        px={2}
        top={0}
        width={isVisibleDrawer ? 54 : 14}
        zIndex={497}
        onMouseEnter={!xsDown ? showDrawer : undefined}
        onMouseLeave={!xsDown ? hideDrawer : undefined}
      >
        {stacks.map(
          (
            {
              title,
              icon,
              image,
              screens,
              options: { isVisibleInNavigation = true } = {},
            },
            index
          ) => {
            if (isVisibleInNavigation === false) return null;

            if (screens.length === 1) {
              const { path, options = {} } = screens[0];
              const isActive = pathname === path;

              if (options.isVisibleInNavigation === false) return null;

              return renderPrimaryNavigationItem({
                isVisibleDrawer,
                title,
                icon,
                image,
                path,
                onClick: isActive
                  ? undefined
                  : () => {
                      if (xsDown) hideDrawer();
                      setVisibleSubItemsIndex(null);
                      window.scrollTo(0, 0);
                    },
                isActive,
              });
            }

            const isVisibleSubItems = visibleSubItemsIndex === index;
            let isPrimaryActive = false;

            const secondaryNavigationItems = [];
            screens.map(({ isDivider, path, options = {} }, screenIndex) => {
              if (options.isVisibleInNavigation === false) return null;

              if (isDivider) {
                const isFristItem = screenIndex === 0;

                return secondaryNavigationItems.push(
                  <Box
                    borderTopColor='grey3'
                    borderTopStyle='solid'
                    borderTopWidth={isFristItem ? '0' : '1px'}
                    key={`${screenIndex.toString()}`}
                    mb={1}
                    ml={8}
                    mt={isFristItem ? 1 : 2}
                    pt={isFristItem ? 2 : 4}
                    px={3}
                    width={42}
                  >
                    <Text isBold isUpperCase variant='caption'>
                      {options.title}
                    </Text>
                  </Box>
                );
              }

              let isActive = false;
              if (pathname === path) {
                isPrimaryActive = true;
                isActive = true;
              }

              return secondaryNavigationItems.push(
                <Box
                  hasRadius
                  isFlex
                  alignItems='center'
                  as={Link}
                  bg={isActive ? 'grey6' : 'transparent'}
                  bgHover={isActive ? 'grey6' : 'grey5'}
                  key={`${screenIndex.toString()}`}
                  mb={1}
                  ml={8}
                  px={3}
                  py={2}
                  to={path}
                  width={42}
                  onClick={
                    isActive
                      ? undefined
                      : () => {
                          if (xsDown) hideDrawer();
                          setVisibleSubItemsIndex(index);
                          window.scrollTo(0, 0);
                        }
                  }
                >
                  <Text color={isActive ? 'primary' : 'grey1'}>
                    {options.title}
                  </Text>
                </Box>
              );
            });

            return (
              <React.Fragment key={title}>
                {renderPrimaryNavigationItem({
                  isVisibleDrawer,
                  title,
                  icon,
                  onClick: () =>
                    isVisibleSubItems
                      ? setVisibleSubItemsIndex(null)
                      : setVisibleSubItemsIndex(index),
                  isActive: isPrimaryActive,
                  hasSubItems: true,
                  isVisibleSubItems,
                })}

                {isVisibleDrawer &&
                  (isVisibleSubItems || isPrimaryActive) &&
                  secondaryNavigationItems}
              </React.Fragment>
            );
          }
        )}
      </Box>
    </>
  );
};

export default Sidebar;
