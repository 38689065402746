import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useTasksFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['tasks', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/tasks`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            isCompleted: filter.isCompleted,
            isScheduled: filter.isScheduled,
            locationId: filter.locationId,
            assignedUserId: filter.assignedUserId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useTasksCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/tasks`,
        data: {
          tenantId: data.tenantId || tenantId,
          summary: data.summary,
          description: data.description,
          dueDate: data.dueDate,
          locationId: data.locationId,
          isCompleted: data.isCompleted,
          assignedUserIds: data.assignedUserIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTasksFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['tasks', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/tasks/${id}`,
      }),
    options
  );
};

export const useTasksUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/tasks/${id}`,
        data: {
          summary: data.summary,
          description: data.description,
          dueDate: data.dueDate,
          locationId: data.locationId,
          isCompleted: data.isCompleted,
          assignedUserIds: data.assignedUserIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTasksDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/tasks/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTasksUpdateCompletedUncompleted = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/tasks/${id}/completed-uncompleted`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] });

      options?.onSuccess?.(...res);
    },
  });
};
