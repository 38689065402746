import { Container, Step } from 'octiv-components';
import { ValidateUser } from 'octiv-containers';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useBanksFind } from 'octiv-hooks/requests/Banks';
import { useFinancesFindDiscounts } from 'octiv-hooks/requests/Finances';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useSettingsFindSignUp } from 'octiv-hooks/requests/Settings';
import { useUsersCreate, useUsersFindStaff } from 'octiv-hooks/requests/Users';
import { useUserTenantsCreate } from 'octiv-hooks/requests/UserTenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sanitizeValues } from '../../userMember/billing/FormPaymentDetails';
import FormUser from './FormUser';
import FormUserTenant from './FormUserTenant';

export default () => {
  const { t } = useTranslation();

  const {
    tenantId,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [user, setUser] = useState();
  const isUser = !!user?.id;

  const [query, setQuery] = useState({
    packagesLocationId: undefined,
    programmesPackageId: undefined,
  });

  const banksFind = useBanksFind();

  const usersFindStaff = useUsersFindStaff({ paging: { perPage: -1 } });

  const packagesFind = usePackagesFind(
    {
      filter: {
        isHidden: false,
        isActive: true,
        locationId: query.packagesLocationId,
        typeId: '1,2,3',
      },
      paging: { perPage: -1 },
    },
    { enabled: !!query.packagesLocationId }
  );

  const programmesFind = useProgrammesFind(
    {
      filter: {
        packageId: query.programmesPackageId,
        isActive: 1,
      },
    },
    {
      enabled: !!query.programmesPackageId,
    }
  );

  const financesFindDiscounts = useFinancesFindDiscounts();

  const usersCreate = useUsersCreate({
    meta: {
      onSuccessToast: {
        title: 'User created',
      },
    },
    onSuccess: setUser,
  });

  const settingsFindSignUp = useSettingsFindSignUp({});

  const userTenantsCreate = useUserTenantsCreate();

  return (
    <Container
      appBarProps={{
        title: t('createMember'),
        breadcrumbs: [t('members')],
      }}
      isLoading={usersCreate.isLoading || userTenantsCreate.isLoading}
      minHeight='100rem'
    >
      <Step boxContainerProps={{ mb: 4 }} title='Octiv Account'>
        <ValidateUser
          // Key is used to reset form on successful submission of userTenantsCreate
          key={userTenantsCreate.data?.id}
          setUser={setUser}
          user={user}
        />
      </Step>

      {user?.email && !isUser ? (
        <Step boxContainerProps={{ mb: 4 }} title='User Details'>
          <FormUser
            isLoading={usersCreate.isLoading}
            signUpRequiredFields={
              settingsFindSignUp?.data?.signUpRequiredFields
            }
            onSubmit={(values) =>
              usersCreate.mutate({ ...values, email: user.email })
            }
          />
        </Step>
      ) : (
        user?.email && (
          <Step boxContainerProps={{ mb: 4 }} title='Account Details'>
            <FormUserTenant
              banks={banksFind.data || []}
              discounts={financesFindDiscounts.data?.data || []}
              isFetchingBanks={banksFind.isFetching}
              isFetchingDiscounts={financesFindDiscounts.isFetching}
              isFetchingPackages={packagesFind.isFetching}
              isFetchingProgrammes={programmesFind.isFetching}
              isFetchingStaff={usersFindStaff.isFetching}
              isLoading={userTenantsCreate.isLoading}
              locationOptions={locationOptions}
              packages={packagesFind.data?.data || []}
              programmes={programmesFind.data?.data || []}
              setQuery={(values) =>
                setQuery((prev) => ({ ...prev, ...values }))
              }
              staff={usersFindStaff.data?.data || []}
              onSubmit={(values, { resetForm }) =>
                userTenantsCreate.mutate(
                  {
                    tenantId,
                    userId: user.id,
                    ...sanitizeValues(values),
                  },
                  {
                    meta: {
                      onSuccessToast: {
                        title: 'User account created',
                      },
                    },
                    onSuccess: () => {
                      setUser();
                      resetForm();
                      window.scrollTo(0, 0);
                    },
                  }
                )
              }
            />
          </Step>
        )
      )}
    </Container>
  );
};
