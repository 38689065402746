import { Box, Button, Container, ProcessingSuspense } from 'octiv-components';
import {
  usePrivacyPolicyCreate,
  usePrivacyPolicyFind,
} from 'octiv-hooks/requests/PrivacyPolicy';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const {
    data: privacyPolicy,
    isFetching: isFetchingPrivacyPolicy,
    refetch: getPrivacyPolicyRequest,
  } = usePrivacyPolicyFind();

  const {
    isLoading: isUpdatingPrivacyPolicy,
    mutate: putPrivacyPolicyRequest,
  } = usePrivacyPolicyCreate({ onSuccess: getPrivacyPolicyRequest });

  const handleSubmit = (values) => putPrivacyPolicyRequest(values);

  return (
    <Container
      appBarProps={{
        title: t('privacyPolicy'),
        breadcrumbs: [t('settings')],
        children: (
          <Box ml='auto'>
            <Button
              text={t('view')}
              onClick={() => window.open('/privacy-policy')}
            />
          </Box>
        ),
      }}
    >
      {isFetchingPrivacyPolicy ? (
        <ProcessingSuspense />
      ) : (
        <Form
          initialValues={privacyPolicy || {}}
          isLoading={isUpdatingPrivacyPolicy}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};
