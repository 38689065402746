import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingTenants,
  tenants,
  isFetchingLocations,
  locations,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        userGroup: Yup.string(),
        tenantId: Yup.string(),
        userTenantLocationId: Yup.string(),
        userTenantStatusId: Yup.string(),
        userTenantDebitStatusId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ values, handleSubmit, setFieldTouched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                doFinally={() => {
                  setFieldTouched('userTenantDebitStatusId', false);
                  setFieldValue('userTenantDebitStatusId', undefined);
                }}
                label={t('type')}
                name='userGroup'
                options={[
                  { label: t('staff'), value: 'staff' },
                  { label: t('members'), value: 'members' },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                doFinally={() => {
                  setFieldTouched('userTenantLocationId', false);
                  setFieldValue('userTenantLocationId', undefined);
                }}
                isLoading={isFetchingTenants}
                label={t('tenant')}
                name='tenantId'
                options={tenants?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingLocations}
                label={t('location')}
                name='userTenantLocationId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(locations
                    ? locations.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                    : []),
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='userTenantStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            {values.userGroup === 'members' && (
              <Col md={3}>
                <Field
                  canSubmitOnChange
                  hasAlternateBackground
                  isSelect
                  label={t('paymentType')}
                  name='userTenantDebitStatusId'
                  options={[
                    { label: t('all'), value: undefined },
                    { label: t('cashEftCard'), value: 1 },
                    { label: t('debitOrder'), value: 2 },
                    { label: t('noPayment'), value: 4 },
                    { label: t('upfrontPayment'), value: 5 },
                    { label: t('onlinePayment'), value: 6 },
                  ]}
                />
              </Col>
            )}

            <Col md={3}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
