import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ initialValues = {}, isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        invoiceIds: initialValues.invoiceIds,
        date: getDateYearMonthDay({ canFallback: true }),
        type: 'cash',
        reference: undefined,
      }}
      validationSchema={Yup.object().shape({
        invoiceIds: Yup.array(),
        date: Yup.string().required(t('required')),
        type: Yup.string().required(t('required')),
        reference: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field isDate label={t('date')} name='date' />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('paymentType')}
                name='type'
                options={[
                  {
                    label: t('cash'),
                    value: 'cash',
                  },
                  {
                    label: t('eft'),
                    value: 'eft',
                  },
                  {
                    label: t('card'),
                    value: 'card',
                  },
                  { label: t('debitOrder'), value: 'debit_order' },
                ]}
              />
            </Col>

            <Col>
              <Field label={t('reference')} name='reference' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
