import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isFetchingPaymentTags,
  isLoading,
  paymentTags,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        date: getDateYearMonthDay({
          date: initialValues?.dateTime,
          canFallback: true,
        }),
        reference: initialValues.reference || undefined,
        type: initialValues.type || 'cash',
        tagId:
          initialValues?.tags && initialValues?.tags[0]
            ? initialValues?.tags?.[0]?.id
            : undefined,
        amount: initialValues.amount || undefined,
      }}
      validationSchema={Yup.object().shape({
        date: Yup.string().required(t('required')),
        reference: Yup.string(),
        type: Yup.string().required(t('required')),
        tagId: Yup.number(),
        amount: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field isDate label={t('date')} name='date' />
            </Col>

            <Col md={6}>
              <Field label={t('reference')} name='reference' />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('paymentType')}
                name='type'
                options={[
                  {
                    label: t('cash'),
                    value: 'cash',
                  },
                  {
                    label: t('eft'),
                    value: 'eft',
                  },
                  {
                    label: t('card'),
                    value: 'card',
                  },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                isLoading={isFetchingPaymentTags}
                label={t('paymentProcessors')}
                name='tagId'
                options={paymentTags?.map?.((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col md={6}>
              <Field label={t('amount')} name='amount' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
