import styled, { keyframes } from 'styled-components';
import { size, space } from 'styled-system';

const animation = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
`;

const StyledProcessingSpinner = styled.div`
  animation: ${animation} 1s infinite ease;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;

  ${({ borderColor, theme: { colors } }) =>
    `border-color: ${colors[borderColor]}`};
  ${({ borderColor, theme: { colors } }) =>
    `border-right-color: ${colors[borderColor]}55`};

  ${size};
  ${space};
`;

export default StyledProcessingSpinner;
