import { useMediaQuery } from 'octiv-hooks';
import React from 'react';

import { Box } from '../..';

const Grid = (props) => {
  const { xsDown, mdDown } = useMediaQuery();

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  return <Box px={padding} {...props} />;
};

export default Grid;
