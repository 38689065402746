import { Alert } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Tags from './tags';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations },
  } = useActiveUserTenant();

  return (
    <Setting title={t('locationTags')}>
      <Alert
        mb={4}
        subtitle={t('locationTagsDescription')}
        title={t('locationTagsDescriptionTitle')}
        variant='info'
      />

      {locations.map((location) => (
        <Tags key={location.id} location={location} />
      ))}
    </Setting>
  );
};
