import { Formik } from 'formik';
import { Box, Button, Field } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ exportOptions, isLoading, ...props }) => {
  const { t } = useTranslation();
  const { md } = useMediaQuery();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        painFormat: 'pain.008.001.02',
        isProcessAsBatch: true,
      }}
      validationSchema={Yup.object().shape({
        painFormat: Yup.string().required(t('required')),
        isProcessAsBatch: Yup.boolean(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box alignItems='center' isFlex={md} justifyContent='flex-end' mb={4}>
            <Field
              isHorizontal
              isRadio
              label={t('processAsBatch')}
              name='isProcessAsBatch'
              options={[
                {
                  label: t('yes'),
                  value: true,
                },
                {
                  label: t('no'),
                  value: false,
                },
              ]}
            />
            <Box
              minWidth={180}
              mx={md ? 4 : undefined}
              my={!md ? 4 : undefined}
            >
              <Field
                hasAlternateBackground
                isSelect
                label={t('format')}
                name='painFormat'
                options={[
                  {
                    label: 'PAIN.008.001.02',
                    value: 'pain.008.001.02',
                  },
                  {
                    label: 'PAIN.008.002.02',
                    value: 'pain.008.002.02',
                  },
                  {
                    label: 'PAIN.008.003.02',
                    value: 'pain.008.003.02',
                  },
                ]}
              />
            </Box>

            <Box>
              <Button isLoading={isLoading} text={t('export')} type='submit' />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};
