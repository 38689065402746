import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Bookings from './bookings';
import LocationTags from './locationTags';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('scheduling'),
        breadcrumbs: [t('settings')],
      }}
    >
      <Bookings />

      <LocationTags />
    </Container>
  );
};
