import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useExerciseCategoriesFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['exerciseCategories', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/exercise-categories`,
        params: {
          filter: {
            isActive: filter.isActive,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useExerciseCategoriesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/exercise-categories`,
        data: { name: data.name, isBenchmark: data.isBenchmark },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exerciseCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExerciseCategoriesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['exerciseCategories', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/exercise-categories/${id}`,
      }),
    options
  );
};

export const useExerciseCategoriesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/exercise-categories/${id}`,
        data: {
          name: data.name,
          isBenchmark: data.isBenchmark,
          isActive: data.isActive,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exerciseCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExerciseCategoriesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/exercise-categories/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exerciseCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExerciseCategoriesUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/exercise-categories/activate-deactivate/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exerciseCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExerciseCategoriesUpdateBulkUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/exercise-categories/bulk-update`,
        data: {
          exerciseCategories: data.exerciseCategories,
          action: data.action,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exerciseCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};
