import { AppBar, Box, Grid, ProcessingBar } from 'octiv-components';
import { ActiveScreenContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useMediaQuery } from 'octiv-hooks';
import { isDevelopment } from 'octiv-utilities/Constants';
import React, { useContext, useEffect } from 'react';

const Container = ({ appBarProps, children, isLoading, ...props }) => {
  const { xsDown } = useMediaQuery();
  const { isVisibleNavigation } = useContext(ActiveScreenContext);
  const { activeUserTenantId } = useActiveUserTenant();
  const { isSuperAdmin, isDiscoveryAdmin } = useSignedInUser();

  // NOTE:
  // PowerUser improvement: Just on DEV for now to show the page name in the tab title.
  // Helps with browser history navigation when right-clicking on back and forward arrows.
  useEffect(() => {
    const seoTitle = `${appBarProps?.breadcrumbs?.slice().pop()} > ${
      appBarProps?.title
    }`;

    if (appBarProps?.title && appBarProps?.breadcrumbs && isDevelopment) {
      if (document) {
        document.title = `Octiv | ${seoTitle}`;
      }
    }
  }, [appBarProps?.title, appBarProps?.breadcrumbs]);

  return (
    <>
      {isLoading && <ProcessingBar />}

      <Box
        pb={6}
        pl={
          isSuperAdmin ||
          isDiscoveryAdmin ||
          (!!activeUserTenantId && !xsDown && isVisibleNavigation)
            ? 14
            : undefined
        }
        pt={isVisibleNavigation ? 14 : undefined}
        width='100%'
        {...props}
      >
        <Grid>
          {appBarProps && <AppBar {...appBarProps} />}

          {children}
        </Grid>
      </Box>
    </>
  );
};

export default Container;
