import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useRemoteConfigFind = (input, options) => {
  return useQuery(
    ['remoteConfig', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/remote-config/v2`,
      }),
    options
  );
};

export const useRemoteConfigUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/remote-config/v2`,
        data: {
          maintenanceTitle: data.maintenanceTitle,
          maintenanceBody: data.maintenanceBody,
          versionWebApp: data.versionWebApp,
          versionWebAppRequired: data.versionWebAppRequired,
          versionAppStoreIos: data.versionAppStoreIos,
          versionAppStoreIosRequired: data.versionAppStoreIosRequired,
          versionAppStoreAndroid: data.versionAppStoreAndroid,
          versionAppStoreAndroidRequired: data.versionAppStoreAndroidRequired,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['remoteConfig'] });

      options?.onSuccess?.(...res);
    },
  });
};
