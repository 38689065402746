import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ classes, isFetchingClasses, locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        date: Yup.string().required(t('required')),
        locationId: Yup.string().required(t('required')),
        classDateId: Yup.string(),
        status: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('date')}
                name='date'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingClasses}
                label={t('class')}
                name='classDateId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(classes?.map(({ id, name, startTime, endTime }) => ({
                    label: getEventString({ name, startTime, endTime }),
                    value: id,
                  })) || []),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='status'
                options={[
                  { label: t('booked'), value: '1' },
                  { label: t('noShow'), value: '5' },
                  { label: t('checkedIn'), value: '6' },
                  { label: t('allCancelled'), value: '2,3,4' },
                  { label: t('cancelledByMember'), value: '2' },
                  { label: t('cancelledByTrainer'), value: '4' },
                  { label: t('cancelledAfterThreshold'), value: '3' },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
