import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: undefined,
        email: undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            boxContainerProps={{ mt: 4, mb: 4 }}
            label={t('fullName')}
            name='name'
          />

          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('emailAddress')}
            name='email'
          />

          <Button isLoading={isLoading} text={t('book')} type='submit' />
        </form>
      )}
    </Formik>
  );
};
