import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { toCurrency } = useActiveUserTenant();

  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('item'),
        accessor: 'name',
      },
      {
        Header: t('quantity'),
        accessor: 'quantity',
      },
      {
        Header: t('costPrice'),
        accessor: 'costPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('sellingPrice'),
        accessor: 'sellingPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
