import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useMandatesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['mandates', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/mandates`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            status: filter.status,
            isDebitOrderMembersOnly: filter.isDebitOrderMembersOnly,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useMandatesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/mandates`,
        data: { tenantId: data.tenantId || tenantId, userId: data.userId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['mandates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useMandatesFindByIdUser = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['mandates', 'findByIdUser', input],
    () =>
      request({
        method: 'get',
        url: `/api/mandates/user/${id}`,
      }),
    options
  );
};

export const useMandatesFindByIdUserLatest = (input, options) => {
  const { id } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['mandates', 'findByIdUserLatest', input],
    () =>
      request({
        method: 'get',
        url: `/api/mandates/user/${id}/latest`,
        params: {
          tenantId,
        },
      }),
    options
  );
};

export const useMandatesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['mandates', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/mandates/${id}`,
      }),
    options
  );
};

export const useMandatesUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/mandates/${id}`,
        data: { status: data.status, tenantId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['mandates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useMandatesCreateSendOnboardingLink = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/mandates/send-onboarding-link`,
        data: {
          userIds: data.userIds,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['mandates'] });

      options?.onSuccess?.(...res);
    },
  });
};
