import { Table, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getFullName,
  getUserTypeString,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: t('type'),
        accessor: 'userTenant.typeId',
        Cell: ({ value }) => getUserTypeString({ id: value }),
      },
      {
        Header: t('dateJoined'),
        accessor: 'userTenant.activatedAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: {
              userTenant: { id, userId, statusId, typeId },
            },
          },
        }) => {
          const canUpdate =
            typeId === 2 ? loggedInUserTypeId === 2 : loggedInUserTypeId !== 3;

          return (
            <Tooltip
              stopPropagation
              options={[
                { label: t('viewEdit'), value: 'view' },
                ...(canUpdate && statusId !== 2
                  ? [
                      { label: t('activate'), value: 'activate' },
                      { label: t('delete'), value: 'delete' },
                    ]
                  : []),
                ...(canUpdate && statusId === 2
                  ? [{ label: t('deactivate'), value: 'deactivate' }]
                  : []),
              ]}
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  userId,
                  userTenantId: id,
                })
              }
            />
          );
        },
      },
    ],
    [onClickAction, loggedInUserTypeId, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
