import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useExercisesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, include } = input || {};

  return useQuery(
    ['exercises', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/exercises`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            type: filter.type,
            isBenchmark: filter.isBenchmark,
            isActive: filter.isActive,
            exerciseCategoryId: filter.exerciseCategoryId,
            search: filter.search,
          },
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useExercisesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['exercises', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/exercises/${id}`,
      }),
    options
  );
};

export const useExercisesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/exercises/${id}`,
        data: {
          name: data.name,
          description: data.description,
          resourceUrl: data.resourceUrl,
          measuringUnitId: data.measuringUnitId,
          rxMale: data.rxMale,
          rxFemale: data.rxFemale,
          exerciseCategoryId: data.exerciseCategoryId,
          isActive: data.isActive,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exercises'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExercisesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/exercises`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          description: data.description,
          resourceUrl: data.resourceUrl,
          measuringUnitId: data.measuringUnitId,
          rxMale: data.rxMale,
          rxFemale: data.rxFemale,
          exerciseCategoryId: data.exerciseCategoryId,
          isBenchmark: Number(data.isBenchmark),
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exercises'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useExercisesUpdateAssignCategories = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/exercises/assign-categories`,
        data: {
          benchmarkExerciseIds: data.benchmarkExerciseIds,
          exerciseCategoryId: data.exerciseCategoryId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['exercises'] });

      options?.onSuccess?.(...res);
    },
  });
};
