import { Box, ProcessingSpinner } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';

const QrCode = ({
  value,
  size,
  qrCodeProps,
  boxSpinnerContainerProps,
  processingSpinnerProps,
  ...props
}) => {
  const { xsDown } = useMediaQuery();
  const defaultSize = size || (xsDown ? 200 : 300);

  return (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      bg='white'
      justifyContent='center'
      size={`${defaultSize + 32}px`}
      {...props}
    >
      {value ? (
        <QRCodeSVG
          bgColor='transparent'
          size={defaultSize}
          value={value}
          {...qrCodeProps}
        />
      ) : (
        <ProcessingSpinner size={6} {...processingSpinnerProps} />
      )}
    </Box>
  );
};

export default QrCode;
