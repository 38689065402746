import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useOnHoldUsersFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['onHoldUsers', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/on-hold-users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
            locationId: filter.locationId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useOnHoldUsersCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/on-hold-users`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          startDate: data.startDate,
          releaseDate: data.releaseDate,
          proRataFee: data.proRataFee,
          note: data.note,
          isExtendPackageEndDate: data.isExtendPackageEndDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['onHoldUsers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useOnHoldUsersFindByIdUserHistory = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['onHoldUsers', 'findByIdUserHistory', input],
    () =>
      request({
        method: 'get',
        url: `/api/on-hold-users/user/${id}/history`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useOnHoldUsersUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/on-hold-users/${id}`,
        data: {
          startDate: data.startDate,
          releaseDate: data.releaseDate,
          proRataFee: data.proRataFee,
          note: data.note,
          isExtendPackageEndDate: data.isExtendPackageEndDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['onHoldUsers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useOnHoldUsersUpdateRelease = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/on-hold-users/${id}/release`,
        data: {
          proRataFee: data.proRataFee,
          isExtendPackageEndDate: data.isExtendPackageEndDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['onHoldUsers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useOnHoldUsersUpdateCancel = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/on-hold-users/${id}/cancel`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['onHoldUsers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};
