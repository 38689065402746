import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useInjuriesFind = (input, options) => {
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['injuries', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/injuries`,
        params: {
          filter: {
            tenantId: filter.tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            status: filter.status,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useInjuriesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/injuries`,
        data: {
          tenantId: data.tenantId,
          userId: data.userId,
          content: data.content,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['injuries'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useInjuriesFindById = (input, options) => {
  const { id, ...queryParams } = input || {};

  return useQuery(
    ['injuries', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/injuries/${id}`,
        params: {
          ...queryParams,
        },
      }),
    options
  );
};

export const useInjuriesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/injuries/${id}`,
        data: { content: data.content },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['injuries'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useInjuriesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/injuries/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['injuries'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useInjuriesUpdateHeal = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/injuries/${id}/healed`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['injuries'] });

      options?.onSuccess?.(...res);
    },
  });
};
