import { Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import { canSetMemberModal, getFullName } from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const BookingValue = ({
  bookingStatusId,
  userId,
  value,
  onClickBookingStatus,
}) =>
  value ? (
    <Text
      as='span'
      color='primary'
      onClick={() => onClickBookingStatus({ bookingStatusId, userId })}
    >
      {value}
    </Text>
  ) : (
    0
  );

export default ({ data, query, onClickBookingStatus, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                ? (e) => {
                    e.stopPropagation();
                    setMemberModal({ userTenantId: value?.userTenant?.id });
                  }
                : null
            }
          >
            {getFullName(value)}
          </Text>
        ),
      },
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: t('bookings'),
        id: 'booked',
        accessor: 'attendance.booked',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={1}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('checkedIn'),
        id: 'checkedIn',
        accessor: 'attendance.checkedIn',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={6}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('noShows'),
        id: 'noShow',
        accessor: 'attendance.noShow',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={5}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('cancellations'),
        id: 'cancelled',
        accessor: 'attendance.cancelled',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={2}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('lateCancellations'),
        id: 'cancelledAfterThreshold',
        accessor: 'attendance.cancelledAfterThreshold',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={3}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('cancelledByTrainer'),
        id: 'cancelledByTrainer',
        accessor: 'attendance.cancelledByTrainer',
        Cell: ({
          value,
          row: {
            original: { user },
          },
        }) => (
          <BookingValue
            bookingStatusId={4}
            userId={user.id}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('workoutsLogged'),
        accessor: 'workoutsLogged',
      },
    ],
    [hasAccess, setMemberModal, onClickBookingStatus, t]
  );

  const hiddenColumns = useMemo(
    () => (query.locationId ? ['location'] : []),
    [query.locationId]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
