import _ from 'lodash';
import { Container } from 'octiv-components';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import {
  useTenantSettingsFind,
  useTenantSettingsUpdate,
} from 'octiv-hooks/requests/TenantSettings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../components/Setting';
import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const { refetchSignedInUser } = useSignedInUser();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useTenantSettingsFind();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useTenantSettingsUpdate({
      onSuccess: () => {
        refetchSignedInUser();
        getSettingsRequest();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const handleSubmit = (values) => putSettingsRequest(values);

  return (
    <Container
      appBarProps={{
        title: 'Octiv',
        breadcrumbs: [t('settings')],
      }}
    >
      <Setting isLoading={isFetchingSettings && _.isEmpty(settings)}>
        <Form
          initialValues={settings || {}}
          isLoading={isUpdatingSettings}
          onSubmit={handleSubmit}
        />
      </Setting>
    </Container>
  );
};
