import { Table, Text, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  getUserPackageInfo,
  translateSessionsAvailableText,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        id: 'name',
        accessor: 'package',
        Cell: ({ value }) => value?.name || null,
      },
      {
        Header: t('start'),
        accessor: 'startDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('end'),
        accessor: 'endDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('sessionsAvailable'),
        accessor: 'sessionsAvailableText',
        Cell: ({ value }) => translateSessionsAvailableText({ text: value }),
      },
      {
        Header: t('status'),
        id: 'status',
        Cell: ({
          row: {
            original: { package: tenantPackage, isActive },
          },
        }) => (
          <>
            <Text as='span'>{isActive ? t('active') : t('inactive')}</Text>
            {tenantPackage.isActive ? (
              ''
            ) : (
              <Text as='span' color='danger'>
                {` (${t('mainPackageDeactivated')})`}
              </Text>
            )}
          </>
        ),
      },
      {
        Header: t('price'),
        id: 'price',
        Cell: ({ row: { original } }) =>
          toCurrency({ value: original?.package?.price }),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original,
            original: { id, isActive, package: tenantPackage },
          },
        }) => {
          const { canTopUp } = getUserPackageInfo({
            userPackage: original,
          });

          return (
            <Tooltip
              options={[
                ...(tenantPackage.isActive
                  ? [{ label: t('edit'), value: 'edit' }]
                  : []),
                ...(isActive
                  ? [
                      ...(canTopUp
                        ? [{ label: t('topUp'), value: 'topUp' }]
                        : []),
                      { label: t('deactivate'), value: 'deactivate' },
                    ]
                  : [{ label: t('delete'), value: 'delete' }]),
              ]}
              placement='left'
              onClick={({ value }) => onClickAction({ action: value, id })}
            />
          );
        },
      },
    ],
    [onClickAction, toCurrency, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
