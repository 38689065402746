import moment from 'moment';
import { Box, Container, SendComm } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging } from 'octiv-hooks';
import { useReportsFindAttendanceMemberNonAttendance } from 'octiv-hooks/requests/Reports';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindAttendanceMemberNonAttendance({
      filter: query,
      paging,
    });

  return (
    <Container
      appBarProps={{
        title: t('memberNonAttendance'),
        breadcrumbs: [t('reports'), t('attendance')],
        children: (
          <SendComm
            members={!isFetchingReport && (report.data || [])}
            ml='auto'
          />
        ),
      }}
      isLoading={isFetchingReport}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions}
        onSubmit={setQuery}
      />

      <Box height={4} />

      <TableReport
        data={report?.data || []}
        isLoading={isFetchingReport}
        paging={report?.paging}
        setPaging={setPaging}
      />
    </Container>
  );
};
