import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ initialValues = {}, isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        id: initialValues.id || undefined,
        name: initialValues.name || undefined,
        isBenchmark: true,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        name: Yup.string().required(t('required')),
        isBenchmark: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field label={t('name')} name='name' />

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
