import { Box, Button, Container, ProcessingSuspense } from 'octiv-components';
import {
  useTermsOfUseCreate,
  useTermsOfUseFind,
} from 'octiv-hooks/requests/TermsOfUse';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from '../privacyPolicy/Form';

export default () => {
  const { t } = useTranslation();

  const {
    data: termsOfUse,
    isFetching: isFetchingTermsOfUse,
    refetch: getTermsOfUseRequest,
  } = useTermsOfUseFind();

  const {
    isLoading: isUpdatingPrivacyPolicy,
    mutate: postPrivacyPolicyRequest,
  } = useTermsOfUseCreate({ onSuccess: getTermsOfUseRequest });

  const handleSubmit = (values) => postPrivacyPolicyRequest(values);

  return (
    <Container
      appBarProps={{
        title: t('termsOfUse'),
        breadcrumbs: [t('settings')],
        children: (
          <Box ml='auto'>
            <Button
              text={t('view')}
              onClick={() => window.open('/terms-of-use')}
            />
          </Box>
        ),
      }}
    >
      {isFetchingTermsOfUse ? (
        <ProcessingSuspense />
      ) : (
        <Form
          initialValues={termsOfUse}
          isLoading={isUpdatingPrivacyPolicy}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};
