import moment from 'moment';
import { Box, Container, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useReportsFindTrainersClassSessionDetails,
  useReportsFindTrainersSessions,
} from 'octiv-hooks/requests/Reports';
import { getDateYearMonthDay, getFullName } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();
  const [paging, setPaging] = usePaging();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindTrainersSessions(
      { filter: query, paging },
      {
        enabled: true, // !!query?.locationId,
      }
    );

  const {
    data: reportDetailClasses,
    isFetching: isFetchingReportDetailClasses,
    paging: pagingReportDetailClasses,
  } = useReportsFindTrainersClassSessionDetails(
    {
      filter: {
        ...query,
        userId: toggle.data?.id,
        isSession: false,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: toggle.type === 'classes' && toggle.data !== undefined,
      usePaging: true,
    }
  );

  const {
    data: reportDetailSessions,
    isFetching: isFetchingReportDetailSessions,
    paging: pagingReportDetailSessions,
  } = useReportsFindTrainersClassSessionDetails(
    {
      filter: {
        userId: toggle.data?.id,
        isSession: true,
        ...query,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: toggle.type === 'sessions' && toggle.data !== undefined,
      usePaging: true,
    }
  );

  const onClickAction = ({ action, user }) => {
    switch (action) {
      case 'classes':
        setToggle({
          type: 'classes',
          data: user,
        });
        break;

      case 'sessions':
        setToggle({
          type: 'sessions',
          data: user,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${
            toggle.type === 'classes' ? t('classes') : t('sessions')
          } ${t('reportDetail')}: ${getFullName(toggle.data)}`}
          onClose={resetToggle}
        >
          {toggle.type === 'classes' ? (
            <TableDetail
              data={reportDetailClasses?.data || []}
              isLoading={isFetchingReportDetailClasses}
              paging={pagingReportDetailClasses}
            />
          ) : (
            <TableDetail
              data={reportDetailSessions?.data || []}
              isLoading={isFetchingReportDetailSessions}
              paging={pagingReportDetailSessions}
            />
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('trainer'),
          breadcrumbs: [t('reports'), t('attendance')],
        }}
        isLoading={isFetchingReport || isFetchingReportDetailClasses}
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptionsAll}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TableReport
          data={isFetchingReport ? [] : report?.data}
          isLoading={isFetchingReport}
          paging={report?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
