import { Images } from 'octiv-assets';
import AppConfig from 'octiv-config/App';
import { useRemoteConfigFind } from 'octiv-hooks/requests/RemoteConfig';
import { isDevelopment } from 'octiv-utilities/Constants';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

const RENDER_TYPES = {
  application: 'application',
  maintenanceMode: 'maintenanceMode',
  updateAvailable: 'updateAvailable',
  updateRequired: 'updateRequired',
};

const RemoteConfig = ({ children }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const isScreenRemoteConfigSettings =
    window.location.pathname === '/settings/remote-config';

  const [renderType, setRenderType] = useState(
    isScreenRemoteConfigSettings || isDevelopment
      ? RENDER_TYPES.application
      : undefined
  );

  const remoteConfigFind = useRemoteConfigFind(undefined, {
    enabled: true, // !isDevelopment,
    staleTime: 10000,
    retryDelay: 10000,
    refetchInterval: 300000,
    onSuccess: (response) => {
      let newRenderType;

      if (response.maintenanceTitle || response.maintenanceBody) {
        newRenderType = RENDER_TYPES.maintenanceMode;
      } else if (response.versionWebAppRequired > AppConfig.version) {
        newRenderType = RENDER_TYPES.updateRequired;
      } else if (response.versionWebApp > AppConfig.version) {
        newRenderType = RENDER_TYPES.updateAvailable;
      } else {
        newRenderType = RENDER_TYPES.application;
      }

      setRenderType(newRenderType);
    },
    onError: () => setRenderType(RENDER_TYPES.maintenanceMode),
  });

  const renderBlockingInterface = ({ title, body }) => (
    <div
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
        backgroundColor: theme.colors.grey7,
      }}
    >
      <img alt={t('octivLogo')} src={Images.logomark} width={80} />

      <h1
        style={{
          fontFamily: "'Ubuntu', 'Roboto', sans-serif",
          fontSize: '1.63rem',
          textAlign: 'center',
          color: theme.colors.font,
          margin: 0,
          marginTop: 24,
        }}
      >
        {title}
      </h1>

      <p
        style={{
          lineHeight: '1.25rem',
          textAlign: 'center',
          color: theme.colors.grey1,
          maxWidth: 650,
          margin: 0,
          marginTop: 8,
        }}
      >
        {body}
      </p>
    </div>
  );

  const renderNonBlockingInterface = ({ title } = {}) => (
    <>
      {title && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 50000,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: theme.colors.warning,
          }}
        >
          <p
            style={{
              fontSize: '0.81rem',
              textAlign: 'center',
              color: theme.colors.button,
              margin: 0,
            }}
          >
            {title}
          </p>
        </div>
      )}

      {children}
    </>
  );

  if (renderType === RENDER_TYPES.maintenanceMode) {
    return renderBlockingInterface({
      title: remoteConfigFind.data?.maintenanceTitle || t('maintenanceTitle'),
      body: remoteConfigFind.data?.maintenanceBody || t('maintenanceBody'),
    });
  }

  if (renderType === RENDER_TYPES.updateRequired) {
    return renderBlockingInterface({
      title: t('updateRequiredTitle'),
      body: t('updateRequiredBody'),
    });
  }

  if (renderType === RENDER_TYPES.updateAvailable) {
    return renderNonBlockingInterface({
      title: t('updateAvailableTitle'),
    });
  }

  if (renderType === RENDER_TYPES.application) {
    return renderNonBlockingInterface();
  }

  return (
    <div
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.grey7,
      }}
    >
      <img alt={t('octivLogo')} src={Images.logoLight} width={250} />
    </div>
  );
};

export default RemoteConfig;
