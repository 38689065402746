import { useState } from 'react';

export default (
  initialState = {
    isVisible: false,
    type: undefined,
    data: undefined,
  }
) => {
  const [toggle, setToggleState] = useState(initialState);

  const setToggle = ({
    isVisible = undefined,
    type = undefined,
    data = undefined,
  } = {}) => {
    let computedType = type;
    if (type === 'click') computedType = undefined;

    setToggleState((prev) => ({
      isVisible:
        isVisible !== undefined
          ? isVisible
          : computedType || data
          ? true
          : !prev.isVisible,
      type: computedType,
      data,
    }));
  };

  const resetToggle = () => setToggleState(initialState);

  return [toggle, setToggle, resetToggle];
};
