import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, to, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        message: undefined,
      }}
      validationSchema={Yup.object().shape({
        message: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            as='textarea'
            boxContainerProps={{ mb: 4 }}
            label={to ? `${t('to')}: ${to}` : t('message')}
            name='message'
          />

          <Button isLoading={isLoading} mt={4} text={t('send')} type='submit' />
        </form>
      )}
    </Formik>
  );
};
