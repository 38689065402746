import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, dirty, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        signUpRedirectUrl: initialValues.signUpRedirectUrl || undefined,
        leadRedirectUrl: initialValues.leadRedirectUrl || undefined,
        leadRequestDemoRedirectUrl:
          initialValues.leadRequestDemoRedirectUrl || undefined,
      }}
      validationSchema={Yup.object().shape({
        signUpRedirectUrl: Yup.string().required(t('required')),
        leadRedirectUrl: Yup.string().required(t('required')),
        leadRequestDemoRedirectUrl: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field label={t('signUpRedirectUrl')} name='signUpRedirectUrl' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('leadsRedirectUrl')} name='leadRedirectUrl' />
            </Col>

            <Col lg={4} md={6}>
              <Field
                label={t('leadsDemoRedirectUrl')}
                name='leadRequestDemoRedirectUrl'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('update')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
