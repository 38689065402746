import { Alert, Container } from 'octiv-components';
import {
  useCrmFindBroadcastMessages,
  useCrmUpdateBroadcastMessages,
} from 'octiv-hooks/requests/Crm';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const noticeboardFind = useCrmFindBroadcastMessages();
  const noticeBoardUpdate = useCrmUpdateBroadcastMessages();

  const hasActiveNotice = noticeboardFind?.data?.isActive === 1;

  return (
    <Container
      appBarProps={{
        title: t('noticeboard'),
        breadcrumbs: [t('comms')],
      }}
      isLoading={noticeboardFind.isFetching || noticeBoardUpdate.isLoading}
    >
      {hasActiveNotice && (
        <Alert
          mb={4}
          subtitle={t('noticeWillDisplayUntil')}
          title={t('pleaseNote')}
          variant='warning'
        />
      )}

      <Form
        initialValues={noticeboardFind?.data || {}}
        isLoading={noticeBoardUpdate.isLoading}
        onSubmit={(values) =>
          noticeBoardUpdate.mutate({
            id: noticeboardFind?.data?.id,
            ...values,
          })
        }
      />
    </Container>
  );
};
