import moment from 'moment';
import { Alert, Container, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import { useClassBookingsFindByIdPackage } from 'octiv-hooks/requests/ClassBookings';
import { useFinancesFindUserInvoices } from 'octiv-hooks/requests/Finances';
import { useReportsFindFinancePackagesSalesAndRevenue } from 'octiv-hooks/requests/Reports';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetailClassBookings from './TableDetailClassBookings';
import TableDetailInvoices from './TableDetailInvoices';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const [
    toggleClassBookings,
    setToggleClassBookings,
    resetToggleClassBookings,
  ] = useToggle();
  const [toggleInvoices, setToggleInvoices, resetToggleInvoices] = useToggle();

  const [paging, setPaging] = usePaging();
  const [pagingBookings, setPagingBookings] = usePaging();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    paymentType: undefined,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindFinancePackagesSalesAndRevenue({ filter: query });

  const {
    data: reportDetailClassBookings,
    isFetching: isFetchingReportDetailClassBookings,
  } = useClassBookingsFindByIdPackage(
    {
      filter: {
        ...query,
        packageId: toggleClassBookings.data?.package?.id,
      },
      paging: pagingBookings,
      include: 'classDate',
    },
    {
      enabled:
        toggleClassBookings.data?.package?.id !== undefined &&
        !!toggleClassBookings?.data?.package?.id,
    }
  );

  const {
    data: reportDetailInvoices,
    isFetching: isFetchingReportDetailInvoices,
  } = useFinancesFindUserInvoices(
    {
      filter: {
        ...query,
        datePeriod: 'custom',
        type: 'athleteInvoices',
        status: 'paid',
        packageId: toggleInvoices.data?.package?.id,
      },
      paging,
      sort: '-due_on',
      include: 'user_tenant,payments',
    },
    {
      enabled: toggleInvoices.data !== undefined,
    }
  );

  return (
    <>
      {toggleClassBookings.isVisible && (
        <Modal
          title={`${t('classBookingsForPackage')}: ${
            toggleClassBookings.data.package.name
          }`}
          onClose={resetToggleClassBookings}
        >
          <TableDetailClassBookings
            data={
              isFetchingReportDetailClassBookings
                ? []
                : reportDetailClassBookings?.data
            }
            isLoading={isFetchingReportDetailClassBookings}
            paging={reportDetailClassBookings?.paging}
            setPaging={setPagingBookings}
          />
        </Modal>
      )}

      {toggleInvoices.isVisible && (
        <Modal
          title={`${t('invoicesForPackage')}: ${
            toggleInvoices.data.package.name
          }`}
          onClose={resetToggleInvoices}
        >
          <TableDetailInvoices
            data={
              isFetchingReportDetailInvoices ? [] : reportDetailInvoices?.data
            }
            isLoading={isFetchingReportDetailInvoices}
            paging={reportDetailInvoices?.paging}
            setPaging={setPaging}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('packageRevenue'),
          breadcrumbs: [t('reports'), t('finance')],
        }}
        isLoading={isFetchingReport}
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptionsAll}
          onSubmit={setQuery}
        />

        <Alert my={4} subtitle={t('packageRevenueInfo')} variant='info' />

        <TableReport
          data={report || []}
          isLoading={isFetchingReport}
          query={query}
          onClickClassesAttended={(data) => {
            setToggleClassBookings({ data });
          }}
          onClickRow={({ original }) => {
            setToggleInvoices({ data: original });
          }}
        />
      </Container>
    </>
  );
};
