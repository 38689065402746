import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('endToEndId'),
        accessor: 'endToEndId',
      },
      {
        Header: t('message'),
        accessor: 'message',
      },
      {
        Header: t('invoiceCode'),
        accessor: 'invoice.code',
      },
      {
        accessor: 'invoice.id',
        Cell: ({ value }) =>
          value && (
            <Text
              as='span'
              color='primary'
              onClick={() =>
                window.open(`/accounts/invoices?invoiceId=${value}`)
              }
            >
              {t('viewInvoice')}
            </Text>
          ),
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
