import { InlineUser, Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getMandateStatusDetails,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setInvoices, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => <InlineUser user={original.user} />,
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({
          row: {
            original: {
              mandate: { status: mandateStatus },
              sentAt,
            },
          },
        }) => {
          const { isActive, isInactive, status } = getMandateStatusDetails({
            status: mandateStatus,
            sentAt,
          });

          return (
            <Text
              as='span'
              color={isActive ? 'success' : isInactive ? 'danger' : 'grey1'}
            >
              {status}
            </Text>
          );
        },
      },
      {
        Header: t('reference'),
        accessor: 'mandate.reference',
      },
      {
        Header: t('sentOn'),
        id: 'sentAt',
        Cell: ({
          row: {
            original: {
              mandate: { status, sentAt },
            },
          },
        }) =>
          !status || status === 'created'
            ? getDateReadableDayMonthYear({ date: sentAt })
            : null,
      },
      {
        Header: t('signedOn'),
        id: 'signedAt',
        Cell: ({
          row: {
            original: {
              mandate: { status, signedAt },
            },
          },
        }) =>
          status === 'active' || status === 'cancelled'
            ? getDateReadableDayMonthYear({ date: signedAt })
            : null,
      },
      {
        Header: t('cancelledOn'),
        id: 'cancelledAt',
        Cell: ({
          row: {
            original: {
              mandate: { status, cancelledAt },
            },
          },
        }) =>
          status === 'cancelled'
            ? getDateReadableDayMonthYear({ date: cancelledAt })
            : null,
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { mandate, user },
          },
        }) => {
          if (mandate.status === 'active') {
            return (
              <Text
                as='span'
                color='grey1'
                onClick={() =>
                  onClickAction({
                    id: mandate.id,
                    action: 'cancel',
                  })
                }
              >
                {t('cancel')}
              </Text>
            );
          }

          if (
            !mandate.status ||
            mandate.status === 'created' ||
            mandate.status === 'cancelled'
          ) {
            return (
              <Text
                as='span'
                color='primary'
                mr={8}
                onClick={() =>
                  onClickAction({
                    id: user?.id,
                    action: 'sendLink',
                  })
                }
              >
                {t('sendOnboardingLink')}
              </Text>
            );
          }

          return null;
        },
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
