import { Box, ProcessingSuspense, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React from 'react';

export default ({ data, ...props }) => {
  const { toCurrency } = useActiveUserTenant();

  return (
    <Box isFlex flexWrap='wrap' justifyContent='space-around' {...props}>
      {data.map(
        ({
          title,
          value,
          isCurrency = false,
          isLoading,
          comparison: comparisonProp = {},
          ...statProps
        }) => {
          const {
            values = [],
            shouldFirstValueBeGreater = true,
            from,
          } = comparisonProp;

          const hasComparison = values?.length === 2;
          let comparison = {};

          if (hasComparison) {
            const value1 = Number(values[0]);
            const value2 = Number(values[1]);
            const isEqual = value1 === value2;
            const isGreater = value1 > value2;
            const isSuccess =
              (shouldFirstValueBeGreater && isGreater) ||
              (!shouldFirstValueBeGreater && !isGreater);

            const percentage = (
              ((value1 - value2) / (value2 || 1)) *
              100
            ).toFixed();

            comparison = {
              value1,
              value2,
              isEqual,
              isGreater,
              isSuccess,
              percentage,
            };
          }

          return (
            <Box key={title} minWidth={40} mx={2} my={1} {...statProps}>
              {isLoading ? (
                <ProcessingSuspense
                  items={[{ width: 10, height: 9 }, { width: 20 }]}
                  mx='auto'
                />
              ) : (
                <>
                  <Text
                    isBold
                    color='primary'
                    textAlign='center'
                    variant='display'
                  >
                    {value === null || value === undefined
                      ? '-'
                      : isCurrency
                      ? toCurrency({ value })
                      : value}
                  </Text>

                  <Text my={1} textAlign='center'>
                    {title}
                  </Text>

                  {hasComparison && (
                    <Text
                      color={
                        comparison.isEqual
                          ? 'grey2'
                          : comparison.isSuccess
                          ? 'success'
                          : 'danger'
                      }
                      textAlign='center'
                      variant='caption'
                    >
                      {`${
                        comparison.isEqual
                          ? ''
                          : comparison.isGreater
                          ? '↑'
                          : '↓'
                      }${comparison.percentage.replace('-', '')}% from ${
                        isCurrency
                          ? toCurrency({ value: from || comparison.value2 })
                          : from || comparison.value2
                      }`}
                    </Text>
                  )}
                </>
              )}
            </Box>
          );
        }
      )}
    </Box>
  );
};
