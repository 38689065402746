import { Table, Text, Tooltip } from 'octiv-components';
import { paymentGateways } from 'octiv-utilities/Constants';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, paymentGatewayOptions, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: t('paymentGateway'),
        accessor: 'location.paymentGatewayId',
        Cell: ({ value }) =>
          paymentGatewayOptions?.find((gateway) => gateway.value === value)
            ?.label,
      },
      {
        Header: t('total'),
        accessor: 'total',
      },
      {
        Header: t('lastModified'),
        accessor: 'updatedAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('processedOn'),
        accessor: 'processedAt',
        Cell: ({ value }) => (
          <Text as='span' color='success'>
            {getDateReadableDayMonthYear({ date: value })}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({ row }) => {
          const { id, isProcessed, location } = row?.original || {};
          return (
            <Tooltip
              options={[
                { label: t('resubmit'), value: 'resubmit' },
                ...(location?.paymentGatewayId === paymentGateways?.THREE_PEAKS
                  ? [
                      {
                        label: t('submissionHistory'),
                        value: 'threePeaksSubmissionHistory',
                      },
                      ...(isProcessed
                        ? [
                            {
                              label: t('reconcileBatch'),
                              value: 'threePeaksReconcileBatch',
                            },
                            {
                              label: t('submissionDebits'),
                              value: 'threePeaksSubmissionDebits',
                            },
                            {
                              label: t('submissionInformation'),
                              value: 'threePeaksSubmissionInformation',
                            },
                            {
                              label: t('validationInformation'),
                              value: 'threePeaksValidationInformation',
                            },
                            {
                              label: t('recallSubmission'),
                              value: 'threePeaksRecallSubmission',
                            },
                          ]
                        : []),
                    ]
                  : location?.paymentGatewayId === paymentGateways?.GO_CARDLESS
                  ? [
                      {
                        label: t('attemptReconcile'),
                        value: 'goCardlessReconcile',
                      },
                      ...(isProcessed
                        ? [
                            {
                              label: t('cancelBatch'),
                              value: 'goCardlessCancel',
                            },
                          ]
                        : []),
                    ]
                  : []),
              ]}
              placement='left'
              onClick={({ value }) =>
                onClickAction({ action: value, id, locationId: location.id })
              }
            />
          );
        },
      },
    ],
    [onClickAction, paymentGatewayOptions, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
