import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { useSettingsFindSignUp } from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  const { data: settingsSignUp, isFetching: isFetchingDebitDays } =
    useSettingsFindSignUp(
      {},
      {
        enabled: !initialValues?.debitDay?.id,
      }
    );

  return (
    <Formik
      {...props}
      initialValues={{
        debitDayId: initialValues?.debitDay?.id || undefined,
        accountHolderName: initialValues?.accountHolderName || undefined,
        iban: initialValues?.iban || undefined,
        bic: initialValues?.bic || undefined,
        address: initialValues?.address || undefined,
      }}
      validationSchema={Yup.object().shape({
        debitDayId: Yup.string().required(t('required')),
        accountHolderName: Yup.string().required(t('required')),
        iban: Yup.string().required(t('required')),
        bic: Yup.string().required(t('required')),
        address: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            {!initialValues?.debitDay?.id && (
              <Col lg={4} md={6}>
                <Field
                  isSelect
                  isLoading={isFetchingDebitDays}
                  label={t('debitDate')}
                  name='debitDayId'
                  options={[
                    { label: '1st', value: 1 },
                    { label: '5th', value: 6 },
                    { label: '15th', value: 2 },
                    { label: '25th', value: 3 },
                    { label: '27th', value: 5 },
                    { label: '28th', value: 7 },
                    { label: t('lastDayMonth'), value: 4 },
                  ].filter((item) =>
                    settingsSignUp?.signUpDebitDayOptions?.includes(
                      item.value.toString()
                    )
                  )}
                />
              </Col>
            )}

            <Col lg={4} md={6}>
              <Field label={t('accountHolder')} name='accountHolderName' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('internationalBankAccountNumber')} name='iban' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('bankIdentifierCode')} name='bic' />
            </Col>

            <Col>
              <Field as='textarea' label={t('address')} name='address' />
            </Col>
          </Row>

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
