import { Box, Icon, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ isSuccess, isDeclined, isInvoiceNotFound }) => {
  const { t } = useTranslation();

  let iconProps = {};
  let title;
  let body;

  if (isSuccess) {
    iconProps = {
      name: 'check_circle',
      color: 'success',
    };
    title = t('success');
    body = t('youMayCloseThisWindow');
  } else if (isDeclined) {
    iconProps = {
      name: 'error',
      color: 'danger',
    };
    title = t('declined');
    body = t('pleaseCheckYourEmail');
  } else if (isInvoiceNotFound) {
    iconProps = {
      name: 'warning',
      color: 'warning',
    };
    title = t('invoiceNotFound');
    body = t('pleaseContactYourStudio');
  }

  return (
    <Box
      isFlex
      alignItems='center'
      bg='grey7'
      bottom={0}
      flexDirection='column'
      justifyContent='center'
      left={0}
      p={6}
      position='absolute'
      right={0}
      top={0}
      zIndex={50001}
    >
      <Icon {...iconProps} mb={4} scale='large' />

      <Text mb={2} textAlign='center' variant='heading'>
        {title}
      </Text>

      <Text color='grey1' textAlign='center'>
        {body}
      </Text>
    </Box>
  );
};
