import { uniqBy } from 'lodash';
import { useQuery } from 'octiv-hooks';
import { paymentGateways } from 'octiv-utilities/Constants';
import { toCurrency, urlSearchParam } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSignedInUser } from './SignedInUser';
import { ThemeContext } from './Theme';

const sortByKey = (key) => (a, b) =>
  a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;

export const ActiveUserTenantContextInitialState = {
  userId: undefined,

  isSettingUserTenant: true,
  isSettingLocation: false,

  isMember: false,
  isStaff: false,
  isOwner: false,
  isAdmin: false,
  isLocationAdmin: false,
  isLocationCheckIn: false,
  isTrainer: false,

  id: undefined,
  activeUserTenantId: undefined,
  currencyCode: undefined,
  bio: undefined,
  programmeId: undefined,
  landingScreen: undefined,
  contract: undefined,
  statusId: undefined,
  typeId: undefined,

  canAccessAllLocations: undefined,
  selectedLocation: {},

  accessPrivileges: [],

  tenantId: undefined,
  tenant: {
    isGoCardless: undefined,
    isPaystack: undefined,
    isSepa: undefined,

    hasHealthProviders: false,

    id: undefined,
    name: undefined,
    regionId: undefined,
    locations: [],
    locationOptions: [],
    locationOptionsAll: [],
    healthProviders: [],
    logo: undefined,
    hiddenFeatures: [],
  },

  setActiveUserTenant: () => {},
  setSelectedLocation: () => {},
  toCurrency: () => {},
};

export const ActiveUserTenantContext = React.createContext(
  ActiveUserTenantContextInitialState
);

export const useActiveUserTenant = () => {
  const activeUserTenant = React.useContext(ActiveUserTenantContext);

  return activeUserTenant;
};

export const ActiveUserTenantContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const switchUserTenantId = useQuery('switchUserTenantId');

  const { setTheme } = React.useContext(ThemeContext);
  const { isSignedIn, isSuperAdmin, isDiscoveryAdmin, userTenants } =
    useSignedInUser();

  const [activeUserTenantContext, setActiveUserTenantContext] = React.useState(
    ActiveUserTenantContextInitialState
  );

  const setActiveUserTenant = async ({
    tenant,
    typeId,
    locationAccessPrivileges = [],
    defaultLocationId,
    ...userTenant
  } = {}) => {
    setActiveUserTenantContext((prev) => ({
      ...prev,
      isSettingUserTenant: true,
    }));

    await localStorage.setItem('activeUserTenantId', userTenant.id);

    const isMember = typeId === 4 || typeId === 9;
    const isStaff = !isMember;
    const isOwner = typeId === 2;
    const isAdmin = typeId === 5;
    const isLocationAdmin = typeId === 6;
    const isLocationCheckIn = typeId === 8;
    const isTrainer = typeId === 3;

    const activeLocations = tenant?.locations?.filter((item) => item.isActive);

    let tenantLocations = [];
    let canAccessAllLocations = true;

    if (activeLocations) {
      const locationAccessPrivilegeIds = locationAccessPrivileges
        .filter((item) => item.revoked === 0)
        .map((item) => item.locationId);

      tenantLocations =
        isOwner || isMember
          ? activeLocations
          : isLocationAdmin || isLocationCheckIn
          ? activeLocations.filter(
              (item) => item.id === userTenant.locations?.[0]?.id
            )
          : activeLocations.filter((item) =>
              locationAccessPrivilegeIds.includes(item.id)
            );

      if (tenantLocations.length !== activeLocations.length) {
        canAccessAllLocations = false;
      }
    }

    const locationOptions = tenantLocations
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
      // NOTE: For the client "Body20", these locations are not sorted, and need to be
      .sort(sortByKey('label'));

    const defaultLocation = defaultLocationId
      ? tenantLocations.find((item) => item.id === defaultLocationId)
      : isMember && userTenant.locations?.[0]?.id
      ? tenantLocations.find((item) => item.id === userTenant.locations[0].id)
      : undefined;

    const selectedLocation = defaultLocation || tenantLocations[0];

    const allHealthProviders = [];
    tenantLocations.map((item) =>
      allHealthProviders.push(
        ...(item.healthProviders ? item.healthProviders : [])
      )
    );
    const healthProviders = uniqBy(allHealthProviders, 'id');

    const accessPrivileges = [];
    if (userTenant.accessPrivileges) {
      userTenant.accessPrivileges.map(({ revoked, accessPrivilegeId }) =>
        accessPrivileges.push(...(revoked ? [] : [accessPrivilegeId]))
      );
    }

    setActiveUserTenantContext((prev) => ({
      ...prev,
      userId: userTenant.userId,

      isSettingUserTenant: false,
      isSettingLocation: false,

      isMember,
      isStaff,
      isOwner,
      isAdmin,
      isLocationAdmin,
      isLocationCheckIn,
      isTrainer,

      id: userTenant.id,
      activeUserTenantId: userTenant.id,
      currencyCode: tenant?.memberBillingCurrency?.code,
      bio: userTenant.bio,
      programmeId: userTenant.programmeId,
      landingScreen: userTenant.landingScreen,
      contract: userTenant.contract,
      statusId: userTenant.statusId,
      typeId,

      canAccessAllLocations,
      selectedLocation,

      accessPrivileges,

      tenantId: tenant?.id,
      tenant: {
        isGoCardless: !!tenantLocations.find(
          (item) => item.paymentGatewayId === paymentGateways.GO_CARDLESS
        ),
        isSepa: !!tenantLocations.find(
          (item) => item.paymentGatewayId === paymentGateways.SEPA
        ),
        isPaystack: !!tenantLocations.find(
          (item) =>
            !!item.locationPaymentGateways.find(
              (locationPaymentGateway) =>
                locationPaymentGateway.paymentGatewayId ===
                paymentGateways.PAYSTACK
            )
        ),

        hasHealthProviders: !!healthProviders[0],

        id: tenant?.id,
        name: tenant?.name,
        regionId: tenant?.region?.id,
        // NOTE: For the client "Body20", these locations are not sorted, and need to be
        locations: tenantLocations?.sort(sortByKey('name')),
        locationOptions,
        locationOptionsAll: canAccessAllLocations
          ? [{ label: t('all'), value: undefined }, ...locationOptions]
          : locationOptions,
        healthProviders,
        logo: tenant?.settings?.logo || undefined,
        hiddenFeatures: tenant?.settings?.hiddenFeatures || [],
      },

      setSelectedLocation: (newLocation) => {
        setActiveUserTenantContext((prev2) => ({
          ...prev2,
          isSettingLocation: true,
          selectedLocation: prev2.tenant?.locations?.find(
            (item) => item.id === newLocation.id
          ),
        }));

        setTimeout(
          () =>
            setActiveUserTenantContext((prev2) => ({
              ...prev2,
              isSettingLocation: false,
            })),
          300
        );
      },
      toCurrency: (props) =>
        toCurrency({ code: tenant?.memberBillingCurrency.code, ...props }),
    }));

    setTheme(tenant?.settings?.theme || undefined);
  };

  React.useEffect(() => {
    if (!!activeUserTenantContext.tenantId && userTenants?.[0]) {
      const updatedUserTenant = userTenants.find(
        (item) => item.tenantId === activeUserTenantContext.tenantId
      );

      setActiveUserTenant(updatedUserTenant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTenants]);

  React.useEffect(() => {
    if (isSignedIn && !isSuperAdmin && !isDiscoveryAdmin) {
      (async () => {
        let activeUserTenantId;

        if (switchUserTenantId) {
          activeUserTenantId = switchUserTenantId;

          urlSearchParam({ param: 'switchUserTenantId', isDelete: true });
        } else {
          activeUserTenantId = await localStorage.getItem('activeUserTenantId');
        }

        const activeUserTenant = activeUserTenantId
          ? userTenants.find((item) => item.id === Number(activeUserTenantId))
          : null;

        setActiveUserTenant(activeUserTenant || userTenants[0]);
      })();
    } else {
      setActiveUserTenantContext({
        ...ActiveUserTenantContextInitialState,
        isSettingUserTenant: !isSuperAdmin && !isDiscoveryAdmin,
        setActiveUserTenant,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  return (
    <ActiveUserTenantContext.Provider value={activeUserTenantContext}>
      {children}
    </ActiveUserTenantContext.Provider>
  );
};
