import styled, { keyframes } from 'styled-components';
import { space } from 'styled-system';

const animateIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const animateOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const StyledContainer = styled.div`
  z-index: 500;
  display: ${({ isSidebar }) => (isSidebar ? undefined : 'flex')};
  align-items: ${({ isSidebar }) => (isSidebar ? undefined : 'center')};
  justify-content: ${({ isSidebar }) => (isSidebar ? undefined : 'center')};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;

  animation: ${({ canAnimateIn }) => (canAnimateIn ? animateIn : animateOut)}
    300ms ease 1 forwards;

  ${space};
`;

export const StyledBackground = styled.div`
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: ${({ isSidebar }) => (isSidebar ? 0.5 : 0.8)};
  background-color: #000000;
  cursor: pointer;
`;
