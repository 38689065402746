import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import StyledText from './Styled';

const TextWithoutLink = ({ variant = 'body', color = 'font', ...props }) => {
  const themeContext = useContext(ThemeContext);
  const { fonts } = themeContext;

  return <StyledText {...fonts[variant]} color={color} {...props} />;
};

const Text = ({ to, ...props }) => {
  const linkProps = to
    ? {
        to,
        as: Link,
      }
    : {};

  return <TextWithoutLink {...linkProps} {...props} />;
};

export default Text;
