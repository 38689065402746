import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingLocationTags,
  isFetchingUsersTrainers,
  locationOptions,
  locationTags,
  usersTrainers,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        isSession: Yup.bool(),
        locationId: Yup.string().required(t('required')),
        tagIds: Yup.array(),
        instructorId: Yup.string(),
        supportingInstructorId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xl={3}>
              <Field
                canSubmitOnChange
                isHorizontal
                isRadio
                label={t('eventTypes')}
                name='isSession'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('classes'), value: 0 },
                  { label: t('sessions'), value: 1 },
                ]}
              />
            </Col>

            <Col md={6} xl={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isMulti
                isSelect
                isLoading={isFetchingLocationTags}
                label={t('locationTags')}
                name='tagIds'
                options={locationTags?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingUsersTrainers}
                label={t('trainer')}
                name='instructorId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(usersTrainers?.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col md={6} xl={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingUsersTrainers}
                label={t('supportingTrainer')}
                name='supportingInstructorId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(usersTrainers?.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
