import { useQuery } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useAccessPrivilegesFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['accessPrivileges', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-privileges`,
        params: {
          filter: { typeId: filter.typeId, userTypeId: filter.userTypeId },
          ...paging,
        },
      }),
    options
  );
};
