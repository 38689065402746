import { Box, Icon, Table } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import {
  getDateReadableDayMonthYear,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MemberTableActions from '../shared/MemberTableActions';

export default ({ data, setSelected, onClickAction, query, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const renderIcon = ({ value }) =>
    value !== undefined ? (
      <Icon
        boxContainerProps={{
          m: 'auto',
        }}
        color={value ? 'success' : 'danger'}
        name={value ? 'check' : 'close'}
      />
    ) : null;

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'user.name',
        sortBy: 'name',
        Cell: ({
          row: {
            original: { isHighRisk, user },
          },
        }) => (
          <Box isFlex alignItems='center'>
            {isHighRisk ? <Icon color='warning' mr={2} name='warning' /> : null}

            {user.name}
          </Box>
        ),
      },
      {
        Header: t('surname'),
        accessor: 'user.surname',
        sortBy: 'surname',
      },
      {
        Header: t('packages'),
        accessor: 'userPackages',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value
            ? value
                .map(
                  (item) =>
                    `${item.package.name} (${item.sessionsAvailableText})`
                )
                .join(', ')
            : '',
      },
      {
        Header: t('contractSent'),
        accessor: 'userContract.sentAt',
        Cell: ({ value }) => (value ? renderIcon({ value }) : null),
      },
      {
        Header: t('contractAccepted'),
        accessor: 'userContract.acceptedAt',
        Cell: ({ value }) => (value ? renderIcon({ value }) : null),
      },
      {
        Header: t('waiverSent'),
        id: 'waiverSent',
        accessor: 'waiver',
        Cell: ({ value }) =>
          value?.status === 'sent' ? renderIcon({ value: true }) : null,
      },
      {
        Header: t('waiverSigned'),
        id: 'waiverSigned',
        accessor: 'waiver',
        Cell: ({ value }) =>
          value?.status === 'sent' || value?.status === 'signed'
            ? renderIcon({ value: value?.status === 'signed' })
            : null,
      },
      {
        Header: t('contractStart'),
        accessor: 'userContract.startingAt',
        sortBy: 'contractStartingOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('contractEnd'),
        accessor: 'userContract.endingAt',
        sortBy: 'contractEndingOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('lastModified'),
        accessor: 'updatedAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({ row }) => (
          <MemberTableActions
            useContract
            useWaiver
            row={row}
            onClickAction={onClickAction}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(!hasAccess.featureMembersActions ? ['actions'] : []),
      ...(query.statusId !== 4 ? ['updatedAt'] : []),
    ],
    [hasAccess.featureMembersActions, query.statusId]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'user.name',
        desc: false,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      sortBy={sortBy}
      onSelect={hasAccess.featureMembersActions ? setRowSelected : undefined}
      onSelectAll={
        hasAccess.featureMembersActions ? setAllRowsSelected : undefined
      }
    />
  );
};
