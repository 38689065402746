import { useState } from 'react';

export default (initialState = { page: 1, perPage: 25 }) => {
  const [paging, setPagingState] = useState(initialState);

  const setPaging = (args) => {
    const { page, perPage } = args || {};

    setPagingState((prev) => ({
      page: page || prev.page,
      perPage: perPage || prev.perPage,
    }));
  };

  const resetPaging = () => setPaging(initialState);

  return [paging, setPaging, resetPaging];
};
