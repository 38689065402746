import { Box, Button, Text } from 'octiv-components';
import Step from 'octiv-components/step/index';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ onSubmit, onBack, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Step
        boxContainerProps={{ mb: 4 }}
        mr={'1rem'}
        title={t('updateAccounts')}
        value='4'
      />
      <Text>{t('mergeAccountsCompleted')}</Text>
      <br />
      <Text>{t('mergeAccountsYouWillBeLoggedOut')}</Text>
      <br />
      <Text>{t('mergeAccountsForgotPassword')}</Text>
      <br />
      <Box isFlex flexDirection='row'>
        <Button hasBorder mr={'1rem'} text={t('back')} onClick={onBack} />
        <Button isLoading={isLoading} onClick={onSubmit}>
          <Text>{t('submit')}</Text>
        </Button>
      </Box>
    </Box>
  );
};
