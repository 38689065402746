import styled from 'styled-components';
import { border, color } from 'styled-system';

export const StyledTable = styled.div`
  border-color: ${({ theme }) => theme.colors.grey6};
  border-radius: ${({ theme }) => theme.sizes[2]};
  color: ${({ theme }) => theme.colors.font};
  overflow-x: scroll;
  width: 100%;

  ${border};
  ${color};

  table {
    border-collapse: collapse;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;

    span,
    a {
      font-size: 0.8rem;
    }

    th {
      position: relative;
      font-weight: 500;
      height: ${({ theme, xsDown }) => theme.sizes[xsDown ? 12 : 14]};
      padding: ${({ hasPadding, theme: { sizes } }) =>
        hasPadding ? `0 ${sizes[2]}` : 0};
      text-align: left;
      text-overflow: ellipsis;
      border-bottom: ${({ theme }) => theme.borders[2]};
      border-color: ${({ theme, hasAlternateBackground }) =>
        hasAlternateBackground ? theme.colors.grey4 : theme.colors.grey5};

      .sorted {
        position: absolute;
        margin-left: ${({ theme }) => `-${theme.sizes[5]}`};
        margin-top: ${({ theme }) => `-${theme.sizes[1]}`};
      }

      :first-child {
        padding-left: ${({ hasPadding, mediaQueryPadding, theme: { sizes } }) =>
          hasPadding ? sizes[mediaQueryPadding] : 0};
      }

      :last-child {
        padding-right: ${({
          hasPadding,
          mediaQueryPadding,
          theme: { sizes },
        }) => (hasPadding ? sizes[mediaQueryPadding] : 0)};
      }
    }

    tbody {
      .highlighted {
        color: ${({ theme, highlightColor }) => theme.colors[highlightColor]};
      }

      td {
        padding: ${({ hasPadding, theme: { sizes } }) =>
          hasPadding ? sizes[2] : 0};
        height: ${({ theme }) => theme.sizes[13]};

        :first-child {
          padding-left: ${({
            hasPadding,
            mediaQueryPadding,
            theme: { sizes },
          }) => (hasPadding ? sizes[mediaQueryPadding] : 0)};
        }

        :last-child {
          padding-right: ${({
            hasPadding,
            mediaQueryPadding,
            theme: { sizes },
          }) => (hasPadding ? sizes[mediaQueryPadding] : 0)};
        }
      }

      tr {
        border-top: ${({ theme }) => theme.borders[1]};
        border-color: ${({ theme, hasAlternateBackground }) =>
          hasAlternateBackground ? theme.colors.grey4 : theme.colors.grey5};

        :first-child {
          border-top: none;
        }
      }
    }

    ${({ hasHoverState }) =>
      hasHoverState &&
      `tbody {
        tr:hover {
          background-color: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }`};
  }
`;
