import { Box, Card, Chart, Col, Row, Stats } from 'octiv-components';
import {
  useReportsFindLocationMetrics,
  useReportsFindUserMetrics,
} from 'octiv-hooks/requests/Reports';
import { getDateYear, getMonths } from 'octiv-utilities/Functions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ query }) => {
  const { t } = useTranslation();

  const [overviewMetricsMonthly, setSchedulingMemberMetricsMonthly] = useState(
    []
  );

  const [overviewMetricsPrevious, setOverviewMetricsPrevious] = useState([]);

  const { data: userMetrics, isFetching: isFetchingUserMetrics } =
    useReportsFindUserMetrics({
      filter: query,
    });

  const { data: overviewMetrics, isFetching: isFetchingOverviewMetrics } =
    useReportsFindLocationMetrics({
      filter: {
        ...query,
        isMonthlyBreakdown: 0,
      },
    });

  const { isFetching: isFetchingOverviewMetricsPrevious } =
    useReportsFindLocationMetrics(
      {
        filter: {
          ...query,
          year: query.year - 1,
          isMonthlyBreakdown: 0,
        },
      },
      {
        enabled: query.year > 2016,
        onSuccess: (response) => setOverviewMetricsPrevious(response),
      }
    );

  const { isFetching: isFetchingOverviewMetricsMonthly } =
    useReportsFindLocationMetrics(
      {
        filter: {
          ...query,
          isMonthlyBreakdown: 1,
        },
      },
      {
        onSuccess: (response) => {
          const data = {
            tenantsCount: getMonths(),
            locationsCount: getMonths(),
          };

          Object.keys(response).forEach((key1) => {
            Object.keys(response[key1]).forEach((key2, index2) => {
              data[key1][index2].Count = response[key1][key2];
            });
          });

          setSchedulingMemberMetricsMonthly(data);
        },
      }
    );

  useEffect(() => {
    setOverviewMetricsPrevious([]);
  }, [query.year]);

  return (
    <>
      <Card
        mt={4}
        title={`${getDateYear({ canFallback: true })} User Overview`}
      >
        <Stats
          data={[
            {
              title: t('users'),
              value: userMetrics?.usersCount,
              isLoading: isFetchingUserMetrics,
            },
            {
              title: t('staff'),
              value: userMetrics?.staffCount,
              isLoading: isFetchingUserMetrics,
            },
            {
              title: t('members'),
              value: userMetrics?.membersCount,
              isLoading: isFetchingUserMetrics,
            },
          ]}
        />
      </Card>

      <Card mt={4} title={`${query.year} Overview`}>
        <Stats
          data={[
            {
              title: t('tenants'),
              value: overviewMetrics?.tenantsCount,
              isLoading: isFetchingOverviewMetrics,
              comparison: {
                values: !isFetchingOverviewMetricsPrevious && [
                  overviewMetrics?.tenantsCount,
                  overviewMetricsPrevious?.tenantsCount,
                ],
              },
            },
            {
              title: t('locations'),
              value: overviewMetrics?.locationsCount,
              isLoading: isFetchingOverviewMetrics,
              comparison: {
                values: !isFetchingOverviewMetricsPrevious && [
                  overviewMetrics?.locationsCount,
                  overviewMetricsPrevious?.locationsCount,
                ],
              },
            },
          ]}
        />

        <Box height={4} />

        <Row>
          <Col md={6}>
            <Chart
              isLine
              isShort
              data={overviewMetricsMonthly?.tenantsCount}
              isLoading={isFetchingOverviewMetricsMonthly}
              isVisibleLegend={false}
              title='Monthly Facilities Count'
            />
          </Col>

          <Col md={6}>
            <Chart
              isLine
              isShort
              data={overviewMetricsMonthly?.locationsCount}
              isLoading={isFetchingOverviewMetricsMonthly}
              isVisibleLegend={false}
              title='Monthly Locations Count'
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
