import { useMediaQuery } from 'octiv-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../box/index';
import Icon from '../icon/index';
import ProcessingSpinner from '../processing/spinner/index';
import Text from '../text/index';
import Tooltip from '../tooltip/index';

export default ({ paging, setPaging, isLoading, isPaginationAllHidden }) => {
  const { t } = useTranslation();
  const { xsDown, mdDown } = useMediaQuery();
  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  return (
    <Box isFlex alignItems='center' height={10} px={padding}>
      {isLoading ? null : (
        <>
          <Text lineHeight={1} variant='caption'>
            {t('pagingResults', {
              offsetStart: paging?.currentPageOffsetStart,
              offsetEnd: paging?.currentPageOffsetEnd,
              numberOfResults: paging?.numberOfResults,
            })}
          </Text>

          <Box ml='auto'>
            {!xsDown && (
              <Text lineHeight={1} mr={1} variant='caption'>
                {`${t('perPage')}:`}
              </Text>
            )}
          </Box>

          <Tooltip
            minWidth={20}
            options={[
              { label: '10', value: 10 },
              { label: '25', value: 25 },
              { label: '50', value: 50 },
              { label: '75', value: 75 },
              { label: '100', value: 100 },
              ...(isPaginationAllHidden
                ? []
                : [{ label: t('all'), value: -1 }]),
            ]}
            placement='bottom'
            pointerEvents={isLoading ? 'none' : 'auto'}
            onClick={
              setPaging
                ? ({ value }) => setPaging({ page: 1, perPage: value })
                : undefined
            }
          >
            <Box isFlex alignItems='center' pl={2}>
              <Text lineHeight={1} variant='caption'>
                {paging?.perPage === -1 ? t('all') : paging?.perPage}
              </Text>

              <Icon color='grey1' name='arrow_drop_down' />
            </Box>
          </Tooltip>

          <Text lineHeight={1} ml={padding} mr={2} variant='caption'>
            {`${xsDown ? '' : `${t('page')} `}${paging?.currentPage} / ${
              paging?.numberOfPages
            }`}
          </Text>

          <Box
            isDisabled={!setPaging || !paging?.previousPage}
            onClick={
              setPaging && paging?.previousPage
                ? () => setPaging({ page: paging?.previousPage })
                : undefined
            }
          >
            <Icon name='chevron_left' />
          </Box>

          <Box
            isDisabled={!setPaging || !paging?.nextPage}
            onClick={
              setPaging && paging?.nextPage
                ? () =>
                    setPaging({
                      page: paging?.nextPage,
                    })
                : undefined
            }
          >
            <Icon name='chevron_right' />
          </Box>
        </>
      )}

      <Box mb={1} mx='auto' size={4}>
        {isLoading && <ProcessingSpinner />}
      </Box>
    </Box>
  );
};
