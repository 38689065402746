import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useBodyWeightsFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['bodyWeights', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/body-weights`,
        params: {
          filter: {
            tenantId: filter.tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            recordedAt: filter.recordedAt,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useBodyWeightsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/body-weights`,
        data: {
          userId: data.userId,
          weight: data.weight,
          recordedAt: data.recordedAt,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyWeights'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useBodyWeightsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/body-weights/${id}`,
        data: { weight: data.weight, recordedAt: data.recordedAt },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyWeights'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useBodyWeightsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/body-weights/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bodyWeights'] });

      options?.onSuccess?.(...res);
    },
  });
};
