import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useWaiversFindCurrent = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['waivers', 'findCurrent', input],
    () =>
      request({
        method: 'get',
        url: `/api/waivers/current`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
            leadId: filter.leadId,
          },
        },
      }),
    options
  );
};

export const useWaiversCreateDownload = (options) => {
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'get',
        url: `/api/waivers/download`,
        params: {
          tenantId: data.tenantId || tenantId,
          userId: data.userId,
        },
      });
    },
  });
};

export const useWaiversFindDownload = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['waivers', 'findDownload', input],
    () =>
      request({
        method: 'get',
        url: `/api/waivers/download`,
        params: {
          tenantId,
          userId: filter.userId,
          filter: {
            userId: filter.userId,
            leadId: filter.leadId,
          },
        },
      }),
    options
  );
};

export const useWaiversFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['waivers', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/waivers/${id}`,
      }),
    options
  );
};

export const useWaiversUpdateBulkSend = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/waivers/bulk-send`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          isLatest: data.isLatest,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['waivers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWaiversUpdateSend = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/waivers/send`,
        data: {
          tenantId: data.tenantId,
          userId: data.userId,
          isLatest: data.isLatest,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['waivers'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWaiversUpdateSign = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/waivers/${id}/sign`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['waivers'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWaiversCreateUploadWaiver = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/waivers/upload-waiver`,
        data: { userId: data.userId, leadId: data.leadId, waiver: data.waiver },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['waivers'] });

      options?.onSuccess?.(...res);
    },
  });
};
