import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingStaff,
  staff,
  isLoading,
  initialValues = {},
  locationOptions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        dueDate:
          getDateYearMonthDay({ date: initialValues.dueDate }) || undefined,
        locationId: initialValues.location?.id || undefined,
        summary: initialValues.summary || undefined,
        description: initialValues.description || undefined,
        isCompleted:
          typeof initialValues.isCompleted === typeof true
            ? initialValues.isCompleted
            : false,
        assignedUserIds: initialValues.assignedUsers
          ? initialValues.assignedUsers.map((item) => item.id)
          : [],
      }}
      validationSchema={Yup.object().shape({
        dueDate: Yup.string(),
        locationId: Yup.string(),
        summary: Yup.string().required(t('required')),
        isCompleted: Yup.bool().required(t('required')),
        description: Yup.string(),
        assignedUserIds: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label={t('summary')} name='summary' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col md={4}>
              <Field
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={4}>
              <Field isDate label={t('dueDate')} name='dueDate' />
            </Col>

            <Col md={4}>
              <Field
                isSelect
                label={t('status')}
                name='isCompleted'
                options={[
                  {
                    label: t('complete'),
                    value: true,
                  },
                  {
                    label: t('incomplete'),
                    value: false,
                  },
                ]}
              />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingStaff}
                label={t('assignedStaff')}
                name='assignedUserIds'
                options={staff?.map((item) => ({
                  label: getFullName(item),
                  value: item.id,
                }))}
              />
            </Col>
          </Row>

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
