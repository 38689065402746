import moment from 'moment';
import { Alert, Box, Container, Modal, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useFinancesFindPaystackSettlements,
  useFinancesFindPaystackSettlementsTransactions,
} from 'octiv-hooks/requests/Finances';
import {
  getDateDayMonthYearExpanded,
  getDateYearMonthDay,
  toCurrency,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TableSettlements from './TableSettlements';

export default () => {
  const { t } = useTranslation();

  const activeUserTenant = useActiveUserTenant();

  const {
    selectedLocation,
    tenant: { locations },
  } = activeUserTenant;

  const [toggle, setToggle, resetToggle] = useToggle();

  const paystackLocationOptions = locations
    .filter((item) =>
      item.locationPaymentGateways
        ?.map((gateway) => gateway.paymentGatewayId)
        ?.includes(7)
    )
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const [settlementTransactions, setSettlementTransactions] = useState();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: settlements, isFetching: isFetchingSettlements } =
    useFinancesFindPaystackSettlements({ filter: query });

  const {
    isFetching: isFetchingSettlementTransactions,
    error: settlementTransactionsError,
    refetch: getSettlementTransactionsRequest,
  } = useFinancesFindPaystackSettlementsTransactions(
    {
      filter: {
        settlementId: toggle.data?.id,
        startDate: moment(toggle.data?.settlementDate)
          .subtract(3, 'week')
          .format('YYYY-MM-DD'),
        endDate: moment(toggle.data?.settlementDate)
          .add(1, 'week')
          .format('YYYY-MM-DD'),
      },
    },
    {
      enabled: !!toggle.data?.id,
      canShowError: false,
      onSuccess: setSettlementTransactions,
    }
  );

  const transactionTotals = settlementTransactions
    ? settlementTransactions.reduce(
        (acc, curr) => {
          acc.amount += curr.amount / 100;
          acc.fees += curr.fees / 100;
          return acc;
        },
        { amount: 0, fees: 0 }
      )
    : null;

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${t('settlementTransactions')}: ${getDateDayMonthYearExpanded(
            {
              date: toggle.data.settlementDate,
            }
          )}`}
          onClose={() => {
            resetToggle();
            setSettlementTransactions();
          }}
        >
          {!settlementTransactions && settlementTransactionsError && (
            <Alert
              buttonRightProps={{
                text: t('tryAgain'),
                onClick: getSettlementTransactionsRequest,
              }}
              mb={4}
              subtitle={t('paystackApiErrorSubtitle')}
              title={t('paystackApiErrorTitle')}
              variant='danger'
            />
          )}

          {transactionTotals && (
            <>
              <Text color='grey1' textAlign='right'>
                {`${t('amount')}: ${toCurrency({
                  code: settlementTransactions[0]?.currency,
                  value: transactionTotals.amount,
                })}`}
              </Text>

              <Text color='grey1' mt={1} textAlign='right'>
                {`${t('fee')}: ${toCurrency({
                  code: settlementTransactions[0]?.currency,
                  value: transactionTotals.fees,
                })}`}
              </Text>

              <Text mb={4} mt={1} textAlign='right' variant='heading'>
                {`${t('total')}: ${toCurrency({
                  code: settlementTransactions[0]?.currency,
                  value: transactionTotals.amount - transactionTotals.fees,
                })}`}
              </Text>
            </>
          )}

          <TableDetail
            data={
              isFetchingSettlementTransactions ? [] : settlementTransactions
            }
            isLoading={isFetchingSettlementTransactions}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: `Paystack ${t('settlements')}`,
          breadcrumbs: [t('accounts')],
        }}
        isLoading={isFetchingSettlements || isFetchingSettlementTransactions}
      >
        <FormQuery
          initialValues={query}
          locationOptions={paystackLocationOptions}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TableSettlements
          data={settlements}
          isLoading={isFetchingSettlements}
          onClickRow={({ original }) => setToggle({ data: original })}
        />
      </Container>
    </>
  );
};
