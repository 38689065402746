import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { appendFile } from 'octiv-utilities/Functions';
import { request } from 'octiv-utilities/Request';

export const useTenantSettingsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['tenantSettings', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/tenant-settings/${tenantId}`,
      }),
    options
  );
};

export const useTenantSettingsUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();

      appendFile(formData, 'logo', data.logo ?? null);

      if (data?.hiddenFeatures?.length > 0) {
        data?.hiddenFeatures?.forEach((feature, i) =>
          formData.append(`hiddenFeatures[${i}]`, feature || undefined)
        );
      } else {
        formData.append(`hiddenFeatures[]`, '');
      }

      if (data?.theme) {
        Object.keys(data?.theme)?.forEach?.((key) => {
          formData.append(
            `theme[${key}]`,
            data.theme?.[key] === undefined ? '' : data?.theme?.[key]
          );
        });
      }

      if (data?.localeLanguage) {
        formData?.append('localeLanguage', data.localeLanguage);
      }

      return request({
        method: 'post',
        url: `/api/tenant-settings/${tenantId}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenantSettings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTenantSettingsFindCoronavirus = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['tenantSettings', 'findCoronavirus', input],
    () =>
      request({
        method: 'get',
        url: `/api/tenant-settings/${input?.tenantId || tenantId}/coronavirus`,
      }),
    options
  );
};

export const useTenantSettingsUpdateCoronavirus = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/tenant-settings/${tenantId}/coronavirus`,
        data: {
          isEnabled: data.isEnabled,
          isEnabledQuestionnaireMemberApp: data.isEnabledQuestionnaireMemberApp,
          isDisplayVaccinationDetailsRoster:
            data.isDisplayVaccinationDetailsRoster,
          isDisplayVaccinationDetailsClass:
            data.isDisplayVaccinationDetailsClass,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenantSettings'] });

      options?.onSuccess?.(...res);
    },
  });
};
