import { Box, Chart, Stats } from 'octiv-components';
import { useReportsFindDashboardLeadsMetrics } from 'octiv-hooks/requests/Reports';
import { getMonths } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ query }) => {
  const { t } = useTranslation();

  const [leadMetricsMonthly, setLeadMetricsMonthly] = useState([]);

  const { data: leadMetrics, isFetching: isFetchingLeadMetrics } =
    useReportsFindDashboardLeadsMetrics(
      {
        filter: {
          ...query,
          isMonthlyBreakdown: 0,
        },
      },
      {
        enabled: true,
      }
    );

  // TODO: this must be calculated against previous year as a date, not just a year
  // const { isLoading: isFetchingLeadMetricsPrevious } = useFetch({
  //   canRequest: query.year > 2016,
  //   ...leadMetricsPrevious({
  //     ...query,
  //     year: query.year - 1,
  //     isMonthlyBreakdown: false,
  //   }),
  //   onSuccess: setLeadMetricsPrevious,
  // });

  const { isFetching: isFetchingLeadMetricsMonthly } =
    useReportsFindDashboardLeadsMetrics(
      {
        filter: {
          ...query,
          isMonthlyBreakdown: 1,
        },
      },
      {
        enabled: true,
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            if (typeof response[key1] === 'object') {
              Object.keys(response[key1]).forEach((key2, index2) => {
                data[index2][t(key1)] = response[key1][key2];
              });
            }
          });

          setLeadMetricsMonthly(data);
        },
      }
    );

  return (
    <Box hasRadiusBottom bg='grey6' p={4}>
      <Stats
        data={[
          {
            title: t('new'),
            value: leadMetrics?.new,
            isLoading: isFetchingLeadMetrics,
            // comparison: {
            //   values: !isFetchingLeadMetricsPrevious && [
            //     leadMetrics.new,
            //     leadMetricsPrevious.new,
            //   ],
            // },
          },
          {
            title: t('converted'),
            value: leadMetrics?.converted,
            isLoading: isFetchingLeadMetrics,
            // comparison: {
            //   values: !isFetchingLeadMetricsPrevious && [
            //     leadMetrics.converted,
            //     leadMetricsPrevious.converted,
            //   ],
            //   shouldFirstValueBeGreater: false,
            // },
          },
          {
            title: t('percentageConverted'),
            value:
              ((leadMetrics?.converted || 0) / (leadMetrics?.new || 1)) * 100
                ? `${(
                    ((leadMetrics?.converted || 0) / (leadMetrics?.new || 1)) *
                    100
                  ).toFixed()}%`
                : null,
            isLoading: isFetchingLeadMetrics,
          },
        ]}
        mb={6}
        mt={2}
      />

      <Chart
        isLine
        isShort
        data={
          leadMetricsMonthly?.map((a) => ({
            ...a,
            New: a?.New || 0,
            Converted: a?.Converted || 0,
          })) || []
        }
        isLoading={isFetchingLeadMetricsMonthly}
        title={t('monthlyBreakdown')}
      />
    </Box>
  );
};
