import _ from 'lodash';
import { Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getFullName,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setSelected, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();
  const { toCurrency } = useActiveUserTenant();

  // Set a row to selected
  const setRowSelected = ({ invoiceId }) => {
    setSelected(toggleRowSelected({ data, id: invoiceId, key: 'invoiceId' }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                ? () => setMemberModal({ userTenantId: value?.userTenant?.id })
                : null
            }
          >
            {getFullName(value)}
          </Text>
        ),
      },
      {
        Header: t('type'),
        accessor: 'memberPaymentType',
        Cell: ({ value }) => t(_.camelCase(value)),
      },
      {
        Header: t('days'),
        accessor: 'days',
      },
      {
        Header: t('amountInvoiced'),
        accessor: 'amountInvoiced',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('amountPaid'),
        accessor: 'amountPaid',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('amountOutstanding'),
        accessor: 'amountOutstanding',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { invoiceId, status },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('view'), value: 'view' },
              { label: t('recordPayment'), value: 'record' },
              ...(status !== 'paid'
                ? [
                    { label: t('edit'), value: 'edit' },
                    { label: t('delete'), value: 'delete' },
                  ]
                : []),
            ]}
            placement='left'
            onClick={({ value }) =>
              onClickAction({ action: value, id: invoiceId })
            }
          />
        ),
      },
    ],
    [toCurrency, setMemberModal, onClickAction, hasAccess, t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      onSelect={setRowSelected}
      onSelectAll={setAllRowsSelected}
    />
  );
};
