import { Formik } from 'formik';
import { Box, Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, onCancel, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        content: undefined,
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field label={t('description')} name='content' />

          <Box isFlex mt={4}>
            <Button isLoading={isLoading} text={t('submit')} type='submit' />

            <Button
              hasBorder
              color='grey2'
              ml={2}
              text={t('cancel')}
              onClick={onCancel}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};
