import moment from 'moment';
import { Images } from 'octiv-assets';
import {
  BookingInfo,
  Box,
  Icon,
  Image,
  Text,
  VaccinationIcon,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useMediaQuery } from 'octiv-hooks';
import { canSetMemberModal, getFullName } from 'octiv-utilities/Functions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const InlineUser = ({
  user,
  leadMember,
  nonMember,

  createdAt,
  createdBy,
  updatedAt,
  updatedBy,

  coronavirusVaccinationDetails,
  dateBirthdayComparison,
  isFirstBookingAtLocation = false,

  memberModalInitialView,

  isVisibleOverdue = true,
  isVisibleBirthday = true,
  isVisibleBookingInfo = true,
  isVisibleCoronavirusVaccinationDetails = false,
  isUserOverdueAtLocation = false,
  // isVisibleFirstBookingAtLocation = true,
  isVisibleUserImage = true,
  isDiscoveryLead = false,

  boxContainerProps,
  imageProps,
  textProps,
}) => {
  const { t } = useTranslation();

  const isLead = user?.userTenant?.typeId === 9;
  const isDiscovery =
    user?.userTenant?.source === 'discovery' || isDiscoveryLead;
  const { xsDown } = useMediaQuery();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  let isBirthday = false;

  if (user?.dateOfBirth) {
    const date = moment(dateBirthdayComparison);
    const birthday = moment(user.dateOfBirth);

    isBirthday =
      date.month() === birthday.month() && date.date() === birthday.date();
  }

  return (
    <Box isFlex alignItems='center' {...boxContainerProps}>
      {isVisibleBookingInfo && (
        <BookingInfo
          createdAt={createdAt}
          createdBy={createdBy}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
        />
      )}

      {isVisibleUserImage && (
        <Image
          hasRadius
          usePlaceholderUser
          mr={2}
          size={xsDown ? 7 : 9}
          {...imageProps}
          isLead={isLead || !!leadMember}
          isNonMember={!!nonMember}
          src={user?.image}
        />
      )}

      {isDiscovery ? (
        <Image
          alt={t('discoveryLogo')}
          backgroundSize='contain'
          height={4}
          mr={1}
          src={Images.vitalityLogomark}
          width={4}
        />
      ) : null}

      {/* NOTE: Not sure where the 'isVisibleFirstBookingAtLocation' is coming from ...  */}
      {/* {isVisibleFirstBookingAtLocation && isFirstBookingAtLocation && ( */}
      {isFirstBookingAtLocation && (
        <Icon
          color='primary'
          name='looks_one'
          pr={2}
          tooltip={t('firstBookingAtLocation')}
        />
      )}

      {isVisibleBirthday && isBirthday && (
        <Icon color='primary' name='cake' pr={2} tooltip={t('birthday')} />
      )}

      {isVisibleCoronavirusVaccinationDetails &&
        coronavirusVaccinationDetails && (
          <VaccinationIcon {...coronavirusVaccinationDetails} mr={2} />
        )}

      <Text
        as='span'
        color={
          isVisibleOverdue &&
          (user?.userTenant?.isOverdue || isUserOverdueAtLocation === true)
            ? 'danger'
            : undefined
        }
        {...textProps}
        onClick={
          canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
            ? () =>
                setMemberModal({
                  userTenantId: user?.userTenant?.id,
                  initialView: memberModalInitialView,
                })
            : null
        }
      >
        {getFullName({
          ...(user || leadMember || nonMember),
          isShortened: xsDown,
        })}
      </Text>
    </Box>
  );
};

export default InlineUser;
