import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import { initialiseUser } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  includePassword = true,
  initialValues = {},
  signUpRequiredFields,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  const signupSettings = {
    isMandatoryAddress: signUpRequiredFields?.includes?.('address'),
    isMandatoryPassportOrId: signUpRequiredFields?.includes?.('id_number'),
  };

  const {
    id,
    name,
    surname,
    genderId,
    dateOfBirth,
    email,
    mobile,
    emergencyContactName,
    emergencyContactMobile,
    address,
    idNumber,
  } = initialiseUser(initialValues);

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        id,
        name,
        surname,
        genderId,
        dateOfBirth,
        email,
        mobile,
        emergencyContactName,
        emergencyContactMobile,
        address,
        idNumber,
        ...(includePassword
          ? { newPassword: undefined, password: undefined }
          : {}),
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        genderId: Yup.string(),
        dateOfBirth: Yup.date()
          .min(moment('1900-01-01').toDate(), t('invalidDate'))
          .max(moment().toDate(), t('invalidDate')),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        mobile: Yup.string(),
        emergencyContactName: Yup.string(),
        emergencyContactMobile: Yup.string(),
        address: Yup.string().when('isMandatoryAddress', {
          is: () => signupSettings.isMandatoryAddress,
          then: Yup.string().required(t('required')),
        }),
        idNumber: Yup.string().when('isMandatoryPassportOrId', {
          is: () => signupSettings.isMandatoryPassportOrId,
          then: Yup.string().required(t('required')),
        }),
        ...(includePassword
          ? {
              newPassword: Yup.string(),
              password: Yup.string().when('newPassword', {
                is: (value) => value,
                then: Yup.string()
                  .oneOf(
                    [Yup.ref('newPassword'), null],
                    t('passwordsMustMatch')
                  )
                  .required(t('required')),
              }),
            }
          : {}),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4}>
              <Field label={t('firstName')} name='name' />
            </Col>

            <Col lg={3} md={4}>
              <Field label={t('surname')} name='surname' />
            </Col>

            <Col lg={3} md={4}>
              <Field
                isSelect
                label={t('gender')}
                name='genderId'
                options={genderOptions}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                isDate
                label={t('dateOfBirth')}
                maxDate={moment()}
                minDate={moment('1900-01-01')}
                name='dateOfBirth'
              />
            </Col>

            <Col lg={3} md={4}>
              <Field label={t('emailAddress')} name='email' />
            </Col>

            <Col lg={3} md={4}>
              <Field label={t('mobileNumber')} name='mobile' />
            </Col>

            <Col lg={3} md={4}>
              <Field
                label={t('emergencyContactName')}
                name='emergencyContactName'
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                label={t('emergencyContactMobile')}
                name='emergencyContactMobile'
              />
            </Col>

            {signupSettings?.isMandatoryAddress ? (
              <Col lg={3} md={4}>
                <Field label={t('address')} name='address' />
              </Col>
            ) : null}

            {signupSettings?.isMandatoryPassportOrId ? (
              <Col lg={3} md={4}>
                <Field label={t('identityNumber')} name='idNumber' />
              </Col>
            ) : null}

            {includePassword && (
              <>
                <Col lg={3} md={4}>
                  <Field
                    autocomplete='new-password'
                    label={t('newPassword')}
                    name='newPassword'
                    type='password'
                  />
                </Col>

                <Col lg={3} md={4}>
                  <Field
                    autocomplete='new-password'
                    label={t('confirmNewPassword')}
                    name='password'
                    type='password'
                  />
                </Col>
              </>
            )}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
