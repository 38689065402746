import { Modal, ProcessingSuspense } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useSettingsFindAccountsInvoiceDetails,
  useSettingsUpdateAccountsInvoiceDetails,
  useSettingsUpdateAccountsInvoiceDetailsImage,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import FormImage from './FormImage';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const { data: settings, isFetching: isFetchingSettings } =
    useSettingsFindAccountsInvoiceDetails(
      {
        filter: { locationId: toggle.data?.id },
      },
      {
        enabled: toggle.data?.id !== undefined,
      }
    );

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateAccountsInvoiceDetails({
      onSuccess: resetToggle,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const {
    isLoading: isUpdatingSettingsImage,
    mutate: putSettingsImageRequest,
  } = useSettingsUpdateAccountsInvoiceDetailsImage({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const handleSubmit = (values) =>
    putSettingsRequest({ locationId: toggle.data.id, ...values });

  const handleSubmitImage = (values) =>
    putSettingsImageRequest({ locationId: toggle.data.id, ...values });

  return (
    <Setting title={t('locationInvoiceDetails')}>
      {toggle.isVisible && (
        <Modal isSidebar title={t('invoiceDetails')} onClose={resetToggle}>
          {isFetchingSettings ? (
            <ProcessingSuspense />
          ) : (
            <>
              <Form
                initialValues={settings || {}}
                isLoading={isUpdatingSettings}
                onSubmit={handleSubmit}
              />
              <FormImage
                initialValues={settings || {}}
                isLoading={isUpdatingSettingsImage}
                onSubmit={handleSubmitImage}
              />
            </>
          )}
        </Modal>
      )}

      <Table
        hasAlternateBackground
        data={locations}
        onClickAction={setToggle}
      />
    </Setting>
  );
};
