const CONTAINER_ACCOUNTS = 'containerAccounts';
const CONTAINER_COMMS = 'containerComms';
const CONTAINER_CORONAVIRUS = 'containerCoronavirus';
const CONTAINER_LEADS = 'containerLeads';
const CONTAINER_MEMBERS = 'containerMembers';
const CONTAINER_STAFF = 'containerStaff';
const CONTAINER_REPORTS = 'containerReports';
const CONTAINER_SCHEDULING = 'containerScheduling';
const CONTAINER_SETTINGS = 'containerSettings';
const CONTAINER_STORE = 'containerStore';
const CONTAINER_TASKS = 'containerTasks';
const CONTAINER_WORKOUTS = 'containerWorkouts';

const SCREEN_ACCOUNTS_CREDIT_NOTES = 'screenAccountsCreditNotes';
const SCREEN_ACCOUNTS_DEBIT_BATCHES = 'screenAccountsDebitBatches';
const SCREEN_ACCOUNTS_INVOICES = 'screenAccountsInvoices';
const SCREEN_ACCOUNTS_PAYMENTS = 'screenAccountsPayments';
const SCREEN_COMMS_NOTICEBOARD = 'screenCommsNoticeboard';
const SCREEN_MEMBERS_BIRTHDAYS = 'screenMembersBirthdays';
const SCREEN_MEMBERS_CREATE = 'screenMembersCreate';
const SCREEN_MEMBERS_INJURIES = 'screenMembersInjuries';
const SCREEN_REPORTS_ATTENDANCE_TRAINER = 'screenReportsAttendanceTrainer';
const SCREEN_REPORTS_DASHBOARD = 'screenReportsDashboard';
const SCREEN_REPORTS_EXPORTS = 'screenReportsExports';
const SCREEN_REPORTS_FINANCE_DEBTORS = 'screenReportsFinanceDebtors';
const SCREEN_REPORTS_FINANCE_DISCOUNTS = 'screenReportsFinanceDiscounts';
const SCREEN_REPORTS_FINANCE_PACKAGES_REVENUE =
  'screenReportsFinancePackagesRevenue';
const SCREEN_REPORTS_FINANCE_PAYMENT_TYPES = 'screenReportsFinancePaymentTypes';
const SCREEN_REPORTS_FINANCE_STORE = 'screenReportsFinanceStore';
const SCREEN_REPORTS_PERFORMANCE_REPORT = 'screenReportsPerformanceReport';
const SCREEN_SETTINGS_ACCOUNTS = 'screenSettingsAccounts';
const SCREEN_SETTINGS_BOOKINGS = 'screenSettingsBookings';
const SCREEN_SETTINGS_COMMS = 'screenSettingsComms';
const SCREEN_SETTINGS_CONTRACTS_WAIVERS = 'screenSettingsContractsWaivers';
const SCREEN_SETTINGS_DROP_INS = 'screenSettingsDropIns';
const SCREEN_SETTINGS_OCTIV = 'screenSettingsOctiv';
const SCREEN_SETTINGS_PACKAGES = 'screenSettingsPackages';
const SCREEN_SETTINGS_SIGN_UP = 'screenSettingsSignUp';
const SCREEN_SETTINGS_WIDGETS = 'screenSettingsWidgets';
const SCREEN_SETTINGS_WORKOUTS = 'screenSettingsWorkouts';

const FEATURE_ACCOUNTS_INVOICE_ACTIONS = 'featureAccountsInvoiceActions';
const FEATURE_CORONAVIRUS_QUESTIONNAIRE_MEMBER_APP =
  'featureCoronavirusQuestionnaireMemberApp';
const FEATURE_CORONAVIRUS_VACCINATION_DETAILS_CLASS =
  'featureCoronavirusVaccinationDetailsClass';
const FEATURE_CORONAVIRUS_VACCINATION_DETAILS_ROSTER =
  'featureCoronavirusVaccinationDetailsRoster';
const FEATURE_MEMBERS_ACTIONS = 'featureMembersActions';
const FEATURE_MEMBERS_EXPORT = 'featureMembersExport';
const FEATURE_MEMBERS_MANAGE_BILLING = 'featureMembersManageBilling';
const FEATURE_MEMBERS_MANAGE_CONTRACTS = 'featureMembersManageContracts';
const FEATURE_MEMBERS_MANAGE_INJURIES = 'featureMembersManageInjuries';
const FEATURE_MEMBERS_MANAGE_PACKAGES = 'featureMembersManagePackages';
const FEATURE_SCHEDULING_ACTIONS = 'featureSchedulingActions';
const FEATURE_SCHEDULING_BULK_ACTIONS = 'featureSchedulingBulkActions';
const FEATURE_SCHEDULING_EXTRA_DETAILS_IN_CLASS =
  'featureSchedulingExtraDetailsInClass';
const FEATURE_SCHEDULING_OVERBOOK_MEMBER = 'featureSchedulingOverbookMember';
const FEATURE_SCHEDULING_SINGLE_ACTIONS = 'featureSchedulingSingleActions';
const FEATURE_WORKOUTS_ACTIONS = 'featureWorkoutsActions';

// Owner: 2
// Trainer: 3
// Admin: 5
// Location Admin: 6

const containerPrivileges = [
  {
    key: CONTAINER_ACCOUNTS,
    hiddenContainer: 'accounts',
    hiddenUserTypeIds: [3],
  },
  {
    key: CONTAINER_COMMS,
    hiddenContainer: 'comms',
    accessPrivilege: 4,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
  },
  {
    key: CONTAINER_CORONAVIRUS,
    settingCoronavirus: 'isEnabled',
  },
  {
    key: CONTAINER_LEADS,
    hiddenContainer: 'leads',
    accessPrivilege: 9,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
  },
  {
    key: CONTAINER_MEMBERS,
    accessPrivilege: 19,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
  },
  {
    key: CONTAINER_STAFF,
    accessPrivilege: 22,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
  },
  {
    key: CONTAINER_REPORTS,
    hiddenContainer: 'reports',
    accessPrivilege: 10,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
  },
  {
    key: CONTAINER_SCHEDULING,
    hiddenContainer: 'scheduling',
  },
  {
    key: CONTAINER_SETTINGS,
    accessPrivilege: 11,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
  },
  {
    key: CONTAINER_STORE,
    hiddenContainer: 'store',
    accessPrivilege: 1,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
  },
  {
    key: CONTAINER_TASKS,
    hiddenContainer: 'tasks',
  },
  {
    key: CONTAINER_WORKOUTS,
    hiddenContainer: 'workouts',
  },
];

// dependantKey can only be a container
const screenPrivileges = [
  {
    key: SCREEN_ACCOUNTS_CREDIT_NOTES,
    accessPrivilege: 14,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_ACCOUNTS,
  },
  {
    key: SCREEN_ACCOUNTS_DEBIT_BATCHES,
    accessPrivilege: 17,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_ACCOUNTS,
  },
  {
    key: SCREEN_ACCOUNTS_INVOICES,
    accessPrivilege: 13,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_ACCOUNTS,
  },
  {
    key: SCREEN_ACCOUNTS_PAYMENTS,
    accessPrivilege: 16,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_ACCOUNTS,
  },
  {
    key: SCREEN_COMMS_NOTICEBOARD,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_COMMS,
  },
  {
    key: SCREEN_MEMBERS_BIRTHDAYS,
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: SCREEN_MEMBERS_CREATE,
    accessPrivilege: 7,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: SCREEN_MEMBERS_INJURIES,
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: SCREEN_REPORTS_DASHBOARD,
    accessPrivilege: 12,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_EXPORTS,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_ATTENDANCE_TRAINER,
    hiddenUserTypeIds: [3, 5, 6],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_FINANCE_DEBTORS,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_FINANCE_DISCOUNTS,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_FINANCE_PACKAGES_REVENUE,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_FINANCE_PAYMENT_TYPES,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_FINANCE_STORE,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_REPORTS_PERFORMANCE_REPORT,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_REPORTS,
  },
  {
    key: SCREEN_SETTINGS_ACCOUNTS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_BOOKINGS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_COMMS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_CONTRACTS_WAIVERS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_DROP_INS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_OCTIV,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_PACKAGES,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_SIGN_UP,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_WIDGETS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
  {
    key: SCREEN_SETTINGS_WORKOUTS,
    hiddenUserTypeIds: [6],
    dependantKey: CONTAINER_SETTINGS,
  },
];

// dependantKey can be a container or screen
const featurePrivileges = [
  {
    key: FEATURE_ACCOUNTS_INVOICE_ACTIONS,
    accessPrivilege: 18,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: SCREEN_ACCOUNTS_INVOICES,
  },
  {
    key: FEATURE_CORONAVIRUS_QUESTIONNAIRE_MEMBER_APP,
    dependantKey: CONTAINER_CORONAVIRUS,
    settingCoronavirus: 'isEnabledQuestionnaireMemberApp',
  },
  {
    key: FEATURE_CORONAVIRUS_VACCINATION_DETAILS_CLASS,
    dependantKey: CONTAINER_CORONAVIRUS,
    settingCoronavirus: 'canDisplayVaccinationDetailsClass',
  },
  {
    key: FEATURE_CORONAVIRUS_VACCINATION_DETAILS_ROSTER,
    dependantKey: CONTAINER_CORONAVIRUS,
    settingCoronavirus: 'canDisplayVaccinationDetailsRoster',
  },
  {
    key: FEATURE_MEMBERS_ACTIONS,
    accessPrivilege: 8,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: FEATURE_MEMBERS_MANAGE_INJURIES,
    dependantKey: SCREEN_MEMBERS_INJURIES,
  },
  {
    key: FEATURE_MEMBERS_MANAGE_PACKAGES,
    accessPrivilege: 21,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: FEATURE_MEMBERS_MANAGE_BILLING,
    accessPrivilege: 21,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: FEATURE_MEMBERS_MANAGE_CONTRACTS,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: FEATURE_MEMBERS_EXPORT,
    hiddenUserTypeIds: [3],
    dependantKey: CONTAINER_MEMBERS,
  },
  {
    key: FEATURE_SCHEDULING_ACTIONS,
    accessPrivilege: 2,
    accessPrivilegeAppliesToUserTypeIds: [5, 6],
    dependantKey: CONTAINER_SCHEDULING,
  },
  {
    key: FEATURE_SCHEDULING_SINGLE_ACTIONS,
    accessPrivilege: 20,
    accessPrivilegeAppliesToUserTypeIds: [3],
    dependantKey: CONTAINER_SCHEDULING,
  },
  {
    key: FEATURE_SCHEDULING_BULK_ACTIONS,
    accessPrivilege: 3,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
    dependantKey: CONTAINER_SCHEDULING,
  },
  {
    key: FEATURE_SCHEDULING_EXTRA_DETAILS_IN_CLASS,
    accessPrivilege: 15,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
    dependantKey: CONTAINER_SCHEDULING,
  },
  {
    key: FEATURE_SCHEDULING_OVERBOOK_MEMBER,
    accessPrivilege: 6,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
    dependantKey: CONTAINER_SCHEDULING,
  },
  {
    key: FEATURE_WORKOUTS_ACTIONS,
    accessPrivilege: 5,
    accessPrivilegeAppliesToUserTypeIds: [3, 5, 6],
    dependantKey: CONTAINER_WORKOUTS,
  },
];

const getAccessContext = ({
  typeId,
  hiddenContainers = [],
  accessPrivileges = [],
  settingsCoronavirus = {},
}) => {
  const privileges = {};

  const getPrivileges = (privilegeSet) =>
    privilegeSet.forEach(
      ({
        key,
        dependantKey,
        hiddenContainer,
        hiddenUserTypeIds,
        accessPrivilegeAppliesToUserTypeIds,
        accessPrivilege,
        settingCoronavirus,
      }) => {
        if (dependantKey && !privileges[dependantKey]) {
          privileges[key] = false;
          return;
        }

        if (hiddenContainer && hiddenContainers.includes(hiddenContainer)) {
          privileges[key] = false;
          return;
        }

        if (
          settingCoronavirus &&
          settingsCoronavirus[settingCoronavirus] === 0
        ) {
          privileges[key] = false;
          return;
        }

        if (typeId !== 1 && typeId !== 2) {
          if (hiddenUserTypeIds && hiddenUserTypeIds.includes(typeId)) {
            privileges[key] = false;
            return;
          }

          if (
            accessPrivilege &&
            (!accessPrivilegeAppliesToUserTypeIds.includes(typeId) ||
              !accessPrivileges.includes(accessPrivilege))
          ) {
            privileges[key] = false;
            return;
          }
        }

        privileges[key] = true;
      }
    );

  getPrivileges(containerPrivileges);
  getPrivileges(screenPrivileges);
  getPrivileges(featurePrivileges);

  return privileges;
};

const accessInitialState = {};
[...containerPrivileges, ...screenPrivileges, ...featurePrivileges].forEach(
  (item) => {
    accessInitialState[item.key] = true;
  }
);

export { accessInitialState, getAccessContext };
