import { Box, Chip, Modal, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useTagsCreate,
  useTagsDelete,
  useTagsFind,
  useTagsUpdate,
} from 'octiv-hooks/requests/Tags';
import { tagsTypes } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default ({ publicToken }) => {
  const { t } = useTranslation();

  const { tenantId } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const tagsFind = useTagsFind({
    filter: { type: tagsTypes.PACKAGE, tenantId },
    paging: { perPage: -1 },
  });
  const tagsCreate = useTagsCreate();
  const tagsUpdate = useTagsUpdate();
  const tagsDelete = useTagsDelete();

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      tagsCreate.mutate({ ...values, type: tagsTypes.PACKAGE });
    } else {
      tagsUpdate.mutate({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: tagsFind.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'delete':
        tagsDelete.mutate({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(tagsFind.isFetching || tagsDelete.isLoading) && <ProcessingBar />}

      <Setting
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setToggle({ type: 'create' })}
            />
          </Box>
        )}
        title={t('packageCategories')}
      >
        {toggle.isVisible && (
          <Modal
            isSidebar
            title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
              'packageCategory'
            )}`}
            onClose={resetToggle}
          >
            <Form
              initialValues={toggle.data}
              isLoading={tagsUpdate.isLoading || tagsCreate.isLoading}
              onSubmit={onPutPostRequest}
            />
          </Modal>
        )}

        <Table
          hasAlternateBackground
          data={tagsFind.data?.data || []}
          isLoading={tagsFind.isFetching || !publicToken}
          publicToken={publicToken}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
