import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import { getFullName, getUserTypeString } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tables from '../tables';
import FormQuery from './FormQuery';

export default ({ selectedRecipients, onChangeRecipients, onClickProceed }) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState({
    userTenantStatusId: 2,
    search: undefined,
    isMinimalData: true,
  });

  const { data: users, isFetching: isLoadingUsers } = useUsersFindStaff({
    filter: query,
    paging: { perPage: -1 },
    sort: 'name',
  });

  return (
    <>
      <FormQuery initialValues={query} onSubmit={setQuery} />

      <Tables
        isLoadingUsers={isLoadingUsers}
        selectedRecipients={selectedRecipients}
        users={users?.data || []}
        usersColumns={[
          {
            Header: t('name'),
            accessor: 'name',
            Cell: ({ row: { original } }) => getFullName(original),
          },
          {
            Header: t('type'),
            accessor: 'type',
            Cell: ({ value }) => getUserTypeString({ id: value?.id }),
          },
        ]}
        onChangeRecipients={onChangeRecipients}
        onClickProceed={onClickProceed}
      />
    </>
  );
};
