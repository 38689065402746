import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        status: Yup.string(),
        type: Yup.string(),
      })}
    >
      {({ handleSubmit, values: { status } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='status'
                options={[
                  { label: t('scheduled'), value: 'upcoming' },
                  { label: t('sent'), value: 'complete' },
                  { label: t('drafts'), value: 'draft' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isDisabled={status === 'event'}
                label={t('type')}
                name='type'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('email'), value: 'email' },
                  { label: t('sms'), value: 'sms' },
                  { label: t('push'), value: 'push' },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
