import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        status: Yup.string(),
        locationId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={9}>
              <Field
                canSubmitOnChange
                isHorizontal
                isRadio
                label={t('status')}
                name='status'
                options={[
                  { label: t('active'), value: 1 },
                  { label: t('inactive'), value: 0 },
                ]}
              />
            </Col>

            <Col lg={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
