import { Box, Button, Container, SendComm } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { usePaging } from 'octiv-hooks';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import {
  useUserPackagesCreateExport,
  useUserPackagesFind,
} from 'octiv-hooks/requests/UserPackages';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { downloadFile } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();

  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    userTenantStatusId: 2,
    userId: undefined,
    packageId: undefined,
    startDateFrom: undefined,
    startDateTo: undefined,
    endDateFrom: undefined,
    endDateTo: undefined,
    isActive: 1,

    sort: '-end_date',
  });

  const usersFindMembers = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.locationId,
      userTenantStatusId: query.userTenantStatusId,
      isMinimalData: true,
    },
    paging: { perPage: -1 },
  });

  const packagesFind = usePackagesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const requestInput = React.useMemo(() => {
    return {
      filter: {
        locationId: query.locationId,
        userId: query.userId,
        packageId: query.packageId,
        isActive: query.isActive,
        userTenantStatusId: query?.userTenantStatusId,
        startsBetween:
          query.startDateFrom && query.startDateTo
            ? `${query.startDateFrom},${query.startDateTo}`
            : undefined,
        endsBetween:
          query.endDateFrom && query.endDateTo
            ? `${query.endDateFrom},${query.endDateTo}`
            : undefined,
      },
      sort: query.sort,
      paging,
    };
  }, [query, paging]);

  const userPackagesFind = useUserPackagesFind(requestInput);

  const userPackagesCreateExport = useUserPackagesCreateExport({
    onSuccess: downloadFile,
  });

  return (
    <Container
      appBarProps={{
        title: t('packages'),
        breadcrumbs: [t('members')],
        children: (
          <Box ml='auto'>
            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={t('csvExport')}
                onClick={() => userPackagesCreateExport.mutate(requestInput)}
              />

              <SendComm
                members={
                  !userPackagesFind.isFetching && userPackagesFind.data?.data
                }
              />
            </Box>
          </Box>
        ),
      }}
      isLoading={packagesFind.isFetching || userPackagesCreateExport.isLoading}
    >
      <FormQuery
        initialValues={query}
        isFetchingMembers={usersFindMembers.isFetching}
        isFetchingPackages={packagesFind.isFetching}
        locationOptions={locationOptionsAll}
        members={usersFindMembers.data?.data}
        packages={packagesFind.data?.data}
        onSubmit={setQuery}
      />

      <Box height={4} />

      <Table
        isPaginationAllHidden
        data={userPackagesFind.data?.data}
        isLoading={userPackagesFind.isFetching}
        paging={userPackagesFind.data?.paging}
        setPaging={setPaging}
        onClickRow={({ original: { user } }) =>
          setMemberModal({
            userTenantId: user?.userTenant?.id,
            onClose: userPackagesFind.refetch,
            initialView: 'packages',
          })
        }
        onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
      />
    </Container>
  );
};
