import {
  Box,
  Card,
  Chip,
  Modal,
  ProcessingBar,
  ProcessingSuspense,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  useFinancesCreatePayments,
  useFinancesCreateUserInvoices,
  useFinancesDeletePayments,
  useFinancesFindByIdUserInvoices,
  useFinancesFindInvoiceItemTypes,
  useFinancesUpdatePayments,
  useFinancesUpdateUserInvoices,
} from 'octiv-hooks/requests/Finances';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { tagsTypes, userStatus, userTypeId } from 'octiv-utilities/Constants';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormInvoice from './FormInvoice';
import FormRecordPayment from './FormRecordPayment';
import TableInvoice from './TableInvoice';
import TableLineItems from './TableLineItems';
import TablePayments from './TablePayments';

export const views = {
  VIEW: 'view',
  FORM: 'form',
  PAYMENT: 'payment',
};

export default ({ invoiceId, initialView = views.VIEW, onClose }) => {
  const { t } = useTranslation();
  const hasAccess = useContext(AccessContext);

  const {
    tenant: { locationOptions },
    toCurrency,
  } = useActiveUserTenant();

  const [selectedView, setSelectedView] = useState(initialView);
  const [payment, setPayment] = useState(undefined);
  const [memberLocationId, setMemberLocationId] = useState(undefined);

  const isCreate = invoiceId === undefined;

  const { data: invoiceItemTypes, isFetching: isFetchingInvoiceItemTypes } =
    useFinancesFindInvoiceItemTypes({
      include: 'payments',
      paging: { perPage: -1 },
    });

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers(
    {
      filter: {
        userGroup: 'gymMembers',
        userTenantTypeId: userTypeId.GYM_MEMBER,
        view: 'members',
        userTenantStatusId: userStatus.ACTIVE,
        isMinimalData: true,
        userTenantLocationId: memberLocationId,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: hasAccess.containerMembers,
    }
  );

  const invoice = useFinancesFindByIdUserInvoices(
    {
      id: invoiceId,
    },
    {
      enabled: !isCreate,
    }
  );

  const { data: paymentTags, isFetching: isFetchingPaymentTags } = useTagsFind(
    {
      filter: { type: tagsTypes.PAYMENT_PROCESSOR },
    },
    {
      enabled: selectedView === views.PAYMENT,
    }
  );

  const { isLoading: isCreatingInvoice, mutate: postInvoiceRequest } =
    useFinancesCreateUserInvoices({
      onSuccess: onClose,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingInvoice, mutate: putInvoiceRequest } =
    useFinancesUpdateUserInvoices({
      onSuccess: onClose,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const {
    isLoading: isCreatingInvoicePayment,
    mutate: postInvoicePaymentRequest,
  } = useFinancesCreatePayments({
    onSuccess: () => {
      setSelectedView(views.VIEW);
    },
    meta: { useOnSuccessToast: true },
  });

  const {
    isLoading: isUpdatingInvoicePayment,
    mutate: putInvoicePaymentRequest,
  } = useFinancesUpdatePayments({
    onSuccess: () => {
      setSelectedView(views.VIEW);
      setPayment(undefined);
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isDeletingInvoicePayment,
    mutate: deleteInvoicePaymentRequest,
  } = useFinancesDeletePayments({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostInvoiceRequest = (values) => {
    if (isCreate) {
      postInvoiceRequest(values);
    } else {
      putInvoiceRequest({ id: invoiceId, ...values });
    }
  };

  const onPutPostInvoicePaymentRequest = (values) => {
    if (payment) {
      putInvoicePaymentRequest({
        id: payment.id,
        ...values,
      });
    } else {
      postInvoicePaymentRequest({
        invoiceId,
        ...values,
      });
    }
  };

  return (
    <>
      {(isDeletingInvoicePayment || invoice.isFetching) && <ProcessingBar />}

      <Modal title={t('invoice')} onClose={onClose}>
        {invoice.isFetching && !invoice?.id ? (
          <ProcessingSuspense />
        ) : selectedView === views.FORM ? (
          <FormInvoice
            initialValues={invoice.data}
            invoiceItemTypes={invoiceItemTypes?.data}
            isCreate={isCreate}
            isFetchingInvoiceItemTypes={isFetchingInvoiceItemTypes}
            isFetchingMembers={isFetchingMembers}
            isLoading={isCreatingInvoice || isUpdatingInvoice}
            locationOptions={locationOptions}
            members={members?.data || []}
            toCurrency={toCurrency}
            onSelectLocation={({ locationId }) => {
              setMemberLocationId(locationId);
            }}
            onSubmit={onPutPostInvoiceRequest}
          />
        ) : (
          <>
            <Card
              hasBorder
              hasPaddingChildren={false}
              mb={4}
              title={t('details')}
            >
              {/*
              NOTE: Quick fix
              To prevent the table and page from crashing while fetching the data.
              Can discuss where & how to add a loading indicator
               */}
              <TableInvoice data={invoice.isFetching ? [] : [invoice.data]} />
            </Card>

            <Card
              hasBorder
              hasPaddingChildren={false}
              mb={4}
              title={t('lineItems')}
            >
              <TableLineItems data={invoice?.data?.items || []} />
            </Card>

            <Card
              hasBorder
              boxTitleContainerRenderRight={() => (
                <Box ml='auto'>
                  <Chip
                    hasAlternateBackground
                    icon='add'
                    title={t('recordPayment')}
                    onClick={() => setSelectedView(views.PAYMENT)}
                  />
                </Box>
              )}
              hasPaddingChildren={false}
              mb={4}
              title={t('payments')}
            >
              <TablePayments
                data={invoice.data?.payments || []}
                onDelete={({ id }) => {
                  if (window.confirm(t('areYouSureProceed'))) {
                    deleteInvoicePaymentRequest({ id });
                  }
                }}
                onEdit={(invoicePayment) => {
                  setSelectedView(views.PAYMENT);
                  setPayment(invoicePayment);
                }}
              />
            </Card>
          </>
        )}
      </Modal>

      {selectedView === views.PAYMENT && (
        <Modal
          isSidebar
          title={t('invoicePayment')}
          onClose={() => {
            setSelectedView(views.VIEW);
            setPayment(undefined);
          }}
        >
          <FormRecordPayment
            initialValues={payment}
            isFetchingPaymentTags={isFetchingPaymentTags}
            isLoading={isCreatingInvoicePayment || isUpdatingInvoicePayment}
            paymentTags={paymentTags?.data || []}
            onSubmit={onPutPostInvoicePaymentRequest}
          />
        </Modal>
      )}
    </>
  );
};
