import { Box, Text } from 'octiv-components';
import React from 'react';

const Badge = ({ hasBorder, isRound, text, textProps, size = 6, ...props }) => {
  const variableProps = {
    color: hasBorder ? 'font' : 'fontInverse',
    bg: hasBorder ? 'transparent' : 'primary',
    borderWidth: hasBorder ? '1px' : undefined,
    borderStyle: hasBorder ? 'solid' : undefined,
    borderColor: hasBorder ? 'primary' : undefined,
    hasRadius: isRound ? undefined : true,
    borderRadius: isRound ? '50%' : undefined,
  };

  return (
    <Box
      alignItems='center'
      display='inline-flex'
      justifyContent='center'
      minHeight={size}
      minWidth={size}
      {...variableProps}
      {...props}
    >
      {text && (
        <Text color='inherit' lineHeight={1} variant='caption' {...textProps}>
          {text}
        </Text>
      )}
    </Box>
  );
};

export default Badge;
