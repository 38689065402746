import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  healthProviders,
  initialValues = {},
  isCreate,
  isLoading,
  locationCategories,
  timezones,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        address: initialValues?.address || undefined,
        streetAddress: initialValues?.streetAddress || undefined,
        city: initialValues?.city || undefined,
        stateProvinceRegion: initialValues?.stateProvinceRegion || undefined,
        postalCode: initialValues?.postalCode || undefined,
        countryCode: initialValues?.countryCode || undefined,
        latitude: initialValues?.latitude || undefined,
        longitude: initialValues?.longitude || undefined,
      }}
      validationSchema={Yup.object().shape({
        streetAddress: Yup.string().required(t('required')),
        city: Yup.string().required(t('required')),
        stateProvinceRegion: Yup.string().required(t('required')),
        postalCode: Yup.string().required(t('required')),
        countryCode: Yup.string().required(t('required')),
        latitude: Yup.string().required(t('required')),
        longitude: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label='Street Address' name='streetAddress' />
            </Col>

            <Col>
              <Field label='City' name='city' />
            </Col>

            <Col>
              <Field label='State/Province/Region' name='stateProvinceRegion' />
            </Col>

            <Col>
              <Field label='Postal Code' name='postalCode' />
            </Col>

            <Col>
              <Field label='CountryCode' name='countryCode' />
            </Col>

            <Col>
              <Field label='Latitude' name='latitude' />
            </Col>
            <Col>
              <Field label='Longitude' name='longitude' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
