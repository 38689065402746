import { Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import { useFinancesFindPaystackBanks } from 'octiv-hooks/requests/Finances';
import {
  useLocationPaymentGatewaysCreateAdhoc,
  useLocationPaymentGatewaysFindAdhoc,
  useLocationPaymentGatewaysUpdateAdhoc,
} from 'octiv-hooks/requests/LocationPaymentGateways';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import FormGoCardless from './FormGoCardless';
import FormNetcash from './FormNetcash';
import FormPaystack from './FormPaystack';
import FormStripe from './FormStripe';
import Table from './Table';

export default ({ hasLocationGoCardless, hasLocationNetcash }) => {
  const { t } = useTranslation();

  const {
    tenant: { regionId, locations },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [settings, setSettings] = useState([]);

  const isTenantSouthAfrican = regionId === 1;

  const {
    data: payStackBanks,
    isFetching: isFetchingPaystackBanks,
    refetch: getPaystackBanksRequest,
  } = useFinancesFindPaystackBanks(
    {
      filter: {
        country: 'South Africa',
      },
    },
    {
      enabled: false,
    }
  );

  const {
    // data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useLocationPaymentGatewaysFindAdhoc(
    {},
    {
      onSuccess: (response) => {
        const data = response.map((item) => {
          return {
            id: item?.id,
            locationId: item?.locationId,
            location: item?.location,
            settings: [
              {
                ...item?.settings,
                id: item?.id,
                settingsId: item?.settings?.id,
                subAccountId: item?.settings?.subAccountId,
                isActive: item?.isActive,
                paymentGatewayId: item?.paymentGatewayId,
                locationSettingId: item?.id,
              },
            ],
          };
        });

        const result = [];

        locations.forEach((location) => {
          result.push({
            location,
            settings: data
              .filter((value) => location.id === value.locationId)
              ?.map((s) => s.settings?.[0]),
          });
        });

        setSettings(Object.keys(result).map((key) => result[key]));
      },
    }
  );

  const { isLoading: isCreatingSetting, mutate: postSettingRequest } =
    useLocationPaymentGatewaysCreateAdhoc({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingSetting, mutate: putSettingRequest } =
    useLocationPaymentGatewaysUpdateAdhoc({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onFormSubmit = (values) => {
    if (toggle.type === 'create') {
      postSettingRequest({
        ...toggle.data,
        ...values,
      });
    } else {
      putSettingRequest({
        settingsId: toggle.data.locationSettingId,
        ...values,
      });
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal isSidebar title={t('adhocDetails')} onClose={resetToggle}>
          {toggle.data.paymentGatewayId === 4 ? (
            <FormNetcash
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isLoading={isCreatingSetting || isUpdatingSetting}
              onSubmit={onFormSubmit}
            />
          ) : toggle.data.paymentGatewayId === 5 ? (
            <FormGoCardless
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isLoading={isCreatingSetting || isUpdatingSetting}
              onSubmit={onFormSubmit}
            />
          ) : toggle.data.paymentGatewayId === 6 ? (
            <FormStripe
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isLoading={isCreatingSetting || isUpdatingSetting}
              onSubmit={onFormSubmit}
            />
          ) : toggle.data.paymentGatewayId === 7 ? (
            <FormPaystack
              banks={isFetchingPaystackBanks ? [] : payStackBanks || []}
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isFetchingBanks={isFetchingPaystackBanks}
              isLoading={
                isCreatingSetting ||
                isUpdatingSetting ||
                isFetchingPaystackBanks
              }
              onSubmit={onFormSubmit}
            />
          ) : null}
        </Modal>
      )}

      <Setting title={t('adhoc')}>
        <Table
          hasAlternateBackground
          data={settings || []}
          hasLocationGoCardless={hasLocationGoCardless}
          hasLocationNetcash={hasLocationNetcash}
          isLoading={isFetchingSettings}
          isTenantSouthAfrican={isTenantSouthAfrican}
          locations={locations}
          onClickAction={(toggleData) => {
            setToggle(toggleData);

            if (toggleData?.data?.paymentGatewayId === 7) {
              getPaystackBanksRequest();
            }
          }}
        />
      </Setting>
    </>
  );
};
