import {
  Modal,
  ProcessingBar,
  ProcessingSuspense,
  Text,
} from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useCoronavirusQuestionnaireResultsFind,
  useCoronavirusQuestionnaireResultsUpdate,
} from 'octiv-hooks/requests/CoronavirusQuestionnaireResults';
import {
  useCoronavirusVaccinationDetailsCreate,
  useCoronavirusVaccinationDetailsFind,
  useCoronavirusVaccinationDetailsFindByIdDownload,
  useCoronavirusVaccinationDetailsUpdate,
} from 'octiv-hooks/requests/CoronavirusVaccinationDetails';
import { downloadFile } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormQuestionnaire from '../../scheduling/coronavirus/FormQuestionnaire';
import FormVaccinationDetails from './FormVaccinationDetails';
import Table from './Table';

const UserMemberCoronavirus = ({ userId }) => {
  const { t } = useTranslation();

  const [paging, setPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();

  const coronavirusVaccinationDetailsFind =
    useCoronavirusVaccinationDetailsFind({
      filter: { userId },
    });

  const coronavirusVaccinationDetailsCreate =
    useCoronavirusVaccinationDetailsCreate();

  const coronavirusVaccinationDetailsUpdate =
    useCoronavirusVaccinationDetailsUpdate();

  const coronavirusQuestionnaireResultsFind =
    useCoronavirusQuestionnaireResultsFind({ filter: { userId }, paging });

  const coronavirusQuestionnaireResultsUpdate =
    useCoronavirusQuestionnaireResultsUpdate({
      onSuccess: resetToggle,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const coronavirusVaccinationDetailsFindByIdDownload =
    useCoronavirusVaccinationDetailsFindByIdDownload({
      enabled: false,
      onSuccess: downloadFile,
    });

  const onSubmitFormQuestionnaire = (values) => {
    coronavirusQuestionnaireResultsUpdate.mutate({
      id: toggle.data.id,
      ...values,
    });
  };

  const onSubmitFormVaccinationDetails = (values) => {
    if (values?.id) {
      coronavirusVaccinationDetailsUpdate.mutate({
        id: values.id,
        ...values,
      });
    } else {
      coronavirusVaccinationDetailsCreate.mutate({
        userId,
        ...values,
      });
    }
  };

  return (
    <>
      {(coronavirusVaccinationDetailsFind.isFetching ||
        coronavirusVaccinationDetailsFindByIdDownload.isFetching) && (
        <ProcessingBar />
      )}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={t('coronavirusQuestionnaire')}
          onClose={resetToggle}
        >
          <FormQuestionnaire
            initialValues={toggle.data}
            isLoading={coronavirusQuestionnaireResultsUpdate.isLoading}
            onSubmit={onSubmitFormQuestionnaire}
          />
        </Modal>
      )}

      <Text mb={2} variant='heading'>
        {t('vaccinationDetails')}
      </Text>

      {coronavirusVaccinationDetailsFind.isFetching &&
      !coronavirusVaccinationDetailsFind.data?.data?.[0] ? (
        <ProcessingSuspense />
      ) : (
        <FormVaccinationDetails
          initialValues={coronavirusVaccinationDetailsFind.data?.data?.[0]}
          isLoading={
            coronavirusVaccinationDetailsCreate.isLoading ||
            coronavirusVaccinationDetailsUpdate.isLoading
          }
          onDownloadFile={
            coronavirusVaccinationDetailsFind.data?.data?.[0]?.id
              ? () =>
                  coronavirusVaccinationDetailsFindByIdDownload.mutate({
                    id: coronavirusVaccinationDetailsFind.data?.data?.[0].id,
                  })
              : undefined
          }
          onSubmit={onSubmitFormVaccinationDetails}
        />
      )}

      <Text mb={2} mt={6} variant='heading'>
        {t('questionnaireResults')}
      </Text>

      <Table
        data={coronavirusQuestionnaireResultsFind.data?.data || []}
        isLoading={coronavirusQuestionnaireResultsFind.isFetching}
        paging={coronavirusQuestionnaireResultsFind.data?.paging}
        setPaging={setPaging}
        onClickAction={setToggle}
      />
    </>
  );
};

export default UserMemberCoronavirus;
