import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  canAccessAllLocations,
  locationOptions,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        reportType: 'members',
        locationId: undefined,
        startDate: undefined,
        endDate: undefined,
      }}
      validationSchema={Yup.object().shape({
        reportType: Yup.string().required(t('required')),
        locationId: canAccessAllLocations
          ? Yup.string()
          : Yup.string().required(t('required')),
        startDate: Yup.string(),
        endDate: Yup.string(),
      })}
    >
      {({ handleSubmit, values: { reportType } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                label={t('type')}
                name='reportType'
                options={[
                  {
                    label: t('members'),
                    value: 'members',
                  },
                  {
                    label: t('finances'),
                    value: 'finances',
                  },
                  {
                    label: t('bookings'),
                    value: 'bookings',
                  },
                  {
                    label: t('leads'),
                    value: 'leads',
                  },
                ]}
              />
            </Col>

            {reportType && (
              <Col lg={3} md={6}>
                <Field
                  hasAlternateBackground
                  isSelect
                  label={t('location')}
                  name='locationId'
                  options={locationOptions}
                />
              </Col>
            )}

            {reportType && reportType !== 'members' && (
              <>
                <Col lg={3} md={6}>
                  <Field
                    hasAlternateBackground
                    isDate
                    label={t('dateFrom')}
                    name='startDate'
                  />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    hasAlternateBackground
                    isDate
                    label={t('dateTo')}
                    name='endDate'
                  />
                </Col>
              </>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('export')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
