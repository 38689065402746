import { Box, Chip, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BulkActions = ({
  isVisible,
  onClick,
  options,
  textTitleProps,
  title,
  data,
  ...props
}) => {
  const { t } = useTranslation();

  if (data && data.length) {
    return (
      <Box {...props}>
        <Text variant='subheading' {...textTitleProps}>
          {`${title === undefined ? t('bulkActions') : title} (${data.length})`}
        </Text>

        <Box isFlex flexWrap='wrap'>
          {options.map(({ icon, value, ...restOption }) => (
            <Chip
              icon={icon}
              key={value}
              mr={2}
              mt={2}
              onClick={() => onClick({ value })}
              {...restOption}
            />
          ))}
        </Box>
      </Box>
    );
  }

  return null;
};

export default BulkActions;
