import { Box, Card, ProcessingBar, ProcessingSuspense } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { ThemeContextProvider } from 'octiv-context/Theme';
import {
  useClassDatesFindById,
  useClassDatesFindByIdClassBookingDetails,
} from 'octiv-hooks/requests/ClassDates';
import { getBookingsData } from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Header from './Header';
import TableAttendees from './TableAttendees';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const hasAccess = useContext(AccessContext);

  const [bookings, setBookings] = useState();
  const [canPrint, setCanPrint] = useState(false);

  const { data: event, isFetching: isFetchingEvent } = useClassDatesFindById(
    { id },
    {
      enabled: !!id,
    }
  );

  const { isFetching: isFetchingEventBookingDetails } =
    useClassDatesFindByIdClassBookingDetails(
      { id: event?.id, append: 'active_user_packages' },
      {
        enabled: !!event?.id,
        onSuccess: (response) => {
          setBookings(getBookingsData({ allBookings: response }));
          setTimeout(() => setCanPrint(true), 500);
        },
      }
    );

  useEffect(() => {
    if (canPrint) {
      window.print();
    }
  }, [canPrint]);

  return (
    <div className='print-show'>
      {isFetchingEvent && <ProcessingBar />}

      {!!event?.id && (
        <ThemeContextProvider staticTheme='light'>
          <Box bg='white' minHeight='100vh' p={5}>
            <Header
              isPrint
              date={event?.date}
              endTime={event?.endTime}
              location={event?.class.location}
              startTime={event?.startTime}
              supportingTrainer={event?.supportingTrainer}
              tags={event?.tags}
              trainer={event?.trainer}
            />

            {isFetchingEventBookingDetails && <ProcessingSuspense mt={4} />}

            {bookings && (
              <>
                <Card
                  hasBorder
                  hasPaddingChildren={false}
                  mt={4}
                  title={`${t('booked')}: ${bookings.bookedCount}/${
                    event?.limit
                  }`}
                >
                  <TableAttendees
                    isPrint
                    canDisplayExtraDetailsInClass={
                      hasAccess.featureSchedulingExtraDetailsInClass
                    }
                    data={bookings.booked}
                    date={event?.date}
                    isLoading={isFetchingEventBookingDetails}
                  />
                </Card>

                {bookings.waitingListCount > 0 && (
                  <Card
                    hasBorder
                    hasPaddingChildren={false}
                    mt={4}
                    title={`${t('waitingList')}: ${bookings.waitingListCount}`}
                  >
                    <TableAttendees
                      isPrint
                      isWaitingList
                      canDisplayExtraDetailsInClass={
                        hasAccess.featureSchedulingExtraDetailsInClass
                      }
                      data={bookings.waitingList}
                      date={event?.date}
                      isLoading={isFetchingEventBookingDetails}
                    />
                  </Card>
                )}

                {bookings.lateCancellationsCount > 0 && (
                  <Card
                    hasBorder
                    hasPaddingChildren={false}
                    mt={4}
                    title={`${t('lateCancellations')}: ${
                      bookings.lateCancellationsCount
                    }`}
                  >
                    <TableAttendees
                      isLateCancellations
                      isPrint
                      canDisplayExtraDetailsInClass={
                        hasAccess.featureSchedulingExtraDetailsInClass
                      }
                      data={bookings.lateCancellations}
                      date={event?.date}
                      isLoading={isFetchingEventBookingDetails}
                    />
                  </Card>
                )}
              </>
            )}
          </Box>
        </ThemeContextProvider>
      )}
    </div>
  );
};
