import { Table } from 'octiv-components';
import React, { useMemo } from 'react';

// TODO: Is this still needed?
export default ({ data, ...props }) => {
  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Information Found',
        id: 'info',
        Cell: () =>
          'Please chat to Matt as soon as you see this so it can be catered for.',
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
