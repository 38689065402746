import moment from 'moment';
import { Alert, Box, Col, Container, Row } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import { useCrmFindBroadcastMessages } from 'octiv-hooks/requests/Crm';
import {
  useDashboardFindContractsExpiring,
  useDashboardFindNewSignUps,
  useDashboardFindNonAttendance,
  useDashboardFindScheduleStats,
} from 'octiv-hooks/requests/Dashboard';
import { useInjuriesFind } from 'octiv-hooks/requests/Injuries';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { useWorkoutsFind } from 'octiv-hooks/requests/Workouts';
import { userStatus } from 'octiv-utilities/Constants';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Birthdays from './modules/Birthdays';
import Injuries from './modules/Injuries';
import Leads from './modules/Leads';
import Schedule from './modules/Schedule';
import ScheduleStats from './modules/ScheduleStats';
import Tasks from './modules/Tasks';
import Weeks from './modules/Weeks';
import Workouts from './modules/Workouts';

const dateToday = getDateYearMonthDay({ canFallback: true });

export default () => {
  const { t } = useTranslation();

  const { tenantId, selectedLocation } = useActiveUserTenant();
  const hasAccess = useContext(AccessContext);

  const dashboardUsersPending = useUsersFindMembers(
    {
      filter: {
        userTenantStatusId: userStatus.PENDING,
        userTenantLocationId: selectedLocation?.id,
      },
      sort: 'name',
    },
    {
      enabled: hasAccess.containerMembers,
    }
  );

  const { data: noticeboard, isFetching: isFetchingNoticeboard } =
    useCrmFindBroadcastMessages(
      {},
      {
        enabled: hasAccess.screenCommsNoticeboard,
      }
    );

  const dashboardBirthdays = useUsersFindMembers(
    {
      filter: {
        userTenantLocationId: selectedLocation?.id,
        birthdaysBetween: `${getDateYearMonthDay({
          canFallback: true,
        })},${getDateYearMonthDay({
          date: moment().add(2, 'week'),
        })}`,
      },
      sort: 'birthday',
    },
    {
      enabled: hasAccess.screenMembersBirthdays,
    }
  );

  const dashboardScheduleStats = useDashboardFindScheduleStats(
    {
      filter: {
        locationId: selectedLocation?.id,
      },
    },
    {
      enabled: hasAccess.containerScheduling,
    }
  );

  const dashboardInjuries = useInjuriesFind(
    {
      filter: {
        locationId: selectedLocation?.id,
        tenantId,
      },
      include: 'user,user.userTenant,injuryUpdates',
    },
    {
      enabled: hasAccess.screenMembersInjuries,
    }
  );

  const dashboardClassDates = useClassDatesFind({
    filter: {
      locationId: selectedLocation?.id,
      between: `${dateToday},${dateToday}`,
    },
    paging: {
      perPage: -1,
    },
  });

  const dashboardWorkouts = useWorkoutsFind(
    {
      filter: {
        startsAfter: dateToday,
        endsBefore: getDateYearMonthDay({
          date: moment(dateToday).add(1, 'day'),
        }),
      },
    },
    {
      enabled: hasAccess.containerWorkouts,
    }
  );

  const {
    data: nonAttendance,
    isFetching: isFetchingNonAttendance,
    error: errorNonAttendance,
  } = useDashboardFindNonAttendance({
    filter: { locationId: selectedLocation?.id },
  });

  const dashboardNewSignups = useDashboardFindNewSignUps({
    filter: { locationId: selectedLocation?.id },
  });

  const dashboardContractsExpiring = useDashboardFindContractsExpiring({
    filter: { locationId: selectedLocation?.id },
  });

  return (
    <Container
      appBarProps={{
        title: t('dashboard'),
      }}
      isLoading={
        dashboardBirthdays.isFetching ||
        dashboardClassDates.isFetching ||
        dashboardInjuries.isFetching ||
        isFetchingNoticeboard ||
        dashboardScheduleStats.isFetching ||
        dashboardUsersPending.isFetching ||
        dashboardWorkouts.isFetching
      }
    >
      {hasAccess.screenCommsNoticeboard && !!noticeboard?.isActive && (
        <Alert
          mb={4}
          subtitle={noticeboard?.message}
          title={t('currentNotice')}
          to='/comms/noticeboard'
          variant='info'
        />
      )}

      {hasAccess.containerMembers &&
        dashboardUsersPending?.data?.data.length > 0 && (
          <Alert
            mb={4}
            subtitle={t('membersInPendingState', {
              count: dashboardUsersPending.data?.data?.filter(
                (user) => user?.userTenant?.statusId === 1
              )?.length,
            })}
            to='/members/manage?statusId=1'
            variant='warning'
          />
        )}

      <Row>
        <Col lg={5}>
          {hasAccess.containerScheduling && !dashboardScheduleStats.error && (
            <>
              <ScheduleStats
                data={dashboardScheduleStats.data || {}}
                isLoading={dashboardScheduleStats.isFetching}
              />
              <Box height={4} />
            </>
          )}

          {hasAccess.containerScheduling && !dashboardClassDates.error && (
            <>
              <Schedule
                data={dashboardClassDates.data?.data || []}
                getEventsRequest={dashboardClassDates.refetch}
                isLoading={dashboardClassDates.isFetching}
              />
              <Box height={4} />
            </>
          )}

          {hasAccess.containerWorkouts && !dashboardWorkouts.error && (
            <Workouts
              data={dashboardWorkouts.data?.data || []}
              isLoading={dashboardWorkouts.isFetching}
            />
          )}

          {!hasAccess.containerWorkouts && hasAccess.containerTasks && (
            <Tasks />
          )}
        </Col>

        <Col lg={7}>
          <Row>
            {hasAccess.screenMembersBirthdays && !dashboardBirthdays.error && (
              <Col md={6}>
                <Birthdays
                  data={dashboardBirthdays.data?.data || []}
                  isLoading={dashboardBirthdays.isFetching}
                />
              </Col>
            )}

            {hasAccess.screenMembersInjuries && !dashboardInjuries.error && (
              <Col md={6}>
                <Injuries
                  data={dashboardInjuries.data?.data || []}
                  isLoading={dashboardInjuries.isFetching}
                />
              </Col>
            )}

            {!errorNonAttendance && (
              <Col md={6}>
                <Weeks
                  data={nonAttendance || {}}
                  isLoading={isFetchingNonAttendance}
                  noDataText={t('noMembersInRange')}
                  title={t('nonAttendance')}
                />
              </Col>
            )}

            {!dashboardNewSignups.error && (
              <Col md={6}>
                <Weeks
                  data={dashboardNewSignups.data || {}}
                  isLoading={dashboardNewSignups.isFetching}
                  noDataText={t('noNewSignUpsInRange')}
                  title={t('newSignUps')}
                />
              </Col>
            )}

            {!dashboardContractsExpiring.error && (
              <Col md={6}>
                <Weeks
                  data={dashboardContractsExpiring?.data || {}}
                  isLoading={dashboardContractsExpiring.isFetching}
                  noDataText={t('noContractsExpiringInRange')}
                  title={t('contractsExpiring')}
                />
              </Col>
            )}
          </Row>
        </Col>

        {hasAccess.containerWorkouts && hasAccess.containerTasks && (
          <Col>
            <Tasks />
          </Col>
        )}

        {hasAccess.containerLeads && (
          <Col>
            <Leads />
          </Col>
        )}
      </Row>
    </Container>
  );
};
