import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('description'),
        accessor: 'description',
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => (
          <Text as='span' color={value.id === 'enabled' ? 'success' : 'danger'}>
            {t(value.name?.toLowerCase())}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        Cell: ({ row: { original } }) => (
          <Text
            as='span'
            color='primary'
            onClick={() => {
              onClickAction({ data: original });
            }}
          >
            {t('viewEdit')}
          </Text>
        ),
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
