import {
  Alert,
  Button,
  Container,
  Divider,
  Modal,
  Text,
} from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useLocationsCreateProcessPayments,
  useLocationsFind,
} from 'octiv-hooks/requests/Locations';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [locationPayments, setLocationPayments] = useState([]);
  const [query, setQuery] = useState({
    regionId: undefined,
    tenantId: undefined,
    healthProviderId: undefined,
    search: undefined,
  });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
    sort: 'name',
  });

  const { data: tenants, isFetching: isFetchingTenants } = useTenantsFind({
    paging: { perPage: -1 },
  });

  const { data: locations, isFetching: isFetchingLocations } = useLocationsFind(
    {
      filter: query,
      paging: { perPage: -1 },
      include:
        'healthProviders,healthProvidersCount,tenant,tenant.tenantCurrency,category,payFastPaymentGateway',
    }
  );

  const {
    isLoading: isCreatingLocationPayments,
    mutate: postLocationPaymentsRequest,
  } = useLocationsCreateProcessPayments({
    onSuccess: (response) => setToggle({ data: response }),
  });

  const onSetLocationPayments = ({ locationId, value }) => {
    setLocationPayments((prev) => {
      const newPayments = [...prev];

      const foundIndex = newPayments.findIndex(
        (item) => item.locationId === locationId
      );

      if (foundIndex > -1) {
        newPayments[foundIndex].amount = value;
      } else {
        newPayments.push({
          locationId,
          amount: value,
        });
      }

      return newPayments;
    });
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('responseData')} onClose={resetToggle}>
          <Text>{JSON.stringify(toggle.data)}</Text>
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('locationBilling'),
          breadcrumbs: [t('accounts')],
        }}
        isLoading={isFetchingLocations || isCreatingLocationPayments}
      >
        <Alert
          mb={4}
          subtitle={t('changingFilterValues')}
          title={t('pleaseNote')}
          variant='warning'
        />

        <FormQuery
          initialValues={query}
          isFetchingRegions={isFetchingRegions}
          isFetchingTenants={isFetchingTenants}
          regions={regions?.data || []}
          tenants={tenants?.data || []}
          onSubmit={(values) => {
            setLocationPayments([]);
            setQuery(values);
          }}
        />

        <Divider pb={4} />

        <Table
          data={locations?.data || []}
          isLoading={isFetchingLocations}
          regions={isFetchingRegions ? [] : regions?.data}
          onSetLocationPayments={onSetLocationPayments}
        />

        <Button
          isLoading={isCreatingLocationPayments}
          ml='auto'
          mt={4}
          text={t('submit')}
          onClick={() =>
            postLocationPaymentsRequest({ billingAmounts: locationPayments })
          }
        />
      </Container>
    </>
  );
};
