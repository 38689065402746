import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useLocationCategoriesFind = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['locationCategories', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/location-categories`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useLocationCategoriesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/location-categories`,
        data: { name: data.name },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationCategoriesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/location-categories/${id}`,
        data: { name: data.name },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationCategoriesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'delete',
        url: `/api/location-categories/${id}`,
        data: { replacementCategoryId: data.replacementCategoryId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locationCategories'] });

      options?.onSuccess?.(...res);
    },
  });
};
