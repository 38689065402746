import React from 'react';

import { StyledDivider } from './Styled';

const Divider = (props) => (
  <StyledDivider
    canFillSpace={false}
    hasLine={false}
    maxWidth='100%'
    {...props}
  />
);

export default Divider;
