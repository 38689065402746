import { Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onCancelAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('action'),
        accessor: 'action',
        Cell: ({ value }) =>
          value === 'onHold' ? t('placeOnHold') : t('deactivate'),
      },
      {
        Header: t('dateAction'),
        accessor: 'date',
        Cell: ({ row: { original }, value }) => {
          const { excludeFromFutureBatches } = original.details;
          return `${getDateReadableDayMonthYear({ date: value })} ${
            excludeFromFutureBatches !== undefined
              ? excludeFromFutureBatches === 1
                ? `(${t('excludedFromFutureBatches')})`
                : `(${t('notExcludedFromFutureBatches')})`
              : ''
          }`;
        },
      },
      {
        Header: t('releaseDate'),
        accessor: 'details.onHoldReleaseDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('willPackagesExtend'),
        accessor: 'details.extendPackageEndDate',
        Cell: ({ value }) => (value === 1 ? 'Yes' : value === 0 ? 'No' : null),
      },
      {
        Header: t('firstInvoiceAmount'),
        accessor: 'details.onHoldProRataFee',
        Cell: ({ value }) => (value ? toCurrency({ value }) : null),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ value }) => (
          <Text
            as='span'
            color='danger'
            onClick={() => onCancelAction({ id: value })}
          >
            {t('cancel')}
          </Text>
        ),
      },
    ],
    [toCurrency, onCancelAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
