import { Card, ProcessingSuspense, Text, Workout } from 'octiv-components';
import { sanitizeWorkout } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isLoading }) => {
  const { t } = useTranslation();

  const hasWorkouts = !!data[0];

  return (
    <Card
      textTitleProps={{ to: '/workouts/manage' }}
      title={t('workouts')}
      toggleTabsProps={
        hasWorkouts && {
          options: data.map(({ programme }) => ({
            label: programme.name,
            children: (activeIndex) =>
              data[activeIndex] && (
                <Workout
                  data={sanitizeWorkout({ workout: data[activeIndex] })}
                  p={4}
                  showProgramme={false}
                />
              ),
          })),
        }
      }
    >
      {isLoading ? (
        <ProcessingSuspense />
      ) : !hasWorkouts ? (
        <Text color='grey2'>{t('noWorkoutsToday')}</Text>
      ) : null}
    </Card>
  );
};
