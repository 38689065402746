import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'createdOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('customer'),
        accessor: 'purchaser',
        Cell: ({ value }) => getFullName(value),
      },
      {
        Header: t('cartSize'),
        accessor: 'cartSize',
      },
      {
        Header: t('cartCostPrice'),
        accessor: 'cartCostPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('cartAmount'),
        accessor: 'cartAmount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => t(value),
      },
    ],
    [toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
