import { useMutation } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useLoginCreate = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/login`,
        data: {
          username: data.username,
          password: data.password,
        },
      });
    },
  });
};
