import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  locationOptions,
  debitBatches,
  isFetchingDebitBatches,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        debitBatchId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit, setFieldTouched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                doFinally={() => {
                  setFieldTouched('debitBatchId', false);
                  setFieldValue('debitBatchId', undefined);
                }}
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingDebitBatches}
                label={t('batch')}
                name='debitBatchId'
                options={
                  debitBatches
                    ? debitBatches.map((item) => ({
                        label: getDateReadableDayMonthYear({
                          date: item.debitDayDate.date,
                        }),
                        value: item.id,
                      }))
                    : []
                }
              />
            </Col>

            <Col md={4}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
