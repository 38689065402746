import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Col,
  Container,
  DateStrip,
  Modal,
  ProcessingSpinner,
  Row,
  Text,
} from 'octiv-components';
import { useMediaQuery, useQuery, useToggle } from 'octiv-hooks';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import {
  useLeadsCreateDropIn,
  useLeadsFindDropInPackages,
} from 'octiv-hooks/requests/Leads';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import useWidgetTenant from 'octiv-hooks/useWidgetTenant';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Event from '../../scheduling/member/Event';
import FullscreenProcessingSpinner from '../components/FullscreenProcessingSpinner';
import WidgetHeader from '../components/Header';
import Package from '../shared/Package';
import FormDetails from './FormDetails';
import FormQuery from './FormQuery';

export default () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const publicToken = useQuery('publicToken');
  const leadToken = useQuery('leadToken');
  const hasEmbeddedDropInQuery = useQuery('hasEmbeddedDropIn');
  const isDropInQuery = useQuery('isDropIn');

  const hasEmbeddedDropIn = hasEmbeddedDropInQuery !== 'false';
  const isDropIn = isDropInQuery === 'true';

  const { xsDown } = useMediaQuery();

  const [toggleTerms, setToggleTerms, resetToggleTerms] = useToggle();
  const [toggleDetails, setToggleDetails, resetToggleDetails] = useToggle();
  const [toggleDropIn, setToggleDropIn, resetToggleDropIn] = useToggle({
    isVisible: isDropIn,
    type: undefined,
    data: undefined,
  });

  const [date, setDate] = useState(getDateYearMonthDay({ canFallback: true }));
  const [chosenPackage, setChosenPackage] = useState();
  const [query, setQuery] = useState({
    locationId: undefined,
    isSession: 0,
  });

  const { tenantId, locationOptions, ...tenant } = useWidgetTenant();

  React.useEffect(() => {
    if (locationOptions?.[0]) {
      setQuery((prev) => ({
        ...prev,
        locationId: locationOptions[0].value,
      }));
    }
  }, [locationOptions]);

  const leadsFindDropInPackages = useLeadsFindDropInPackages(
    {
      filter: {
        tenantId,
        leadToken,
      },
    },
    {
      enabled: !!tenantId && !!leadToken,
    }
  );

  const leadDropInPackages = React.useMemo(() => {
    return leadsFindDropInPackages.data || [];
  }, [leadsFindDropInPackages.data]);

  const leadDropInPackagesSessionsAvailable = React.useMemo(() => {
    return leadDropInPackages.reduce(
      (acc, item) => acc + item.sessionsAvailable,
      0
    );
  }, [leadDropInPackages]);

  const classDatesFind = useClassDatesFind(
    {
      filter: {
        tenantId,
        locationId: query?.locationId,
        between: `${date},${date}`,
        packageIds: leadToken
          ? leadDropInPackages
              .map((leadDropInPackage) => leadDropInPackage?.package?.id)
              .join(',')
          : chosenPackage?.id,
        classBookingsForLeadToken: leadToken,
        isVisibleInApp: 1,
        isSession: query?.isSession,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: query?.locationId
        ? isDropIn
          ? !!chosenPackage?.id
          : undefined
        : false,
    }
  );

  const packagesFind = usePackagesFind(
    {
      filter: {
        tenantId,
        typeId: 4,
        isActive: 1,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: !!tenantId && !leadToken && (hasEmbeddedDropIn || isDropIn),
    }
  );

  const leadsCreateDropIn = useLeadsCreateDropIn({
    onSuccess: ({
      invoiceId,
      locationPaymentGatewayId,
      link,
      leadToken: responseLeadToken,
    } = {}) => {
      if (link) {
        window.location.assign(link);
      } else if (invoiceId) {
        navigate(
          `/payment/${invoiceId}${
            locationPaymentGatewayId ? `?gid=${locationPaymentGatewayId}` : ''
          }`,
          { replace: true }
        );
      } else {
        navigate(
          `/widget/schedule?publicToken=${publicToken}&leadToken=${responseLeadToken}`,
          { replace: true }
        );

        resetToggleDetails();
        setToggleDropIn({
          isVisible: false,
          type: undefined,
          data: undefined,
        });
      }
    },
  });

  if (!tenantId) {
    return <FullscreenProcessingSpinner />;
  }

  return (
    <>
      {toggleDetails.isVisible && (
        <Modal title={t('yourDetails')} onClose={resetToggleDetails}>
          <FormDetails
            initialValues={query}
            isLoading={leadsCreateDropIn.isLoading}
            locationOptions={locationOptions}
            useContractWaiver={tenant.useContractWaiver}
            onClickTerms={setToggleTerms}
            onSubmit={(values) =>
              leadsCreateDropIn.mutate({
                tenantId,
                packageId: chosenPackage.id,
                locationId: query?.locationId,
                ...values,
              })
            }
          />
        </Modal>
      )}

      {toggleTerms.isVisible && (
        <Modal isSidebar title={t('waiver')} onClose={resetToggleTerms}>
          {tenant.waiverTermsAndConditions && (
            <Text
              as='div'
              dangerouslySetInnerHTML={{
                __html: tenant.waiverTermsAndConditions,
              }}
            />
          )}
        </Modal>
      )}

      <Container
        isLoading={
          classDatesFind.isFetching ||
          packagesFind.isFetching ||
          leadsFindDropInPackages.isFetching
        }
      >
        <WidgetHeader tenant={tenant} />

        <DateStrip
          activeDate={date}
          mb={6}
          onChange={(value) =>
            setDate(getDateYearMonthDay({ date: value.date }))
          }
        />

        {!!leadToken && leadDropInPackages?.[0] && (
          <Alert
            buttonRightProps={
              leadDropInPackagesSessionsAvailable > 0
                ? undefined
                : {
                    text: t('buySessions'),
                    onClick: () =>
                      navigate(`/widget/schedule?publicToken=${publicToken}`),
                  }
            }
            mb={4}
            subtitle={`${t(
              'sessionsRemaining'
            )}: ${leadDropInPackagesSessionsAvailable}`}
            title={`${t('packages')}: ${leadDropInPackages
              .map((leadDropInPackage) => leadDropInPackage?.package?.name)
              .join(', ')}`}
            variant={
              leadDropInPackagesSessionsAvailable > 0 ? 'info' : 'danger'
            }
          />
        )}

        <Box mb={4}>
          <Row>
            <Col lg={3} md={4}>
              <FormQuery
                initialValues={query}
                locationOptions={locationOptions}
                onSubmit={(values) => {
                  setQuery({
                    locationId: values.locationId,
                    isSession: values.isSession,
                  });
                }}
              />
            </Col>

            <Col lg={9} md={8}>
              <Box isFlex alignItems='center' minHeight={14}>
                <Text
                  isBold
                  mr='auto'
                  variant={xsDown ? 'subheading' : 'heading'}
                >
                  {moment(date)
                    .locale(i18n.resolvedLanguage)
                    .format('dddd DD MMMM YYYY')}
                </Text>

                {hasEmbeddedDropIn && !isDropIn && !leadToken ? (
                  toggleDropIn.isVisible ? (
                    <Button
                      hasBorder
                      color='grey1'
                      text={t('cancel')}
                      onClick={() => {
                        resetToggleDropIn();
                        setChosenPackage(undefined);
                      }}
                    />
                  ) : (
                    <Button
                      color='primary'
                      text={t('buyDropIn')}
                      onClick={setToggleDropIn}
                    />
                  )
                ) : null}
              </Box>
            </Col>
          </Row>
        </Box>

        {toggleDropIn.isVisible && (
          <Box hasRadius bg='grey6' mb={4} p={4}>
            <Text variant='heading'>{t('dropInPackages')}</Text>

            <Box color='grey1' mb={4}>
              <ol>
                <li>{t('selectAPackage')}</li>
                <li>{t('classesYouMayBookFor')}</li>
                <li>{t('confirmPackageFillOutDetails')}</li>
                <li>{t('thenAbleToBook')}</li>
              </ol>
            </Box>

            {packagesFind.isFetching ? (
              <ProcessingSpinner mx='auto' />
            ) : packagesFind.data?.data?.[0] ? (
              packagesFind.data.data.map((item, index) => {
                const { id } = item;

                return (
                  <Package
                    boxContainerProps={index > 0 && { mt: 2 }}
                    isChosen={id === chosenPackage?.id}
                    item={item}
                    key={id}
                    onClickSelect={({ isChosen }) => {
                      setChosenPackage(isChosen ? undefined : item);
                    }}
                  />
                );
              })
            ) : (
              <Text textAlign='center'>{t('noPackagesAvailable')}</Text>
            )}

            <Button
              color='primary'
              isDisabled={!chosenPackage}
              ml='auto'
              mt={4}
              text={t('confirm')}
              onClick={setToggleDetails}
            />
          </Box>
        )}

        {!toggleDropIn.isVisible || chosenPackage ? (
          classDatesFind.data?.data?.[0] ? (
            classDatesFind.data.data.map((item) => (
              <Event
                isWidget
                data={item}
                key={item.id}
                leadSessions={leadDropInPackagesSessionsAvailable}
                leadToken={leadToken}
                leadUserId={leadDropInPackages?.[0]?.userId}
                mb={2}
              />
            ))
          ) : (
            <Text color='grey1' textAlign='center'>
              {`${classDatesFind.isFetching ? t('fetching') : t('no')} ${t(
                'classes'
              )}`}
            </Text>
          )
        ) : null}
      </Container>
    </>
  );
};
