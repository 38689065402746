import { Images } from 'octiv-assets';
import {
  Alert,
  Box,
  Button,
  Divider,
  Image,
  Modal,
  Pagination,
  ProcessingSpinner,
  Text,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useAddBookingsModal } from 'octiv-context/AddBookingsModal';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useUserPackageModal } from 'octiv-context/UserPackageModal';
import { usePaging } from 'octiv-hooks/';
import { useClassBookingsCreate } from 'octiv-hooks/requests/ClassBookings';
import { useClassBookingWaitingCreate } from 'octiv-hooks/requests/ClassBookingWaiting';
import {
  useClassDatesFindById,
  useClassDatesFindByIdClassBookingDetails,
} from 'octiv-hooks/requests/ClassDates';
import { useClassesFindById } from 'octiv-hooks/requests/Classes';
import { useLeadsFind } from 'octiv-hooks/requests/Leads';
import { useUsersFind } from 'octiv-hooks/requests/Users';
import { getBookingsData, getFullName } from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { views } from '../../userMember';
import FormNonMember from './FormNonMember';
import FormQuery from './FormQuery';

export default () => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const {
    selectedLocation,
    tenant: { locationOptionsAll },
    isOwner,
  } = useActiveUserTenant();
  const { setUserPackageModal } = useUserPackageModal();
  const { setMemberModal } = useMemberModal();
  const { eventId, onClose, resetAddBookingsModal } = useAddBookingsModal();

  const [paging, setPaging] = usePaging();
  const [pagingLeads, setPagingLeads] = usePaging();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [bookings, setBookings] = useState({});
  const [bookingLoadingId, setBookingLoadingId] = useState(undefined);
  const [query, setQuery] = useState({
    locationId: selectedLocation.id,
    userType: 'member',
    search: undefined,
    sort: 'name',
  });

  const onCloseModal = () => {
    resetAddBookingsModal();
    if (onClose) onClose();
  };

  const classDatesFindById = useClassDatesFindById({ id: eventId });

  const classesFindById = useClassesFindById(
    { id: classDatesFindById.data?.classId },
    { enabled: !!classDatesFindById.data?.classId }
  );

  const packageIds = React.useMemo(
    () => classesFindById.data?.packages?.map((item) => item.id),
    [classesFindById.data?.packages]
  );

  const classDatesFindByIdClassBookingDetails =
    useClassDatesFindByIdClassBookingDetails(
      { id: eventId },
      {
        onSuccess: (response) => {
          setBookings(getBookingsData({ allBookings: response }));
          setBookingLoadingId(undefined);
        },
      }
    );

  const leadsFind = useLeadsFind(
    {
      filter: {
        ...query,
        locationId: query?.locationId,
      },
      include: 'referredBy,classBookings,waiver',
      sort: '-captured',
      paging: pagingLeads,
    },
    {
      enabled: query?.userType === 'lead',
    }
  );
  const usersFind = useUsersFind(
    {
      include: 'userTenant.userPackages',
      filter: {
        statusId: 2,
        userTenantStatusId: 2,
        userTenantLocationId: query.locationId,
        search: query.search,
        ...(query.userType === 'staff'
          ? { userTenantTypeId: '2,3,5,6,8' }
          : query.userType === 'member'
          ? {
              userTenantTypeId: '4',
            }
          : {
              // Leads
              userTenantTypeId: '9',
            }),
      },
      ...(query.userType === 'member'
        ? {
            append: `user_tenant_has_sessions_available_for_date:${classDatesFindById.data?.date}`,
          }
        : {}),
      paging,
    },
    {
      enabled:
        !!classDatesFindById.data?.date &&
        !['nonMember', 'lead'].includes(query.userType),
    }
  );

  const onBookComplete = {
    onSuccess: classDatesFindByIdClassBookingDetails.refetch,
    onError: () => setBookingLoadingId(undefined),
    meta: {
      useOnSuccessToast: true,
    },
  };

  const classBookingsCreate = useClassBookingsCreate(onBookComplete);

  const classBookingWaitingCreate =
    useClassBookingWaitingCreate(onBookComplete);

  const onBook = ({ type, id, name, email }) => {
    setBookingLoadingId(id);

    if (type === 'booking') {
      classBookingsCreate.mutate({
        classDateId: eventId,
        ...(query.userType === 'member' || query.userType === 'staff'
          ? { userId: id }
          : query.userType === 'lead'
          ? { leadId: id }
          : query.userType === 'nonMember'
          ? { nonMember: { name, email } }
          : {}),
      });
    } else {
      classBookingWaitingCreate.mutate({ classDateId: eventId, userId: id });
    }
  };

  useEffect(() => {
    let filtered =
      (query?.userType === 'lead'
        ? leadsFind.data?.data
        : usersFind.data?.data) || [];

    if (
      filtered[0] &&
      query.userType === 'member' &&
      !!packageIds?.[0] &&
      classDatesFindById.data?.date
    ) {
      filtered.forEach((user, index) => {
        const hasPackage = user?.userTenant?.userPackages?.some(
          (userPackage) =>
            packageIds.includes(userPackage.package.id) &&
            userPackage.startDate <= classDatesFindById.data?.date &&
            (!userPackage.endDate ||
              userPackage.endDate >= classDatesFindById.data?.date)
        );

        filtered[index].isVisibleBuyPackage = !hasPackage;
      });
    }

    if (
      filtered[0] &&
      query.userType === 'lead' &&
      !!packageIds?.[0] &&
      classDatesFindById.data?.date
    ) {
      filtered.forEach((user, index) => {
        const hasPackage = user?.userTenant?.userPackages?.some(
          (userPackage) =>
            packageIds.includes(userPackage.package.id) &&
            userPackage.startDate <= classDatesFindById.data?.date &&
            (!userPackage.endDate ||
              userPackage.endDate >= classDatesFindById.data?.date)
        );

        filtered[index].isVisibleBuyPackage = !hasPackage;
      });
    }

    if (filtered[0] && bookings?.bookedWaitingList) {
      bookings.bookedWaitingList.forEach((booking) => {
        filtered = filtered.filter(
          (user) =>
            user.id !== booking.userId && user.user?.id !== booking.userId
        );
      });
    }

    setFilteredUsers(filtered);
  }, [
    query.userType,
    usersFind.data?.data,
    leadsFind.data?.data,
    packageIds,
    bookings?.bookedWaitingList,
    classDatesFindById.data?.date,
  ]);

  const isFetching =
    classDatesFindById.isFetching ||
    (classDatesFindByIdClassBookingDetails.isFetching && !filteredUsers[0]) ||
    usersFind.isFetching ||
    leadsFind.isFetching;

  const isFullyBooked = bookings.bookedCount >= classDatesFindById.data?.limit;

  return (
    <Modal
      isSidebar
      title={`${t('addBookings')}${
        classDatesFindById.data?.date && bookings.bookedCount !== undefined
          ? ` (${bookings.bookedCount}/${classDatesFindById.data?.limit})`
          : ''
      }`}
      onClose={onCloseModal}
    >
      {isFullyBooked && (
        <Alert
          mb={4}
          subtitle={t('onlyMembersCanBeAddedToWaitingList')}
          title={t('eventFullyBooked')}
          variant='info'
        />
      )}

      <FormQuery
        initialValues={query}
        isFetching={isFetching}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Box height={2} />

      {query.userType === 'nonMember' ? (
        <FormNonMember
          isLoading={classBookingsCreate.isLoading}
          onSubmit={(values, { resetForm }) => {
            onBook({ type: 'booking', ...values });
            resetForm();
          }}
        />
      ) : isFetching ? (
        <ProcessingSpinner m='auto' mt={4} />
      ) : (
        <>
          <Pagination
            isPaginationAllHidden
            isLoading={leadsFind?.isFetching || usersFind?.isFetching}
            paging={
              query?.userType === 'lead'
                ? leadsFind?.data?.paging
                : usersFind.data?.paging
            }
            setPaging={query?.userType === 'lead' ? setPagingLeads : setPaging}
          />
          {filteredUsers.map((item, index) => {
            const hasPackage =
              query.userType === 'member' && !item.isVisibleBuyPackage;

            const canBook =
              query.userType !== 'member' ||
              item.userTenant.hasSessionsAvailableForDate ||
              (hasPackage &&
                (hasAccess.featureSchedulingOverbookMember || isOwner));

            return (
              <Box key={item.id}>
                {index > 0 && index !== filteredUsers.length && (
                  <Divider hasLine />
                )}

                <Box
                  isFlex
                  alignItems='center'
                  height={12}
                  justifyContent='space-between'
                >
                  <Box>
                    <Box
                      alignItems='center'
                      display='flex'
                      flexDirection='row'
                      marginLeft={item.source === 'discovery' ? '-1.2rem' : 0}
                    >
                      {item.source === 'discovery' && (
                        <Image
                          alt={t('discoveryLogo')}
                          backgroundSize='contain'
                          height={4}
                          mr={1}
                          src={Images.vitalityLogomark}
                          width={4}
                        />
                      )}
                      <Text color={item.isOverdue ? 'danger' : undefined}>
                        {getFullName(item)}
                      </Text>
                    </Box>

                    {hasPackage &&
                      !item.userTenant.hasSessionsAvailableForDate && (
                        <Text as='span'>
                          <Text
                            as='span'
                            color='danger'
                            textDecoration='underline'
                            variant='tiny'
                          >
                            {t('noSessionsRemaining')}
                          </Text>

                          {hasAccess.featureMembersManagePackages && (
                            <>
                              <Text as='span' color='grey3' variant='tiny'>
                                {` - `}
                              </Text>

                              <Text
                                as='span'
                                color='primary'
                                variant='tiny'
                                onClick={() =>
                                  setMemberModal({
                                    userTenantId: item?.userTenant?.id,
                                    initialView: views.PACKAGES,
                                    onClose: usersFind.refetch,
                                  })
                                }
                              >
                                {t('managePackages')}
                              </Text>
                            </>
                          )}
                        </Text>
                      )}
                  </Box>

                  <Box isFlex>
                    {!hasPackage &&
                    !['lead', 'staff'].includes(query?.userType) ? (
                      <Button
                        color='primary'
                        isDisabled={!!bookingLoadingId}
                        minWidth={0}
                        mr={'1rem'}
                        scale='small'
                        text={t('addPackage')}
                        onClick={() => {
                          setUserPackageModal({
                            userId: item.id,
                            userTenantId: item?.userTenant?.id,
                            userLocationId: query.locationId,
                            validPackageIds: packageIds,
                            onClose: usersFind.refetch,
                          });
                        }}
                      />
                    ) : null}
                    {bookingLoadingId === item.id ? (
                      <ProcessingSpinner />
                    ) : canBook || !(hasPackage && isOwner) ? (
                      <>
                        <Button
                          hasBorder
                          color='primary'
                          isDisabled={!!bookingLoadingId}
                          minWidth={0}
                          scale='small'
                          text={t('book')}
                          onClick={() =>
                            onBook({
                              type: 'booking',
                              id: item.user?.id || item.id,
                              isLead: query?.userType === 'lead',
                            })
                          }
                        />

                        {isFullyBooked && query.userType === 'member' && (
                          <Button
                            hasBorder
                            color='grey1'
                            isDisabled={!!bookingLoadingId}
                            minWidth={0}
                            ml={2}
                            scale='small'
                            text={t('waitingList')}
                            onClick={() =>
                              onBook({ type: 'waitingList', id: item.id })
                            }
                          />
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Pagination
            isPaginationAllHidden
            isLoading={leadsFind?.isFetching || usersFind?.isFetching}
            paging={
              query?.userType === 'lead'
                ? leadsFind?.data?.paging
                : usersFind.data?.paging
            }
            setPaging={query?.userType === 'lead' ? setPagingLeads : setPaging}
          />
        </>
      )}
    </Modal>
  );
};
