import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        smsStatus: initialValues.smsStatus || undefined,
        emailStatus: initialValues.emailStatus || undefined,
        replyTo: initialValues.replyTo || undefined,
        senderName: initialValues.senderName || undefined,
        emailSignature: initialValues.emailSignature || undefined,
        // TODO: check what this field name should be for the image upload request
        logo: initialValues.fileAbsolutePath || undefined,
      }}
      validationSchema={Yup.object().shape({
        smsStatus: Yup.string().required(t('required')),
        emailStatus: Yup.string().required(t('required')),
        replyTo: Yup.string().email(t('invalidEmail')).required(t('required')),
        senderName: Yup.string().required(t('required')),
        emailSignature: Yup.string().required(t('required')),
        logo: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                isSelect
                label={t('sendSmsNotifications')}
                name='smsStatus'
                options={[
                  {
                    label: t('enabled'),
                    value: 'enabled',
                  },
                  {
                    label: t('disabled'),
                    value: 'disabled',
                  },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('sendEmailNotifications')}
                name='emailStatus'
                options={[
                  {
                    label: t('enabled'),
                    value: 'enabled',
                  },
                  {
                    label: t('disabled'),
                    value: 'disabled',
                  },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field label={t('replyToEmailAddress')} name='replyTo' />
            </Col>

            <Col md={6}>
              <Field label={t('senderName')} name='senderName' />
            </Col>

            <Col>
              <Field isEditor label={t('signature')} name='emailSignature' />
            </Col>

            <Col>
              <Field isDrop label={t('locationLogo')} name='logo' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
