import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues,
  isFetchingUsersTrainers,
  usersTrainers,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        classDateIds: initialValues.classDateIds,
        headTrainerUserTenantId: undefined,
      }}
      validationSchema={Yup.object().shape({
        classDateIds: Yup.array().required(t('required')).min(1, t('required')),
        headTrainerUserTenantId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                isLoading={isFetchingUsersTrainers}
                label={t('trainer')}
                name='headTrainerUserTenantId'
                options={usersTrainers.map((item) => {
                  return {
                    label: getFullName(item),
                    value: item.id,
                  };
                })}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
