import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isCreate,
  isLoading,
  locationOptions,
  productCategories,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        ...(isCreate
          ? { locationIds: [] }
          : { locationId: initialValues.locationId }),
        name: initialValues.name || undefined,
        sku: initialValues.sku || '',
        costPrice: initialValues.costPrice || 0,
        sellingPrice: initialValues.sellingPrice || 0,
        vat: initialValues.vat || '',
        hasLimitedStock:
          initialValues.stockLevel !== undefined
            ? initialValues.stockLevel !== -1
              ? 1
              : 0
            : 0,
        stockLevel:
          initialValues.stockLevel === -1
            ? -1
            : initialValues.stockLevel || undefined,
        categoryType: 'select',
        category: initialValues.category || undefined,
        description: initialValues.description || undefined,
        image: initialValues.imageUrl || undefined,
      }}
      validationSchema={Yup.object().shape({
        ...(isCreate
          ? {
              locationIds: Yup.array()
                .required(t('required'))
                .min(1, t('required')),
            }
          : { locationId: Yup.string().required(t('required')) }),
        name: Yup.string().required(t('required')),
        sku: Yup.string(),
        costPrice: Yup.string().required(t('required')),
        sellingPrice: Yup.string().required(t('required')),
        vat: Yup.string(),
        hasLimitedStock: Yup.number().required(t('required')),
        stockLevel: Yup.string(),
        categoryType: Yup.string().required(t('required')),
        category: Yup.string().required(t('required')),
        description: Yup.string(),
        image: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        values: { categoryType, hasLimitedStock },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            {isCreate ? (
              <Col>
                <Field
                  isArray
                  isCheckbox
                  isHorizontal
                  label={t('whichLocationsSellThisProduct')}
                  name='locationIds'
                  options={locationOptions}
                />
              </Col>
            ) : (
              <Col lg={3} md={6}>
                <Field
                  isSelect
                  label={t('whichLocationSellsThisProduct')}
                  name='locationId'
                  options={locationOptions}
                />
              </Col>
            )}

            <Col lg={3} md={6}>
              <Field label={t('name')} name='name' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('sku')} name='sku' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('costPrice')} name='costPrice' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('retailPrice')} name='sellingPrice' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('vatPercentage')} name='vat' />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                label={t('stockType')}
                name='hasLimitedStock'
                options={[
                  { label: t('limitedStock'), value: 1 },
                  { label: t('unlimitedStock'), value: 0 },
                ]}
              />
            </Col>

            {!!hasLimitedStock && (
              <Col lg={3} md={6}>
                <Field label={t('stockQuantity')} name='stockLevel' />
              </Col>
            )}

            <Col>
              <Field
                isHorizontal
                isRadio
                doFinally={() => {
                  setFieldTouched('category', false);
                  setFieldValue('category', undefined);
                }}
                label={t('productCategory')}
                name='categoryType'
                options={[
                  { label: t('selectFromCurrent'), value: 'select' },
                  { label: t('createNewCategory'), value: 'create' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              {categoryType === 'select' ? (
                <Field
                  isSelect
                  label={t('selectCategory')}
                  name='category'
                  options={productCategories?.map((productCategory) => ({
                    label: productCategory,
                    value: productCategory,
                  }))}
                />
              ) : (
                <Field label={t('newCategoryName')} name='category' />
              )}
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col>
              <Field isDrop label={t('productImage')} name='image' />
            </Col>
          </Row>

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
