import { Container, Modal, Text } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { useFinancesFindGoCardlessMandatesImport } from 'octiv-hooks/requests/Finances';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [locations, setLocations] = useState();
  const [locationId, setLocationId] = useState(undefined);
  const [resetForm, setResetForm] = useState(() => {});

  const { isFetching: isFetchingLocations } = useLocationsFind(
    {
      filter: { paymentGatewayId: 5 },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        const tempLocations = [];

        response?.data.forEach((location) => {
          const foundIndex = tempLocations.findIndex(
            (item) => item?.tenant?.id === location?.tenant?.id
          );

          if (foundIndex > -1) {
            tempLocations[foundIndex].locations.push(location);
          } else {
            tempLocations.push({
              tenant: location.tenant,
              locations: [location],
            });
          }
        });

        setLocations(tempLocations);
      },
    }
  );

  const {
    isFetching: isFetchingGoCardlessMandatesImport,
    mutate: putGoCardlessMandatesImport,
  } = useFinancesFindGoCardlessMandatesImport({
    enabled: locationId !== undefined,
    onSuccess: (response) => {
      resetForm?.();
      setToggle({ data: response });
    },
  });

  const onSubmitForm = (values, { resetForm: resetFormFunc }) => {
    setResetForm(resetFormFunc);
    setLocationId(values.locationId);
    putGoCardlessMandatesImport({
      filter: values,
    });
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('responseData')} onClose={resetToggle}>
          <Text isBold variant='heading'>
            {toggle.data?.metaData?.allMandatesCount}
          </Text>

          <Text color='grey1' mt={1}>
            {toggle.data?.metaData?.validMandatesCount}
          </Text>

          <Text color='grey1' mt={1}>
            {toggle.data?.metaData?.createdMandatesCount}
          </Text>

          <Text color='grey1' mt={1}>
            {toggle.data?.metaData?.updatedMandatesCount}
          </Text>

          <Text isBold mt={4} variant='heading'>
            {`${t('results')}:`}
          </Text>

          {toggle.data?.results ? (
            toggle.data.results.map((item) => (
              <Text
                color={
                  item.includes('Mandate created for')
                    ? 'success'
                    : item.includes('Mandate updated for')
                    ? 'info'
                    : item.includes('Mandate not created for')
                    ? 'danger'
                    : 'grey1'
                }
                key={item}
                mt={1}
              >
                {item}
              </Text>
            ))
          ) : (
            <Text color='grey1'>{t('noResults')}</Text>
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('goCardlessMandatesImport'),
          breadcrumbs: [t('accounts')],
        }}
        isLoading={isFetchingLocations || isFetchingGoCardlessMandatesImport}
      >
        <Form
          isFetchingLocations={isFetchingLocations}
          isLoading={isFetchingLocations}
          locations={locations || []}
          onSubmit={onSubmitForm}
        />
      </Container>
    </>
  );
};
