import { Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useCrmNotificationsFind,
  useCrmNotificationsUpdate,
} from 'octiv-hooks/requests/CrmNotifications';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Placeholders from './Placeholders';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [settings, setSettings] = useState([]);

  const { isFetching: isFetchingSettings, refetch: getSettingsRequest } =
    useCrmNotificationsFind(
      {
        filter: { perPage: -1 },
      },
      {
        onSuccess: (response) => {
          const data = [];

          response?.data?.map((notification) => {
            const { placeholders } =
              Placeholders.find(
                (placeholder) =>
                  placeholder.name === notification.name &&
                  placeholder.description === notification.description
              ) || {};

            return data.push({ ...notification, placeholders });
          });

          setSettings(data);
        },
      }
    );

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useCrmNotificationsUpdate({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const handleSubmit = (values) =>
    putSettingsRequest({ id: toggle.data.id, ...values });

  return (
    <>
      {toggle.isVisible && (
        <Modal isSidebar title={toggle.data?.name} onClose={resetToggle}>
          <Form
            initialValues={toggle.data}
            isLoading={isUpdatingSettings}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}

      <Setting title={t('notifications')}>
        <Table
          hasAlternateBackground
          data={isFetchingSettings ? [] : settings}
          isLoading={isFetchingSettings}
          onClickAction={setToggle}
        />
      </Setting>
    </>
  );
};
