import { Box, Button, Container, Divider, Modal } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useRegionsDelete,
  useRegionsFind,
  useRegionsUpdateActivateDeactivate,
} from 'octiv-hooks/requests/Regions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Region from './region';
import TableRegions from './TableRegions';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const [paging, setPaging, resetPaging] = usePaging();
  const [query, setQuery] = useState({
    isActive: true,
    include: 'currencies,timezones',
  });

  const {
    data: regions,
    isFetching: isFetchingRegions,
    refetch: getRegionsRequest,
  } = useRegionsFind({
    filter: query,
    paging,
    include: 'timezones,currencies',
  });

  const { isLoading: isUpdatingRegionStatus, mutate: putRegionStatusRequest } =
    useRegionsUpdateActivateDeactivate({
      onSuccess: getRegionsRequest,
    });

  const { isLoading: isDeletingRegion, mutate: deleteRegionRequest } =
    useRegionsDelete({
      onSuccess: getRegionsRequest,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: regions?.data.find((item) => item.id === id),
        });
        break;

      case 'activate':
        putRegionStatusRequest({ id });
        break;

      case 'deactivate':
        if (window.confirm(t('areYouSureProceed'))) {
          putRegionStatusRequest({ id });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteRegionRequest({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${
            toggle.type === 'create' ? t('create') : t('update')
          } Region`}
          onClose={resetToggle}
        >
          <Region
            region={toggle.data}
            onSubmitSuccess={() => {
              resetToggle();
              getRegionsRequest();
            }}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('regions'),
          breadcrumbs: [t('settings')],
          children: (
            <Box ml='auto'>
              <Button
                text='Create Region'
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={
          isFetchingRegions || isUpdatingRegionStatus || isDeletingRegion
        }
      >
        <FormQuery
          initialValues={query}
          onSubmit={(values) => {
            if (values?.isActive !== query?.isActive) {
              resetPaging();
            }
            setQuery(values);
          }}
        />

        <Divider pb={4} />

        <TableRegions
          data={isFetchingRegions ? [] : regions.data}
          isLoading={isFetchingRegions}
          paging={regions?.paging}
          query={query}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
