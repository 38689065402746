import { Box, Button, Container, Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useLocationCategoriesCreate,
  useLocationCategoriesDelete,
  useLocationCategoriesFind,
  useLocationCategoriesUpdate,
} from 'octiv-hooks/requests/LocationCategories';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormCreateUpdate from './FormCreateUpdate';
import FormDelete from './FormDelete';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const {
    data: locationCategories,
    isFetching: isFetchingLocationCategories,
    refetch: getLocationCategoriesRequest,
  } = useLocationCategoriesFind();

  const onSubmitSuccess = () => {
    getLocationCategoriesRequest();
    resetToggle();
  };

  const {
    isLoading: isCreatingLocationCategory,
    mutate: postLocationCategoryRequest,
  } = useLocationCategoriesCreate({
    onSuccess: onSubmitSuccess,
  });

  const {
    isLoading: isUpdatingLocationCategory,
    mutate: putLocationCategoryRequest,
  } = useLocationCategoriesUpdate({
    onSuccess: onSubmitSuccess,
  });

  const {
    isLoading: isDeletingLocationCategory,
    mutate: deleteLocationCategoryRequest,
  } = useLocationCategoriesDelete({
    onSuccess: onSubmitSuccess,
  });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: locationCategories?.data.find((item) => item.id === id),
        });
        break;

      case 'delete':
        setToggle({
          type: 'delete',
          data: locationCategories?.data?.find((item) => item.id === id),
        });
        break;

      default:
        break;
    }
  };

  const onSubmitRequest = (values) => {
    if (toggle.type === 'create') {
      postLocationCategoryRequest(values);
    } else if (toggle.type === 'update') {
      putLocationCategoryRequest(values);
    } else {
      deleteLocationCategoryRequest(values);
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${
            // TODO: translations
            toggle.type === 'create'
              ? 'Create'
              : toggle.type === 'update'
              ? 'Update'
              : 'Delete'
          } Location Category`}
          onClose={resetToggle}
        >
          {toggle.type === 'delete' ? (
            <FormDelete
              initialValues={toggle.data}
              isLoading={isDeletingLocationCategory}
              locationCategories={locationCategories?.data || []}
              onSubmit={onSubmitRequest}
            />
          ) : (
            <FormCreateUpdate
              initialValues={toggle.data}
              isLoading={
                isCreatingLocationCategory || isUpdatingLocationCategory
              }
              onSubmit={onSubmitRequest}
            />
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('locationCategories'),
          breadcrumbs: [t('settings')],
          children: (
            <Box ml='auto'>
              <Button
                text='Create Location Category'
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={
          isFetchingLocationCategories ||
          isUpdatingLocationCategory ||
          isDeletingLocationCategory
        }
      >
        <Table
          data={locationCategories?.data || []}
          isLoading={isFetchingLocationCategories}
          onClickAction={onClickAction}
          onClickRow={({ original }) =>
            onClickAction({
              action: 'edit',
              id: original.id,
            })
          }
        />
      </Container>
    </>
  );
};
