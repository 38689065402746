import moment from 'moment';
import { BulkActions, Container, Divider, Modal } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useClassDatesDeleteBulkDelete,
  useClassDatesFind,
  useClassDatesUpdateBulkCoachChange,
  useClassDatesUpdateBulkLimitChange,
} from 'octiv-hooks/requests/ClassDates';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import {
  getDateYearMonthDay,
  getSelectedRows,
} from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormCapacity from './FormCapacity';
import FormQuery from './FormQuery';
import FormTrainer from './FormTrainer';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [paging, setPaging] = usePaging();

  const hasAccess = useContext(AccessContext);
  const {
    id,
    isTrainer,
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const hasFullAccess = isTrainer
    ? hasAccess.featureSchedulingBulkActions
    : true;

  const [toggleFormTrainer, setToggleFormTrainer, resetToggleFormTrainer] =
    useToggle();
  const [toggleFormCapacity, setToggleFormCapacity, resetToggleFormCapacity] =
    useToggle();

  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState({
    isSession: !hasFullAccess,
    locationId: selectedLocation?.id,
    headTrainerUserTenantId: hasFullAccess ? undefined : id,
    supportingTrainerUserTenantId: undefined,
    startDate: getDateYearMonthDay({ canFallback: true }),
    endDate: getDateYearMonthDay({ date: moment().add(1, 'week') }),
  });
  const selectedData = getSelectedRows({
    data: events?.length > 0 ? events : [],
  });

  const { data: usersTrainers, isFetching: isFetchingUsersTrainers } =
    useUsersFindStaff({
      filter: {
        locationId: query.locationId,
        isMinimalData: true,
      },
      paging: { perPage: -1 },
    });

  const classDatesFind = useClassDatesFind(
    {
      filter: {
        ...query,
        between: `${query.startDate},${query.endDate}`,
      },
      paging,
    },
    {
      onSuccess: (response) => {
        // TODO: make sure that the backend removes inactive items from the list
        setEvents(response?.data || []);
        // setEvents(response?.data?.filter((event) => event?.isActive) || []);
      },
    }
  );

  const {
    isLoading: isUpdatingClassDateBulkChangeTrainer,
    mutate: putClassDateBulkChangeTrainer,
  } = useClassDatesUpdateBulkCoachChange({
    onSuccess: () => {
      resetToggleFormTrainer();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingClassDateBulkChangeCapacity,
    mutate: putClassDateBulkChangeCapacity,
  } = useClassDatesUpdateBulkLimitChange({
    onSuccess: () => {
      resetToggleFormCapacity();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isLoading: isDeletingClassDateBulk, mutate: deleteClassDateBulk } =
    useClassDatesDeleteBulkDelete({
      onSuccess: () => {
        classDatesFind.refetch();
        resetToggleFormCapacity();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onClickAction = ({ action }) => {
    switch (action) {
      case 'changeTrainer':
        setToggleFormTrainer({
          data: {
            classDateIds: selectedData?.map((item) => item.id),
          },
        });
        break;

      case 'changeClassCapacity':
        setToggleFormCapacity({
          data: {
            classDateIds: selectedData?.map((item) => item.id),
          },
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteClassDateBulk({
            classDateIds: selectedData?.map((item) => item.id),
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleFormTrainer.isVisible && (
        <Modal
          isSidebar
          title={t('changeTrainer')}
          onClose={resetToggleFormTrainer}
        >
          <FormTrainer
            initialValues={toggleFormTrainer.data}
            isFetchingUsersTrainers={isFetchingUsersTrainers}
            isLoading={isUpdatingClassDateBulkChangeTrainer}
            usersTrainers={usersTrainers?.data || []}
            onSubmit={(values) => {
              putClassDateBulkChangeTrainer(values);
            }}
          />
        </Modal>
      )}

      {toggleFormCapacity.isVisible && (
        <Modal
          isSidebar
          title={t('changeCapacity')}
          onClose={resetToggleFormCapacity}
        >
          <FormCapacity
            initialValues={toggleFormCapacity.data}
            isLoading={isUpdatingClassDateBulkChangeCapacity}
            onSubmit={(values) => putClassDateBulkChangeCapacity(values)}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('bulkActions'),
          breadcrumbs: [t('scheduling')],
        }}
        isLoading={classDatesFind.isFetching || isDeletingClassDateBulk}
      >
        <FormQuery
          hasFullAccess={hasFullAccess}
          initialValues={query}
          isFetchingUsersTrainers={isFetchingUsersTrainers}
          locationOptions={locationOptions || []}
          usersTrainers={usersTrainers?.data || []}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <BulkActions
          data={selectedData || []}
          mb={4}
          options={[
            {
              icon: 'person',
              title: t('changeTrainer'),
              value: 'changeTrainer',
            },
            {
              icon: 'tag',
              title: t('changeCapacity'),
              value: 'changeClassCapacity',
            },
            {
              icon: 'delete',
              title: t('delete'),
              value: 'delete',
              color: 'danger',
            },
          ]}
          onClick={({ value }) => onClickAction({ action: value })}
        />

        <Table
          data={classDatesFind.isFetching ? [] : events}
          isLoading={classDatesFind.isFetching}
          paging={classDatesFind?.data?.paging}
          query={query}
          setPaging={setPaging}
          setSelected={setEvents}
        />
      </Container>
    </>
  );
};
