import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useCrmNotificationsFind = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['crmNotifications', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-notifications`,
        params: {
          page: filter.page,
          perPage: filter.perPage,
          filter: { tenantId },
        },
      }),
    options
  );
};

export const useCrmNotificationsFindEmailSubscriptions = (input, options) => {
  const { userId } = input || {};

  return useQuery(
    ['crmNotifications', 'findEmailSubscriptions', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-notifications/${userId}/email-subscriptions`,
      }),
    options
  );
};

export const useCrmNotificationsFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['crmNotifications', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-notifications/${id}`,
      }),
    options
  );
};

export const useCrmNotificationsUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/crm-notifications/${id}`,
        data: {
          subject: data.subject,
          content: data.content,
          status: data.status,
          cc: data.cc,
          tenantId: data?.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crmNotifications'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmNotificationsUpdateUnsubscribe = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/crm-notifications/${id}/unsubscribe`,
        data: { userId: data.userId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crmNotifications'] });

      options?.onSuccess?.(...res);
    },
  });
};
