import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { measures } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  exerciseCategories,
  isFetchingExerciseCategories,
  initialValues = {},
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        exerciseCategoryId: initialValues.exerciseCategoryId || undefined,
        resourceUrl: initialValues.resourceUrl || undefined,
        description: initialValues.description || undefined,
        measuringUnitId: initialValues.measuringUnitId || undefined,
        rxMale: initialValues.rxMale || undefined,
        rxFemale: initialValues.rxFemale || undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        exerciseCategoryId: Yup.string().required(t('required')),
        resourceUrl: Yup.string().url(),
        description: Yup.string(),
        measuringUnitId: Yup.string().required(t('required')),
        rxMale: Yup.string(),
        rxFemale: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field label={t('name')} name='name' />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                isLoading={isFetchingExerciseCategories}
                label={t('category')}
                name='exerciseCategoryId'
                options={exerciseCategories.map((exerciseCategory) => ({
                  label: exerciseCategory.name,
                  value: exerciseCategory.id,
                }))}
              />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                isSelect
                label={t('measure')}
                name='measuringUnitId'
                options={measures.map((measure) => ({
                  label: measure.description,
                  value: measure.measureId,
                }))}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('rxMale')} name='rxMale' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('rxFemale')} name='rxFemale' />
            </Col>

            <Col>
              <Field label={t('resourceUrl')} name='resourceUrl' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
