import { Formik } from 'formik';
import { Alert, Box, Button, Col, Field, Row, Step } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import TableCsvExampleUsers from './TableCsvExampleUsers';

export default ({ isLoading, tenants, isFetchingTenants, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        type: undefined,
        tenantId: undefined,
        csvFile: undefined,
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(t('required')),
        tenantId: Yup.string().required(t('required')),
        csvFile: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, values: { type } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                label='Import Type'
                name='type'
                options={[
                  {
                    label: t('users'),
                    value: 'users',
                  },
                  {
                    label: 'Bank Details',
                    value: 'bankDetails',
                  },
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                isLoading={isFetchingTenants}
                label={t('tenant')}
                name='tenantId'
                options={tenants.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            {type === 'users' && (
              <Col>
                <Alert
                  mb={4}
                  // TODO: translations
                  subtitle='The format of the file you upload must be .csv (comma separated value) and adhere to the guidelines provided below.'
                  title={t('pleaseNote')}
                  variant='warning'
                />

                <Step
                  boxContainerProps={{ mb: 4 }}
                  mb={2}
                  title='Column order must match the example below:'
                  value='1'
                >
                  <TableCsvExampleUsers
                    hasAlternateBackground
                    data={[
                      {
                        locationName: 'Octiv Fitness',
                        package: 'Unlimited',
                        packageStartDate: '2021-02-01',
                        packageEndDate: '2021-02-31',
                        memberId: '12345',
                        programme: 'CrossFit',
                        name: 'John',
                        surname: 'Doe',
                        gender: 'm',
                        emailAddress: 'john@gmail.com',
                        mobileNumber: '0831234567',
                        dateOfBirth: '1990-12-25',
                        contractStart: '2021-01-01',
                        contractEnd: '2021-12-31',
                        sessions: '3',
                        notes: '',
                        goCardless: '',
                        goCardlessDebitDay: '',
                        dateJoined: '2021-01-01',
                        memberSocialSecurityNumber: '7610206532197',
                        memberAddress:
                          '128 Beach road, Sea Point, Cape Town, South Africa',
                      },
                    ]}
                  />
                </Step>

                <Step
                  boxContainerProps={{ mb: 4 }}
                  mb={2}
                  title='Specifics:'
                  value='2'
                >
                  <Box color='font'>
                    <ul>
                      <li>
                        Include column headers in the file or your first line
                        item will not be uploaded.
                      </li>
                      <li>* indicates a required field.</li>
                      <li>
                        Location Name: must match the location name as it is on
                        Octiv.
                      </li>
                      <li>
                        Package: must match the package name as it is on Octiv.
                      </li>
                      <li>
                        Package Start Date: must match the format of YYYY-MM-DD.
                      </li>
                      <li>
                        Package End Date: must match the format of YYYY-MM-DD.
                      </li>
                      <li>
                        Programme: must match the programme name as it is on
                        Octiv.
                      </li>
                      <li>
                        Gender: must be either &quot;m&quot; or &quot;f&quot;.
                      </li>
                      <li>
                        Date of Birth: must match the format of YYYY-MM-DD.
                      </li>
                      <li>
                        Contract Start: must match the format of YYYY-MM-DD.
                      </li>
                      <li>
                        Contract End: must match the format of YYYY-MM-DD.
                      </li>
                      <li>
                        GoCardless: must be &quot;GoCardless&quot; if setting up
                        for GoCardless debit.
                      </li>
                    </ul>
                  </Box>
                </Step>
              </Col>
            )}

            <Col>
              <Field
                isDrop
                useFileIcon
                accept={{
                  'text/csv': ['.csv'],
                  'application/vnd.ms-excel': ['.xls'],
                }}
                label={t('csvFile')}
                name='csvFile'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
