import { Box, Icon } from 'octiv-components';
import React from 'react';

const ToggleItems = ({
  activeIndex,
  iconProps,
  onClick,
  options,
  textProps,
  ...props
}) => {
  const isPrevDisabled = activeIndex < 1;
  const isNextDisabled = activeIndex === options.length - 1;

  return (
    <Box isFlex {...props}>
      <Box
        isDisabled={isPrevDisabled}
        onClick={
          isPrevDisabled ? undefined : () => onClick({ index: activeIndex - 1 })
        }
      >
        <Icon name='arrow_back' />
      </Box>

      {options.map((option, index) => {
        const isActive = activeIndex === index;
        return (
          <Box
            key={`${index.toString()}`}
            onClick={isActive ? undefined : () => onClick({ index })}
          >
            <Box
              hasRadius
              bg={isActive ? 'primary' : 'grey2'}
              mx={1}
              my={2}
              size={2}
            />
          </Box>
        );
      })}

      <Box
        isDisabled={isNextDisabled}
        onClick={
          isNextDisabled ? undefined : () => onClick({ index: activeIndex + 1 })
        }
      >
        <Icon name='arrow_forward' />
      </Box>
    </Box>
  );
};

export default ToggleItems;
