import { Images } from 'octiv-assets';
import { isValidUrl } from 'octiv-utilities/Functions';
import React from 'react';

import { Box, Text } from '..';

const Image = ({
  isLead,
  isNonMember,
  placeholder,
  size,
  src,
  usePlaceholderUser,
  ...props
}) => {
  let placeholderImage = placeholder;
  if (usePlaceholderUser && !(isLead || isNonMember)) {
    placeholderImage = Images.user;
  }

  return (
    <Box
      backgroundColor={isLead || isNonMember ? '#d1d1d6' : undefined}
      backgroundImage={
        isLead
          ? null
          : isValidUrl(src)
          ? `url(${src})`
          : placeholderImage
          ? `url(${placeholderImage})`
          : undefined
      }
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      backgroundSize='cover'
      maxHeight={size}
      maxWidth={size}
      minHeight={size}
      minWidth={size}
      {...props}
    >
      {isLead || isNonMember ? (
        <Box
          isFlex
          alignItems='center'
          justifyContent='center'
          maxHeight={size}
          maxWidth={size}
          minHeight={size}
          minWidth={size}
        >
          <Text isBold color='#636366' variant='subheading'>
            {isLead ? 'L' : 'NM'}
          </Text>
        </Box>
      ) : null}
    </Box>
  );
};

export default Image;
