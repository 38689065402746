import { Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateReadableDayMonthYear, toCase } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setInvoices, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'date',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => toCase({ value, isCamelCase: true }),
      },
      {
        Header: t('description'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('amount'),
        id: 'amount',
        Cell: ({
          row: {
            original: { type, invoiceAmount, paymentAmount },
          },
        }) => (
          <Text
            as='span'
            color={
              type === 'payment' || type === 'creditNote' ? 'success' : 'danger'
            }
          >
            {toCurrency({
              value: type === 'payment' ? paymentAmount : invoiceAmount,
            })}
          </Text>
        ),
      },
      {
        Header: t('balance'),
        accessor: 'balance',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, t]
  );

  return (
    <Table
      noDataText={t('noTransactionsForPeriod')}
      {...props}
      columns={tableColumns}
      data={tableData}
    />
  );
};
