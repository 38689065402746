import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingMembers,
  isFetchingPackages,
  locationOptions,
  members,
  packages,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        userTenantStatusId: Yup.string(),
        userId: Yup.string(),
        packageId: Yup.string(),
        startDateFrom: Yup.string(),
        startDateTo: Yup.string(),
        endDateFrom: Yup.string(),
        endDateTo: Yup.string(),
        isActive: Yup.bool(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('memberStatus')}
                name='userTenantStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingMembers}
                label={t('member')}
                name='userId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(members?.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingPackages}
                label={t('package')}
                name='packageId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(packages?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('startDateFrom')}
                name='startDateFrom'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('startDateTo')}
                name='startDateTo'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('endDateFrom')}
                name='endDateFrom'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('endDateTo')}
                name='endDateTo'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='isActive'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('active'), value: 1 },
                  { label: t('deactivated'), value: 0 },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
