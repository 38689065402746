import { Box, Container, Modal, Text } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { useAffiliatesFind } from 'octiv-hooks/requests/Affiliates';
import { useWorkoutsCreateImport } from 'octiv-hooks/requests/Workouts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Form from './Form';
import TableResponseWorkouts from './TableResponseWorkouts';

export default () => {
  const { t } = useTranslation();
  const [toggle, setToggle, resetToggle] = useToggle();
  // TODO: either integrate this type with the above toggle, or find a less semantically confusing term for this
  const [toggleType, setToggleType] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const navigate = useNavigate();

  const workoutsImport = useWorkoutsCreateImport({
    onSuccess: (response) => {
      setToggle({ type: toggleType, data: response });
      setErrorMessages([]);
      navigate('/workouts/manage');
    },
    onError: (error) => {
      const data = JSON.parse(error.message);
      setErrorMessages(data.messageBody.map((item) => item[0]));
      setToggle({
        type: 'errors',
        data: data.messageBody.map((item) => item[0]),
      });
    },
    meta: {
      // TODO: add a success message like:
      // - Upload successful
      // - Your workouts should now appear in the calendar.
      // - instruct
      useOnSuccessToast: true,
    },
  });

  const affiliatesFind = useAffiliatesFind();

  const onSubmitForm = ({ type, ...values }) => {
    setToggleType(type);
    workoutsImport.mutate({
      ...values,
    });
  };

  const toggleDataHasError = !!toggle.data?.[0]?.invalidField;

  return (
    <Container
      appBarProps={{
        title: t('import'),
        breadcrumbs: ['Affiliate Programmes WODs'],
      }}
      isLoading={workoutsImport.isLoading}
    >
      {toggle.isVisible && (
        <Modal
          title={
            toggleDataHasError || errorMessages?.length > 0
              ? 'Errors'
              : 'Response Data'
          }
          onClose={resetToggle}
        >
          {errorMessages?.length > 0 ? (
            <Box>
              <ul>
                {errorMessages?.map((item) => (
                  <Text>
                    <li key={item}>{item}</li>
                  </Text>
                ))}
              </ul>
            </Box>
          ) : (
            <TableResponseWorkouts data={toggle.data} />
          )}
        </Modal>
      )}

      <Form
        affiliates={affiliatesFind.isFetching ? [] : affiliatesFind?.data}
        isLoading={workoutsImport.isLoading}
        onSubmit={onSubmitForm}
      />
    </Container>
  );
};
