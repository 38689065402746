import { Box, Chip, Modal, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useTagsCreate,
  useTagsDelete,
  useTagsFind,
  useTagsUpdate,
} from 'octiv-hooks/requests/Tags';
import { tagsTypes } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const { tenantId } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [paymentProcessorTags, setPaymentProcessorTags] = useState();

  const {
    isFetching: isFetchingPaymentProcessorTags,
    refetch: getPaymentProcessorTagsRequest,
  } = useTagsFind(
    { filter: { type: 'payment_processor', tenantId } },
    {
      onSuccess: (response) =>
        setPaymentProcessorTags(
          response?.data?.filter((item) => !item.isGlobal)
        ),
    }
  );

  const {
    isLoading: isCreatingPaymentProcessorTag,
    mutate: postPaymentProcessorTagRequest,
  } = useTagsCreate({
    onSuccess: () => {
      getPaymentProcessorTagsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingPaymentProcessorTag,
    mutate: putPaymentProcessorTagRequest,
  } = useTagsUpdate({
    onSuccess: () => {
      getPaymentProcessorTagsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isDeletingPaymentProcessorTag,
    mutate: deletePaymentProcessorTagRequest,
  } = useTagsDelete({
    onSuccess: getPaymentProcessorTagsRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      postPaymentProcessorTagRequest({
        ...values,
        type: tagsTypes.PAYMENT_PROCESSOR,
        tenantId,
      });
    } else {
      putPaymentProcessorTagRequest({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: paymentProcessorTags.find((item) => item.id === id),
        });
        break;

      case 'delete':
        deletePaymentProcessorTagRequest({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {isDeletingPaymentProcessorTag && <ProcessingBar />}

      <Setting
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setToggle({ type: 'create' })}
            />
          </Box>
        )}
        title={t('paymentProcessors')}
      >
        {toggle.isVisible && (
          <Modal
            isSidebar
            title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
              'paymentProcessor'
            )}`}
            onClose={resetToggle}
          >
            <Form
              initialValues={toggle.data}
              isLoading={
                isUpdatingPaymentProcessorTag || isCreatingPaymentProcessorTag
              }
              onSubmit={onPutPostRequest}
            />
          </Modal>
        )}

        <Table
          hasAlternateBackground
          data={isFetchingPaymentProcessorTags ? [] : paymentProcessorTags}
          isLoading={isFetchingPaymentProcessorTags}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
