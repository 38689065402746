import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: initialValues.userIds,
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month'),
      }}
      validationSchema={Yup.object().shape({
        userIds: Yup.array().required(t('required')).min(1, t('required')),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field isDate label={t('startDate')} name='startDate' />
            </Col>

            <Col>
              <Field isDate label={t('endDate')} name='endDate' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
