import { Box, Icon, InlineUser, Table, Text, Tooltip } from 'octiv-components';
import {
  getEventString,
  getExercisePart,
  getResult,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  data,
  query,
  exercises,
  hasWorkout,
  onClickAction,
  onClickLogResults,
  ...props
}) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        id: 'athlete',
        Cell: ({
          row: {
            original: { classBooking, ...original },
          },
        }) => (
          <InlineUser
            {...classBooking}
            {...original}
            isVisibleOverdue={false}
          />
        ),
      },
      {
        Header: t('class'),
        accessor: 'classBooking.classDate',
        Cell: ({ value }) => getEventString(value),
      },
      {
        Header: t('results'),
        accessor: 'resultExercises',
        Cell: ({
          row: {
            original: { workoutId, classBooking, resultExercises },
          },
        }) => {
          if (classBooking?.user?.id) {
            return (
              <Box
                hasRadius
                isFlex
                bgHover='grey5'
                flexDirection='column'
                justifyContent='center'
                minHeight={10}
                px={2}
                py={1}
                onClick={() =>
                  onClickLogResults({
                    userId: classBooking.user.id,
                    workoutId,
                    resultExercises,
                  })
                }
              >
                {!resultExercises[0] ? (
                  <Text as='span' color='grey1'>
                    {t('logResult')}
                  </Text>
                ) : (
                  exercises.map((exercise, index) => {
                    const resultExercise = resultExercises.find(
                      (item) => item.exerciseId === exercise.id
                    );

                    // If the workout was updated by an owner or staff member (e.g., changed exercise details),
                    // Show a "Re-Log Result" prompt to indicate the need to re-enter scores for the new exerciseId.
                    if (!resultExercise && exercise.id) {
                      return (
                        <Text
                          as='span'
                          color='grey1'
                          key={`relog-${exercise.id}`}
                        >
                          {t('relogResult')}
                        </Text>
                      );
                    }

                    // If resultExercise exists, display the result for the exercise
                    if (resultExercise) {
                      return (
                        <Box isFlex key={exercise.id}>
                          <Text isBold as='span' color='primary'>
                            {`${
                              exercise.prefix
                                ? exercise.prefix
                                : getExercisePart({ index })
                            }: `}
                            <Text as='span'>
                              {getResult({
                                measureId: exercise.measuringUnit?.id,
                                score: resultExercise.score,
                                isRx: resultExercise.isRx,
                              })}
                            </Text>
                          </Text>
                        </Box>
                      );
                    }
                    return null;
                  })
                )}
              </Box>
            );
          }

          return null;
        },
      },
      {
        Header: t('attendance'),
        accessor: 'classBooking',
        Cell: ({ value }) => (
          <Box isFlex>
            <Icon
              color={value.checkedInAt ? 'success' : 'grey3'}
              name='check_circle'
              px={1}
              onClick={() =>
                onClickAction({
                  action: value.checkedInAt ? 'checkInCancel' : 'checkIn',
                  id: value.id,
                })
              }
            />

            <Icon
              color={value.statusId === 5 ? 'warning' : 'grey3'}
              name='visibility_off'
              px={1}
              onClick={() => onClickAction({ action: 'noShow', id: value.id })}
            />
          </Box>
        ),
      },
      {
        accessor: 'id',
        Cell: ({
          row: {
            original: { classBooking },
          },
        }) => (
          <Tooltip
            options={[{ label: t('cancelBooking'), value: 'cancel' }]}
            onClick={({ value }) =>
              onClickAction({
                action: value,
                id: classBooking.id,
              })
            }
          />
        ),
      },
    ],
    [exercises, onClickAction, onClickLogResults, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(query.classDateId ? ['class'] : []),
      ...(hasWorkout ? [] : ['resultExercises']),
    ],
    [query.classDateId, hasWorkout]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
