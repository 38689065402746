import moment from 'moment';
import { Box, Container, DateStrip, DownloadApp, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMediaQuery } from 'octiv-hooks';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Event from './Event';
import FormQuery from './FormQuery';

export default () => {
  const { t, i18n } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const { xsDown } = useMediaQuery();

  const [date, setDate] = useState(getDateYearMonthDay({ canFallback: true }));
  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    isSession: false,
  });

  const classDates = useClassDatesFind(
    {
      filter: {
        between: `${date},${date}`,
        ...query,
        isSession: Number(query.isSession),
      },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (res) => {
        setEvents(res.data);
      },
    }
  );

  return (
    <Container
      appBarProps={{ title: t('schedule') }}
      isLoading={classDates.isFetching}
    >
      <DownloadApp mb={6} />

      <DateStrip
        activeDate={date}
        mb={6}
        onChange={(value) => setDate(getDateYearMonthDay({ date: value.date }))}
      />

      <Box mb={4}>
        <Text isBold mb={2} variant={xsDown ? 'subheading' : 'heading'}>
          {moment(date)
            .locale(i18n.resolvedLanguage)
            .format('dddd DD MMMM YYYY')}
        </Text>

        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />
      </Box>

      {events[0] ? (
        events?.map((item) => (
          <Event
            data={item}
            key={item.id}
            mb={2}
            onSuccessBook={classDates.refetch}
            onSuccessCancel={classDates.refetch}
          />
        ))
      ) : (
        <Text color='grey1' textAlign='center'>
          {`${classDates.isFetching ? t('fetching') : t('no')} ${
            query.isSession ? t('sessions') : t('classes')
          }`}
        </Text>
      )}
    </Container>
  );
};
