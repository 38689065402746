import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { daysOfMonthStringOptions } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, currencies, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        memberBillingCurrency: initialValues?.memberBillingCurrency
          ? initialValues?.memberBillingCurrency.id
          : undefined,
        deactivateMemberOnContractEnd:
          typeof initialValues?.deactivateMemberOnContractEnd === typeof 1
            ? initialValues?.deactivateMemberOnContractEnd
            : undefined,
        cashMemberInvoiceGenerationDay:
          initialValues?.cashMemberInvoiceGenerationDay || '0',
        cashMemberInvoiceDueDay:
          initialValues?.cashMemberInvoiceDueDay || undefined,
        cashMemberInvoiceStrategy:
          initialValues?.cashMemberInvoiceStrategy || undefined,
      }}
      validationSchema={Yup.object().shape({
        memberBillingCurrency: Yup.string().required(t('required')),
        deactivateMemberOnContractEnd: Yup.bool()
          .required(t('required'))
          .nullable(),
        cashMemberInvoiceGenerationDay: Yup.string().required(t('required')),
        cashMemberInvoiceDueDay: Yup.string().when(
          'cashMemberInvoiceGenerationDay',
          {
            is: (value) => value !== '0',
            then: Yup.string().required(t('required')),
          }
        ),
        cashMemberInvoiceStrategy: Yup.string().when(
          'cashMemberInvoiceGenerationDay',
          {
            is: (value) => value !== '0',
            then: Yup.string().required(t('required')),
          }
        ),
      })}
    >
      {({
        handleSubmit,
        dirty,
        values: { cashMemberInvoiceGenerationDay },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} xl={4}>
              <Field
                isSelect
                label={t('billingCurrency')}
                name='memberBillingCurrency'
                options={currencies.map((currency) => ({
                  label: currency.name,
                  value: currency.id,
                }))}
              />
            </Col>

            <Col md={6} xl={4}>
              <Field
                isSelect
                label={t('deactivateMemberWhenContractEnds')}
                name='deactivateMemberOnContractEnd'
                options={[
                  { label: t('yes'), value: 1 },
                  { label: t('no'), value: 0 },
                ]}
              />
            </Col>

            <Col md={6} xl={4}>
              <Field
                isSelect
                label={t('cashMemberInvoiceGenerationDay')}
                name='cashMemberInvoiceGenerationDay'
                options={[
                  { label: t('doNotGenerate'), value: '0' },
                  ...daysOfMonthStringOptions,
                ]}
              />
            </Col>

            {cashMemberInvoiceGenerationDay !== '0' && (
              <>
                <Col md={6} xl={4}>
                  <Field
                    isSelect
                    label={t('cashMemberInvoiceDueDay')}
                    name='cashMemberInvoiceDueDay'
                    options={daysOfMonthStringOptions}
                  />
                </Col>

                <Col md={6} xl={4}>
                  <Field
                    isSelect
                    label={t('cashMemberInvoiceStrategy')}
                    name='cashMemberInvoiceStrategy'
                    options={[
                      { label: t('generateOnly'), value: 'generate_only' },
                      {
                        label: t('generateAndSend'),
                        value: 'generate_and_send',
                      },
                    ]}
                  />
                </Col>
              </>
            )}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
