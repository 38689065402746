import moment from 'moment';
import { Box, Text } from 'octiv-components';
import { getDateFromTime, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  isPrint,
  date,
  endTime,
  location,
  startTime,
  supportingTrainer,
  trainer,
  tags,
}) => {
  const { i18n } = useTranslation();

  return (
    <Box
      display={isPrint ? 'initial' : ['initial', 'flex']}
      justifyContent='space-between'
    >
      <Box>
        <Text isBold mb={1} variant='subheading'>
          {moment(date).locale(i18n.resolvedLanguage).format('dddd, DD MMMM')}
        </Text>

        <Text color='grey1' mb={1}>
          {`${startTime.slice(0, -3)} - ${endTime.slice(0, -3)} (${moment(
            getDateFromTime({ time: endTime })
          ).diff(moment(getDateFromTime({ time: startTime })), 'minutes')}m)`}
        </Text>

        <Text color='grey1' variant='caption'>
          {location.name}
        </Text>

        {tags?.length > 0 && (
          <Text color='grey1' mt={1} variant='caption'>
            {tags.map((tag) => tag.name).join(', ')}
          </Text>
        )}
      </Box>

      <Box mt={[0, 1]}>
        {trainer && (
          <Text
            isBold
            textAlign={isPrint ? undefined : ['left', 'right']}
            variant='subheading'
          >
            {getFullName(trainer)}
          </Text>
        )}

        {supportingTrainer && (
          <Text
            color='grey1'
            mt={1}
            textAlign={isPrint ? undefined : ['left', 'right']}
          >
            {getFullName(supportingTrainer)}
          </Text>
        )}
      </Box>
    </Box>
  );
};
