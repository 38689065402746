import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        content: initialValues.content || undefined,
        shouldUsersAccept: false,
        viewInEditor: true,
      }}
      validationSchema={Yup.object().shape({
        content: Yup.string().required(t('required')),
        shouldUsersAccept: Yup.bool().required(t('required')),
        viewInEditor: Yup.bool().required(t('required')),
      })}
    >
      {({ handleSubmit, values: { viewInEditor } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isHorizontal
                isRadio
                label='View in:'
                name='viewInEditor'
                options={[
                  {
                    label: 'Text Editor',
                    value: true,
                  },
                  {
                    label: 'Plain Text',
                    value: false,
                  },
                ]}
              />
            </Col>

            <Col className='textarea-large'>
              <Field
                as={viewInEditor ? undefined : 'textarea'}
                isEditor={viewInEditor}
                label={t('content')}
                name='content'
              />
            </Col>

            <Col>
              <Field
                isCheckbox
                name='shouldUsersAccept'
                options={[
                  {
                    label:
                      'Should users need to reaccept the Privacy Policy and Terms Of Use?',
                    value: true,
                  },
                ]}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('update')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
