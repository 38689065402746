import React from 'react';

// If the userTenantId is not provided, a request will be made to get the user with their userTenant by userId
// For example, when fetching injuries a userTenant is not included in the response
// Please note that this is not optimal as it requires 2 requests instead of 1. Always supply the userTenantId if possible
export const MemberModalInitialState = {
  userId: undefined,
  userTenantId: undefined,
  initialView: 'overview',
  onClose: undefined,
  setMemberModal: () => {},
  resetMemberModal: () => {},
};

export const MemberModalContext = React.createContext(MemberModalInitialState);

export const useMemberModal = () => {
  const memberModal = React.useContext(MemberModalContext);

  return memberModal;
};

export const MemberModalContextProvider = ({ children }) => {
  const [memberModalContext, setMemberModalContext] = React.useState(
    MemberModalInitialState
  );

  React.useEffect(() => {
    setMemberModalContext({
      ...MemberModalInitialState,
      setMemberModal: (values) =>
        setMemberModalContext((prev) => ({
          ...prev,
          userId: values.userId,
          userTenantId: values.userTenantId,
          initialView: values.initialView || prev.initialView,
          onClose: values.onClose || prev.onClose,
        })),
      resetMemberModal: () =>
        setMemberModalContext((prev) => ({
          ...MemberModalInitialState,
          setMemberModal: prev.setMemberModal,
          resetMemberModal: prev.resetMemberModal,
        })),
    });
  }, []);

  return (
    <MemberModalContext.Provider value={memberModalContext}>
      {children}
    </MemberModalContext.Provider>
  );
};
