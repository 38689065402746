import { Formik } from 'formik';
import { Box, Card, Col, Field, Row, Step } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Package from '../shared/Package';

export default ({
  isFetchingPackageLocations,
  isFetchingPackages,
  isPackagesOnly,
  isSingleLocation,
  isSinglePackage,
  locationOptions,
  onGetPackagesRequest,
  packages,
  tagId,
  ...props
}) => {
  const { t } = useTranslation();

  const packagesByCategory = useMemo(() => {
    const categories = {};

    packages.forEach((item) => {
      if (tagId) {
        if (item?.tags?.length) {
          item.tags.forEach((tag) => {
            if (tag.id === Number(tagId)) {
              if (!categories[tag.name]) {
                categories[tag.name] = [];
              }

              categories[tag.name].push(item);
            }
          });
        }
      } else {
        if (!item?.tags?.length) {
          if (!categories.Other) {
            categories.Other = [];
          }

          categories.Other.push(item);
          return;
        }

        item.tags.forEach((tag) => {
          if (!categories[tag.name]) {
            categories[tag.name] = [];
          }

          categories[tag.name].push(item);
        });
      }
    });

    return Object.keys(categories)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: categories[key],
        }),
        {}
      );
  }, [packages, tagId]);

  const onlyHasOtherCategory =
    Object.keys(packagesByCategory).length === 1 && !!packagesByCategory.Other;

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        chosenLocationId: isSingleLocation
          ? locationOptions[0]?.value
          : undefined,
        chosenPackage: isSinglePackage ? packages[0] : undefined,
      }}
      validationSchema={Yup.object().shape({
        chosenLocationId: Yup.string().required(t('required')),
        chosenPackage: Yup.object(),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        submitForm,
        values: { chosenLocationId, chosenPackage },
      }) => {
        const onClickSelect =
          isSinglePackage || isPackagesOnly
            ? undefined
            : ({ item, isChosen }) => {
                setFieldTouched('chosenPackage', !isChosen);
                setFieldValue('chosenPackage', isChosen ? undefined : item);

                setTimeout(submitForm, 100);
              };

        return (
          <form onSubmit={handleSubmit}>
            <Step
              boxContainerProps={{ mb: 4 }}
              title={
                isPackagesOnly ? t('packages') : t('locationPackageSelection')
              }
              value={isPackagesOnly ? undefined : '2'}
            >
              <Row>
                <Col lg={4} md={6}>
                  <Field
                    isSelect
                    doFinally={(newLocationId) => {
                      if (!isSinglePackage) {
                        setFieldTouched('chosenPackage', false);
                        setFieldValue('chosenPackage', undefined);

                        onGetPackagesRequest(newLocationId);
                      }

                      setTimeout(submitForm, 100);
                    }}
                    isDisabled={isSingleLocation}
                    isLoading={isFetchingPackageLocations}
                    label={t('location')}
                    name='chosenLocationId'
                    options={locationOptions}
                  />
                </Col>
              </Row>

              {(isSinglePackage || !!chosenLocationId) &&
                !isFetchingPackages &&
                (chosenPackage ? (
                  <Package
                    isChosen
                    boxContainerProps={{ mt: 4 }}
                    isVisibleSelect={!isSinglePackage}
                    item={chosenPackage}
                    onClickSelect={onClickSelect}
                  />
                ) : (
                  <>
                    <Box height={2} />

                    {Object.keys(packagesByCategory).map((key) =>
                      onlyHasOtherCategory ? (
                        packagesByCategory[key].map((item) => (
                          <Package
                            boxContainerProps={{ mt: 2 }}
                            isVisibleSelect={!isPackagesOnly}
                            item={item}
                            key={item.id}
                            onClickSelect={onClickSelect}
                          />
                        ))
                      ) : (
                        <Card hasBorder key={key} mt={4} title={key}>
                          {packagesByCategory[key].map((item) => (
                            <Package
                              boxContainerProps={{ mt: 2 }}
                              isVisibleSelect={!isPackagesOnly}
                              item={item}
                              key={item.id}
                              onClickSelect={onClickSelect}
                            />
                          ))}
                        </Card>
                      )
                    )}
                  </>
                ))}
            </Step>
          </form>
        );
      }}
    </Formik>
  );
};
