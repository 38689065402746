import { Container } from 'octiv-components';
import { useFinancesFindGoCardlessMandatesMemberOnboardingLink } from 'octiv-hooks/requests/Finances';
import React from 'react';
import { useParams } from 'react-router-dom';

export default () => {
  const params = useParams();

  const { isFetching: isFetchingGoCardlessOnBoardingLink } =
    useFinancesFindGoCardlessMandatesMemberOnboardingLink(
      {
        userId: params?.userId,
        tenantId: params?.tenantId,
      },
      {
        onSuccess: (response) => window.location.assign(response.url),
      }
    );

  return <Container isLoading={isFetchingGoCardlessOnBoardingLink} />;
};
