import { useQuery } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useWhiteboardFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['whiteboard', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/whiteboard`,
        params: {
          filter: {
            locationId: filter.locationId,
            date: filter.date,
            programmeId: filter.programmeId,
            classDateId: filter.classDateId,
            showNoneBookingMembers: filter.showNoneBookingMembers,
          },
          ...paging,
          sortBy: filter.sortBy,
          sortDirection: filter.sortDirection,
        },
      }),
    options
  );
};

export const useWhiteboardFindResults = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['whiteboard', 'findResults', input],
    () =>
      request({
        method: 'get',
        url: `/api/whiteboard/results`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            date: filter.date,
            programmeId: filter.programmeId,
          },
        },
      }),
    options
  );
};
