import { Box, Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onDelete, onEdit, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'dateTime',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('reference'),
        accessor: 'reference',
      },
      {
        Header: t('processors'),
        accessor: 'tags',
        Cell: ({ value }) => value?.map((tag) => tag?.name).join(', ') || '',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <Box isFlex>
            <Text
              as='span'
              color='primary'
              mr={2}
              onClick={() => onEdit(original)}
            >
              {t('edit')}
            </Text>

            <Text
              as='span'
              color='danger'
              onClick={() => onDelete({ id: original.id })}
            >
              {t('delete')}
            </Text>
          </Box>
        ),
      },
    ],
    [onEdit, onDelete, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
