import { Images } from 'octiv-assets';
import { Box, Button, Container, Image, Modal, Text } from 'octiv-components';
import { useQuery, useToggle } from 'octiv-hooks';
import {
  useAttendanceRecordsCreateCheckIn,
  useAttendanceRecordsCreateCheckOut,
} from 'octiv-hooks/requests/AttendanceRecords';
import { domain } from 'octiv-utilities/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import FormDetails from './FormDetails';

const HealthProvidersAttendance = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const code = useQuery('code');
  const locationId = useQuery('locationId');

  const [toggleConfirmation, setToggleConfirmation, resetToggleConfirmation] =
    useToggle();

  const [isLoading, setIsLoading] = useState(true);
  const [nonMemberDetails, setNonMemberDetails] = useState(undefined);

  useEffect(() => {
    (async () => {
      const nonMember = await localStorage.getItem('nonMemberDetails');

      if (nonMember !== null) {
        setNonMemberDetails(JSON.parse(nonMember));
      }

      setIsLoading(false);
    })();
  }, []);

  const checkInRequest = useAttendanceRecordsCreateCheckIn({
    onSuccess: resetToggleConfirmation,
    meta: {
      useOnSuccessToast: true,
      omitAuthToken: true,
      onSuccessToast: {
        title: 'You have checked-in',
        body: "Don't forget to check out after your class",
      },
    },
  });

  const checkOutRequest = useAttendanceRecordsCreateCheckOut({
    onSuccess: resetToggleConfirmation,
    meta: {
      useOnSuccessToast: true,
      omitAuthToken: true,
      onSuccessToast: {
        title: 'You have checked out',
        body: 'Your Vitality points will reflect within 3 - 5 days, if applicable',
      },
    },
  });

  const onCheckIn = () => {
    checkInRequest.mutate({
      code,
      ...nonMemberDetails,
    });
  };

  const onCheckOut = () => {
    checkOutRequest.mutate({
      code,
      ...nonMemberDetails,
    });
  };

  const onSubmitFormDetails = async ({ isCheckIn, ...values }) => {
    setNonMemberDetails(values);

    setToggleConfirmation({ type: isCheckIn ? 'checkIn' : 'checkOut' });

    await localStorage.setItem('nonMemberDetails', JSON.stringify(values));
  };

  const isCheckIn = toggleConfirmation?.type === 'checkIn';

  return (
    <>
      {toggleConfirmation.isVisible && (
        <Modal
          isCondensed
          title={`${isCheckIn ? 'Check-In' : 'Check Out'} Confirmation`}
          onClose={resetToggleConfirmation}
        >
          <Text textAlign='center' variant='subheading'>
            {`Are you sure you want to ${
              toggleConfirmation.type === 'checkIn' ? 'check-in' : 'check out'
            }?`}
          </Text>

          {!isCheckIn && (
            <Text color='grey1' mt={2} textAlign='center' variant='caption'>
              Vitality points will reflect within 3 - 5 days, if applicable.
            </Text>
          )}

          <Box isFlex justifyContent='center' mt={4}>
            <Button
              hasBorder
              mr={2}
              text={t('cancel')}
              onClick={resetToggleConfirmation}
            />

            <Button
              isLoading={checkInRequest.isLoading || checkOutRequest.isLoading}
              ml={2}
              text={`Yes, ${isCheckIn ? 'Check-In' : 'Check Out'}`}
              onClick={isCheckIn ? onCheckIn : onCheckOut}
            />
          </Box>
        </Modal>
      )}

      <Container
        appBarProps={{
          title: 'Check-In & Out',
          breadcrumbs: ['Attendance'],
        }}
        isLoading={checkInRequest.isLoading || checkOutRequest.isLoading}
      >
        <Box m='auto' maxWidth={600} pb={20}>
          {!isLoading && (
            <>
              <Box
                isFlex
                alignItems='center'
                justifyContent='center'
                mb={4}
                mt={2}
              >
                <Image
                  alt='Vitality Logo'
                  backgroundSize='contain'
                  height={14}
                  src={Images.vitality}
                  width={26}
                />

                <Box bg='grey4' height={11} mx={4} width='1px' />

                <Image
                  alt='Octiv Logo'
                  backgroundSize='contain'
                  height={14}
                  src={theme.isDark ? Images.logoLight : Images.logoDark}
                  width={32}
                />
              </Box>

              <Text isBold mb={4} variant='heading'>
                Please select the platform where your booking was made
              </Text>

              <Text isBold variant='subheading'>
                Booking made with Vitality Fitness
              </Text>

              <Text color='grey1' mb={4} mt={2}>
                If you booked through Vitality Fitness, please check-in with the
                Discovery app.
              </Text>

              <Button
                bg='#F8116A'
                hasRadius={false}
                px={6}
                scale='medium'
                text='Log in to Discovery'
                textProps={{ color: '#fff', isBold: false, isUpperCase: false }}
                onClick={() =>
                  window.open(
                    `https://discovery101.page.link/?link=https%3A//www.discovery.co.za/vitality_fit_checkin_checkout%3Flocation=${locationId}%26code=${code}&apn=za.co.discovery.consumer&isi=458077762&ibi=za.co.discovery.Discovery101`
                  )
                }
              />

              <Box bg='grey4' height='1px' mb={5} mt={6} />

              <Text isBold variant='subheading'>
                Booking made with Octiv
              </Text>

              <Text color='grey1' mb={4} mt={2}>
                If you booked through Octiv, and you have an Octiv account,
                please log in.
              </Text>

              <Button
                px={6}
                scale='medium'
                text='Log in to Octiv'
                onClick={() =>
                  window.location.assign(`${domain}/schedule?code=${code}`)
                }
              />

              <Text color='grey1' my={4}>
                If you booked through Octiv, and you DO NOT have an Octiv
                account, please fill in the form below:
              </Text>

              <FormDetails
                initialValues={nonMemberDetails}
                onSubmit={onSubmitFormDetails}
              />
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default HealthProvidersAttendance;
