import { Images } from 'octiv-assets';
import {
  Box,
  Icon,
  Image,
  LanguageSelector,
  ProcessingBar,
  Sidebar,
  Text,
  Tooltip,
} from 'octiv-components';
import { ActiveScreenContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useUsersUpdateSwitch } from 'octiv-hooks/requests/Users';
import { useUserTenantsUpdate } from 'octiv-hooks/requests/UserTenants';
import { getFullName, getUserTypeString } from 'octiv-utilities/Functions';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

const Navigation = ({ stacks }) => {
  const navigate = useNavigate();

  const theme = useContext(ThemeContext);
  const { isVisibleNavigation } = useContext(ActiveScreenContext);

  const {
    isSignedIn,
    image,
    userTenants,
    linkedUsers,
    signIn,
    signOut,
    isSuperAdmin,
    isDiscoveryAdmin,
  } = useSignedInUser();
  const {
    activeUserTenantId,
    selectedLocation,
    tenant: { logo, name, locations },
    setSelectedLocation,
    setActiveUserTenant,
  } = useActiveUserTenant();

  const canRender = isSignedIn && isVisibleNavigation;

  const usersUpdateSwitch = useUsersUpdateSwitch({
    onSuccess: ({ token }) => {
      setTimeout(() => {
        signIn({ jwt: token });
      }, 100);
    },
  });

  const {
    isLoading: isUpdatingUserTenantDefaultLocation,
    mutate: putUserTenantDefaultLocation,
  } = useUserTenantsUpdate();

  if (!canRender) return null;

  const renderDivider = () => <Box bg='grey5' height='1px' my={2} />;

  const renderOption = ({ id, icon, isActive, label, onClick, ...props }) => (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      bgHover={isActive ? undefined : 'grey5'}
      flexDirection='row'
      height={9}
      key={id || label || icon}
      px={1}
      py={2}
      onClick={isActive ? undefined : onClick}
      {...props}
    >
      <Text color={isActive ? 'primary' : undefined} mr={4}>
        {label}
      </Text>

      {(icon || isActive) && (
        <Icon isActive ml='auto' name={icon || 'check'} scale='small' />
      )}
    </Box>
  );

  const renderSection = ({ title, options, hasDivider = true, ...props }) => (
    <>
      {title && (
        <Text color='grey1' mb={1} variant='caption' {...props}>
          {title}
        </Text>
      )}

      {options && options.map(renderOption)}

      {hasDivider && renderDivider()}
    </>
  );

  const renderAccountsMenu = ({ closeTooltip }) => {
    return (
      <Box py={2}>
        {locations?.length > 1 &&
          renderSection({
            // TODO: translations
            title: 'Change Default Location',
            options: locations.map((location) => ({
              id: location.id,
              isActive: location.id === selectedLocation.id,
              label: location.name,
              onClick: () =>
                putUserTenantDefaultLocation(
                  {
                    id: activeUserTenantId,
                    defaultLocationId: location.id,
                  },
                  {
                    onSuccess: () => setSelectedLocation({ id: location.id }),
                  }
                ),
            })),
          })}

        {userTenants.filter(({ statusId }) => statusId === 2)?.length > 1 &&
          renderSection({
            // TODO: translations
            title: 'Switch Account',
            options: userTenants.map((userTenant) => ({
              id: userTenant.id,
              isActive: userTenant.id === activeUserTenantId,
              label: `${userTenant.tenant.name}${
                userTenants.find(
                  ({ tenant }) => tenant.id === userTenant.tenant.id
                )
                  ? ` (${getUserTypeString({
                      id: userTenant.typeId,
                    })})`
                  : ''
              }`,
              onClick: () => setActiveUserTenant(userTenant),
            })),
          })}

        {linkedUsers[0] &&
          renderSection({
            // TODO: translations
            title: 'Switch User',
            options: linkedUsers.map(({ linkedUser }) => ({
              id: linkedUser.id,
              label: getFullName(linkedUser),
              onClick: () => usersUpdateSwitch.mutate({ id: linkedUser.id }),
            })),
          })}

        {renderOption({
          // TODO: translations
          label: 'Manage Accounts',
          icon: 'chevron_right',
          onClick: () => {
            closeTooltip();
            navigate('/user/accounts');
          },
        })}
      </Box>
    );
  };

  const renderUserMenu = ({ closeTooltip }) => {
    return (
      <Box>
        {renderSection({
          options: [
            {
              // TODO: translations
              label: 'My Profile',
              onClick: () => {
                closeTooltip();
                navigate('/user/profile');
              },
            },
            {
              // TODO: translations
              label: 'My Accounts',
              onClick: () => {
                closeTooltip();
                navigate('/user/accounts');
              },
            },
            {
              // TODO: translations
              label: 'Logout',
              icon: 'logout',
              onClick: signOut,
            },
          ],
        })}

        {renderSection({
          hasDivider: false,
          options: [
            {
              // TODO: translations
              label: 'Privacy Policy',
              onClick: () => {
                closeTooltip();
                window.open('/privacy-policy');
              },
            },
            {
              // TODO: translations
              label: 'Terms of Use',
              onClick: () => {
                closeTooltip();
                window.open('/terms-of-use');
              },
            },
            {
              // TODO: translations
              label: 'Changelog',
              icon: 'update',
              onClick: () => {
                closeTooltip();
                navigate('/changelog');
              },
            },
          ],
        })}
      </Box>
    );
  };

  return (
    <>
      {(isUpdatingUserTenantDefaultLocation ||
        usersUpdateSwitch.isFetching) && <ProcessingBar />}

      <Box
        isFlex
        alignItems='center'
        bg='grey7'
        boxShadow={`0 1px ${theme.colors.grey5}`}
        height={14}
        left={0}
        position='fixed'
        px={2}
        right={0}
        top={0}
        zIndex={498}
      >
        {activeUserTenantId ? (
          <Image
            hasRadius
            alt='Logo'
            bg='grey7'
            mr={2}
            placeholder={Images.logomarkPadded}
            size={10}
            src={logo}
          />
        ) : (
          <Image
            hasRadius
            alt='Octiv Logo'
            bg='grey7'
            height={8}
            mr={2}
            src={theme.isDark ? Images.logoLight : Images.logoDark}
            width={26}
          />
        )}

        {!!activeUserTenantId && (
          <>
            <Box>
              <Text isBold>{name}</Text>

              {selectedLocation && (
                <Text color='grey1' variant='caption'>
                  {selectedLocation.name}
                </Text>
              )}
            </Box>

            <Tooltip placement='bottom-start' render={renderAccountsMenu}>
              <Icon
                hasRadius
                bgHover='grey6'
                height={8}
                mx={2}
                name='unfold_more'
                width={7}
              />
            </Tooltip>
          </>
        )}

        <Box ml='auto' />

        <Tooltip placement='bottom-end' render={renderUserMenu}>
          <Image
            hasRadius
            usePlaceholderUser
            alt='User Profile'
            size={8}
            src={image}
          />
        </Tooltip>

        <Box ml={2}>
          <LanguageSelector color={'grey2'} isVisibleTitle={false} />
        </Box>
      </Box>

      {(isSuperAdmin || isDiscoveryAdmin || !!activeUserTenantId) && (
        <Sidebar stacks={stacks} />
      )}
    </>
  );
};

export default Navigation;
