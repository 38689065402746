import { Formik } from 'formik';
import _ from 'lodash';
import { Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Form = ({ packages, selectedPackages, isLoading, setSelected }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        packageIds: selectedPackages?.map((p) => p.id),
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <form>
            <Field
              hasColumns
              hasSelectAll
              isArray
              isCheckbox
              isLoading={isLoading}
              label={t('whichPackagesShouldHaveALateFee', {
                classType: t('class'),
              })}
              name='packageIds'
              options={packages?.map?.((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={(value) => {
                const newSelection = _.uniq([
                  ...(values?.packageIds || []),
                  ...value,
                ]);
                const selection = _.isEmpty(value) ? [] : newSelection;
                setFieldValue('packageIds', selection);
                setSelected(selection);
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default Form;
