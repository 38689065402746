import moment from 'moment';
import { Alert, Box, Container, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useFinancesFindGoCardlessPaymentsPayouts,
  useFinancesFindGoCardlessPaymentsPayoutsItems,
} from 'octiv-hooks/requests/Finances';
import { paymentGateways } from 'octiv-utilities/Constants';
import {
  getDateDayMonthYearExpanded,
  getDateYearMonthDay,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableDetail from './TableDetail';
import TablePayouts from './TablePayouts';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locations },
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  // Only use locations that have GoCardless as a payment gateway
  const locationOptions = locations
    .filter((item) => item.paymentGatewayId === paymentGateways.GO_CARDLESS)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: payouts, isFetching: isFetchingPayouts } =
    useFinancesFindGoCardlessPaymentsPayouts(
      {
        filter: query,
      },
      {
        enabled: !!query.locationId,
      }
    );

  const { data: payoutItems, isFetching: isFetchingPayoutItems } =
    useFinancesFindGoCardlessPaymentsPayoutsItems(
      {
        filter: {
          locationId: query.locationId,
          payoutId: toggle.data?.id,
          limit: 10000,
        },
      },
      {
        enabled: !!toggle.data?.id,
      }
    );

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${t('payoutItems')}: ${getDateDayMonthYearExpanded({
            date: toggle.data.arrivalDate,
          })}`}
          onClose={resetToggle}
        >
          <Alert mb={4} title='GC = GoCardless' variant='info' />

          <TableDetail
            currency={toggle.data.currency}
            data={isFetchingPayoutItems ? [] : payoutItems?.payoutItems}
            isLoading={isFetchingPayoutItems}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: `GoCardless ${t('payouts')}`,
          breadcrumbs: [t('accounts')],
        }}
        isLoading={isFetchingPayouts || isFetchingPayoutItems}
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <TablePayouts
          data={payouts?.payouts || []}
          isLoading={isFetchingPayouts}
          onClickRow={({ original }) => setToggle({ data: original })}
        />
      </Container>
    </>
  );
};
