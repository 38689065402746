import _ from 'lodash';
import React, { useState } from 'react';

import Leads from './leads';
import Members from './members';
import Others from './others';
import Staff from './staff';

export default ({
  currentRecipients,
  isTypeEmail,
  onCloseModal,
  recipientType,
  setFieldValue,
}) => {
  const [selectedRecipients, setSelectedRecipients] =
    useState(currentRecipients);

  const onChangeRecipients = ({ add = [], removeAll = false, removeId }) => {
    setSelectedRecipients((prev) =>
      _.uniq(
        removeAll
          ? []
          : removeId
          ? [...prev.filter((item) => item.id !== removeId)]
          : [...prev, ...add]
      )
    );
  };

  const onClickProceed = ({ recipients }) => {
    setFieldValue(recipients || selectedRecipients);

    onCloseModal();
  };

  switch (recipientType) {
    case 'recipientsMembers':
      return (
        <Members
          selectedRecipients={selectedRecipients}
          onChangeRecipients={onChangeRecipients}
          onClickProceed={onClickProceed}
        />
      );

    case 'recipientsStaff':
      return (
        <Staff
          selectedRecipients={selectedRecipients}
          onChangeRecipients={onChangeRecipients}
          onClickProceed={onClickProceed}
        />
      );

    case 'recipientsLeads':
      return (
        <Leads
          selectedRecipients={selectedRecipients}
          onChangeRecipients={onChangeRecipients}
          onClickProceed={onClickProceed}
        />
      );

    case 'recipientsNonMembers':
      return (
        <Others
          isTypeEmail={isTypeEmail}
          selectedRecipients={selectedRecipients}
          onChangeRecipients={onChangeRecipients}
          onClickProceed={onClickProceed}
        />
      );

    default:
      return null;
  }
};
