import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getEventString, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  locationOptions,
  isFetchingMembers,
  members,
  classes,
  isFetchingClasses,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        isSession: Yup.bool(),
        isClassActive: Yup.bool().required(t('required')),
        classId: Yup.string(),
        userId: Yup.string(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, setFieldTouched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                doFinally={() => {
                  setFieldTouched('classId', false);
                  setFieldValue('classId', undefined);
                }}
                label={t('eventTypes')}
                name='isSession'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('classes'), value: false },
                  { label: t('sessions'), value: true },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('classStatus')}
                name='isClassActive'
                options={[
                  { label: t('active'), value: true },
                  { label: t('deactivated'), value: false },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingClasses}
                label={t('class')}
                name='classId'
                options={[
                  { label: t('all'), value: undefined },
                  ...classes.map((item) => ({
                    label: getEventString(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingMembers}
                label={t('member')}
                name='userId'
                options={[
                  { label: t('all'), value: undefined },
                  ...members.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
