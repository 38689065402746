import styled from 'styled-components';
import { color } from 'styled-system';

export const StyledIcon = styled('i').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color', 'fontSize'].includes(prop) && defaultValidatorFn(prop),
})`
  transition: color 250ms ease;
  font-size: ${({ fontSize }) => fontSize};

  ${color};
`;
