import { Box, Button, Container, Divider, Modal, Text } from 'octiv-components';
import { usePaging, useQuery, useToggle } from 'octiv-hooks';
import {
  useCrmDeleteMailers,
  useCrmDuplicateMailers,
  useCrmFindByIdMailers,
  useCrmFindMailers,
} from 'octiv-hooks/requests/Crm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const status = useQuery('status');

  const [toggle, setToggle, resetToggle] = useToggle();
  const [commsId, setCommsId] = useState(null);
  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    status: status === 'sent' ? 'complete' : 'upcoming',
    type: undefined,
  });

  const crmFindMailers = useCrmFindMailers({
    filter: query,
    paging,
  });

  const crmDuplicateMailers = useCrmDuplicateMailers({
    onSuccess: (response) => {
      if (response.id) {
        navigate(`/comms/update/${response.id}`);
      }
    },
  });

  const crmFindMailer = useCrmFindByIdMailers(
    { id: commsId },
    { onSuccess: setToggle, enabled: !!commsId }
  );
  const crmDeleteMailers = useCrmDeleteMailers();

  const onClickAction = ({ action, id }) => {
    let commsItem;
    switch (action) {
      case 'view':
        setToggle({ ...toggle, isVisible: true });
        setCommsId(id);
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          crmDeleteMailers.mutate({ id });
        }
        break;

      case 'duplicate':
        commsItem = crmFindMailers?.data?.data.find((item) => item.id === id);

        if (commsItem?.type === 'sms') {
          alert('Duplicating SMS has been temporarily disabled');
          break;
        }

        if (window.confirm(t('areYouSureProceed'))) {
          crmDuplicateMailers.mutate({ id });
        }
        break;

      default:
        break;
    }
  };

  const canDisplayEmailContent =
    crmFindMailer.data?.type === 'email' &&
    (crmFindMailer.data?.imageHeader ||
      crmFindMailer.data?.content ||
      crmFindMailer.data?.imageFooter);
  const canDisplaySmsPushContent =
    (crmFindMailer.data?.type === 'sms' ||
      crmFindMailer.data?.type === 'push') &&
    crmFindMailer.data?.content;

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${
            crmFindMailer.data?.type === 'email'
              ? 'Email'
              : crmFindMailer.data?.type === 'sms'
              ? 'SMS'
              : crmFindMailer.data?.type === 'push'
              ? 'Push Notification'
              : ''
          }: ${crmFindMailer.data?.name}`}
          onClose={resetToggle}
        >
          {canDisplayEmailContent ? (
            <>
              <Text>{`Subject: ${crmFindMailer.data?.subject}`}</Text>
              <Text>{`Reply To: ${crmFindMailer.data?.replyTo}`}</Text>
              <Text>{`Sender Name: ${crmFindMailer.data?.senderName}`}</Text>

              <Text mb={2} mt={4} variant='subheading'>
                Content
              </Text>

              <Box hasRadius bg='#fff' p={4}>
                <Box
                  hasRadius
                  border='1px solid #e5e5ea'
                  m='auto'
                  maxWidth='600px'
                  p={6}
                >
                  {crmFindMailer.data?.imageHeader && (
                    <img
                      alt='Header'
                      src={crmFindMailer.data?.imageHeader}
                      style={{
                        maxWidth: '100%',
                        marginBottom: '16px',
                      }}
                    />
                  )}

                  {crmFindMailer.data?.content && (
                    <Box
                      className='email-content'
                      dangerouslySetInnerHTML={{
                        __html: crmFindMailer.data?.content,
                      }}
                    />
                  )}

                  {crmFindMailer.data?.imageFooter && (
                    <img
                      alt='Header'
                      src={crmFindMailer.data?.imageFooter}
                      style={{
                        maxWidth: '100%',
                        marginTop: '16px',
                      }}
                    />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Text color='grey1'>
              {canDisplaySmsPushContent
                ? crmFindMailer.data?.content
                : 'No Content'}
            </Text>
          )}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: 'Manage Comms',
          breadcrumbs: ['Comms'],
          children: (
            <Box ml='auto'>
              <Button text={t('createNew')} to='/comms/create' />
            </Box>
          ),
        }}
        isLoading={
          crmFindMailers.isFetching ||
          crmFindMailer.isFetching ||
          crmDeleteMailers.isLoading
        }
      >
        <FormQuery initialValues={query} onSubmit={setQuery} />

        <Divider pb={4} />

        <Table
          data={
            crmFindMailers.isFetching || crmDeleteMailers.isLoading
              ? []
              : crmFindMailers.data?.data
          }
          isLoading={crmFindMailers.isFetching || crmDeleteMailers.isLoading}
          paging={crmFindMailers?.data?.paging}
          query={query}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
