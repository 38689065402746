import {
  Box,
  Chip,
  ProcessingBar,
  ProcessingSuspense,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useInjuriesCreate,
  useInjuriesDelete,
  useInjuriesFind,
  useInjuriesUpdate,
  useInjuriesUpdateHeal,
} from 'octiv-hooks/requests/Injuries';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormNew from './FormNew';
import FormUpdate from './FormUpdate';

export default ({ userId }) => {
  const { t } = useTranslation();

  const { tenantId } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const injuriesFind = useInjuriesFind({
    filter: { tenantId, userId },
    include: 'injuryUpdates',
  });

  const injuriesCreate = useInjuriesCreate();

  const injuriesUpdate = useInjuriesUpdate({
    onSuccess: resetToggle,
  });

  const injuriesDelete = useInjuriesDelete();

  const injuriesUpdateHeal = useInjuriesUpdateHeal();

  return (
    <>
      {(injuriesFind.isFetching ||
        injuriesCreate.isLoading ||
        injuriesUpdate.isLoading ||
        injuriesUpdateHeal.isLoading ||
        injuriesDelete.isLoading) && <ProcessingBar />}

      <Text mb={2} variant='heading'>
        {t('createNewInjury')}
      </Text>
      <FormNew
        isLoading={injuriesCreate.isLoading}
        onSubmit={(values, { resetForm }) =>
          injuriesCreate.mutate(
            { userId, tenantId, ...values },
            {
              onSuccess: resetForm,
            }
          )
        }
      />

      <Text mb={3} mt={6} variant='heading'>
        {t('memberInjuries')}
      </Text>

      {injuriesFind.isFetching && injuriesFind.data?.data?.length === 0 ? (
        <ProcessingSuspense />
      ) : injuriesFind.data?.data?.length === 0 ? (
        <Text color='grey1'>{t('noInjuriesLoggedForMember')}</Text>
      ) : (
        injuriesFind.data?.data?.map((injury, index) => {
          const injuryUpdates = [...injury.updates];
          const initialInjury = injuryUpdates.shift();
          const isHealed =
            injuryUpdates[injuryUpdates.length - 1]?.content ===
            'Marked as "healed"';

          return (
            <Box
              hasRadius
              border={1}
              borderColor={isHealed ? 'grey3' : 'danger'}
              key={injury.id.toString()}
              mt={index > 0 ? 4 : undefined}
              overflow='hidden'
              p={4}
            >
              <Box isFlex justifyContent='space-between' mb={3}>
                <Box>
                  <Text variant='subheading'>{initialInjury.content}</Text>
                  <Text color='grey1' variant='caption'>
                    {getDateReadableDayMonthYear({
                      date: initialInjury.createdAt,
                    })}
                  </Text>
                </Box>

                <Box isFlex>
                  {!isHealed && (
                    <Chip
                      hasAlternateBackground
                      icon='done'
                      mr={2}
                      title={t('markAsHealed')}
                      onClick={() =>
                        injuriesUpdateHeal.mutate({ id: injury.id })
                      }
                    />
                  )}

                  <Chip
                    hasAlternateBackground
                    color='danger'
                    icon='delete'
                    onClick={() => injuriesDelete.mutate({ id: injury.id })}
                  />
                </Box>
              </Box>

              {injuryUpdates.length > 0 && (
                <Box mb={3}>
                  <Text>{`${t('updates')}:`}</Text>

                  {injuryUpdates.map((update) => (
                    <Text
                      color='grey1'
                      key={update.id}
                      pt={1}
                      variant='caption'
                    >
                      {`${getDateReadableDayMonthYear({
                        date: update.createdAt,
                      })}: ${update.content}`}
                    </Text>
                  ))}
                </Box>
              )}

              {toggle.isVisible && toggle.data.id === injury.id ? (
                <FormUpdate
                  isLoading={injuriesUpdate.isLoading}
                  onCancel={resetToggle}
                  onSubmit={(values) =>
                    injuriesUpdate.mutate({ id: toggle.data.id, ...values })
                  }
                />
              ) : (
                <Chip
                  hasAlternateBackground
                  icon='note_add'
                  title={t('update')}
                  onClick={() => setToggle({ type: 'update', data: injury })}
                />
              )}
            </Box>
          );
        })
      )}
    </>
  );
};
