import { FieldArray, Formik } from 'formik';
import { Box, Button, Col, Divider, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ initialValues, isTypeEmail, ...props }) => {
  const { t } = useTranslation();

  const initialValue = {
    name: undefined,
    ...(isTypeEmail ? { email: undefined } : { mobile: undefined }),
  };

  return (
    <Formik
      {...props}
      initialValues={{
        recipients: initialValues.recipients[0]
          ? initialValues.recipients
          : [{ ...initialValue }],
      }}
      validationSchema={Yup.object().shape({
        recipients: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            ...(isTypeEmail
              ? {
                  email: Yup.string()
                    .email(t('invalidEmail'))
                    .required(t('required')),
                }
              : {
                  mobile: Yup.string().required(t('required')),
                }),
          })
        ),
      })}
    >
      {({ handleSubmit, setFieldValue, values: { recipients } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <FieldArray
              name='recipients'
              render={(arrayHelpers) =>
                recipients.map((recipient, index) => (
                  <React.Fragment key={`${index.toString()}`}>
                    <Divider hasLine mb={5} mt={4} />

                    <Col md={5}>
                      <Field
                        label={t('name')}
                        name={`recipients[${index}].name`}
                      />
                    </Col>

                    <Col md={5}>
                      <Field
                        label={isTypeEmail ? t('email') : t('mobile')}
                        name={
                          isTypeEmail
                            ? `recipients[${index}].email`
                            : `recipients[${index}].mobile`
                        }
                      />
                    </Col>

                    <Col md={2}>
                      <Button
                        hasBorder
                        isFullWidth
                        color='danger'
                        height='100%'
                        text={t('remove')}
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </Col>
                  </React.Fragment>
                ))
              }
            />
          </Row>

          <Box isFlex mt={4}>
            <Button
              hasBorder
              mr={2}
              text={t('addAnother')}
              type='button'
              onClick={() =>
                setFieldValue('recipients', [
                  ...recipients,
                  { ...initialValue },
                ])
              }
            />

            <Button text={t('proceed')} type='submit' />
          </Box>
        </form>
      )}
    </Formik>
  );
};
