import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isSigned: false,
      }}
      validationSchema={Yup.object().shape({
        isSigned: Yup.bool()
          .oneOf([true], t('termsWaiverMustBeAccepted'))
          .required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isCheckbox
                helper={t('digitalSignature')}
                name='isSigned'
                options={[
                  {
                    label: t('termsConditionsWaiver'),
                    value: true,
                  },
                ]}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('sign')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
