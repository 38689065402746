import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PaymentOptions from './paymentOptions';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('store'),
        breadcrumbs: [t('settings')],
      }}
    >
      <PaymentOptions />
    </Container>
  );
};
