import { Box, Container, Text, Toast } from 'octiv-components';
import { useQuery } from 'octiv-hooks';
import { useFinancesCreateGoCardlessMerchantOauthCallback } from 'octiv-hooks/requests/Finances';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const error = useQuery('error');
  const errorDescription = useQuery('error_description');
  const code = useQuery('code');
  const state = useQuery('state');

  const redirect = () =>
    navigate('/settings/payment-gateways', { replace: true });

  const redirectWithTimeout = () => {
    setTimeout(() => redirect(), 5000);
  };

  const { mutate: postGoCardlessMerchantOauthCallbackRequest } =
    useFinancesCreateGoCardlessMerchantOauthCallback({
      onSuccess: redirect,
      onError: redirect,
      meta: {
        useOnSuccessToast: true,
      },
    });

  useEffect(() => {
    if (code && state) {
      return postGoCardlessMerchantOauthCallbackRequest({ code, state });
    }

    if (error) {
      toast.error(
        <Toast body={errorDescription} title={t('error')} variant='danger' />
      );
    }

    return redirectWithTimeout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container isLoading>
      <Box
        isFlex
        alignItems='center'
        bottom={0}
        flexDirection='column'
        justifyContent='center'
        left={0}
        p={6}
        position='absolute'
        right={0}
        top={0}
      >
        <Text mb={2} textAlign='center' variant='heading'>
          {t('pleaseWait')}
        </Text>

        <Text color='grey1' textAlign='center'>
          {t('redirectedOnceProcessCompleted')}
        </Text>
      </Box>
    </Container>
  );
};
