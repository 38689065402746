import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ classes, isFetchingClasses, locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        date: Yup.string().required(t('required')),
        locationId: Yup.string().required(t('required')),
        classDateId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('date')}
                name='date'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingClasses}
                label={t('class')}
                name='classDateId'
                options={[
                  { label: t('all'), value: undefined },
                  ...classes.map(({ name, startTime, endTime, id }) => ({
                    label: getEventString({ name, startTime, endTime }),
                    value: id,
                  })),
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
