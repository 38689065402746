import { Formik } from 'formik';
import { Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default (props) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        image: Yup.mixed(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            canSubmitOnChange
            isDrop
            boxContainerProps={{ mb: 4 }}
            label={t('profileImage')}
            name='image'
          />
        </form>
      )}
    </Formik>
  );
};
