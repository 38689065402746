import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, signUpRequiredFields, ...props }) => {
  const { t } = useTranslation();

  const signupSettings = {
    isMandatoryAddress: signUpRequiredFields?.includes?.('address'),
    isMandatoryPassportOrId: signUpRequiredFields?.includes?.('id_number'),
  };

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        name: undefined,
        surname: undefined,
        genderId: undefined,
        dateOfBirth: undefined,
        mobile: undefined,
        address: undefined,
        idNumber: undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        genderId: Yup.string(),
        dateOfBirth: Yup.date()
          .min(moment('01/01/1900').toDate(), t('invalidDate'))
          .max(moment().toDate(), t('invalidDate')),
        mobile: Yup.string().required(t('required')),
        address: Yup.string().when('isMandatoryAddress', {
          is: () => signupSettings.isMandatoryAddress,
          then: Yup.string().required(t('required')),
        }),
        idNumber: Yup.string().when('isMandatoryPassportOrId', {
          is: () => signupSettings.isMandatoryPassportOrId,
          then: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Row>
              <Col md={6} xl={4}>
                <Field label={t('firstName')} name='name' />
              </Col>

              <Col md={6} xl={4}>
                <Field label={t('surname')} name='surname' />
              </Col>

              <Col md={6} xl={4}>
                <Field label={t('mobileNumber')} name='mobile' />
              </Col>

              <Col md={6} xl={4}>
                <Field
                  isSelect
                  label={t('gender')}
                  name='genderId'
                  options={genderOptions}
                />
              </Col>

              <Col md={6} xl={4}>
                <Field isDate label={t('dateOfBirth')} name='dateOfBirth' />
              </Col>
            </Row>

            <Row marginBottom='1rem' marginTop='0.5rem'>
              <Col md={12} xl={4}>
                {signupSettings?.isMandatoryAddress ? (
                  <Field label={t('address')} name='address' />
                ) : null}
              </Col>

              <Col md={6} xl={4}>
                {signupSettings?.isMandatoryPassportOrId ? (
                  <Field
                    label={`${t('memberId')}/${t('passportNumber')}`}
                    name='idNumber'
                  />
                ) : null}
              </Col>
            </Row>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
