import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        status: Yup.string(),
        isDebitOrderMembersOnly: Yup.boolean(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='status'
                options={[
                  {
                    label: t('all'),
                    value: undefined,
                  },
                  {
                    label: t('created'),
                    value: 'created',
                  },
                  {
                    label: t('active'),
                    value: 'active',
                  },
                  {
                    label: t('cancelled'),
                    value: 'cancelled',
                  },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('debitStatus')}
                name='isDebitOrderMembersOnly'
                options={[
                  {
                    label: t('debitOrder'),
                    value: 1,
                  },
                  {
                    label: t('other'),
                    value: 0,
                  },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
