import appStoreApple from './appStoreApple.png';
import appStoreGoogle from './appStoreGoogle.png';
import logoDark from './logoDark.png';
import logoLight from './logoLight.png';
import logomark from './logomark.png';
import logomarkPadded from './logomarkPadded.png';
import logoPoweredByDark from './logoPoweredByDark.png';
import logoPoweredByLight from './logoPoweredByLight.png';
import measurements from './measurements.png';
import mockup from './mockup.jpg';
import user from './user.png';
import vitality from './vitality.png';
import vitalityLogomark from './vitalityLogomark.png';

export default {
  appStoreApple,
  appStoreGoogle,
  logoDark,
  logoLight,
  logomark,
  logomarkPadded,
  logoPoweredByDark,
  logoPoweredByLight,
  measurements,
  mockup,
  user,
  vitality,
  vitalityLogomark,
};
