import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { toCurrency } = useActiveUserTenant();

  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('discount'),
        accessor: 'discount',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('#membersOnDiscount'),
        accessor: 'numberOfAthletes',
      },
      {
        Header: t('amountDiscounted'),
        accessor: 'totalDiscountedAmount',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
