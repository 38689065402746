import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useReportsFindAttendanceOverview = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceOverview', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/overview`,
        params: {
          filter: {
            tenantId,
            year: filter.year,
            locationId: filter.locationId,
            bookingStatusId: filter.bookingStatusId,
            classId: filter.classId,
            userId: filter.userId,
          },
        },
      }),
    options
  );
};

export const useReportsFindAttendanceClassAttendance = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceClassAttendance', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/class-attendance`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            classId: filter.classId,
            isSession: filter.isSession,
            isClassActive: filter.isClassActive,
            userId: filter.userId,
            classDatesBetween: `${filter.startDate},${filter.endDate}`,
          },
        },
      }),
    options
  );
};

export const useReportsFindAttendanceClassAttendanceDetails = (
  input,
  options
) => {
  const { filter = {}, include = undefined } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceClassAttendanceDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/class-attendance-details`,
        params: {
          filter: {
            tenantId,
            classId: filter.classId,
            bookingStatusId: filter.classBookingStatusId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            isSession: Number(filter.isSession),
          },
          include,
        },
      }),
    options
  );
};

export const useReportsFindAttendanceMemberAttendance = (input, options) => {
  const { filter = {}, paging = {}, sort } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceMemberAttendance', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/member-attendance`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            classId: filter.classId,
            isSession: filter.isSession,
            between: `${filter.startDate},${filter.endDate}`,
          },
          sort: sort || 'user.name',
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindAttendanceMemberAttendanceDetails = (
  input,
  options
) => {
  const { filter = {}, include } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceMemberAttendanceDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/member-attendance-details`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            classId: filter.classId,
            locationId: filter.locationId,
            bookingStatusId: filter.bookingStatusId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          include,
        },
      }),
    options
  );
};

export const useReportsFindAttendanceMemberNonAttendance = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceMemberNonAttendance', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/member-non-attendance`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            between: `${filter.startDate},${filter.endDate}`,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindAttendancePersonalSessionsAttendance = (
  input,
  options
) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendancePersonalSessionsAttendance', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/personal-sessions-attendance`,
        params: {
          filter: {
            tenantId,
            search: filter.search,
            appointmentName: filter.appointmentName,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindAttendancePersonalSessionsAttendanceDetails = (
  input,
  options
) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendancePersonalSessionsAttendanceDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/personal-sessions-attendance-details`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            appointmentName: filter.appointmentName,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useReportsCreateAttendanceMailNonAttendanceMembers = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/reports/attendance/mail-non-attendance-members`,
        data: {
          tenantId,
          message: data.message,
          subject: data.subject,
          locationId: data.locationId,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['reports'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useReportsFindAttendanceFirstBookings = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceFirstBookings', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/first-bookings`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useReportsFindAttendanceClassAttendanceOver24Hours = (
  input,
  options
) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findAttendanceClassAttendanceOver24Hours', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/attendance/class-attendance-over-24-hours`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            between: `${filter.startDate},${filter.endDate}`,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardMembersMetrics = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMembersMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/members-metrics`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
            isMonthlyBreakdown: filter.isMonthlyBreakdown,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardMembersMetricDetails = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMembersMetricDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/members-metric-details`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
            month: filter.month,
            metric: filter.metric,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardMembersPerPackage = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMembersPerPackage', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/members-per-package`,
        params: {
          filter: {
            tenantId,
            year: filter.year,
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardMembersPerProgramme = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMembersPerProgramme', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/members-per-programme`,
        params: { filter: { tenantId, locationId: filter.locationId } },
      }),
    options
  );
};

export const useReportsFindDashboardSchedulingMetrics = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardSchedulingMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/scheduling-metrics`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
            isMonthlyBreakdown: filter.isMonthlyBreakdown,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardAccountMetrics = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardAccountMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/account-metrics`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardTotalSalesAndPayments = (
  input,
  options
) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardTotalSalesAndPayments', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/total-sales-and-payments`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardSalesByType = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardSalesByType', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/sales-by-type`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardPaymentsByType = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardPaymentsByType', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/payments-by-type`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardPaymentsByTag = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardPaymentsByTag', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/payments-by-tag`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardMembersByPaymentType = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMembersByPaymentType', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/members-by-payment-type`,
        params: { filter: { tenantId, locationId: filter.locationId } },
      }),
    options
  );
};

export const useReportsFindDashboardLeadsMetrics = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardLeadsMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/leads-metrics`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            year: filter.year,
            isMonthlyBreakdown: filter.isMonthlyBreakdown,
          },
        },
      }),
    options
  );
};

export const useReportsFindDashboardDebtorsSummary = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardDebtorsSummary', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/debtors-summary`,
        params: { filter: { tenantId, locationId: filter.locationId } },
      }),
    options
  );
};

export const useReportsFindDashboardMemberMovement = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardMemberMovement', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/member-movement`,
        params: { filter: { tenantId, locationId: filter.locationId } },
      }),
    options
  );
};

export const useReportsFindDashboardInactiveMandatesCount = (
  input,
  options
) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findDashboardInactiveMandatesCount', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/dashboard/inactive-mandates-count`,
        params: { filter: { tenantId, locationId: filter.locationId } },
      }),
    options
  );
};

export const useReportsFindFinanceDebtors = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findFinanceDebtors', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/finance/debtors`,
        params: {
          filter: {
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            tenantId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindFinancePackagesSalesAndRevenue = (
  input,
  options
) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findFinancePackagesSalesAndRevenue', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/finance/packages-sales-and-revenue`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            // between: `${filter.startDate},${filter.endDate}`,
            startDate: filter.startDate,
            endDate: filter.endDate,
            paymentType: filter.paymentType,
            tagId: filter.tagId,
          },
        },
      }),
    options
  );
};

export const useReportsFindFinanceDiscounts = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findFinanceDiscounts', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/finance/discounts`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useReportsFindFinancePaymentTypes = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findFinancePaymentTypes', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/finance/payment-types`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            paymentType: filter.paymentType,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useReportsFindAmendments = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['reports', 'findAmendments', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/amendments`,
        params: {
          filter: {
            userId: filter.userId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindTrainersSessions = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findTrainersSessions', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/coaches-sessions`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            coachId: filter.trainerId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindTrainersClassSessionDetails = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findTrainersClassSessionDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/coaches-class-session-details`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            coachId: filter.trainerId,
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            isSession: filter.isSession,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindPerformance = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['reports', 'findPerformance', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/performance`,
        params: {
          filter: {
            exerciseId: filter.exerciseId,
            userId: filter.userId,
            gender: filter.gender,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindPerformanceDetails = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findPerformanceDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/performance-details`,
        params: {
          filter: { exerciseId: filter.exerciseId, userId: filter.userId },
        },
      }),
    options
  );
};

export const useReportsFindPersonalBests = (input, options) => {
  const { paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findPersonalBests', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/personal-bests`,
        params: {
          filter: { tenantId },
          ...paging,
        },
      }),
    options
  );
};

export const useReportsFindExport = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['reports', 'findExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/export`,
        params: {
          filter: {
            tenantId,
            reportType: filter.reportType,
            locationId: filter.locationId,
            startDate: filter.startDate,
            endDate: filter.endDate,
          },
        },
      }),
    options
  );
};

export const useReportsFindDiscoveryVitality = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findDiscoveryVitality', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/discovery-vitality`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useReportsFindUserMetrics = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findUserMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/user-metrics`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
            year: filter.year,
          },
        },
      }),
    options
  );
};

export const useReportsFindLocationMetrics = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findLocationMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/tenant-and-location-metrics`,
        params: {
          filter: {
            year: filter.year,
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
            locationCategoryId: filter.locationCategoryId,
            isMonthlyBreakdown: filter.isMonthlyBreakdown,
          },
        },
      }),
    options
  );
};

export const useReportsFindLocationCategoryMetrics = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findLocationMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/location-category-metrics`,
        params: {
          filter: {
            year: filter.year,
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
            locationCategoryId: filter.locationCategoryId,
            isMonthlyBreakdown: filter.isMonthlyBreakdown,
          },
        },
      }),
    options
  );
};

export const useReportsFindRegionsMetrics = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findRegionsMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/region-metrics`,
        params: { filter: { regionId: filter.regionId } },
      }),
    options
  );
};

export const useReportsFindTenantTenantCategoriesMetrics = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['reports', 'findTenantTenantCategoriesMetrics', input],
    () =>
      request({
        method: 'get',
        url: `/api/reports/tenant-tenant-categories-metrics`,
        params: {
          filter: {
            regionId: filter.regionId,
            locationCategoryId: filter.locationCategoryId,
          },
        },
      }),
    options
  );
};
