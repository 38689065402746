import { useQuery } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useCurrenciesFind = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['currencies', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/currencies`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useCurrenciesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['currencies', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/currencies/${id}`,
      }),
    options
  );
};
