import * as Sentry from '@sentry/react';
import { Box, Icon, Text } from 'octiv-components';
import React from 'react';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <Box
          isFlex
          alignItems='center'
          bg='grey7'
          bottom={0}
          flexDirection='column'
          justifyContent='center'
          left={0}
          p={6}
          position='absolute'
          right={0}
          top={0}
          zIndex={50001}
        >
          <Icon isActive mb={4} name='bug_report' scale='large' />

          <Text mb={2} textAlign='center' variant='heading'>
            {t('somethingWentWrong')}
          </Text>

          <Text color='grey1' mb={2} textAlign='center'>
            {t('teamNotified')}
          </Text>

          <Text color='grey1' mb={1} textAlign='center'>
            {t('refreshBrowserWindow')}
          </Text>
        </Box>
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
