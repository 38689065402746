import Button from 'octiv-components/button/index';
import Modal from 'octiv-components/modal/index';
import ProcessingBar from 'octiv-components/processing/bar/index';
import { useToggle } from 'octiv-hooks';
import {
  useAffiliatesCreateLink,
  useAffiliatesCreateUnlink,
  useAffiliatesFind,
} from 'octiv-hooks/requests/Affiliates';
import { useTenantsFind } from 'octiv-hooks/requests/Tenants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormAffiliates from './FormAffiliates';
import TableAffiliates from './TableAffiliates';

export default ({ tenantId }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  // #region CRUD

  const tenantsFind = useTenantsFind({
    filter: {
      tenantId,
    },
    include: 'affiliations',
  });

  const affiliatesFind = useAffiliatesFind();

  const affiliatesLink = useAffiliatesCreateLink({
    onSuccess: () => {
      affiliatesFind.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const affiliatesUnlink = useAffiliatesCreateUnlink({
    onSuccess: () => {
      affiliatesFind.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });
  // #endregion

  const onClickAction = ({ action, id }) => {
    const tenantIds = [tenantId];

    switch (action) {
      case 'unlink':
        if (window.confirm(t('areYouSureProceed'))) {
          affiliatesUnlink.mutate({
            affiliateId: id,
            tenantIds,
          });
        }
        break;

      default:
        break;
    }
  };

  const onSubmitForm = (values) => {
    const { affiliateId, action } = values;
    const tenantIds = [tenantId];

    if (action === 'link') {
      affiliatesLink.mutate({
        tenantIds,
        affiliateId,
      });
    } else if (action === 'unlink') {
      affiliatesUnlink.mutate({
        tenantIds,
        affiliateId,
      });
    }
  };

  return (
    <>
      {affiliatesUnlink?.isLoading ||
        (affiliatesLink.isLoading && <ProcessingBar />)}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${toggle.type === 'add' ? t('add') : t('update')} Affiliate`}
          onClose={() => {
            resetToggle();
          }}
        >
          <FormAffiliates
            affiliates={affiliatesFind?.data || []}
            initialValues={affiliatesFind?.data || []}
            isLoading={affiliatesLink.isLoading}
            type={toggle.type}
            onSubmit={onSubmitForm}
          />
        </Modal>
      )}

      <Button
        mb={4}
        text='Add Affiliates'
        onClick={() => setToggle({ type: 'add' })}
      />

      <TableAffiliates
        data={
          tenantsFind?.isFetching
            ? []
            : tenantsFind?.data?.data?.[0]?.affiliations
        }
        isLoading={tenantsFind?.isFetching}
        onClickAction={onClickAction}
      />
    </>
  );
};
