import React, { Suspense } from 'react';

import ProcessingSuspense from '../processing/suspense';

const EditorLazy = React.lazy(() => import('./Lazy'));

const Editor = (props) => (
  <Suspense fallback={<ProcessingSuspense />}>
    <EditorLazy {...props} />
  </Suspense>
);

export default Editor;
