import { Images } from 'octiv-assets';
import { Box, Card, Col, Container, Image, Row, Text } from 'octiv-components';
import { usePasswordCreateReset } from 'octiv-hooks/requests/Password';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Form from './Form';

const AuthResetPassword = () => {
  const { t } = useTranslation();

  // TODO: Check why this isn't working anymore.
  // This wasn't working when going through this with the team
  // const { email, token } = useParams();
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get('email');
  const token = urlParams.get('token');

  const passwordCreateReset = usePasswordCreateReset({
    onSuccess: () => navigate('/login', { replace: true }),
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onFormSubmit = (values) => {
    passwordCreateReset.mutate({ email, token, ...values });
  };

  return (
    <Container pb={0}>
      <Box isFlex alignItems='center' minHeight='100vh'>
        <Row justifyContent='center'>
          <Col lg={8} md={10} xl={5}>
            <Card>
              <Box isFlex justifyContent='center' mt={-19}>
                <Box bg='grey6' borderRadius='50%' p={4}>
                  <Image
                    alt={t('octivLogo')}
                    bg='transparent'
                    size={22}
                    src={Images.logomark}
                  />
                </Box>
              </Box>

              <Text mb={4} textAlign='center' variant='heading'>
                {t('resetYourPassword')}
              </Text>

              <Form
                isLoading={passwordCreateReset.isLoading}
                onSubmit={onFormSubmit}
              />
            </Card>
          </Col>
        </Row>
      </Box>
    </Container>
  );
};

export default AuthResetPassword;
