import { Alert, Box, Container, Step } from 'octiv-components';
import { useLeadsCreateImport } from 'octiv-hooks/requests/Leads';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [resetForm, setResetForm] = React.useState(() => {});

  const userTenantsCreateLeadsImport = useLeadsCreateImport(
    {
      onSuccess: resetForm,
      meta: {
        useOnSuccessToast: true,
      },
    },
    {
      enabled: false,
    }
  );

  return (
    <Container
      appBarProps={{
        title: t('importLeads'),
        breadcrumbs: [t('leads')],
      }}
      isLoading={userTenantsCreateLeadsImport?.isLoading}
    >
      <Alert
        mb={4}
        subtitle={t('importFileFormat')}
        title={t('pleaseNote')}
        variant='warning'
      />

      <Step
        boxContainerProps={{ mb: 4 }}
        mb={2}
        title={t('importColumnOrder')}
        value='1'
      >
        <Table
          hasAlternateBackground
          data={[
            {
              locationName: 'Octiv Fitness',
              name: 'John',
              surname: 'Doe',
              gender: 'M',
              emailAddress: 'john@gmail.com',
              mobileNumber: '0831234567',
              dateOfBirth: '1990-12-25',
              status: 'Pending',
              source: 'Social Media Marketing',
            },
          ]}
        />
      </Step>

      <Step
        boxContainerProps={{ mb: 4 }}
        mb={2}
        title={`${t('specifics')}:`}
        value='2'
      >
        <Box color='font'>
          <ul>
            <li>{t('importColumnHeader')}</li>
            <li>{t('importLocationName')}</li>
            <li>{t('importGender')}</li>
            <li>{t('importDateOfBirth')}</li>
            <li>{t('importStatus')}</li>
          </ul>
        </Box>
      </Step>

      <Form
        isLoading={userTenantsCreateLeadsImport.isLoading}
        onSubmit={(data, { resetForm: resetFormFunc }) => {
          setResetForm(resetFormFunc);
          userTenantsCreateLeadsImport.mutate(data);
        }}
      />
    </Container>
  );
};
