import { Images } from 'octiv-assets';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Image,
  QrCode,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useIsWindowFocused } from 'octiv-hooks';
import { useLocationsFindByIdAttendanceCode } from 'octiv-hooks/requests/Locations';
import { domain, isDevelopment } from 'octiv-utilities/Constants';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { ThemeContext } from 'styled-components';

import FormQuery from './FormQuery';

export default () => {
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const location = useLocation();
  const isWindowFocused = useIsWindowFocused();

  const {
    selectedLocation,
    tenant: { locations, locationOptions, hasHealthProviders },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId:
      hasHealthProviders && locationOptions.length > 1
        ? undefined
        : selectedLocation?.id,
  });

  const healthProviders =
    locations.find((item) => item.id === query.locationId)?.healthProviders ||
    [];

  const locationHasHealthProviders = !!(query.locationId && healthProviders[0]);

  const locationsFindByIdAttendanceCode = useLocationsFindByIdAttendanceCode(
    {
      id: query.locationId,
    },
    {
      enabled: locationHasHealthProviders,
      refetchInterval: 300000, // 5 min
    }
  );

  const qrCodeValue =
    query.locationId && !locationHasHealthProviders
      ? query.locationId.toString()
      : locationHasHealthProviders &&
        !locationsFindByIdAttendanceCode.isFetching
      ? `${domain}/attendance?code=${locationsFindByIdAttendanceCode.data.attendanceCode}&locationId=${query.locationId}`
      : undefined;

  return (
    <Container
      appBarProps={{
        title: t('qrCode'),
        breadcrumbs: [t('checkIn')],
        children: (
          <Box ml='auto'>
            <Box isFlex>
              {location.pathname === '/check-in' && (
                <Button
                  text={t('hideNavigation')}
                  to='/check-in-hidden-navigation'
                />
              )}

              {!!query.locationId && !locationHasHealthProviders && (
                <Button
                  ml={2}
                  text={t('printSave')}
                  onClick={() => window.print()}
                />
              )}
            </Box>
          </Box>
        ),
      }}
      isLoading={locationsFindByIdAttendanceCode.isFetching}
    >
      {locationOptions.length > 1 && (
        <>
          <FormQuery
            initialValues={query}
            locationOptions={locationOptions}
            onSubmit={setQuery}
          />

          <Divider pb={4} />
        </>
      )}

      {isDevelopment && locationHasHealthProviders && qrCodeValue && (
        <Text mb={4}>{qrCodeValue}</Text>
      )}

      {locationHasHealthProviders && !isWindowFocused && (
        <Alert
          mb={4}
          subtitle={t('qrCodeExpire')}
          title={t('pleaseNote')}
          variant='warning'
        />
      )}

      {(locationHasHealthProviders &&
        locationsFindByIdAttendanceCode.data?.attendanceCode) ||
      (!locationHasHealthProviders && query.locationId) ? (
        <div className='print-show'>
          <Box isFlex justifyContent='center'>
            <Box
              hasRadius
              isFlex
              alignItems='center'
              bg={theme.isDark ? 'grey6' : 'grey1'}
              flexDirection='column'
              maxWidth={430}
              px={8}
              py={4}
            >
              <Text
                isUpperCase
                color='light'
                mb={locationHasHealthProviders ? 2 : 3}
                textAlign='center'
                variant='display'
              >
                {t(
                  locationHasHealthProviders ? 'scanCheckInOut' : 'scanCheckIn'
                )}
              </Text>

              {locationHasHealthProviders && (
                <Text color='light' mb={4} textAlign='center'>
                  {t('makeSureCheckInOut')}
                </Text>
              )}

              <QrCode value={qrCodeValue} />

              {locationHasHealthProviders && (
                <Text color='light' mt={4} textAlign='center'>
                  {t('pointsReflect')}
                </Text>
              )}

              <Box isFlex alignItems='center' justifyContent='center' mt={4}>
                {locationHasHealthProviders && (
                  <Image
                    alt={t('discoveryLogo')}
                    backgroundSize='contain'
                    height={9}
                    mr={1}
                    src={Images.vitalityLogomark}
                    width={9}
                  />
                )}

                <Image
                  alt={t('octivLogo')}
                  backgroundSize='contain'
                  height={11}
                  src={Images.logoLight}
                  width={32}
                />
              </Box>
            </Box>
          </Box>
        </div>
      ) : null}
    </Container>
  );
};
