import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useDebitBatchesFindTenants = (input, options) => {
  const { filter = {}, paging = {}, include = undefined } = input || {};

  return useQuery(
    ['debitBatches', 'findTenants', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/tenants`,
        params: {
          filter: {
            date: filter.date,
            regionId: filter.regionId,
            locationId: filter.locationId,
            paymentGatewayId: filter.paymentGatewayId,
            search: filter.search,
          },
          ...paging,
          include,
        },
      }),
    options
  );
};

export const useDebitBatchesFindLocation = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['debitBatches', 'findLocation', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/location`,
        params: {
          filter: {
            locationId: filter.locationId,
            tenantId,
          },
          isReturnAll: filter.isReturnAll
            ? Number(filter.isReturnAll)
            : undefined,
          ...paging,
        },
      }),
    options
  );
};

export const useDebitBatchesFindByIdExport = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/${id}/export`,
        params: {
          painFormat: input.painFormat,
          isProcessAsBatch: Number(input.isProcessAsBatch),
        },
      }),
    options
  );
};

export const useDebitBatchesFindByIdThreePeaksSubmissionDebits = (
  input,
  options
) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdThreePeaksSubmissionDebits', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/${id}/three-peaks-submission-debits`,
      }),
    options
  );
};

export const useDebitBatchesFindByIdThreePeaksSubmissionInfo = (
  input,
  options
) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdThreePeaksSubmissionInfo', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/${id}/three-peaks-submission-info`,
      }),
    options
  );
};

export const useDebitBatchesFindByIdThreePeaksSubmissions = (
  input,
  options
) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdThreePeaksSubmissions', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/three-peaks-submissions/${id}`,
      }),
    options
  );
};

export const useDebitBatchesFindByIdThreePeaksValidationInfo = (
  input,
  options
) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdThreePeaksValidationInfo', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/${id}/three-peaks-validation-info`,
      }),
    options
  );
};

export const useDebitBatchesFindByIdThreePeaksRecallSubmission = (
  input,
  options
) => {
  const { id } = input || {};

  return useQuery(
    ['debitBatches', 'findByIdThreePeaksRecallSubmission', input],
    () =>
      request({
        method: 'get',
        url: `/api/debit-batches/${id}/three-peaks-recall-submission`,
      }),
    options
  );
};

export const useDebitBatchesCreateReconcileThreePeaksBatch = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/reconcile-three-peaks`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateRegenerateInvoices = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/regenerate-invoices`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateEnsureUsersOnBatch = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/ensure-users-on-batch`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateResubmit = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/resubmit`,
        data: {
          date: data.date,
          isProcessAsSameDayBatch: data.isProcessAsSameDayBatch || false,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateMarkAsUnprocessed = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/mark-as-unprocessed`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateRequestNetcashStatment = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/debit-batches/${id}/request-netcash-statment`,
        data: { date: data.date },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useDebitBatchesCreateReconcileSepaBankStatement = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();

      formData.append('statement', data.bankStatement);
      formData.append('tenantId', tenantId);

      return request({
        method: 'post',
        url: `/api/debit-batches/reconcile-sepa-bank-statement`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['debitBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};
