import _ from 'lodash';
import { Container, Divider, Modal, Toast } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useDebitBatchesCreateReconcileThreePeaksBatch,
  useDebitBatchesCreateResubmit,
  useDebitBatchesFindByIdThreePeaksRecallSubmission,
  useDebitBatchesFindByIdThreePeaksSubmissionDebits,
  useDebitBatchesFindByIdThreePeaksSubmissionInfo,
  useDebitBatchesFindByIdThreePeaksSubmissions,
  useDebitBatchesFindByIdThreePeaksValidationInfo,
  useDebitBatchesFindTenants,
} from 'octiv-hooks/requests/DebitBatches';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import {} from 'octiv-requests';
import { paymentGateways } from 'octiv-utilities/Constants';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FormQuery from './FormQuery';
import FormResubmit from './FormResubmit';
import TableDebitBatches from './TableDebitBatches';
import TableThreePeaksSubmissionDebits from './TableThreePeaksSubmissionDebits';
import TableThreePeaksSubmissionInformationHistory from './TableThreePeaksSubmissionInformationHistory';
import TableThreePeaksValidationInformation from './TableThreePeaksValidationInformation';

export default () => {
  const { t } = useTranslation();

  const [toggleResubmit, setToggleResubmit, resetToggleResubmit] = useToggle();
  const [toggleThreePeaks, setToggleThreePeaks, resetToggleThreePeaks] =
    useToggle();
  const [query, setQuery] = useState({
    date: getDateYearMonthDay({ canFallback: true }),
    regionId: undefined,
    locationId: undefined,
    paymentGatewayId: undefined,
    search: undefined,
  });
  const [paging, setPaging] = usePaging();
  const [accountsAction, setAccountsAction] = useState('');
  const [locationId, setLocationId] = useState(undefined);
  const [debitBatchId, setDebitBatchId] = useState(undefined);
  const paymentGatewayOptions = [
    {
      label: t('all'),
      value: undefined,
    },
    {
      label: 'Sage',
      value: paymentGateways.SAGE,
    },
    {
      label: 'Three Peaks',
      value: paymentGateways.THREE_PEAKS,
    },
    {
      label: 'Netcash',
      value: paymentGateways.NETCASH,
    },
    {
      label: 'GoCardless',
      value: paymentGateways.GO_CARDLESS,
    },
    {
      label: 'Stripe',
      value: paymentGateways.STRIPE,
    },
    {
      label: 'SEPA',
      value: paymentGateways.SEPA,
    },
  ];

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const { data: locations, isFetching: isFetchingLocations } = useLocationsFind(
    {
      filter: { regionId: query.regionId },
      paging: { perPage: -1 },
    }
  );

  const {
    data: debitBatches,
    isFetching: isFetchingDebitBatches,
    // refetch: getDebitBatchesRequest,
  } = useDebitBatchesFindTenants({
    filter: query,
    paging,
    include: 'location',
  });

  const {
    isLoading: isCreatingDebitBatchResubmit,
    mutate: postDebitBatchResubmitRequest,
  } = useDebitBatchesCreateResubmit({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isFetching: isFetchingDebitBatchThreePeaksSubmissionInfo,
    // refetch: getDebitBatchThreePeaksSubmissionInfoRequest,
  } = useDebitBatchesFindByIdThreePeaksSubmissionInfo(
    {
      id: debitBatchId,
    },
    {
      enabled:
        !!debitBatchId && accountsAction === 'threePeaksSubmissionInformation',
      onSuccess: (response) => {
        setAccountsAction(undefined);
        setToggleThreePeaks({
          type: accountsAction,
          data: response,
        });
      },
    }
  );

  const {
    isFetching: isFetchingDebitBatchThreePeaksValidationInfo,
    // refetch: getDebitBatchThreePeaksValidationInfoRequest,
  } = useDebitBatchesFindByIdThreePeaksValidationInfo(
    {
      id: debitBatchId,
    },
    {
      enabled:
        !!debitBatchId && accountsAction === 'threePeaksValidationInformation',
      onSuccess: (response) => {
        setAccountsAction(undefined);
        setToggleThreePeaks({
          type: accountsAction,
          data: response,
        });
      },
    }
  );

  const {
    isFetching: isFetchingDebitBatchThreePeaksRecallSubmission,
    // refetch: getDebitBatchThreePeaksRecallSubmissionRequest,
  } = useDebitBatchesFindByIdThreePeaksRecallSubmission(
    {
      id: debitBatchId,
    },
    {
      enabled:
        !!debitBatchId && accountsAction === 'threePeaksRecallSubmission',
      // onSuccess: getDebitBatchesRequest,
      onSuccess: () => {
        setAccountsAction(undefined);
      },
      meta: {
        useOnSuccessToast: true,
      },
    }
  );

  const {
    isFetching: isFetchingDebitBatchThreePeaksSubmissionDebits,
    // refetch: getDebitBatchThreePeaksSubmissionDebitsRequest,
  } = useDebitBatchesFindByIdThreePeaksSubmissionDebits(
    {
      id: debitBatchId,
    },
    {
      enabled:
        !!debitBatchId && accountsAction === 'threePeaksSubmissionDebits',
      onSuccess: (response) => {
        setAccountsAction(undefined);
        setToggleThreePeaks({
          type: accountsAction,
          data: response,
        });
      },
    }
  );

  const {
    isLoading: isCreatingDebitBatchThreePeaksReconcile,
    mutate: postDebitBatchThreePeaksReconcileRequest,
  } = useDebitBatchesCreateReconcileThreePeaksBatch({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isFetching: isFetchingDebitBatchThreePeaksSubmissionHistory,
    // refetch: getDebitBatchThreePeaksSubmissionHistoryRequest,
  } = useDebitBatchesFindByIdThreePeaksSubmissions(
    {
      id: locationId,
    },
    {
      enabled: !!locationId,
      onSuccess: (response) => {
        setAccountsAction(undefined);
        setLocationId(undefined);
        setToggleThreePeaks({
          type: accountsAction,
          data: _.reverse(response),
        });
      },
    }
  );

  const onClickAction = (values) => {
    const { action, id } = values;
    setAccountsAction(action);
    switch (action) {
      case 'resubmit':
        setToggleResubmit({
          type: 'resubmit',
          data: debitBatches?.data?.find((item) => item.id === id),
        });
        break;

      case 'threePeaksSubmissionHistory':
        setLocationId(values?.locationId);
        break;

      case 'threePeaksReconcileBatch':
        postDebitBatchThreePeaksReconcileRequest({ id });
        break;

      case 'threePeaksSubmissionInformation':
        setDebitBatchId(id);
        break;

      case 'threePeaksValidationInformation':
        setDebitBatchId(id);
        break;

      case 'threePeaksRecallSubmission':
        if (window.confirm(t('areYouSureProceed'))) {
          setDebitBatchId(id);
        }
        break;

      case 'threePeaksSubmissionDebits':
        setDebitBatchId(id);
        break;

      case 'goCardlessReconcile':
      case 'goCardlessCancel':
        toast.info(<Toast title={t('comingSoon')} variant='info' />);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleResubmit.isVisible && (
        <Modal
          isSidebar
          title={t('resubmitBatch')}
          onClose={resetToggleResubmit}
        >
          <FormResubmit
            initialValues={toggleResubmit.data}
            isLoading={isCreatingDebitBatchResubmit}
            onSubmit={(values) => postDebitBatchResubmitRequest(values)}
          />
        </Modal>
      )}

      {toggleThreePeaks.isVisible && (
        <Modal title='Three Peaks' onClose={resetToggleThreePeaks}>
          {toggleThreePeaks.type === 'threePeaksSubmissionDebits' ? (
            <TableThreePeaksSubmissionDebits data={toggleThreePeaks.data} />
          ) : toggleThreePeaks.type === 'threePeaksValidationInformation' ? (
            <TableThreePeaksValidationInformation
              data={toggleThreePeaks?.data || []}
            />
          ) : toggleThreePeaks.type === 'threePeaksSubmissionInformation' ? (
            <TableThreePeaksSubmissionInformationHistory
              data={[toggleThreePeaks?.data]}
            />
          ) : toggleThreePeaks.type === 'threePeaksSubmissionHistory' ? (
            <TableThreePeaksSubmissionInformationHistory
              data={toggleThreePeaks?.data || []}
            />
          ) : null}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('debitBatchControl'),
          breadcrumbs: [t('accounts')],
        }}
        isLoading={
          isFetchingDebitBatches ||
          isCreatingDebitBatchThreePeaksReconcile ||
          isFetchingDebitBatchThreePeaksSubmissionInfo ||
          isFetchingDebitBatchThreePeaksValidationInfo ||
          isFetchingDebitBatchThreePeaksRecallSubmission ||
          isFetchingDebitBatchThreePeaksSubmissionDebits ||
          isFetchingDebitBatchThreePeaksSubmissionHistory
        }
      >
        <FormQuery
          initialValues={query}
          isFetchingLocations={isFetchingLocations}
          isFetchingRegions={isFetchingRegions}
          locations={locations?.data || []}
          paymentGatewayOptions={paymentGatewayOptions}
          regions={regions?.data || []}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <TableDebitBatches
          data={isFetchingDebitBatches ? [] : debitBatches?.data}
          isLoading={isFetchingDebitBatches}
          paging={debitBatches?.paging}
          paymentGatewayOptions={paymentGatewayOptions}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
