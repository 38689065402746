import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  currencies,
  initialValues = {},
  isCreate,
  isFetchingCurrencies,
  isFetchingRegions,
  isLoading,
  regions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        description: initialValues.description || undefined,
        regionId: initialValues.regionId || undefined,
        tenantBillingCurrencyId:
          initialValues.tenantBillingCurrencyId || undefined,
        memberBillingCurrencyId:
          initialValues.memberBillingCurrencyId || undefined,
        timezoneId: initialValues.timezoneId || undefined,
        isTrial: initialValues.isTrial,
        facebookUrl: initialValues.facebookUrl || '',
        websiteUrl: initialValues.websiteUrl || '',
        instagramUrl: initialValues.instagramUrl || '',
        ...(isCreate
          ? {
              ownerName: undefined,
              ownerSurname: undefined,
              ownerGenderId: undefined,
              ownerEmail: undefined,
              ownerMobile: undefined,
              ownerDateOfBirth: undefined,
            }
          : {}),
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        description: Yup.string(),
        regionId: Yup.string().required(t('required')),
        tenantBillingCurrencyId: Yup.string().required(t('required')),
        memberBillingCurrencyId: Yup.string().required(t('required')),
        timezoneId: Yup.string().required(t('required')),
        facebookUrl: Yup.string(),
        websiteUrl: Yup.string(),
        instagramUrl: Yup.string(),
        isTrial: Yup.bool(),
        ...(isCreate
          ? {
              ownerName: Yup.string().required(t('required')),
              ownerSurname: Yup.string().required(t('required')),
              ownerGenderId: Yup.string(),
              ownerEmail: Yup.string()
                .email(t('invalidEmail'))
                .required(t('required')),
              ownerMobile: Yup.string().required(t('required')),
              ownerDateOfBirth: Yup.string(),
            }
          : {}),
      })}
    >
      {({ handleSubmit, values: { regionId }, setFieldValue }) => {
        const selectedRegion = regions.find((item) => item.id === regionId);
        const tenantTimezones = selectedRegion?.timezones || [];
        const tenantCurrencies = selectedRegion?.currencies || [];

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col lg={4} md={6}>
                <Field label='Name' name='name' />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isSelect
                  isLoading={isFetchingRegions}
                  label='Region'
                  name='regionId'
                  options={regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  onChange={(value) => {
                    setFieldValue('regionId', value);
                    setFieldValue('timezoneId', null);
                    setFieldValue('tenantBillingCurrencyId', null);
                  }}
                />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isSelect
                  isDisabled={!selectedRegion}
                  isLoading={isFetchingRegions}
                  label='Timezone'
                  name='timezoneId'
                  options={tenantTimezones.map((item) => ({
                    label: `${item.name} (${item.zone})`,
                    value: item.id,
                  }))}
                />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isSelect
                  isDisabled={!selectedRegion}
                  isLoading={isFetchingRegions}
                  label='Billing Currency'
                  name='tenantBillingCurrencyId'
                  options={tenantCurrencies.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isSelect
                  isLoading={isFetchingCurrencies}
                  label='Member Billing Currency'
                  name='memberBillingCurrencyId'
                  options={currencies.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Col>
            </Row>
            <Row mt={2}>
              <Col lg={4} md={6}>
                <Field
                  isLoading={isFetchingRegions}
                  label='Website URL'
                  name='websiteUrl'
                />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isLoading={isFetchingRegions}
                  label='Facebook URL'
                  name='facebookUrl'
                />
              </Col>

              <Col lg={4} md={6}>
                <Field
                  isLoading={isFetchingRegions}
                  label='Instagram URL'
                  name='instagramUrl'
                />
              </Col>

              <Col>
                <Field as='textarea' label='Description' name='description' />
              </Col>

              {isCreate && (
                <>
                  <Col lg={4} md={6}>
                    <Field label='Owner Name' name='ownerName' />
                  </Col>

                  <Col lg={4} md={6}>
                    <Field label='Owner Surname' name='ownerSurname' />
                  </Col>

                  <Col lg={4} md={6}>
                    <Field
                      isSelect
                      label='Owner Gender'
                      name='ownerGenderId'
                      options={genderOptions}
                    />
                  </Col>

                  <Col lg={4} md={6}>
                    <Field label='Owner Email' name='ownerEmail' />
                  </Col>

                  <Col lg={4} md={6}>
                    <Field label='Owner Mobile' name='ownerMobile' />
                  </Col>

                  <Col lg={4} md={6}>
                    <Field
                      isDate
                      label='Owner Date Of Birth'
                      name='ownerDateOfBirth'
                    />
                  </Col>
                </>
              )}

              <Col>
                <Field
                  isCheckbox
                  name='isTrial'
                  options={[
                    {
                      label: 'Is this facility in a trial state?',
                      value: 1,
                    },
                  ]}
                />
              </Col>

              <Col>
                <Button
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};
