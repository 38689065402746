import { Icon, ProcessingSpinner, Text, Tooltip } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import { determineScale } from 'octiv-utilities/Functions';
import React from 'react';
import { Link } from 'react-router-dom';

import { StyledButton } from './Styled';

const BaseButton = ({
  children,
  color = 'primary',
  hasBorder,
  icon,
  iconProps,
  isDisabled,
  isFullWidth,
  isLoading,
  processingSpinnerProps,
  scale = 'regular',
  text,
  textProps,
  ...props
}) => {
  const { xsDown } = useMediaQuery();

  const computedColor = hasBorder ? color : 'button';
  const computedText = determineScale({
    scale,
    scales: xsDown
      ? ['tiny', 'tiny', 'caption', 'body']
      : ['tiny', 'caption', 'body', 'body'],
  });

  return (
    <StyledButton
      hasRadius
      isFlex
      alignItems='center'
      as='button'
      bg={hasBorder ? 'transparent' : color}
      border={hasBorder ? [1] : 'none'}
      borderColor={hasBorder ? color : undefined}
      disabled={isLoading || isDisabled}
      justifyContent='center'
      minHeight={determineScale({
        scale,
        scales: xsDown ? [5, 9, 13, 17] : [6, 10, 14, 18],
      })}
      minWidth={
        isFullWidth
          ? '100%'
          : determineScale({
              scale,
              scales: xsDown ? [15, 25, 35, 45] : [25, 35, 45, 50],
            })
      }
      p={0}
      px={xsDown ? 2 : 4}
      type='button'
      {...props}
    >
      {text && !isLoading && (
        <Text
          isBold
          isUpperCase
          color={computedColor}
          lineHeight='initial'
          textAlign='center'
          variant={computedText}
          {...textProps}
        >
          {text}
        </Text>
      )}

      {icon && !isLoading && (
        <Icon
          color={computedColor}
          ml={text ? 1 : undefined}
          scale={scale}
          {...iconProps}
          name={icon}
        />
      )}

      {isLoading && (
        <ProcessingSpinner
          borderColor={computedColor}
          ml={text ? 1 : undefined}
          {...processingSpinnerProps}
        />
      )}

      {children}
    </StyledButton>
  );
};

const LinkButton = ({ to, linkProps, isDisabled, ...props }) =>
  to ? (
    <Link to={to} {...linkProps}>
      <BaseButton isDisabled={isDisabled} {...props} />
    </Link>
  ) : (
    <BaseButton isDisabled={isDisabled} {...props} />
  );

const TooltipButton = ({ tooltip, tooltipProps, ...props }) => (
  <Tooltip placement='top' text={tooltip} trigger='hover' {...tooltipProps}>
    <LinkButton {...props} />
  </Tooltip>
);

const Button = (props) => {
  const { xsDown } = useMediaQuery();

  const { tooltip, isDisabled } = props;

  if ((tooltip && !xsDown) || (tooltip && xsDown && isDisabled)) {
    return <TooltipButton {...props} />;
  }

  return <LinkButton {...props} />;
};

export default Button;
