import { Box, Chart, Container } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useClassesFind } from 'octiv-hooks/requests/Classes';
import { useReportsFindAttendanceOverview } from 'octiv-hooks/requests/Reports';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { userStatus } from 'octiv-utilities/Constants';
import { getDateYear, getMonths } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const [report, setReport] = useState([]);
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    classId: undefined,
    userId: undefined,
    bookingStatusId: 1,
    year: getDateYear({ canFallback: true }),
  });

  const { isFetching: isFetchingReport } = useReportsFindAttendanceOverview(
    { filter: query },
    {
      onSuccess: (response) => {
        const data = getMonths();

        Object.keys(response).forEach((key, index) => {
          data[index][t('amount')] = response[key];
        });

        setReport(data);
      },
    }
  );

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers({
    filter: {
      userTenantStatusId: userStatus.ACTIVE,
      isMinimalData: true,
    },
    paging: {
      perPage: -1,
    },
  });

  const { data: classDates, isFetching: isFetchingClassDates } = useClassesFind(
    {
      filter: query,
      paging: {
        perPage: -1,
      },
      sort: 'name',
    }
  );

  return (
    <Container
      appBarProps={{
        title: t('overview'),
        breadcrumbs: [t('reports'), t('attendance')],
      }}
      isLoading={isFetchingReport}
    >
      <FormQuery
        classDates={classDates?.data || []}
        initialValues={query}
        isFetchingClassDates={isFetchingClassDates}
        isFetchingMembers={isFetchingMembers}
        locationOptions={locationOptions}
        members={members?.data || []}
        onSubmit={setQuery}
      />

      <Box height={4} />

      <Chart
        isBar
        data={report}
        isLoading={isFetchingReport}
        isVisibleLegend={false}
      />
    </Container>
  );
};
