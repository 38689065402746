import DiscoveryDashboard from 'octiv-containers/discovery/dashboard';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { getStacksPublic } from './Public';

export const getStacksDiscovery = ({ t, onRedirectPath, onLoginPath } = {}) => [
  {
    options: {
      isVisibleInNavigation: true,
      isVisibleNavigation: true,
      isSecure: false,
    },
    title: t('dashboard'),
    icon: 'dashboard',
    screens: [
      {
        name: 'DiscoveryDashboard',
        path: '/dashboard',
        element: <DiscoveryDashboard />,
      },
    ],
  },

  ...getStacksPublic({ onRedirectPath: '/dashboard', onLoginPath }),

  {
    options: {
      isVisibleInNavigation: false,
      isSecure: false,
    },
    screens: [
      {
        name: 'BaseUrl',
        path: '/',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
      {
        name: 'NotFound',
        path: '*',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
    ],
  },
];
