import React from 'react';

import Form from './Form';

export default ({ isTypeEmail, onClickProceed, selectedRecipients = [] }) => (
  <Form
    initialValues={{ recipients: selectedRecipients }}
    isTypeEmail={isTypeEmail}
    onSubmit={(values) => {
      onClickProceed({ recipients: values.recipients });
    }}
  />
);
