import { Box, Icon, Image, Text, Tooltip } from 'octiv-components';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const Dropzone = React.forwardRef(
  (
    {
      containerProps,
      value,
      onRemoveImage,
      useFileIcon,
      canOpen,
      onDownload,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const {
      fileRejections,
      getInputProps,
      getRootProps,
      isDragAccept,
      isDragActive,
      isDragReject,
    } = useDropzone({
      multiple: false,
      maxFiles: 1,
      maxSize: 4194304,
      ...props,
    });

    const renderIconImage = () =>
      useFileIcon || onDownload ? (
        <Box
          hasRadiusTop
          isFlex
          alignItems='center'
          border='1px solid'
          borderBottom='none'
          borderColor='grey3'
          justifyContent='center'
          size={24}
        >
          <Icon
            color={onDownload ? 'primary' : 'grey2'}
            name={onDownload ? 'download' : 'description'}
            scale='large'
          />
        </Box>
      ) : (
        <Image
          hasRadiusTop
          bg='grey7'
          size={24}
          src={value?.path ? URL.createObjectURL(value) : value}
        />
      );

    return (
      <Box isFlex flexDirection='row'>
        {value && (
          <Box mr={4}>
            {onDownload || canOpen ? (
              <Box onClick={onDownload || (() => window.open(value))}>
                <Tooltip
                  delayShow={300}
                  placement='top'
                  text={onDownload ? t('clickToDownload') : t('clickToOpen')}
                  trigger='hover'
                >
                  {renderIconImage()}
                </Tooltip>
              </Box>
            ) : (
              renderIconImage()
            )}

            <Box
              hasRadiusBottom
              isFlex
              alignItems='center'
              borderColor='danger'
              borderStyle='solid'
              borderWidth='1px'
              height={6}
              justifyContent='center'
              px={2}
              py={1}
              onClick={onRemoveImage}
            >
              <Text color='danger' textAlign='center' variant='caption'>
                {t('remove')}
              </Text>
            </Box>
          </Box>
        )}

        <Box
          hasRadius
          isFlex
          alignItems='center'
          borderColor={
            isDragAccept
              ? 'success'
              : isDragReject
              ? 'danger'
              : isDragActive
              ? 'info'
              : 'grey2'
          }
          borderStyle='dashed'
          borderWidth='2px'
          flex={1}
          flexDirection='column'
          height={30}
          justifyContent='center'
          {...containerProps}
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
            refKey: ref,
          })}
        >
          <input {...getInputProps()} />

          <Box pointerEvents='none'>
            <Text textAlign='center'>{t('dragFileOrSelectFromDevice')}</Text>

            <Text color='grey1' mt='2px' textAlign='center' variant='caption'>
              {t('maximumFileSize')}
            </Text>

            {fileRejections.map(({ file, errors }) => (
              <Text
                color='danger'
                key={file.path}
                mt={1}
                textAlign='center'
                variant='caption'
              >
                {`${file.path} ${errors.map((error) =>
                  error.code === 'file-too-large'
                    ? t('maximumFileSizeExceeded')
                    : error.code === 'too-many-files'
                    ? t('maximumFilesExceeded')
                    : error.code === 'file-invalid-type'
                    ? t('isInvalidFileType')
                    : error.message
                )}`}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Dropzone;
