import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useAttendanceRecordsCreateCheckIn = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/attendance-records/check-in`,
        data: {
          code: data.code,
          classBookingId: data.classBookingId,
          classDateId: data.classDateId,
          healthProviderId: data.healthProviderId,
          idNumber: data.idNumber,
          name: data.name,
          surname: data.surname,
          dateOfBirth: data.dateOfBirth,
        },
        omitAuthToken: options?.meta?.omitAuthToken,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAttendanceRecordsCreateCheckOut = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/attendance-records/check-out`,
        data: {
          code: data.code,
          classBookingId: data.classBookingId,
          idNumber: data.idNumber,
        },
        omitAuthToken: options?.meta?.omitAuthToken,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAttendanceRecordsDeleteCancelCheckIn = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/attendance-records/cancel-check-in/${data.classBookingId}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};
