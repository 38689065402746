import { Table, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYearTime,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('sentOn'),
        accessor: 'sentOn',
        Cell: ({ value }) => getDateReadableDayMonthYearTime({ date: value }),
      },
      {
        Header: 'Scheduled For',
        accessor: 'nextScheduledFor',
        Cell: ({ value }) => getDateReadableDayMonthYearTime({ date: value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ value: id }) => (
          <Tooltip
            options={[
              { label: t('view'), value: 'view' },
              ...(query.status === 'upcoming'
                ? [{ label: t('edit'), to: `/comms/update/${id}` }]
                : []),
              {
                label: t('duplicate'),
                value: 'duplicate',
              },
              { label: t('delete'), value: 'delete' },
            ]}
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [query.status, onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(query.status === 'upcoming' ? ['sentOn'] : ['scheduledFor']),
      ...(query.type ? ['type'] : []),
    ],
    [query.status, query.type]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
