import { Images } from 'octiv-assets';
import {
  Box,
  Container,
  Image,
  Modal,
  ProcessingBar,
  Text,
} from 'octiv-components';
import { usePrivacyPolicyFind } from 'octiv-hooks/requests/PrivacyPolicy';
import { useTermsOfUseFind } from 'octiv-hooks/requests/TermsOfUse';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({ type = 'privacyPolicy', onClose }) => {
  const { t } = useTranslation();

  const { isFetching: isFetchingPrivacyPolicy, data: dataPrivacyPolicy } =
    usePrivacyPolicyFind(
      {},
      {
        enabled: type === 'privacyPolicy',
      }
    );

  const { isFetching: isFetchingTermsOfUse, data: dataTermsOfUse } =
    useTermsOfUseFind(
      {},
      {
        enabled: type === 'termsOfUse',
      }
    );

  const isLoading = isFetchingPrivacyPolicy || isFetchingTermsOfUse;
  const data = dataPrivacyPolicy || dataTermsOfUse;

  const renderContent = () => (
    <Text
      as='div'
      className='terms'
      dangerouslySetInnerHTML={{
        __html: data?.content,
      }}
    />
  );

  if (onClose) {
    return (
      <>
        {isLoading && <ProcessingBar />}

        <Modal
          title={
            type === 'privacyPolicy' ? t('privacyPolicy') : t('termsOfUse')
          }
          onClose={onClose}
        >
          {!isLoading && renderContent()}
        </Modal>
      </>
    );
  }

  return (
    <Container
      appBarProps={{
        title: type === 'privacyPolicy' ? t('privacyPolicy') : t('termsOfUse'),
        children: (
          <Box ml='auto'>
            <Image
              hasRadius
              alt={t('octivLogo')}
              backgroundSize='contain'
              height={10}
              src={Images.logoLight}
              width={34}
            />
          </Box>
        ),
      }}
      isLoading={isLoading}
    >
      {renderContent()}
    </Container>
  );
};
