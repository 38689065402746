import { ProcessingBar, Step } from 'octiv-components';
import { ValidateUser } from 'octiv-containers';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useUsersCreate } from 'octiv-hooks/requests/Users';
import { useUserTenantsCreate } from 'octiv-hooks/requests/UserTenants';
import React, { useState } from 'react';

import FormUser from './FormUser';
import FormUserTenant from './FormUserTenant';

export default ({ onSuccess }) => {
  const {
    typeId,
    tenantId,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [user, setUser] = useState();
  const isUser = !!user?.id;

  const usersCreate = useUsersCreate({
    meta: {
      onSuccessToast: {
        title: 'User created',
      },
    },
    onSuccess: setUser,
  });

  const userTenantsCreate = useUserTenantsCreate({
    meta: {
      onSuccessToast: {
        title: 'User account created',
      },
    },
    onSuccess,
  });

  return (
    <>
      {userTenantsCreate.isLoading && <ProcessingBar />}

      <Step hasBorder boxContainerProps={{ mb: 4 }} title='Octiv Account'>
        <ValidateUser setUser={setUser} user={user} />
      </Step>

      {user?.email && !isUser ? (
        <Step hasBorder boxContainerProps={{ mb: 4 }} title='User Details'>
          <FormUser
            isLoading={usersCreate.isLoading}
            onSubmit={(values) =>
              usersCreate.mutate({ ...values, email: user.email })
            }
          />
        </Step>
      ) : (
        user?.email && (
          <Step hasBorder boxContainerProps={{ mb: 4 }} title='Account Details'>
            <FormUserTenant
              isLoading={userTenantsCreate.isLoading}
              locationOptions={locationOptions}
              loggedInUserTypeId={typeId}
              onSubmit={(values) =>
                userTenantsCreate.mutate({
                  ...values,
                  tenantId,
                  userId: user.id,
                })
              }
            />
          </Step>
        )
      )}
    </>
  );
};
