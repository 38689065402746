import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues,
  tenants,
  isFetchingTenants,
  isFetchingLocations,
  isFetchingRegions,
  isLoading,
  locations,
  onGetTenantsRequest,
  onGetLocationsRequest,
  regions,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Formik
      {...props}
      initialValues={{
        userType: initialValues?.type,
        statusId: initialValues?.statusId,
        regionId: initialValues?.regionId,
        tenantStatusId: initialValues?.tenantStatusId,
        tenantId: initialValues?.tenantId,
        isLocationActive: initialValues?.isLocationActive,
        locationId: initialValues?.locationId,
      }}
      validationSchema={Yup.object().shape({
        userType: Yup.string(),
        statusId: Yup.string(),
        regionId: Yup.string(),
        tenantStatusId: Yup.string(),
        tenantId: Yup.string(),
        isLocationActive: Yup.string(),
        locationId: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        values: { regionId, tenantStatusId, tenantId, isLocationActive },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                label={t('userType')}
                name='userType'
                options={[
                  {
                    label: t('all'),
                    value: 'all',
                  },
                  {
                    label: t('staff'),
                    value: 'staff',
                  },
                  {
                    label: t('members'),
                    value: 'member',
                  },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                label='User Status'
                name='statusId'
                options={[
                  { label: t('active'), value: 2 },
                  { label: t('pending'), value: 1 },
                  { label: t('suspended'), value: 3 },
                  // TODO: translations
                  { label: 'De-activated', value: 4 },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                doFinally={(newRegionId) =>
                  onGetTenantsRequest({
                    regionId: newRegionId,
                    tenantStatusId,
                  })
                }
                isLoading={isFetchingRegions}
                label={t('region')}
                name='regionId'
                options={[
                  { label: t('all'), value: undefined },
                  ...regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                doFinally={(newTenantStatusId) =>
                  onGetTenantsRequest({
                    regionId,
                    tenantStatusId: newTenantStatusId,
                  })
                }
                label='Facility Status'
                name='tenantStatusId'
                options={[
                  { label: t('active'), value: 1 },
                  { label: t('deactivated'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  // TODO: translations
                  { label: 'Deleted', value: 4 },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                doFinally={(newTenantId) =>
                  onGetLocationsRequest({
                    tenantId: newTenantId,
                    isLocationActive,
                  })
                }
                isLoading={isFetchingTenants}
                label={t('tenant')}
                name='tenantId'
                options={[
                  { label: t('all'), value: undefined },
                  ...tenants.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                doFinally={(newIsLocationActive) =>
                  onGetLocationsRequest({
                    tenantId,
                    isLocationActive: newIsLocationActive,
                  })
                }
                isDisabled={!tenantId}
                label='Location Status'
                name='isLocationActive'
                options={[
                  { label: t('active'), value: true },
                  { label: t('deactivated'), value: false },
                ]}
              />
            </Col>

            <Col lg={3} md={4}>
              <Field
                hasAlternateBackground
                isSelect
                isDisabled={!tenantId}
                isLoading={isFetchingLocations}
                label={t('location')}
                name='locationId'
                options={[
                  { label: t('all'), value: undefined },
                  ...locations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
