import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  hasFullAccess,
  isFetchingUsersTrainers,
  isLoading,
  locationOptions,
  usersTrainers,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        isSession: Yup.bool(),
        locationId: Yup.string().required(t('required')),
        instructorId: Yup.string(),
        supportingInstructorId: Yup.string(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} md={6}>
              <Field
                canSubmitOnChange
                isHorizontal
                isRadio
                isDisabled={!hasFullAccess}
                label={t('eventTypes')}
                name='isSession'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('classes'), value: false },
                  { label: t('sessions'), value: true },
                ]}
              />
            </Col>

            <Col lg={hasFullAccess ? 4 : 3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={hasFullAccess ? 4 : 3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col lg={hasFullAccess ? 4 : 3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>

            <Col lg={hasFullAccess ? 4 : 3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isDisabled={!hasFullAccess}
                isLoading={isFetchingUsersTrainers}
                label={t('trainer')}
                name='instructorId'
                options={[
                  { label: t('all'), value: undefined },
                  ...usersTrainers.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            {hasFullAccess && (
              <Col lg={4} md={6}>
                <Field
                  canSubmitOnChange
                  hasAlternateBackground
                  isSelect
                  isLoading={isFetchingUsersTrainers}
                  label={t('supportingTrainer')}
                  name='supportingInstructorId'
                  options={[
                    { label: t('all'), value: undefined },
                    ...usersTrainers.map((item) => ({
                      label: getFullName(item),
                      value: item.id,
                    })),
                  ]}
                />
              </Col>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
