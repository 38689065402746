import moment from 'moment';
import { Box, Container } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useReportsFindFinancePaymentTypes } from 'octiv-hooks/requests/Reports';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const { data: report, isFetching: isFetchingReport } =
    useReportsFindFinancePaymentTypes({
      filter: query,
    });

  return (
    <Container
      appBarProps={{
        title: t('paymentTypes'),
        breadcrumbs: [t('reports'), t('finance')],
      }}
      isLoading={isFetchingReport}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Box height={4} />

      <TableReport data={report} isLoading={isFetchingReport} />
    </Container>
  );
};
