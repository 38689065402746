import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        paymentOptions: initialValues || [],
      }}
      validationSchema={Yup.object().shape({
        paymentOptions: Yup.array(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isArray
                isCheckbox
                label={t('enableMethods')}
                name='paymentOptions'
                options={[
                  { label: t('cash'), value: 'cash' },
                  { label: t('card'), value: 'card' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('debitOrder'), value: 'debit_order' },
                  { label: t('adhoc'), value: 'adhoc' },
                  { label: t('nonPaymentInvoiceOnly'), value: 'non_payment' },
                ]}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
