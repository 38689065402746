import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        paymentType: Yup.string(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentType')}
                name='paymentType'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('cash'), value: 'cash' },
                  { label: t('card'), value: 'card' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('debitOrder'), value: 'debit_order' },
                  { label: t('adhoc'), value: 'adhoc' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateFrom')}
                name='startDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                label={t('dateTo')}
                name='endDate'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
