import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, locations, isFetchingLocations, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        locationId: undefined,
      }}
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                isLoading={isFetchingLocations}
                label={t('location')}
                name='locationId'
                options={
                  locations
                    ? locations.map((item) => ({
                        label: item.tenant?.name,
                        options: item.locations?.map((location) => ({
                          label: location?.name,
                          value: location?.id,
                        })),
                      }))
                    : []
                }
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
