import { Box, Text, ToggleTabs } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';

const Card = ({
  boxChildrenContainerProps,
  boxTitleContainerProps,
  boxTitleContainerRenderRight,
  boxToggleTabsContainerProps,
  children,
  hasAlternateBackground,
  hasBorder,
  hasPaddingChildren = true,
  onClickTitle,
  renderAboveChildren,
  textTitleProps,
  title,
  toggleTabsProps,
  ...props
}) => {
  const { xsDown, mdDown } = useMediaQuery();

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  return (
    <Box
      hasRadius
      bg={hasAlternateBackground ? 'grey5' : 'grey6'}
      {...(hasBorder
        ? {
            borderColor: 'grey4',
            borderStyle: 'solid',
            borderWidth: '1px',
          }
        : {})}
      {...props}
    >
      {(title || boxTitleContainerRenderRight) && (
        <Box
          hasRadiusTop
          isFlex
          alignItems='center'
          justifyContent='space-between'
          p={padding}
          pb={2}
          {...boxTitleContainerProps}
        >
          <Text
            isBold
            lineHeight='normal'
            variant={xsDown ? 'subheading' : 'heading'}
            onClick={onClickTitle}
            {...textTitleProps}
          >
            {title}
          </Text>

          {boxTitleContainerRenderRight && boxTitleContainerRenderRight()}
        </Box>
      )}

      {toggleTabsProps && (
        <Box p={2} pt={0} {...boxToggleTabsContainerProps}>
          <ToggleTabs
            borderColor={hasAlternateBackground ? 'grey4' : 'grey5'}
            {...toggleTabsProps}
          />
        </Box>
      )}

      {renderAboveChildren && renderAboveChildren()}

      {children && (
        <Box
          {...(hasPaddingChildren
            ? {
                p: padding,
                pt:
                  title || boxTitleContainerRenderRight
                    ? xsDown
                      ? 2
                      : 3
                    : padding,
              }
            : {})}
          {...boxChildrenContainerProps}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Card;
