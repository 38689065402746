import { InlineUser, Table, Text } from 'octiv-components';
import { toCase, toCurrency } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, currency, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: `GC ${t('amount')}`,
        id: 'amount',
        Cell: ({
          row: {
            original: { amount },
          },
        }) => toCurrency({ code: currency, value: Number(amount) }),
      },
      {
        Header: `GC ${t('type')}`,
        accessor: 'type',
        Cell: ({ value }) => toCase({ value, shouldCapitalizeAll: true }),
      },
      {
        Header: `GC ${t('paymentId')}`,
        accessor: 'paymentId',
      },
      {
        Header: t('invoiceCode'),
        accessor: 'invoice.code',
      },
      {
        Header: t('invoiceUser'),
        accessor: 'invoice.user',
        // TODO: userTenant
        Cell: ({ value }) =>
          value ? <InlineUser isVisibleUserImage={false} user={value} /> : null,
      },
      {
        Header: t('viewInvoice'),
        accessor: 'invoice.id',
        Cell: ({ value }) =>
          value && value.deleted ? (
            <Text as='span' color='danger'>
              {t('invoiceDeleted')}
            </Text>
          ) : value ? (
            <Text
              as='span'
              color='primary'
              onClick={() =>
                window.open(`/accounts/invoices?invoiceId=${value}`)
              }
            >
              {t('view')}
            </Text>
          ) : null,
      },
    ],
    [t, currency]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
