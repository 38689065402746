import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingPackageProgrammes,
  isLoading,
  packageProgrammes,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        programmeIds: isFetchingPackageProgrammes
          ? []
          : packageProgrammes
              .filter((item) => item.isChecked)
              .map((item) => item.id),
      }}
      validationSchema={Yup.object().shape({
        programmeIds: Yup.array().required(t('required')).min(1, t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                hasSelectAll
                isArray
                isCheckbox
                isLoading={isFetchingPackageProgrammes}
                label={t('programmes')}
                name='programmeIds'
                options={packageProgrammes?.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
