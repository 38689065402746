import {
  Box,
  BulkActions,
  Button,
  Container,
  Divider,
  Modal,
  ToggleTabs,
} from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useAffiliatesCreateLink,
  useAffiliatesCreateUnlink,
  useAffiliatesFind,
} from 'octiv-hooks/requests/Affiliates';
import { useLocationCategoriesFind } from 'octiv-hooks/requests/LocationCategories';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import { useTenantsFind, useTenantsUpdate } from 'octiv-hooks/requests/Tenants';
import { getSelectedRows } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Affiliates from './affiliates';
import FormAffiliates from './affiliates/FormAffiliates';
import FormQuery from './FormQuery';
import Locations from './locations';
import TableTenants from './TableTenants';
import Tenant from './tenant';

export const views = {
  ACCOUNT: 'account',
  AFFILIATES: 'affiliates',
  LOCATIONS: 'locations',
};

export default () => {
  const { t } = useTranslation();

  const [facilities, setFacilities] = useState([]);
  const [toggle, setToggle, resetToggle] = useToggle();
  const [selectedModalView, setSelectedModalView] = useState(views.ACCOUNT);
  const [paging, setPaging, resetPaging] = usePaging();
  const [query, setQuery] = useState({
    regionId: undefined,
    affiliateId: undefined,
    statusId: 1,
    search: undefined,
  });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
    include: 'timezones,currencies',
  });

  const {
    data: tenants,
    isFetching: isFetchingTenants,
    refetch: getTenantsRequest,
  } = useTenantsFind(
    {
      filter: query,
      include:
        'affiliations,region,nonDeactivatedUsersCount,locations.category',
      paging,
    },
    {
      onSuccess: (response) => {
        setPaging(response?.meta?.paging);
        setFacilities(response?.data || []);
      },
    }
  );

  const { isLoading: isUpdatingTenantStatus, mutate: putTenantStatusRequest } =
    useTenantsUpdate({
      onSuccess: getTenantsRequest,
    });

  const affiliatesFind = useAffiliatesFind();

  const affiliatesLink = useAffiliatesCreateLink({
    onSuccess: resetToggle,
  });

  const affiliatesUnlink = useAffiliatesCreateUnlink({
    onSuccess: resetToggle,
  });

  const { data: locationCategories, isFetching: isFetchingLocationCategories } =
    useLocationCategoriesFind();

  const onClickAction = ({ action, isBulk = false, id }) => {
    switch (action) {
      case 'manageAffiliates':
        if (isBulk) {
          setToggle({
            type: 'manageAffiliatesBulk',
            data: facilities?.map((f) => f.id),
          });
        } else {
          setToggle({
            type: 'manageAffiliates',
            data: id,
          });
        }
        break;
      case 'edit':
        setToggle({
          type: 'update',
          data: (tenants?.data || []).find((item) => item.id === id),
        });
        break;

      case 'activate':
        putTenantStatusRequest({ id, statusId: 1 });
        break;

      case 'deactivate':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 2 });
        }
        break;

      case 'suspend':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 3 });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          putTenantStatusRequest({ id, statusId: 4 });
        }
        break;

      default:
        break;
    }
  };

  const onSubmitBulk = (values) => {
    const { affiliateId, action } = values;
    const tenantIds = facilities
      ?.filter((item) => item.isSelected)
      .map((item) => item.id);

    if (action === 'link') {
      affiliatesLink.mutate({
        tenantIds,
        affiliateId,
      });
    } else if (action === 'unlink') {
      affiliatesUnlink.mutate({
        tenantIds,
        affiliateId,
      });
    }
  };

  const selectedData = getSelectedRows({ data: facilities });

  return (
    <>
      {toggle.isVisible &&
        !['manageAffiliates', 'manageAffiliatesBulk'].includes(toggle.type) && (
          <Modal
            renderAboveContent={() =>
              toggle.type === 'update' ? (
                <ToggleTabs
                  activeKey={selectedModalView}
                  mt={1}
                  options={[
                    { label: 'Account', key: views.ACCOUNT },
                    { label: 'Locations', key: views.LOCATIONS },
                    { label: 'Affiliates', key: views.AFFILIATES },
                  ]}
                  px={4}
                  setActiveKey={setSelectedModalView}
                />
              ) : null
            }
            title={`${
              toggle.type === 'create' ? t('create') : t('update')
            } Facility`}
            onClose={resetToggle}
          >
            {selectedModalView === views.ACCOUNT ? (
              <Tenant
                tenantId={toggle?.data?.id}
                onSuccessCreate={() => {
                  resetToggle();
                  getTenantsRequest();
                }}
              />
            ) : selectedModalView === views.AFFILIATES ? (
              <Affiliates tenantId={toggle?.data?.id} />
            ) : (
              <Locations
                tenantId={toggle.data?.id}
                timezones={
                  regions?.data?.find(
                    (item) => item.id === toggle.data?.region?.id
                  )?.timezones
                }
              />
            )}
          </Modal>
        )}

      {toggle.isVisible &&
      ['manageAffiliates', 'manageAffiliatesBulk'].includes(toggle.type) ? (
        <Modal
          isSidebar
          title={t('manageAffiliates')}
          onClose={() => {
            setToggle((prev) => ({
              ...prev,
              isVisible: false,
            }));
          }}
        >
          <FormAffiliates
            affiliates={affiliatesFind?.data || []}
            initialValues={{
              action: undefined,
            }}
            isLoading={
              affiliatesFind?.isFetching ||
              affiliatesLink.isLoading ||
              affiliatesUnlink.isLoading
            }
            type='update'
            onSubmit={onSubmitBulk}
          />
        </Modal>
      ) : null}

      <Container
        appBarProps={{
          title: t('manage'),
          breadcrumbs: ['Facilities'],
          children: (
            <Box ml='auto'>
              <Button
                text='Create Facility'
                onClick={() => setToggle({ type: 'create', data: {} })}
              />
            </Box>
          ),
        }}
        isLoading={isFetchingTenants || isUpdatingTenantStatus}
      >
        <FormQuery
          affiliates={affiliatesFind?.data || []}
          initialValues={query}
          isFetchingAffiliates={affiliatesFind.isFetching}
          isFetchingLocationCategories={isFetchingLocationCategories}
          isFetchingRegions={isFetchingRegions}
          locationCategories={locationCategories?.data || []}
          regions={regions?.data || []}
          onSubmit={(values) => {
            if (values?.search !== query?.search) {
              resetPaging();
            }
            setQuery(values);
          }}
        />

        <Divider pb={4} />

        <BulkActions
          data={selectedData}
          mb={4}
          options={[
            {
              icon: 'business',
              title: t('changeAffiliates'),
              value: 'manageAffiliates',
              color: 'info',
            },
          ]}
          onClick={({ value }) =>
            onClickAction({ isBulk: true, action: value })
          }
        />

        <TableTenants
          data={facilities || []}
          isLoading={isFetchingTenants}
          paging={tenants?.paging || {}}
          query={query}
          setPaging={setPaging}
          setSelected={setFacilities}
          onClickAction={onClickAction}
          onClickRegion={(regionId) =>
            setQuery((prev) => ({ ...prev, regionId }))
          }
        />
      </Container>
    </>
  );
};
