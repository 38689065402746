import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  ProcessingSuspense,
  Text,
} from 'octiv-components';
import { useSettingsFindContract } from 'octiv-hooks/requests/Settings';
import {
  useUserContractsCreateDownload,
  useUserContractsFindById,
  useUserContractsUpdateSign,
} from 'octiv-hooks/requests/UserContracts';
import { downloadFile } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FormSign from './FormSign';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const {
    data: contract,
    isFetching: isFetchingContract,
    refetch: getContractRequest,
  } = useUserContractsFindById(
    { id },
    {
      initialData: {},
    }
  );

  const { data: settingsContract, isFetching: isFetchingSettingsContract } =
    useSettingsFindContract(
      {},
      {
        enabled: !!(contract?.id && !contract?.termsAndConditions),
      }
    );

  const userContractsCreateDownload = useUserContractsCreateDownload({
    onSuccess: (response) => downloadFile(response.file),
  });

  const userContractsUpdateSign = useUserContractsUpdateSign({
    onSuccess: getContractRequest,
  });

  return (
    <Container
      appBarProps={{
        title: t('signContract'),
        children: contract?.accepted && (
          <Box ml='auto'>
            <Button
              isLoading={userContractsCreateDownload.isLoading}
              text={t('downloadContract')}
              onClick={() => userContractsCreateDownload.mutate({ id })}
            />
          </Box>
        ),
      }}
      isLoading={isFetchingContract || isFetchingSettingsContract}
    >
      {contract?.accepted && (
        <Alert mb={4} title={t('contractHasBeenSigned')} />
      )}

      <Card mb={4} title={t('termsConditions')}>
        {isFetchingContract || isFetchingSettingsContract ? (
          <ProcessingSuspense />
        ) : (
          <Text
            as='div'
            dangerouslySetInnerHTML={{
              __html:
                contract?.termsAndConditions ||
                settingsContract?.contractTermsAndConditions,
            }}
          />
        )}
      </Card>

      {contract?.id && !contract?.accepted && (
        <FormSign
          isLoading={userContractsUpdateSign.isLoading}
          onSubmit={() => userContractsUpdateSign.mutate({ id })}
        />
      )}
    </Container>
  );
};
