import React from 'react';

export const EventModalInitialState = {
  type: undefined,
  eventMasterId: undefined,
  eventInstanceId: undefined,
  initialEvent: undefined,
  onClose: undefined,
  setEventModal: () => {},
  resetEventModal: () => {},
};

export const EventModalContext = React.createContext(EventModalInitialState);

export const useEventModal = () => {
  const eventModal = React.useContext(EventModalContext);

  return eventModal;
};

export const EventModalContextProvider = ({ children }) => {
  const [eventModalContext, setEventModalContext] = React.useState(
    EventModalInitialState
  );

  React.useEffect(() => {
    setEventModalContext({
      ...EventModalInitialState,
      setEventModal: (values) =>
        setEventModalContext((prev) => ({
          ...prev,
          type: values.type || prev.type,
          eventMasterId: values.eventMasterId,
          eventInstanceId: values.eventInstanceId,
          initialEvent: values.initialEvent,
          onClose: values.onClose || prev.onClose,
        })),
      resetEventModal: () =>
        setEventModalContext((prev) => ({
          ...EventModalInitialState,
          setEventModal: prev.setEventModal,
          resetEventModal: prev.resetEventModal,
        })),
    });
  }, []);

  return (
    <EventModalContext.Provider value={eventModalContext}>
      {children}
    </EventModalContext.Provider>
  );
};
