import { InlineUser, Table } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getEventString,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => <InlineUser {...original} />,
      },
      {
        Header: t('class'),
        accessor: 'class',
        Cell: ({
          row: {
            original: {
              classDate: { name },
              class: { startTime, endTime },
            },
          },
        }) => getEventString({ name, startTime, endTime }),
      },
      {
        Header: t('date'),
        accessor: 'classDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value.date }),
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
