import { Formik } from 'formik';
import {
  Button,
  Col,
  Field,
  ProcessingSpinner,
  Row,
  Text,
} from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  typeId,
  initialValues = {},
  locationOptions,
  accessPrivileges,
  isFetchingAccessPrivileges,
  ...props
}) => {
  const { t } = useTranslation();

  const initialLocationAccessPrivileges = [];
  if (initialValues.locationAccessPrivileges && typeId !== 6) {
    initialValues.locationAccessPrivileges.forEach(
      ({ revoked, locationId }) => {
        if (!revoked) {
          initialLocationAccessPrivileges.push(locationId);
        }
      }
    );
  }

  const initialAccessPrivileges = [];
  if (initialValues.accessPrivileges) {
    initialValues.accessPrivileges.forEach(({ revoked, accessPrivilegeId }) => {
      if (!revoked) {
        initialAccessPrivileges.push(accessPrivilegeId);
      }
    });
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        ...(typeId !== 6
          ? {
              locationAccessPrivileges: initialLocationAccessPrivileges,
            }
          : {}),
        userAccessPrivileges: initialAccessPrivileges,
      }}
      validationSchema={Yup.object().shape({
        ...(typeId !== 6
          ? {
              locationAccessPrivileges: Yup.array()
                .required(t('required'))
                .min(1, t('required')),
            }
          : {}),
        userAccessPrivileges: Yup.array(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            {typeId !== 6 && (
              <Col>
                <Text mb={2} variant='heading'>
                  {t('locationAccess')}
                </Text>

                <Field
                  isArray
                  isCheckbox
                  name='locationAccessPrivileges'
                  options={locationOptions}
                />
              </Col>
            )}

            <Col>
              <Text mb={2} variant='heading'>
                {t('octivAccess')}
              </Text>

              {isFetchingAccessPrivileges ? (
                <ProcessingSpinner />
              ) : (
                <Field
                  isArray
                  isCheckbox
                  name='userAccessPrivileges'
                  options={accessPrivileges?.map((item) => ({
                    label: item.name,
                    labelSubText: item.description,
                    value: item.id,
                  }))}
                />
              )}
            </Col>
          </Row>

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
