import moment from 'moment';
import {
  Box,
  Button,
  Calendar,
  CalendarEvent,
  Container,
  Divider,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useEventModal } from 'octiv-context/EventModal';
import { useMediaQuery } from 'octiv-hooks';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import { tagsTypes } from 'octiv-utilities/Constants';
import {
  getDateFromTime,
  getDateTime,
  getDateYearMonthDay,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';

export default () => {
  const { t } = useTranslation();
  const { xsDown } = useMediaQuery();

  const {
    isTrainer,
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const { setEventModal } = useEventModal();

  const [events, setEvents] = useState([]);
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    between: undefined,
    instructorId: undefined,
    supportingInstructorId: undefined,
    classId: undefined,
    isSession: 0,
    tagIds: undefined,
  });

  const generatedToDate = getDateYearMonthDay({
    date: moment().endOf('isoWeek').subtract(1, 'week').add(105, 'days'),
  });

  const classDatesFind = useClassDatesFind(
    {
      filter: query,
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.between,
      // NOTE: changing this for now, as 15seconds was to frequent.
      // Result of  discussion with Darron and Mark, during standup.
      // refetchInterval: 15000,
      refetchInterval: 300000,
      onSuccess: ({ data }) => {
        setEvents(
          data.map((item) => ({
            start: getDateFromTime({ date: item.date, time: item.startTime }),
            end: getDateFromTime({ date: item.date, time: item.endTime }),
            extendedProps: item,
          }))
        );
      },
    }
  );

  // TODO: implement once done on backend
  const tagsFind = useTagsFind(
    {
      filter: {
        type: tagsTypes.LOCATION,
        locationId: query.locationId,
      },
      paging: { perPage: -1 },
    },
    { enabled: !!query?.locationId, refetchOnWindowFocus: false }
  );

  const usersFindStaff = useUsersFindStaff(
    {
      filter: {
        userTenantLocationId: query.locationId,
        isMinimalData: true,
      },
      paging: { perPage: -1 },
    },
    { refetchOnWindowFocus: false }
  );

  const onDatesSet = ({ start, end }) => {
    setQuery((prev) => {
      const startDate = getDateYearMonthDay({ date: moment(start) });
      const endDate = getDateYearMonthDay({
        date: moment(end).subtract(1, 'days'),
      });

      const between = `${startDate},${endDate}`;

      if (prev.between !== between) {
        return { ...prev, between };
      }

      return prev;
    });
  };

  return (
    <Container
      appBarProps={{
        title: t('calendar'),
        breadcrumbs: [t('scheduling')],
        children: (
          <Box ml='auto'>
            <Button
              text={t('createNew')}
              onClick={() => setEventModal({ type: 'create' })}
            />
          </Box>
        ),
      }}
      isLoading={classDatesFind.isInitialLoading || classDatesFind.isFetching}
    >
      <FormQuery
        initialValues={query}
        isFetchingLocationTags={tagsFind.isFetching}
        isFetchingUsersTrainers={usersFindStaff.isFetching}
        locationOptions={locationOptions}
        locationTags={tagsFind.data?.data}
        usersTrainers={usersFindStaff.data?.data}
        onSubmit={(values) =>
          setQuery((prev) => ({
            ...values,
            between: prev.between,
          }))
        }
      />

      <Divider pb={4} />

      <Calendar
        allDaySlot={false}
        dateClick={({ dateStr }) =>
          setEventModal({
            type: 'create',
            initialEvent: {
              recurringStartDate: getDateYearMonthDay({ date: dateStr }),
              onceOffDate: getDateYearMonthDay({ date: dateStr }),
              startTime: getDateTime({ date: dateStr }),
              location: { id: query.locationId },
              instructorId: isTrainer ? undefined : { id: query.instructorId },
            },
          })
        }
        datesSet={onDatesSet}
        eventClick={({ event: { extendedProps } }) =>
          setEventModal({
            type: 'view',
            eventInstanceId: extendedProps.id,
            initialEvent: extendedProps,
          })
        }
        eventContent={({
          event: { extendedProps },
          view: { type },
          isPast,
        }) => (
          <CalendarEvent
            data={extendedProps}
            isHorizontal={type === 'listWeek'}
            isPast={isPast}
            showTime={type === 'dayGridMonth' || type === 'listWeek'}
          />
        )}
        events={events || []}
        initialView={xsDown ? 'timeGridDay' : 'timeGridWeek'}
        scrollTime={moment().subtract(1, 'hours').format('HH:00:00')}
        validRange={{
          end: generatedToDate,
        }}
      />
    </Container>
  );
};
