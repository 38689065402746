import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useTermsOfUseFind = (input, options) => {
  return useQuery(
    ['termsOfUse', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/terms-of-use`,
      }),
    options
  );
};

export const useTermsOfUseCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/terms-of-use`,
        data: {
          content: data.content,
          shouldUsersAccept: data.shouldUsersAccept,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['termsOfUse'] });

      options?.onSuccess?.(...res);
    },
  });
};
