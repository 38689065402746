import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isFailed, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        accessor: 'invoice',
        Cell: ({ value }) =>
          value?.name || getFullName(value?.user || value?.leadMember?.user),
      },
      {
        Header: t('invoiceCode'),
        id: 'code',
        accessor: 'invoice',
        Cell: ({ value }) => value?.code,
      },
      {
        Header: t('invoiceAmount'),
        id: 'amount',
        accessor: 'invoice',
        Cell: ({ value }) => toCurrency({ value: value?.amount }),
      },
      {
        Header: t('error'),
        accessor: 'message',
      },
    ],
    [toCurrency, t]
  );

  const hiddenColumns = useMemo(
    () => (isFailed ? [] : ['message']),
    [isFailed]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
