import { Box, Icon, ProcessingBar, Text } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeContext } from 'styled-components';

import { StyledBackground, StyledContainer } from './Styled';

const Modal = ({
  boxTitleContainerProps,
  children,
  containerProps,
  isCondensed,
  isLoading,
  isSidebar,
  onClose,
  render,
  renderAboveContent,
  renderLeftTitle,
  renderRightTitle,
  textTitleProps,
  title,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const { xsDown, smDown, mdDown } = useMediaQuery();
  const [canAnimateIn, setCanAnimateIn] = useState(true);

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  const computedIsSidebar = isSidebar && !smDown;
  const computedIsCondensed = !computedIsSidebar && isCondensed;

  const onClickClose = () => {
    setCanAnimateIn(false);
    setTimeout(() => onClose?.(), 300);
  };

  return ReactDOM.createPortal(
    <StyledContainer
      canAnimateIn={canAnimateIn}
      isSidebar={computedIsSidebar}
      {...containerProps}
    >
      <StyledBackground isSidebar={computedIsSidebar} onClick={onClickClose} />

      {isLoading && <ProcessingBar />}

      <Box
        bg='grey6'
        hasRadius={!computedIsSidebar}
        hasRadiusLeft={computedIsSidebar}
        maxWidth={computedIsCondensed ? '37rem' : undefined}
        ml={computedIsSidebar ? 'auto' : computedIsCondensed ? 4 : undefined}
        mr={computedIsCondensed ? 4 : undefined}
        overflow='hidden'
        width={
          computedIsSidebar
            ? '37rem'
            : computedIsCondensed
            ? undefined
            : xsDown
            ? '95vw'
            : '90vw'
        }
      >
        {(renderLeftTitle || title || renderRightTitle || onClose) && (
          <Box
            isFlex
            alignItems='center'
            p={padding}
            pb={2}
            {...boxTitleContainerProps}
          >
            {renderLeftTitle && renderLeftTitle()}

            {title && (
              <Box flex={1}>
                <Text isBold variant='heading' {...textTitleProps}>
                  {title}
                </Text>
              </Box>
            )}

            {renderRightTitle && renderRightTitle()}

            {onClose && <Icon ml='auto' name='close' onClick={onClickClose} />}
          </Box>
        )}

        {render ? (
          render({ onClickClose })
        ) : (
          <Box
            className='hide-scrollbar'
            height={
              computedIsCondensed
                ? undefined
                : `calc(${computedIsSidebar ? '100vh' : '85vh'} - ${
                    theme.space[12]
                  })`
            }
            overflow='scroll'
          >
            {renderAboveContent && renderAboveContent()}

            <Box p={padding} pt={3} {...props}>
              {children}
            </Box>
          </Box>
        )}
      </Box>
    </StyledContainer>,
    document.body
  );
};

export default Modal;
