import { Formik } from 'formik';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import { getPaymentGatewayName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  adhocPaymentGateways,
  isFetchingAdhocPaymentGateways,
  initialValues = {},
  isSingle,
  ...props
}) => {
  const { t } = useTranslation();

  const paymentGateways = adhocPaymentGateways || [];
  const hasPaymentGateways = !!paymentGateways[0];

  return (
    <Formik
      {...props}
      initialValues={{
        invoiceIds: initialValues.invoiceIds,
        ...(isSingle ? { isImmediatePayment: false } : {}),
        locationPaymentGatewayId: undefined,
      }}
      validationSchema={Yup.object().shape({
        invoiceIds: Yup.array().required(t('required')).min(1, t('required')),
        ...(isSingle
          ? { isImmediatePayment: Yup.bool().required(t('required')) }
          : {}),
        locationPaymentGatewayId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, values: { isImmediatePayment } }) => (
        <form onSubmit={handleSubmit}>
          {!isFetchingAdhocPaymentGateways && !hasPaymentGateways ? (
            <Text color='warning'>{t('noAdhocGatewaysSetup')}</Text>
          ) : (
            <Row>
              {isSingle && (
                <Col>
                  <Field
                    isCheckbox
                    name='isImmediatePayment'
                    options={[
                      {
                        label: t('processPaymentImmediately'),
                        value: true,
                      },
                    ]}
                  />

                  {isImmediatePayment && (
                    <Text color='info' mt={2} variant='caption'>
                      {t('ifMandateDoesNotExist')}
                    </Text>
                  )}
                </Col>
              )}

              <Col>
                <Field
                  isRadio
                  isLoading={isFetchingAdhocPaymentGateways}
                  label={t('paymentGateway')}
                  name='locationPaymentGatewayId'
                  options={
                    hasPaymentGateways &&
                    paymentGateways.map((item) => ({
                      label: getPaymentGatewayName({
                        id: item.paymentGatewayId,
                      }),
                      value: item.id,
                    }))
                  }
                />
              </Col>

              <Col>
                <Button
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          )}
        </form>
      )}
    </Formik>
  );
};
