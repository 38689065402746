import _ from 'lodash';
import { Alert, Container } from 'octiv-components';
import { useQuery } from 'octiv-hooks';
import {
  useCrmCreateMailers,
  useCrmFindByIdMailers,
  useCrmUpdateMailers,
  useCrmUpdateMailersAttachments,
} from 'octiv-hooks/requests/Crm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const duplicateId = useQuery('duplicate');
  const requestTimeout = 300000;
  const [sendWhenField, setSendWhenField] = React.useState('');

  const canRequestComm = !!id || !!duplicateId;
  const canSetType = !id && !duplicateId;

  const onSuccess = () =>
    navigate(`/comms/manage${sendWhenField === 'now' ? '?status=sent' : ''}`);

  const { data: comm, isFetching: isFetchingComm } = useCrmFindByIdMailers(
    {
      id: id || duplicateId,
      include: 'recipients',
    },
    {
      enabled: canRequestComm,
    }
  );

  const refEntityIdToId = (recipient) => ({
    ...recipient,
    id: recipient?.id || recipient?.refEntityId,
  });

  const crmUpdateAttachments = useCrmUpdateMailersAttachments({
    onSuccess,
  });

  const { isLoading: isCreatingComm, mutate: postCommRequest } =
    useCrmCreateMailers({
      timeout: requestTimeout,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingComm, mutate: putCommRequest } =
    useCrmUpdateMailers({
      timeout: requestTimeout,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onFormSubmit = ({
    sendWhen,
    sendDate,
    sendTime,
    recipientsMembers,
    recipientsStaff,
    recipientsLeads,
    recipientsNonMembers,
    ...values
  }) => {
    const recipients = {};
    if (!_.isEmpty(recipientsMembers))
      recipients.members = recipientsMembers?.map(refEntityIdToId);
    if (!_.isEmpty(recipientsStaff))
      recipients.staff = recipientsStaff?.map(refEntityIdToId);
    if (!_.isEmpty(recipientsLeads))
      recipients.leads = recipientsLeads?.map(refEntityIdToId);
    if (!_.isEmpty(recipientsNonMembers))
      recipients.nonMembers = recipientsNonMembers?.map(refEntityIdToId);

    setSendWhenField(sendWhen);

    if (id) {
      putCommRequest(
        {
          id,
          recipients,
          sendWhen,
          sendDate,
          sendTime,
          ...values,
        },
        {
          onSuccess: () => {
            crmUpdateAttachments.mutate({
              id,
              ...values,
            });
          },
        }
      );
    } else {
      postCommRequest(
        {
          recipients,
          sendWhen,
          sendDate,
          sendTime,
          ...values,
        },
        {
          onSuccess: (res) => {
            crmUpdateAttachments.mutate({
              id: res.id,
              ...values,
            });
          },
        }
      );
    }
  };

  const mapKey = (key) => {
    switch (key) {
      case 'coach':
        return 'staff';
      case 'lead-member':
        return 'leads';
      case 'non-member':
        return 'nonMembers';
      case 'member':
        return 'members';
      default:
        return 'nonMember';
    }
  };

  const groupedRecipients = (communication) =>
    communication?.recipients?.reduce((acc, recipient) => {
      // eslint-disable-next-line no-shadow
      const { name, surname, type, email, mobile, refEntityId } = recipient;

      // Create a new array if the type doesn't exist in the accumulator
      const mappedKey = mapKey(type);
      if (!acc[mappedKey]) {
        acc[mappedKey] = [];
      }

      // Push the recipient to the corresponding type array
      acc[mappedKey].push({
        id: refEntityId,
        name,
        surname,
        email: email || undefined,
        mobile: mobile || undefined,
      });

      return acc;
    }, {});

  const initialRecipients = state?.recipients;
  const initialRecipientsCount =
    (initialRecipients?.members?.length || 0) +
    (initialRecipients?.staff?.length || 0) +
    (initialRecipients?.leads?.length || 0) +
    (initialRecipients?.nonMembers?.length || 0);

  return (
    <Container
      appBarProps={{
        title: id ? t('update') : t('create'),
        breadcrumbs: [t('comms')],
      }}
      isLoading={isFetchingComm}
    >
      {initialRecipientsCount > 0 && (
        <Alert mb={4} subtitle={t('recipientsPreloaded')} variant='info' />
      )}

      {!isFetchingComm && (
        <Form
          canSetType={canSetType}
          initialValues={
            canRequestComm
              ? {
                  ...comm,
                  recipients: groupedRecipients(comm),
                }
              : { recipients: initialRecipients } || {}
          }
          isLoading={isCreatingComm || isUpdatingComm}
          onSubmit={onFormSubmit}
        />
      )}
    </Container>
  );
};
