import { Modal, ProcessingSuspense } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useSettingsFindStores,
  useSettingsUpdateStores,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const findStores = useSettingsFindStores(
    {
      filter: { locationId: toggle.data?.id || locations?.[0]?.id },
    },
    {
      enabled: toggle.data?.id !== undefined,
    }
  );
  const updateStores = useSettingsUpdateStores();

  const handleSubmit = (values) => {
    updateStores.mutate({
      locationId: toggle.data.id,
      paymentOptions: values.paymentOptions.filter((pO) => pO !== '') || [],
    });
  };

  return (
    <Setting title={t('locationPaymentOptions')}>
      {toggle.isVisible && (
        <Modal isSidebar title={t('paymentOptions')} onClose={resetToggle}>
          {findStores.isFetching ? (
            <ProcessingSuspense />
          ) : (
            <Form
              initialValues={findStores?.data}
              isLoading={updateStores.isLoading}
              onSubmit={handleSubmit}
            />
          )}
        </Modal>
      )}

      <Table
        hasAlternateBackground
        data={locations}
        onClickAction={setToggle}
      />
    </Setting>
  );
};
