import { Images } from 'octiv-assets';
import { Alert, Button, Card, Container, Image, Text } from 'octiv-components';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useUsersUpdate } from 'octiv-hooks/requests/Users';
import React from 'react';

import Form from './Form';

const DiscoveryVitality = ({ useContainer }) => {
  const { isRefetchingSignedInUser, refetchSignedInUser, ...user } =
    useSignedInUser();

  const usersUpdate = useUsersUpdate();

  const Inner = () => {
    return (
      <>
        <Text color='grey1' mb={4}>
          Octiv has partnered with Discovery Vitality to make earning your
          Vitality points easier than ever before. Link your Discovery Vitality
          account and make sure you check-in and out of your classes by scanning
          the QR code at your studio. Your Vitality points will reflect within 3
          - 5 days, if applicable.
        </Text>

        {user.healthProviderId === 1 ? (
          <>
            <Alert
              mb={4}
              subtitle='Your details have been linked. You may update or unlink them below if needed'
              title='Details Linked'
              variant='info'
            />

            <Button
              hasBorder
              color='warning'
              isLoading={usersUpdate.isLoading}
              m='auto'
              mb={4}
              text='Unlink Discovery Vitality'
              onClick={() =>
                usersUpdate.mutate(
                  {
                    id: user.id,
                    healthProviderId: null,
                  },
                  {
                    meta: {
                      useOnSuccessToast: true,
                    },
                    onSuccess: refetchSignedInUser,
                  }
                )
              }
            />
          </>
        ) : (
          <Alert
            mb={4}
            subtitle='To earn Vitality points you need to submit your details below'
            variant='warning'
          />
        )}

        <Form
          initialValues={user}
          isFetchingUser={isRefetchingSignedInUser}
          isLoading={usersUpdate.isLoading}
          onSubmit={(values, { resetForm }) => {
            usersUpdate.mutate(
              {
                id: user.id,
                dateOfBirth: values.dateOfBirth,
                idNumber: values.idNumber,
                healthProviderId: 1,
              },
              {
                meta: {
                  useOnSuccessToast: true,
                },
                onSuccess: () => {
                  resetForm();
                  refetchSignedInUser();
                },
              }
            );
          }}
        />
      </>
    );
  };

  return useContainer ? (
    <Container appBarProps={{ title: 'Discovery Vitality' }}>
      <Inner />
    </Container>
  ) : (
    <Card
      boxTitleContainerRenderRight={() => (
        <Image
          alt='Discovery Vitality'
          size={5}
          src={Images.vitalityLogomark}
        />
      )}
      mt={4}
      title={'Discovery Vitality'}
    >
      <Inner />
    </Card>
  );
};

export default DiscoveryVitality;
