import { Table, Text, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, publicToken, ...props }) => {
  const { t } = useTranslation();
  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);

  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => (value ? t(value.name.toLowerCase()) : undefined),
      },
      {
        Header: t('price'),
        accessor: 'price',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('topUpPrice'),
        accessor: 'topupPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('displayOnSignUp'),
        accessor: 'isDisplayOnSignUp',
        Cell: ({ value }) => (value ? t('yes') : t('no')),
      },
      {
        Header: t('signUpPage'),
        accessor: 'id',
        Cell: ({ value }) => (
          <Text
            as='a'
            color='primary'
            href={`/widget/sign-up?publicToken=${publicToken}&packageId=${value}`}
            target='_blank'
          >
            {t('view')}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { id, isActive },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('manageClasses'), value: 'manageEvents' },
              { label: t('manageProgrammes'), value: 'manageProgrammes' },
              { label: t('manageLocations'), value: 'manageLocations' },
              ...(isActive
                ? [{ label: t('deactivate'), value: 'deactivate' }]
                : [{ label: t('activate'), value: 'activate' }]),
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, publicToken, toCurrency, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
