import { Box, Button, Container, Modal } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useExerciseCategoriesCreate,
  useExerciseCategoriesFind,
  useExerciseCategoriesUpdate,
} from 'octiv-hooks/requests/ExerciseCategories';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [paging, setPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    isActive: true,
  });

  const {
    data: exerciseCategories,
    isFetching: isFetchingExerciseCategories,
    refetch: getExerciseCategoriesRequest,
  } = useExerciseCategoriesFind({
    filter: query,
    paging,
  });

  const {
    isLoading: isUpdatingExerciseCategory,
    mutate: putExerciseCategoryRequest,
  } = useExerciseCategoriesUpdate({
    onSuccess: () => {
      getExerciseCategoriesRequest();
      resetToggle();
    },
  });

  const {
    isLoading: isCreatingExerciseCategory,
    mutate: postExerciseCategoryRequest,
  } = useExerciseCategoriesCreate({
    onSuccess: () => {
      getExerciseCategoriesRequest();
      resetToggle();
    },
  });

  const handleSubmit = ({ id, ...values }) => {
    if (toggle.type === 'update') {
      putExerciseCategoryRequest({ id, ...toggle.data, ...values });
    } else {
      postExerciseCategoryRequest({ ...values, isExerciseCategory: true });
    }
  };

  const onClickAction = ({ action, id }) => {
    const exerciseCategory = exerciseCategories?.data.find(
      (item) => item.id === id
    );
    switch (action) {
      case 'update':
        setToggle({
          type: 'update',
          data: exerciseCategory,
        });
        break;

      case 'updateStatus':
        putExerciseCategoryRequest({
          id,
          ...exerciseCategory,
          isActive: exerciseCategory?.isActive === 1 ? 0 : 1,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal isSidebar title='Exercise Category' onClose={resetToggle}>
          <Form
            exerciseCategories={exerciseCategories?.data || []}
            initialValues={toggle.data}
            isFetchingExerciseCategories={isFetchingExerciseCategories}
            isLoading={isUpdatingExerciseCategory || isCreatingExerciseCategory}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('exerciseCategories'),
          breadcrumbs: [t('settings')],
          children: (
            <Box ml='auto'>
              <Button
                text='New Exercise Category'
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={isUpdatingExerciseCategory || isCreatingExerciseCategory}
      >
        <FormQuery
          exerciseCategories={exerciseCategories}
          initialValues={query}
          isFetchingExerciseCategories={isFetchingExerciseCategories}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <Table
          data={exerciseCategories?.data || []}
          isLoading={isFetchingExerciseCategories}
          paging={exerciseCategories?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onClickRow={({ original }) =>
            onClickAction({
              action: 'update',
              id: original.id,
            })
          }
        />
      </Container>
    </>
  );
};
