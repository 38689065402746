import { useCurrenciesFind } from 'octiv-hooks/requests/Currencies';
import {
  useRegionsCreate,
  useRegionsUpdate,
} from 'octiv-hooks/requests/Regions';
import { useTimezonesFind } from 'octiv-hooks/requests/Timezones';
import React from 'react';

import FormRegion from './FormRegion';

export default ({ region, onSubmitSuccess }) => {
  const isCreate = !region?.id;

  const { isLoading: isCreatingRegion, mutate: postRegionRequest } =
    useRegionsCreate({
      onSuccess: onSubmitSuccess,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingRegion, mutate: putRegionRequest } =
    useRegionsUpdate({
      onSuccess: onSubmitSuccess,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { data: currencies, isFetching: isFetchingCurrencies } =
    useCurrenciesFind({
      filter: {
        perPage: -1,
      },
    });

  const { data: timezones, isFetching: isFetchingTimezones } = useTimezonesFind(
    {
      filter: {
        perPage: -1,
      },
    }
  );

  const onPutPostRequest = (values) => {
    if (isCreate) {
      postRegionRequest(values);
    } else {
      putRegionRequest(values);
    }
  };

  return (
    <FormRegion
      currencies={currencies?.data || []}
      initialValues={region}
      isCreate={isCreate}
      isFetchingCurrencies={isFetchingCurrencies}
      isFetchingTimezones={isFetchingTimezones}
      isLoading={isCreatingRegion || isUpdatingRegion}
      timezones={timezones?.data || []}
      onSubmit={onPutPostRequest}
    />
  );
};
