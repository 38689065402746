import moment from 'moment';
import { Box, Button, Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useFinancesCreateCreditNotes,
  useFinancesDeleteUserInvoices,
  useFinancesFindByIdUserInvoicesDownload,
  useFinancesFindByIdUserInvoicesSend,
  useFinancesFindCreditNotes,
  useFinancesFindCreditNotesExport,
  useFinancesUpdateCreditNotes,
} from 'octiv-hooks/requests/Finances';
import { useUsersFind } from 'octiv-hooks/requests/Users';
import { userStatus, userTypeId } from 'octiv-utilities/Constants';
import { downloadFile, getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormCreditNote from './FormCreditNote';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [sendType, setSendType] = useState(undefined);
  const [creditNoteId, setCreditNoteId] = useState(undefined);
  const [paging, setPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    datePeriod: 'custom',
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
    locationId: selectedLocation?.id,
    sentStatus: undefined,
    search: undefined,

    sort: '-dueOn',
  });

  const findMembers = useUsersFind({
    filter: {
      userTenantStatusId: userStatus.ACTIVE,
      userTenantTypeId: userTypeId.GYM_MEMBER,
      isMinimalData: true,
    },
    sort: 'name',
    paging: { perPage: -1 },
  });

  const findCreditNotes = useFinancesFindCreditNotes({
    filter: query,
    sort: query.sort,
    paging,
  });
  const { isLoading: isCreatingCreditNote, mutate: postCreditNoteRequest } =
    useFinancesCreateCreditNotes({
      onSuccess: () => {
        resetToggle();
      },
    });

  const { isLoading: isUpdatingCreditNote, mutate: putCreditNoteRequest } =
    useFinancesUpdateCreditNotes({
      onSuccess: () => {
        resetToggle();
      },
    });

  const { isLoading: isDeletingCreditNote, mutate: deleteCreditNoteRequest } =
    useFinancesDeleteUserInvoices({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const {
    isFetching: isFetchingCreditNoteSend,
    // refetch: getCreditNoteSendRequest,
  } = useFinancesFindByIdUserInvoicesSend(
    {
      id: creditNoteId,
    },
    {
      enabled: !!creditNoteId && sendType === 'send',
      meta: {
        useOnSuccessToast: true,
      },
      onSuccess: () => {
        setCreditNoteId(undefined);
        setSendType(undefined);
      },
    }
  );

  const {
    isFetching: isFetchingCreditNoteDownload,
    // refetch: getCreditNoteDownloadRequest,
  } = useFinancesFindByIdUserInvoicesDownload(
    {
      id: creditNoteId,
    },
    {
      enabled: !!creditNoteId && sendType === 'download',
      onSuccess: (response) => {
        setCreditNoteId(undefined);
        setSendType(undefined);
        downloadFile(response);
      },
    }
  );

  const {
    isFetching: isFetchingCreditNotesExport,
    refetch: getCreditNotesExportRequest,
  } = useFinancesFindCreditNotesExport(
    {
      filter: query,
    },
    {
      enabled: false,
      onSuccess: downloadFile,
    }
  );

  const onPutPostCreditNoteRequest = (values) => {
    if (toggle.type === 'create') {
      postCreditNoteRequest(values);
    } else {
      putCreditNoteRequest({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: findCreditNotes?.data?.data.find((item) => item.id === id),
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteCreditNoteRequest({ id });
        }
        break;

      case 'download':
        setSendType('download');
        setCreditNoteId(id);
        break;

      case 'send':
        setSendType('send');
        setCreditNoteId(id);
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('creditNote')} onClose={resetToggle}>
          <FormCreditNote
            initialValues={toggle.data}
            isCreate={toggle.type === 'create'}
            isFetchingMembers={findMembers.isFetching}
            members={findMembers.data?.data || []}
            onSubmit={onPutPostCreditNoteRequest}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('creditNotes'),
          breadcrumbs: [t('accounts')],
          children: (
            <Box ml='auto'>
              <Box isFlex>
                <Button
                  hasBorder
                  mr={2}
                  text={t('csvExport')}
                  onClick={getCreditNotesExportRequest}
                />

                <Button
                  text={t('createNew')}
                  onClick={() => setToggle({ type: 'create' })}
                />
              </Box>
            </Box>
          ),
        }}
        isLoading={
          findCreditNotes.isFetching ||
          isUpdatingCreditNote ||
          isCreatingCreditNote ||
          isDeletingCreditNote ||
          isFetchingCreditNoteSend ||
          isFetchingCreditNoteDownload ||
          isFetchingCreditNotesExport
        }
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptionsAll}
          onSubmit={(values) =>
            setQuery((prev) => ({
              ...values,
              sort: prev.sort,
            }))
          }
        />

        <Divider pb={4} />

        <Table
          data={findCreditNotes.data?.data || []}
          isLoading={findCreditNotes.isFetching}
          paging={findCreditNotes?.data?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
        />
      </Container>
    </>
  );
};
