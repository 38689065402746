import { Box, Icon, InlineUser, Table, Tooltip } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('attendee'),
        id: 'attendee',
        Cell: ({ row: { original } }) => (
          <InlineUser {...original} isVisibleOverdue={false} />
        ),
      },
      {
        Header: t('class'),
        accessor: 'class',
        Cell: ({
          row: {
            original: {
              classDate: { name, startTime, endTime },
            },
          },
        }) =>
          getEventString({
            name,
            startTime,
            endTime,
          }),
      },
      {
        Header: t('attendance'),
        id: 'attendance',
        accessor: 'user',
        Cell: ({
          row: {
            original: { id, checkedInAt, status },
          },
        }) => (
          <Box isFlex>
            <Icon
              color={checkedInAt ? 'success' : 'grey3'}
              name='check_circle'
              px={1}
              tooltip='Check-In'
              onClick={() =>
                onClickAction({
                  action: checkedInAt ? 'checkInCancel' : 'checkIn',
                  id,
                })
              }
            />

            <Icon
              color={status.id === 5 ? 'warning' : 'grey3'}
              name='visibility_off'
              px={1}
              tooltip={t('noShow')}
              onClick={() => onClickAction({ action: 'noShow', id })}
            />
          </Box>
        ),
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) =>
          value?.id === 2
            ? t('cancelledByMember')
            : value?.id === 4
            ? t('cancelledByTrainer')
            : value?.id === 3
            ? t('cancelledAfterThreshold')
            : '',
      },
      {
        id: 'actions',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[{ label: t('cancelBooking'), value: 'cancel' }]}
            onClick={({ value }) =>
              onClickAction({
                action: value,
                id,
              })
            }
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(query.classDateId ? ['class'] : []),
      ...(query.statusIds !== '2,3,4' ? ['status'] : []),
      ...(query.statusIds === '2' ||
      query.statusIds === '3' ||
      query.statusIds === '4' ||
      query.statusIds === '2,3,4'
        ? ['actions']
        : []),
    ],
    [query.classDateId, query.statusIds]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
