import { Table, Text, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, publicToken, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('signUpPage'),
        accessor: 'id',
        Cell: ({ value }) => (
          <Text
            as='a'
            color='primary'
            href={`/widget/sign-up?publicToken=${publicToken}&tagId=${value}`}
            target='_blank'
          >
            {t('view')}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, publicToken, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
