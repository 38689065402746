import { Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getEventString,
  getFullName,
  getRecurringDays,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                ? () => setMemberModal({ userTenantId: value?.userTenant?.id })
                : null
            }
          >
            {getFullName(value)}
          </Text>
        ),
      },
      {
        Header: t('class'),
        accessor: 'class',
        Cell: ({ value }) => {
          const eventWithTimes = {
            ...value,
            startTime: value?.startTime,
            endTime: value?.endTime,
          };

          return getEventString(eventWithTimes);
        },
      },
      {
        Header: t('bookedDays'),
        accessor: 'daysOfTheWeek',
        Cell: ({ value }) =>
          getRecurringDays({
            days: value,
            isShort: true,
          }),
      },
      {
        Header: t('endDate'),
        Cell: ({ row: { original } }) => {
          const endDate = original?.class?.recurringEndDate || original?.endsAt;

          return endDate
            ? getDateReadableDayMonthYear({ date: endDate })
            : null;
        },
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: {
              id,
              isActive,
              class: { id: classId },
            },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              ...(isActive
                ? [{ label: t('deactivate'), value: 'deactivate' }]
                : [{ label: t('activate'), value: 'activate' }]),
            ]}
            placement='left'
            onClick={({ value }) =>
              onClickAction({ action: value, id, classId })
            }
          />
        ),
      },
    ],
    [onClickAction, setMemberModal, hasAccess, t]
  );

  const hiddenColumns = useMemo(
    () => (query.status === 'inactive' ? ['endDate'] : []),
    [query.status]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
