import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        creditorId: initialValues.creditorId || undefined,
        creditorBic: initialValues.creditorBic || undefined,
        creditorIban: initialValues.creditorIban || undefined,
      }}
      validationSchema={Yup.object().shape({
        creditorId: Yup.string().required(t('required')),
        creditorBic: Yup.string().required(t('required')),
        creditorIban: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('creditorId')}
            name='creditorId'
          />

          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('bankIdentifierCode')}
            name='creditorBic'
          />

          <Field
            boxContainerProps={{ mb: 4 }}
            label={t('internationalBankAccountNumber')}
            name='creditorIban'
          />

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
