import moment from 'moment';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Modal,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useQuery, useToggle } from 'octiv-hooks';
import {
  useFinancesCreateStatementsSend,
  useFinancesFindStatements,
  useFinancesFindStatementsDownload,
} from 'octiv-hooks/requests/Finances';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import {
  downloadFile,
  getDateReadableDayMonthYear,
  getDateYearMonthDay,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import FormSend from './FormSend';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const initialUserId = useQuery('userId');
  const initialUserStatusId = useQuery('statusId');
  const {
    selectedLocation,
    tenant: { locationOptionsAll },
    toCurrency,
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    statusId: initialUserStatusId !== null ? Number(initialUserStatusId) : 2,
    userId: initialUserId !== null ? Number(initialUserId) : undefined,
    startDate: getDateYearMonthDay({ date: moment().startOf('month') }),
    endDate: getDateYearMonthDay({ date: moment().endOf('month') }),
  });

  const membersFind = useUsersFindMembers({
    filter: {
      userTenantStatusId: query?.statusId,
      userTenantLocationId: query?.locationId,
      isMinimalData: true,
    },
    paging: {
      perPage: -1,
    },
  });

  const { isFetching: isFetchingStatements, data: statements } =
    useFinancesFindStatements(
      {
        filter: query,
      },
      {
        enabled: !!query.userId,
      }
    );

  const {
    isLoading: isCreatingStatementSend,
    mutate: postStatementSendRequest,
  } = useFinancesCreateStatementsSend({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isFetching: isFetchingStatementDownload,
    refetch: getStatementDownloadRequest,
  } = useFinancesFindStatementsDownload(
    {
      filter: query,
    },
    {
      enabled: false,
      onSuccess: downloadFile,
    }
  );

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('message')} onClose={resetToggle}>
          <FormSend
            initialValues={{
              message: t('sendStatementMessage', {
                dateFrom: getDateReadableDayMonthYear({
                  date: query.startDate,
                }),
                dateTo: getDateReadableDayMonthYear({
                  date: query.endDate,
                }),
              }),
            }}
            isLoading={isCreatingStatementSend}
            onSubmit={(values) =>
              postStatementSendRequest({ ...query, ...values })
            }
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('statements'),
          breadcrumbs: [t('accounts')],
          children: statements?.transactions ? (
            <Box ml='auto'>
              <Box isFlex>
                <Button
                  hasBorder
                  mr={2}
                  text={t('download')}
                  onClick={getStatementDownloadRequest}
                />

                <Button text={t('send')} onClick={() => setToggle()} />
              </Box>
            </Box>
          ) : null,
        }}
        isLoading={isFetchingStatements || isFetchingStatementDownload}
      >
        <FormQuery
          initialValues={query}
          isFetchingMembers={membersFind.isFetching}
          locationOptions={locationOptionsAll}
          members={membersFind?.data?.data || []}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        {!query.userId && (
          <Alert title={t('selectMemberToViewStatement')} variant='info' />
        )}

        {statements?.transactions && (
          <>
            <Text color='grey1' mb={1}>
              {`${t('openingBalance')}: ${toCurrency({
                value: statements?.openingBalance,
              })}`}
            </Text>

            <Text color='grey1' mb={1}>
              {`${t('transactionsTotal')}: ${toCurrency({
                value: statements?.total,
              })}`}
            </Text>

            <Text mb={4} variant='subheading'>
              {`${t('closingBalance')}: ${toCurrency({
                value: statements?.outstandingBalance,
              })}`}
            </Text>

            <Table
              data={statements?.transactions}
              isLoading={isFetchingStatements}
            />
          </>
        )}
      </Container>
    </>
  );
};
