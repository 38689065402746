import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        description: initialValues.description || undefined,
        type: initialValues.type || undefined,
        amount: initialValues.amount || undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        description: Yup.string().required(t('required')),
        type: Yup.string().required(t('required')),
        amount: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field label={t('name')} name='name' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>

            <Col md={6}>
              <Field
                isSelect
                label={t('type')}
                name='type'
                options={[
                  { label: t('fixedAmount'), value: 'fixed' },
                  { label: t('percentageOfAmount'), value: 'percentage' },
                ]}
              />
            </Col>

            <Col md={6}>
              <Field label={t('amount')} name='amount' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
