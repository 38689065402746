import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('status'),
        Cell: ({
          row: {
            original: { hasOnboarded, onboardingUrl },
          },
        }) =>
          hasOnboarded ? (
            <Text as='span' color='success'>
              {t('onboarded')}
            </Text>
          ) : (
            <Text
              as='span'
              color='danger'
              onClick={() => window.location.assign(onboardingUrl)}
            >
              {t('onboardNow')}
            </Text>
          ),
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
