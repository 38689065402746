import 'react-popper-tooltip/dist/styles.css';
import './Styles.css';

import { useMediaQuery } from 'octiv-hooks';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Link } from 'react-router-dom';

import { Box, Button, Icon, Text } from '..';
import { StyledBox } from '../box/Styled';

const Tooltip = ({
  children,
  hasSelectAll,
  hideArrow,
  icon = 'more_vertical',
  isSelect,
  minWidth = 55,
  onClick,
  onVisibilityChange,
  options,
  placement: tooltipPlacement = 'left',
  render,
  stopPropagation,
  text,
  trigger = 'click',
  triggerProps = {},
  ...props
}) => {
  const { t } = useTranslation();

  const { xsDown } = useMediaQuery();

  const [isVisible, setIsVisible] = useState(false);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    ...props,
    trigger: xsDown ? 'click' : trigger,
    placement: xsDown ? 'bottom' : tooltipPlacement,
    interactive: true,
    visible: isVisible,
    onVisibleChange: (tooltipShown) => {
      if (onVisibilityChange) onVisibilityChange(tooltipShown);
      setIsVisible(tooltipShown);
    },
  });

  return (
    <>
      <StyledBox
        display='inline-block'
        ref={setTriggerRef}
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
        }}
        {...triggerProps}
      >
        {options && !children ? (
          <Box size={6}>
            <Icon name={icon} />
          </Box>
        ) : (
          children
        )}
      </StyledBox>

      {visible &&
        ReactDOM.createPortal(
          <StyledBox
            minWidth={minWidth}
            ref={setTooltipRef}
            {...getTooltipProps({
              className: 'tooltip-container',
            })}
            hasRadius
            options={options}
            px={options ? undefined : 4}
            zIndex={511}
            onClick={(e) => {
              if (stopPropagation) e.stopPropagation();
            }}
          >
            <Box
              className='hide-scrollbar'
              maxHeight={xsDown ? '80vh' : '400px'}
              overflowY='scroll'
              py={2}
              zIndex={1}
            >
              {options ? (
                <>
                  {isSelect && hasSelectAll && (
                    <Box isFlex justifyContent='center' mb={2} mx={2}>
                      <Button
                        hasBorder
                        hasRadiusLeft
                        color='grey2'
                        scale='small'
                        text={t('selectAll')}
                        onClick={() => onClick({ selectAll: true })}
                      />

                      <Button
                        hasBorder
                        hasRadiusRight
                        color='grey2'
                        scale='small'
                        text={t('deselectAll')}
                        onClick={() => onClick({ deselectAll: true })}
                      />
                    </Box>
                  )}

                  {options.map((option, index) => {
                    if (option.title) {
                      return (
                        <Box key={`${index.toString()}`} pt={2} px={4}>
                          <Text opacity={0.5} variant='caption'>
                            {option.title}
                          </Text>
                        </Box>
                      );
                    }

                    return (
                      <Box
                        hasRadius
                        isFlex
                        alignItems='center'
                        as={option.to ? Link : undefined}
                        bgHover='grey5'
                        isDisabled={option.isDisabled}
                        key={`${index.toString()}`}
                        mx={2}
                        p={2}
                        title={option.isDisabled ? option.disabledText : null}
                        to={option.to}
                        onClick={() => {
                          if (option.isDisabled) return;

                          if (!isSelect) {
                            setIsVisible(false);
                          }
                          if (onClick) onClick(option);
                        }}
                      >
                        {isSelect && (
                          <Icon
                            color='primary'
                            mr={1}
                            name={
                              option.isSelected
                                ? 'check_box'
                                : 'check_box_outline_blank'
                            }
                            scale='small'
                          />
                        )}

                        <Text {...option.labelProps}>{option.label}</Text>
                      </Box>
                    );
                  })}
                </>
              ) : text ? (
                <Text textAlign='center' variant='caption'>
                  {text}
                </Text>
              ) : render ? (
                render({ closeTooltip: () => setIsVisible(false) })
              ) : null}
            </Box>

            {!hideArrow && (
              <Box {...getArrowProps({ className: 'tooltip-arrow' })} />
            )}
          </StyledBox>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
