import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        actionDate: undefined,
        isExcludedFromFutureBatches: false,
      }}
      validationSchema={Yup.object().shape({
        actionDate: Yup.string().required(t('required')),
        isExcludedFromFutureBatches: Yup.bool(),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field isDate label={t('deactivationDate')} name='actionDate' />
            </Col>

            <Col>
              <Field
                isCheckbox
                name='isExcludedFromFutureBatches'
                options={[
                  {
                    label: t('excludeUserFromNextDebitRun'),
                    value: true,
                  },
                ]}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
