import { Box } from 'octiv-components';
import React from 'react';

import StyledProgressBar from './Styled';

const ProgressBar = ({ boxContainerProps, ...props }) => (
  <Box hasRadius bg='grey4' {...boxContainerProps}>
    <StyledProgressBar bg='primary' borderRadius={1} height='2px' {...props} />
  </Box>
);

export default ProgressBar;
