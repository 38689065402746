import { Box, Icon, Image, Table, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { isValidUrl } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, updateCart, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);

  // NOTE: I think a totalCost would be useful here:
  //
  // const totalCost = tableData
  //   .map((item) => Number(item.sellingPrice))
  //   .reduce((prev, curr) => prev + curr, 0);

  const tableColumns = useMemo(
    () => [
      {
        Header: t('product'),
        Cell: ({
          cell: {
            row: {
              original: { name, imageUrl },
            },
          },
        }) => (
          <Box isFlex alignItems='center'>
            {isValidUrl(imageUrl) && (
              <Image hasRadius mr={2} size={8} src={imageUrl} />
            )}
            <Text as='span'>{name}</Text>
          </Box>
        ),
      },
      {
        Header: t('sku'),
        accessor: 'sku',
      },
      {
        Header: t('retailPrice'),
        accessor: 'sellingPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('quantity'),
        accessor: 'quantity',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Box isFlex alignItems='center'>
            <Icon
              name='remove_circle'
              onClick={() => updateCart({ subtract: true, product: original })}
            />

            <Text as='span' textAlign='center' width={8}>
              {original.quantity}
            </Text>

            <Icon
              name='add_circle'
              onClick={() => updateCart({ add: true, product: original })}
            />
          </Box>
        ),
      },
    ],
    [updateCart, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
