import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import General from './general';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('coronavirus'),
        breadcrumbs: [t('settings')],
      }}
    >
      <General />
    </Container>
  );
};
