import { Card, Col, ProcessingSuspense, Row, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default ({ data, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Card title={t('classStats')}>
      <Row>
        {isLoading ? (
          <>
            <Col xs={4}>
              <ProcessingSuspense
                items={[{ width: 10, height: 9 }, { width: 20 }]}
                mx='auto'
              />
            </Col>
            <Col xs={4}>
              <ProcessingSuspense
                items={[{ width: 10, height: 9 }, { width: 20 }]}
                mx='auto'
              />
            </Col>
            <Col xs={4}>
              <ProcessingSuspense
                items={[{ width: 10, height: 9 }, { width: 20 }]}
                mx='auto'
              />
            </Col>
          </>
        ) : (
          [
            {
              title: t('bookings'),
              body: data.bookedClassesMembers?.toString(),
              statusId: 1,
            },
            {
              title: t('cancellations'),
              body: data.cancelledClassesMembers?.toString(),
              statusId: 2,
            },
            {
              title: t('noShows'),
              body: data.noShowMembers?.toString(),
              statusId: 5,
            },
          ].map(({ title, body, statusId }) => (
            <Col
              as={Link}
              key={title}
              to={`/scheduling/roster?statusId=${statusId}`}
              xs={4}
            >
              <Text isBold color='primary' textAlign='center' variant='jumbo'>
                {body}
              </Text>
              <Text textAlign='center'>{title}</Text>
            </Col>
          ))
        )}
      </Row>
    </Card>
  );
};
