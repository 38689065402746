import { Container, Divider } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { usePaging } from 'octiv-hooks';
import { useUserTenantsFindContractsWaivers } from 'octiv-hooks/requests/UserTenants';
import { getSelectedRows } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MemberBulkActions from '../shared/MemberBulkActions';
import MembersWrapper from '../shared/MembersWrapper';
import FormQuery from './FormQuery';
import Table from './Table';

const MembersContractsWaivers = () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();

  const [members, setMembers] = useState([]);
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    statusId: 2,
    dateToQuery: undefined,
    startDate: undefined,
    endDate: undefined,
    search: undefined,

    sort: 'name',
  });

  const selectedData = getSelectedRows({ data: members });

  const userTenantsFindContractsWaivers = useUserTenantsFindContractsWaivers(
    {
      filter: {
        typeId: 4,
        ...query,
        dateToQuery:
          query.startDate || query.endDate ? query.dateToQuery : undefined,
      },
      include: 'isOverdue',
      sort: query.sort,
      paging,
    },
    {
      onSuccess: (response) => setMembers(response?.data || []),
    }
  );

  return (
    <MembersWrapper selectedData={selectedData}>
      {({ onClickAction, programmesFind, isContainerLoading }) => (
        <Container
          appBarProps={{
            title: t('contractsWaivers'),
            breadcrumbs: [t('members')],
          }}
          isLoading={isContainerLoading}
        >
          <FormQuery
            initialValues={query}
            isFetchingProgrammes={programmesFind.isFetching}
            locationOptions={locationOptionsAll}
            programmes={programmesFind.data?.data}
            onSubmit={(values) =>
              setQuery((prev) => ({
                ...values,
                sort: prev.sort,
              }))
            }
          />

          <Divider pb={4} />

          <MemberBulkActions
            mb={4}
            queryStatusId={query.statusId}
            selectedData={selectedData}
            onClickAction={onClickAction}
          />

          <Table
            isPaginationAllHidden
            data={members || []}
            highlightTestFunc={({ original: { isOverdue } }) =>
              isOverdue === true
            }
            isLoading={userTenantsFindContractsWaivers.isFetching}
            paging={userTenantsFindContractsWaivers.data?.paging}
            query={query}
            setPaging={setPaging}
            setQuery={setQuery}
            setSelected={setMembers}
            onClickAction={onClickAction}
            onClickRow={({ original }) => {
              setMemberModal({ userTenantId: original?.id });
            }}
            onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
          />
        </Container>
      )}
    </MembersWrapper>
  );
};

export default MembersContractsWaivers;
