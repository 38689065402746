import { useQuery } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useExportFindUsers = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['export', 'findUsers', input],
    () =>
      request({
        method: 'get',
        url: `/api/export/users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            tenantStatusId: filter.tenantStatusId,
            regionId: filter.regionId,
            locationId: filter.locationId,
            locationActive: filter.isLocationActive,
            userStatusId: filter.statusId,
            userGroup: filter.userType,
          },
        },
      }),
    options
  );
};

export const useExportFindLocations = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['export', 'findLocations', input],
    () =>
      request({
        method: 'get',
        url: `/api/export/locations`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
          },
        },
      }),
    options
  );
};
