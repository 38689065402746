import { Badge, Box, Image, Table, Text } from 'octiv-components';
import { getFullName, getResult } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('pos'),
        accessor: 'position',
        Cell: ({ value }) => (
          <Badge isRound bg='grey4' color='font' size={7} text={value} />
        ),
      },
      {
        Header: t('result'),
        id: 'result',
        Cell: ({
          cell: {
            row: {
              original: {
                score,
                exercise: {
                  measuringUnit: { id },
                },
              },
            },
          },
        }) => (
          <Text as='span' color='primary'>
            {getResult({
              measureId: id,
              score: String(score),
            })}
          </Text>
        ),
      },
      {
        Header: t('member'),
        accessor: 'user',
        Cell: ({ value }) => (
          <Box isFlex alignItems='center'>
            <Image
              hasRadius
              usePlaceholderUser
              mr={2}
              size={8}
              src={value?.user?.image}
            />

            <Text as='span'>{getFullName(value || {})}</Text>
          </Box>
        ),
      },
      {
        Header: t('tenant'),
        accessor: 'tenant',
        Cell: ({ value }) => value?.name,
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
