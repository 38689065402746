import moment from 'moment';
import { Table, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getDateTime,
  getRecurringDays,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('days'),
        accessor: 'recurringDays',
        Cell: ({ value }) => getRecurringDays({ days: value, isShort: true }),
      },
      {
        Header: t('time'),
        accessor: 'startTime',
        Cell: ({
          row: {
            original: { startTime, endTime },
          },
        }) =>
          `${getDateTime({ date: startTime }).slice(0, -3)} - ${getDateTime({
            date: endTime,
          }).slice(0, -3)} (${moment(endTime).diff(
            moment(startTime),
            'minutes'
          )}m)`,
      },
      {
        Header: t('startDate'),
        accessor: 'recurringStartDate',
        Cell: ({ value }) =>
          value ? getDateReadableDayMonthYear({ date: value }) : null,
      },
      {
        Header: t('endDate'),
        accessor: 'recurringEndDate',
        Cell: ({ value }) =>
          value ? getDateReadableDayMonthYear({ date: value }) : null,
      },
      {
        Header: t('type'),
        accessor: 'isSession',
        Cell: ({ value }) => (value ? t('session') : t('class')),
      },
      {
        id: 'actions',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            stopPropagation
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('duplicate'), value: 'duplicate' },
              { label: t('delete'), value: 'delete' },
            ]}
            onClick={({ value }) =>
              onClickAction({
                action: value,
                id,
              })
            }
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
