import { Container } from 'octiv-components';
import { usePaging } from 'octiv-hooks';
import { useReportsFindPersonalBests } from 'octiv-hooks/requests/Reports';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();
  const [paging, setPaging] = usePaging();

  const reportsFindPersonalBests = useReportsFindPersonalBests({
    paging,
  });

  return (
    <Container
      appBarProps={{
        title: t('personalBests'),
        breadcrumbs: [t('reports'), t('exercises')],
      }}
      isLoading={reportsFindPersonalBests?.isFetching}
    >
      <TableReport
        data={reportsFindPersonalBests?.data?.data || []}
        isLoading={reportsFindPersonalBests?.isFetching}
        paging={reportsFindPersonalBests?.data?.paging}
        setPaging={setPaging}
      />
    </Container>
  );
};
