import { Table, Tooltip } from 'octiv-components';
import Col from 'octiv-components/layout/col/index';
import Row from 'octiv-components/layout/row/index';
import {
  getUserTypeString,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setSelected, onClickAction, query, ...props }) => {
  const { t } = useTranslation();

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        sortBy: 'name',
      },
      {
        Header: t('surname'),
        accessor: 'surname',
        sortBy: 'surname',
      },
      {
        Header: t('email'),
        accessor: 'email',
        sortBy: 'email',
      },
      // {
      //   Header: t('type'),
      //   accessor: 'userTenant.typeId',
      //   Cell: ({ value }) => getUserTypeString({ id: value }),
      // },
      // {
      //   Header: t('tenant'),
      //   accessor: 'userTenants',
      //   Cell: ({ value }) =>
      //     value?.map((item) => item?.tenant?.name).join(', '),
      // },
      // {
      //   Header: t('location'),
      //   accessor: 'userTenant.locations',
      //   Cell: ({ value }) => value?.map((item) => item?.name).join(', '),
      // },
      {
        Header: (
          <Row>
            <Col sm={2}>{t('type')}</Col>
            <Col sm={4}>{t('tenant')}</Col>
            <Col sm={5}>{t('location')}</Col>
          </Row>
        ),
        accessor: 'userTenants',
        Cell: ({ row: { original } }) =>
          original?.userTenants?.map((userTenant) => (
            <Row key={userTenant?.id}>
              <Col sm={2}>{getUserTypeString({ id: userTenant?.typeId })}</Col>
              <Col sm={4}>{userTenant?.tenant?.name}</Col>
              <Col sm={5}>
                {userTenant?.locations?.map((location) => location.name)}
              </Col>
            </Row>
          )),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({ row: { original } }) => {
          const { statusId } = original?.userTenant || {};

          return (
            <Tooltip
              stopPropagation
              options={[
                { label: t('viewEdit'), value: 'view' },
                ...(statusId !== 2 && statusId !== 6
                  ? [
                      {
                        label: t('activate'),
                        value: 'activate',
                      },
                    ]
                  : []),
                ...(statusId !== 4
                  ? [
                      {
                        label: t('deactivate'),
                        value: 'deactivate',
                      },
                    ]
                  : []),
                ...(statusId === 4
                  ? [
                      {
                        label: t('delete'),
                        value: 'delete',
                      },
                    ]
                  : []),
                {
                  label: t('changePassword'),
                  value: 'changePassword',
                },
                { label: t('login'), value: 'login' },
              ]}
              placement='left'
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  userId: original.id,
                  userTenantId: original.userTenant.id,
                })
              }
            />
          );
        },
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [...(query.userGroup === 'members' ? ['userTenant.typeId'] : [])],
    [query.userGroup]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'name',
        desc: false,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      sortBy={sortBy}
      onSelect={setRowSelected}
      onSelectAll={setAllRowsSelected}
    />
  );
};
