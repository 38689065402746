import moment from 'moment';
import {
  Box,
  Container,
  DateStrip,
  DownloadApp,
  Text,
  Workout,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMediaQuery } from 'octiv-hooks';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useWorkoutsFind } from 'octiv-hooks/requests/Workouts';
import {
  getDateYearMonthDay,
  sanitizeWorkout,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';

export default () => {
  const { t, i18n } = useTranslation();

  const activeUserTenant = useActiveUserTenant();
  const { xsDown } = useMediaQuery();

  const [date, setDate] = useState(getDateYearMonthDay({ canFallback: true }));
  const [programmeId, setProgrammeId] = useState(undefined);

  const { data: programmes, isFetching: isFetchingProgrammes } =
    useProgrammesFind(
      {
        paging: { perPage: -1 },
      },
      {
        onSuccess: (response) => {
          if (
            activeUserTenant.programmeId &&
            response &&
            response.data.find(
              (item) => item.id === activeUserTenant.programmeId
            )
          ) {
            setProgrammeId(activeUserTenant.programmeId);
          } else if (response && response[0]) {
            setProgrammeId(response[0].id);
          }
        },
      }
    );

  const workoutsFind = useWorkoutsFind(
    {
      filter: {
        useWorkoutThreshold: 1,
        programmeId,
        startsAfter: date,
        endsBefore: getDateYearMonthDay({
          date: moment(date).add(1, 'day'),
        }),
      },
    },
    {
      enabled: !!programmeId,
    }
  );

  return (
    <Container
      appBarProps={{ title: t('workouts') }}
      isLoading={workoutsFind.isFetching}
    >
      <DownloadApp mb={6} />

      <DateStrip
        activeDate={date}
        mb={6}
        onChange={(value) => setDate(getDateYearMonthDay({ date: value.date }))}
      />

      <Box mb={4}>
        <Text isBold mb={2} variant={xsDown ? 'subheading' : 'heading'}>
          {moment(date)
            .locale(i18n.resolvedLanguage)
            .format('dddd DD MMMM YYYY')}
        </Text>

        <FormQuery
          initialValues={{ programmeId }}
          isFetchingProgrammes={isFetchingProgrammes}
          programmes={isFetchingProgrammes ? [] : programmes?.data || []}
          onSubmit={(values) => setProgrammeId(values.programmeId)}
        />
      </Box>

      {!workoutsFind.data?.data &&
        !workoutsFind.isFetching &&
        !isFetchingProgrammes && (
          <Text color='grey1'>{t('workoutUnavailable')}</Text>
        )}

      <Workout
        bg={undefined}
        data={sanitizeWorkout({ workout: workoutsFind.data?.data?.[0] })}
        isLoading={workoutsFind.isFetching}
        showProgramme={false}
        showTrainerNotes={false}
        textProps={{ variant: 'subheading' }}
      />
    </Container>
  );
};
