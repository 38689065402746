import { Tooltip } from 'octiv-components';
import { userStatus } from 'octiv-utilities/Constants';
import { getWaiverOptions } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MemberTableActions = ({
  useContract,
  useWaiver,
  useScheduledActions,
  onClickAction,
  row: { original },
}) => {
  const { t } = useTranslation();

  const { userContract, waiver, ...restOriginal } = original || {};

  const userId = restOriginal?.userTenant
    ? restOriginal?.id
    : restOriginal?.userId;
  const userTenant = restOriginal?.userTenant || restOriginal;

  const { statusId, isHighRisk } = userTenant;

  return (
    <Tooltip
      stopPropagation
      options={[
        ...(useContract || useWaiver || useScheduledActions
          ? [{ title: t('member') }]
          : []),
        { label: t('manageMember'), value: 'view' },
        {
          label: isHighRisk ? t('removeHighRisk') : t('markHighRisk'),
          value: isHighRisk ? 'removeHighRisk' : 'markHighRisk',
        },
        ...(statusId === 2
          ? [
              {
                label: t('deactivate'),
                value: 'deactivate',
              },
            ]
          : statusId !== 2 && statusId !== 6
          ? [
              {
                label: t('activate'),
                value: 'activate',
              },
            ]
          : []),
        ...(statusId === 4
          ? [
              {
                label: t('delete'),
                value: 'delete',
              },
            ]
          : []),
        ...(useContract
          ? [
              { title: t('contract') },
              ...(userContract?.id
                ? [
                    {
                      label: t('sendContract'),
                      value: 'sendContract',
                    },
                    {
                      label: t('downloadContract'),
                      value: 'downloadContract',
                    },
                    ...(userContract.file
                      ? [
                          {
                            label: t('sendContractAttachment'),
                            value: 'sendContractAttachment',
                          },
                          {
                            label: t('downloadContractAttachment'),
                            value: 'downloadContractAttachment',
                          },
                        ]
                      : []),
                  ]
                : [
                    {
                      label: t('createContract'),
                      value: 'createContract',
                    },
                  ]),
            ]
          : []),
        ...(useWaiver
          ? [{ title: t('waiver') }, ...getWaiverOptions({ waiver })]
          : []),
        ...(useScheduledActions
          ? [
              { title: t('scheduledAction') },
              {
                label: t('delete'),
                value: 'deleteScheduledAction',
              },
            ]
          : []),
        ...([userStatus.ACTIVE, userStatus.PENDING].includes(statusId)
          ? [
              {
                label: t('sendWelcomeEmail'),
                value: 'sendWelcomeEmail',
              },
            ]
          : []),
      ]}
      placement='left'
      onClick={({ value }) =>
        onClickAction({
          action: value,
          userId,
          userTenantId: userTenant?.id,
          contractId: userContract?.id,
          waiverId: waiver?.id,
          scheduledActionId: restOriginal?.id,
        })
      }
    />
  );
};

export default MemberTableActions;
