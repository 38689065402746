import { Table } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';

export default ({ data }) => {
  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        id: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
    ],
    []
  );

  return (
    <Table
      columns={tableColumns}
      data={tableData}
      hasPadding={false}
      isVisibleHeader={false}
    />
  );
};
