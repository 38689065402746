import {
  Box,
  Card,
  InlineUser,
  ProcessingSuspense,
  ProgressBar,
  Text,
  ToggleItems,
} from 'octiv-components';
import { useEventModal } from 'octiv-context/EventModal';
import { useClassDatesFindByIdClassBookingDetails } from 'octiv-hooks/requests/ClassDates';
import {
  getBookingsData,
  getEventString,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isLoading, getEventsRequest }) => {
  const { t } = useTranslation();

  const { setEventModal } = useEventModal();

  const [activeIndex, setActiveIndex] = useState(0);
  const [bookings, setBookings] = useState([]);

  const event = data[activeIndex] || {};

  const {
    id: eventId,
    bookingsCount,
    class: classDate,
    limit,
    startTime,
    instructor,
    supportingInstructor,
    endTime,
    name,
  } = event;

  useClassDatesFindByIdClassBookingDetails(
    { id: eventId },
    {
      enabled: !!eventId && bookingsCount > 0,
      onSuccess: (response) => {
        setBookings(getBookingsData({ allBookings: response }).booked || []);
      },
    }
  );

  const attendancePercentage =
    (bookingsCount / limit) * 100 > 100 ? 100 : (bookingsCount / limit) * 100;

  return (
    <Card
      boxTitleContainerRenderRight={() =>
        !isLoading &&
        name && (
          <ToggleItems
            activeIndex={activeIndex}
            options={data}
            onClick={({ index }) => {
              setBookings([]);
              setActiveIndex(index);
            }}
          />
        )
      }
      textTitleProps={{ to: '/scheduling/calendar' }}
      title={t('schedule')}
    >
      {isLoading ? (
        <ProcessingSuspense
          items={[
            { width: '40%' },
            { width: '20%' },
            { width: '100%', height: 1 },
          ]}
        />
      ) : !name ? (
        <Text color='grey2'>{t('noEventsToday')}</Text>
      ) : (
        <>
          <Box isFlex alignItems='flex-end' justifyContent='space-between'>
            <Box>
              <Text
                isBold
                onClick={() =>
                  setEventModal({
                    type: 'view',
                    eventInstanceId: eventId,
                    initialEvent: event,
                    onClose: getEventsRequest,
                  })
                }
              >
                {getEventString({ name, startTime, endTime })}
              </Text>

              <Text color='grey1'>
                {getFullName(
                  instructor ||
                    classDate?.instructor ||
                    classDate?.supportingInstructor ||
                    supportingInstructor ||
                    {}
                )}
              </Text>
            </Box>

            <Box isFlex alignItems='flex-end'>
              <Text isBold lineHeight={1} variant='display'>
                {bookingsCount}
              </Text>

              <Text isBold mx={1} variant='subheading'>
                /
              </Text>

              <Text isBold variant='subheading'>
                {limit}
              </Text>
            </Box>
          </Box>

          <ProgressBar mt={2} width={`${attendancePercentage}%`} />

          {bookingsCount > 0 && (
            <Box isFlex flexWrap='wrap'>
              {bookings.map((booking) => (
                <InlineUser
                  {...booking}
                  boxContainerProps={{
                    height: undefined,
                    mr: 4,
                    mt: 2,
                  }}
                  imageProps={{
                    size: 8,
                  }}
                  isVisibleBookingInfo={false}
                  isVisibleOverdue={false}
                  key={booking.id}
                  textProps={{
                    variant: 'caption',
                  }}
                />
              ))}
            </Box>
          )}
        </>
      )}
    </Card>
  );
};
