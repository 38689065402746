import { Icon, Table, Text, Tooltip } from 'octiv-components';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('active'),
        accessor: 'isCurrent',
        Cell: ({ value }) =>
          value ? (
            <Icon isActive justifyContent='flex-start' name='check_circle' />
          ) : (
            ''
          ),
      },
      {
        Header: t('start'),
        accessor: 'startingAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('end'),
        accessor: 'endingAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('sentOn'),
        accessor: 'sentAt',
        Cell: ({ value }) =>
          value ? (
            getDateReadableDayMonthYear({ date: value })
          ) : (
            <Text as='span' color='danger'>
              {t('notSent')}
            </Text>
          ),
      },
      {
        Header: t('acceptedOn'),
        accessor: 'acceptedAt',
        Cell: ({ value }) =>
          value ? (
            getDateReadableDayMonthYear({ date: value })
          ) : (
            <Text as='span' color='danger'>
              {t('notAccepted')}
            </Text>
          ),
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { id, filePath },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              {
                label: t('sendContract'),
                value: 'sendContract',
              },
              {
                label: t('downloadContract'),
                value: 'downloadContract',
              },
              ...(filePath
                ? [
                    {
                      label: t('sendContractAttachment'),
                      value: 'sendContractAttachment',
                    },
                    {
                      label: t('downloadContractAttachment'),
                      value: 'downloadContractAttachment',
                    },
                  ]
                : []),
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
