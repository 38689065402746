import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isEnabled:
          typeof initialValues.isEnabled === 'number'
            ? initialValues.isEnabled
            : 1,
        isEnabledQuestionnaireMemberApp:
          typeof initialValues.isEnabledQuestionnaireMemberApp === 'number'
            ? initialValues.isEnabledQuestionnaireMemberApp
            : 1,
        isDisplayVaccinationDetailsClass:
          typeof initialValues.isDisplayVaccinationDetailsClass === 'number'
            ? initialValues.isDisplayVaccinationDetailsClass
            : 1,
        isDisplayVaccinationDetailsRoster:
          typeof initialValues.isDisplayVaccinationDetailsRoster === 'number'
            ? initialValues.isDisplayVaccinationDetailsRoster
            : 1,
      }}
      validationSchema={Yup.object().shape({
        isEnabled: Yup.bool().required(t('required')),
        isEnabledQuestionnaireMemberApp: Yup.bool().required(t('required')),
        isDisplayVaccinationDetailsClass: Yup.bool().required(t('required')),
        isDisplayVaccinationDetailsRoster: Yup.bool().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty, values: { isEnabled } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isHorizontal
                isRadio
                label={t('enableDisableFeature')}
                name='isEnabled'
                options={[
                  { label: t('enabled'), value: 1 },
                  { label: t('disabled'), value: 0 },
                ]}
              />
            </Col>

            {!!isEnabled && (
              <>
                <Col>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('answerCoronavirusQuestionnaireWhenCheckingIn')}
                    name='isEnabledQuestionnaireMemberApp'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('displayMemberVaccinationDetailsInClassView')}
                    name='isDisplayVaccinationDetailsClass'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('displayMemberVaccinationDetailsInRoster')}
                    name='isDisplayVaccinationDetailsRoster'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>
              </>
            )}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
