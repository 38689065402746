import { Table, Text } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const BookingValue = ({
  classBookingStatusId,
  original,
  value,
  onClickBookingStatus,
}) =>
  value ? (
    <Text
      as='span'
      color='primary'
      onClick={() =>
        onClickBookingStatus({ classBookingStatusId, ...original.class })
      }
    >
      {value}
    </Text>
  ) : (
    0
  );

export default ({ data, onClickBookingStatus, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('class'),
        accessor: 'class',
        Cell: ({ value }) => getEventString(value),
      },
      {
        Header: t('classInstances'),
        accessor: 'classDatesCount',
      },
      {
        Header: t('capacity'),
        accessor: 'capacity',
      },
      {
        Header: t('bookings'),
        accessor: 'booked',
        Cell: ({ value, row: { original } }) => (
          <BookingValue
            classBookingStatusId={1}
            original={original}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: `${t('attendance')} %`,
        id: 'attendance',
        Cell: ({
          row: {
            original: { capacity, booked },
          },
        }) => {
          let percentage = (booked / capacity) * 100;

          if (Number.isNaN(percentage)) {
            percentage = 0;
          } else if (!Number.isInteger(percentage)) {
            percentage = percentage.toFixed(2);
          }

          return `${percentage}%`;
        },
      },
      {
        Header: t('checkedIn'),
        accessor: 'checkedIn',
        Cell: ({ value, row: { original } }) => (
          <BookingValue
            classBookingStatusId={6}
            original={original}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('noShows'),
        accessor: 'noShow',
        Cell: ({ value, row: { original } }) => (
          <BookingValue
            classBookingStatusId={5}
            original={original}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
      {
        Header: t('cancellations'),
        accessor: 'allCancellations',
        Cell: ({ value, row: { original } }) => (
          <BookingValue
            classBookingStatusId={2}
            original={original}
            value={value}
            onClickBookingStatus={onClickBookingStatus}
          />
        ),
      },
    ],
    [onClickBookingStatus, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
