import { Table } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  toCapital,
  toCurrency,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('settlementDate'),
        accessor: 'settlementDate',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => toCapital({ value, shouldCapitalizeAll: true }),
      },
      {
        Header: t('totalAmount'),
        Cell: ({
          row: {
            original: { currency, totalAmount },
          },
        }) => toCurrency({ code: currency, value: totalAmount / 100 }),
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
