import { Icon, Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onDelete, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('description'),
        accessor: 'strategy',
        Cell: ({
          row: {
            original: { strategy, minMembers, maxMembers },
          },
        }) =>
          strategy.id === 'member_based_rate'
            ? `${t('memberBasedRate')}: ${minMembers} - ${maxMembers}`
            : t('flatRate'),
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('action'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }) => <Icon name='delete' onClick={() => onDelete({ id })} />,
      },
    ],
    [toCurrency, onDelete, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
