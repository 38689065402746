import { Icon, Tooltip } from 'octiv-components';
import { toCapital } from 'octiv-utilities/Functions';
import React from 'react';

const VaccinationIcon = ({ status, ...props }) =>
  status ? (
    <Tooltip
      delayShow={300}
      placement='top'
      text={toCapital({ value: status })}
      trigger='hover'
    >
      <Icon
        color={
          status === 'unvaccinated'
            ? 'warning'
            : status === 'vaccinated'
            ? 'success'
            : 'info'
        }
        name='coronavirus'
        {...props}
      />
    </Tooltip>
  ) : null;

export default VaccinationIcon;
