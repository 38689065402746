import { useQuery } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useLogbookFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['logbook', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/logbook`,
        params: {
          filter: {
            tenantId: filter.tenantId,
            userId: filter.userId,
            exerciseId: filter.exerciseId,
            type: filter.type,
            search: filter.search,
            isOnlyReturnBest: filter.isOnlyReturnBest,
          },
          ...paging,
        },
      }),
    options
  );
};
