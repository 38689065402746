import { FieldArray, Formik } from 'formik';
import _ from 'lodash';
import { Box, Button, Col, Divider, Field, Row, Text } from 'octiv-components';
import Step from 'octiv-components/step/index';
import { getFullName, toCase } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  primaryAccount,
  hasDuplicates,
  hasEmptyFields,
  signedInUser,
  userTenants,
  isLoading,
  primaryAccountId,
  onUpdateEmails,
  onBack,
  ...props
}) => {
  const { t } = useTranslation();

  const [, unassociatedUserTenants] = _.partition(
    userTenants,
    (ut) => ut.userId === primaryAccount?.id
  );

  return (
    <Formik
      {...props}
      initialValues={{
        userEmails: [],
      }}
      validationSchema={Yup.object().shape({
        // TODO: add schema for each array element
        // users: Yup.array().of(Yup.object().shape({}).required(t('required'))),
        userEmails: Yup.array(),
      })}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit}>
          <Step
            hasBorder
            boxContainerProps={{ mb: 4 }}
            mb='1rem'
            title={t('updateAccounts')}
            value='3'
          />
          <Box isFlex flexDirection='column'>
            <Text>{t('mergeAccountsAccountsToUpdate')}</Text>
          </Box>

          <br />

          <Row>
            <FieldArray
              name='userIds'
              render={() =>
                unassociatedUserTenants?.map((userTenant, index) => (
                  <React.Fragment key={`${index.toString()}`}>
                    <Divider hasLine mb={5} mt={4} />
                    <Col md={6} sm={12}>
                      <Field
                        canSubmitOnChange
                        label={t('emailAddress')}
                        name={`userEmails[${index}]`}
                        type='email'
                        onChange={(e) => {
                          setFieldValue(`userEmails[${index}]`, e.target.value);
                          onUpdateEmails?.({
                            [unassociatedUserTenants?.[index].userId]:
                              e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      md={6}
                      sm={12}
                      style={{
                        margin: 'auto',
                      }}
                    >
                      <Text
                        color={
                          userTenant?.status?.name === 'ACTIVE'
                            ? 'success'
                            : 'danger'
                        }
                        style={{
                          margin: 'auto',
                        }}
                      >{`${
                        unassociatedUserTenants?.[index]?.tenant?.name
                      } (${getFullName(
                        unassociatedUserTenants?.[index]
                      )} - ${toCase({
                        value: unassociatedUserTenants?.[index]?.status?.name,
                      })}) ${`(${toCase({
                        value: userTenant?.type?.name,
                      })})`}`}</Text>
                    </Col>
                  </React.Fragment>
                ))
              }
            />
          </Row>

          <br />
          {hasDuplicates && (
            <Text color='#F00'>{t('mergeAccountsNeedUniqueEmails')}</Text>
          )}

          <br />
          <Box isFlex flexDirection='row'>
            <Button
              hasBorder
              isLoading={isLoading}
              mr={'1rem'}
              text={t('back')}
              onClick={onBack}
            />
            <Button
              isDisabled={
                hasDuplicates ||
                hasEmptyFields ||
                values?.userEmails?.length === 0 ||
                values?.userEmails?.some((item) => item?.length === 0)
              }
              isLoading={isLoading}
              text={t('next')}
              type='submit'
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};
