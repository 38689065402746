import { Alert, Button, Modal, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useUserContractsCreate,
  useUserContractsCreateDownload,
  useUserContractsCreateDownloadAttachment,
  useUserContractsCreateSend,
  useUserContractsCreateSendAttachment,
  useUserContractsFind,
  useUserContractsUpdate,
} from 'octiv-hooks/requests/UserContracts';
import { downloadFile } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default ({ userId }) => {
  const { t } = useTranslation();

  const {
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const userContractsFind = useUserContractsFind({ filter: { userId } });

  const userContractsCreate = useUserContractsCreate({
    onSuccess: resetToggle,
  });

  const userContractsUpdate = useUserContractsUpdate({
    onSuccess: resetToggle,
  });

  const userContractsCreateSend = useUserContractsCreateSend({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userContractsCreateSendAttachment =
    useUserContractsCreateSendAttachment({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const userContractsCreateDownload = useUserContractsCreateDownload({
    onSuccess: (response) => downloadFile(response.file),
  });

  const userContractsCreateDownloadAttachment =
    useUserContractsCreateDownloadAttachment({
      onSuccess: (response) => downloadFile(response.file),
    });

  const onFormSubmit = (values) => {
    if (toggle.type === 'create') {
      userContractsCreate.mutate({ userId, ...values });
    } else {
      userContractsUpdate.mutate({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: userContractsFind.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'sendContract':
        userContractsCreateSend.mutate({ id });
        break;

      case 'sendContractAttachment':
        userContractsCreateSendAttachment.mutate({ id });
        break;

      case 'downloadContract':
        userContractsCreateDownload.mutate({ id });
        break;

      case 'downloadContractAttachment':
        userContractsCreateDownloadAttachment.mutate({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(userContractsCreate.isLoading ||
        userContractsCreateDownload.isLoading ||
        userContractsCreateDownloadAttachment.isLoading ||
        userContractsFind.isFetching ||
        userContractsCreateSend.isLoading ||
        userContractsCreateSendAttachment.isLoading ||
        userContractsUpdate.isLoading) && <ProcessingBar />}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
            'contract'
          )}`}
          onClose={resetToggle}
        >
          {toggle.type === 'create' &&
            !!userContractsFind.data?.data?.find(
              (item) => item.isCurrent === true
            ) && (
              <Alert
                mb={4}
                subtitle={t('warningModifyContract')}
                title={t('warning')}
                variant='warning'
              />
            )}

          <Form
            initialValues={toggle.data}
            isCreateFlow={toggle.type === 'create'}
            isCurrent={toggle.data?.isCurrent}
            isLoading={
              userContractsUpdate.isLoading || userContractsCreate.isLoading
            }
            locationOptions={locationOptions}
            onCancel={resetToggle}
            onSubmit={onFormSubmit}
          />
        </Modal>
      )}

      <Button
        mb={4}
        text={t('createNew')}
        onClick={() => setToggle({ type: 'create' })}
      />

      <Table
        data={userContractsFind.data?.data || []}
        isLoading={userContractsFind.isFetching}
        onClickAction={onClickAction}
      />
    </>
  );
};
