import {
  Alert,
  Box,
  Button,
  Col,
  Modal,
  ProcessingBar,
  Row,
  Text,
  ToggleTabs,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useTrainerRatesCreate,
  useTrainerRatesDelete,
  useTrainerRatesFind,
} from 'octiv-hooks/requests/TrainerRates';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default ({ userId }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [classRates, setClassRates] = useState([]);
  const [sessionRates, setSessionRates] = useState([]);

  const trainerRatesFind = useTrainerRatesFind(
    {
      filter: { userId },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        const sort = (rates) =>
          rates?.sort(
            (a, b) =>
              a.location.id - b.location.id ||
              a.strategy.id - b.strategy.id ||
              a.minMembers - b.minMembers
          );

        setClassRates(
          sort(response?.data?.filter((item) => item.type?.name === 'CLASSES'))
        );
        setSessionRates(
          sort(response?.data?.filter((item) => item.type?.name === 'SESSIONS'))
        );
      },
    }
  );

  const trainerRatesCreate = useTrainerRatesCreate({
    onSuccess: resetToggle,
  });

  const trainerRatesDelete = useTrainerRatesDelete();

  const onDelete = ({ id }) => {
    if (window.confirm(t('areYouSureProceed'))) {
      trainerRatesDelete.mutate({ id });
    }
  };

  const onSubmitTrainerRate = (values) => {
    const {
      location: { id },
      strategy,
      type,
    } = toggle.data;

    trainerRatesCreate.mutate({
      userId,
      locationId: id,
      strategy,
      type,
      ...values,
    });
  };

  return (
    <>
      {(trainerRatesFind.isFetching ||
        trainerRatesCreate.isLoading ||
        trainerRatesDelete.isLoading) && <ProcessingBar />}

      <Alert
        mb={4}
        subtitle={t('rateInfoSubtitle')}
        title={t('rateInfoTitle')}
        variant='info'
      />

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${toggle.data.location.name}: ${
            toggle.data.type === 'classes' ? t('class') : t('session')
          } ${
            toggle.data.type === 'flat_rate'
              ? t('flatRate')
              : t('memberBasedRate')
          }`}
          onClose={resetToggle}
        >
          <Form
            isLoading={trainerRatesCreate.isLoading}
            strategy={toggle.data.strategy}
            onSubmit={onSubmitTrainerRate}
          />
        </Modal>
      )}

      <ToggleTabs
        hasRadiusTop
        bg='grey5'
        options={locations.map((location) => {
          const locationClassRates = classRates.filter(
            (item) => item.location.id === location.id
          );
          const locationSessionRates = sessionRates.filter(
            (item) => item.location.id === location.id
          );

          const hasFlatRateClass = !!locationClassRates.find(
            (rate) => rate.strategy === 'flat_rate'
          );
          // const hasMemberBasedRateClass = !!locationClassRates.find(
          //   (rate) => rate.strategy === 'member_based_rate'
          // );

          const hasFlatRateSession = !!locationSessionRates.find(
            (rate) => rate.strategy === 'flat_rate'
          );
          // const hasMemberBasedRateSession = !!locationSessionRates.find(
          //   (rate) => rate.strategy === 'member_based_rate'
          // );

          const renderButtons = ({ type, hasFlatRate }) =>
            !trainerRatesFind.isFetching && (
              <Box isFlex>
                {!hasFlatRate && (
                  <Button
                    hasBorder
                    mr={2}
                    mt={2}
                    scale='small'
                    text={t('addFlatRate')}
                    onClick={() =>
                      setToggle({
                        data: {
                          location,
                          strategy: 'flat_rate',
                          type,
                        },
                      })
                    }
                  />
                )}

                <Button
                  hasBorder
                  mt={2}
                  scale='small'
                  text={t('addMemberBasedRate')}
                  onClick={() =>
                    setToggle({
                      data: {
                        location,
                        strategy: 'member_based_rate',
                        type,
                      },
                    })
                  }
                />
              </Box>
            );

          return {
            label: location.name,
            children: (
              <Box
                hasRadiusBottom
                borderColor='grey5'
                borderStyle='solid'
                borderWidth='1px'
                p={4}
              >
                <Row>
                  <Col md={6}>
                    <Text color='grey1' mb={1}>
                      {t('classRates')}
                    </Text>
                    <Table
                      data={locationClassRates}
                      isLoading={trainerRatesFind.isFetching}
                      onDelete={onDelete}
                    />

                    {renderButtons({
                      type: 'classes',
                      hasFlatRate: hasFlatRateClass,
                    })}
                  </Col>

                  <Col md={6}>
                    <Text color='grey1' mb={1}>
                      {t('sessionRates')}
                    </Text>

                    <Table
                      data={locationSessionRates}
                      isLoading={trainerRatesFind.isFetching}
                      onDelete={onDelete}
                    />

                    {renderButtons({
                      type: 'pt_sessions',
                      hasFlatRate: hasFlatRateSession,
                    })}
                  </Col>
                </Row>
              </Box>
            ),
          };
        })}
      />
    </>
  );
};
