import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import { getDateYearMonthDay, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isCreate,
  isFetchingMembers,
  isFetchingStaff,
  isLoading,
  locationOptions,
  members,
  onSelectLocation,
  staff,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        ...(!isCreate ? { statusId: initialValues.status || undefined } : {}),
        locationId: initialValues.locationId || undefined,
        referredById: initialValues.referredBy?.id || undefined, // TODO:
        source: initialValues.source || undefined,
        name: initialValues.name || undefined,
        surname: initialValues.surname || undefined,
        genderId: initialValues.gender
          ? initialValues.gender?.name === 'MALE'
            ? 1
            : 2
          : undefined,
        email: initialValues.email || undefined,
        mobile: initialValues.mobile || undefined,
        notes: initialValues.notes || undefined,
        dateOfBirth:
          getDateYearMonthDay({ date: initialValues.dateOfBirth }) || undefined,
        lastContactedAt:
          getDateYearMonthDay({ date: initialValues.lastContactedAt }) ||
          undefined,
        nextFollowUpAt:
          getDateYearMonthDay({ date: initialValues.nextFollowUpAt }) ||
          undefined,
      }}
      validationSchema={Yup.object().shape({
        ...(!isCreate
          ? { statusId: Yup.string().required(t('required')) }
          : {}),
        locationId: Yup.string().required(t('required')),
        referredById: Yup.string(),
        source: Yup.string(),
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        genderId: Yup.string(),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        mobile: Yup.string(),
        notes: Yup.string(),
        dateOfBirth: Yup.string(),
        lastContactedAt: Yup.string(),
        nextFollowUpAt: Yup.string(),
      })}
    >
      {({ handleSubmit, setFieldValue, values: { locationId } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                isSelect
                label={t('status')}
                name='statusId'
                options={[
                  { label: t('pending'), value: 'pending' },
                  { label: t('dropIn'), value: 'drop_in' },
                  { label: t('contacted'), value: 'contacted' },
                  { label: t('needsFollowUp'), value: 'follow_up' },
                  { label: t('requestedDemo'), value: 'request_demo' },
                  { label: t('converted'), value: 'converted' },
                  { label: t('other'), value: 'other' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                doFinally={(newLocationId) => {
                  setFieldValue('referredById', undefined);
                  onSelectLocation({ locationId: newLocationId });
                }}
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                isLoading={isFetchingStaff || isFetchingMembers}
                label={t('referredBy')}
                name='referredById'
                options={[
                  ...(staff
                    ? [
                        {
                          label: t('staff'),
                          options: staff.map((item) => ({
                            label: getFullName(item),
                            value: item.id,
                          })),
                        },
                      ]
                    : []),
                  ...(!!locationId && members
                    ? [
                        {
                          label: t('members'),
                          options: members.map((item) => ({
                            label: getFullName(item),
                            value: item.id,
                          })),
                        },
                      ]
                    : []),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('source')} name='source' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('firstName')} name='name' />
            </Col>

            <Col lg={3} md={6}>
              <Field label={t('surname')} name='surname' />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                label={t('gender')}
                name='genderId'
                options={genderOptions}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isDisabled={!isCreate}
                label={t('emailAddress')}
                name='email'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isDisabled={!isCreate}
                label={t('mobileNumber')}
                name='mobile'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field isDate label={t('dateOfBirth')} name='dateOfBirth' />
            </Col>

            <Col lg={3} md={6}>
              <Field isDate label={t('lastContacted')} name='lastContactedAt' />
            </Col>

            <Col lg={3} md={6}>
              <Field isDate label={t('nextFollowUp')} name='nextFollowUpAt' />
            </Col>

            <Col>
              <Field as='textarea' label={t('notes')} name='notes' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
