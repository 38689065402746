import { Container, Divider } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging } from 'octiv-hooks';
import {
  useFinancesDeleteGoCardlessMandates,
  useFinancesFindGoCardlessMandates,
  useFinancesFindGoCardlessMandatesMemberOnboardingLink,
  useFinancesUpdateGoCardlessMandatesSendOnBoardingLink,
} from 'octiv-hooks/requests/Finances';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });
  const [paging, setPaging] = usePaging();
  const [userId, setUserId] = useState(undefined);
  const [clickAction, setClickAction] = useState('');
  const { tenantId } = useActiveUserTenant();

  const {
    data: mandates,
    isFetching: isFetchingMandates,
    // refetch: getMandatesRequest,
  } = useFinancesFindGoCardlessMandates({
    filter: query,
    paging,
  });

  const {
    isLoading: isUpdatingMandateSendLink,
    mutate: putMandateSendLinkRequest,
  } = useFinancesUpdateGoCardlessMandatesSendOnBoardingLink({
    enabled: !!userId && clickAction === 'sendLink',
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      setUserId(null);
      setClickAction('');
    },
  });

  const { isFetching: isFetchingMandateLink } =
    useFinancesFindGoCardlessMandatesMemberOnboardingLink(
      {
        tenantId,
        userId,
      },
      {
        onSuccess: (response) => {
          setUserId(null);
          setClickAction('');
          window.open(response.url);
        },
        enabled: !!userId && clickAction === 'openLink',
      }
    );

  const { isLoading: isDeletingMandate, mutate: deleteMandateRequest } =
    useFinancesDeleteGoCardlessMandates({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'sendLink':
        setClickAction('sendLink');
        // setUserId(id);
        putMandateSendLinkRequest({
          userIds: [id],
        });

        break;

      case 'openLink':
        setClickAction('openLink');
        setUserId(id);
        // getMandateLinkRequest({
        //   userTenantId: id,
        // });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteMandateRequest({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('goCardlessMandates'),
        breadcrumbs: [t('accounts')],
      }}
      isLoading={
        isFetchingMandates ||
        isUpdatingMandateSendLink ||
        isDeletingMandate ||
        isFetchingMandateLink
      }
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      <Table
        data={mandates?.data || []}
        isLoading={isFetchingMandates}
        paging={mandates?.paging}
        setPaging={setPaging}
        onClickAction={onClickAction}
      />
    </Container>
  );
};
