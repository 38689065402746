import { Box, Button, Icon, Text } from 'octiv-components';
import React from 'react';
import { Link } from 'react-router-dom';

const Alert = ({
  buttonRightProps,
  iconProps,
  subtitle,
  textSubtitleProps,
  textTitleProps,
  title,
  variant = 'success',
  to,
  ...props
}) => {
  let colorBackground;
  let colorBorder;
  let icon;

  switch (variant) {
    case 'success':
      colorBackground = 'success20';
      colorBorder = 'success';
      icon = 'done';
      break;

    case 'info':
      colorBackground = 'info20';
      colorBorder = 'info';
      icon = 'info';
      break;

    case 'warning':
      colorBackground = 'warning20';
      colorBorder = 'warning';
      icon = 'warning';
      break;

    case 'danger':
      colorBackground = 'danger20';
      colorBorder = 'danger';
      icon = 'error';
      break;

    default:
      break;
  }

  return (
    <Box
      hasRadius
      isFlex
      alignItems='center'
      as={to ? Link : undefined}
      bg={colorBackground}
      border={[1]}
      borderColor={colorBorder}
      p={2}
      to={to}
      {...props}
    >
      {icon && <Icon color='font' ml={2} mr={4} name={icon} {...iconProps} />}

      {(title || subtitle) && (
        <Box>
          {title && (
            <Text isBold {...textTitleProps}>
              {title}
            </Text>
          )}

          {(subtitle || textSubtitleProps) && (
            <Text whiteSpace='pre-line' {...textSubtitleProps}>
              {subtitle}
            </Text>
          )}
        </Box>
      )}

      {buttonRightProps && (
        <Box ml='auto'>
          <Button
            hasBorder
            color='font'
            ml={2}
            scale='small'
            {...buttonRightProps}
          />
        </Box>
      )}
    </Box>
  );
};

export default Alert;
