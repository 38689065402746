import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, locations, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'locationId',
        Cell: ({ value }) =>
          locations?.find((location) => location.id === value)?.name,
      },
      {
        Header: t('creditorId'),
        id: 'creditorId',
        accessor: 'settings',
        Cell: ({ value }) => value?.creditorId,
      },
      {
        Header: t('bankIdentifierCode'),
        id: 'creditorBic',
        accessor: 'settings',
        Cell: ({ value }) => value?.creditorBic,
      },
      {
        Header: t('internationalBankAccountNumber'),
        id: 'creditorIban',
        accessor: 'settings',
        Cell: ({ value }) => value?.creditorIban,
      },
      {
        Header: t('action'),
        Cell: ({
          row: {
            original: {
              settings: { id, creditorId },
            },
          },
        }) => (
          <Text
            as='span'
            color={creditorId ? 'primary' : 'danger'}
            onClick={() => onClickAction({ id })}
          >
            {creditorId ? t('edit') : t('onboardNow')}
          </Text>
        ),
      },
    ],
    [onClickAction, t, locations]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
