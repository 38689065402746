import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useClassBookingsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    include = undefined,
    sort = undefined,
  } = input || {};

  return useQuery(
    ['classBookings', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-bookings`,
        params: {
          include,
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            userTypeId: filter.userTypeId,
            date: filter.date,
            classDateId: filter.classDateId,
            status: filter.status,
            between: filter.between,
            leadMemberId: filter.leadMemberId,
          },
          sort,
          ...paging,
        },
      }),
    options
  );
};

export const useClassBookingsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/class-bookings`,
        data: {
          classDateId: data.classDateId,
          userId: data.userId || data.leadId,
          nonMember: data.nonMember,
          bookingThresholdBypass: data.bookingThresholdBypass,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsFindByIdPackage = (input, options) => {
  const { filter = {}, paging = {}, include } = input || {};

  return useQuery(
    ['classBookings', 'findByIdPackage', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-bookings/package/${filter?.packageId}`,
        params: {
          filter: {
            classDateBetween: `${filter.startDate},${filter.endDate}`,
            locationId: filter.locationId,
          },
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useClassBookingsFindById = (input, options) => {
  const { id } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['classBookings', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-bookings/${id}`,
        params: {
          filter: {
            tenantId,
          },
        },
      }),
    options
  );
};

export const useClassBookingsCreateSendAthleteMessage = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/class-bookings/${id}/send-athlete-message`,
        data: { message: data.message },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsUpdateCancel = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/class-bookings/${id}/cancel`,
        data: { isLateCancellation: data.isLateCancellation },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsUpdateStatusUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/class-bookings/${id}/status-update`,
        data: { statusId: data.statusId, isCheckedIn: data.isCheckedIn },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsUpdateCheckIn = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/class-bookings/${id}/check-in`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsUpdateNoShow = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/class-bookings/${id}/no-show`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookings'] });
      queryClient.invalidateQueries({
        queryKey: ['classDates', 'findByIdClassBookingDetails'],
      });
      queryClient.invalidateQueries({ queryKey: ['whiteboard'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingsFindStats = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['classBookings', 'findStats', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-bookings/stats`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
          },
          ...paging,
        },
      }),
    options
  );
};
