import { Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  toCapital,
  toCurrency,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const getMetadataField = ({ value, variableName } = {}) =>
    Array.isArray(value)
      ? value?.find((field) => field?.variableName === variableName)?.value
      : null;

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('paidDate'),
        accessor: 'paidAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('invoiceCode'),
        accessor: 'metadata.customFields',
        id: 'invoiceCode',
        Cell: ({ value }) =>
          getMetadataField({ value, variableName: 'invoice_code' }),
      },
      {
        Header: t('invoiceUser'),
        accessor: 'metadata.customFields',
        id: 'invoiceUser',
        Cell: ({ value }) =>
          getMetadataField({ value, variableName: 'invoice_user_name' }),
      },
      {
        Header: t('invoiceDescription'),
        accessor: 'metadata.customFields',
        id: 'invoiceDescription',
        Cell: ({ value }) =>
          getMetadataField({ value, variableName: 'invoice_description' }),
      },
      {
        Header: t('amount'),
        id: 'amount',
        Cell: ({
          row: {
            original: { amount, currency },
          },
        }) => toCurrency({ code: currency, value: amount / 100 }),
      },
      {
        Header: t('fee'),
        id: 'fee',
        Cell: ({
          row: {
            original: { fees, currency },
          },
        }) => toCurrency({ code: currency, value: fees / 100 }),
      },
      {
        Header: t('total'),
        id: 'total',
        Cell: ({
          row: {
            original: { totalAmount, currency },
          },
        }) => toCurrency({ code: currency, value: totalAmount / 100 }),
      },
      {
        Header: t('channel'),
        accessor: 'channel',
        Cell: ({ value }) => toCapital({ value, shouldCapitalizeAll: true }),
      },
      {
        Header: t('gatewayResponse'),
        accessor: 'gatewayResponse',
        Cell: ({ value }) => toCapital({ value, shouldCapitalizeAll: true }),
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => toCapital({ value, shouldCapitalizeAll: true }),
      },
      {
        Header: t('viewInvoice'),
        accessor: 'metadata.customFields',
        id: 'invoiceId',
        Cell: ({ value }) => {
          const invoiceId = getMetadataField({
            value,
            variableName: 'invoice_id',
          });

          return invoiceId ? (
            <Text
              as='span'
              color='primary'
              onClick={() =>
                window.open(`/accounts/invoices?invoiceId=${invoiceId}`)
              }
            >
              {t('view')}
            </Text>
          ) : null;
        },
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
