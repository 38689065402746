import { Box, Image, Table, Text, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { isValidUrl } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('product'),
        Cell: ({
          cell: {
            row: {
              original: { name, imageUrl },
            },
          },
        }) => (
          <Box isFlex alignItems='center'>
            {isValidUrl(imageUrl) && (
              <Image hasRadius mr={2} size={8} src={imageUrl} />
            )}
            <Text as='span'>{name}</Text>
          </Box>
        ),
      },
      {
        Header: t('category'),
        accessor: 'category',
      },
      {
        Header: t('sku'),
        accessor: 'sku',
      },
      {
        Header: t('cost'),
        accessor: 'costPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('retail'),
        accessor: 'sellingPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('vat'),
        accessor: 'vat',
        Cell: ({ value }) => (value ? `${value}%` : ''),
      },
      {
        Header: t('stock'),
        accessor: 'stockLevel',
        Cell: ({ value }) => (value === -1 ? '∞' : value),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ cell }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
            ]}
            onClick={({ value }) =>
              onClickAction({ action: value, id: cell.value })
            }
          />
        ),
      },
    ],
    [onClickAction, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
