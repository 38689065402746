import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import {
  getDateYearMonthDay,
  getEventString,
  getFullName,
  getRecurringDay,
} from 'octiv-utilities/Functions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  event,
  events,
  initialValues = {},
  isCreate,
  isFetchingEvent,
  isFetchingEvents,
  isFetchingMembers,
  isLoading,
  locationOptions,
  members,
  onGetEventsRequest,
  onGetMembersRequest,
  ...props
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isCreate) {
      // TODO: ideally call the getById endpoint instead of the list
      onGetEventsRequest({ locationId: initialValues?.locationId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      {...props}
      initialValues={{
        ...(isCreate
          ? {
              locationId: undefined,
              classId: undefined,
              userId: undefined,
            }
          : {}),
        daysOfTheWeek: initialValues.daysOfTheWeek || [],
        endingOnDate:
          getDateYearMonthDay({ date: initialValues.endsAt }) || undefined,
      }}
      validationSchema={Yup.object().shape({
        ...(isCreate
          ? {
              locationId: Yup.string().required(t('required')),
              classId: Yup.string().required(t('required')),
              userId: Yup.string().required(t('required')),
            }
          : {}),
        daysOfTheWeek: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
        endingOnDate: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        values: { locationId, classId },
        resetForm,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            {isCreate && (
              <>
                <Col lg={4}>
                  <Field
                    isSelect
                    doFinally={(newLocationId) => {
                      resetForm();
                      setFieldValue('locationId', newLocationId);
                      onGetEventsRequest({ locationId: newLocationId });
                    }}
                    label={t('location')}
                    name='locationId'
                    options={locationOptions}
                  />
                </Col>

                {locationId && (
                  <Col lg={4}>
                    <Field
                      isSelect
                      doFinally={(newClassId) => {
                        setFieldValue('daysOfTheWeek', []);
                        onGetMembersRequest({
                          locationId,
                          classId: newClassId,
                        });
                      }}
                      isLoading={isFetchingEvents}
                      label={t('class')}
                      name='classId'
                      options={events?.map((item) => {
                        const itemWithTimes = {
                          ...item,
                          startTime: item?.startTime,
                          endTime: item?.endTime,
                        };

                        return {
                          label: getEventString(itemWithTimes),
                          value: item.id,
                        };
                      })}
                    />
                  </Col>
                )}

                {classId && (
                  <Col md={4}>
                    <Field
                      isSelect
                      isLoading={isFetchingMembers}
                      label={t('member')}
                      name='userId'
                      options={members?.map((item) => ({
                        label: getFullName(item),
                        value: item.id,
                      }))}
                    />
                  </Col>
                )}
              </>
            )}

            {(classId || !isCreate) && (
              <>
                <Col>
                  <Field
                    isArray
                    isCheckbox
                    isHorizontal
                    isLoading={isFetchingEvent}
                    label={t('daysToBook')}
                    name='daysOfTheWeek'
                    options={
                      isCreate
                        ? events
                            ?.find((item) => item.id === classId)
                            .recurringDays?.map((item) => ({
                              label: getRecurringDay({ day: item }),
                              value: item,
                            }))
                        : event?.recurringDays
                        ? event?.recurringDays?.map((item) => ({
                            label: getRecurringDay({ day: item }),
                            value: item,
                          }))
                        : events
                            ?.find(
                              (classEvent) =>
                                classEvent.id === initialValues?.classId
                            )
                            ?.recurringDays?.map((item) => ({
                              label: getRecurringDay({ day: item }),
                              value: item,
                            }))
                    }
                  />
                </Col>

                <Col md={4}>
                  <Field isDate label={t('endDate')} name='endingOnDate' />
                </Col>
              </>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
