import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useCoronavirusVaccinationDetailsFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['coronavirusVaccinationDetails', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/coronavirus-vaccination-details`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            status: filter.status,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useCoronavirusVaccinationDetailsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/coronavirus-vaccination-details`,
        data: {
          tenantId: data.tenantId || tenantId,
          userId: data.userId,
          status: data.status,
          file: data.file,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusVaccinationDetails'],
      });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCoronavirusVaccinationDetailsFindByIdDownload = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/coronavirus-vaccination-details/download/${id}`,
      });
    },
  });
};

export const useCoronavirusVaccinationDetailsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/coronavirus-vaccination-details/${id}`,
        data: { status: data.status, file: data.file },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusVaccinationDetails'],
      });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCoronavirusVaccinationDetailsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/coronavirus-vaccination-details/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({
        queryKey: ['coronavirusVaccinationDetails'],
      });

      options?.onSuccess?.(...res);
    },
  });
};
