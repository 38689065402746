import {
  Alert,
  Box,
  Card,
  Chip,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useToggle } from 'octiv-hooks';
import {
  useLinkedUsersCreate,
  useLinkedUsersDelete,
} from 'octiv-hooks/requests/LinkedUsers';
import { useUsersUpdateSwitch } from 'octiv-hooks/requests/Users';
import {
  getUserStatusName,
  getUserTypeString,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormLinkUser from './FormLinkUser';
import TableLinkedUser from './TableLinkedUser';

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    userTenants,
    linkedUsers,
    isRefetchingSignedInUser,
    refetchSignedInUser,
    signIn,
  } = useSignedInUser();
  const { setActiveUserTenant } = useActiveUserTenant();
  const [toggleLinkUser, setToggleLinkUser, resetToggleLinkUser] = useToggle();

  const usersUpdateSwitch = useUsersUpdateSwitch({
    onSuccess: ({ token }) => {
      setTimeout(() => {
        sessionStorage.clear();
        signIn({ jwt: token });
      }, 100);
    },
  });

  const linkedUsersCreate = useLinkedUsersCreate({
    onSuccess: resetToggleLinkUser,
  });

  const linkedUsersDelete = useLinkedUsersDelete();

  const onUserUnlink = ({ id }) => {
    // TODO: translations
    if (window.confirm('Are you sure you want to unlink this user?')) {
      linkedUsersDelete.mutate({ id });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(refetchSignedInUser, []);

  return (
    <>
      {toggleLinkUser.isVisible && (
        // TODO: translations
        <Modal isSidebar title='Link User' onClose={resetToggleLinkUser}>
          <Alert
            mb={4}
            // TODO: translations
            subtitle='You will need to provide the email address and password of the user you want to link to'
            variant='info'
          />

          <FormLinkUser
            isLoading={linkedUsersCreate.isLoading}
            onSubmit={(values) =>
              linkedUsersCreate.mutate({
                ...values,
                email: values.email ? values.email.trim() : undefined,
              })
            }
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          // TODO: translations
          title: 'My Accounts',
          breadcrumbs: ['User'],
        }}
        isLoading={
          isRefetchingSignedInUser ||
          usersUpdateSwitch.isFetching ||
          linkedUsersDelete.isLoading
        }
      >
        <Row mb={4}>
          {userTenants?.map((userTenant) => (
            <Col key={userTenant.id} lg={4} md={6}>
              <Card
                boxTitleContainerRenderRight={
                  userTenant.tenant.logo
                    ? () => (
                        <Image
                          alt={`${userTenant.tenant.name} logo`}
                          size={5}
                          src={userTenant.tenant.logo}
                        />
                      )
                    : undefined
                }
                title={userTenant.tenant.name}
                onClick={() => {
                  setActiveUserTenant(userTenant);
                  navigate('/dashboard');
                }}
              >
                <Row>
                  {[
                    {
                      // TODO: translations
                      title: 'Account Type',
                      body: getUserTypeString({ id: userTenant.typeId }),
                    },
                    {
                      // TODO: translations
                      title: 'Account Status',
                      body: getUserStatusName({ id: userTenant.statusId }),
                    },
                  ].map(
                    ({ title, body, ...props }) =>
                      (body || body === 0) && (
                        <Col key={title} md={6} {...props}>
                          <Text color='grey1' variant='caption'>
                            {title}
                          </Text>
                          <Text>{body}</Text>
                        </Col>
                      )
                  )}
                </Row>
              </Card>
            </Col>
          ))}
        </Row>

        <Card
          boxTitleContainerRenderRight={() => (
            <Box ml='auto'>
              <Chip
                hasAlternateBackground
                icon='add_link'
                title={t('linkUser')}
                onClick={setToggleLinkUser}
              />
            </Box>
          )}
          // TODO:
          title='Linked Users'
        >
          <TableLinkedUser
            hasAlternateBackground
            data={linkedUsers}
            isLoading={isRefetchingSignedInUser}
            isSwitchingUnlinking={
              usersUpdateSwitch.isFetching || linkedUsersDelete.isLoading
            }
            onSwitch={({ id }) => usersUpdateSwitch.mutate({ id })}
            onUnlink={onUserUnlink}
          />
        </Card>
      </Container>
    </>
  );
};
