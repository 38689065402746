import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingPackages,
  isFetchingProgrammes,
  isFetchingStaff,
  locationOptions,
  packages,
  programmes,
  staff,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        userTenantLocationId: Yup.string(),
        userTenantProgrammeId: Yup.string(),
        userTenantAssignedUserId: Yup.string(),
        userTenantStatusId: Yup.string().required(t('required')),
        userTenantDebitStatusId: Yup.string(),
        userTenantPackageId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='userTenantLocationId'
                options={locationOptions}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='userTenantStatusId'
                options={[
                  { label: t('all'), value: '1,2,3,4,6' },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingPackages}
                label={t('package')}
                name='userTenantPackageId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(packages?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingProgrammes}
                label={t('programme')}
                name='userTenantProgrammeId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(programmes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentType')}
                name='userTenantDebitStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('cashEftCard'), value: 1 },
                  { label: t('debitOrder'), value: 2 },
                  { label: t('noPayment'), value: 4 },
                  { label: t('upfrontPayment'), value: 5 },
                  { label: t('onlinePayment'), value: 6 },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingStaff}
                label={t('assignedStaff')}
                name='userTenantAssignedUserId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(staff?.map((item) => ({
                    label: getFullName(item),
                    value: item?.userTenant?.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
