import { Container, Divider } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useEventModal } from 'octiv-context/EventModal';
import { usePaging } from 'octiv-hooks';
import { useClassesDelete, useClassesFind } from 'octiv-hooks/requests/Classes';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const hasAccess = useContext(AccessContext);

  const {
    activeUserTenantId,
    isTrainer,
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const { setEventModal } = useEventModal();

  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    isSession: isTrainer ? true : undefined,
    locationId: selectedLocation?.id,
    tagIds: [],
    trainerUserTenantId: isTrainer ? activeUserTenantId : undefined,
  });

  const { data: locationTags, isFetching: isFetchingLocationTags } =
    useTagsFind({
      filter: { type: 'location', locationId: query.locationId },
    });

  const { data: usersTrainers, isFetching: isFetchingUsersTrainers } =
    useUsersFindStaff(
      {
        filter: {
          isMinimalData: true,
          userTenantLocationId: query.id,
        },
        paging: {
          perPage: -1,
        },
      },
      {
        enabled: hasAccess.containerMembers,
      }
    );

  const {
    data: events,
    isFetching: isFetchingEvents,
    refetch: getEventsRequest,
  } = useClassesFind({
    filter: {
      classTypeId: 2,
      isActive: 1,
      ...query,
    },
    include: 'daysOfWeek',
    paging,
  });

  const { isLoading: isDeletingEventMaster, mutate: deleteEventMasterRequest } =
    useClassesDelete({
      meta: {
        useSuccessToast: true,
      },
      onSuccess: getEventsRequest,
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setEventModal({
          type: 'updateMaster',
          eventMasterId: id,
          onClose: getEventsRequest,
        });
        break;

      case 'duplicate':
        setEventModal({
          type: 'create',
          eventMasterId: id,
          onClose: getEventsRequest,
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteEventMasterRequest({
            id,
            fromDate: getDateYearMonthDay({
              canFallback: true,
            }),
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('recurringEvents'),
        breadcrumbs: [t('scheduling')],
      }}
      isLoading={isFetchingEvents || isDeletingEventMaster}
    >
      <FormQuery
        initialValues={query}
        isFetchingLocationTags={isFetchingLocationTags}
        isFetchingUsersTrainers={isFetchingUsersTrainers}
        isTrainer={isTrainer}
        locationOptions={locationOptions}
        locationTags={locationTags?.data || []}
        usersTrainers={usersTrainers?.data || []}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      <Table
        data={events?.data || []}
        isLoading={isFetchingEvents}
        paging={events?.paging}
        query={query}
        setPaging={setPaging}
        onClickAction={onClickAction}
        onClickRow={({ original }) =>
          onClickAction({ action: 'edit', id: original.id })
        }
      />
    </Container>
  );
};
