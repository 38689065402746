import { Box, Icon, Table } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import {
  getDateReadableDayMonthYear,
  toCase,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MemberTableActions from '../shared/MemberTableActions';

export default ({ data, setSelected, onClickAction, query, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'user.name',
        sortBy: 'name',
        Cell: ({
          row: {
            original: { isHighRisk, user },
          },
        }) => (
          <Box isFlex alignItems='center'>
            {isHighRisk ? <Icon color='warning' mr={2} name='warning' /> : null}

            {user.name}
          </Box>
        ),
      },
      {
        Header: t('surname'),
        accessor: 'user.surname',
        sortBy: 'surname',
      },
      {
        Header: t('actionType'),
        accessor: 'action',
        sortBy: 'action',
        Cell: ({ value }) => toCase({ value, isCamelCase: true }),
      },
      {
        Header: t('actionDate'),
        accessor: 'date',
        sortBy: 'date',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({ row }) => (
          <MemberTableActions
            useScheduledActions
            row={row}
            onClickAction={onClickAction}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [...(!hasAccess.featureMembersActions ? ['actions'] : [])],
    [hasAccess.featureMembersActions]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'user.name',
        desc: false,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      sortBy={sortBy}
      onSelect={hasAccess.featureMembersActions ? setRowSelected : undefined}
      onSelectAll={
        hasAccess.featureMembersActions ? setAllRowsSelected : undefined
      }
    />
  );
};
