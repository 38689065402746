import { Box, Chip, Modal, ProcessingBar } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useTagsCreate,
  useTagsDelete,
  useTagsFind,
  useTagsUpdate,
} from 'octiv-hooks/requests/Tags';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../../components/Setting';
import Form from './Form';
import Table from './Table';

export default ({ location }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging] = usePaging();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useTagsFind({
    filter: {
      type: 'location',
      locationId: location.id,
    },
    paging,
  });

  const { isLoading: isCreatingSetting, mutate: postSettingRequest } =
    useTagsCreate({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingSetting, mutate: putSettingRequest } =
    useTagsUpdate({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isDeletingSetting, mutate: deleteSettingRequest } =
    useTagsDelete({
      onSuccess: getSettingsRequest,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      postSettingRequest({
        ...values,
        type: 'location',
        locationId: location.id,
      });
    } else {
      putSettingRequest({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: settings?.data?.find((item) => item.id === id),
        });
        break;

      case 'delete':
        deleteSettingRequest({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {isDeletingSetting && <ProcessingBar />}

      <Setting
        hasBorder
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setToggle({ type: 'create' })}
            />
          </Box>
        )}
        title={location.name}
      >
        {toggle.isVisible && (
          <Modal
            isSidebar
            title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
              'locationTag'
            )}`}
            onClose={resetToggle}
          >
            <Form
              initialValues={toggle.data}
              isLoading={isUpdatingSetting || isCreatingSetting}
              onSubmit={onPutPostRequest}
            />
          </Modal>
        )}

        <Table
          hasAlternateBackground
          data={settings?.data || []}
          isLoading={isFetchingSettings}
          paging={settings?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
