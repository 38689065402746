import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'name',
        Cell: ({ value }) => value,
      },
      {
        Header: t('action'),
        accessor: 'id',
        Cell: ({ value }) => (
          <Text
            as='span'
            color='primary'
            onClick={() => onClickAction({ data: { id: value } })}
          >
            {t('viewEdit')}
          </Text>
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      isVisibleHeader={false}
    />
  );
};
