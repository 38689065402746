import { Table, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            stopPropagation
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
            ]}
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
