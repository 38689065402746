import { Formik } from 'formik';
import { Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        isSession: Yup.number(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            canSubmitOnChange
            hasAlternateBackground
            isSelect
            label={t('location')}
            name='locationId'
            options={locationOptions}
          />
          <br />
          <Field
            canSubmitOnChange
            isHorizontal
            isRadio
            label={t('classType')}
            name='isSession'
            options={[
              { label: t('class'), value: 0 },
              { label: t('session'), value: 1 },
            ]}
          />
        </form>
      )}
    </Formik>
  );
};
