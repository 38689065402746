import { Box, Chart, Col, Row, Stats } from 'octiv-components';
import {
  useReportsFindDashboardAccountMetrics,
  useReportsFindDashboardMembersByPaymentType,
  useReportsFindDashboardPaymentsByType,
  useReportsFindDashboardSalesByType,
  useReportsFindDashboardTotalSalesAndPayments,
} from 'octiv-hooks/requests/Reports';
import { getMonths } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ query }) => {
  const { t } = useTranslation();

  const [membersByPaymentType, setMembersByPaymentType] = useState([]);
  const [salesAndPayments, setSalesAndPayments] = useState([]);
  const [salesByPaymentType, setSalesByPaymentType] = useState([]);
  const [paymentsByPaymentType, setPaymentsByPaymentType] = useState([]);

  const { data: accountMetrics, isFetching: isFetchingAccountMetrics } =
    useReportsFindDashboardAccountMetrics(
      { filter: query },
      {
        enabled: query.year > 2016,
      }
    );

  // TODO: this must be calculated against previous year as a date, not just a year
  // const { isLoading: isFetchingAccountMetricsPrevious } = useFetch({
  //   canRequest: query.year > 2016,
  //   ...getReportsDashboardAccountMetrics({
  //     ...query,
  //     year: query.year - 1,
  //   }),
  //   onSuccess: (response) => setAccountMetricsPrevious(response),
  // });

  const { isFetching: isFetchingMembersByPaymentType } =
    useReportsFindDashboardMembersByPaymentType(
      {
        filter: query,
      },
      {
        onSuccess: (response) => {
          const data = [];

          Object.keys(response).forEach((key) => {
            if (response[key] > 0) {
              data.push({
                name: key === 'upfrontPayment' ? t('upfrontPayment') : t(key),
                value: response[key],
              });
            }
          });

          setMembersByPaymentType(data);
        },
      }
    );

  const { isFetching: isFetchingSalesAndPayments } =
    useReportsFindDashboardTotalSalesAndPayments(
      {
        filter: query,
      },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            Object.keys(response[key1]).forEach((key2, index2) => {
              data[index2][t(key1)] = response[key1][key2];
            });
          });
          setSalesAndPayments(data);
        },
      }
    );

  const { isFetching: isFetchingSalesByPaymentType } =
    useReportsFindDashboardSalesByType(
      { filter: query },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            Object.keys(response[key1]).forEach((key2, index2) => {
              data[index2][t(key1)] = response[key1][key2];
            });
          });

          setSalesByPaymentType(data);
        },
      }
    );

  const { isFetching: isFetchingPaymentsByPaymentType } =
    useReportsFindDashboardPaymentsByType(
      { filter: query },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            Object.keys(response[key1]).forEach((key2, index2) => {
              data[index2][t(key1)] = response[key1][key2];
            });
          });

          setPaymentsByPaymentType(data);
        },
      }
    );

  const getAverageMemberLifespan = ({
    averageDays,
    averageMonths,
    averageYears,
  } = {}) => {
    if (!averageDays && !averageMonths && !averageYears) {
      return '';
    }

    return `${
      averageYears > 0
        ? `${averageYears} ${
            averageYears > 1
              ? t('years').toLowerCase()
              : t('year').toLowerCase()
          }`
        : ''
    }${averageYears > 0 && averageMonths > 0 ? ', ' : ''}${
      averageMonths > 0
        ? `${averageMonths} ${
            averageMonths > 1
              ? t('months').toLowerCase()
              : 'month'.toLowerCase()
          }`
        : ''
    }${averageYears > 0 || averageMonths > 0 ? ' & ' : ''}${averageDays} ${
      averageDays > 1 ? 'days'.toLowerCase() : 'day'.toLowerCase()
    }`;
  };

  return (
    <Box hasRadiusBottom bg='grey6' p={4}>
      <Stats
        data={[
          {
            title: t('totalInvoiced'),
            value:
              accountMetrics?.totalInvoiced?.[0]?.totalInvoiced ||
              accountMetrics?.totalInvoiced,
            isLoading: isFetchingAccountMetrics,
            isCurrency: true,
            // comparison: {
            //   values: !isFetchingAccountMetricsPrevious && [
            //     accountMetrics.totalInvoiced,
            //     accountMetricsPrevious.totalInvoiced,
            //   ],
            // },
          },
          {
            title: t('totalReceived'),
            value:
              accountMetrics?.totalReceived?.[0]?.totalReceived ||
              accountMetrics?.totalReceived,
            isLoading: isFetchingAccountMetrics,
            isCurrency: true,
            // comparison: {
            //   values: !isFetchingAccountMetricsPrevious && [
            //     accountMetrics.totalReceived,
            //     accountMetricsPrevious.totalReceived,
            //   ],
            // },
          },
          {
            title: t('totalOutstanding'),
            value: accountMetrics?.totalOutstanding,
            isLoading: isFetchingAccountMetrics,
            isCurrency: true,
            // comparison: {
            //   values: !isFetchingAccountMetricsPrevious && [
            //     accountMetrics.totalOutstanding,
            //     accountMetricsPrevious.totalOutstanding,
            //   ],
            //   shouldFirstValueBeGreater: false,
            // },
          },
          {
            title: t('averageMemberLifespan'),
            value: getAverageMemberLifespan(
              accountMetrics?.averageMemberLifespan
            ),
            isLoading: isFetchingAccountMetrics,
            // comparison: {
            //   values: !isFetchingAccountMetricsPrevious && [
            //     accountMetrics.averageMemberLifespan?.averageTotalDays,
            //     accountMetricsPrevious.averageMemberLifespan?.averageTotalDays,
            //   ],
            //   from: getAverageMemberLifespan(
            //     accountMetrics.averageMemberLifespan
            //   ),
            // },
          },
          {
            title: t('averageMemberRevenue'),
            value: accountMetrics?.averageMemberRevenue,
            isLoading: isFetchingAccountMetrics,
            isCurrency: true,
            // comparison: {
            //   values: !isFetchingAccountMetricsPrevious && [
            //     accountMetrics.averageMemberRevenue,
            //     accountMetricsPrevious.averageMemberRevenue,
            //   ],
            // },
          },
        ]}
        mb={6}
        mt={2}
      />

      <Row>
        <Col md={6} xxl={4}>
          <Chart
            isPie
            isShort
            data={membersByPaymentType}
            isLoading={isFetchingMembersByPaymentType}
            title={t('membersByPaymentType')}
          />
        </Col>

        <Col md={6} xxl={4}>
          <Chart
            isBar
            isCurrency
            isShort
            data={salesAndPayments}
            isLoading={isFetchingSalesAndPayments}
            title={t('salesPayments')}
          />
        </Col>

        <Col md={6} xxl={4}>
          <Chart
            isBar
            isCurrency
            isShort
            data={salesByPaymentType}
            isLoading={isFetchingSalesByPaymentType}
            title={t('salesByPaymentType')}
          />
        </Col>

        <Col md={6} xxl={4}>
          <Chart
            isBar
            isCurrency
            isShort
            data={paymentsByPaymentType}
            isLoading={isFetchingPaymentsByPaymentType}
            title={t('paymentsByPaymentType')}
          />
        </Col>
      </Row>
    </Box>
  );
};
