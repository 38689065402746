import { Container, ProcessingSuspense } from 'octiv-components';
import {
  useRemoteConfigFind,
  useRemoteConfigUpdate,
} from 'octiv-hooks/requests/RemoteConfig';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const {
    data: remoteConfig,
    isFetching: isFetchingRemoteConfig,
    refetch: getRemoteConfigRequest,
  } = useRemoteConfigFind();

  const { isLoading: isUpdatingRemoteConfig, mutate: putRemoteConfigRequest } =
    useRemoteConfigUpdate({ onSuccess: getRemoteConfigRequest });

  const handleSubmit = (values) => putRemoteConfigRequest(values);

  return (
    <Container
      appBarProps={{
        title: 'Remote Config',
        breadcrumbs: [t('settings')],
      }}
    >
      {isFetchingRemoteConfig ? (
        <ProcessingSuspense />
      ) : (
        <Form
          initialValues={remoteConfig || {}}
          isLoading={isUpdatingRemoteConfig}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};
