import moment from 'moment';
import { Box, Chart, Col, Modal, Row, SendComm, Stats } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useReportsFindDashboardMembersMetricDetails,
  useReportsFindDashboardMembersMetrics,
  useReportsFindDashboardMembersPerPackage,
  useReportsFindDashboardMembersPerProgramme,
} from 'octiv-hooks/requests/Reports';
import { getMonths, toCapital, toCase } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import Table from './Table';

export default ({ query }) => {
  const { t } = useTranslation();

  const [memberMetricsMonthly, setMemberMetricsMonthly] = useState([]);
  const [membersPerPackage, setMembersPerPackage] = useState([]);
  const [membersPerProgramme, setMembersPerProgramme] = useState([]);
  const [queryOverrides, setQueryOverrides] = useState({
    metric: query?.metric,
    month: query?.month,
  });

  const [toggle, setToggle, resetToggle] = useToggle();
  const month = moment().month() + 1;

  const { data: memberMetrics, isFetching: isFetchingMemberMetrics } =
    useReportsFindDashboardMembersMetrics({
      filter: {
        ...query,
        isMonthlyBreakdown: false,
      },
    });

  const {
    data: memberMetricsDetails,
    isFetching: isFetchingMemberMetricsDetails,
  } = useReportsFindDashboardMembersMetricDetails(
    {
      filter: {
        ...query,
        month: queryOverrides?.month,
        metric: queryOverrides?.metric,
      },
    },
    {
      enabled: !!queryOverrides?.metric,
      onSuccess: () => {
        // setQueryOverrides((prev) => ({
        //   ...prev,
        //   metric: undefined,
        // }));
      },
    }
  );

  // TODO: this must be calculated against previous year as a date, not just a year
  // const { isLoading: isFetchingMemberMetricsPrevious } = useFetch({
  //   canRequest: query.year > 2016,
  //   ...getReportsDashboardMembersMetrics({
  //     ...query,
  //     year: query.year - 1,
  //     isMonthlyBreakdown: false,
  //   }),
  //   onSuccess: (response) => setMemberMetricsPrevious(response),
  // });

  const { isFetching: isFetchingMemberMetricsMonthly } =
    useReportsFindDashboardMembersMetrics(
      {
        filter: {
          ...query,
          isMonthlyBreakdown: true,
        },
      },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            if (typeof response[key1] === 'object') {
              Object.keys(response[key1]).forEach((key2, index2) => {
                data[index2][t(key1)] = response[key1][key2];
              });
            }
          });

          setMemberMetricsMonthly(data);
        },
      }
    );

  const { isFetching: isFetchingMembersPerPackage } =
    useReportsFindDashboardMembersPerPackage(
      {
        filter: query,
      },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            if (typeof response[key1] === 'object') {
              Object.keys(response[key1]).forEach((key2, index2) => {
                data[index2][toCase({ value: key1, isCamelCase: true })] =
                  response[key1][key2];
              });
            }
          });

          setMembersPerPackage(data);
        },
      }
    );

  const { isFetching: isFetchingMembersPerProgramme } =
    useReportsFindDashboardMembersPerProgramme(
      {
        filter: query,
      },
      {
        onSuccess: (response) => {
          const data = [];

          response.forEach((item) => {
            if (item.isActive && item.total > 0) {
              data.push({
                name: item.name,
                value: item.total,
              });
            }
          });

          setMembersPerProgramme(data);
        },
      }
    );

  return (
    <>
      {toggle.isVisible && (
        <Modal
          renderRightTitle={() => (
            <SendComm
              isChip
              disabled={false}
              members={
                memberMetricsDetails?.map((item) => ({
                  user: {
                    ...item,
                    id: item?.userId,
                  },
                })) || []
              }
              ml='auto'
              mr={2}
            />
          )}
          title={`${toCapital({ value: toggle.type })} ${t('members')}`}
          onClose={resetToggle}
        >
          <Form
            amount={
              isFetchingMemberMetricsDetails
                ? undefined
                : memberMetricsDetails?.length
            }
            initialValues={{ month }}
            onSubmit={(values) =>
              // TODO: add update these requests
              toggle.type === 'new'
                ? setQueryOverrides((prev) => ({
                    ...prev,
                    month: values?.month,
                    metric: 'newMembers',
                  }))
                : setQueryOverrides((prev) => ({
                    ...prev,
                    month: values?.month,
                    metric: 'deactivatedMembers',
                  }))
            }
          />

          <Box height={4} />

          <Table
            data={isFetchingMemberMetricsDetails ? [] : memberMetricsDetails}
            isLoading={isFetchingMemberMetricsDetails}
            isNew={toggle.type === 'new'}
          />
        </Modal>
      )}

      <Box hasRadiusBottom bg='grey6' p={4}>
        <Stats
          data={[
            {
              title: t('active'),
              value: memberMetrics?.activeMembers,
              isLoading: isFetchingMemberMetrics,
            },
            {
              title: t('new'),
              value: memberMetrics?.newMembers,
              isLoading: isFetchingMemberMetrics,
              onClick: () => {
                setQueryOverrides((prev) => ({
                  ...prev,
                  month,
                  metric: 'newMembers',
                }));
                setToggle({ type: 'new' });
              },
              // comparison: {
              //   values: !isFetchingMemberMetricsPrevious && [
              //     memberMetrics.newMembers,
              //     memberMetricsPrevious.newMembers,
              //   ],
              // },
            },
            {
              title: t('deactivated'),
              value: memberMetrics?.deactivatedMembers,
              isLoading: isFetchingMemberMetrics,
              onClick: () => {
                setQueryOverrides((prev) => ({
                  ...prev,
                  month,
                  metric: 'deactivatedMembers',
                }));
                setToggle({ type: 'deactivated' });
              },
              // comparison: {
              //   values: !isFetchingMemberMetricsPrevious && [
              //     memberMetrics.deactivatedMembers,
              //     memberMetricsPrevious.deactivatedMembers,
              //   ],
              //   shouldFirstValueBeGreater: false,
              // },
            },
          ]}
          mb={6}
          mt={2}
        />

        <Row>
          <Col xxl={6}>
            <Chart
              isLine
              isShort
              data={memberMetricsMonthly || []}
              isLoading={isFetchingMemberMetricsMonthly}
              title={t('newDeactivated')}
            />
          </Col>

          <Col xxl={6}>
            <Chart
              isLine
              isShort
              data={membersPerPackage || []}
              isLoading={isFetchingMembersPerPackage}
              title={t('membersPerPackage')}
            />
          </Col>

          <Col md={6}>
            <Chart
              isPie
              data={membersPerProgramme || []}
              isLoading={isFetchingMembersPerProgramme}
              title={t('membersPerProgramme')}
            />
          </Col>
        </Row>
      </Box>
    </>
  );
};
