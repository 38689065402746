import { Table, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { toCapital } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('description'),
        accessor: 'description',
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('amount'),
        Cell: ({
          row: {
            original: { amount, type },
          },
        }) =>
          type === 'percentage' ? `${amount}%` : toCurrency({ value: amount }),
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
