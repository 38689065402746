import { ThemeContext } from 'octiv-context/Theme';
import { paymentGateways } from 'octiv-utilities/Constants';
import React from 'react';

import { useQuery } from '.';
import { useTenantsFindByToken } from './requests/Tenants';

export default () => {
  const publicToken = useQuery('publicToken');
  const { setTheme } = React.useContext(ThemeContext);

  const tenantsFindByToken = useTenantsFindByToken({
    publicToken,
    params: {
      include_contract_terms_and_conditions: 1,
      include_digital_terms_and_conditions: 1,
    },
  });

  const tenant = React.useMemo(() => {
    if (!tenantsFindByToken.data) return {};

    const activeLocations = tenantsFindByToken.data.locations
      .filter((item) => item.isActive)
      .sort((a, b) => a.name.localeCompare(b.name));

    const locationOptions = activeLocations.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    // NOTE: This shouldn't be needed, but acts as a fallback
    const defaultTheme = {
      isDark: 'true',
      buttonDark: '#FFFFFF',
      buttonLight: '#FFFFFF',
      primaryDark: '#79DA45',
      primaryLight: '#79DA45',
    };

    setTheme(tenantsFindByToken.data?.settings?.theme || defaultTheme);

    return {
      ...tenantsFindByToken.data,
      tenantId: tenantsFindByToken.data.id,
      locations: activeLocations,
      locationOptions,
      useContractWaiver:
        tenantsFindByToken.data.isSignUpUseContractsAndWaivers === 1,
      isGoCardless: !!activeLocations.find(
        (item) => item.paymentGatewayId === paymentGateways.GO_CARDLESS
      ),
      isSepa: !!activeLocations.find(
        (item) => item.paymentGatewayId === paymentGateways.SEPA
      ),
    };
  }, [tenantsFindByToken.data, setTheme]);

  return tenant;
};
