import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useClassBookingWaitingFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['classBookingWaiting', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-booking-waiting/${id}`,
      }),
    options
  );
};

export const useClassBookingWaitingCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/class-booking-waiting`,
        data: {
          tenantId: data.tenantId || tenantId,
          classDateId: data.classDateId,
          userId: data.userId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookingWaiting'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingWaitingCreateSendAthleteMessage = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/class-booking-waiting/${id}/send-athlete-message`,
        data: { message: data.message },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookingWaiting'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassBookingWaitingUpdateLeaveWaitingList = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/class-booking-waiting/${id}/leave-waiting-list`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classBookingWaiting'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};
