import moment from 'moment';
import {
  Box,
  Card,
  Image,
  ProcessingSuspense,
  SendComm,
  Text,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonth,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isLoading }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  return (
    <Card
      boxChildrenContainerProps={{
        height: 50,
        overflowY: 'scroll',
        className: 'hide-scrollbar',
      }}
      boxTitleContainerRenderRight={() => (
        <SendComm isIcon members={!isLoading && data} ml='auto' />
      )}
      textTitleProps={{ to: '/members/birthdays' }}
      title={t('upcomingBirthdays')}
    >
      {isLoading ? (
        <ProcessingSuspense
          items={[
            { width: '40%' },
            { width: '60%', mb: 4 },
            { width: '40%' },
            { width: '60%', mb: 4 },
          ]}
        />
      ) : !data?.[0] ? (
        <Text color='grey2'>{t('noUpcomingBirthdays')}</Text>
      ) : (
        data.map((user, index) => {
          const { id, dateOfBirth, image, userTenant } = user;
          const today = moment();
          const birthday = moment(dateOfBirth);

          return (
            <Box isFlex alignItems='center' key={id} mt={index > 0 ? 3 : 0}>
              <Image hasRadius usePlaceholderUser mr={2} size={9} src={image} />

              <Box>
                <Text
                  isBold
                  onClick={
                    canSetMemberModal({ userTenant, hasAccess })
                      ? () => setMemberModal({ userTenantId: userTenant?.id })
                      : null
                  }
                >
                  {getFullName(user)}
                </Text>

                <Text color='grey1' variant='caption'>
                  {`${getDateReadableDayMonth({
                    date: birthday,
                  })} (${moment().diff(birthday, 'years')}${
                    today.month() === birthday.month() &&
                    today.date() === birthday.date()
                      ? ` ${t('today').toLowerCase()}`
                      : ''
                  })`}
                </Text>
              </Box>
            </Box>
          );
        })
      )}
    </Card>
  );
};
