import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Contracts from './contracts';
import Waivers from './waivers';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('contractsAndWaivers'),
        breadcrumbs: [t('settings')],
      }}
    >
      <Contracts />

      <Waivers />
    </Container>
  );
};
