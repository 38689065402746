import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { toCapital } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('item'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('itemType'),
        accessor: 'discriminator',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('unitPrice'),
        accessor: 'unitPrice',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('quantity'),
        accessor: 'quantity',
      },
      {
        Header: t('total'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
    ],
    [toCurrency, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
