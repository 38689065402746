import { Modal, ProcessingSpinner } from 'octiv-components';
import { useUserPackageModal } from 'octiv-context/UserPackageModal';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import {
  useUserPackagesCreate,
  useUserPackagesUpdate,
} from 'octiv-hooks/requests/UserPackages';
import {
  useUserTenantsFindById,
  useUserTenantsFindByIdPaymentDetails,
} from 'octiv-hooks/requests/UserTenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { paymentTypes } from '../../userMember/billing';
import FormPackage from './FormPackage';

export default () => {
  const { t } = useTranslation();

  const {
    userId,
    userTenantId,
    userLocationId,
    validPackageIds,
    initialUserPackage,
    onClose,
    resetUserPackageModal,
  } = useUserPackageModal();

  const [tenantPackages, setTenantPackages] = useState();

  const isCreate = !initialUserPackage?.id;

  const onCloseModal = () => {
    resetUserPackageModal();
    if (onClose) onClose();
  };

  const userTenantsFindById = useUserTenantsFindById(
    {
      id: userTenantId,
      include: 'locations',
    },
    {
      enabled: !userLocationId,
    }
  );

  const locationId =
    userLocationId || userTenantsFindById.data?.locations?.[0]?.id;

  const userTenantsFindByIdPaymentDetails =
    useUserTenantsFindByIdPaymentDetails(
      {
        id: userTenantId,
      },
      {
        enabled: isCreate,
      }
    );

  const isCashMember =
    userTenantsFindByIdPaymentDetails.data?.paymentDetails?.userDebitStatus
      ?.id === paymentTypes.CASH;
  const isDebitOrderMember =
    userTenantsFindByIdPaymentDetails.data?.paymentDetails?.userDebitStatus
      ?.id === paymentTypes.DEBIT_ORDER;

  const packagesFind = usePackagesFind(
    {
      filter: isCreate
        ? {
            typeId: '1,2,3',
            isHidden: 0,
            isActive: 1,
            locationId,
          }
        : { packageId: initialUserPackage.package.id },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        setTenantPackages(
          validPackageIds?.[0]
            ? response?.data?.filter((item) =>
                validPackageIds.includes(item.id)
              )
            : response?.data
        );
      },
      enabled: !!locationId,
    }
  );

  const userPackagesCreate = useUserPackagesCreate();

  const userPackagesUpdate = useUserPackagesUpdate({
    onSuccess: onCloseModal,
  });

  const onSubmitFormPackage = ({
    invoice: { shouldGenerate, ...invoice },
    ...packageDetails
  }) => {
    if (isCreate) {
      userPackagesCreate.mutate(
        {
          userId,
          ...packageDetails,
          ...(shouldGenerate ? { invoice } : {}),
        },
        {
          onSuccess: (response) => {
            const invoiceCode = response?.invoices?.[0]?.code;
            if (invoice.paymentType === 'adhocPayment' && invoiceCode) {
              window.open(
                `/accounts/invoices?requestPaymentCode=${invoiceCode}`
              );
            }

            onCloseModal();
          },
          onSuccessToast:
            invoice.paymentType === 'adhocPayment'
              ? undefined
              : {
                  title: t('actionSuccessful'),
                },
        }
      );
    } else {
      userPackagesUpdate.mutate({
        id: initialUserPackage.id,
        ...packageDetails,
      });
    }
  };

  return (
    <Modal
      isSidebar
      title={`${isCreate ? t('createPackage') : t('updatePackage')}`}
      onClose={onCloseModal}
    >
      {userTenantsFindById.isFetching ||
      userTenantsFindByIdPaymentDetails.isFetching ? (
        <ProcessingSpinner mx='auto' />
      ) : (
        <FormPackage
          initialValues={initialUserPackage}
          isCashMember={isCashMember}
          isCreate={isCreate}
          isDebitOrderMember={isDebitOrderMember}
          isFetchingTenantPackages={packagesFind.isFetching}
          isFetchingUserPaymentDetails={
            userTenantsFindByIdPaymentDetails.isFetching
          }
          isLoading={
            userPackagesUpdate.isLoading || userPackagesCreate.isLoading
          }
          tenantPackages={tenantPackages}
          onSubmit={onSubmitFormPackage}
        />
      )}
    </Modal>
  );
};
