import { Table } from 'octiv-components';
import { toCapital, toCurrency } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, currencyCode, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('item'),
        accessor: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('itemType'),
        accessor: 'discriminator',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('unitPrice'),
        accessor: 'unitPrice',
        Cell: ({ value }) => toCurrency({ value, code: currencyCode }),
      },
      {
        Header: t('quantity'),
        accessor: 'quantity',
      },
      {
        Header: t('total'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value, code: currencyCode }),
      },
    ],
    [currencyCode, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
