import { Box, Button, Container, Modal, SendComm } from 'octiv-components';
import { UserStaff } from 'octiv-containers';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import {
  useUserTenantsDelete,
  useUserTenantsUpdateBulkStatus,
} from 'octiv-hooks/requests/UserTenants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Create from './create';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const { typeId } = useActiveUserTenant();

  const [
    toggleCreateAccount,
    setToggleCreateAccount,
    resetToggleCreateAccount,
  ] = useToggle();
  const [toggleUserStaff, setToggleUserStaff, resetToggleUserStaff] =
    useToggle();
  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    userTenantStatusId: 2,
    search: undefined,
  });

  const usersFindStaff = useUsersFindStaff({
    filter: query,
    paging,
    sort: 'name',
  });

  const userTenantsUpdateBulkStatus = useUserTenantsUpdateBulkStatus({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userTenantsDelete = useUserTenantsDelete({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onClickAction = ({ action, userId, userTenantId }) => {
    switch (action) {
      case 'view':
        setToggleUserStaff({ data: { userTenantId } });
        break;

      case 'activate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: [userId],
          statusId: 2,
        });
        break;

      case 'deactivate':
        userTenantsUpdateBulkStatus.mutate({
          userIds: [userId],
          statusId: 4,
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          userTenantsDelete.mutate({ id: userTenantId });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleCreateAccount.isVisible && (
        <Modal title={t('createNew')} onClose={resetToggleCreateAccount}>
          <Create onSuccess={resetToggleCreateAccount} />
        </Modal>
      )}

      {toggleUserStaff.isVisible && (
        <UserStaff {...toggleUserStaff.data} onClose={resetToggleUserStaff} />
      )}

      <Container
        appBarProps={{
          title: t('manageStaff'),
          breadcrumbs: [t('staff')],
          children: (
            <Box ml='auto'>
              <Box isFlex>
                <SendComm
                  buttonProps={{ hasBorder: true }}
                  mr={2}
                  staff={
                    !usersFindStaff.isFetching && usersFindStaff.data?.data
                  }
                />

                <Button
                  text={t('createNew')}
                  onClick={setToggleCreateAccount}
                />
              </Box>
            </Box>
          ),
        }}
        isLoading={
          usersFindStaff.isFetching ||
          userTenantsUpdateBulkStatus.isLoading ||
          userTenantsDelete.isLoading
        }
      >
        <FormQuery initialValues={query} onSubmit={setQuery} />

        <Box height={4} />

        <Table
          data={usersFindStaff.data?.data}
          isLoading={usersFindStaff.isFetching}
          loggedInUserTypeId={typeId}
          paging={usersFindStaff.data?.paging}
          query={query}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onClickRow={({ original: { userTenant } }) =>
            setToggleUserStaff({ data: { userTenantId: userTenant.id } })
          }
        />
      </Container>
    </>
  );
};
