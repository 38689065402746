import { Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import { useClassBookingsFind } from 'octiv-hooks/requests/ClassBookings';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import {
  useCoronavirusQuestionnaireResultsCreate,
  useCoronavirusQuestionnaireResultsUpdate,
} from 'octiv-hooks/requests/CoronavirusQuestionnaireResults';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import FormQuestionnaire from './FormQuestionnaire';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    date: getDateYearMonthDay({ canFallback: true }),
    locationId: selectedLocation?.id,
    classDateId: undefined,
  });

  const { data: classes, isFetching: isFetchingClasses } = useClassDatesFind({
    filter: {
      locationId: query.locationId,
      between: `${query.date},${query.date}`,
    },
    paging: {
      perPage: -1,
    },
  });

  const coronavirusClassBookings = useClassBookingsFind({
    include: 'coronavirusQuestionaireResult',
    filter: {
      locationId: query.locationId,
      between: `${query.date},${query.date}`,
      classDateId: query.classDateId,
    },
  });

  const {
    isLoading: isCreatingCoronavirusQuestionnaireResult,
    mutate: postCoronavirusQuestionnaireResultRequest,
  } = useCoronavirusQuestionnaireResultsCreate({
    onSuccess: () => {
      coronavirusClassBookings.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingCoronavirusQuestionnaireResult,
    mutate: putCoronavirusQuestionnaireResultRequest,
  } = useCoronavirusQuestionnaireResultsUpdate({
    onSuccess: () => {
      coronavirusClassBookings.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onSubmitFormQuestionnaire = (values) => {
    if (toggle.type === 'create') {
      postCoronavirusQuestionnaireResultRequest({
        classBookingId: toggle.data.classBookingId,
        ...values,
      });
    } else {
      putCoronavirusQuestionnaireResultRequest({
        id: toggle.data.id,
        ...values,
      });
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          isSidebar
          title={t('coronavirusQuestionnaire')}
          onClose={resetToggle}
        >
          <FormQuestionnaire
            initialValues={toggle.data}
            isLoading={
              isCreatingCoronavirusQuestionnaireResult ||
              isUpdatingCoronavirusQuestionnaireResult
            }
            onSubmit={onSubmitFormQuestionnaire}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('coronavirus'),
          breadcrumbs: [t('scheduling')],
        }}
        isLoading={isFetchingClasses || coronavirusClassBookings.isFetching}
      >
        <FormQuery
          classes={classes?.data || []}
          initialValues={query}
          isFetchingClasses={isFetchingClasses}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Divider pb={6} />

        <Table
          data={
            coronavirusClassBookings.isFetching
              ? []
              : coronavirusClassBookings?.data?.data
          }
          isLoading={coronavirusClassBookings?.isFetching}
          query={query}
          onClickAction={setToggle}
        />
      </Container>
    </>
  );
};
