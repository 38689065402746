import { Table, Tooltip } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('startDate'),
        accessor: 'startsAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('releaseDate'),
        accessor: 'releaseAt',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('packagesExtended'),
        accessor: 'isExtendPackage',
        Cell: ({ value }) => (value ? t('yes') : t('no')),
      },
      {
        Header: t('firstDebitOrderInvoiceAmount'),
        accessor: 'proRataFee',
        Cell: ({ value }) => (value ? toCurrency({ value }) : null),
      },
      {
        Header: t('note'),
        accessor: 'note',
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: () => (
          <Tooltip
            options={[
              { label: t('editDetails'), value: 'updateOnHold' },
              { label: t('releaseOnHold'), value: 'releaseOnHold' },
              { label: t('cancelOnHold'), value: 'cancelOnHold' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value })}
          />
        ),
      },
    ],
    [toCurrency, onClickAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
