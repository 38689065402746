import _ from 'lodash';
import {
  Card,
  Image,
  Modal,
  ProcessingSuspense,
  ToggleTabs,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useAccessPrivilegesFind } from 'octiv-hooks/requests/AccessPrivileges';
import { useUsersUpdate } from 'octiv-hooks/requests/Users';
import {
  useUserTenantsFindById,
  useUserTenantsUpdate,
} from 'octiv-hooks/requests/UserTenants';
import { getFullName } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormUser from '../user/profile/FormUser';
import FormAccess from './FormAccess';
import FormUserTenant from './FormUserTenant';
import Rates from './rates';

export const views = {
  PROFILE: 'profile',
  ACCESS: 'access',
  RATES: 'rates',
};

export default ({ userTenantId, initialView = views.PROFILE, onClose }) => {
  const { t } = useTranslation();

  const {
    typeId: loggedInUserTypeId,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const { isSuperAdmin } = useSignedInUser();
  const [selectedView, setSelectedView] = useState(initialView);

  const userTenantsFindById = useUserTenantsFindById({
    id: userTenantId,
    include: 'user,accessPrivileges,locationAccessPrivileges,locations',
  });

  const userTenant = userTenantsFindById.data;
  const userTenantTypeId = userTenant?.typeId;
  const user = userTenant?.user;
  const userId = user?.id;

  const accessPrivilegesFind = useAccessPrivilegesFind(
    { filter: { userTypeId: userTenantTypeId }, paging: { perPage: -1 } },
    {
      enabled: !!userTenantTypeId && selectedView === views.ACCESS,
    }
  );

  const userTenantsUpdate = useUserTenantsUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const usersUpdate = useUsersUpdate({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const returnView = () => {
    switch (selectedView) {
      case views.PROFILE:
        return (
          <>
            <Card hasBorder mb={4} title={t('userDetails')}>
              <FormUser
                includePassword={false}
                initialValues={user}
                isLoading={usersUpdate.isLoading}
                onSubmit={usersUpdate.mutate}
              />
            </Card>

            <Card hasBorder title={t('accountDetails')}>
              <FormUserTenant
                initialValues={userTenant}
                isLoading={userTenantsUpdate.isLoading}
                locationOptions={locationOptions}
                loggedInUserTypeId={loggedInUserTypeId}
                onSubmit={(values) =>
                  userTenantsUpdate.mutate({ id: userTenantId, ...values })
                }
              />
            </Card>
          </>
        );

      case views.RATES:
        return <Rates userId={userId} />;

      case views.ACCESS:
        return (
          <FormAccess
            accessPrivileges={accessPrivilegesFind.data?.data}
            initialValues={userTenant}
            isFetchingAccessPrivileges={accessPrivilegesFind.isFetching}
            isLoading={userTenantsUpdate.isLoading}
            locationOptions={locationOptions}
            typeId={userTenantTypeId}
            onSubmit={(values) =>
              userTenantsUpdate.mutate({ id: userTenantId, ...values })
            }
          />
        );

      default:
        return null;
    }
  };

  const isVisibleDetail = !(
    userTenantsFindById.isFetching && _.isEmpty(userTenant)
  );

  return (
    <Modal
      isLoading={userTenantsFindById.isFetching || userTenantsUpdate.isLoading}
      renderAboveContent={() =>
        isVisibleDetail && !isSuperAdmin && loggedInUserTypeId === 2 ? (
          <ToggleTabs
            activeKey={selectedView}
            mt={1}
            options={[
              { label: t('profile'), key: views.PROFILE },
              ...(userTenantTypeId !== 8
                ? [
                    ...(userTenantTypeId !== 2
                      ? [{ label: t('access'), key: views.ACCESS }]
                      : []),
                    { label: t('rates'), key: views.RATES },
                  ]
                : []),
            ]}
            px={4}
            setActiveKey={setSelectedView}
          />
        ) : null
      }
      renderLeftTitle={() =>
        isVisibleDetail && (
          <Image
            hasRadius
            usePlaceholderUser
            mr={2}
            size={8}
            src={user?.image}
          />
        )
      }
      title={isVisibleDetail && getFullName(user)}
      onClose={onClose}
    >
      {isVisibleDetail ? returnView() : <ProcessingSuspense />}
    </Modal>
  );
};
