import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        showAllClasses: initialValues.showAllClasses || undefined,
        paymentType: initialValues.paymentType || undefined,
      }}
      validationSchema={Yup.object().shape({
        showAllClasses: Yup.string().required(t('required')),
        paymentType: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isRadio
                label={t('classDisplay')}
                name='showAllClasses'
                options={[
                  { label: t('displayFullSchedule'), value: 'yes' },
                  {
                    label: t('displayOnlyApplicableClasses'),
                    value: 'no',
                  },
                ]}
              />
            </Col>

            <Col>
              <Field
                isRadio
                label={t('paymentMethods')}
                name='paymentType'
                options={[
                  { label: t('onlinePaymentOnly'), value: 'adhoc' },
                  {
                    label: t('cashPaymentOnly'),
                    value: 'cash',
                  },
                ]}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
