import { Container, Modal, Text } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { useDebitBatchesCreateReconcileSepaBankStatement } from 'octiv-hooks/requests/DebitBatches';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import TableResponse from './TableResponse';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const {
    isLoading: isCreatingDebitBatchReconcile,
    mutate: postDebitBatchReconcileRequest,
  } = useDebitBatchesCreateReconcileSepaBankStatement({
    onSuccess: (response) => {
      // TODO: get resetForm function to be called onSuccess
      setToggle({ data: response });
    },
  });

  const onSubmitForm = (values, { resetForm: resetFormFunc }) => {
    postDebitBatchReconcileRequest(values);
    resetFormFunc();
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('results')} onClose={resetToggle}>
          <Text mb={2} variant='heading'>
            {t('successes')}: {toggle.data?.successes?.length}
          </Text>
          <TableResponse data={toggle.data?.successes} />

          <Text mb={2} mt={4} variant='heading'>
            {t('failures')}: {toggle.data?.failures?.length}
          </Text>
          <TableResponse data={toggle.data?.failures} />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('debitBatchRecon'),
          breadcrumbs: [t('accounts')],
        }}
        isLoading={isCreatingDebitBatchReconcile}
      >
        <Form
          isLoading={isCreatingDebitBatchReconcile}
          onSubmit={onSubmitForm}
        />
      </Container>
    </>
  );
};
