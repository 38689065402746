import moment from 'moment';
import {
  Box,
  BulkActions,
  Button,
  Card,
  Container,
  Divider,
  Modal,
} from 'octiv-components';
import { AccountsInvoice } from 'octiv-containers';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useQuery, useToggle } from 'octiv-hooks';
import {
  useFinancesCreatePaymentsBulkCreate,
  useFinancesCreateUserInvoicesBulkReverse,
  useFinancesCreateUserInvoicesBulkSendInvoice,
  useFinancesCreateUserInvoicesSendPaymentRequest,
  useFinancesDeleteUserInvoices,
  useFinancesFindByIdUserInvoicesDownload,
  useFinancesFindByIdUserInvoicesSend,
  useFinancesFindUserInvoices,
  useFinancesFindUserInvoicesExport,
  useFinancesUpdateUserInvoicesAddToDebitBatch,
  useFinancesUpdateUserInvoicesReverse,
} from 'octiv-hooks/requests/Finances';
import { useLocationPaymentGatewaysFindAdhoc } from 'octiv-hooks/requests/LocationPaymentGateways';
import {
  downloadFile,
  getDateYearMonthDay,
  getInvoiceLocationId,
  getSelectedRows,
} from 'octiv-utilities/Functions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormAddToBatch from './FormAddToBatch';
import FormQuery from './FormQuery';
import FormRecordPayment from './FormRecordPayment';
import FormRequestPayment from './FormRequestPayment';
import TableInvoices from './TableInvoices';
import TableRequestPaymentResponse from './TableRequestPaymentResponse';

export default () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paging, setPaging, resetPaging] = usePaging();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  let requestPaymentCode = useQuery('requestPaymentCode');
  const search = useQuery('search') || requestPaymentCode;
  const invoiceId = useQuery('invoiceId');

  const [
    toggleAddToDebitBatch,
    setToggleAddToDebitBatch,
    resetToggleAddToDebitBatch,
  ] = useToggle();
  const [
    toggleFormRecordPayment,
    setToggleFormRecordPayment,
    resetToggleFormRecordPayment,
  ] = useToggle();
  const [
    toggleFormRequestPayment,
    setToggleFormRequestPayment,
    resetToggleFormRequestPayment,
  ] = useToggle();
  const [toggleInvoice, setToggleInvoice, resetToggleInvoice] = useToggle();
  const [invoices, setInvoices] = useState([]);
  const [sendType, setSendType] = useState('');
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(
    invoiceId || undefined
  );
  const selectedData = getSelectedRows({ data: invoices });
  const [query, setQuery] = useState({
    datePeriod: 'custom',
    type: 'athleteInvoices',
    paymentType: undefined,
    startDate: getDateYearMonthDay({
      date: moment().startOf('month'),
    }),
    endDate: getDateYearMonthDay({ date: moment().endOf('month') }),
    locationId: search ? undefined : selectedLocation?.id,
    sentStatus: undefined,
    search: search || undefined,

    sort: '-due_on',
  });

  const financesUserInvoices = useFinancesFindUserInvoices(
    {
      include: 'user_tenant,payments',
      filter: query,
      sort: query.sort,
      paging,
    },
    {
      onSuccess: ({ data = [] }) => {
        setInvoices(data);

        if (requestPaymentCode && data.length === 1) {
          navigate(`/accounts/invoices?search=${requestPaymentCode}`, {
            replace: true,
          });
          requestPaymentCode = null;

          const invoice = data[0];

          setToggleFormRequestPayment({
            type: 'single',
            data: {
              invoiceIds: [invoice.id],
              locationId: getInvoiceLocationId(invoice),
            },
          });
        }
      },
      refetchOnFocus: selectedData?.length === 0,
    }
  );

  const { isLoading: isDeletingInvoice, mutate: deleteInvoiceRequest } =
    useFinancesDeleteUserInvoices({
      onSuccess: financesUserInvoices.refetch,
      enabled: false,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const {
    isLoading: isUpdatingInvoiceReverse,
    mutate: putInvoiceReverseRequest,
  } = useFinancesUpdateUserInvoicesReverse({
    onSuccess: financesUserInvoices.refetch,
    enabled: false,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isFetching: isFetchingInvoiceSend } =
    useFinancesFindByIdUserInvoicesSend(
      {
        id: selectedInvoiceId,
      },
      {
        enabled: selectedInvoiceId !== undefined && sendType === 'send',
        meta: {
          useOnSuccessToast: true,
        },
      }
    );

  const { isFetching: isFetchingInvoiceDownload } =
    useFinancesFindByIdUserInvoicesDownload(
      {
        id: invoiceId || selectedInvoiceId,
      },
      {
        enabled: !!selectedInvoiceId && sendType === 'download',
        onSuccess: (res) => {
          downloadFile(res);
          setSelectedInvoiceId(null);
        },
      }
    );

  const findInvoicesExport = useFinancesFindUserInvoicesExport(
    {
      filter: query,
    },
    {
      enabled: false,
      onSuccess: downloadFile,
    }
  );

  const {
    isLoading: isCreatingBulkInvoiceSend,
    mutate: postBulkInvoiceSendRequest,
  } = useFinancesCreateUserInvoicesBulkSendInvoice({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isCreatingBulkRecordPayment,
    mutate: postBulkRecordPaymentRequest,
  } = useFinancesCreatePaymentsBulkCreate({
    onSuccess: () => {
      financesUserInvoices.refetch();
      resetToggleFormRecordPayment();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    data: adhocPaymentGateways,
    isFetching: isFetchingAdhocPaymentGateways,
  } = useLocationPaymentGatewaysFindAdhoc(
    {
      locationId: toggleFormRequestPayment?.data?.locationId,
      isActive: 1,
    },
    {
      enabled: !!toggleFormRequestPayment?.data?.locationId,
    }
  );

  const {
    isLoading: isCreatingRequestPayment,
    mutate: postRequestPaymentRequest,
  } = useFinancesCreateUserInvoicesSendPaymentRequest({
    onSuccess: ({ link, ...response } = {}) => {
      if (link) {
        resetToggleFormRequestPayment();
        window.open(link);
      } else {
        setToggleFormRequestPayment({
          type: 'response',
          data: response,
        });
      }
    },
  });

  const {
    isLoading: isCreatingBulkInvoiceDeleteReverse,
    mutate: postBulkInvoiceDeleteReverseRequest,
  } = useFinancesCreateUserInvoicesBulkReverse({
    onSuccess: financesUserInvoices.refetch,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingAddToDebitBatch,
    mutate: putAddToDebitBatchRequest,
  } = useFinancesUpdateUserInvoicesAddToDebitBatch({
    onSuccess: () => {
      financesUserInvoices.refetch();
      resetToggleAddToDebitBatch();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onBulkAction = ({ value }) => {
    const data = selectedData.map((item) => item.id);

    switch (value) {
      case 'send':
        if (!isCreatingBulkInvoiceSend) {
          postBulkInvoiceSendRequest({ invoiceIds: data });
        }
        break;

      case 'request':
        setToggleFormRequestPayment({
          type: 'bulk',
          data: {
            invoiceIds: data,
            locationId: query.locationId,
          },
        });
        break;

      case 'record':
        setToggleFormRecordPayment({
          data: { invoiceIds: data },
        });
        break;

      case 'deleteReverse':
        if (window.confirm(t('invoiceDeleteReverseConfirmation'))) {
          postBulkInvoiceDeleteReverseRequest({ invoiceIds: data });
        }
        break;

      default:
        break;
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'view':
        setToggleInvoice({
          type: 'view',
          data: { invoiceId: id },
        });
        break;

      case 'request':
        setToggleFormRequestPayment({
          type: 'single',
          data: {
            invoiceIds: [id],
            locationId: getInvoiceLocationId(
              invoices.find((item) => item.id === id)
            ),
          },
        });
        break;

      case 'record':
        setToggleInvoice({
          type: 'payment',
          data: { invoiceId: id },
        });
        break;

      case 'edit':
        setToggleInvoice({
          type: 'form',
          data: { invoiceId: id },
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteInvoiceRequest({ id });
        }
        break;

      case 'download':
        setSelectedInvoiceId(id);
        setSendType('download');
        break;

      case 'reverse':
        if (window.confirm(t('invoiceReverseConfirmation'))) {
          putInvoiceReverseRequest({ id });
        }
        break;

      case 'send':
        setSelectedInvoiceId(id);
        setSendType('send');
        break;

      case 'addToDebitBatch':
        setToggleAddToDebitBatch({
          data: {
            invoiceId: id,
            locationId: getInvoiceLocationId(
              invoices.find((item) => item.id === id)
            ),
          },
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (invoiceId) {
      setToggleInvoice({
        type: 'view',
        data: { invoiceId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  return (
    <>
      {toggleAddToDebitBatch.isVisible && (
        <Modal
          isSidebar
          title={t('addToDebitBatch')}
          onClose={resetToggleAddToDebitBatch}
        >
          <FormAddToBatch
            {...toggleAddToDebitBatch.data}
            isLoading={isUpdatingAddToDebitBatch}
            onSubmit={(values) => putAddToDebitBatchRequest(values)}
          />
        </Modal>
      )}

      {toggleFormRecordPayment.isVisible && (
        <Modal
          isSidebar
          title={t('recordPayment')}
          onClose={resetToggleFormRecordPayment}
        >
          <FormRecordPayment
            initialValues={toggleFormRecordPayment.data}
            isLoading={isCreatingBulkRecordPayment}
            onSubmit={(values) => postBulkRecordPaymentRequest(values)}
          />
        </Modal>
      )}

      {toggleFormRequestPayment.isVisible && (
        <Modal
          title={t('requestProcessPayment')}
          onClose={resetToggleFormRequestPayment}
        >
          {toggleFormRequestPayment.type === 'response' ? (
            <>
              <Card
                hasBorder
                hasPaddingChildren={false}
                mb={4}
                title={t('processedPaymentsSentToGateway')}
              >
                <TableRequestPaymentResponse
                  data={toggleFormRequestPayment.data.processed}
                />
              </Card>

              <Card
                hasBorder
                hasPaddingChildren={false}
                mb={4}
                title={t('requestedPaymentsSentToMember')}
              >
                <TableRequestPaymentResponse
                  data={toggleFormRequestPayment.data.requested}
                />
              </Card>

              <Card
                hasBorder
                hasPaddingChildren={false}
                mb={4}
                title={t('failed')}
              >
                <TableRequestPaymentResponse
                  isFailed
                  data={toggleFormRequestPayment.data.failed}
                />
              </Card>
            </>
          ) : (
            <FormRequestPayment
              adhocPaymentGateways={adhocPaymentGateways || []}
              initialValues={toggleFormRequestPayment?.data}
              isFetchingAdhocPaymentGateways={isFetchingAdhocPaymentGateways}
              isLoading={isCreatingRequestPayment}
              isSingle={toggleFormRequestPayment.type === 'single'}
              onSubmit={(values) => postRequestPaymentRequest(values)}
            />
          )}
        </Modal>
      )}

      {toggleInvoice.isVisible && (
        <AccountsInvoice
          initialView={toggleInvoice.type}
          invoiceId={toggleInvoice.data?.invoiceId}
          onClose={() => {
            // financesUserInvoices.refetch();
            resetToggleInvoice();
          }}
        />
      )}

      <Container
        appBarProps={{
          title: t('invoices'),
          breadcrumbs: [t('accounts')],
          children: (
            <Box ml='auto'>
              <Box isFlex>
                <Button
                  hasBorder
                  mr={2}
                  text={t('csvExport')}
                  onClick={findInvoicesExport.refetch}
                />

                <Button
                  text={t('createNew')}
                  onClick={() => setToggleInvoice({ type: 'form' })}
                />
              </Box>
            </Box>
          ),
        }}
        isLoading={
          financesUserInvoices.isFetching ||
          isDeletingInvoice ||
          isUpdatingInvoiceReverse ||
          isFetchingInvoiceSend ||
          isFetchingInvoiceDownload ||
          findInvoicesExport.isFetching ||
          isCreatingBulkInvoiceSend ||
          isCreatingBulkInvoiceDeleteReverse ||
          isUpdatingAddToDebitBatch
        }
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptionsAll}
          onResetPaging={resetPaging}
          onSubmit={(values) =>
            setQuery((prev) => ({
              ...values,
              sort: prev.sort,
            }))
          }
        />

        <Divider pb={4} />

        <BulkActions
          data={selectedData}
          mb={4}
          options={[
            {
              icon: 'send',
              title: t('sendInvoice'),
              value: 'send',
            },
            ...(query.locationId
              ? [
                  {
                    icon: 'payment',
                    title: t('requestProcessPayment'),
                    value: 'request',
                  },
                ]
              : []),
            {
              icon: 'receipt',
              title: t('recordPayment'),
              value: 'record',
            },
            {
              icon: 'delete',
              title: t('deleteReverseInvoice'),
              value: 'deleteReverse',
              color: 'danger',
            },
          ]}
          onClick={onBulkAction}
        />

        <TableInvoices
          data={financesUserInvoices?.isFetching ? [] : invoices}
          isLoading={financesUserInvoices.isFetching}
          paging={financesUserInvoices?.data?.paging}
          setPaging={setPaging}
          setSelected={setInvoices}
          onClickAction={onClickAction}
          onClickRow={({ original }) =>
            setToggleInvoice({
              type: 'view',
              data: { invoiceId: original.id },
            })
          }
          onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
        />
      </Container>
    </>
  );
};
