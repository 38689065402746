import {
  Box,
  Card,
  Image,
  ProcessingSuspense,
  SendComm,
  Text,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonth,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ title, noDataText, data, isLoading }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const renderItems = ({ items }) => {
    if (!items) return null;

    return (
      <Box className='hide-scrollbar' height={50} overflowY='scroll' p={4}>
        {items.length ? (
          items.map((item, index) => (
            <Box
              isFlex
              alignItems='center'
              key={item.userId}
              mt={index > 0 ? 3 : 0}
            >
              <Image
                hasRadius
                usePlaceholderUser
                mr={2}
                size={9}
                src={item.image}
              />

              <Box>
                <Text
                  isBold
                  onClick={
                    canSetMemberModal({
                      userTenant: item?.user?.userTenant,
                      // NOTE: I know this isn't elegant ... but depending on the endpoint it is returned differently.
                      userTenantTypeId: item?.userTenantTypeId,
                      hasAccess,
                    })
                      ? () =>
                          setMemberModal({
                            userTenantId:
                              // NOTE: I know this isn't elegant ... but depending on the endpoint it is returned differently.
                              item?.user?.userTenant?.id ||
                              item?.tenantUserId ||
                              item.userTenantId,
                          })
                      : null
                  }
                >
                  {getFullName(item)}
                </Text>

                {(!!item.endDate || !!item.lastAttended) && (
                  <Text color='grey1' variant='caption'>
                    {`${
                      item.endDate
                        ? `${t('expires')}: `
                        : `${t('lastAttended')}: `
                    }${getDateReadableDayMonth({
                      date: item.endDate || item.lastAttended,
                    })}`}
                  </Text>
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Text color='grey2'>{noDataText}</Text>
        )}
      </Box>
    );
  };

  const sendCommMembers = useMemo(() => {
    return data?.[`${activeTabIndex + 1}`]?.map((user) => ({
      id: user?.id || user?.userId,
      name: user?.name,
      surname: user?.surname,
    }));
  }, [data, activeTabIndex]);

  return (
    <Card
      boxTitleContainerRenderRight={() => (
        <SendComm isIcon members={!isLoading && sendCommMembers} ml='auto' />
      )}
      boxToggleTabsContainerProps={{ pb: 0 }}
      title={title}
      toggleTabsProps={
        isLoading
          ? undefined
          : {
              onChangeTab: ({ index }) => setActiveTabIndex(index),
              options: [
                {
                  label: `1 ${t('week')}`,
                  children: () => renderItems({ items: data['1'] }),
                },
                {
                  label: `2 ${t('weeks')}`,
                  children: () => renderItems({ items: data['2'] }),
                },
                {
                  label: `3 ${t('weeks')}`,
                  children: () => renderItems({ items: data['3'] }),
                },
                {
                  label: `4 ${t('weeks')}`,
                  children: () => renderItems({ items: data['4'] }),
                },
              ],
            }
      }
    >
      {isLoading && (
        <ProcessingSuspense
          items={[
            { width: '40%' },
            { width: '60%', mb: 4 },
            { width: '40%' },
            { width: '60%', mb: 4 },
          ]}
        />
      )}
    </Card>
  );
};
