import { Formik } from 'formik';
import { Box, Button, Col, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ type, affiliates, initialValues, isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        action: initialValues?.action || 'link',
        affiliateId: initialValues?.affiliate,
      }}
      validationSchema={Yup.object().shape({
        action: Yup.string(),
        affiliateId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Field
              isSelect
              helper='Please choose which affiliates should be linked to the relevant tenant(s).'
              label='Affiliate'
              name='affiliateId'
              options={affiliates?.map((item) => ({
                value: item?.id,
                label: item?.name,
              }))}
            />
            <br />
            {type === 'update' ? (
              <Field
                isSelect
                helper='Please choose whether to assign or unassign the selected Affiliate to the selected facilities.'
                label='Action'
                name='action'
                options={[
                  {
                    label: 'Assign',
                    value: 'link',
                  },
                  {
                    label: 'Unassign',
                    value: 'unlink',
                  },
                ]}
              />
            ) : null}
          </Box>

          <Col>
            <Button isLoading={isLoading} text={t('submit')} type='submit' />
          </Col>
        </form>
      )}
    </Formik>
  );
};
