import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useLeaderboardFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['leaderboard', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/leaderboard`,
        params: {
          filter: {
            locationId: filter.locationId,
            exerciseId: filter.exerciseId,
            regionId: filter.regionId,
            genderId: filter.genderId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useLeaderboardDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/leaderboard/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leaderboard'] });

      options?.onSuccess?.(...res);
    },
  });
};
