import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ThemeContext } from 'styled-components';

import Box from '../box';
import Card from '../card';
import Chip from '../chip';
import ProcessingSpinner from '../processing/spinner';
import Text from '../text';
import Tooltip from '../tooltip';

const colors = [
  '#10b981',
  '#3b82f6',
  '#8b5cf6',
  '#f97316',
  '#f43f5e',
  '#84cc16',
  '#06b6d4',
  '#a855f7',
];

const Chart = ({
  data = [],
  isBar,
  isCurrency,
  isLine,
  isLoading,
  isPie,
  isShort,
  isVisibleLegend = true,
  visibleKeysCount = 6,
  ...props
}) => {
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const { toCurrency } = useActiveUserTenant();

  const [visibleKeys, setVisibleKeys] = useState([]);

  const hasData = data?.length > 0;
  const dataKeys = hasData
    ? isPie
      ? data.map((item) => item.name)
      : Object.keys(data[0]).filter((key) => key !== 'name')
    : undefined;

  const height = 400 - (isShort ? 100 : 0) - (!isVisibleLegend ? 8 : 0);

  const responsiveContainerProps = {
    height,
    width: '100%',
  };

  const cartesianGridProps = {
    horizontal: true,
    stroke: theme.colors.grey4,
    strokeDasharray: '3 3',
    vertical: false,
  };

  const xAxisProps = {
    axisLine: false,
    dataKey: 'name',
    minTickGap: 5,
    style: { fontSize: '12px', fill: theme.colors.grey1 },
    tick: { transform: 'translate(0, 6)' },
    tickLine: false,
  };

  const yAxisProps = {
    axisLine: false,
    style: { fontSize: '12px', fill: theme.colors.grey1 },
    tick: { transform: 'translate(-3, 0)' },
    tickLine: false,
    type: 'number',
    ...(isCurrency && {
      tickFormatter: (value) => toCurrency({ value, isShorthand: true }),
    }),
  };

  const tooltipProps = {
    contentStyle: {
      borderRadius: '4px',
      color: theme.colors.font,
      boxShadow: `1px 2px 12px 0px ${
        theme.isDark ? theme.colors.dark50 : theme.colors.dark10
      }`,
      background: theme.colors.grey6,
      borderColor: theme.colors.grey4,
    },
    cursor: {
      stroke: theme.colors.grey3,
      ...(isLine && { strokeWidth: 1 }),
      ...(isBar && { opacity: '0.1' }),
    },
    isAnimationActive: false,
    itemStyle: {
      fontSize: '12px',
      lineHeight: '12px',
    },
    labelStyle: { marginBottom: '5px' },
    position: { y: 0 },
    wrapperStyle: { outline: 'none' },
    ...(isCurrency && { formatter: (value) => toCurrency({ value }) }),
  };

  const legendProps = {
    align: 'right',
    verticalAlign: 'top',
  };

  const getCommonItemProps = ({ key }) => ({
    dataKey: key,
    key,
    name: key,
    type: 'linear',
  });

  const getItemColor = ({ index }) => colors[index] || colors[index - 8];

  const parsedData =
    hasData && isPie
      ? data
          .filter((item) => visibleKeys.includes(item.name))
          .map((item, index) => ({
            ...item,
            fill: getItemColor({ index }),
          }))
      : hasData
      ? dataKeys.filter((key) => visibleKeys.includes(key))
      : [];

  useEffect(() => {
    if (hasData) {
      setVisibleKeys(dataKeys.slice(0, visibleKeysCount));
    } else {
      setVisibleKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, data]);

  const canRenderGraph = !isLoading && hasData;

  const renderLegend = ({ payload }) => (
    <Box
      hasRadius
      isFlex
      flexWrap='wrap'
      justifyContent='flex-end'
      minHeight={10}
      pb={2}
    >
      {payload.map((entry, index) => (
        <Text
          as='span'
          color={theme.colors.grey1}
          key={`${index.toString()}`}
          ml={2}
          textAlign='right'
          variant='caption'
        >
          <Text as='span' color={entry.color} variant='subheading'>
            {`● `}
          </Text>

          {entry.value}
        </Text>
      ))}
    </Box>
  );

  return (
    <Card hasBorder {...props}>
      <Box mt={!isVisibleLegend && '8px'}>
        {dataKeys && dataKeys.length > visibleKeysCount && (
          <Box isFlex justifyContent='flex-end'>
            <Tooltip
              hasSelectAll
              isSelect
              options={dataKeys.map((item) => ({
                label: item,
                value: item,
                isSelected: visibleKeys.includes(item),
                labelProps: { variant: 'caption' },
              }))}
              placement='bottom'
              onClick={({ value, selectAll, deselectAll }) =>
                setVisibleKeys((prev) => {
                  if (selectAll) {
                    return dataKeys;
                  }

                  if (deselectAll) {
                    return [];
                  }

                  if (prev.includes(value)) {
                    return prev.filter((item) => item !== value);
                  }

                  return [...prev, value];
                })
              }
            >
              <Chip
                border={[1]}
                borderColor='grey4'
                color='primary'
                icon='visibility'
                title='Set Visible Data'
              />
            </Tooltip>
          </Box>
        )}

        {(isLoading || !canRenderGraph) && (
          <Box
            isFlex
            alignItems='center'
            height={height}
            justifyContent='center'
          >
            {isLoading ? (
              <ProcessingSpinner />
            ) : (
              <Text color='grey1' variant='caption'>
                {t('noData')}
              </Text>
            )}
          </Box>
        )}

        {canRenderGraph && isLine && (
          <ResponsiveContainer {...responsiveContainerProps}>
            <LineChart data={data}>
              <CartesianGrid {...cartesianGridProps} />
              <XAxis {...xAxisProps} />
              <YAxis {...yAxisProps} />
              <RechartsTooltip {...tooltipProps} />
              {isVisibleLegend && (
                <Legend {...legendProps} content={renderLegend} />
              )}

              {parsedData.map((key, index) => (
                <Line
                  {...getCommonItemProps({ key })}
                  dot={false}
                  stroke={getItemColor({ index })}
                  strokeWidth={2}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        )}

        {canRenderGraph && isBar && (
          <ResponsiveContainer {...responsiveContainerProps}>
            <BarChart data={data}>
              <CartesianGrid {...cartesianGridProps} />
              <XAxis {...xAxisProps} />
              <YAxis {...yAxisProps} />
              <RechartsTooltip {...tooltipProps} />
              {isVisibleLegend && (
                <Legend {...legendProps} content={renderLegend} />
              )}

              {parsedData.map((key, index) => (
                <Bar
                  {...getCommonItemProps({ key })}
                  fill={getItemColor({ index })}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        )}

        {canRenderGraph && isPie && (
          <ResponsiveContainer {...responsiveContainerProps}>
            <PieChart data={data}>
              <RechartsTooltip
                {...tooltipProps}
                itemStyle={{
                  ...tooltipProps.itemStyle,
                  color: theme.colors.font,
                }}
              />
              {isVisibleLegend && (
                <Legend {...legendProps} content={renderLegend} />
              )}

              <Pie
                cx='50%'
                cy='50%'
                data={parsedData}
                dataKey='value'
                endAngle={-270}
                nameKey='name'
                outerRadius='100%'
                paddingAngle={0}
                startAngle={90}
              >
                <LabelList />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Card>
  );
};

export default Chart;
