import { Box, Button, Container, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useTasksCreate,
  useTasksDelete,
  useTasksFind,
  useTasksUpdate,
  useTasksUpdateCompletedUncompleted,
} from 'octiv-hooks/requests/Tasks';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import FormTask from './FormTask';
import Table from './Table';

export default ({ isCompactView = false, assignedUserId = undefined }) => {
  const { t } = useTranslation();

  const {
    userId,
    selectedLocation,
    tenant: { locationOptions, locationOptionsAll },
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle({
    isVisible: false,
    type: undefined,
    data: undefined,
  });
  const [paging, setPaging] = usePaging(
    isCompactView ? { page: 1, perPage: 10 } : undefined
  );
  const [query, setQuery] = useState({
    isScheduled: undefined,
    isCompleted: false,
    locationId: selectedLocation?.id,
    assignedUserId,
  });

  const usersFindStaff = useUsersFindStaff(
    {
      isMinimalData: true,
      paging: { perPage: -1 },
    },
    {
      enabled: !isCompactView,
    }
  );

  const tasksCreate = useTasksCreate({ onSuccess: resetToggle });

  const tasksFind = useTasksFind({ filter: query, paging });

  const tasksUpdate = useTasksUpdate({ onSuccess: resetToggle });

  const tasksDelete = useTasksDelete();

  const tasksUpdateCompletedUncompleted = useTasksUpdateCompletedUncompleted();

  const handleSubmit = ({ id, ...values }) => {
    if (toggle.type === 'update') {
      tasksUpdate.mutate({ id: toggle.data.id, ...values });
    } else {
      tasksCreate.mutate(values);
    }
  };

  const onClickAction = ({ action, id }) => {
    const task = tasksFind.data.data.find((item) => item.id === id);

    switch (action) {
      case 'edit':
        setToggle({ type: 'update', data: task });
        break;

      case 'delete':
        tasksDelete.mutate({ id });
        break;

      case 'completeUncomplete':
        tasksUpdateCompletedUncompleted.mutate({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('task')} onClose={resetToggle}>
          <FormTask
            initialValues={
              toggle.data
                ? toggle.data
                : {
                    location: selectedLocation,
                    assignedUsers: [{ id: userId }],
                  }
            }
            isFetchingStaff={usersFindStaff.isFetching}
            isLoading={tasksUpdate.isLoading || tasksCreate.isLoading}
            locationOptions={locationOptions}
            staff={usersFindStaff.data?.data || []}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}

      {isCompactView ? (
        <Table
          data={tasksFind.data?.data}
          isCompactView={isCompactView}
          isLoading={tasksFind.isFetching}
          paging={tasksFind.data?.paging}
          query={query}
          setQuery={setQuery}
          onClickAction={onClickAction}
        />
      ) : (
        <Container
          appBarProps={{
            title: t('manageTasks'),
            breadcrumbs: [t('tasks')],
            children: (
              <Box ml='auto'>
                <Button
                  text={t('createNew')}
                  onClick={() => setToggle({ type: 'create' })}
                />
              </Box>
            ),
          }}
          isLoading={
            tasksFind.isFetching ||
            tasksUpdate.isLoading ||
            tasksCreate.isLoading ||
            tasksDelete.isLoading ||
            tasksUpdateCompletedUncompleted.isLoading
          }
        >
          <FormQuery
            initialValues={query}
            isFetchingStaff={usersFindStaff.isFetching}
            locationOptions={locationOptionsAll}
            staff={usersFindStaff.data?.data || []}
            onSubmit={setQuery}
          />

          <Box height={4} />

          <Table
            data={tasksFind.data?.data || []}
            isLoading={tasksFind.isFetching}
            paging={tasksFind.data?.paging}
            query={query}
            setPaging={setPaging}
            onClickAction={onClickAction}
          />
        </Container>
      )}
    </>
  );
};
