import { ProcessingSuspense } from 'octiv-components';
import { useCurrenciesFind } from 'octiv-hooks/requests/Currencies';
import { useRegionsFind } from 'octiv-hooks/requests/Regions';
import {
  useTenantsCreate,
  useTenantsFindById,
  useTenantsUpdate,
} from 'octiv-hooks/requests/Tenants';
import React from 'react';

import FormTenant from './FormTenant';

export default ({ tenantId, onSuccessCreate }) => {
  const isCreate = !tenantId;

  const {
    data: tenant,
    isFetching: isFetchingTenant,
    refetch: getTenantRequest,
  } = useTenantsFindById(
    { id: tenantId },
    {
      enabled: !isCreate,
    }
  );

  const { isLoading: isCreatingTenant, mutate: postTenantRequest } =
    useTenantsCreate({
      onSuccess: onSuccessCreate,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingTenant, mutate: putTenantRequest } =
    useTenantsUpdate({
      onSuccess: ({ id }) => getTenantRequest({ id }),
    });

  const { data: currencies, isFetching: isFetchingCurrencies } =
    useCurrenciesFind({ filter: { perPage: -1 } });

  const { data: regions, isFetching: isFetchingRegions } = useRegionsFind({
    filter: { isActive: true },
    paging: { perPage: -1 },
    include: 'timezones,currencies',
  });

  const onPutPostRequest = (values) => {
    if (isCreate) {
      postTenantRequest(values);
    } else {
      putTenantRequest({ id: tenantId, ...values });
    }
  };

  return isFetchingTenant && !tenant?.name ? (
    <ProcessingSuspense />
  ) : (
    <FormTenant
      currencies={currencies?.data || []}
      initialValues={tenant}
      isCreate={isCreate}
      isFetchingCurrencies={isFetchingCurrencies}
      isFetchingRegions={isFetchingRegions}
      isLoading={isCreatingTenant || isUpdatingTenant}
      regions={regions?.data || []}
      onSubmit={onPutPostRequest}
    />
  );
};
