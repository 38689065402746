import { useCurrenciesFind } from 'octiv-hooks/requests/Currencies';
import {
  useSettingsFindTenantFinances,
  useSettingsUpdateTenantFinances,
} from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const currenciesFind = useCurrenciesFind({
    filter: { perPage: -1 },
  });

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useSettingsFindTenantFinances();

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateTenantFinances({
      enabled: false,
      onSuccess: getSettingsRequest,
    });

  const handleSubmit = (values) => {
    // TODO: only call this when the user presses the "Update" button
    putSettingsRequest(values);
  };

  return (
    <Setting
      isLoading={currenciesFind.isFetching || isFetchingSettings}
      title={t('tenant')}
    >
      <Form
        currencies={currenciesFind?.data?.data || []}
        initialValues={settings}
        isLoading={isUpdatingSettings}
        onSubmit={handleSubmit}
      />
    </Setting>
  );
};
