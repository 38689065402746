import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useProgrammesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, include = undefined } = input || {};

  return useQuery(
    ['programmes', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/programmes`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            search: filter.search,
            isActive: filter.isActive,
            packageId: filter.packageId,
            isGlobal: filter.isGlobal,
            // affiliateId: filter.affiliateId,
          },
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useProgrammesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/programmes`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          description: data.description,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['programmes'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useProgrammesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/programmes/${id}`,
        data: {
          name: data.name,
          description: data.description,
          isActive: data.isActive,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['programmes'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useProgrammesUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/programmes/${id}/activate-deactivate`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['programmes'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useProgrammesFindMarketplace = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['programmes', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/programmes/marketplace`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            search: filter.search,
            isActive: filter.isActive,
            packageId: filter.packageId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useProgrammesCreateMarketplace = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/programmes/marketplace`,
        data: {
          tenantId: data.tenantId,
          programmeId: data.programmeId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['programmes'] });

      options?.onSuccess?.(...res);
    },
  });
};
