import { Formik } from 'formik';
import moment from 'moment';
import { Box, Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const HealthProvidersFormDetails = ({ initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        healthProviderId: 1,
        name: initialValues.name || undefined,
        surname: initialValues.surname || undefined,
        dateOfBirth: initialValues.dateOfBirth || undefined,
        idNumber: initialValues.idNumber || undefined,
        hasConsented:
          typeof initialValues.hasConsented === typeof true
            ? initialValues.hasConsented
            : false,
        isCheckIn: true,
      }}
      validationSchema={Yup.object().shape({
        healthProviderId: Yup.string(),
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        dateOfBirth: Yup.date()
          .min(moment('1900-01-01').toDate(), t('invalidDate'))
          .max(moment().toDate(), t('invalidDate'))
          .required(t('required')),
        idNumber: Yup.string().required(t('required')),
        hasConsented: Yup.bool()
          .oneOf([true], t('consent'))
          .required(t('required')),
        isCheckIn: Yup.bool(),
      })}
    >
      {({ handleSubmit, setFieldValue, submitForm }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field label={t('firstName')} name='name' />
            </Col>

            <Col md={6}>
              <Field label={t('surname')} name='surname' />
            </Col>

            <Col md={6}>
              <Field
                isDate
                label={t('dateOfBirth')}
                maxDate={moment()}
                minDate={moment('1900-01-01')}
                name='dateOfBirth'
              />
            </Col>

            <Col md={6}>
              <Field label='RSA ID/Passport Number' name='idNumber' />
            </Col>

            <Col>
              <Field
                isCheckbox
                name='hasConsented'
                options={[
                  {
                    label:
                      'I consent to sharing this information with Discovery Vitality to award Vitality points',
                    value: true,
                  },
                ]}
              />
            </Col>

            <Col>
              <Box isFlex justifyContent='center'>
                <Button
                  mr={2}
                  text='Check-In'
                  onClick={() => {
                    setFieldValue('isCheckIn', true);
                    submitForm();
                  }}
                />

                <Button
                  ml={2}
                  text='Check Out'
                  onClick={() => {
                    setFieldValue('isCheckIn', false);
                    submitForm();
                  }}
                />
              </Box>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default HealthProvidersFormDetails;
