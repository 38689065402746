import { Modal, ProcessingSuspense } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useLocationsFindByIdExportParameters,
  useLocationsUpdateExportParameters,
} from 'octiv-hooks/requests/Locations';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();

  const { data: settings, isFetching: isFetchingSettings } =
    useLocationsFindByIdExportParameters(
      { id: toggle.data?.id },
      {
        enabled: toggle.data?.id !== undefined,
      }
    );

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useLocationsUpdateExportParameters({
      onSuccess: resetToggle,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const handleSubmit = (values) =>
    putSettingsRequest({ id: toggle.data.id, ...values });

  return (
    <Setting title={t('debitBatchParameters')}>
      {toggle.isVisible && (
        <Modal
          isSidebar
          title={t('debitBatchParameters')}
          onClose={resetToggle}
        >
          {isFetchingSettings ? (
            <ProcessingSuspense />
          ) : (
            <Form
              initialValues={settings || {}}
              isLoading={isUpdatingSettings}
              onSubmit={handleSubmit}
            />
          )}
        </Modal>
      )}

      <Table
        hasAlternateBackground
        data={locations}
        onClickAction={setToggle}
      />
    </Setting>
  );
};
