import { Changelog, UserProfile, UserTenants } from 'octiv-containers';
import React from 'react';

import { getStacksPublic } from './Public';

export const getStacksUser = ({ onLoginPath }) => {
  const onRedirectPath = '/user/accounts';

  return [
    {
      options: {
        isVisibleInNavigation: false,
      },
      screens: [
        {
          name: 'UserProfile',
          path: '/user/profile',
          element: <UserProfile />,
        },
        {
          name: 'UserTenants',
          path: '/user/accounts',
          element: <UserTenants />,
        },
        {
          name: 'Changelog',
          path: '/changelog',
          element: <Changelog />,
        },
      ],
    },

    {
      options: {
        isVisibleInNavigation: false,
        isVisibleNavigation: false,
      },
      screens: [
        {
          name: 'Changelog',
          path: '/changelog-hidden-navigation',
          element: <Changelog />,
        },
      ],
    },

    ...getStacksPublic({ onRedirectPath, onLoginPath }),
  ];
};
