import { Formik } from 'formik';
import { Button, Col, Field, Row, Step } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  getInitialValues as getInitialValuesBilling,
  getValidationSchema as getValidationSchemaBilling,
  renderFormFields as renderFormFieldsBilling,
} from '../../userMember/billing/FormPaymentDetails';
import {
  getInitialValues as getInitialValuesProfile,
  getValidationSchema as getValidationSchemaProfile,
  renderFormFields as renderFormFieldsProfile,
} from '../../userMember/FormUserTenant';

export default ({
  banks,
  discounts,
  isFetchingBanks,
  isFetchingDiscounts,
  isFetchingPackages,
  isFetchingProgrammes,
  isFetchingStaff,
  isLoading,
  locationOptions,
  setQuery,
  packages,
  programmes,
  staff,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    tenant: { isGoCardless, isSepa },
  } = useActiveUserTenant();

  return (
    <Formik
      {...props}
      initialValues={{
        packageId: undefined,
        contractDetails: {
          useOctiv: false,
          startDate: undefined,
          endDate: undefined,
          document: undefined,
        },
        ...getInitialValuesProfile(),
        ...getInitialValuesBilling(),
      }}
      validationSchema={Yup.object().shape({
        packageId: Yup.string().required(t('required')),
        contractDetails: Yup.object().shape({
          useOctiv: Yup.bool().required(t('required')),
          startDate: Yup.string().when('useOctiv', {
            is: (value) => value === true,
            then: Yup.string().required(t('required')),
          }),
          endDate: Yup.string(),
          document: Yup.string(),
        }),
        ...getValidationSchemaProfile({ t }),
        ...getValidationSchemaBilling({
          t,
          isGoCardless,
          isSepa,
        }),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        dirty,
        values: {
          packageId,
          memberDetails: { locationId },
          discountDetails: { discountId },
          paymentDetails: {
            debitStatusId,
            invoicingType,
            upfrontPaymentPeriodType,
          },
          contractDetails: { useOctiv },
        },
      }) => (
        <form onSubmit={handleSubmit}>
          <Step
            boxContainerProps={{ p: 0, mb: 4 }}
            title={t('membershipDetails')}
            value='1'
          >
            <Row>
              <Col lg={3} md={6}>
                <Field
                  isSelect
                  doFinally={(newLocationId) =>
                    setQuery({ packagesLocationId: newLocationId })
                  }
                  label={t('primaryLocation')}
                  name='memberDetails.locationId'
                  options={locationOptions}
                />
              </Col>

              {!!locationId && (
                <Col lg={3} md={6}>
                  <Field
                    isSelect
                    doFinally={(newPackageId) =>
                      setQuery({ programmesPackageId: newPackageId })
                    }
                    isLoading={isFetchingPackages}
                    label={t('package')}
                    name='packageId'
                    options={packages.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col>
                <Field
                  isCheckbox
                  boxContainerProps={{ mt: 2 }}
                  name='contractDetails.useOctiv'
                  options={[
                    {
                      label: t('useOctivForContractManagement'),
                      value: true,
                    },
                  ]}
                />
              </Col>

              {useOctiv && (
                <>
                  <Col lg={3} md={6}>
                    <Field
                      isDate
                      label={t('contractStartDate')}
                      name='contractDetails.startDate'
                    />
                  </Col>

                  <Col lg={3} md={6}>
                    <Field
                      isDate
                      label={t('contractEndDate')}
                      name='contractDetails.endDate'
                    />
                  </Col>

                  <Col>
                    <Field
                      isDrop
                      label={t('contractDocument')}
                      name='contractDetails.document'
                    />
                  </Col>
                </>
              )}
            </Row>
          </Step>

          {!!packageId && (
            <>
              <Step
                boxContainerProps={{ p: 0, mb: 4 }}
                title={t('profileDetails')}
                value='2'
              >
                {renderFormFieldsProfile({
                  t,
                  isDisplayLocation: false,
                  isFetchingProgrammes,
                  programmes,
                  isFetchingStaff,
                  staff,
                })}
              </Step>

              <Step
                boxContainerProps={{ p: 0, mb: 4 }}
                mb={2}
                title={t('billingDetails')}
                value='3'
              >
                {renderFormFieldsBilling({
                  t,
                  banks,
                  discountId,
                  discounts,
                  hasProRatedAmount: true,
                  invoicingType,
                  isFetchingBanks,
                  isFetchingDiscounts,
                  isGoCardless,
                  isSepa,
                  setFieldTouched,
                  setFieldValue,
                  upfrontPaymentPeriodType,
                  debitStatusId,
                })}
              </Step>

              <Button
                isDisbled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </>
          )}
        </form>
      )}
    </Formik>
  );
};
