import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        bankNominatedAccount: initialValues.bankNominatedAccount || undefined,
        bankUserCode: initialValues.bankUserCode || undefined,
        bankUserName: initialValues.bankUserName || undefined,
      }}
      validationSchema={Yup.object().shape({
        bankNominatedAccount: Yup.string().required(t('required')),
        bankUserCode: Yup.string().required(t('required')),
        bankUserName: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                label={t('bankNominatedAccount')}
                name='bankNominatedAccount'
              />
            </Col>

            <Col>
              <Field label={t('bankUserCode')} name='bankUserCode' />
            </Col>

            <Col>
              <Field label={t('bankUserName')} name='bankUserName' />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
