import moment from 'moment';
import { Box, Button, Container, Divider } from 'octiv-components';
import { AccountsInvoice } from 'octiv-containers';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useStoreFindReportsExportSales,
  useStoreFindReportsSales,
  useStoreUpdateSalesCancel,
} from 'octiv-hooks/requests/Store';
import { downloadFile, getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [paging, setPaging] = usePaging();

  const {
    selectedLocation,
    tenant: { locationOptions },
    tenantId,
  } = useActiveUserTenant();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    location: selectedLocation.id,
    status: undefined,
    startDate: getDateYearMonthDay({ date: moment().startOf('month') }),
    endDate: getDateYearMonthDay({ date: moment().endOf('month') }),
    order: 'DESC',
    sort: 'purchaser.name',
  });

  const storeFindReportsSales = useStoreFindReportsSales({
    filter: {
      tenantId,
      locationId: query?.location,
      startDate: query?.startDate,
      endDate: query?.endDate,
      status: query?.status,
    },
    paging,
  });

  const storeUpdateSalesCancel = useStoreUpdateSalesCancel();

  const storeFindReportsExportSales = useStoreFindReportsExportSales(
    {
      filter: query,
    },
    {
      enabled: false,
    }
  );

  const { refetch: getSalesExportRequest } = useStoreFindReportsExportSales(
    {
      filter: query,
    },
    {
      enabled: false,
      onSuccess: (response) => downloadFile(response.file),
    }
  );

  const onClickAction = ({ action, id, invoiceId }) => {
    switch (action) {
      case 'view':
        setToggle({
          type: 'view',
          data: { invoiceId },
        });
        break;

      case 'update':
        setToggle({
          type: 'form',
          data: { invoiceId },
        });
        break;

      case 'cancel':
        if (window.confirm(t('areYouSureProceed'))) {
          storeUpdateSalesCancel.mutate({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <AccountsInvoice
          initialView={toggle.type}
          invoiceId={toggle.data.invoiceId}
          onClose={() => {
            resetToggle();
          }}
        />
      )}

      <Container
        appBarProps={{
          title: t('sales'),
          breadcrumbs: [t('store')],
          children: (
            <Box ml='auto'>
              {/* TODO: call the GET request with query here */}
              <Button text={t('csvExport')} onClick={getSalesExportRequest} />
            </Box>
          ),
        }}
        isLoading={
          storeFindReportsSales.isFetching ||
          storeUpdateSalesCancel.isLoading ||
          storeFindReportsExportSales.isFetching
        }
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <Table
          data={storeFindReportsSales?.data?.data || []}
          isLoading={storeFindReportsSales?.isFetching}
          paging={{ ...storeFindReportsSales?.data?.paging, perPage: 25 }}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
