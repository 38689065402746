import React from 'react';

export const AddBookingsModalInitialState = {
  eventId: undefined,
  onClose: undefined,
  setAddBookingsModal: () => {},
  resetAddBookingsModal: () => {},
};

export const AddBookingsModalContext = React.createContext(
  AddBookingsModalInitialState
);

export const useAddBookingsModal = () => {
  const addBookingsModal = React.useContext(AddBookingsModalContext);

  return addBookingsModal;
};

export const AddBookingsModalContextProvider = ({ children }) => {
  const [addBookingsModalContext, setAddBookingsModalContext] = React.useState(
    AddBookingsModalInitialState
  );

  React.useEffect(() => {
    setAddBookingsModalContext({
      ...AddBookingsModalInitialState,
      setAddBookingsModal: (values) =>
        setAddBookingsModalContext((prev) => ({
          ...prev,
          eventId: values.eventId,
          onClose: values.onClose || prev.onClose,
        })),
      resetAddBookingsModal: () =>
        setAddBookingsModalContext((prev) => ({
          ...AddBookingsModalInitialState,
          setAddBookingsModal: prev.setAddBookingsModal,
          resetAddBookingsModal: prev.resetAddBookingsModal,
        })),
    });
  }, []);

  return (
    <AddBookingsModalContext.Provider value={addBookingsModalContext}>
      {children}
    </AddBookingsModalContext.Provider>
  );
};
