import { useMediaQuery } from 'octiv-hooks';
import { determineScale } from 'octiv-utilities/Functions';
import React from 'react';

import { Box, Tooltip } from '..';
import { StyledIcon } from './Styled';

const Icon = ({
  boxContainerProps = {},
  color = 'grey2',
  colorActive = 'primary',
  isActive = false,
  name,
  scale = 'regular',
  stopPropagation,
  tooltip,
  tooltipProps = {},
  onClick,
  ...props
}) => {
  const { xsDown } = useMediaQuery();
  const size = `${determineScale({ scale, scales: [18, 24, 36, 48] })}px`;

  const handleClick = (e) => {
    if (stopPropagation) e.stopPropagation();
    onClick();
  };

  const renderIcon = () => (
    <Box
      isFlex
      alignItems='center'
      justifyContent='center'
      onClick={onClick ? handleClick : undefined}
      {...props}
    >
      <Box
        {...boxContainerProps}
        maxHeight={size}
        maxWidth={size}
        overflow='hidden'
      >
        <StyledIcon
          className='material-icons-round'
          color={isActive ? colorActive || 'primary' : color}
          fontSize={size}
        >
          {name}
        </StyledIcon>
      </Box>
    </Box>
  );

  if ((tooltip && !xsDown) || (tooltip && xsDown && !onClick)) {
    return (
      <Tooltip
        delayShow={300}
        minWidth={null}
        placement='top'
        text={tooltip}
        trigger='hover'
        {...tooltipProps}
      >
        {renderIcon()}
      </Tooltip>
    );
  }

  return renderIcon();
};

export default Icon;
