import styled from 'styled-components';

const TextInput = styled.input.attrs(({ type, autoComplete = false }) =>
  autoComplete
    ? { autoComplete: undefined }
    : type === 'password'
    ? { autoComplete: 'new-password' }
    : { autoComplete: 'off' }
)`
  appearance: none;
  border-radius: 0;
  border: 0;
  line-height: normal;
  margin: 0;
  min-width: 0;
  width: 100%;
  background-color: transparent;

  padding: ${({ theme, hasPadding = true }) =>
    hasPadding &&
    `${theme.sizes[7]} ${theme.sizes[3]} ${theme.sizes[2]} ${theme.sizes[3]}`};

  color: ${({ theme }) => theme.colors.font};

  :focus {
    outline: 0;
  }

  :invalid {
    box-shadow: none;
  }

  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.grey2};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.grey2};
  }

  ::-webkit-search-decoration {
    display: none;
  }

  ${({ type }) =>
    type === 'color' && `padding: 1.5rem 0.75rem 0.1rem 0.75rem; height: 55px;`}
`;

export default TextInput;
