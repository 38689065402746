import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useClassesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    sort = undefined,
    include = undefined,
  } = input || {};

  return useQuery(
    ['classes', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/classes`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            classTypeId: filter.classTypeId,
            instructorId: filter.instructorId,
            isSession: filter.isSession,
            isVisibleInApp: filter.isVisibleInApp,
            isActive: filter.isActive,
            isVirtual: filter.isVirtual,
            tagIds: filter.tagIds,
            sort: filter.sort,
            order: filter.order,
          },
          sort,
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useClassesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/classes`,
        data: {
          tenantId: data.tenantId || tenantId,
          isSession: data.isSession,
          name: data.name,
          description: data.description,
          locationId: data.locationId,
          instructorId: data.instructorId,
          supportingInstructorId: data.supportingInstructorId,
          isDisplayCoachName: data.isDisplayInstructorName,
          isFree: data.isFree,
          classTypeId: data.typeId,
          onceOffDate: data.onceOffDate,
          recurringDays: data.recurringDays,
          recurringStartDate: data.recurringStartDate,
          recurringEndDate: data.recurringEndDate,
          startTime: data.startTime,
          endTime: data.endTime,
          isVisibleInApp: data.isVisibleInApp,
          limit: data.limit,
          bookingThreshold: data.bookingThreshold,
          cancellationThreshold: data.cancellationThreshold,
          packageIds: data.packageIds,
          meetingUrl: data.meetingUrl,
          isVirtual: data.isVirtual,
          tagIds: data.tagIds,
          autoCancelThresholdMin: data.autoCancelThresholdMin || 0,
          minBookedMembersCount: data.minBookedMembersCount || 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classes'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['classes', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/classes/${id}`,
      }),
    options
  );
};

export const useClassesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/classes/${id}`,
        data: {
          fromDate: data.fromDate,
          isSession: data.isSession,
          name: data.name,
          description: data.description,
          locationId: data.locationId,
          instructorId: data.instructorId,
          supportingInstructorId: data.supportingInstructorId,
          isDisplayCoachName: data.isDisplayInstructorName,
          isFree: data.isFree,
          onceOffDate: data.onceOffDate,
          recurringDays: data.recurringDays,
          recurringEndDate: data.recurringEndDate || null,
          startTime: data.startTime,
          endTime: data.endTime,
          isVisibleInApp: data.isVisibleInApp,
          limit: data.limit,
          bookingThreshold: data.bookingThreshold,
          cancellationThreshold: data.cancellationThreshold,
          packageIds: data.packageIds,
          meetingUrl: data.meetingUrl,
          isVirtual: data.isVirtual,
          tagIds: data.tagIds,
          autoCancelThresholdMin: data.autoCancelThresholdMin || 0,
          minBookedMembersCount: data.minBookedMembersCount || 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classes'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'delete',
        url: `/api/classes/${id}`,
        data: { fromDate: data.fromDate },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classes'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};
