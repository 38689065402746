import { Box, Col, Icon, Row, Text } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';

export default ({ location }) => {
  const headCoaches = location?.tenant?.headCoaches;

  return (
    <Box paddingTop='1rem'>
      {headCoaches?.map((coach) => (
        <>
          <Row key={coach.id}>
            <Col md={4}>
              <Box
                alignItems='center'
                display='flex'
                flexDirection='row'
                marginRight='1rem'
              >
                <Icon marginRight='0.5rem' name='person' />
                <Text weight='700'>{getFullName({ user: coach })}</Text>
              </Box>
            </Col>

            <Col md={4}>
              <a href={`mailto:${coach?.email}`}>
                <Box
                  alignItems='center'
                  display='flex'
                  flexDirection='row'
                  marginRight='1rem'
                >
                  <Icon marginRight='0.5rem' name='email' />
                  <Text color='primary'>{coach?.email}</Text>
                </Box>
              </a>
            </Col>

            <Col md={4}>
              <a href={`tel:${coach?.mobile}`}>
                <Box
                  alignItems='center'
                  display='flex'
                  flexDirection='row'
                  marginRight='1rem'
                >
                  <Icon marginRight='0.5rem' name='phone' />
                  <Text color='primary'>{coach?.mobile}</Text>
                </Box>
              </a>
            </Col>
          </Row>
          <hr style={{ opacity: 0.25 }} />
        </>
      ))}
    </Box>
  );
};
