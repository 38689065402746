import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        isActive: Yup.number().required(t('required')),
        message: Yup.string().when('status', {
          is: (value) => value === true,
          then: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Field
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='isActive'
                options={[
                  { label: t('active'), value: 1 },
                  { label: t('inactive'), value: 0 },
                ]}
              />
            </Col>

            <Col>
              <Field
                hasAlternateBackground
                as='textarea'
                label={t('notice')}
                name='message'
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
