import { StyledBox } from 'octiv-components/box/Styled';
import styled from 'styled-components';

export const StyledButton = styled(StyledBox)`
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
