import styled from 'styled-components';
import {
  background,
  border,
  boxShadow,
  color,
  flexbox,
  layout,
  position,
  space,
} from 'styled-system';

export const StyledBox = styled('div').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![
      'height',
      'display',
      'width',
      'size',
      'pointerEvents',
      'opacity',
      'overflow',
    ].includes(prop) && defaultValidatorFn(prop),
})`
  transition: background-color 250ms ease, border-color 250ms ease,
    opacity 250ms ease, width 250ms ease;
  outline: none;

  ${({
    hasRadius,
    hasRadiusTop,
    hasRadiusTopLeft,
    hasRadiusTopRight,
    hasRadiusBottom,
    hasRadiusBottomLeft,
    hasRadiusBottomRight,
    hasRadiusLeft,
    hasRadiusRight,
    sizeRadius = 2,
    theme,
  }) =>
    hasRadius &&
    !hasRadiusTop &&
    !hasRadiusTopLeft &&
    !hasRadiusTopRight &&
    !hasRadiusBottom &&
    !hasRadiusBottomLeft &&
    !hasRadiusBottomRight &&
    !hasRadiusLeft &&
    !hasRadiusRight &&
    `border-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusTop, sizeRadius = 2, theme }) =>
    hasRadiusTop &&
    `border-top-left-radius: ${theme.sizes[sizeRadius]}; border-top-right-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusTopLeft, sizeRadius = 2, theme }) =>
    hasRadiusTopLeft && `border-top-left-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusTopRight, sizeRadius = 2, theme }) =>
    hasRadiusTopRight && `border-top-right-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusBottom, sizeRadius = 2, theme }) =>
    hasRadiusBottom &&
    `border-bottom-left-radius: ${theme.sizes[sizeRadius]}; border-bottom-right-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusBottomLeft, sizeRadius = 2, theme }) =>
    hasRadiusBottomLeft &&
    `border-bottom-left-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusBottomRight, sizeRadius = 2, theme }) =>
    hasRadiusBottomRight &&
    `border-bottom-right-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusLeft, sizeRadius = 2, theme }) =>
    hasRadiusLeft &&
    `border-bottom-left-radius: ${theme.sizes[sizeRadius]}; border-top-left-radius: ${theme.sizes[sizeRadius]}`};
  ${({ hasRadiusRight, sizeRadius = 2, theme }) =>
    hasRadiusRight &&
    `border-bottom-right-radius: ${theme.sizes[sizeRadius]}; border-top-right-radius: ${theme.sizes[sizeRadius]}`};

  ${({ isFlex }) => isFlex && 'display: flex'};
  ${({ pointerEvents }) => pointerEvents && `pointer-events: ${pointerEvents}`};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`}
  ${({ isDisabled }) => isDisabled && `cursor: not-allowed; opacity: 0.7;`}

  ${({ theme, onClick, bgHover }) =>
    (onClick || bgHover) &&
    `&:hover {
      ${onClick && 'cursor: pointer'};
      ${
        bgHover &&
        `background-color: ${
          bgHover.includes('#') ? bgHover : theme.colors[bgHover]
        }`
      }
  }`};

  ${({ canEllipsisText }) =>
    canEllipsisText &&
    `text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;`};

  ${background};
  ${border};
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${space};
  ${boxShadow};
`;
