import { Table, Tooltip } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'affiliate.name',
        Cell: ({ value }) => value,
      },
      {
        Header: t('actions'),
        Cell: ({ row: { original } }) => (
          <Tooltip
            options={[{ label: t('unassign'), value: 'unlink' }]}
            onClick={({ value }) =>
              onClickAction({ action: value, id: original.affiliate.id })
            }
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
