import { Images } from 'octiv-assets';
import { Box, Icon, Image, Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, isLoading, onClickAction, ...props }) => {
  const { t } = useTranslation();
  const iconSize = 4;

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => {
          const packageHasHealthProviders = !!original.healthProviderPrice;

          return (
            <Box alignItems='center' display='flex' flexDirection='row'>
              {packageHasHealthProviders && (
                <Image
                  alt={t('discoveryLogo')}
                  backgroundSize='contain'
                  height={iconSize}
                  mr={1}
                  src={Images.vitalityLogomark}
                  width={iconSize}
                />
              )}
              <Text>{original.name}</Text>
            </Box>
          );
        },
      },
      {
        Header: t('active'),
        accessor: 'isActive',
        Cell: ({ value }) =>
          value ? (
            <Icon
              color={'success'}
              name='check_circle'
              px={1}
              tooltip='Is Active'
            />
          ) : (
            <Icon
              color={'grey3'}
              name='check_circle'
              px={1}
              tooltip='Is Deactivated'
            />
          ),
      },
      {
        Header: t('sessions'),
        accessor: 'limit',
        Cell: ({ value }) => value,
      },
      {
        Header: t('price'),
        accessor: 'price',
        Cell: ({ value }) => value,
      },
      {
        Header: t('healthProviderPrice'),
        accessor: 'healthProviderPrice',
        Cell: ({ value }) => value,
      },
      {
        Header: t('description'),
        accessor: 'description',
        Cell: ({ value }) => value,
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      isLoading={isLoading}
    />
  );
};
