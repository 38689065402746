import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        maintenanceTitle: initialValues.maintenanceTitle || undefined,
        maintenanceBody: initialValues.maintenanceBody || undefined,
        versionWebApp: initialValues.versionWebApp || undefined,
        versionWebAppRequired: initialValues.versionWebAppRequired || undefined,
        versionAppStoreIos: initialValues.versionAppStoreIos || undefined,
        versionAppStoreIosRequired:
          initialValues.versionAppStoreIosRequired || undefined,
        versionAppStoreAndroid:
          initialValues.versionAppStoreAndroid || undefined,
        versionAppStoreAndroidRequired:
          initialValues.versionAppStoreAndroidRequired || undefined,
      }}
      validationSchema={Yup.object().shape({
        maintenanceTitle: Yup.string().nullable(),
        maintenanceBody: Yup.string().nullable(),
        versionWebApp: Yup.string().required(t('required')),
        versionWebAppRequired: Yup.string().required(t('required')),
        versionAppStoreIos: Yup.string().required(t('required')),
        versionAppStoreIosRequired: Yup.string().required(t('required')),
        versionAppStoreAndroid: Yup.string().required(t('required')),
        versionAppStoreAndroidRequired: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field
                canSetEmptyStringNull
                label='Maintenance Title'
                name='maintenanceTitle'
              />
            </Col>

            <Col md={6}>
              <Field
                canSetEmptyStringNull
                label='Maintenance Body'
                name='maintenanceBody'
              />
            </Col>

            <Col md={6}>
              <Field label='Version Web App' name='versionWebApp' />
            </Col>

            <Col md={6}>
              <Field
                label='Version Web App Required'
                name='versionWebAppRequired'
              />
            </Col>

            <Col md={6}>
              <Field label='Version App Store iOS' name='versionAppStoreIos' />
            </Col>

            <Col md={6}>
              <Field
                label='Version App Store iOS Required'
                name='versionAppStoreIosRequired'
              />
            </Col>

            <Col md={6}>
              <Field
                label='Version App Store Android'
                name='versionAppStoreAndroid'
              />
            </Col>

            <Col md={6}>
              <Field
                label='Version App Store Android Required'
                name='versionAppStoreAndroidRequired'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('update')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
