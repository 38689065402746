import { Alert } from 'octiv-components';
import { useLocationPaymentGatewaysFindGoCardlessDetails } from 'octiv-hooks/requests/LocationPaymentGateways';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const { data: settings, isFetching: isFetchingSettings } =
    useLocationPaymentGatewaysFindGoCardlessDetails();

  const hasNotOnboarded = !!settings?.find(
    (item) => item.hasOnboarded === false
  );

  return (
    <Setting title='GoCardless'>
      {hasNotOnboarded && (
        <Alert
          mb={4}
          subtitle={t('locationNotOnboardedTo', {
            paymentGateway: 'GoCardless',
          })}
          variant='danger'
        />
      )}

      <Table
        hasAlternateBackground
        data={settings || []}
        isLoading={isFetchingSettings}
      />
    </Setting>
  );
};
