import { Box, Button, Container, Modal } from 'octiv-components';
import { usePaging, useToggle } from 'octiv-hooks';
import { useExerciseCategoriesFind } from 'octiv-hooks/requests/ExerciseCategories';
import {
  useExercisesCreate,
  useExercisesFind,
  useExercisesUpdate,
} from 'octiv-hooks/requests/Exercises';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging, resetPaging] = usePaging();
  const [query, setQuery] = useState({
    isActive: 1,
    search: undefined,
    exerciseCategoryId: undefined,
  });

  const exercisesFind = useExercisesFind({
    filter: { ...query, isBenchmark: 1, type: 'global' },
    include: 'exerciseCategory',
    paging,
  });

  const exercisesCreate = useExercisesCreate({ onSuccess: resetToggle });

  const exercisesUpdate = useExercisesUpdate({ onSuccess: resetToggle });

  const exerciseCategoriesFind = useExerciseCategoriesFind({
    paging: { perPage: -1 },
  });

  const handleSubmit = ({ id, ...values }) => {
    if (toggle.type === 'update') {
      exercisesUpdate.mutate({ id: id || toggle?.data?.id, ...values });
    } else {
      exercisesCreate.mutate({ ...values, isBenchmark: 1 });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'update':
        setToggle({
          type: 'update',
          data: exercisesFind.data?.data.find((item) => item.id === id),
        });
        break;

      case 'activate':
        exercisesUpdate.mutate({ id, isActive: 1 });
        break;

      case 'deactivate':
        exercisesUpdate.mutate({ id, isActive: 0 });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title='Benchmark' onClose={resetToggle}>
          <Form
            exerciseCategories={exerciseCategoriesFind.data?.data || []}
            initialValues={toggle.data}
            isFetchingExerciseCategories={exerciseCategoriesFind.isFetching}
            isLoading={exercisesUpdate.isLoading || exercisesCreate.isLoading}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('benchmarks'),
          breadcrumbs: [t('settings')],
          children: (
            <Box ml='auto'>
              <Button
                text='New Benchmark'
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={exercisesUpdate.isLoading || exercisesCreate.isLoading}
      >
        <FormQuery
          exerciseCategories={exerciseCategoriesFind.data?.data || []}
          initialValues={query}
          isFetchingExerciseCategories={exerciseCategoriesFind.isFetching}
          onSubmit={(values) => {
            if (values?.search !== query?.search) {
              resetPaging();
            }
            setQuery(values);
          }}
        />

        <Box height={4} />

        <Table
          data={exercisesFind.data?.data}
          isLoading={exercisesFind.isFetching}
          paging={exercisesFind?.data?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onClickRow={({ original }) =>
            onClickAction({
              action: 'update',
              id: original.id,
            })
          }
        />
      </Container>
    </>
  );
};
