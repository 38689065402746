import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isFetchingStaff, locationOptions, staff, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        isScheduled: Yup.bool(),
        isCompleted: Yup.bool(),
        locationId: Yup.string(),
        assignedUserId: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Field
                canSubmitOnChange
                isHorizontal
                isRadio
                label={t('type')}
                name='isScheduled'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('scheduled'), value: true },
                  { label: t('general'), value: false },
                ]}
              />
            </Col>

            <Col lg={2} xs={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='isCompleted'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('incomplete'), value: false },
                  { label: t('complete'), value: true },
                ]}
              />
            </Col>

            <Col lg={2} xs={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={2}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingStaff}
                label={t('assignee')}
                name='assignedUserId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(staff?.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
