import { Box, Text } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import React from 'react';

const Step = ({
  badgeProps,
  boxContainerProps,
  children,
  hasBorder,
  textTitleProps,
  title,
  value,
  ...props
}) => {
  const { xsDown, mdDown } = useMediaQuery();

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  let borderProps = {};
  if (hasBorder) {
    borderProps = {
      borderColor: 'grey5',
      borderStyle: 'solid',
      borderWidth: '1px',
    };
  }

  const renderStep = () => (
    <Box isFlex alignItems='center' mb={children ? 4 : 0} {...props}>
      {value && (
        <Box
          hasRadius
          isFlex
          alignItems='center'
          bg='grey5'
          justifyContent='center'
          minHeight={9}
          minWidth={9}
          mr={2}
          {...badgeProps}
        >
          <Text>{value}</Text>
        </Box>
      )}

      {title && (
        <Text variant='subheading' {...textTitleProps}>
          {title}
        </Text>
      )}
    </Box>
  );

  if (children) {
    return (
      <Box
        hasRadius
        bg='grey6'
        p={padding}
        {...borderProps}
        {...boxContainerProps}
      >
        {renderStep()}
        {children}
      </Box>
    );
  }

  return renderStep();
};

export default Step;
