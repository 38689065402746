import { Box, ProcessingBar } from 'octiv-components';
import Setting from 'octiv-containers/settings/components/Setting';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useProgrammesCreateMarketplace,
  useProgrammesFind,
  useProgrammesFindMarketplace,
  useProgrammesUpdate,
  useProgrammesUpdateActivateDeactivate,
} from 'octiv-hooks/requests/Programmes';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const { tenantId } = useActiveUserTenant();

  const [toggle, , resetToggle] = useToggle();
  const [paging] = usePaging();

  const programmesFindMarketPlace = useProgrammesFindMarketplace({
    paging,
  });

  const programmesFind = useProgrammesFind({
    filter: { tenantId, isGlobal: 1 },
  });

  const programmesCreate = useProgrammesCreateMarketplace({
    onSuccess: () => {
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const programmesUpdate = useProgrammesUpdate({
    onSuccess: () => {
      programmesFindMarketPlace.refetch();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isUpdatingActivationSetting,
    mutate: putActivationSettingRequest,
  } = useProgrammesUpdateActivateDeactivate({
    onSuccess: programmesFindMarketPlace.refetch,
    meta: {
      useOnSuccessToast: true,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      programmesCreate.mutate(values);
    } else {
      programmesUpdate.mutate({ id: toggle.data.id, ...values });
    }
  };

  const programesCreateMkarketPlace = useProgrammesCreateMarketplace();

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'toggleActivation':
        putActivationSettingRequest({ id });
        break;

      case 'link':
        programesCreateMkarketPlace.mutate({
          programmeId: id,
          tenantId,
        });
        break;
      case 'unlink':
        programesCreateMkarketPlace.mutate({
          programmeId: id,
          tenantId,
        });
        break;
      default:
        break;
    }
  };

  if (
    programmesFindMarketPlace?.data?.data?.length === 0 ||
    programmesFindMarketPlace?.isFetching
  ) {
    return null;
  }

  return (
    <>
      {isUpdatingActivationSetting && <ProcessingBar />}

      <Setting title={t('globalProgrammes')}>
        <Box height={4} />

        <Table
          hasAlternateBackground
          activatedGlobalProgrammes={programmesFind?.data?.data || []}
          data={programmesFindMarketPlace?.data?.data || []}
          isLoading={programmesFindMarketPlace.isFetching}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
