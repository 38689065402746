import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useTrainerRatesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['trainerRates', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/coach-rates`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useTrainerRatesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/coach-rates`,
        data: {
          userId: data.userId,
          locationId: data.locationId,
          strategy: data.strategy,
          type: data.type,
          amount: data.amount,
          minMembers: data.minMembers,
          maxMembers: data.maxMembers,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['trainerRates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTrainerRatesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/coach-rates/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['trainerRates'] });

      options?.onSuccess?.(...res);
    },
  });
};
