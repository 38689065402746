import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  const workoutThresholdOptions = [];
  for (let index = 1; index < 15; index += 1) {
    workoutThresholdOptions.push({
      label: `${index} ${index > 1 ? t('daysInAdvance') : t('dayInAdvance')}`,
      value: `${index}`,
    });
  }

  return (
    <Formik
      {...props}
      initialValues={{
        workoutThreshold:
          initialValues.workoutThreshold !== undefined
            ? `${initialValues.workoutThreshold}`
            : undefined,
      }}
      validationSchema={Yup.object().shape({
        workoutThreshold: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                isSelect
                label={t('howLongInAdvanceCanViewWorkout')}
                name='workoutThreshold'
                options={[
                  {
                    label: t('theDayAfter'),
                    value: '-1',
                  },
                  {
                    label: t('onTheDay'),
                    value: '0',
                  },
                  ...workoutThresholdOptions,
                ]}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
