import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Modal,
  SendComm,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useQuery, useToggle } from 'octiv-hooks';
import { useClassBookingsFind } from 'octiv-hooks/requests/ClassBookings';
import {
  useLeadsCreate,
  useLeadsCreateGenerateInvoice,
  useLeadsDeleteMembersBulkDelete,
  useLeadsFind,
  useLeadsFindExport,
  useLeadsUpdate,
  useLeadsUpdateMembersConvert,
} from 'octiv-hooks/requests/Leads';
import {
  useUsersFindMembers,
  useUsersFindStaff,
} from 'octiv-hooks/requests/Users';
import {
  useWaiversCreateDownload,
  useWaiversFindById,
} from 'octiv-hooks/requests/Waivers';
import {
  downloadFile,
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormInvoice from './FormInvoice';
import FormLead from './FormLead';
import FormQuery from './FormQuery';
import TableBookings from './TableBookings';
import TableLeads from './TableLeads';

export default ({ isCompactView = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    selectedLocation,
    tenant: { locationOptionsAll, locationOptions },
  } = useActiveUserTenant();
  const [toggleFormLead, setToggleFormLead, resetToggleFormLead] = useToggle();
  const [toggleFormInvoice, setToggleFormInvoice, resetToggleFormInvoice] =
    useToggle();
  const [toggleBookings, setToggleBookings, resetToggleBookings] = useToggle();
  const [toggleWaiver, setToggleWaiver, resetToggleWaiver] = useToggle();
  const [paging, setPaging] = usePaging();
  const [leads, setLeads] = useState([]);
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    status: isCompactView ? 'pending' : undefined,
    type: undefined,
    search: useQuery('search') ?? undefined,
    sort: '-captured',
  });

  const [waiverId, setWaiverId] = useState(undefined);

  const usersFind = useLeadsFind(
    {
      filter: {
        ...query,
      },
      include: 'referredBy,classBookings,waiver',
      sort: query.sort,
      paging,
    },
    {
      onSuccess: (response) => setLeads(response?.data),
    }
  );

  const { data: staff, isFetching: isFetchingStaff } = useUsersFindStaff(
    {
      paging: { perPage: -1 },
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers(
    {
      filter: {
        userTenantStatusId: 2,
        isMinimalData: true,
        userTenantLocationId: query?.locationId,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: query?.locationId !== undefined,
    }
  );

  const { isLoading: isCreatingLead, mutate: postLeadRequest } = useLeadsCreate(
    {
      meta: {
        useOnSuccessToast: true,
      },
      onSuccess: () => {
        resetToggleFormLead();
      },
    }
  );

  const { isLoading: isUpdatingLead, mutate: putUserRequest } = useLeadsUpdate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      resetToggleFormLead();
    },
  });

  const deleteLeadBulk = useLeadsDeleteMembersBulkDelete();

  const { isLoading: isUpdatingLeadConvert, mutate: putLeadConvertRequest } =
    useLeadsUpdateMembersConvert({
      onSuccess: (response) => {
        navigate(`/members/manage?userTenantId=${response.userTenantId}`);
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isFetching: isFetchingLeadsExport, refetch: getLeadsExportRequest } =
    useLeadsFindExport(
      {
        filter: query,
        sort: query.sort,
        paging,
      },
      {
        enabled: false,
        onSuccess: (response) => downloadFile(response.file),
      }
    );

  const { data: leadBookings, isFetching: isFetchingLeadBookings } =
    useClassBookingsFind(
      {
        filter: {
          userId: toggleBookings.data?.user?.id,
          leadMemberId: toggleBookings.data?.id,
          status: 1,
          locationId: query?.locationId,
        },
      },
      {
        enabled: toggleBookings.data?.id !== undefined,
      }
    );

  const {
    isFetching: isCreatingLeadGenerateInvoice,
    mutate: postLeadGenerateInvoiceRequest,
  } = useLeadsCreateGenerateInvoice({
    onSuccess: resetToggleFormInvoice,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { data: waiver, isFetching: isFetchingWaiver } = useWaiversFindById(
    { id: waiverId },
    {
      enabled: waiverId !== undefined,
      onSuccess: () => setToggleWaiver({ type: 'viewWaiver' }),
      onError: resetToggleWaiver,
    }
  );

  const { isLoading: isFetchingWaiversDownload, mutate: postWaiversDownload } =
    useWaiversCreateDownload({
      onSuccess: (res) => downloadFile(res.file),
    });

  const onPutPostRequest = (values) => {
    // TODO: we need to update both the user and userTenant, i.e. 2 endpoints.
    // - some fields (e.g. name, email, surname, gender, mobilenumber) will update the user
    // - otherm fields like (e.g. status, location, referredBy,)
    if (toggleFormLead.type === 'create') {
      postLeadRequest(values);
    } else {
      putUserRequest({
        ...values,
        id: toggleFormLead.data.id,
      });
    }
  };

  const onClickAction = ({
    action,
    id,
    userTenantId,
    waiverId: waiverIdTemp,
  }) => {
    const leadUser = leads?.find((item) => item.id === id);
    const { user } = leadUser || {};

    switch (action) {
      case 'edit':
        // eslint-disable-next-line no-case-declarations
        setToggleFormLead({
          type: 'update',
          data: {
            // TODO: only extract the relevant fields for the form...
            ...leadUser,
            ...leadUser.userTenant?.leadMember,
            ...user,
            id,
          },
        });

        break;

      case 'convert':
        if (window.confirm(t('areYouSureProceed'))) {
          putLeadConvertRequest({
            id: leadUser?.id,
            locationId: leadUser?.locationId || query?.locationId,
          });
        }
        break;

      case 'invoice':
        setToggleFormInvoice({
          data: { id },
        });
        break;

      case 'viewWaiver':
        setWaiverId(waiverIdTemp);
        break;

      case 'downloadWaiver':
        postWaiversDownload({ userId: user.id });
        break;

      case 'assignNewWaiver':
        setToggleWaiver({
          type: 'assignNewWaiver',
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteLeadBulk.mutate({ id: userTenantId, leadMemberIds: [id] });
        }
        break;

      case 'bookings':
        // TODO: set in query
        setToggleBookings({
          data: leadUser,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleFormLead.isVisible && (
        <Modal
          title={`${
            toggleFormLead.type === 'create' ? t('create') : t('update')
          } ${t('lead')}`}
          onClose={resetToggleFormLead}
        >
          <FormLead
            initialValues={toggleFormLead.data}
            isCreate={toggleFormLead.type === 'create'}
            isFetchingMembers={isFetchingMembers}
            isFetchingStaff={isFetchingStaff}
            isLoading={isCreatingLead || isUpdatingLead}
            locationOptions={locationOptions}
            members={members?.data || []}
            staff={staff?.data || []}
            onSelectLocation={(
              { locationId } // Set locationId
            ) => {
              setQuery((prev) => ({
                ...prev,
                type: prev.type,
                locationId,
              }));
            }}
            onSubmit={onPutPostRequest}
          />
        </Modal>
      )}

      {toggleFormInvoice.isVisible && (
        <Modal title={t('invoice')} onClose={resetToggleFormInvoice}>
          <FormInvoice
            isLoading={isCreatingLeadGenerateInvoice}
            onSubmit={(values) => {
              postLeadGenerateInvoiceRequest({
                id: toggleFormInvoice?.data?.id,
                leadMemberId: toggleFormInvoice?.data?.id,
                userTenantId: toggleFormInvoice.data.id,
                status: 'unpaid',
                lineItems: [],
                locationId: query?.locationId,
                ...values,
              });
            }}
          />
        </Modal>
      )}

      {toggleBookings.isVisible && (
        <Modal
          title={`${t('bookings')}: ${getFullName(toggleBookings.data.user)}`}
          onClose={resetToggleBookings}
        >
          <TableBookings
            data={isFetchingLeadBookings ? [] : leadBookings?.data || []}
            isLoading={isFetchingLeadBookings}
          />
        </Modal>
      )}

      {toggleWaiver.isVisible && (
        <Modal
          isSidebar
          title={
            toggleWaiver.type === 'assignNewWaiver'
              ? t('assignNewWaiver')
              : t('waiver')
          }
          onClose={resetToggleWaiver}
        >
          {toggleWaiver.type === 'assignNewWaiver' ? (
            <Text>{t('assignNewWaiverToLead')}</Text>
          ) : (
            <>
              <Alert
                mb={4}
                subtitle={
                  waiver.status === 'sent'
                    ? t('waiverHasNotBeenSigned')
                    : `${t(
                        'waiverHasBeenSigned'
                      )}: ${getDateReadableDayMonthYear({
                        date: waiver.signedAt,
                      })}`
                }
                variant={waiver.status === 'sent' ? 'warning' : 'success'}
              />

              <Text
                as='div'
                dangerouslySetInnerHTML={{
                  __html: waiver.digitalTermsAndConditions,
                }}
              />
            </>
          )}
        </Modal>
      )}

      {isCompactView ? (
        <TableLeads
          hasAlternateBackground
          data={leads || []}
          isLoading={usersFind?.isFetching}
          paging={usersFind?.data?.paging}
          query={query}
          setLeads={setLeads}
          setPaging={setPaging}
          onClickAction={onClickAction}
          onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
        />
      ) : (
        <Container
          appBarProps={{
            title: t('manageLeads'),
            breadcrumbs: [t('leads')],
            children: (
              <Box ml='auto'>
                <Box isFlex flexWrap='wrap' justifyContent='flex-end'>
                  <SendComm
                    buttonProps={{ hasBorder: true }}
                    leads={(!usersFind?.isFetching && leads) || []}
                    mb={2}
                  />

                  <Button
                    hasBorder
                    mb={2}
                    ml={2}
                    text={t('csvExport')}
                    onClick={getLeadsExportRequest}
                  />

                  <Button
                    ml={2}
                    text={t('createNew')}
                    onClick={() => setToggleFormLead({ type: 'create' })}
                  />
                </Box>
              </Box>
            ),
          }}
          isLoading={
            usersFind?.isFetching ||
            isFetchingLeadsExport ||
            isUpdatingLeadConvert ||
            deleteLeadBulk.isLoading ||
            isFetchingWaiver ||
            isFetchingWaiversDownload
          }
        >
          <FormQuery
            initialValues={query}
            locationOptions={locationOptionsAll}
            onSubmit={setQuery}
          />

          <Divider pb={4} />

          <TableLeads
            data={usersFind?.isFetching ? [] : leads}
            isLoading={usersFind?.isFetching}
            paging={usersFind?.data?.paging}
            query={query}
            setLeads={setLeads}
            setPaging={setPaging}
            onClickAction={onClickAction}
            onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
          />
        </Container>
      )}
    </>
  );
};
