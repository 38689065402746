import { Formik } from 'formik';
import { Button, Card, Col, Field, Row, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  cartTotal,
  isDebitOrder,
  isFetchingMembers,
  isFetchingUserPaymentDetails,
  isLoading,
  members,
  onGetUserPaymentDetailsRequest,
  onChangeCustomerType,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    tenant: { isGoCardless },
    toCurrency,
  } = useActiveUserTenant();

  return (
    <Formik
      {...props}
      initialValues={{
        customerType: 'member',
        userTenantId: undefined,
        name: undefined,
        email: undefined,
        mobile: undefined,
        note: undefined,
        salePaymentType: undefined,
        discount: undefined,
      }}
      validationSchema={Yup.object().shape({
        customerType: Yup.string().required(t('required')),
        userTenantId: Yup.string().when('customerType', {
          is: (value) => value === 'member',
          then: Yup.string().required(t('required')),
        }),
        name: Yup.string().when('customerType', {
          is: (value) => value === 'nonMember',
          then: Yup.string().required(t('required')),
        }),
        email: Yup.string().when('customerType', {
          is: (value) => value === 'nonMember',
          then: Yup.string().required(t('required')),
        }),
        mobile: Yup.string().when('customerType', {
          is: (value) => value === 'nonMember',
          then: Yup.string().required(t('required')),
        }),
        note: Yup.string(),
        salePaymentType: Yup.string().required(t('selectPaymentOption')),
        discount: Yup.string(),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        values: { customerType, userTenantId, discount },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Card hasBorder title={t('customer')}>
                <Row>
                  <Col>
                    <Field
                      isHorizontal
                      isRadio
                      doFinally={() => {
                        setFieldTouched('userTenantId', false);
                        setFieldValue('userTenantId', undefined);

                        setFieldTouched('name', false);
                        setFieldValue('name', undefined);

                        setFieldTouched('email', false);
                        setFieldValue('email', undefined);

                        setFieldTouched('mobile', false);
                        setFieldValue('mobile', undefined);

                        setFieldTouched('salePaymentType', false);
                        setFieldValue('salePaymentType', undefined);
                      }}
                      label={t('isCustomerMember')}
                      name='customerType'
                      options={[
                        { label: t('yes'), value: 'member' },
                        { label: t('no'), value: 'nonMember' },
                      ]}
                      onChange={(value) => {
                        setFieldValue('customerType', value);
                        onChangeCustomerType(value);
                      }}
                    />
                  </Col>

                  {customerType === 'member' ? (
                    <Col>
                      <Field
                        isSelect
                        doFinally={(newUserTenantId) => {
                          setFieldTouched('salePaymentType', false);
                          setFieldValue('salePaymentType', undefined);

                          onGetUserPaymentDetailsRequest(newUserTenantId);
                        }}
                        isLoading={isFetchingMembers}
                        label={t('member')}
                        name='userTenantId'
                        options={members.map((item) => ({
                          label: getFullName(item),
                          value: item.id,
                        }))}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col>
                        <Field label={t('fullName')} name='name' />
                      </Col>

                      <Col md={6}>
                        <Field label={t('emailAddress')} name='email' />
                      </Col>

                      <Col md={6}>
                        <Field label={t('mobileNumber')} name='mobile' />
                      </Col>
                    </>
                  )}

                  <Col>
                    <Field as='textarea' label={t('note')} name='note' />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={6}>
              <Card hasBorder title={t('payment')}>
                {customerType === 'nonMember' || !!userTenantId ? (
                  <Row>
                    <Col md={6}>
                      <Field label={t('discountAmount')} name='discount' />
                    </Col>

                    <Col>
                      <Text variant='heading'>
                        {`${t('total')}: ${toCurrency({
                          value: discount ? cartTotal - discount : cartTotal,
                        })}`}
                      </Text>
                    </Col>

                    <Col>
                      <Field
                        isRadio
                        canShowErrorUntouched={false}
                        label={t('customerHasPaid')}
                        name='salePaymentType'
                        options={[
                          { label: t('cash'), value: 'cash' },
                          { label: t('card'), value: 'card' },
                          { label: t('eft'), value: 'eft' },
                        ]}
                      />
                    </Col>

                    <Col>
                      <Field
                        isRadio
                        canShowErrorUntouched={false}
                        isLoading={isFetchingUserPaymentDetails}
                        label={t('customerHasNotPaid')}
                        name='salePaymentType'
                        options={[
                          ...(isDebitOrder
                            ? [
                                {
                                  label: t('addToNextDebitOrder'),
                                  value: 'debitOrder',
                                },
                              ]
                            : []),
                          {
                            label: t('generateAnInvoice'),
                            value: 'invoiceOnly',
                          },
                          ...(isGoCardless && customerType === 'nonMember'
                            ? []
                            : [
                                {
                                  label: t('requestAdhocPayment'),
                                  value: 'adhocPayment',
                                },
                              ]),
                        ]}
                      />
                    </Col>

                    <Col>
                      <Button
                        isLoading={isLoading}
                        text={t('submit')}
                        type='submit'
                      />
                    </Col>
                  </Row>
                ) : (
                  <Text color='grey1'>{t('pleaseSelectMember')}</Text>
                )}
              </Card>
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
