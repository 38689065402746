import { useMediaQuery } from 'octiv-hooks';
import React from 'react';

import { Box } from '../..';

const Col = ({ children, xs = 12, sm, md, lg, xl, xxl, ...props }) => {
  const breakpoints = useMediaQuery();

  const getPercentage = (value) => `${100 / (12 / value)}%`;

  let percentage = getPercentage(xs);

  if (breakpoints.xxl)
    percentage = getPercentage(xxl || xl || lg || md || sm || xs);
  else if (breakpoints.xl)
    percentage = getPercentage(xl || lg || md || sm || xs);
  else if (breakpoints.lg) percentage = getPercentage(lg || md || sm || xs);
  else if (breakpoints.md) percentage = getPercentage(md || sm || xs);
  else if (breakpoints.sm) percentage = getPercentage(sm || xs);

  return (
    <Box
      flexBasis={percentage}
      flexGrow={0}
      m={0}
      maxWidth={percentage}
      p={2}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Col;
