import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from '../TextInput';

const DateInput = ({ onChange, value, isDisabled, ...props }) => {
  const { i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const onDateChange = (date) => {
    if (!date) {
      return onChange(undefined);
    }

    if (!moment(date).isValid()) {
      return null;
    }

    return onChange(getDateYearMonthDay({ date }));
  };

  const selected = useMemo(() => {
    if (value && moment(value).isValid()) {
      return moment(value).toDate();
    }

    return null;
  }, [value]);

  useEffect(() => {
    moment.locale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  return (
    <LocalizationProvider
      adapterLocale={i18n.resolvedLanguage}
      dateAdapter={AdapterMoment}
    >
      <DatePicker
        inputFormat='DD MMM YYYY'
        key={i18n.resolvedLanguage}
        {...props}
        closeOnSelect
        disableMaskedInput
        disableOpenPicker
        hideTabs
        showDaysOutsideCurrentMonth
        disabled={isDisabled}
        open={isVisible}
        PopperProps={{
          placement: 'bottom-start',
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <>
            <TextInput
              ref={inputRef}
              {...inputProps}
              readOnly
              disabled={isDisabled}
              placeholder={null}
              onClick={() => setIsVisible(true)}
            />
            {InputProps?.endAdornment}
          </>
        )}
        showToolbar={false}
        value={selected}
        onAccept={onDateChange}
        onChange={() => {}}
        onClose={() => setIsVisible(false)}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
