import { Box } from 'octiv-components';
import { usePaging } from 'octiv-hooks';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import React, { useState } from 'react';

import FormQuery from './FormQuery';
import TablePackages from './TablePackages';

export default ({ locationId }) => {
  const [paging, setPaging] = usePaging();

  const [query, setQuery] = useState({
    isActive: undefined,
  });

  const packagesFind = usePackagesFind({
    filter: { ...query, locationId, typeId: 4 },
    paging,
  });

  return (
    <Box>
      <FormQuery
        initialValues={query}
        isFetchingTenants={packagesFind.isFetching}
        tenants={packagesFind.data?.data}
        onSubmit={setQuery}
      />
      <br />
      <TablePackages
        hasAlternateBackground
        data={packagesFind.data?.data}
        isLoading={packagesFind.isFetching}
        paging={packagesFind?.data?.paging}
        setPaging={setPaging}
      />
    </Box>
  );
};
