import { Box, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useLogbookFind } from 'octiv-hooks/requests/Logbook';
import React, { useState } from 'react';

import FormQuery from './FormQuery';
import Table from './Table';

const LogBook = ({ userId }) => {
  const { tenantId } = useActiveUserTenant();

  const [query, setQuery] = useState({
    type: undefined,
    search: undefined,
  });

  const logbookFind = useLogbookFind({
    filter: {
      tenantId,
      userId,
      type: query.search ? undefined : query.type,
      search: query.search,
    },
    paging: { perPage: -1 },
  });

  return (
    <>
      {logbookFind.isFetching && <ProcessingBar />}

      <FormQuery initialValues={query} onSubmit={setQuery} />

      <Box height={4} />

      <Table
        data={logbookFind.data?.data || []}
        isLoading={logbookFind.isFetching}
      />
    </>
  );
};

export default LogBook;
