import _ from 'lodash';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Chip,
  Col,
  Container,
  Divider,
  Modal,
  Row,
  Text,
  Tooltip,
  Workout,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useAddBookingsModal } from 'octiv-context/AddBookingsModal';
import { useMediaQuery, useToggle } from 'octiv-hooks';
import {
  useAttendanceRecordsCreateCheckIn,
  useAttendanceRecordsDeleteCancelCheckIn,
} from 'octiv-hooks/requests/AttendanceRecords';
import {
  useClassBookingsUpdateCancel,
  useClassBookingsUpdateNoShow,
} from 'octiv-hooks/requests/ClassBookings';
import { useClassDatesFind } from 'octiv-hooks/requests/ClassDates';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useWhiteboardFind } from 'octiv-hooks/requests/Whiteboard';
import {
  useWorkoutResultExercisesCreate,
  useWorkoutResultExercisesUpdate,
} from 'octiv-hooks/requests/WorkoutResultExercises';
import { useWorkoutsFind } from 'octiv-hooks/requests/Workouts';
import {
  getDateYearMonthDay,
  getExercisePart,
  getResultAsArray,
  sanitizeWorkout,
} from 'octiv-utilities/Functions';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormCancel from '../../modals/event/event/FormCancel';
import FormQuery from './FormQuery';
import FormResult from './FormResult';
import TableWhiteboard from './TableWhiteboard';

export default () => {
  const { t } = useTranslation();

  const { mdDown } = useMediaQuery();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const { setAddBookingsModal } = useAddBookingsModal();

  const [
    toggleBookingCancel,
    setToggleBookingCancel,
    resetToggleBookingCancel,
  ] = useToggle();

  const [whiteboard, setWhiteboard] = useState();
  // TODO: Sorting
  // - add sort criteria to whiteboardFind request
  // - update sorting to the new shape (including asc/desc using '-' prefix)
  const [sort, setSort] = useState({
    sortBy: undefined,
    sortDirection: 'desc',
    useRx: false,
    useGender: false,
    exercise: undefined,
  });
  const [workout, setWorkout] = useState();
  const [resultsCapture, setResultsCapture] = useState();
  const [query, setQuery] = useState({
    date: getDateYearMonthDay({ canFallback: true }),
    locationId: selectedLocation?.id,
    programmeId: undefined,
    classDateId: undefined,
  });

  const programmesFind = useProgrammesFind(
    {
      filter: { isActive: true },
      paging: { perPage: -1 },
    },
    {
      onSuccess: ({ data }) => {
        if (data && data[0]) {
          setQuery((prev) => ({ ...prev, programmeId: data[0].id }));
        }
      },
    }
  );

  const classDatesFind = useClassDatesFind({
    filter: {
      locationId: query.locationId,
      between: `${query.date},${query.date}`,
    },
    paging: { perPage: -1 },
  });

  const whiteboardFind = useWhiteboardFind(
    {
      filter: { ...query, ...sort },
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.programmeId,
    }
  );

  const workoutsFind = useWorkoutsFind(
    {
      filter: {
        programmeId: query.programmeId,
        startsAfter: query.date,
        endsBefore: getDateYearMonthDay({
          date: moment(query.date).add(1, 'day'),
        }),
      },
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.programmeId,
      onSuccess: ({ data }) => {
        setWorkout(sanitizeWorkout({ workout: data?.[0] }));

        setSort((prev) => ({ ...prev, exercise: undefined }));
      },
    }
  );

  const attendanceRecordsCreateCheckIn = useAttendanceRecordsCreateCheckIn();

  const attendanceRecordsDeleteCancelCheckIn =
    useAttendanceRecordsDeleteCancelCheckIn();

  const classBookingsUpdateNoShow = useClassBookingsUpdateNoShow();

  const classBookingsUpdateCancel = useClassBookingsUpdateCancel({
    onSuccess: resetToggleBookingCancel,
  });

  const workoutResultExercisesCreate = useWorkoutResultExercisesCreate();

  const workoutResultExercisesUpdate = useWorkoutResultExercisesUpdate();

  useEffect(() => {
    const hasSortableScore = [];
    const hasOtherScores = [];
    const hasNoScores = [];

    whiteboardFind?.data?.data?.map((item) => {
      if (
        item?.resultExercises?.find(
          (resultExercise) => resultExercise?.exerciseId === sort?.exercise?.id
        )
      ) {
        return hasSortableScore.push(item);
      }

      if (item?.resultExercises?.length > 0) {
        return hasOtherScores.push(item);
      }

      return hasNoScores.push(item);
    });

    hasSortableScore.sort((a, b) => {
      const resultExerciseA = a?.resultExercises?.find(
        (resultExercise) => resultExercise?.exerciseId === sort?.exercise?.id
      );
      const resultExerciseB = b?.resultExercises?.find(
        (resultExercise) => resultExercise?.exerciseId === sort?.exercise?.id
      );

      const resultExerciseAScore = getResultAsArray({
        measureId: sort?.exercise?.measuringUnit?.id,
        score: resultExerciseA.score,
      });

      const resultExerciseBScore = getResultAsArray({
        measureId: sort?.exercise?.measuringUnit?.id,
        score: resultExerciseB.score,
      });

      return (
        (sort.useGender &&
          (a?.classBooking?.user?.genderId === null ||
          b?.classBooking?.user?.genderId === null
            ? 1
            : // eslint-disable-next-line no-unsafe-optional-chaining
              a?.classBooking?.user?.genderId -
              // eslint-disable-next-line no-unsafe-optional-chaining
              b?.classBooking?.user?.genderId)) ||
        (sort.useRx && resultExerciseB.isRx - resultExerciseA.isRx) ||
        (sort.sortDirection === 'asc'
          ? resultExerciseAScore[0] - resultExerciseBScore[0] ||
            resultExerciseAScore[1] - resultExerciseBScore[1]
          : resultExerciseBScore[0] - resultExerciseAScore[0] ||
            resultExerciseBScore[1] - resultExerciseAScore[1])
      );
    });

    hasOtherScores.sort(
      (a, b) =>
        a?.classBooking.classDate?.startTime.localeCompare(
          b?.classBooking.classDate?.startTime
        ) ||
        a?.classBooking?.user?.name.localeCompare(b?.classBooking?.user?.name)
    );

    hasNoScores.sort(
      (a, b) =>
        a?.classBooking.classDate?.startTime.localeCompare(
          b?.classBooking.classDate?.startTime
        ) ||
        a?.classBooking?.user?.name.localeCompare(b?.classBooking?.user?.name)
    );

    setWhiteboard([...hasNoScores, ...hasSortableScore, ...hasOtherScores]);
  }, [whiteboardFind?.data, sort]);

  const onResultCapture = (
    { resultId, workoutId, exerciseId, userId, isRx, note, ...values },
    { setSubmitting }
  ) => {
    const score = Object.values(values);

    const data = {
      id: resultId,
      workoutId,
      exerciseId,
      userId,
      isRx,
      note,
      score: score.length > 1 ? score.join('.') : score[0],
    };

    const onSuccess = ({ id } = {}) => {
      setResultsCapture((prev) => {
        // Only update resultsCapture if the modal is open and it is for the same user
        if (prev && prev[0].userId === data.userId) {
          const newResults = [...prev];
          const index = newResults.findIndex(
            (item) => item.exerciseId === exerciseId
          );
          newResults[index] = { ...data, resultId: id };
          delete newResults[index].id;
          return newResults;
        }

        return prev;
      });

      whiteboardFind.refetch();
    };

    const onFailure = () => setSubmitting(false);

    if (data.id === undefined) {
      workoutResultExercisesCreate.mutate(data, {
        onSuccess,
        onFailure,
        meta: {
          useOnSuccessToast: true,
        },
      });
    } else {
      workoutResultExercisesUpdate.mutate(data, {
        onSuccess,
        onFailure,
        meta: {
          useOnSuccessToast: true,
        },
      });
    }
  };

  const setResultsCaptureData = ({ userId, workoutId, resultExercises }) => {
    const commonData = {
      workoutId,
      userId,
    };

    const data = [];

    workout?.exercises?.map((exercise) => {
      const resultExercise = resultExercises.find(
        (item) => item.exerciseId === exercise.id
      );

      return data.push({
        ...commonData,
        resultId: resultExercise?.id || undefined,
        exerciseId: exercise.id,
        score: resultExercise?.score || undefined,
        isRx:
          resultExercise && typeof resultExercise.isRx === 'number'
            ? resultExercise.isRx
            : undefined,
        note: resultExercise?.note || undefined,
      });
    });

    setResultsCapture(data);
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'checkIn':
        attendanceRecordsCreateCheckIn.mutate({ classBookingId: id });
        break;

      case 'checkInCancel':
        attendanceRecordsDeleteCancelCheckIn.mutate({ classBookingId: id });
        break;

      case 'noShow':
        classBookingsUpdateNoShow.mutate({ id });
        break;

      case 'cancel':
        setToggleBookingCancel({ data: { id } });
        break;

      default:
        break;
    }
  };

  const hasWorkout = !_.isEmpty(workout);

  const renderSorting = () =>
    hasWorkout &&
    sort.exercise && (
      <>
        <Chip
          color='success'
          icon='unfold_more'
          mb={2}
          ml={2}
          title={
            sort.sortDirection === 'asc'
              ? 'Score Ascending'
              : 'Score Descending'
          }
          onClick={() => {
            setSort((prev) => ({
              ...prev,
              sortBy: getExercisePart({ index: sort.exercise.order - 1 }),
              sortDirection: prev.sortDirection === 'asc' ? 'desc' : 'asc',
            }));
          }}
        />

        <Chip
          color={sort.useRx ? 'success' : 'grey2'}
          icon={sort.useRx ? 'check_box' : 'check_box_outline_blank'}
          mb={2}
          ml={2}
          title='Group by RX/Scaled'
          onClick={() =>
            setSort((prev) => ({
              ...prev,
              sortBy: getExercisePart({ index: sort.exercise.order - 1 }),
              useRx: !prev.useRx,
            }))
          }
        />

        <Chip
          color={sort.useGender ? 'success' : 'grey2'}
          icon={sort.useGender ? 'check_box' : 'check_box_outline_blank'}
          mb={2}
          ml={2}
          title='Group by Gender'
          onClick={() =>
            setSort((prev) => ({
              ...prev,
              sortBy: getExercisePart({ index: sort.exercise.order - 1 }),
              useGender: !prev.useGender,
            }))
          }
        />
      </>
    );

  return (
    <>
      {toggleBookingCancel.isVisible && (
        <Modal
          isCondensed
          title={t('cancelBooking')}
          onClose={resetToggleBookingCancel}
        >
          <FormCancel
            initialValues={toggleBookingCancel.data}
            isLoading={classBookingsUpdateCancel.isLoading}
            onSubmit={(values) => classBookingsUpdateCancel.mutate(values)}
          />
        </Modal>
      )}

      {resultsCapture !== undefined && (
        <Modal
          title={t('results')}
          onClose={() => setResultsCapture(undefined)}
        >
          {workout?.exercises?.map((exercise, index) => (
            <React.Fragment key={exercise.id}>
              {index > 0 && <Divider hasLine mb={4} mt={5} />}

              <Text mb={4} variant='heading'>
                {`${t('part')} ${
                  exercise.prefix ? exercise.prefix : getExercisePart({ index })
                }${exercise.name ? `: ${exercise.name}` : ''}`}
              </Text>

              <FormResult
                initialValues={resultsCapture[index]}
                measureId={exercise.measuringUnit?.id}
                onSubmit={onResultCapture}
              />
            </React.Fragment>
          ))}
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('whiteboard'),
          breadcrumbs: [t('workouts')],
          children: (
            <Box ml='auto'>
              <Button
                isDisabled={!query.classDateId}
                text={t('addBookings')}
                tooltip={
                  !query.classDateId
                    ? t('filterByClassToAddBookings')
                    : undefined
                }
                onClick={() =>
                  setAddBookingsModal({
                    eventId: query.classDateId,
                    onClose: whiteboardFind.refetch,
                  })
                }
              />
            </Box>
          ),
        }}
        isLoading={
          classDatesFind.isFetching ||
          programmesFind.isFetching ||
          whiteboardFind.isFetching ||
          workoutsFind.isFetching ||
          attendanceRecordsCreateCheckIn.isLoading ||
          classBookingsUpdateCancel.isLoading
        }
      >
        <FormQuery
          classes={classDatesFind.data?.data}
          initialValues={query}
          isFetchingClasses={classDatesFind.isFetching}
          isFetchingProgrammes={programmesFind.isFetching}
          locationOptions={locationOptions}
          programmes={programmesFind.data?.data}
          onSubmit={setQuery}
        />

        <Divider pb={6} />

        <Row>
          <Col lg={4}>
            <Text mb={2} variant='heading'>
              {t('workout')}
            </Text>

            <Card>
              {workoutsFind.isFetching || hasWorkout ? (
                <Workout
                  hasTrainerNotesToggle
                  showTrainerView
                  data={workout}
                  isLoading={workoutsFind.isFetching}
                  showProgramme={false}
                  textProps={{ variant: 'body' }}
                />
              ) : (
                <Text color='grey2' variant='subheading'>
                  {t('noWorkout')}
                </Text>
              )}
            </Card>
          </Col>

          <Col lg={8}>
            <Box isFlex>
              <Text mb={2} mr='auto' variant='heading'>
                {t('whiteboard')}
              </Text>

              {hasWorkout && (
                <Box isFlex flexWrap='wrap' justifyContent='flex-end'>
                  <Tooltip
                    options={[
                      { title: 'Sorting' },
                      { label: 'Default', value: undefined },
                      ...(workout?.exercises || []).map((exercise, index) => ({
                        label: `${t('part')} ${
                          exercise.prefix
                            ? exercise.prefix
                            : getExercisePart({ index })
                        }`,
                        value: exercise,
                      })),
                    ]}
                    placement='bottom'
                    onClick={({ value }) => {
                      setSort((prev) => ({
                        ...prev,
                        sortBy: getExercisePart({
                          index: (value?.order || 1) - 1,
                        }),
                        exercise: value,
                      }));
                    }}
                  >
                    <Chip
                      color='primary'
                      icon='sort'
                      mb={2}
                      title={
                        sort?.exercise
                          ? `${t('part')} ${
                              sort?.exercise.prefix
                                ? sort?.exercise.prefix
                                : getExercisePart({
                                    index: sort.exercise.order - 1,
                                  })
                            }`
                          : 'Default Sorting'
                      }
                    />
                  </Tooltip>

                  {!mdDown && renderSorting()}
                </Box>
              )}
            </Box>

            {mdDown && (
              <Box isFlex flexWrap='wrap' justifyContent='flex-end'>
                {renderSorting()}
              </Box>
            )}

            <TableWhiteboard
              data={whiteboard || []}
              exercises={
                hasWorkout && workout.exercises ? workout.exercises : []
              }
              hasWorkout={hasWorkout}
              isLoading={whiteboardFind.isFetching}
              query={query}
              onClickAction={onClickAction}
              onClickLogResults={setResultsCaptureData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
