import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        vatNumber: initialValues.vatNumber || undefined,
        vatPercent: initialValues.vatPercent || undefined,
        invoiceInformation: initialValues.invoiceInformation || undefined,
        showInvoiceTotals: initialValues.showInvoiceTotals === 1,
      }}
      validationSchema={Yup.object().shape({
        vatNumber: Yup.string(),
        vatPercent: Yup.string(),
        invoiceInformation: Yup.string().required(t('required')),
        showInvoiceTotals: Yup.bool().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Field label={t('vatNumber')} name='vatNumber' />
            </Col>

            <Col md={6}>
              <Field label={t('vatPercentage')} name='vatPercent' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('address')}
                name='invoiceInformation'
              />
            </Col>

            <Col>
              <Field
                isCheckbox
                name='showInvoiceTotals'
                options={[
                  {
                    label: t('showOpeningOutstandingBalanceOnInvoices'),
                    value: true,
                  },
                ]}
              />
            </Col>

            {/* <Col>
              <Field isDrop label={t('locationLogo')} name='logo' />
            </Col> */}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
