import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  locationCategories,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        id: initialValues.id,
        replacementCategoryId: undefined,
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required(t('required')),
        replacementCategoryId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            isSelect
            label='Replacement Category'
            name='replacementCategoryId'
            options={locationCategories
              ?.filter(
                (locationCategory) => locationCategory.id !== initialValues.id
              )
              .map((locationCategory) => ({
                label: locationCategory.name,
                value: locationCategory.id,
              }))}
          />

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
