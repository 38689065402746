import { Formik } from 'formik';
import { Alert, Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isExtendPackageEndDate: initialValues.isExtendPackage === 1 ? 1 : 0,
        proRataFee: initialValues.proRataFee || undefined,
      }}
      validationSchema={Yup.object().shape({
        isExtendPackageEndDate: Yup.bool(),
        proRataFee: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {!!initialValues.isExtendPackageEndDate && (
            <Alert
              mb={4}
              subtitle={t('packageEndDatesWereExtendedWhenPlacedOnHold')}
              variant='info'
            />
          )}

          <Field
            isRadio
            label={t('whatWouldYouLikeToDoWithPackageEndDates')}
            name='isExtendPackageEndDate'
            options={
              initialValues.isExtendPackageEndDate
                ? [
                    {
                      label: t('updateToCaterForEarlyRelease'),
                      value: 1,
                    },
                    {
                      label: t('setToOriginalValues'),
                      value: 0,
                    },
                  ]
                : [
                    {
                      label: t('leaveAsTheyAre'),
                      value: 0,
                    },
                    {
                      label: t('extendByNumberOfDaysOnHold'),
                      value: 1,
                    },
                  ]
            }
          />

          <Field
            boxContainerProps={{ mt: 4 }}
            label={t('firstDebitOrderInvoiceAmount')}
            name='proRataFee'
          />

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
