import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues,
  tenants,
  isFetchingTenants,
  isFetchingRegions,
  isLoading,
  onGetTenantsRequest,
  regions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        regionId: undefined,
        tenantId: undefined,
        startDate: initialValues?.startDate,
        endDate: initialValues?.endDate,
      }}
      validationSchema={Yup.object().shape({
        regionId: Yup.string(),
        tenantId: Yup.string(),
        startDate: Yup.string(),
        endDate: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                doFinally={(newRegionId) =>
                  onGetTenantsRequest({
                    regionId: newRegionId,
                  })
                }
                isLoading={isFetchingRegions}
                label={t('region')}
                name='regionId'
                options={[
                  { label: t('all'), value: undefined },
                  ...regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                isLoading={isFetchingTenants}
                label={t('tenant')}
                name='tenantId'
                options={[
                  { label: t('all'), value: undefined },
                  ...tenants.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                isDate
                label='Start Date (Class Bookings)'
                name='startDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                isDate
                label='End Date (Class Bookings)'
                name='endDate'
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
