import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useTenantsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, include = undefined } = input || {};

  return useQuery(
    ['tenants', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/tenants`,
        params: {
          include,
          filter: {
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
            affiliateId: filter.affiliateId,
            locationCategoryId: filter.locationCategoryId,
            statusId: filter.statusId,
            search: filter.search,
            page: filter.page,
            perPage: filter.perPage,
            sort: filter.sort,
            order: filter.order,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useTenantsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/tenants`,
        data: {
          name: data.name,
          description: data.description,
          regionId: data.regionId,
          billingCurrencyId: data.tenantBillingCurrencyId,
          memberBillingCurrencyId: data.memberBillingCurrencyId,
          timezoneId: data.timezoneId,
          userName: data.ownerName,
          userSurname: data.ownerSurname,
          userGenderId: data.ownerGenderId,
          userEmail: data.ownerEmail,
          userMobile: data.ownerMobile,
          userDateOfBirth: data.ownerDateOfBirth,
          isTrial: data.isTrial || false,

          facebookUrl: data.facebookUrl,
          websiteUrl: data.websiteUrl,
          instagramUrl: data.instagramUrl,

          // NOTE: Required for headcoach access priviledges on the BE
          perPage: -1,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTenantsFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['tenants', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/tenants/${id}`,
      }),
    options
  );
};

export const useTenantsFindByToken = (input, options) => {
  const { publicToken } = input || {};

  return useQuery(
    ['tenants', 'findByToken', input],
    () =>
      request({
        method: 'get',
        url: `/api/tenants/${publicToken}/token`,
        params: {
          include_contract_terms_and_conditions:
            input.include_contract_terms_and_conditions || 1,
          include_digital_terms_and_conditions:
            input.include_digital_terms_and_conditions || 1,
        },
      }),
    options
  );
};

export const useTenantsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/tenants/${id}`,
        data: {
          name: data.name,
          description: data.description,
          status: data.statusId,
          regionId: data.regionId,
          tenantBillingCurrencyId: data.tenantBillingCurrencyId,
          memberBillingCurrencyId: data.memberBillingCurrencyId,
          timezoneId: data.timezoneId,
          isTrial: data.isTrial,
          categoryOverride: data.categoryOverride,

          facebookUrl: data.facebookUrl,
          websiteUrl: data.websiteUrl,
          instagramUrl: data.instagramUrl,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTenantsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/tenants/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useTenantsUpdateAssignCategory = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/tenants/assign-category`,
        data: {
          locationCategoryId: data.locationCategoryId,
          tenantIds: data.tenantIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['tenants'] });

      options?.onSuccess?.(...res);
    },
  });
};
