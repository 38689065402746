import { Container } from 'octiv-components';
import { useSettingsFindWidgets } from 'octiv-hooks/requests/Settings';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LateFees from './lateFees';
import Manage from './manage';
import Tags from './tags';

export default () => {
  const { t } = useTranslation();

  const { data: widgetSettings, isFetching: isFetchingWidgetSettings } =
    useSettingsFindWidgets();

  return (
    <Container
      appBarProps={{
        title: t('packages'),
        breadcrumbs: [t('settings')],
      }}
      isLoading={isFetchingWidgetSettings}
    >
      <Tags publicToken={widgetSettings?.publicToken} />

      <Manage publicToken={widgetSettings?.publicToken} />

      <LateFees publicToken={widgetSettings?.publicToken} />
    </Container>
  );
};
