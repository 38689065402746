import {
  Alert,
  Button,
  Modal,
  ProcessingBar,
  ProcessingSpinner,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useUserPackageModal } from 'octiv-context/UserPackageModal';
import { useToggle } from 'octiv-hooks';
import {
  useUserPackagesDelete,
  useUserPackagesFind,
  useUserPackagesUpdateDeactivate,
  useUserPackagesUpdateTrainerTopUp,
} from 'octiv-hooks/requests/UserPackages';
import { useUserTenantsFindByIdPaymentDetails } from 'octiv-hooks/requests/UserTenants';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormTopUp from './FormTopUp';
import Table from './Table';

export default ({ userId, userTenantId, userTenantLocationId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { toCurrency } = useActiveUserTenant();
  const { resetMemberModal } = useMemberModal();
  const { setUserPackageModal } = useUserPackageModal();

  const [toggleFormTopUp, setToggleFormTopUp, resetToggleFormTopUp] =
    useToggle();

  const [paymentDetails, setPaymentDetails] = useState({
    packagesTotal: 0,
    discount: {},
    total: 0,
  });

  const userPackagesFind = useUserPackagesFind({
    filter: {
      userId,
      typeId: '1,2,3',
    },
    paging: { perPage: -1 },
  });

  const userTenantsFindByIdPaymentDetails =
    useUserTenantsFindByIdPaymentDetails({
      id: userTenantId,
    });

  const userPackagesUpdateDeactivate = useUserPackagesUpdateDeactivate();

  const userPackagesDelete = useUserPackagesDelete();

  const userPackagesUpdateTrainerTopUp = useUserPackagesUpdateTrainerTopUp();

  const onSubmitFormTopUp = ({ paymentType, ...values }) => {
    userPackagesUpdateTrainerTopUp.mutate(
      {
        paymentType,
        ...values,
      },
      {
        onSuccess: ({ code } = {}) => {
          if (paymentType === 'adhocPayment') {
            navigate(`/accounts/invoices?requestPaymentCode=${code}`);
            resetMemberModal();
          } else {
            resetToggleFormTopUp();
          }
        },
        onSuccessToast:
          paymentType === 'adhocPayment'
            ? undefined
            : {
                title:
                  paymentType === 'invoiceOnly'
                    ? t('invoiceCreated')
                    : t('sessionsAllocated'),
              },
      }
    );
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setUserPackageModal({
          userId,
          userTenantId,
          userLocationId: userTenantLocationId,
          initialUserPackage: userPackagesFind.data?.data?.find(
            (item) => item.id === id
          ),
        });
        break;

      case 'deactivate':
        if (window.confirm(t('areYouSureDeactivatePackage'))) {
          userPackagesUpdateDeactivate.mutate({ id });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          userPackagesDelete.mutate({ id });
        }
        break;

      case 'topUp':
        setToggleFormTopUp({
          data: userPackagesFind.data?.data?.find((item) => item.id === id),
        });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const packagesTotal = userPackagesFind.data?.data
      ?.filter((item) => item.isActive && item.package.isActive)
      .reduce((a, b) => a + b.package.price, 0);

    let total = packagesTotal;
    let discount = {};

    if (
      userTenantsFindByIdPaymentDetails.data?.discountDetails?.type ===
      'specialRate'
    ) {
      discount = userTenantsFindByIdPaymentDetails.data?.discountDetails;

      total = discount.amount;
    } else if (
      userTenantsFindByIdPaymentDetails.data?.discountDetails?.type ===
      'discount'
    ) {
      discount =
        userTenantsFindByIdPaymentDetails.data?.discountDetails?.discount;

      if (discount.type === 'percentage') {
        total = packagesTotal - (packagesTotal / 100) * discount.amount;
      } else {
        total = packagesTotal - discount.amount;
      }
    }

    setPaymentDetails({
      packagesTotal,
      discount,
      total,
    });
  }, [userPackagesFind.data, userTenantsFindByIdPaymentDetails.data]);

  return (
    <>
      {(userPackagesFind.isFetching ||
        userPackagesUpdateDeactivate.isLoading ||
        userPackagesDelete.isLoading) && <ProcessingBar />}

      {toggleFormTopUp.isVisible && (
        <Modal
          isSidebar
          title={t('topUpPackage')}
          onClose={resetToggleFormTopUp}
        >
          {!!toggleFormTopUp.data.unpaidInvoice && (
            <Alert
              mb={4}
              subtitle={`${t('memberHasUnpaidInvoice')} ${
                toggleFormTopUp.data.unpaidInvoice.numberOfSessions
              } ${t('session').toLowerCase()}${
                toggleFormTopUp.data.unpaidInvoice.numberOfSessions > 1
                  ? 's'
                  : ''
              } (${toCurrency({
                value: toggleFormTopUp.data.unpaidInvoice.amount,
              })}). ${t('canStillTopUpPackage')}.`}
              title={t('unpaidInvoice')}
              variant='warning'
            />
          )}

          <FormTopUp
            initialValues={toggleFormTopUp.data}
            isDebitOrder={
              userTenantsFindByIdPaymentDetails.data?.paymentDetails
                ?.userDebitStatus?.id === 2
            }
            isFetchingUserTenantPaymentDetails={
              userTenantsFindByIdPaymentDetails.isFetching
            }
            isLoading={userPackagesUpdateTrainerTopUp.isLoading}
            onSubmit={onSubmitFormTopUp}
          />
        </Modal>
      )}

      <Button
        mb={4}
        text={t('createNew')}
        onClick={() =>
          setUserPackageModal({
            userId,
            userTenantId,
            userTenantLocationId,
          })
        }
      />

      <Table
        data={userPackagesFind.data?.data || []}
        isLoading={userPackagesFind.isFetching}
        onClickAction={onClickAction}
      />

      {userPackagesFind.isFetching ||
      userTenantsFindByIdPaymentDetails.isFetching ? (
        <ProcessingSpinner ml='auto' mt={4} />
      ) : (
        <>
          <Text color='grey1' mt={4} textAlign='right'>
            {`${t('packagesTotal')}: ${toCurrency({
              value: paymentDetails.packagesTotal,
            })}`}
          </Text>

          <Text color='grey1' mt={1} textAlign='right'>
            {`${
              paymentDetails.discount.type === 'specialRate'
                ? t('specialRate')
                : t('discount')
            }: ${
              paymentDetails.discount.type === 'percentage'
                ? `${paymentDetails.discount.amount}%`
                : toCurrency({ value: paymentDetails.discount.amount || 0 })
            }`}
          </Text>

          <Text mt={1} textAlign='right' variant='heading'>
            {`${t('total')}: ${toCurrency({ value: paymentDetails.total })}`}
          </Text>
        </>
      )}
    </>
  );
};
