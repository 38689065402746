import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Box } from '../..';

const Row = (props) => {
  const theme = useContext(ThemeContext);

  return (
    <Box
      isFlex
      flexWrap='wrap'
      m={-2}
      width={`calc(100% + ${theme.sizes[4]})`}
      {...props}
    />
  );
};

export default Row;
