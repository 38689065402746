import { Card } from 'octiv-components';
import { useReportsFindLocationCategoryMetrics } from 'octiv-hooks/requests/Reports';
import React, { useState } from 'react';

import TableLocationCategories from './TableLocationCategories';

export default ({ query }) => {
  const [locationCategoryMetrics, setLocationCategoryMetrics] = useState([]);

  const { isFetching: isFetchingLocationCategoryMetrics } =
    useReportsFindLocationCategoryMetrics(
      {
        filter: query,
      },
      {
        onSuccess: (response) =>
          setLocationCategoryMetrics(
            (response?.data || response)?.sort?.(
              (a, b) => b.tenantsCount - a.tenantsCount
            )
          ),
      }
    );

  return (
    <Card mt={4} title='Location Category Metrics'>
      <TableLocationCategories
        data={locationCategoryMetrics}
        isLoading={isFetchingLocationCategoryMetrics}
      />
    </Card>
  );
};
