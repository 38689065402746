import { Table } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  getDateReadableDayMonthYear,
  getEventString,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { toCurrency } = useActiveUserTenant();

  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('class'),
        id: 'class',
        Cell: ({
          row: {
            original: { name, startTime, endTime },
          },
        }) =>
          getEventString({
            name,
            startTime,
            endTime,
          }),
      },
      {
        Header: t('date'),
        accessor: 'date',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: `# ${t('attendees')}`,
        accessor: 'attendanceCount',
      },
      {
        Header: `# ${t('checkedIn')}`,
        accessor: 'attendeesCheckedInCount',
      },
      {
        Header: t('fee'),
        id: 'fee',
        Cell: ({
          row: {
            original: { trainersClassFee, trainersSessionFee },
          },
        }) =>
          toCurrency({ value: trainersClassFee || trainersSessionFee || 0 }),
      },
    ],
    [toCurrency, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
