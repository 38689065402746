import { useQuery } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useDashboardFindScheduleStats = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dashboard', 'findScheduleStats', input],
    () =>
      request({
        method: 'get',
        url: `/api/dashboard/schedule-stats`,
        params: {
          filter: { locationId: filter.locationId, tenantId },
        },
      }),
    options
  );
};

export const useDashboardFindNonAttendance = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dashboard', 'findNonAttendance', input],
    () =>
      request({
        method: 'get',
        url: `/api/dashboard/non-attendance`,
        params: {
          filter: {
            tenantId,
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const useDashboardFindNewSignUps = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dashboard', 'findNewSignUps', input],
    () =>
      request({
        method: 'get',
        url: `/api/dashboard/new-sign-ups`,
        params: {
          filter: { locationId: filter.locationId, tenantId },
        },
      }),
    options
  );
};

export const useDashboardFindContractsExpiring = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['dashboard', 'findContractsExpiring', input],
    () =>
      request({
        method: 'get',
        url: `/api/dashboard/contracts-expiring`,
        params: {
          filter: { locationId: filter.locationId, tenantId },
        },
      }),
    options
  );
};
