import { Box, Button, Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useStoreCreateStockItems,
  useStoreDeleteStockItems,
  useStoreFindStockItems,
  useStoreFindStockItemsCategories,
  useStoreUpdateStockItems,
} from 'octiv-hooks/requests/Store';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormLocation from './FormLocation';
import FormProduct from './FormProduct';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const [selectedLocationId, setSelectedLocationId] = useState(
    selectedLocation?.id
  );
  const [toggle, setToggle, resetToggle] = useToggle();

  const storeFindStockItems = useStoreFindStockItems({
    filter: { locationId: selectedLocationId },
    paging: { perPage: -1 },
  });
  const storeCreateStockItems = useStoreCreateStockItems({
    onSuccess: resetToggle,
  });
  const storeUpdateStockItems = useStoreUpdateStockItems({
    onSuccess: resetToggle,
  });
  const storeDeleteStockItems = useStoreDeleteStockItems();

  const storeFindStockItemsCategories = useStoreFindStockItemsCategories({
    locationId: selectedLocation?.id,
  });

  const onSubmitForm = (values) => {
    if (toggle.type === 'create') {
      storeCreateStockItems.mutate(values);
    } else {
      storeUpdateStockItems.mutate({ id: toggle.data.id, ...values });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: storeFindStockItems?.data?.data?.find?.(
            (item) => item.id === id
          ),
        });
        break;

      case 'delete':
        storeDeleteStockItems.mutate({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          title={`${toggle.type === 'create' ? t('create') : t('update')} ${t(
            'product'
          )}`}
          onClose={resetToggle}
        >
          <FormProduct
            initialValues={toggle.data}
            isCreate={toggle.type === 'create'}
            isLoading={
              storeCreateStockItems.isLoading || storeUpdateStockItems.isLoading
            }
            locationOptions={locationOptions}
            productCategories={
              storeFindStockItemsCategories.data?.categories || []
            }
            onSubmit={onSubmitForm}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('products'),
          breadcrumbs: [t('store')],
          children: (
            <Box ml='auto'>
              <Button
                text={t('createNew')}
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={
          storeFindStockItems.isFetching || storeDeleteStockItems.isLoading
        }
      >
        <FormLocation
          initialValues={{ locationId: selectedLocationId }}
          locationOptions={locationOptions}
          onSubmit={({ locationId }) => setSelectedLocationId(locationId)}
        />

        <Divider pb={4} />

        <Table
          data={storeFindStockItems?.data?.data || []}
          isLoading={storeFindStockItems.isFetching}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
