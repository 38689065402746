import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useUserContractsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['userContracts', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-contracts`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useUserContractsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      formData.append('tenantId', data.tenantId || tenantId);
      formData.append('userId', data.userId);
      formData.append('locationId', data.locationId);
      formData.append('startDate', data.startDate);
      formData.append('endDate', data.endDate);
      formData.append('file', data.file);

      return request({
        method: 'post',
        url: `/api/user-contracts`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: {
          tenantId: data.tenantId || tenantId,
          userId: data.userId,
          locationId: data.locationId,
          startDate: data.startDate,
          endDate: data.endDate,
          file: data.file,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsCreateSend = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/user-contracts/${id}/send`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsCreateSendCurrent = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-contracts/send-current`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsCreateSendAttachment = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'post',
        url: `/api/user-contracts/${id}/send-attachment`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsCreateDownload = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'get',
        url: `/api/user-contracts/${id}/download`,
      });
    },
  });
};

export const useUserContractsCreateDownloadAttachment = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'get',
        url: `/api/user-contracts/${id}/download-attachment`,
      });
    },
  });
};

export const useUserContractsFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['userContracts', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-contracts/${id}`,
      }),
    options
  );
};

export const useUserContractsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/user-contracts/${id}`,
        data: {
          startDate: data.startDate,
          endDate: data.endDate,
          file: data.file,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/user-contracts/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsCreateBulkGenerate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-contracts/bulk-generate`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          startDate: data.startDate,
          endDate: data.endDate,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsUpdateSign = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/user-contracts/${id}/sign`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserContractsUpdateDeleteAttachment = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/user-contracts/delete-attachment/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userContracts'] });

      options?.onSuccess?.(...res);
    },
  });
};
