import { Formik } from 'formik';
import { Alert, Box, Button, Col, Field, Row, Text } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isAdult,
  onCancel,
  reasonCannotCalculate,
  calculatedBodyFatPercentage,
  ...props
}) => {
  const { t } = useTranslation();

  const numberValidation = Yup.number().typeError(t('mustBeANumber'));

  const numberValidationOctiv = numberValidation.when('useOctiv', {
    is: (value) => value === true,
    then: Yup.number().typeError(t('mustBeANumber')).required(t('required')),
  });

  return (
    <Formik
      {...props}
      initialValues={{
        useOctiv: initialValues.bodyFatPercentage
          ? calculatedBodyFatPercentage(initialValues) ===
            initialValues.bodyFatPercentage
          : true,
        recordedAt: getDateYearMonthDay({
          date: initialValues.recordedAt,
          canFallback: true,
        }),
        tricep: initialValues.tricep || undefined,
        subscapular: initialValues.subscapular || undefined,
        abdominal: initialValues.abdominal || undefined,
        suprailiac: initialValues.suprailiac || undefined,
        thigh: initialValues.thigh || undefined,
        calf: initialValues.calf || undefined,
        bodyFatPercentage: initialValues.bodyFatPercentage || undefined,
      }}
      validationSchema={Yup.object().shape({
        useOctiv: Yup.bool().required(t('required')),
        recordedAt: Yup.string().required(t('required')),
        tricep: numberValidationOctiv,
        subscapular: isAdult ? numberValidationOctiv : numberValidation,
        abdominal: isAdult ? numberValidationOctiv : numberValidation,
        suprailiac: isAdult ? numberValidationOctiv : numberValidation,
        thigh: isAdult ? numberValidationOctiv : numberValidation,
        calf: numberValidationOctiv,
        bodyFatPercentage: numberValidation,
      })}
    >
      {({ handleSubmit, dirty, isValid, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field isDate label={t('dateRecorded')} name='recordedAt' />
            </Col>

            <Col>
              <Field
                isCheckbox
                name='useOctiv'
                options={[
                  {
                    label: t('useOctivToCalculateBodyFatPercentage'),
                    value: true,
                  },
                ]}
              />
            </Col>

            {values.useOctiv && reasonCannotCalculate ? (
              <Col>
                <Alert
                  subtitle={t('requireBodyFatCalculationData', {
                    data: reasonCannotCalculate,
                  })}
                  title={t('requiredData')}
                  variant='warning'
                />
              </Col>
            ) : (
              <>
                <Col lg={6}>
                  <Field label={t('tricep')} name='tricep' />
                </Col>

                <Col lg={6}>
                  <Field label={t('subscapular')} name='subscapular' />
                </Col>

                <Col lg={6}>
                  <Field label={t('abdominal')} name='abdominal' />
                </Col>

                <Col lg={6}>
                  <Field label={t('suprailiac')} name='suprailiac' />
                </Col>

                <Col lg={6}>
                  <Field label={t('thigh')} name='thigh' />
                </Col>

                <Col lg={6}>
                  <Field label={t('calf')} name='calf' />
                </Col>

                {values.useOctiv ? (
                  isValid && values.tricep !== undefined ? (
                    <Col>
                      <Text>
                        {`${t(
                          'bodyFatPercentage'
                        )}: ${calculatedBodyFatPercentage(values).toString()}`}
                      </Text>
                    </Col>
                  ) : null
                ) : (
                  <Col lg={6}>
                    <Field
                      label={t('bodyFatPercentage')}
                      name='bodyFatPercentage'
                    />
                  </Col>
                )}

                <Col>
                  <Box isFlex>
                    <Button
                      isDisabled={!dirty}
                      mr={2}
                      text={t('submit')}
                      type='submit'
                    />

                    <Button
                      hasBorder
                      color='danger'
                      text={t('cancel')}
                      onClick={onCancel}
                    />
                  </Box>
                </Col>
              </>
            )}
          </Row>
        </form>
      )}
    </Formik>
  );
};
