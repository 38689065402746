import { FieldArray, Formik } from 'formik';
import { Box, Button, Col, Field, Row, Text } from 'octiv-components';
import Step from 'octiv-components/step/index';
import { getFullName, toCase } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  signedInUser,
  users,
  userTenants,
  isLoading,
  onBack,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userId: undefined,
      }}
      validationSchema={Yup.object().shape({
        // TODO: add schema for each array element
        // users: Yup.array().of(Yup.object().shape({}).required(t('required'))),
        userId: Yup.number(),
      })}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box isFlex flexDirection='column'>
            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              mb='1rem'
              title={t('choosePrimaryAccount')}
              value='1'
            />
            <br />
            <Text>Hi, {getFullName(signedInUser)}!</Text>
            <br />
            <Text>{t('mergeAccountsDetectedDuplicates')}</Text>
          </Box>

          <br />
          <Text>{t('mergeAccountsPleaseSelectPrimary')}</Text>
          <br />

          {isLoading ? null : (
            <Row>
              <FieldArray
                name='userId'
                render={() =>
                  users?.map((user, index) => (
                    <React.Fragment key={`${index.toString()}`}>
                      <Col>
                        <Field
                          isRadio
                          name={`userId`}
                          options={[
                            {
                              label: `${users?.[index]?.email} (${getFullName(
                                users?.[index]
                              )})`,
                              value: user?.id,
                            },
                          ]}
                        />
                        <br />
                        {userTenants
                          ?.filter(
                            (userTenant) => userTenant?.userId === user?.id
                          )
                          .map((userTenant, userTenantIndex) => (
                            <Text
                              color={
                                userTenant?.status?.name === 'ACTIVE'
                                  ? 'success'
                                  : 'danger'
                              }
                              ml='1rem'
                            >
                              {userTenantIndex + 1}.&nbsp;
                              {`${userTenant?.tenant?.name} (${getFullName(
                                userTenant
                              )} - ${toCase({
                                value: userTenant?.status?.name,
                              })})`}
                              {`(${toCase({ value: userTenant?.type?.name })})`}
                            </Text>
                          ))}
                        <div />
                      </Col>
                    </React.Fragment>
                  ))
                }
              />
            </Row>
          )}

          <br />

          <Box isFlex flexDirection='row'>
            {/* <Button isLoading={isLoading} text={t('back')} onClick={onBack} /> */}
            <Button
              isDisabled={!values?.userId}
              isLoading={isLoading}
              text={t('next')}
              type='submit'
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};
