import { Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: t('merchantAccountNumber'),
        id: 'merchantAccountNumber',
        accessor: 'settings',
        Cell: ({ value }) => value?.merchantAccountNumber,
      },
      {
        Header: t('accountServiceKey'),
        id: 'accountServiceKey',
        accessor: 'settings',
        Cell: ({ value }) => value?.accountServiceKey,
      },
      {
        Header: t('debitOrderServiceKey'),
        id: 'debitOrderServiceKey',
        accessor: 'settings',
        Cell: ({ value }) => value?.debitOrderServiceKey,
      },
      {
        Header: t('action'),
        Cell: ({
          row: {
            original: {
              settings: { accountServiceKey, id },
            },
          },
        }) => (
          <Text
            as='span'
            color={accountServiceKey ? 'primary' : 'danger'}
            onClick={() => onClickAction({ id })}
          >
            {accountServiceKey ? t('edit') : t('onboardNow')}
          </Text>
        ),
      },
    ],
    [onClickAction, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
