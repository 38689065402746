import { Container } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DebitOrderDueDate from './debitOrderDueDate';
import Discounts from './discounts';
import InvoiceItemTypes from './invoiceItemTypes';
import LocationInvoiceDetails from './locationInvoiceDetails';
import Tags from './tags';
import Tenant from './tenant';

export default () => {
  const { t } = useTranslation();

  return (
    <Container
      appBarProps={{
        title: t('accounts'),
        breadcrumbs: [t('settings')],
      }}
    >
      <Tenant />

      <DebitOrderDueDate />

      <LocationInvoiceDetails />

      <Discounts />

      <InvoiceItemTypes />

      <Tags />
    </Container>
  );
};
