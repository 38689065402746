import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        status: initialValues.status.id || undefined,
        subject: initialValues.subject || undefined,
        cc: initialValues.cc || undefined,
        content: initialValues.content || undefined,
      }}
      validationSchema={Yup.object().shape({
        status: Yup.string().required(t('required')),
        subject: Yup.string().required(t('required')),
        cc: Yup.string(),
        content: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                label={t('status')}
                name='status'
                options={[
                  {
                    label: t('enabled'),
                    value: 'enabled',
                  },
                  {
                    label: t('disabled'),
                    value: 'disabled',
                  },
                ]}
              />
            </Col>

            <Col>
              <Field label={t('subject')} name='subject' />
            </Col>

            <Col>
              <Field label={t('ccEmailAddress')} name='cc' />
            </Col>

            <Col>
              <Field
                isEditor
                label={t('content')}
                name='content'
                placeholders={initialValues.placeholders}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
