import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        isUserContractVisibleInApp:
          typeof initialValues.isUserContractVisibleInApp === typeof true
            ? initialValues.isUserContractVisibleInApp
            : true,
        contractExpiryNotificationDays:
          initialValues.contractExpiryNotificationDays || undefined,
        contractTermsAndConditions:
          initialValues.contractTermsAndConditions || undefined,
      }}
      validationSchema={Yup.object().shape({
        isUserContractVisibleInApp: Yup.bool().required(t('required')),
        contractExpiryNotificationDays: Yup.string().required(t('required')),
        contractTermsAndConditions: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isCheckbox
                name='isUserContractVisibleInApp'
                options={[
                  {
                    label: t('shouldContractEndDateBeVisibleInMemberApp'),
                    value: true,
                  },
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                label={t('daysBeforeContractExpiryNotificationIsSent')}
                name='contractExpiryNotificationDays'
              />
            </Col>

            <Col>
              <Field
                isEditor
                label={t('termsConditions')}
                name='contractTermsAndConditions'
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('update')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
