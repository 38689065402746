import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useWorkoutsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['workouts', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/wods`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            startsAfter: filter.startsAfter,
            endsBefore: filter.endsBefore,
            programmeId: filter.programmeId,
            useWorkoutThreshold: filter.useWorkoutThreshold,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useWorkoutsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/wods`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          description: data.description,
          date: data.date,
          programmeId: data.programmeId,
          classDateId: data.classDateId,
          warmUp: data.warmUp,
          coolDown: data.coolDown,
          coachNotes: data.trainerNotes,
          memberNotes: data.memberNotes,
          exercises: data.exercises,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workouts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutsFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['workouts', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/wods/${id}`,
      }),
    options
  );
};

export const useWorkoutsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/wods/${id}`,
        data: {
          name: data.name,
          description: data.description,
          date: data.date,
          programmeId: data.programmeId,
          warmUp: data.warmUp,
          coolDown: data.coolDown,
          coachNotes: data.trainerNotes,
          memberNotes: data.memberNotes,
          exercises: data.exercises,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workouts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/wods/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workouts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutsCreateImport = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      // formData.append('affiliateId', data.file);
      formData.append('file', data.file);

      if (tenantId) {
        formData.append('tenantId', tenantId);
      }

      if (data.affiliateId) {
        formData.append('affiliateId', data.affiliateId);
      }

      return request({
        method: 'post',
        url: `/api/wods/import`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workouts'] });

      options?.onSuccess?.(...res);
    },
  });
};
