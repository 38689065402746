import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { getTheme } from 'octiv-utilities/Theme';
import React from 'react';
import { ThemeProvider } from 'styled-components';

const baseTheme = getTheme().dark;

const ThemeInitialState = {
  ...baseTheme,
  setTheme: () => {},
};

export const ThemeContext = React.createContext(ThemeInitialState);

export const ThemeContextProvider = ({ staticTheme, children }) => {
  const [themeContext, setThemeContext] = React.useState(ThemeInitialState);

  const setTheme = React.useCallback((args = {}) => {
    const { isDark, primaryDark, buttonDark, primaryLight, buttonLight } =
      args || {};

    const theme = getTheme({
      primaryDark,
      buttonDark,
      primaryLight,
      buttonLight,
    });

    // TODO: remove this once backend fix is done
    const isDarkPlaster = !(
      isDark === false ||
      isDark === 'false' ||
      isDark === 0 ||
      isDark === '0'
    );

    const newTheme = isDarkPlaster === false ? theme.light : theme.dark;

    setThemeContext((prev) => ({
      ...prev,
      ...newTheme,
    }));

    const root = document.documentElement;
    const colorKeys = Object.keys(newTheme.app.colors);
    colorKeys.forEach((key) => {
      root.style.setProperty(`--${key}`, newTheme.app.colors[key]);
    });
  }, []);

  React.useEffect(() => {
    if (staticTheme) {
      setTheme({ isDark: staticTheme === 'dark' });
    } else {
      setThemeContext({ setTheme });
      setTheme();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticTheme]);

  return (
    <ThemeContext.Provider value={themeContext}>
      <ThemeProviderMui theme={themeContext.mui}>
        <ThemeProvider theme={themeContext.app}>{children}</ThemeProvider>
      </ThemeProviderMui>
    </ThemeContext.Provider>
  );
};
