import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { appendFile } from 'octiv-utilities/Functions';
import { request } from 'octiv-utilities/Request';

export const useCrmFindBroadcastMessages = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['crm', 'findBroadcastMessages', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-broadcast-messages`,
        params: {
          filter: {
            tenantId,
            isActive: filter.isActive,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useCrmFindByIdBroadcastMessages = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['crm', 'findByIdBroadcastMessages', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-broadcast-messages/${id}`,
      }),
    options
  );
};

export const useCrmUpdateBroadcastMessages = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/crm-broadcast-messages/${id}`,
        data: {
          message: data.message,
          isActive: data.isActive,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmFindMailers = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['crm', 'findMailers', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-mailers`,
        params: {
          filter: {
            type: filter.type,
            status: filter.status,
            tenantId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useCrmCreateMailers = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      // TODO: consider putting this in a better place
      const keys = Object.keys(data.recipients);
      const minifiedRecipientPayload = {};
      keys?.forEach((key) => {
        minifiedRecipientPayload[key] = data.recipients[key].map((user) =>
          user?.id
            ? {
                id: user.id,
                name: user.name,
                surname: user.surname,
                email: user.email || undefined,
                mobile: user.mobile || undefined,
              }
            : {
                email: user.email || undefined,
                mobile: user.mobile || undefined,
                name: user.name,
                surname: user.surname,
              }
        );
      });

      const recipients = {
        members: data.recipients?.members?.map((r) => r.id),
        leads: data.recipients?.leads?.map((r) => r.user?.id || r?.id),
        staff: data.recipients?.staff?.map((r) => r.id),
        nonMembers: data.recipients.nonMembers,
      };

      // NOTE: The attachments are added upon success of this request, using useCrmUpdateMailersAttachments
      delete data.imageHeader;
      delete data.imageFooter;
      delete data.attachment;

      return request({
        method: 'post',
        url: `/api/crm-mailers`,
        data: {
          ...data,
          sendAt:
            data.frequency !== 'now'
              ? `${data.sendDate} ${data.sendTime}`
              : undefined,
          recipients,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmFindByIdMailers = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['crm', 'findByIdMailers', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-mailers/${id}`,
        params: {
          include: input.include || undefined,
        },
      }),
    options
  );
};

export const useCrmDuplicateMailers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    // NOTE:
    // I know this should not be a mutation.
    // Writing it as a mutation was much simpler, because then I can just call the .mutate function where the onClickAction handler is called,
    // instead of having to store the ID in a state variable, which then triggers the refetch
    // technically it is a mutation, as this "GET" request creates a new resource
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'get',
        url: `/api/crm-mailers/${id}/copy`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmUpdateMailersAttachments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const formData = new FormData();
      appendFile(formData, 'imageHeader', data.imageHeader);
      appendFile(formData, 'imageFooter', data.imageFooter);
      appendFile(formData, 'attachment', data.attachment);

      return request({
        method: 'post',
        url: `/api/crm-mailers/${id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmUpdateMailers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const recipients = {
        members: data.recipients?.members?.map((r) => r.id),
        leads: data.recipients?.leads?.map((r) => r.user?.id || r.id),
        staff: data.recipients?.staff?.map((r) => r.id),
        nonMembers: data.recipients.nonMembers,
      };

      // NOTE: The attachments are added upon success of this request, using useCrmUpdateMailersAttachments
      delete data.imageHeader;
      delete data.imageFooter;
      delete data.attachment;

      return request({
        method: 'post',
        url: `/api/crm-mailers/${id}`,
        data: {
          ...data,
          sendAt:
            data.frequency !== 'now'
              ? `${data.sendDate} ${data.sendTime}`
              : undefined,
          recipients,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmDeleteMailers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/crm-mailers/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmUpdateMailersCancel = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/crm-mailers/cancel/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmFindSettings = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['crm', 'findSettings', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm-settings`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useCrmUpdateSettings = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      appendFile(formData, 'logo', data.logo);
      formData.append('locationId', data.locationId);
      formData.append('smsStatus', data.smsStatus);
      formData.append('emailStatus', data.emailStatus);
      formData.append('emailSignature', data.emailSignature);
      formData.append('replyTo', data.replyTo);
      formData.append('senderName', data.senderName);

      return request({
        method: 'post',
        url: `/api/crm-settings/${data?.id}`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmFindTemplates = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['crm', 'findTemplates', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm/templates`,
        params: {
          filter: {
            type: filter.type,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useCrmCreateTemplates = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/crm/templates`,
        data: { name: data.name, type: data.type, content: data.content },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmFindByIdTemplates = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['crm', 'findByIdTemplates', input],
    () =>
      request({
        method: 'get',
        url: `/api/crm/templates/${id}`,
      }),
    options
  );
};

export const useCrmUpdateTemplates = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/crm/templates/${id}`,
        data: { name: data.name, content: data.content },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useCrmDeleteTemplates = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/crm/templates/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['crm'] });

      options?.onSuccess?.(...res);
    },
  });
};
