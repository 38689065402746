import { Images } from 'octiv-assets';
import { Image, Table, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getFullName,
  toCase,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setLeads, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        accessor: 'source',
        Cell: ({ row: { original } }) => {
          const isDiscoveryLead = original.source === 'discovery';
          return isDiscoveryLead ? (
            <Image
              alt={t('discoveryLogo')}
              backgroundSize='contain'
              height={4}
              mr={1}
              src={Images.vitalityLogomark}
              width={4}
            />
          ) : null;
        },
      },
      {
        Header: t('name'),
        accessor: 'name',
        sortBy: 'name',
        Cell: ({ row: { original } }) => getFullName(original),
      },
      {
        Header: t('email'),
        accessor: 'user.email',
        sortBy: 'email',
      },
      {
        Header: t('mobile'),
        accessor: 'user.mobile',
        sortBy: 'mobile',
      },
      {
        Header: t('lastContacted'),
        accessor: 'lastContactedAt',
        sortBy: 'last_contacted_at',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('nextFollowUp'),
        accessor: 'nextFollowUpAt',
        sortBy: 'next_follow_up_at',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('referrer'),
        accessor: 'referredBy',
        Cell: ({ row: { original } }) =>
          getFullName(original.referredBy || undefined),
      },
      {
        Header: t('captured'),
        accessor: 'createdAt',
        sortBy: 'captured',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('bookings'),
        accessor: 'bookingsCount',
      },
      {
        Header: t('status'),
        accessor: 'status',
        Cell: ({ value }) => {
          return toCase({ value });
        },
      },
      {
        Header: t('waiverSigned'),
        accessor: 'waiver',
        Cell: ({ value }) =>
          value?.status === 'signed'
            ? getDateReadableDayMonthYear({
                date: value.signedAt,
              })
            : null,
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id, status, waiver, userTenant, source },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('viewBookings'), value: 'bookings' },
              ...(status !== 'converted'
                ? [{ label: t('convertToMember'), value: 'convert' }]
                : []),
              { label: t('generateInvoice'), value: 'invoice' },
              {
                label: t('delete'),
                value: 'delete',
                isDisabled: source === 'discovery',
                disabledText:
                  // TODO: translations
                  'This is a Discovery Lead, and should not be deleted',
              },
              { title: t('waiver') },
              ...(waiver?.status === 'signed'
                ? [
                    ...(waiver?.isDigital
                      ? [{ label: t('viewWaiver'), value: 'viewWaiver' }]
                      : []),
                    {
                      label: t('downloadWaiver'),
                      value: 'downloadWaiver',
                    },
                  ]
                : []),
              {
                label: t('assignNewWaiver'),
                value: 'assignNewWaiver',
              },
            ]}
            onClick={({ value }) =>
              onClickAction({
                action: value,
                id,
                userTenantId: userTenant?.id,
                waiverId: waiver?.id,
              })
            }
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => (query.status ? ['status'] : []),
    [query.status]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'createdOn',
        desc: true,
      },
    ],
    []
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
      sortBy={sortBy}
    />
  );
};
