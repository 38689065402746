import { Box, Button, Image, Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getFullName,
  getResult,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        accessor: 'wodCapture',
        Cell: (
          { value: { user } } // TODO: userTenant
        ) => (
          <Box isFlex alignItems='center' height={16}>
            <Image
              hasRadius
              usePlaceholderUser
              mr={2}
              size={9}
              src={user.image}
            />

            <Text
              as='span'
              onClick={
                canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
                  ? () => setMemberModal({ userTenantId: user?.userTenant?.id })
                  : null
              }
            >
              {getFullName(user)}
            </Text>
          </Box>
        ),
      },
      {
        Header: t('benchmark'),
        accessor: 'wodCapture',
        id: 'name',
        Cell: ({ value }) => value?.workout?.name,
      },
      {
        Header: t('result'),
        id: 'score',
        Cell: ({
          row: {
            original: { exercise, score, isRx },
          },
        }) => (
          <Text as='span'>
            {/* TODO: use / update the getResult fucntion */}
            {score}
            {getResult({
              measureId: exercise?.measuringUnit?.id,
              score,
              isRx,
            })}
          </Text>
        ),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Box isFlex>
            <Button
              minWidth={undefined}
              mr={2}
              scale='small'
              text={t('approve')}
              onClick={() => onClickAction({ action: 'approve', id })}
            />

            <Button
              hasBorder
              color='danger'
              minWidth={undefined}
              scale='small'
              text={t('reject')}
              onClick={() => onClickAction({ action: 'reject', id })}
            />
          </Box>
        ),
      },
    ],
    [onClickAction, setMemberModal, hasAccess, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
