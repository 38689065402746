import { Table } from 'octiv-components';
import {
  getDateDayMonthYearExpanded,
  getEventString,
  getFullName,
  toggleAllRowsSelected,
  toggleRowSelected,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setSelected, ...props }) => {
  const { t } = useTranslation();

  // Set a row to selected
  const setRowSelected = ({ id }) => {
    setSelected(toggleRowSelected({ data, id }));
  };

  // Set all rows selected
  const setAllRowsSelected = ({ isAllSelected }) => {
    setSelected(toggleAllRowsSelected({ data, isAllSelected }));
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'date',
        Cell: ({ value }) => getDateDayMonthYearExpanded({ date: value }),
      },
      {
        Header: t('class'),
        id: 'class',
        Cell: ({
          row: {
            original: { name, startTime, endTime },
          },
        }) => getEventString({ name, startTime, endTime }),
      },
      {
        Header: t('trainer'),
        accessor: 'instructor',
        Cell: ({ value }) =>
          value?.name || value?.user ? getFullName(value) : value,
      },
      {
        Header: t('bookings'),
        id: 'bookings',
        Cell: ({
          row: {
            original: { bookingsCount, limit, waitingListCount },
          },
        }) =>
          `${bookingsCount}/${limit}${
            waitingListCount > 0 ? ` + ${waitingListCount} WL` : ''
          }`,
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      onSelect={setRowSelected}
      onSelectAll={setAllRowsSelected}
    />
  );
};
