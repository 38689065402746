import { Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
  toCapital,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();
  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('code'),
        accessor: 'code',
        sortBy: 'code',
      },
      {
        Header: t('member'),
        accessor: 'user',
        sortBy: 'name',
        Cell: ({
          row: {
            original: { user, leadMember },
          },
        }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
                ? () => setMemberModal({ userTenantId: user?.userTenant?.id })
                : null
            }
          >
            {getFullName(leadMember || user)}
          </Text>
        ),
      },
      {
        Header: t('description'),
        accessor: 'description',
        sortBy: 'description',
        whiteSpaceWrap: true,
      },
      {
        Header: t('status'),
        accessor: 'status',
        sortBy: 'status',
        Cell: ({ value }) => toCapital({ value }),
      },
      {
        Header: t('dueDate'),
        accessor: 'dueOn',
        sortBy: 'dueOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        sortBy: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: { id },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
              { label: t('download'), value: 'download' },
              { label: t('send'), value: 'send' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, toCurrency, setMemberModal, hasAccess, t]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'dueOn',
        desc: true,
      },
    ],
    []
  );

  return (
    <Table {...props} columns={tableColumns} data={tableData} sortBy={sortBy} />
  );
};
