import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingExerciseCategories,
  exerciseCategories,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        isActive: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='isActive'
                options={[
                  { label: t('active'), value: true },
                  { label: t('deactivated'), value: false },
                ]}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
