import { Alert, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useToggle } from 'octiv-hooks';
import {
  useLocationPaymentGatewaysFindDebitOrderSepaDetails,
  useLocationPaymentGatewaysUpdateDebitOrderSepaDetails,
} from 'octiv-hooks/requests/LocationPaymentGateways';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Form from './Form';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const {
    tenant: { locations },
  } = useActiveUserTenant();

  const [toggle, setToggle, resetToggle] = useToggle();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useLocationPaymentGatewaysFindDebitOrderSepaDetails({
    include: 'location', // Not working so we're matching the locationId to the active user tenant's locations
  });

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useLocationPaymentGatewaysUpdateDebitOrderSepaDetails({
      onSuccess: () => {
        getSettingsRequest();
        resetToggle();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onFormSubmit = (values) =>
    putSettingsRequest({
      settingsId: toggle.data.locationPaymentGatewayId,
      ...values,
    });

  const hasNotOnboarded = !!settings?.data?.find(
    (item) =>
      item?.settings?.creditorId === null || item?.settings?.creditorId === ''
  );

  return (
    <>
      {toggle.isVisible && (
        <Modal isSidebar title={`SEPA ${t('details')}`} onClose={resetToggle}>
          <Form
            initialValues={toggle.data}
            isLoading={isUpdatingSettings}
            onSubmit={onFormSubmit}
          />
        </Modal>
      )}

      <Setting title='SEPA'>
        {hasNotOnboarded && (
          <Alert
            mb={4}
            subtitle={t('locationNotOnboardedTo', {
              paymentGateway: 'SEPA',
            })}
            variant='danger'
          />
        )}

        <Table
          hasAlternateBackground
          data={settings || []}
          isLoading={isFetchingSettings}
          locations={locations}
          onClickAction={({ id }) =>
            setToggle({
              type: 'update',
              data: settings?.find((item) => item?.settings?.id === id)
                .settings,
            })
          }
        />
      </Setting>
    </>
  );
};
