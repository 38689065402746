import { Table, Text, Tooltip } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setInvoices, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();
  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('code'),
        accessor: 'invoice.code',
      },
      {
        Header: t('member'),
        accessor: 'invoice.user',
        Cell: ({ value }) =>
          value ? (
            <Text
              as='span'
              onClick={
                canSetMemberModal({ userTenant: value?.userTenant, hasAccess })
                  ? () =>
                      setMemberModal({ userTenantId: value?.userTenant?.id })
                  : null
              }
            >
              {getFullName(value)}
            </Text>
          ) : (
            ''
          ),
      },
      {
        Header: t('date'),
        accessor: 'dateTime',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) => t(value),
      },
      {
        Header: t('processors'),
        accessor: 'tags',
        Cell: ({ value }) => value?.map((tag) => tag.name).join(', ') || '',
      },
      {
        Header: t('amount'),
        accessor: 'amount',
        Cell: ({ value }) => toCurrency({ value }),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({ value: id }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              { label: t('delete'), value: 'delete' },
            ]}
            placement='left'
            onClick={({ value }) => onClickAction({ action: value, id })}
          />
        ),
      },
    ],
    [onClickAction, toCurrency, setMemberModal, hasAccess, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
