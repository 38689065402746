import { Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getResult,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('exercise'),
        accessor: 'exercise',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('type'),
        accessor: 'type',
        Cell: ({ value }) =>
          value === 'benchmarkExercise'
            ? t('benchmarkExercise')
            : value === 'wodExercise'
            ? t('programmedExercise')
            : value === 'personalExercise'
            ? t('personalExercise')
            : null,
      },
      {
        Header: t('result'),
        id: 'result',
        Cell: ({
          row: {
            original: { exercise, score, isRx },
          },
        }) => (
          <Text as='span'>
            {getResult({
              measureId: exercise.measuringUnit.id,
              score,
              isRx,
            })}
          </Text>
        ),
      },
      {
        Header: t('date'),
        accessor: 'date',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
