import { Container, Divider } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging } from 'octiv-hooks';
import {
  useMandatesCreateSendOnboardingLink,
  useMandatesFind,
  useMandatesUpdate,
} from 'octiv-hooks/requests/Mandates';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    status: undefined,
    isDebitOrderMembersOnly: 1,
  });

  const [paging, setPaging] = usePaging();

  const mandatesFind = useMandatesFind({
    filter: query,
    paging,
  });

  const {
    isLoading: isCreatingMandateSendOnboardingLink,
    mutate: postMandateSendOnboardingLinkRequest,
  } = useMandatesCreateSendOnboardingLink({
    // onSuccess: mandatesFind.refetch,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isLoading: isUpdatingMandate, mutate: putMandateRequest } =
    useMandatesUpdate({
      // onSuccess: mandatesFind.refetch,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'sendLink':
        postMandateSendOnboardingLinkRequest({ userIds: [id] });
        break;

      case 'cancel':
        if (window.confirm(t('areYouSureProceed'))) {
          putMandateRequest({ id, status: 'cancelled' });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('mandates'),
        breadcrumbs: [t('accounts')],
      }}
      isLoading={
        mandatesFind.isFetching ||
        isCreatingMandateSendOnboardingLink ||
        isUpdatingMandate
      }
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      <Table
        data={mandatesFind.data?.data || []}
        isLoading={mandatesFind.isFetching}
        paging={mandatesFind.data?.paging}
        setPaging={setPaging}
        onClickAction={onClickAction}
      />
    </Container>
  );
};
