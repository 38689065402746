import { InlineUser, Table, Text } from 'octiv-components';
import { getEventString } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const getCounts = (row) => {
    // NOTE: This will no longer work if backend keys change
    const keys = [
      'hasBeenInContactExperiencing',
      'hasBeenInContactPositive',
      'hasCough',
      'hasDifficultyBreathing',
      'hasFever',
      'hasTravelled',
    ];

    let yesCount = 0;
    keys.forEach((key) => {
      yesCount += row?.[key] || 0;
    });

    const noCount = keys.length - yesCount;

    return {
      yesCount,
      noCount,
    };
  };

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        Cell: ({ row: { original } }) => (
          <InlineUser isVisibleOverdue={false} {...original} />
        ),
      },
      {
        Header: t('class'),
        accessor: 'classDate',
        Cell: ({ value }) => getEventString(value),
      },
      {
        Header: t('temperature'),
        id: 'temp',
        accessor: 'coronavirusQuestionaireResult.temperature',
        Cell: ({ value }) => value || null,
      },
      {
        Header: t('yesCount'),
        id: 'yes',
        // accessor: 'coronavirusQuestionnaireResults',
        Cell: ({ row: { original } }) =>
          // value ? value.yesNoCount.yesCount : null,
          getCounts(original?.coronavirusQuestionaireResult).yesCount,
      },
      {
        Header: t('noCount'),
        id: 'no',
        accessor: 'coronavirusQuestionnaireResults',
        Cell: ({ row: { original } }) => {
          // (value ? value.yesNoCount.noCount : null),
          return getCounts(original?.coronavirusQuestionaireResult).noCount;
        },
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: {
              id,
              // class: classBooking,
              coronavirusQuestionaireResult,
            },
          },
        }) => {
          // TODO: check whether a user has results
          const hasResult = coronavirusQuestionaireResult !== null;

          return (
            <Text
              as='span'
              color='primary'
              onClick={() =>
                onClickAction({
                  type: hasResult ? 'update' : 'create',
                  data: hasResult
                    ? { id, ...coronavirusQuestionaireResult }
                    : { classBookingId: id },
                })
              }
            >
              {hasResult ? t('view') : t('capture')}
            </Text>
          );
        },
      },
    ],
    [onClickAction, t]
  );

  const hiddenColumns = useMemo(
    () => [...(query.classDateId ? ['class'] : [])],
    [query.classDateId]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      hiddenColumns={hiddenColumns}
    />
  );
};
