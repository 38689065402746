import { Card } from 'octiv-components';
import { useReportsFindRegionsMetrics } from 'octiv-hooks/requests/Reports';
import React from 'react';

import TableRegions from './TableRegions';

export default ({ query }) => {
  const { data: regionMetrics, isFetching: isFetchingRegionMetrics } =
    useReportsFindRegionsMetrics({
      filter: query,
    });

  return (
    <Card mt={4} title='Region Metrics'>
      <TableRegions data={regionMetrics} isLoading={isFetchingRegionMetrics} />
    </Card>
  );
};
