import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useWorkoutResultExercisesFind = (input, options) => {
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['workoutResultExercises', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/wod-capture-exercises`,
        params: {
          filter: {
            userId: filter.userId,
            wodId: filter.workoutId,
            exerciseId: filter.exerciseId,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useWorkoutResultExercisesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/wod-capture-exercises`,
        data: {
          wodId: data.workoutId,
          exerciseId: data.exerciseId,
          userId: data.userId,
          score: data.score,
          isRx: data.isRx,
          note: data.note,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workoutResultExercises'] });
      queryClient.invalidateQueries({ queryKey: ['workoutResults'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutResultExercisesFindUnvalidatedBenchmarks = (
  input,
  options
) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['workoutResultExercises', 'findUnvalidatedBenchmarks', input],
    () =>
      request({
        method: 'get',
        url: `/api/wod-capture-exercises`,
        params: {
          filter: {
            tenantId,
            userId: filter.userId,
            exerciseId: filter.exerciseId,
            unverifiedBenchmarks: 1,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useWorkoutResultExercisesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['workoutResultExercises', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/wod-capture-exercises/${id}`,
      }),
    options
  );
};

export const useWorkoutResultExercisesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/wod-capture-exercises/${id}`,
        data: { score: data.score, isRx: data.isRx, note: data.note },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workoutResultExercises'] });
      queryClient.invalidateQueries({ queryKey: ['workoutResults'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutResultExercisesUpdateApproveBenchmark = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/wod-capture-exercises/${id}/approve`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workoutResultExercises'] });
      queryClient.invalidateQueries({ queryKey: ['workoutResults'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutResultExercisesUpdateApproveAllBenchmarks = (
  options
) => {
  const { tenantId } = useActiveUserTenant();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/wod-capture-exercises/approve-all`,
        data: {
          userId: data.userId,
          exerciseId: data.exerciseId,
          tenantId: data.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workoutResultExercises'] });
      queryClient.invalidateQueries({ queryKey: ['workoutResults'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useWorkoutResultExercisesUpdateRejectBenchmark = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/wod-capture-exercises/${id}/reject`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['workoutResultExercises'] });
      queryClient.invalidateQueries({ queryKey: ['workoutResults'] });

      options?.onSuccess?.(...res);
    },
  });
};
