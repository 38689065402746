import moment from 'moment';
import { Box, BulkActions, Container, Modal, SendComm } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useFinancesCreatePaymentsBulkCreate,
  useFinancesCreateUserInvoicesBulkReverse,
  useFinancesDeleteUserInvoices,
} from 'octiv-hooks/requests/Finances';
import { useReportsFindFinanceDebtors } from 'octiv-hooks/requests/Reports';
import {
  getDateYearMonthDay,
  getSelectedRows,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountsInvoice } from '../../../accounts';
import FormRecordPayment from '../../../accounts/invoices/FormRecordPayment';
import FormQuery from './FormQuery';
import TableReport from './TableReport';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();

  const [paging, setPaging] = usePaging();
  const [toggleInvoice, setToggleInvoice, resetToggleInvoice] = useToggle();
  const [
    toggleFormRecordPayment,
    setToggleFormRecordPayment,
    resetToggleFormRecordPayment,
  ] = useToggle();

  const [report, setReport] = useState([]);
  const selectedData = getSelectedRows({ data: report });
  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ date: moment().subtract(1, 'month') }),
    endDate: getDateYearMonthDay({ canFallback: true }),
  });

  const {
    data: reportData,
    isFetching: isFetchingReport,
    refetch: getReportRequest,
  } = useReportsFindFinanceDebtors(
    {
      filter: query,
      paging,
    },
    {
      onSuccess: (response) => {
        setReport(response.data);
      },
    }
  );

  const { isFetching: isDeletingInvoice, mutate: deleteInvoiceRequest } =
    useFinancesDeleteUserInvoices({
      onSuccess: getReportRequest,
      meta: {
        useSuccessToast: true,
      },
    });

  const {
    isFetching: isCreatingBulkRecordPayment,
    mutate: postBulkRecordPaymentRequest,
  } = useFinancesCreatePaymentsBulkCreate({
    onSuccess: () => {
      getReportRequest();
      resetToggleFormRecordPayment();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isCreatingBulkInvoiceDeleteReverse,
    mutate: postBulkInvoiceDeleteReverseRequest,
  } = useFinancesCreateUserInvoicesBulkReverse({
    onSuccess: getReportRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onBulkAction = ({ value }) => {
    const data = selectedData.map((item) => item.invoiceId);

    switch (value) {
      case 'record':
        setToggleFormRecordPayment({
          data: { invoiceIds: data },
        });
        break;

      case 'deleteReverse':
        if (window.confirm(t('invoiceDeleteReverseConfirmation'))) {
          postBulkInvoiceDeleteReverseRequest({ invoiceIds: data });
        }
        break;

      default:
        break;
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'view':
        setToggleInvoice({
          type: 'view',
          data: { invoiceId: id },
        });
        break;

      case 'record':
        setToggleInvoice({
          type: 'payment',
          data: { invoiceId: id },
        });
        break;

      case 'edit':
        setToggleInvoice({
          type: 'form',
          data: { invoiceId: id },
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteInvoiceRequest({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleInvoice.isVisible && (
        <AccountsInvoice
          initialView={toggleInvoice.type}
          invoiceId={toggleInvoice.data?.invoiceId}
          onClose={() => {
            getReportRequest();
            resetToggleInvoice();
          }}
        />
      )}

      {toggleFormRecordPayment.isVisible && (
        <Modal
          isSidebar
          title={t('recordPayment')}
          onClose={resetToggleFormRecordPayment}
        >
          <FormRecordPayment
            initialValues={toggleFormRecordPayment.data}
            isLoading={isCreatingBulkRecordPayment}
            onSubmit={(values) => postBulkRecordPaymentRequest(values)}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('debtors'),
          breadcrumbs: [t('reports'), t('finance')],
          children: (
            <SendComm members={!isFetchingReport && report} ml='auto' />
          ),
        }}
        isLoading={
          isFetchingReport ||
          isDeletingInvoice ||
          isCreatingBulkInvoiceDeleteReverse
        }
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptionsAll}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <BulkActions
          data={selectedData}
          mb={4}
          options={[
            {
              icon: 'receipt',
              title: t('recordPayment'),
              value: 'record',
            },
            {
              icon: 'delete',
              title: t('deleteReverseInvoice'),
              value: 'deleteReverse',
              color: 'danger',
            },
          ]}
          onClick={onBulkAction}
        />

        <TableReport
          data={report || []}
          isLoading={isFetchingReport}
          paging={reportData?.paging}
          setPaging={setPaging}
          setSelected={setReport}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
