import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getFullName, initialiseUser } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const getInitialValues = (initialValues = {}) => {
  const { memberId, programmeId, assignedUserId, notes } =
    initialiseUser(initialValues);

  const locationId = initialValues.locations?.[0]?.id;

  return {
    memberDetails: { memberId, programmeId, locationId, assignedUserId, notes },
  };
};

export const getValidationSchema = ({ t } = {}) => ({
  memberDetails: Yup.object().shape({
    memberId: Yup.string(),
    programmeId: Yup.string().required(t('required')),
    locationId: Yup.string().required(t('required')),
    assignedUserId: Yup.string(),
    notes: Yup.string(),
  }),
});

export const renderFormFields = ({
  t,
  isDisplayLocation = true,
  isFetchingProgrammes,
  programmes,
  isFetchingStaff,
  locationOptions,
  staff,
}) => (
  <Row>
    <Col lg={3} md={4}>
      <Field label={t('memberId')} name='memberDetails.memberId' />
    </Col>

    {isDisplayLocation && (
      <Col lg={3} md={4}>
        <Field
          isSelect
          label={t('primaryLocation')}
          name='memberDetails.locationId'
          options={locationOptions}
        />
      </Col>
    )}

    <Col lg={3} md={4}>
      <Field
        isSelect
        isLoading={isFetchingProgrammes}
        label={t('programme')}
        name='memberDetails.programmeId'
        options={programmes?.map((item) => ({
          label: item.name,
          value: item?.id,
        }))}
      />
    </Col>

    <Col lg={3} md={4}>
      <Field
        isSelect
        isLoading={isFetchingStaff}
        label={t('assignedStaffMember')}
        name='memberDetails.assignedUserId'
        options={[
          { label: t('noAssignedStaffMember'), value: undefined },
          ...staff.map((item) => ({
            label: getFullName(item),
            value: item?.id,
          })),
        ]}
      />
    </Col>

    <Col>
      <Field as='textarea' label={t('notes')} name='memberDetails.notes' />
    </Col>
  </Row>
);

export default ({
  isFetchingProgrammes,
  initialValues,
  programmes,
  isFetchingStaff,
  isLoading,
  locationOptions,
  staff,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={getInitialValues(initialValues)}
      validationSchema={Yup.object().shape(
        getValidationSchema({ t, hasUserDetails: false })
      )}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          {renderFormFields({
            t,
            isFetchingProgrammes,
            programmes,
            isFetchingStaff,
            locationOptions,
            staff,
          })}

          <Button
            isDisabled={!dirty}
            isLoading={isLoading}
            mt={4}
            text={t('update')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
