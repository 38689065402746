import _ from 'lodash';
import { Images } from 'octiv-assets';
import {
  Box,
  Icon,
  Image,
  ProcessingBar,
  Text,
  Workout,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useWorkoutsFindById } from 'octiv-hooks/requests/Workouts';
import { measures } from 'octiv-utilities/Constants';
import {
  getDateReadableDayMonthYear,
  getExercisePart,
  sanitizeWorkout,
} from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { StyledNavButton } from './Styled';

export default () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const theme = useContext(ThemeContext);
  const {
    tenant: { logo },
  } = useActiveUserTenant();
  const [
    { workoutName, workoutParts, currentPartKey, nextPartName, prevPartName },
    setWorkout,
  ] = useState({
    workoutName: undefined,
    workoutParts: [],
    currentPartKey: undefined,
    nextPartName: undefined,
    prevPartName: undefined,
  });

  const setCurrentPart = ({ prev = false, next = false } = {}) => {
    setWorkout((prevData) => {
      let newKey;

      if (
        (prev && currentPartKey === undefined) ||
        (next && currentPartKey === prevData.workoutParts.length - 1)
      ) {
        return prevData;
      }

      if (prev) {
        newKey = currentPartKey === 0 ? undefined : currentPartKey - 1;
      } else if (next) {
        newKey = currentPartKey === undefined ? 0 : currentPartKey + 1;
      }

      return {
        ...prevData,
        currentPartKey: newKey,
        prevPartName:
          newKey === 0
            ? t('summary')
            : newKey > 0
            ? prevData.workoutParts[newKey - 1].name
            : undefined,
        nextPartName:
          newKey === undefined
            ? prevData.workoutParts[0].name
            : newKey !== prevData.workoutParts.length - 1
            ? prevData.workoutParts[newKey + 1].name
            : undefined,
      };
    });
  };

  const handleKeyPress = ({ keyCode }) => {
    if (!_.isEmpty(workoutParts)) {
      if (keyCode === 37) setCurrentPart({ prev: true });
      if (keyCode === 39) setCurrentPart({ next: true });
    }
  };

  // Listen for keydown events
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  });

  const sanitizeWorkoutData = (data) => {
    const workout = sanitizeWorkout({ workout: data });

    workout.name =
      workout.name ||
      `${workout.programme.name} - ${getDateReadableDayMonthYear({
        canFallback: true,
      })}`;

    const newWorkoutParts = [];

    if (workout.warmUp) {
      newWorkoutParts.push({
        name: t('warmUp'),
        description: workout.warmUp,
      });
    }

    if (workout.exercises) {
      newWorkoutParts.push(
        ...workout.exercises.map((exercise, index) => ({
          name: `${t('part')} ${
            exercise.prefix ? exercise.prefix : getExercisePart({ index })
          }${exercise.name ? `: ${exercise.name}` : ''}`,
          description: exercise.description,
          measure: `${t('measure')}: ${
            measures.find(
              (measure) => measure.measureId === exercise.measuringUnit?.id
            )?.description
          }`,
        }))
      );
    }

    if (workout.coolDown) {
      newWorkoutParts.push({
        name: t('coolDown'),
        description: workout.coolDown,
      });
    }

    setWorkout((prev) => ({
      ...prev,
      workoutName: workout.name,
      workoutParts: newWorkoutParts,
    }));

    setCurrentPart();
  };

  const {
    data: workout,
    isFetching,
    error,
  } = useWorkoutsFindById(
    { id },
    {
      enabled: id !== null,
      canShowError: false,
      onSuccess: sanitizeWorkoutData,
    }
  );

  const renderNavigation = () => {
    return (
      <Box
        hasRadiusBottom
        isFlex
        alignItems='center'
        bg='primary'
        justifyContent='space-between'
        left={0}
        minHeight='4.75rem'
        position='fixed'
        px={6}
        right={0}
        top={0}
      >
        <Text
          isBold
          color='fontInverse'
          fontSize={['2rem', '2.75rem']}
          lineHeight={['2rem', '2.75rem']}
        >
          {workoutName}
        </Text>

        <Box isFlex>
          {prevPartName && (
            <StyledNavButton onClick={() => setCurrentPart({ prev: true })}>
              <Icon color='fontInverse' name='arrow_back' scale='medium' />
              <Text
                isBold
                color='fontInverse'
                fontSize='1.75rem'
                lineHeight='1.75rem'
                ml={2}
              >
                {prevPartName}
              </Text>
            </StyledNavButton>
          )}

          {nextPartName && (
            <StyledNavButton onClick={() => setCurrentPart({ next: true })}>
              <Text
                isBold
                color='fontInverse'
                fontSize='1.75rem'
                lineHeight='1.75rem'
                mr={2}
              >
                {nextPartName}
              </Text>
              <Icon color='fontInverse' name='arrow_forward' scale='medium' />
            </StyledNavButton>
          )}
        </Box>
      </Box>
    );
  };

  const renderPart = () => {
    const { name, measure, description } = workoutParts[currentPartKey];

    return (
      <Box justifyContent='center'>
        {name && (
          <Text isBold color='primary' fontSize='4rem' lineHeight='4.25rem'>
            {name}
          </Text>
        )}

        {measure && (
          <Text isBold color='grey2' fontSize='3rem' lineHeight='3.5rem'>
            {measure}
          </Text>
        )}

        {description && (
          <Text
            isBold
            fontSize='3.5rem'
            lineHeight='4rem'
            maxHeight='100%'
            mt={2}
            whiteSpace='pre-line'
          >
            {description}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <>
      {isFetching ? (
        <ProcessingBar />
      ) : error || _.isEmpty(workoutParts) ? (
        <Box
          isFlex
          alignItems='center'
          height='100vh'
          justifyContent='center'
          width='100%'
        >
          <Text textAlign='center' variant='heading'>
            {error && error.status === 404 ? t('workoutNotFound') : t('noData')}
          </Text>
        </Box>
      ) : (
        <>
          <Box
            isFlex
            alignItems='center'
            flex={1}
            minHeight='calc(100vh - 4.75rem)'
            pb='2rem'
            pt='6rem'
            px={6}
          >
            {currentPartKey === undefined ? (
              <Workout
                hasLargeSpacing
                bg={undefined}
                data={sanitizeWorkout({ workout })}
                showMemberNotes={false}
                showName={false}
                showProgramme={false}
                showTrainerNotes={false}
                textProps={{ fontSize: '2.5rem', lineHeight: '3rem' }}
              />
            ) : (
              renderPart()
            )}
          </Box>

          {renderNavigation()}
        </>
      )}

      <Box
        hasRadius
        isFlex
        alignItems='center'
        bg='grey6'
        bottom={4}
        p={2}
        position='fixed'
        right={8}
      >
        {logo && (
          <Image hasRadius alt={t('logo')} mr={2} size={15} src={logo} />
        )}

        <Image
          hasRadius
          alt={t('octivLogo')}
          backgroundSize='contain'
          height={10}
          src={theme.isDark ? Images.logoLight : Images.logoDark}
          width={34}
        />
      </Box>
    </>
  );
};
