import UserMergeAccounts from 'octiv-containers/user/mergeAccounts';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { getStacksPublic } from './Public';

export const getStacksMergeAccounts = ({
  t,
  onRedirectPath,
  onLoginPath,
} = {}) => [
  {
    options: {
      isVisibleInNavigation: false,
      isVisibleNavigation: false,
      isSecure: false,
    },
    title: t('mergeAccounts'),
    screens: [
      {
        name: 'UserMergeAccounts',
        path: '/user/merge-accounts',
        element: <UserMergeAccounts />,
      },
    ],
  },

  ...getStacksPublic({ onRedirectPath: '/user/merge-accounts', onLoginPath }),

  {
    options: {
      isVisibleInNavigation: false,
      isSecure: false,
    },
    screens: [
      {
        name: 'BaseUrl',
        path: '/',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
      {
        name: 'NotFound',
        path: '*',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
    ],
  },
];
