import { Box, Button, Container, Divider, Modal } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useClassesFind,
  useClassesFindById,
} from 'octiv-hooks/requests/Classes';
import {
  useClassRecurringBookingsCreate,
  useClassRecurringBookingsFind,
  useClassRecurringBookingsUpdate,
  useClassRecurringBookingsUpdateDeactivate,
  useClassRecurringBookingsUpdateReActivate,
} from 'octiv-hooks/requests/ClassRecurringBookings';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import FormRecurringBooking from './FormRecurringBooking';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const {
    activeUserTenantId,
    isTrainer,
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [paging, setPaging] = usePaging();
  const [toggle, setToggle, resetToggle] = useToggle();
  const [query, setQuery] = useState({
    status: 1,
    locationId: selectedLocation?.id,
  });
  const [queryEvents, setQueryEvents] = useState({
    locationId: undefined,
  });
  const [classId, setClassId] = useState(null);
  const [queryMembers, setQueryMembers] = useState({
    userTenantLocationId: selectedLocation?.id,
    isMinimalData: true,
  });

  const {
    data: recurringBookings,
    isFetching: isFetchingRecurringBookings,
    refetch: getRecurringBookingsRequest,
  } = useClassRecurringBookingsFind({
    filter: {
      ...query,
      ...(isTrainer
        ? { trainerUserTenantId: activeUserTenantId, isSession: true }
        : {}),
    },
    include: 'daysOfWeek',
    paging,
  });

  const {
    isLoading: isCreatingRecurringBooking,
    mutate: postRecurringBookingRequest,
  } = useClassRecurringBookingsCreate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      getRecurringBookingsRequest();
      resetToggle();
    },
  });

  const {
    isLoading: isUpdatingRecurringBooking,
    mutate: putRecurringBookingRequest,
  } = useClassRecurringBookingsUpdate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      getRecurringBookingsRequest();
      resetToggle();
    },
  });

  const {
    isLoading: isUpdatingRecurringBookingActivate,
    mutate: putRecurringBookingActivateRequest,
  } = useClassRecurringBookingsUpdateReActivate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      getRecurringBookingsRequest();
      resetToggle();
    },
  });

  const {
    isLoading: isUpdatingRecurringBookingDeactivate,
    mutate: putRecurringBookingDeactivateRequest,
  } = useClassRecurringBookingsUpdateDeactivate({
    useOnSuccessToast: true,
    onSuccess: () => {
      getRecurringBookingsRequest();
      resetToggle();
    },
  });

  const { data: events, isFetching: isFetchingEvents } = useClassesFind(
    {
      filter: {
        classTypeId: 2,
        isActive: 1,
        locationId: queryEvents.locationId,
        ...(isTrainer
          ? { trainerUserTenantId: activeUserTenantId, isSession: true }
          : {}),
      },
      paging: { perPage: -1 },
      include: 'daysOfWeek',
    },
    {
      enabled:
        toggle.type === 'create' && !classId && !!queryEvents?.locationId,
    }
  );

  const { data: event, isFetching: isFetchingEvent } = useClassesFindById(
    {
      id: classId,
    },
    {
      enabled: !!classId,
    }
  );

  const onGetEventsRequest = ({ locationId: location }) => {
    setQueryEvents({
      classTypeId: 2,
      locationId: location,
      ...(isTrainer
        ? { trainerUserTenantId: activeUserTenantId, isSession: true }
        : {}),
    });
  };

  const { data: members, isFetching: isFetchingMembers } = useUsersFindMembers(
    {
      filter: queryMembers,
      paging: { perPage: -1 },
    },
    {
      enabled: !!queryMembers?.userTenantLocationId,
    }
  );

  const onGetMembersRequest = ({ locationId, classId: classIdValue }) => {
    setQueryMembers({
      userTenantLocationId: locationId,
      isMinimalData: true,
      packageIds: events?.data
        ?.find((item) => item?.id === classIdValue)
        .packages?.map((item) => item?.id)
        ?.join(','),
    });
  };

  const onSubmitFormRecurringBooking = (values) => {
    if (toggle.type === 'create') {
      postRecurringBookingRequest(values);
    } else {
      putRecurringBookingRequest({ id: toggle.data?.id, ...values });
    }
  };

  const onClickAction = ({ action, id, classId: classIdValue }) => {
    switch (action) {
      case 'edit':
        setClassId(classIdValue);
        setToggle({
          type: 'update',
          data: recurringBookings?.data?.find((item) => item?.id === id),
        });
        break;

      case 'activate':
        putRecurringBookingActivateRequest({ id });
        break;

      case 'deactivate':
        putRecurringBookingDeactivateRequest({ id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('recurringBooking')} onClose={resetToggle}>
          <FormRecurringBooking
            event={event}
            events={events?.data || []}
            initialValues={toggle.data}
            isCreate={toggle.type === 'create'}
            isFetchingEvent={isFetchingEvent}
            isFetchingEvents={isFetchingEvents}
            isFetchingMembers={isFetchingMembers}
            isLoading={isCreatingRecurringBooking || isUpdatingRecurringBooking}
            locationOptions={locationOptions}
            members={members?.data || []}
            onGetEventsRequest={onGetEventsRequest}
            onGetMembersRequest={onGetMembersRequest}
            onSubmit={onSubmitFormRecurringBooking}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('recurringBookings'),
          breadcrumbs: [t('scheduling')],
          children: (
            <Box ml='auto'>
              <Button
                text={t('createNew')}
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={
          isFetchingRecurringBookings ||
          isUpdatingRecurringBookingActivate ||
          isUpdatingRecurringBookingDeactivate ||
          isFetchingEvent
        }
      >
        <FormQuery
          initialValues={query}
          locationOptions={locationOptions}
          onSubmit={setQuery}
        />

        <Divider pb={4} />

        <Table
          data={isFetchingRecurringBookings ? [] : recurringBookings?.data}
          events={events?.data || []}
          isLoading={isFetchingRecurringBookings}
          paging={recurringBookings?.paging}
          query={query}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Container>
    </>
  );
};
