import { Box, Container, Text, ToggleTabs } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import members from './Members';
import staff from './Staff';

const Changelog = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const { isMember } = useActiveUserTenant();

  const hasHiddenNavigation =
    location.pathname === '/changelog-hidden-navigation';

  const renderChangelog = ({ data, hasBorder = true }) => (
    <Box
      {...(hasBorder
        ? {
            hasRadiusBottom: true,
            border: [1],
            borderColor: 'grey6',
            p: 4,
          }
        : {})}
    >
      {data.map(({ date, content }, index) => (
        <Box key={date} mt={index > 0 ? 8 : 0}>
          <Text isBold variant='heading'>
            {getDateReadableDayMonthYear({ date })}
          </Text>

          <Box bg='grey5' height='1px' mt={2} />

          {content.map(({ title, body, bullets }) => (
            <React.Fragment key={title}>
              {title && (
                <Text mb={1} mt={3} variant='subheading'>
                  {title}
                </Text>
              )}

              {body && <Text color='grey1'>{body}</Text>}

              {bullets && (
                <Text as='span' color='grey1'>
                  <ul>
                    {bullets.map((bullet) => (
                      <li key={bullet}>{bullet}</li>
                    ))}
                  </ul>
                </Text>
              )}
            </React.Fragment>
          ))}
        </Box>
      ))}
    </Box>
  );

  return (
    <Container
      appBarProps={
        hasHiddenNavigation
          ? undefined
          : {
              title: t('changelog'),
              subtitle: t('stayUpToDate'),
            }
      }
      {...(hasHiddenNavigation ? { pt: 4 } : {})}
    >
      {isMember ? (
        renderChangelog({ data: members, hasBorder: false })
      ) : (
        <ToggleTabs
          hasRadiusTop
          bg='grey6'
          options={[
            {
              label: t('staff'),
              children: renderChangelog({ data: staff }),
            },
            {
              label: t('members'),
              children: renderChangelog({ data: members }),
            },
          ]}
        />
      )}
    </Container>
  );
};

export default Changelog;
