import { Alert, ProcessingBar, Text, Toast } from 'octiv-components';
import { usePasswordCreateForget } from 'octiv-hooks/requests/Password';
import { useUsersFindValidate } from 'octiv-hooks/requests/Users';
import { getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Form from './Form';

export default ({
  user,
  setUser,
  isPublicContext,
  tenantId,
  skipHasLeadUserTenantCheck = false,
}) => {
  const isUser = !!user?.id;

  const navigate = useNavigate();
  const [submittedValues, setSubmittedValues] = React.useState();

  const usersFindValidate = useUsersFindValidate(
    {
      ...submittedValues,
      tenantId,
    },
    {
      enabled: !!submittedValues?.email,
      retry: false,
      refetchOnWindowFocus: false,
      meta: {
        useOnErrorToast: false,
      },
      onSuccess: (response) => {
        if (!response?.email) {
          setUser({ email: submittedValues.email });
          return;
        }

        if (response.hasStaffUserTenant || response.hasMemberUserTenant) {
          toast.error(
            <Toast
              body={`This user already has a ${
                response.hasStaffUserTenant ? 'staff' : 'member'
              } account associated with this facility. Please use a different email address.`}
              variant='danger'
            />
          );
          return;
        }

        if (response.hasLeadUserTenant && !skipHasLeadUserTenantCheck) {
          if (
            window.confirm(
              'This user appears to be a Lead. Please go to "Leads", and convert this user.'
            )
          ) {
            navigate(`/leads/manage?search=${submittedValues.email}`);
          }
        }

        setUser(response);
      },
      onError: () => {
        // TODO: the error should be checked before disregarding it and setting the user
        setUser({
          email: submittedValues.email,
        });
      },
    }
  );

  const passwordCreateForget = usePasswordCreateForget({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onClickForgotPassword = ({ email }) => {
    if (!email) {
      window.confirm(
        'Please enter your email address and then click on Forgot Password'
      );

      return;
    }

    if (
      window.confirm(
        'An email will be sent to you with further details to reset your password'
      )
    ) {
      passwordCreateForget.mutate({ email });
    }
  };

  return (
    <>
      {(usersFindValidate.isFetching || passwordCreateForget.isLoading) && (
        <ProcessingBar />
      )}

      {!isPublicContext && (
        <Text mb={4}>Let&apos;s check if they are already an Octiv user.</Text>
      )}

      <Form
        isLoading={usersFindValidate.isFetching}
        isPublicContext={isPublicContext}
        onClickForgotPassword={onClickForgotPassword}
        onSubmit={setSubmittedValues}
      />

      {/* TODO: translations */}
      {user?.email && (
        <Alert
          mt={4}
          {...(isUser
            ? {
                title: `User: ${getFullName(user)}`,
                variant: 'success',
              }
            : isPublicContext
            ? {
                title: 'Invalid Details',
                subtitle: `The email address and/or password you provided are invalid. Please try again or click on "Forgot Password" if you don't remember your password.`,
                variant: 'info',
              }
            : {
                title: 'User Not Found',
                subtitle:
                  "You'll need to provide all of their details in order to create their account.",
                variant: 'info',
              })}
        />
      )}
    </>
  );
};
