import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, isFetchingProgrammes, programmes, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        programmeId: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingProgrammes}
                label={t('programme')}
                name='programmeId'
                options={programmes?.map?.((programme) => ({
                  label: programme.name,
                  value: programme.id,
                }))}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
