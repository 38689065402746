import { Box, Chart, Col, Row, Stats } from 'octiv-components';
import {
  useReportsFindAttendanceClassAttendanceOver24Hours,
  useReportsFindDashboardSchedulingMetrics,
} from 'octiv-hooks/requests/Reports';
import { getMonths } from 'octiv-utilities/Functions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ query }) => {
  const { t } = useTranslation();

  const [schedulingMetricsMonthly, setSchedulingMemberMetricsMonthly] =
    useState([]);

  const [attendanceOver24Hours, setAttendanceOver24Hours] = useState([]);

  const { data: schedulingMetrics, isFetching: isFetchingSchedulingMetrics } =
    useReportsFindDashboardSchedulingMetrics({
      filter: {
        ...query,
        isMonthlyBreakdown: 0,
      },
    });

  // TODO: this must be calculated against previous year as a date, not just a year
  // const { isLoading: isFetchingSchedulingMetricsPrevious } = useFetch({
  //   canRequest: query.year > 2016,
  //   ...getReportsDashboardSchedulingMetrics({
  //     ...query,
  //     year: query.year - 1,
  //     isMonthlyBreakdown: false,
  //   }),
  //   onSuccess: (response) => setSchedulingMetricsPrevious(response),
  // });

  const { isFetching: isFetchingSchedulingMetricsMonthly } =
    useReportsFindDashboardSchedulingMetrics(
      {
        filter: {
          ...query,
          isMonthlyBreakdown: true,
        },
      },
      {
        onSuccess: (response) => {
          const data = getMonths();

          Object.keys(response).forEach((key1) => {
            Object.keys(response[key1]).forEach((key2, index2) => {
              data[index2][t(key1)] = response[key1][key2];
            });
          });

          setSchedulingMemberMetricsMonthly(data);
        },
      }
    );

  const { isFetching: isFetchingAttendanceOver24Hours } =
    useReportsFindAttendanceClassAttendanceOver24Hours(
      {
        filter: {
          ...query,
          startDate: `${query.year}-01-01`,
          endDate: `${query.year}-12-31`,
        },
      },
      {
        onSuccess: (response) => {
          const data = [];

          Object.keys(response).map(
            (key) =>
              response[key] > 0 &&
              data.push({
                name: key,
                [t('numberOfBookings')]: response[key],
              })
          );

          setAttendanceOver24Hours(data);
        },
      }
    );

  return (
    <Box hasRadiusBottom bg='grey6' p={4}>
      <Stats
        data={[
          {
            title: t('bookings'),
            value: schedulingMetrics?.bookings,
            isLoading: isFetchingSchedulingMetrics,
            // comparison: {
            //   values: !isFetchingSchedulingMetricsPrevious && [
            //     schedulingMetrics.bookings,
            //     schedulingMetricsPrevious.bookings,
            //   ],
            // },
          },
          {
            title: t('checkIns'),
            value: schedulingMetrics?.checkIns,
            isLoading: isFetchingSchedulingMetrics,
            // comparison: {
            //   values: !isFetchingSchedulingMetricsPrevious && [
            //     schedulingMetrics.checkIns,
            //     schedulingMetricsPrevious.checkIns,
            //   ],
            // },
          },
          {
            title: t('cancellations'),
            value: schedulingMetrics?.allCancellations,
            isLoading: isFetchingSchedulingMetrics,
            // comparison: {
            //   values: !isFetchingSchedulingMetricsPrevious && [
            //     schedulingMetrics.allCancellations,
            //     schedulingMetricsPrevious.allCancellations,
            //   ],
            //   shouldFirstValueBeGreater: false,
            // },
          },
          {
            title: t('lateCancellations'),
            value: schedulingMetrics?.lateCancellations,
            isLoading: isFetchingSchedulingMetrics,
            // comparison: {
            //   values: !isFetchingSchedulingMetricsPrevious && [
            //     schedulingMetrics.lateCancellations,
            //     schedulingMetricsPrevious.lateCancellations,
            //   ],
            //   shouldFirstValueBeGreater: false,
            // },
          },
          {
            title: t('noShows'),
            value: schedulingMetrics?.noShows,
            isLoading: isFetchingSchedulingMetrics,
            // comparison: {
            //   values: !isFetchingSchedulingMetricsPrevious && [
            //     schedulingMetrics.noShows,
            //     schedulingMetricsPrevious.noShows,
            //   ],
            //   shouldFirstValueBeGreater: false,
            // },
          },
        ]}
        mb={6}
        mt={2}
      />

      <Row>
        <Col lg={6}>
          <Chart
            isLine
            isShort
            data={schedulingMetricsMonthly || []}
            isLoading={isFetchingSchedulingMetricsMonthly}
            title={t('monthlyBreakdown')}
          />
        </Col>

        <Col lg={6}>
          <Chart
            isBar
            isShort
            data={attendanceOver24Hours || []}
            isLoading={isFetchingAttendanceOver24Hours}
            isVisibleLegend={false}
            title={t('mostPopularBookingTimes')}
          />
        </Col>
      </Row>
    </Box>
  );
};
