import { Images } from 'octiv-assets';
import {
  Box,
  Card,
  Col,
  Container,
  Image,
  LanguageSelector,
  Row,
  Text,
} from 'octiv-components';
import { Terms } from 'octiv-containers';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useToggle } from 'octiv-hooks';
import { useLoginCreate } from 'octiv-hooks/requests/Login';
import { usePasswordCreateForget } from 'octiv-hooks/requests/Password';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import Form from './Form';

const AuthLogin = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const { isSignedIn, signIn } = useSignedInUser();

  const [toggle, setToggle, resetToggle] = useToggle();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onNavigate = () => {
    navigate(
      location?.state?.onLoginPath && location.state.onLoginPath !== '/login'
        ? location.state.onLoginPath
        : '/',
      { replace: true }
    );
  };

  const loginCreate = useLoginCreate({
    onSuccess: (response) => {
      signIn({
        jwt: response.accessToken,
        mergeAccount: response?.mergeAccount,
        navigateTo:
          location?.state?.onLoginPath &&
          location.state.onLoginPath !== '/login'
            ? location.state.onLoginPath
            : '/',
      });
    },
    onError: () => setIsLoggingIn(false),
  });

  const passwordCreateForget = usePasswordCreateForget({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onSubmitForm = (values) => {
    setIsLoggingIn(true);

    loginCreate.mutate({
      username: values.email ? values.email.trim() : undefined,
      password: values.password,
    });
  };

  const onClickForgotPassword = ({ email }) => {
    if (window.confirm(t('forgotPasswordEmailWillBeSent'))) {
      passwordCreateForget.mutate({ email });
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      onNavigate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  return (
    <Container isLoading={isLoggingIn || passwordCreateForget.isLoading} pb={0}>
      {toggle.isVisible && <Terms type={toggle.type} onClose={resetToggle} />}

      <Box isFlex alignItems='center' minHeight='100vh'>
        <Row justifyContent='center'>
          <Col lg={8} md={10} xl={5}>
            <Card>
              <LanguageSelector />

              <Box isFlex justifyContent='center' mt={-19}>
                <Box bg='grey6' borderRadius='50%' p={4}>
                  <Image
                    alt={t('octivLogo')}
                    bg='transparent'
                    size={22}
                    src={Images.logomark}
                  />
                </Box>
              </Box>

              <Text mb={4} textAlign='center' variant='heading'>
                {t('welcomeToOctiv')}
              </Text>

              <Form
                isLoading={isLoggingIn}
                onClickForgotPassword={onClickForgotPassword}
                onClickPrivacyPolicy={() =>
                  setToggle({ type: 'privacyPolicy' })
                }
                onClickTermsOfUse={() => setToggle({ type: 'termsOfUse' })}
                onSubmit={onSubmitForm}
              />
            </Card>
          </Col>
        </Row>
      </Box>
    </Container>
  );
};

export default AuthLogin;
